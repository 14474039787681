import { Button, Card, CardHeader, Text } from '@upsales/components';
import AdminHeader from 'Components/Admin/AdminHeader';
import UpSelect from 'Components/Inputs/UpSelect';

ReactTemplates.admin.integrations = window.ReactCreateClass({
	getInitialState: function () {
		return {
			activeCategory: 'all',
			activeFeature: null,
			categories: [
				{ name: Tools.$translate('integrations.category.all'), id: 'all' },
				{ name: Tools.$translate('integrations.category.data-sources'), id: 'data-sources' },
				{ name: Tools.$translate('integrations.category.nps-scoring'), id: 'nps-scoring' },
				{ name: Tools.$translate('integrations.category.ticketing'), id: 'ticketing' },
				{ name: Tools.$translate('integrations.category.time-reporting'), id: 'time-reporting' },
				{ name: Tools.$translate('integrations.category.productivity'), id: 'productivity' },
				{ name: Tools.$translate('integrations.category.team-collaboration'), id: 'team-collaboration' },
				{ name: Tools.$translate('integrations.category.esign'), id: 'esign' },
				{ name: Tools.$translate('integrations.category.webinar'), id: 'webinar' },
				{ name: Tools.$translate('integrations.category.phone-services'), id: 'phone-services' },
				{ name: Tools.$translate('integrations.category.third-party-apps'), id: 'third-party-apps' },
				{ name: Tools.$translate('integrations.category.billing'), id: 'Billing' },
				{ name: Tools.$translate('integrations.category.chat'), id: 'Chatt' },
				{ name: Tools.$translate('integrations.category.video'), id: 'Video' },
				{ name: Tools.$translate('integrations.category.marketing'), id: 'Marknadsföring' }
			],
			features: [
				{
					id: 'region',
					title: Tools.$translate('admin.apps.myRegion'),
					attribute: { field: 'region' },
					comparison: 'wc',
					value: Tools.AppService.getMetadata().params.CustomerRegion,
					type: 'checkbox',
					active: true
				},
				{
					id: 'free',
					title: 'Free',
					attribute: { field: 'price' },
					comparison: 'lt',
					value: 1,
					type: 'checkbox',
					active: false
				},
				{
					id: 'premium',
					title: 'Premium',
					attribute: { field: 'price' },
					comparison: 'gte',
					value: 1,
					type: 'checkbox',
					filterType: 'AND',
					active: false
				}
			]
		};
	},
	componentDidMount: function () {
		this.props.rootData.pageData.updateSearch(this.state.activeCategory, this.state.features);
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			integrations: t('integrations.integrations'),
			adminIntro: t('integrations.adminIntro'),
			name: t('default.name'),
			public: t('default.public'),
			private: t('default.private'),
			active: t('default.active'),
			inactive: t('default.inactive'),
			createIntegration: t('admin.createIntegration'),
			yourIntegrations: t('admin.yourIntegrations'),
			filterIntegration: t('admin.apps.filterIntegration')
		};

		this.doSearch = _.debounce(this.doSearch, 200);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	viewIntegration: function (integration) {
		this.props.rootData.onViewIntegration(integration);
	},
	editIntegration: function (integration) {
		this.props.rootData.onEditIntegration(integration);
	},
	onSearch: function (e) {
		this.doSearch(e.target.value);
	},
	doSearch: function (value) {
		this.props.rootData.pageData.onSearch(value);
	},
	onCategoryChange: function (value) {
		this.setState({ activeCategory: value });
		this.props.rootData.pageData.updateSearch(this.state.activeCategory, this.state.features);
	},
	generateCategoryDropdown: function () {
		var self = this;
		var categories = this.state.categories;

		return (
			<UpSelect
				className="form-control"
				onChange={function (e) {
					self.onCategoryChange(e.target.value);
				}}
				formatSelection={function (s, container, escape) {
					return escape(s.name);
				}}
				formatResult={function (s, container, query, escape) {
					return escape(s.name);
				}}
				getId={function (s) {
					return s.id;
				}}
				data={_.sortBy(categories, 'name')}
				matcher={function (term, undef, item) {
					// var name = Tools.$translate(item.name).toLowerCase();
					return item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
				}}
				defaultValue={this.state.activeCategory}
			/>
		);
	},
	onCheckboxClick: function (featureId) {
		const { features } = this.state;
		// Easier to work with a map if we need to make changes in the array.
		// We map the array with references to the map and keep the state immutable
		const featuresMap = features.reduce((acc, feature) => {
			acc[feature.id] = { ...feature };
			return acc;
		}, {});

		const updatedFeatures = features.map(feature => {
			if (feature.id === featureId) {
				featuresMap[featureId].active = !featuresMap[featureId].active;
			}
			return featuresMap[feature.id];
		});

		featuresMap['premium'].filterType = featuresMap['free'].active && featuresMap['premium'].active ? 'OR' : 'AND';

		this.setState(
			{
				features: updatedFeatures
			},
			() => {
				this.props.rootData.pageData.updateSearch(this.state.activeCategory, this.state.features);
			}
		);
	},
	generateFeatureCheckboxes: function () {
		var self = this;
		var features = this.state.features;
		var Checkbox = window.Checkbox;

		return features.map(function (feature) {
			return (
				<div
					key={feature.id}
					className="checkbox-holder"
					onClick={function () {
						self.onCheckboxClick(feature.id);
					}}
				>
					<Checkbox size="xs" checked={feature.active} />
					<Text>{feature.title}</Text>
				</div>
			);
		});
	},
	render: function () {
		var self = this;
		var rootData = this.props.rootData;

		var integrations = null;
		if (
			rootData.pageData.integrations &&
			Array.isArray(rootData.pageData.integrations) &&
			rootData.pageData.integrations.length
		) {
			integrations = rootData.pageData.integrations
				.filter(function (integration) {
					var searchValue = rootData.pageData.searchString
						? rootData.pageData.searchString.trim().toLowerCase()
						: rootData.pageData.searchString;

					if (integration.standardIntegrationInit.some(i => i.type === 'import')) {
						return false;
					}

					if (searchValue) {
						var nameMatch = (integration.name || '').trim().toLowerCase().indexOf(searchValue) > -1;
						var tagMatch = _.some(integration.standardIntegrationInit, function (init) {
							return (init.type || '').trim().toLowerCase() === searchValue;
						});

						return nameMatch || tagMatch;
					}
					return true;
				})
				.map(function (integration) {
					return (
						<ReactTemplates.appPreview
							key={'integration-' + integration.id}
							integration={integration}
							onClick={self.viewIntegration.bind(self, integration)}
						/>
					);
				});
		}

		var publisherTools = null;
		if (rootData.pageData.integrationPublisher) {
			publisherTools = (
				<div className="admin-table">
					<div className="admin-table-top">
						<span className="admin-table-title">{self.lang.yourIntegrations}</span>
						<div className="pull-right">
							<Button onClick={self.editIntegration} size="sm">
								<i className="fa fa-plus" /> {self.lang.createIntegration}
							</Button>
						</div>
					</div>

					<table>
						<thead>
							<tr>
								<th>{self.lang.name}</th>
								<th>{self.lang.public}</th>
								<th>{self.lang.active}</th>
							</tr>
						</thead>
						<tbody>
							{_.map(rootData.pageData.yourIntegrations, function (integration, i) {
								return (
									<tr
										className="clickable"
										key={'my-integration-' + i}
										onClick={self.editIntegration.bind(self, integration)}
									>
										<td>{integration.name}</td>
										<td>
											<span className="text-grey">
												{integration.public ? self.lang.public : self.lang.private}
											</span>
										</td>
										<td>
											<span className="text-grey">
												{integration.active ? self.lang.active : self.lang.inactive}
											</span>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			);
		}

		return (
			<div id="admin-page-integrations">
				<AdminHeader title={self.lang.integrations} description={self.lang.adminIntro} />

				<div className="menu clearfix">
					<div className="category-select-wrap pull-left">
						<div className="category-wrap">{this.generateCategoryDropdown()}</div>
						<div className="feature-wrap">{this.generateFeatureCheckboxes()}</div>
					</div>
					<div className="search-input-wrap pull-right">
						<input
							type="text"
							className="search-input"
							placeholder={this.lang.filterIntegration}
							defaultValue={rootData.pageData.searchString}
							onChange={this.onSearch}
						/>
						<b className="fa fa-search" />
					</div>
				</div>

				<div id="admin-content">
					<div id="integration-overview">
						{integrations?.length ? (
							<div className="box-wrapper">{integrations}</div>
						) : (
							<Card space="mbl">
								<CardHeader
									title={Tools.$translate('noMatches.apps')}
									subtitle={Tools.$translate('noMatches.tryChangeFilters')}
								/>
							</Card>
						)}

						{publisherTools}
					</div>
				</div>
			</div>
		);
	}
});
