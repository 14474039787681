import { Card, Row, Column, Text, Toggle, Title, Help, Tooltip, StateFrame } from '@upsales/components';
import { useMetadata, useSelf, useUsers } from 'App/components/hooks/appHooks';
import { useTranslation } from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import User from 'App/resources/Model/User';
import './LicenseTypeSettings.scss';
import React, { useRef } from 'react';
import { useFeatureAvailable } from 'App/components/hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';

type Props = {
	user: User;
	newEmails: number;
	saving: boolean;
	onChange: (key: string, value: 1 | 0) => void;
};

const LicenseTypeSettings = ({ user, newEmails, saving, onChange }: Props) => {
	const hasServiceManagement = useFeatureAvailable(Feature.PROJECT_PLAN);
	const { id, support, crm, service } = user;
	const classes = new bemClass('LicenseTypeSettings');
	const { t } = useTranslation();
	const { licenses, supportLicenses } = useMetadata() || { licenses: 0, supportLicenses: 0 };

	const activeUsers = useUsers('active', false, 'all', true);
	const activeSupportUsers = useRef(activeUsers.filter(u => u.support || u.service).length);
	const activeCrmUsers = useRef(activeUsers.filter(u => u.crm).length);

	const self = useSelf();
	const editingSelf = id === self?.id;

	const prevUser = useRef(user);
	const removingCrm = id && prevUser.current.crm && !crm;

	const prevUserInactive =
		!prevUser.current.id || (prevUser.current.active && prevUser.current.ghost) || !prevUser.current.active;
	const userInactive = (user.active && user.ghost) || !user.active;

	// Some calculations to display how many seats will be available after saving the user
	const supportUsersIncreasing =
		(user.support || user.service) &&
		!userInactive &&
		(!(prevUser.current.support || prevUser.current.service) || prevUserInactive);
	const supportUsersDecreasing =
		(!(user.support || user.service) || userInactive) &&
		(prevUser.current.support || prevUser.current.service) &&
		!prevUserInactive;
	const availableSupportSeatsChange = newEmails * (supportUsersIncreasing ? -1 : supportUsersDecreasing ? 1 : 0);

	const crmUsersIncreasing = user.crm && !userInactive && (!prevUser.current.crm || prevUserInactive);
	const crmUsersDecreasing = (!user.crm || userInactive) && prevUser.current.crm && !prevUserInactive;
	const availableCrmSeatsChange = newEmails * (crmUsersIncreasing ? -1 : crmUsersDecreasing ? 1 : 0);

	const availableSupportSeats = supportLicenses - activeSupportUsers.current + availableSupportSeatsChange;
	const availableCrmSeats = licenses - activeCrmUsers.current + availableCrmSeatsChange;

	const supportDisabled = !support && availableSupportSeats < newEmails;
	const serviceDisabled = !service && availableSupportSeats < newEmails;
	const crmDisabled = (!crm && availableCrmSeats < newEmails) || editingSelf;
	const disabledTitleProps = { color: 'grey-11' as const, italic: true };

	// When multi adding new users, if you add more emails then more licenses are needed
	// This will untoggle the support/crm option if there are not enough licenses
	if (availableSupportSeats < 0) {
		onChange('support', 0);
		onChange('service', 0);
	}
	if (availableCrmSeats < 0) {
		onChange('crm', 0);
	}

	return (
		<Card className={classes.b()}>
			<Row className={classes.elem('header-row').b()}>
				<Title>{t('admin.user.licenseType')}</Title>
				<Help articleId={1483} sidebar />
			</Row>
			<Row className={classes.elem('option-row').b()}>
				<Column fixedWidth={40} className={classes.elem('option-toggle-column').b()}>
					<Toggle
						checked={!!support}
						disabled={supportDisabled}
						onChange={value => {
							if (value) {
								onChange('support', 1);
							} else {
								onChange('support', 0);
							}
						}}
					/>
				</Column>
				<Column className={classes.elem('option-info-column').b()}>
					<Text size="lg" {...(supportDisabled ? disabledTitleProps : {})}>
						{t('default.support')}
					</Text>
					<Text
						size="sm"
						color={availableSupportSeats >= newEmails ? 'success-5' : 'grey-11'}
					>{`${availableSupportSeats}/${supportLicenses} ${t('admin.user.supportSeatsAvailable')}`}</Text>
					<Text size="sm" color="grey-11" italic={supportDisabled}>
						{t('admin.user.supportSeatsDesc')}
					</Text>
				</Column>
			</Row>
			{hasServiceManagement ? (
				<Row className={classes.elem('option-row').b()}>
					<Column fixedWidth={40} className={classes.elem('option-toggle-column').b()}>
						<Toggle
							checked={!!service}
							disabled={serviceDisabled}
							onChange={value => {
								if (value) {
									onChange('service', 1);
								} else {
									onChange('service', 0);
								}
							}}
						/>
					</Column>
					<Column className={classes.elem('option-info-column').b()}>
						<Text size="lg" {...(serviceDisabled ? disabledTitleProps : {})}>
							{t('default.service')}
						</Text>
						<Text
							size="sm"
							color={availableSupportSeats >= newEmails ? 'success-5' : 'grey-11'}
						>{`${availableSupportSeats}/${supportLicenses} ${t('admin.user.serviceSeatsAvailable')}`}</Text>
						<Text size="sm" color="grey-11" italic={serviceDisabled}>
							{t('admin.user.serviceSeatsDesc')}
						</Text>
					</Column>
				</Row>
			) : null}
			<Row className={classes.elem('option-row').b()}>
				<Column fixedWidth={40} className={classes.elem('option-toggle-column').b()}>
					<Tooltip title={t('admin.user.cannotRemoveCrmFromSelf')} disabled={!editingSelf}>
						<Toggle
							checked={!!crm}
							disabled={crmDisabled}
							onChange={value => {
								if (value) {
									onChange('crm', 1);
								} else {
									onChange('crm', 0);
								}
							}}
						/>
					</Tooltip>
				</Column>
				<Column className={classes.elem('option-info-column').b()}>
					<Text size="lg" {...(crmDisabled ? disabledTitleProps : {})}>
						{t('default.crm')}
					</Text>
					<Text
						size="sm"
						color={availableCrmSeats >= newEmails ? 'success-5' : 'grey-11'}
					>{`${availableCrmSeats}/${licenses} ${t('admin.user.crmSeatsAvailable')}`}</Text>
					<Text size="sm" color="grey-11">
						{t('admin.user.crmSeatsDesc')}
					</Text>
				</Column>
			</Row>
			{removingCrm ? (
				<StateFrame
					space="mrl mll"
					state="info"
					icon="info"
					title={t('admin.user.removingCrmTitle')}
					subtitle={t('admin.user.removingCrmDesc')}
				/>
			) : null}
		</Card>
	);
};

export default LicenseTypeSettings;
