import MailTemplate from 'Resources/MailTemplate';
import UpSelect from 'Components/Inputs/UpSelect';
import ActionSquare from 'Components/Flow/ActionSquare/ActionSquare';
import BehaviorDiamond from 'Components/Flow/BehaviorDiamond/BehaviorDiamond';
import SnoozeBall from 'Components/Flow/SnoozeBall/SnoozeBall';
import ActionPopup from 'Components/Flow/ActionPopup/ActionPopup';
import ABTestingPopupPreview from 'Components/Flow/ABTestingPopupPreview/ABTestingPopupPreview';
import { openDrawer } from 'Services/Drawer';
import ContactTablePreview from 'Components/Flow/StepDrawer/Action/ContactTablePreview';
import SendEmailDrawer from 'Components/Flow/StepDrawer/Action/SendEmail';
import PhoneCallDrawer from 'Components/Flow/StepDrawer/Action/PhoneCall';
import FormSubmitDrawer from 'Components/Flow/StepDrawer/Behaviour/FormSubmit';
import ClickedMailDrawer from 'Components/Flow/StepDrawer/Behaviour/ClickedMail';
import ReadMailDrawer from 'Components/Flow/StepDrawer/Behaviour/ReadMail';
import SnoozePreview from 'Components/Flow/StepDrawer/Snooze/Snooze';
import EndPreview from 'Components/Flow/StepDrawer/End';
import MailCampaignResource from 'App/resources/MailCampaign';
import TemplateCard from 'App/babel/components/TemplateCard';
import { previewTemplate } from 'App/helpers/mailTemplateHelpers';
import { Button, Text, Icon, Label, Row, Column, Block } from '@upsales/components';
import { capitalize } from 'lodash';
import { makeCancelable } from 'Helpers/promise';
import logError from 'Helpers/logError';
import { DefaultButton } from '@upsales/components/Buttons';
import history from 'App/pages/routes/history';
import { openAssignModalLead } from 'Components/AssignModal/AssignModalLead';
import { openUpdateContactAction } from 'App/components/MultiActionModal/MultiUpdateContact/OpenMultiUpdateContact';
import { openUpdateClientAction } from 'App/components/MultiActionModal/MultiUpdateClient/openMultiUpdateClientModal';
import { openCreateActivityAction } from 'App/components/MultiActionModal/CreateActivityAction/openCreateActivityAction';

ReactTemplates.segment.flowPopup = window.ReactCreateClass({
	_form: {},
	linkInit: false,
	formInit: false,
	relationGetter: null,
	colors: {
		turcose: '#2EAA9B',
		brightPurple: '#B254E0',
		darkPurple: '#4E0065',
		upsalesBrightBlue: '#4A90E2',
		grey: 'rgba(0, 0, 0, 0.2)'
	},
	getStateFromProps: function (props) {
		return _.cloneDeep({
			params: props.popup.step.params || {},
			type: props.popup.type,
			waitTime: props.popup.step.waitTime || null,
			waitUnit: props.popup.step.waitUnit || null,
			mailCampaignId: props.popup.step.mailCampaignId || null,
			templateId: props.popup.step.templateId || null,
			template: props.popup.step.template || null,
			formId: props.popup.step.formId || null,
			link: props.popup.step.link || null,
			rangeValue: (props.popup.step.params && props.popup.step.params.rangeValue) || 0,
			parentType: props.popup.step.parentType || null
		});
	},
	getInitialState: function () {
		var state = this.getStateFromProps(this.props);

		state.loadingRelation = false;
		state.relationData = null;
		state.params.rangeValue = 0;

		return state;
	},
	UNSAFE_componentWillReceiveProps: function (props) {
		this.setState(this.getStateFromProps(props));
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			add: t('default.add'),
			action: t('flow.action'),
			behavior: t('flow.behavior'),
			snooze: t('flow.snooze'),
			abtesting: t('flow.abtesting'),
			clickedMail: t('flow.clickedMail'),
			mail: t('mail.mail'),
			sendEmail: t('mail.sendEmail'),
			market: t('default.market'),
			save: t('default.save'),
			internalName: t('default.internalName'),
			quantity: t('default.quantity'),
			timePeriod: t('default.timePeriod'),
			form: t('form.form'),
			formSubmit: t('event.form.submited'),
			selectForm: t('default.selectForm'),
			noResults: t('default.noResults'),
			openedMail: t('mailCampaign.openedMail'),
			mailReadInfo: t('flow.mailReadInfo'),
			mailReadInfoLink: t('flow.mailReadInfoLink'),
			selectLink: t('flow.selectLink'),
			allLinks: t('flow.allLinks'),
			created: t('default.created'),
			latestForms: t('flow.latestForms'),
			searching: t('default.searching'),
			howLongDoesOneHave: t('flow.howLongDoesOneHave'),
			selectTemplate: t('default.choose') + ' ' + t('default.template'),
			changeTemplate: t('default.change') + ' ' + t('default.template'),
			editTemplate: t('groupMail.editTemplate'),
			days: t('calendar.days').toLowerCase(),
			weeks: t('date.weeks').toLowerCase(),
			months: t('date.months').toLowerCase(),
			day: t('calendar.day').toLowerCase(),
			week: t('calendar.week').toLowerCase(),
			month: t('calendar.month').toLowerCase(),
			groupMail: t('mail.mailings'),
			template: t('default.template'),
			dayUnit: t('date.dayUnit'),
			weekUnit: t('date.weekUnit'),
			monthUnit: t('date.monthUnit'),
			crm: t('default.crm'),
			createActivity: t('automationAction.CreateActivity'),
			createPhonecall: t('automationAction.CreatePhonecall'),
			createTodo: t('automationAction.CreateTodo'),
			updateClient: t('automationAction.UpdateClient'),
			updateContact: t('automationAction.UpdateContact'),
			selectGroupMailCategory: t('groupMail.selectGroupMailCategory'),
			whatTypeOfMailIsThis: t('groupMail.whatTypeOfMailIsThis'),
			howToSplitTitle: t('flow.abtesting.howSplit'),
			whatEmailTemplates: t('flow.abtesting.whatTemplates'),
			variantA: t('flow.abtesting.variantA'),
			variantB: t('flow.abtesting.variantB'),
			openedAndClickedEmail: t('flow.openedAndClickedEmail'),
			assignToUser: t('automationAction.AssignToUser'),
			noName: t('default.noName'),
			cancel: t('default.abort')
		};

		this.hasABTesting = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.AB_TESTING);
		this.canEditTemplate = Tools.FeatureHelper.hasSoftDeployAccess('FLOW_EDIT_MAIL_TEMPLATE');
	},
	getHref: function (state, stateParams) {
		var params = _.merge(stateParams, { customerId: this.props.tools.AppService.getCustomerId() });
		return this.props.tools.$state.href(state, params);
	},
	getRelatedData: function () {
		var self = this;
		var prevStep = this.getPrevStep();
		var isMerge = this.props.popup.step.isMerge;

		if (this.state.relationData || this.state.loadingRelation) {
			return;
		}
		switch (this.state.type) {
			case 'sendMailPreview':
				if (this.state.mailCampaignId) {
					this.relationGetter = makeCancelable(MailCampaignResource.get(this.state.mailCampaignId));
				} else if (this.state.templateId) {
					this.relationGetter = makeCancelable(MailTemplate.get(this.state.templateId));
				}
				break;
			case 'clickedMailPreview':
			case 'readMailPreview':
				if (isMerge) {
					return MailTemplate.get(prevStep.childNo.templateId).then(function (leftTemplate) {
						return MailTemplate.get(prevStep.childYes.templateId).then(function (rightTemplate) {
							self.setState({
								relationData: { leftTemplate: leftTemplate.data, rightTemplate: rightTemplate.data },
								loadingRelation: false
							});
						});
					});
				}
				if (this.state.mailCampaignId) {
					this.relationGetter = makeCancelable(MailCampaignResource.get(this.state.mailCampaignId));
				} else if (prevStep && prevStep.templateId) {
					this.relationGetter = makeCancelable(MailTemplate.get(prevStep.templateId));
				}
				break;
			case 'formSubmitPreview':
			case 'formSubmit':
				this.relationGetter = makeCancelable(Tools.Form.get(this.state.formId));
				break;
			case 'sendMail':
				if (this.state.templateId) {
					this.relationGetter = makeCancelable(MailTemplate.get(this.state.templateId));
				}
				break;
			case 'clickedMail':
			case 'readMail':
				if (isMerge) {
					var linksList = [];
					if (Array.isArray(this.props.popup.step.relationData)) {
						_.each(this.props.popup.step.relationData, function (obj) {
							linksList = linksList.concat(obj.links);
						});
					}

					linksList = _.uniq(linksList);
					return self.setState({
						relationData: {
							links: linksList
						},
						loadingRelation: false
					});
				}

				var mailStep = this.hasMailBehaviors();
				if (mailStep && mailStep.templateId) {
					this.relationGetter = makeCancelable(MailTemplate.get(mailStep.templateId));
				}

				break;
			case 'updateContactPreview':
			case 'updateClientPreview':
			case 'createActivityPreview':
			case 'updateContact':
			case 'updateClient':
			case 'createActivity':
				var customerId = Tools.AppService.getCustomerId();
				var RequestBuilder = Tools.RequestBuilder;
				var promises = {};

				var removeProject = _.find(this.state.params, { name: 'RemoveProject' });
				var addProject = _.find(this.state.params, { name: 'AddProject' });
				var project = _.find(this.state.params, { name: 'Project' });

				if (removeProject || addProject || project) {
					var projectValues = (removeProject ? removeProject.value.split(',') : []).concat(
						addProject ? addProject.value.split(',') : []
					);

					if (project) {
						projectValues.push(project.value);
					}

					var Campaign = Tools.Campaign;
					var campaignRb = new RequestBuilder();

					campaignRb.addFilter(Campaign.attr.id, campaignRb.comparisonTypes.Equals, projectValues);
					promises.campaign = Campaign.customer(customerId).find(campaignRb.build());
				}

				var addSegments = _.find(this.state.params, { name: 'AddSegments' });

				if (addSegments) {
					var Segment = Tools.Segment;
					var segmentRb = new RequestBuilder();

					segmentRb.addFilter(Segment.attr.id, segmentRb.comparisonTypes.Equals, addSegments.value);
					promises.segment = Segment.find(segmentRb.build());
				}

				this.relationGetter = makeCancelable(Tools.$q.all(promises));
				// eslint-disable-next-line promise/catch-or-return
				this.relationGetter.promise.then(function (res) {
					return {
						data: {
							campaign: res.campaign ? res.campaign.data : null,
							segment: res.segment ? res.segment.data : null
						}
					};
				});
				break;
		}

		if (this.relationGetter) {
			this.setState({ loadingRelation: true });

			this.relationGetter.promise
				.then(function (r) {
					self.setState({ relationData: r.data, loadingRelation: false });
				})
				.catch(e => logError(e, 'Failed to fetch relations'));
		} else {
			this.setState({ relationData: {}, loadingRelation: false });
		}
	},
	componentDidMount: function () {
		this.setSelects();
		this.renderChart();
		this.getRelatedData();
	},
	componentDidUpdate: function () {
		this.setSelects();
		this.renderChart();

		window.setImmediate(() => this.props.positionPopup());
	},
	renderChart: function () {
		if (this._chart) {
			var self = this;
			var config = {
				chart: {
					type: 'pie',
					backgroundColor: 'transparent',
					margin: [0, 0, 0, 0],
					spacing: [0, 0, 0, 0]
				},
				title: { text: null },
				credits: { enabled: false },
				yAxis: {},
				legend: { enabled: false },
				plotOptions: {
					pie: {
						size: 90,
						innerSize: 60,
						shadow: false,
						dataLabels: { enabled: false },
						borderWidth: 0,
						states: {
							hover: {
								brightness: 0,
								halo: false
							}
						}
					}
				},
				tooltip: { enabled: false },
				series: this.chartSeries
			};
			new Highcharts.Chart(self._chart, config, function () {
				self.chart = this;
			});
		}
	},
	componentWillUnmount: function () {
		if (this._form.form) {
			jQuery(this._form.form).select2('destroy');
			this.formInit = false;
		}
		this.relationGetter?.cancel();
	},
	UNSAFE_componentWillUpdate: function (props, state) {
		if (state.type !== 'formSubmit' && this._form.form) {
			jQuery(this._form.form).select2('destroy');
			this.formInit = false;
		}
	},
	setType: function (type, props) {
		var step = this.props.getStep(type, props);

		this.setState({
			params: step.params,
			type: type,
			waitTime: step.waitTime,
			waitUnit: step.waitUnit,
			mailCampaignId: step.mailCampaignId,
			templateId: step.templateId,
			formId: step.formId,
			form: null,
			template: null,
			link: step.link,
			relationData: null,
			loadingRelation: false
		});
	},
	setSelects: function () {
		var self = this;
		if (self._form.form && self._form.form && !this.formInit) {
			jQuery(self._form.form)
				.select2({
					minimumInputLength: 0,
					quietMillis: 100,
					allowClear: 0,
					placeholder: ' ',
					formatSearching: function () {
						return self.lang.searching;
					},
					initSelection: function (input, cb) {
						self.props.tools.Form.get(input[0].value)
							.then(function (r) {
								cb(r.data);
							})
							.catch(function () {
								cb(null);
							});
					},
					formatResult: function (obj, el, x, encode) {
						if (obj.children) {
							return obj.name;
						}
						var name = obj.internalName || obj.name;
						return (
							'<span class="' +
							(name ? '' : 'text-grey') +
							'">' +
							encode(name || self.lang.noName) +
							'<div class="subtitle">' +
							self.lang.created +
							': ' +
							encode(moment(obj.regDate).format('L LT')) +
							'</div>' +
							'</span>'
						);
					},
					formatSelection: function (obj, x, encode) {
						return encode(obj.internalName || obj.name || self.lang.noName);
					},
					formatNoMatches: function () {
						return self.lang.noResults;
					},
					id: 'id',
					ajax: {
						data: function (term) {
							return term;
						},
						transport: function (query) {
							var filter = new self.props.tools.RequestBuilder();
							filter.addFilter(
								self.props.tools.Form.attr.isArchived,
								filter.comparisonTypes.Equals,
								false
							);
							filter.limit = 10;
							filter.addSort(self.props.tools.Form.attr.regDate, false);

							if (!query.data || !query.data.length) {
								// Return last 10 mailcampaigns
								return self.props.tools.Form.find(filter.build()).then(function (r) {
									var data = [];
									if (r.data && r.data.length) {
										data.push({ name: self.lang.latestForms, children: r.data });
									}
									query.success({ data: data });
								});
							} else {
								var or = filter.orBuilder();
								or.next();
								or.addFilter(
									self.props.tools.Form.attr.name,
									filter.comparisonTypes.Search,
									query.data
								);
								or.next();
								or.addFilter(
									self.props.tools.Form.attr.internalName,
									filter.comparisonTypes.Search,
									query.data
								);
								or.next();
								or.addFilter(
									self.props.tools.Form.attr.title,
									filter.comparisonTypes.Search,
									query.data
								);
								or.done();
								return self.props.tools.Form.find(filter.build()).then(query.success);
							}
						},
						results: function (res) {
							return { results: res.data };
						}
					}
				})
				.on('change', function (e) {
					self.setState({ formId: e.added.id, relationData: e.added, form: e.added });
				});

			this.formInit = true;
		}
	},
	linkChange: function (e) {
		this.setState({ link: e.target.value });
	},
	renderBackBtn: function (backToType) {
		if (this.props.popup.edit) {
			return null;
		}
		var self = this;
		return (
			<button
				type="button"
				className="back-btn"
				onClick={function () {
					self.setType(backToType || 'selectType');
				}}
			>
				<b className="fa fa-chevron-left" />
			</button>
		);
	},
	renderRemoveBtn: function () {
		var self = this;
		return (
			<button
				type="button"
				className="remove-btn"
				onClick={function () {
					self.props.removeStep();
				}}
			>
				<b className="fa fa-trash-o" />
			</button>
		);
	},
	renderSaveBtn: function (disabled) {
		return (
			<div className="btn-flex">
				<Button onClick={this.saveStep} disabled={disabled}>
					{this.lang.save}
				</Button>
				<Button onClick={this.props.closePopup} type="link">
					<Text color="grey-10">{this.lang.cancel}</Text>
				</Button>
			</div>
		);
	},
	saveStep: function () {
		var self = this;
		var step = {
			type: self.state.type,
			params: self.state.params,
			waitTime: self.state.waitTime || null,
			waitUnit: self.state.waitUnit || null,
			mailCampaignId: self.state.mailCampaignId || null,
			templateId: self.state.templateId || null,
			formId: self.state.formId || null,
			form: self.state.form || null,
			template: self.state.template || null,
			link: self.state.link || null
		};

		self.props.onSave(step);
	},
	fixSnoozeTime: function (e) {
		var value = parseInt(e.target.value);
		if (isNaN(value) || value < 1) {
			value = 1;
		}
		this.setState({ waitTime: value });
	},
	validateWaitTime: function () {
		var value = parseInt(this.state.waitTime);
		if (!isNaN(value) && value > 0) {
			return true;
		}
		return false;
	},
	renderTimeComponent: function () {
		var self = this;
		return (
			<div className="row">
				<div className="col-md-3">
					<label>{self.lang.quantity}</label>
					<input
						type="number"
						className="form-control"
						value={this.state.waitTime || ''}
						ref={function (r) {
							self._form.waitTime = r;
						}}
						onChange={function (e) {
							self.setState({ waitTime: e.target.value });
						}}
						min={1}
						onBlur={this.fixSnoozeTime}
						data-test-id="flow-step-popup-snooze-quantity"
					/>
				</div>
				<div className="col-md-9">
					<label>{self.lang.timePeriod}</label>
					<select
						className="form-control"
						value={this.state.waitUnit}
						ref={function (r) {
							self._form.waitUnit = r;
						}}
						onChange={function (e) {
							self.setState({ waitUnit: e.target.value });
						}}
						data-test-id="flow-step-popup-snooze-unit"
					>
						<option value="day">{self.lang.days}</option>
						<option value="week">{self.lang.weeks}</option>
						<option value="month">{self.lang.months}</option>
					</select>
				</div>
			</div>
		);
	},
	selectMailTemplate: function () {
		var self = this;

		// eslint-disable-next-line promise/catch-or-return
		self.props.showEmailTemplateModal(self.state.templateId).then(function (template) {
			self.setState({
				templateId: template.id,
				template,
				relationData: template
			});
		});
	},
	getPrevStep: function () {
		if (!this.props.popup.path || !this.props.popup.path.length) {
			return null;
		}
		var path = this.props.popup.path.split('.');
		path.pop();
		path = 'path.' + path.join('.');
		return _.get(this.props.flow, path);
	},
	hasMailBehaviors: function () {
		const prevStep = this.getPrevStep();
		return prevStep && prevStep.type === 'sendMail' ? prevStep : null;
	},
	getStats: function () {
		if (!this.props.popup.path || !this.props.popup.path.length) {
			return this.props.flow.path ? this.props.flow.path.stats : null;
		}
		var path = 'path.' + this.props.popup.path;
		var step = _.get(this.props.flow, path);
		return step ? step.stats : null;
	},
	renderSnoozeComponent: function () {
		var stats = this.getStats();
		var waitUnit = '';
		switch (this.props.popup.step.waitUnit) {
			case 'day':
				waitUnit = this.lang.dayUnit;
				break;
			case 'week':
				waitUnit = this.lang.weekUnit;
				break;
			case 'month':
				waitUnit = this.lang.monthUnit;
				break;
		}
		return (
			<div className="snooze-component">
				<div className="snooze-tooltip">
					<b className="fa fa-user" />
					{stats.currentlyInStep || 0}
				</div>
				<div className="snooze-ball">
					{this.state.waitTime}
					{waitUnit}
					<b className="up-icon-snooze" />
				</div>
			</div>
		);
	},
	openActionModal: function (action, properties) {
		var self = this;
		var options = {
			properties: properties || null,
			activeUsers: Tools.AppService.getUsers(),
			roles: Tools.AppService.getRoles(),
			tagEntity: 'contact',
			hideDate: true,
			isTrigger: true,
			isAutomation: true,
			isFlow: true
		};

		var type;

		let propertiesPromise;

		switch (action) {
			case 'CreateTodo':
				type = 'createActivity';
				propertiesPromise = new Promise(onSave => {
					openDrawer('CreateTodosAction', {
						properties,
						onSave
					});
				});
				break;
			case 'CreatePhonecall':
				type = 'createActivity';
				propertiesPromise = new Promise(onSave => {
					openDrawer('PlanPhonecallsAction', {
						properties,
						assignOneUser: true,
						onSave
					});
				});
				break;
			case 'CreateActivity':
				options.activityCustomFields = Tools.AppService.getCustomFields('activity');
				options.activityTypes = Tools.AppService.getActivityTypes('activity');
				type = 'createActivity';
				propertiesPromise = openCreateActivityAction(options);
				break;
			case 'UpdateClient':
				options.accountCategories = Tools.AppService.getCategories('account');
				options.accountCustomFields = Tools.AppService.getCustomFields('account');
				type = 'updateClient';
				propertiesPromise = openUpdateClientAction(options);
				break;
			case 'UpdateContact':
				options.contactCategories = Tools.AppService.getCategories('contact');
				options.contactCustomFields = Tools.AppService.getCustomFields('contact');
				type = 'updateContact';
				propertiesPromise = openUpdateContactAction(options);
				break;
			case 'AssignToUser':
				type = 'assign';
				propertiesPromise = openAssignModalLead(options);
				break;
		}

		return propertiesPromise.then(function (res) {
			var step = self.props.getStep(type, res);
			var newState = {
				params: step.params,
				type: type,
				waitTime: step.waitTime,
				waitUnit: step.waitUnit,
				mailCampaignId: step.mailCampaignId,
				templateId: step.templateId,
				formId: step.formId,
				link: step.link,
				relationData: null,
				loadingRelation: false
			};

			self.setState(newState, function () {
				self.saveStep();
			});
		});
	},
	onRangeChange: function (value) {
		this.setState({
			rangeValue: value
		});
	},
	variantPreview: function (templateName, data) {
		var openPercent = data.total ? ((data.open / data.total) * 100).toFixed() : 0;
		var clickPercent = data.total ? ((data.click / data.total) * 100).toFixed() : 0;

		return (
			<div className={'data-variant'}>
				<label>{templateName}</label>
				<div className="data-variant-col">
					<span className="percent">{openPercent + '%'}</span>
					<span className="open-num">{Tools.$filter('numberFormat')(data.open)}</span>
					<span className="text">{Tools.$translate('mailCampaign.openedMail')}</span>
				</div>
				<div className="data-variant-col">
					<span className="percent">{clickPercent + '%'}</span>
					<span className="open-num">{Tools.$filter('numberFormat')(data.click)}</span>
					<span className="text">{Tools.$translate('mailCampaign.mostClickedLinks')}</span>
				</div>
			</div>
		);
	},
	editMailTemplate: function (templateId) {
		// Save flow step and flow to avoid losing changes
		this.saveStep();

		this.props
			.saveFlow(true)
			.then(() => {
				// Redirect to mail template editor
				const stateName = Tools.$state.current.name;
				const params = JSON.stringify(
					stateName === 'editFlowStandalone'
						? { id: Tools.$state.params.id }
						: { segmentId: Tools.$state.params.segmentId }
				);
				history.push(`/mail-template-editor/${templateId}?returnTo=${stateName}:${encodeURIComponent(params)}`);
			})
			.catch(e => logError(e, 'Error while saving flow'));
	},
	render: function () {
		var self = this;

		var content = null;

		if (!this.props.popup.step) {
			return null;
		}

		var plusClass = 'plus';
		var editClass;
		var isPreview = false;
		var stats = this.getStats();

		let optOutSelect = null;
		let selectedCategory = (this.state.params || {}).category;
		if (this.props.optOutCategories) {
			selectedCategory =
				this.props.optOutCategories.length > 1
					? (this.state.params || {}).category
					: this.props.optOutCategories[0];
			optOutSelect = (
				<div>
					<Label required>{this.lang.whatTypeOfMailIsThis}</Label>
					<UpSelect
						required={true}
						className="form-control"
						onChange={e => {
							var params = Array.isArray(this.state.params) ? {} : this.state.params;
							params.category = e.target.value;
							this.setState({ params });
						}}
						data={this.props.optOutCategories}
						placeholder={this.lang.selectGroupMailCategory}
						defaultValue={selectedCategory}
						formatSelection={function (o, container, escape) {
							return escape(o.title);
						}}
						formatResult={function (o, container, query, escape) {
							return escape(o.title);
						}}
						getId={function (o) {
							return o.id;
						}}
					/>
				</div>
			);
		}

		switch (this.state.type) {
			case 'selectType':
				var isRoot = this.props.popup.path.length === 0;
				this._form = {};
				content = (
					<div>
						<div className="popup-header">
							{self.lang.add}
							{':'}
						</div>
						<div
							key="action"
							className="popup-type text-purple"
							onClick={function () {
								self.setType('action');
							}}
							data-test-id="flow-step-popup-set-type-action"
						>
							<div className="icon-wrap">
								<ActionSquare />
							</div>
							{self.lang.action}
						</div>
						<div
							key="behavior"
							className={isRoot ? 'popup-type text-turcose disabled' : 'popup-type text-turcose'}
							onClick={function () {
								if (!isRoot) {
									self.setType('behavior');
								}
							}}
							data-test-id="flow-step-popup-set-type-behavior"
						>
							<div className="icon-wrap">
								<BehaviorDiamond />
							</div>
							{self.lang.behavior}
						</div>
						<div
							key="snooze"
							className={'popup-type'}
							onClick={function () {
								self.setType('snooze');
							}}
							data-test-id="flow-step-popup-set-type-snooze"
						>
							<div className="icon-wrap">
								<SnoozeBall text="2d" />
							</div>
							{self.lang.snooze}
						</div>
					</div>
				);
				break;

			// BEHAVIORS
			case 'behavior':
				var popupStep = this.props.popup.step;
				var hasABTestingBehaviors = popupStep.isMerge;
				plusClass += ' turcose';
				content = (
					<div>
						<div className="popup-header-new white">
							{self.renderBackBtn()}
							{self.lang.add} {self.lang.behavior.toLowerCase()}
						</div>
						{hasABTestingBehaviors && (
							<div>
								<div className="flow-row-header">{self.lang.mail}</div>
								<div
									className="flow-row"
									onClick={function () {
										self.setType('clickedMail');
									}}
								>
									<b className="fa fa-eye text-turcose" />
									{self.lang.openedAndClickedEmail}
									<b className="fa fa-angle-right" />
								</div>
							</div>
						)}
						<div className="flow-row-header">{self.lang.form}</div>
						<div
							className="flow-row"
							onClick={function () {
								self.setType('formSubmit');
							}}
							data-test-id="flow-step-popup-behavior-row-form"
						>
							<b className="up-icon-form text-turcose" />
							{self.lang.formSubmit}
							<b className="fa fa-angle-right" />
						</div>
					</div>
				);
				break;
			case 'clickedMail':
				plusClass += ' rose-4';
				editClass = 'fa fa-mouse-pointer';

				content = (
					<div>
						<div className="popup-header-new white">
							{self.lang.behavior}
							{': '}
							{self.lang.clickedMail}
							{self.renderRemoveBtn()}
						</div>
						<div className="popup-content">
							<label>{capitalize(self.lang.template)}</label>
							<div>{self.state.relationData ? self.state.relationData.name : ''}</div>
							<label style={{ display: 'block' }}>
								{self.lang.selectLink}
								{this.state.link ? (
									<a href={this.state.link} target="_blank" className="pull-right">
										<b className="fa fa-external-link" />
									</a>
								) : null}
							</label>
							<select
								disabled={
									!this.state.relationData ||
									!this.state.relationData.links ||
									!this.state.relationData.links.length
								}
								className="form-control"
								ref={function (r) {
									self._form.link = r;
								}}
								value={this.state.link || ''}
								onChange={this.linkChange}
							>
								<option value="">{self.lang.allLinks}</option>
								{this.state.relationData
									? _.chain(this.state.relationData.links || [])
											.map(function (link) {
												return (
													<option key={link} value={link}>
														{link}
													</option>
												);
											})
											.value()
									: null}
							</select>
							<h3>
								{self.lang.howLongDoesOneHave} <ReactTemplates.elevio articleId={498} />
							</h3>
							{this.renderTimeComponent()}

							{this.renderSaveBtn(!this.validateWaitTime(this.state.waitTime) || !this.state.waitUnit)}
						</div>
					</div>
				);
				break;
			case 'readMail':
				plusClass += ' rose-4';
				editClass = 'fa fa-eye';
				content = (
					<div>
						<div className="popup-header-new white">
							{self.lang.behavior}
							{': '}
							{self.lang.openedMail}
							{self.renderRemoveBtn()}
						</div>
						<div className="popup-content">
							<label>{capitalize(self.lang.template)}</label>
							<div>{self.state.relationData ? self.state.relationData.name : ''}</div>
							<div className="info">
								{self.lang.mailReadInfo}{' '}
								<ReactTemplates.elevio
									sidebar={true}
									articleId="402"
									text={self.lang.mailReadInfoLink}
								/>
							</div>
							<h3>
								{self.lang.howLongDoesOneHave} <ReactTemplates.elevio articleId={498} />
							</h3>
							{this.renderTimeComponent()}

							{this.renderSaveBtn(!this.validateWaitTime(this.state.waitTime) || !this.state.waitUnit)}
						</div>
					</div>
				);
				break;
			case 'formSubmit':
				plusClass += ' turcose';
				editClass = 'up-icon-form';
				var form;
				var formLocked = false;
				if (this.props.flow.hasBeenActive && this.state.formId && this.props.popup.step.id) {
					form = (
						<div>
							<a href={'#/form-overview/' + this.state.formId}>
								{_.get(this.state.relationData, 'name', '')}
							</a>
						</div>
					);
					formLocked = true;
				} else {
					form = (
						<input
							type="hidden"
							ref={function (r) {
								self._form.form = r;
							}}
							defaultValue={this.state.formId}
							className="form-control"
							id="flow-step-popup-formselect"
						/>
					);
				}
				content = (
					<div>
						<div className="popup-header-new white">
							{self.renderBackBtn('behavior')}
							{self.lang.behavior}
							{': '}
							{self.lang.formSubmit}
							{self.renderRemoveBtn()}
						</div>
						<div className="popup-content">
							<label>{formLocked ? self.lang.form : self.lang.selectForm}</label>
							{form}
							<h3>
								{self.lang.howLongDoesOneHave} <ReactTemplates.elevio articleId={498} />
							</h3>
							{this.renderTimeComponent()}

							{this.renderSaveBtn(
								!this.state.formId ||
									!this.validateWaitTime(this.state.waitTime) ||
									!this.state.waitUnit
							)}
						</div>
					</div>
				);
				break;

			// AB TESTING
			case 'abTesting':
				plusClass += ' purple';
				if (!self.state.params || !self.state.params.variantA) {
					var params = self.state.params || {};
					params.variantA = { value: 10 };
					params.variantB = { value: 90 };
				}

				var compareLinks = function (variantA, variantB) {
					if (!variantA || !variantB) {
						return;
					}

					var a = variantA.links || [],
						b = variantB.links || [],
						exists = false;

					for (var x = 0; x < a.length; x++) {
						for (var y = 0; y < b.length; y++) {
							if (a[x].toLowerCase() === b[y].toLowerCase()) {
								exists = true;
							}
						}
					}

					if (!exists) {
						return (
							<div className="warning">
								<i className="fa fa-exclamation-triangle" />
								{Tools.$translate('flow.warning.abtest')}
							</div>
						);
					}
				};

				var pickVariantTemplate = function (key, paramsArg) {
					var templateSelected = 'btn up-btn btn-bright-blue btn-link btn-block btn-ellipsis';
					var templateName = self.lang.selectTemplate;
					if (paramsArg) {
						var obj = paramsArg[key];
						if (obj && obj.relationData) {
							templateName = obj.relationData.name;
							templateSelected += ' got-template';
						}
					}
					return (
						<div className={'pick-' + key}>
							<label>{self.lang[key] + ' ' + self.lang.template}</label>
							<button
								id="template-select-btn"
								className={templateSelected}
								onClick={function () {
									// eslint-disable-next-line promise/catch-or-return
									self.props.showEmailTemplateModal(self.state.templateId).then(function (template) {
										var params = paramsArg || {};

										// eslint-disable-next-line promise/catch-or-return
										MailTemplate.get(template.id).then(function (templ) {
											params[key].templateId = templ.data.id;
											params[key].relationData = {
												name: templ.data.name,
												id: templ.data.id,
												links: templ.data.links
											};

											self.setState({
												params: params
											});
										});
									});
								}}
							>
								<b className="fa fa-plus" />
								{templateName}
							</button>
						</div>
					);
				};

				var _disabled =
					selectedCategory &&
					self.state.params &&
					self.state.params.variantA &&
					self.state.params.variantA.value &&
					self.state.params.variantA.templateId &&
					self.state.params.variantB &&
					self.state.params.variantB.value &&
					self.state.params.variantB.templateId;

				content = (
					<div>
						<div className="popup-header-new purple">
							{self.renderBackBtn()}
							{self.lang.add} {self.lang.abtesting.toLowerCase()}
							{self.renderRemoveBtn()}
						</div>
						<div className="popup-content ab-testing-content">
							<h3 className="ab-testing-header">{self.lang.howToSplitTitle}</h3>
							<div className="ab-testing-slider-wrapper">
								<div className="ab-testing-variants variantA">{self.lang.variantA}</div>
								<div className="ab-testing-variants variantB">{self.lang.variantB}</div>
								{React.createElement(window.RangeSlider, {
									min: 10,
									max: 90,
									fullWidth: true,
									minMaxLabels: true,
									value: self.state.params.variantA.value,
									showHandleLabel: true,
									onChange: function (e) {
										var min = 10,
											max = 90;
										var params = self.state.params || {};

										var rightValue = 100 - e;
										var value = e;

										if (min && min > value) {
											value = min;

											if (max) {
												rightValue = max;
											}
										}

										if (params.variantA.hasOwnProperty('templateId')) {
											params.variantA.value = value;
										} else {
											params.variantA = { value: value };
										}

										if (params.variantB.hasOwnProperty('templateId')) {
											params.variantB.value = rightValue;
										} else {
											params.variantB = { value: rightValue };
										}

										params.rangeValue = e;
										self.setState({ params: params, rangeValue: e });
									},
									handleLabelComponent: window.ABTestingLabel
								})}
							</div>
						</div>
						<div className="popup-content ab-testing-content abtesting-darker">
							<h3 className="ab-testing-header">{self.lang.whatEmailTemplates}</h3>
							{pickVariantTemplate('variantA', self.state.params)}
							{pickVariantTemplate('variantB', self.state.params)}
							{compareLinks(
								self.state.params.variantA.relationData,
								self.state.params.variantB.relationData
							)}
							<div className="ab-testing-optout-select">{optOutSelect}</div>
							{this.renderSaveBtn(!_disabled)}
						</div>
					</div>
				);
				break;
			// ACTIONS
			case 'action':
				plusClass += ' rose-4';
				content = (
					<div>
						<div className="popup-header-new white">
							{self.renderBackBtn()}
							{self.lang.add} {self.lang.action.toLowerCase()}
						</div>
						<div className="flow-row-header">{self.lang.market}</div>
						<div
							className="flow-row"
							onClick={function () {
								self.setType('sendMail');
							}}
						>
							{self.lang.sendEmail}
							<b className="fa fa-angle-right" />
						</div>
						<div
							className="flow-row"
							onClick={function () {
								self.openActionModal('AssignToUser', null);
							}}
						>
							{self.lang.assignToUser}
							<b className="fa fa-angle-right" />
						</div>
						{this.hasABTesting && (
							<div
								className="flow-row"
								onClick={function () {
									self.setType('abTesting');
								}}
							>
								{self.lang.abtesting}
								<b className="fa fa-angle-right" />
							</div>
						)}
						<div className="flow-row-header">{self.lang.crm}</div>
						{Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
						Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') ? null : (
							<div
								className="flow-row"
								onClick={function () {
									self.openActionModal('CreateActivity', null);
								}}
							>
								{self.lang.createActivity}
								<b className="fa fa-angle-right" />
							</div>
						)}
						{Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') ? (
							<div
								className="flow-row"
								onClick={function () {
									self.openActionModal('CreatePhonecall', [
										{ name: 'User', value: [Tools.AppService.getSelf().id] }
									]);
								}}
							>
								{self.lang.createPhonecall}
								<b className="fa fa-angle-right" />
							</div>
						) : null}
						{Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
						Tools.FeatureHelper.hasSoftDeployAccess('FLOW_TODO') ? (
							<div
								className="flow-row"
								onClick={function () {
									self.openActionModal('CreateTodo', [
										{ name: 'User', value: Tools.AppService.getSelf().id }
									]);
								}}
							>
								{self.lang.createTodo}
								<b className="fa fa-angle-right" />
							</div>
						) : null}
						<div
							className="flow-row"
							onClick={function () {
								self.openActionModal('UpdateClient', null);
							}}
						>
							{self.lang.updateClient}
							<b className="fa fa-angle-right" />
						</div>
						<div
							className="flow-row"
							onClick={function () {
								self.openActionModal('UpdateContact', null);
							}}
						>
							{self.lang.updateContact}
							<b className="fa fa-angle-right" />
						</div>
					</div>
				);
				break;

			case 'sendMail':
				plusClass += ' rose-4';
				editClass = 'fa fa-paper-plane';
				content = (
					<div>
						<div className="popup-header-new white">
							{self.renderBackBtn('action')}
							{self.lang.action}
							{': '}
							{self.lang.sendEmail}
							{self.renderRemoveBtn()}
						</div>
						<div className="popup-content">
							<div className="send-mail-flex">
								{this.state.relationData && this.state.templateId ? (
									<TemplateCard
										background={this.state.relationData.$$thumbnailUrl}
										onPreview={e =>
											previewTemplate(e, this.state.relationData, this.props.accountProfile)
										}
									/>
								) : null}
								<div>
									<Text>{this.state.relationData?.name}</Text>
									<Block space="mbl mtl">
										<Row>
											<Column>
												<DefaultButton onClick={this.selectMailTemplate}>
													<Icon name="swap-vertical" space="mrs" />
													{this.state.templateId
														? this.lang.changeTemplate
														: this.lang.selectTemplate}
												</DefaultButton>
											</Column>
											<Column fixedWidth={12} />
											{this.canEditTemplate && this.state.templateId ? (
												<Column>
													<DefaultButton
														onClick={() => this.editMailTemplate(this.state.templateId)}
														disabled={!selectedCategory}
													>
														<Icon name="edit" space="mrs" />
														{this.lang.editTemplate}
													</DefaultButton>
												</Column>
											) : null}
										</Row>
									</Block>
									<Block space="mbl">{optOutSelect}</Block>
									<Label>{this.lang.internalName}</Label>
									<input
										type="text"
										className="form-control"
										value={(self.state.params || {}).mailCampaignName || ''}
										onChange={function (e) {
											var params = Array.isArray(self.state.params) ? {} : self.state.params;
											params.mailCampaignName = e.target.value;
											self.setState({ params });
										}}
									/>
								</div>
							</div>
							{this.renderSaveBtn(!self.state.templateId || !selectedCategory)}
						</div>
					</div>
				);
				break;

			case 'assign':
				plusClass += ' purple';
				editClass = 'fa fa-user-plus';

				content = (
					<ActionPopup
						onEdit={function () {
							self.openActionModal('AssignToUser', self.state.params);
						}}
						properties={self.state.params}
						relationData={self.state.relationData}
						type="assign"
					>
						<div className="popup-header purple">
							{self.renderBackBtn('action')}
							{this.lang.action + ': ' + this.lang.assignToUser}
							{self.renderRemoveBtn()}
						</div>
					</ActionPopup>
				);
				break;
			case 'createActivity': {
				const isPhoneCall = self.state.params.some(
					prop =>
						prop.name === 'ActivityType' && prop.value === Tools.AppService.getTodoTypes()?.PHONE_CALL?.id
				);
				plusClass += ' bright-blue';
				editClass = isPhoneCall ? 'fa fa-phone' : 'fa fa-check';

				content = (
					<ActionPopup
						onEdit={function () {
							self.openActionModal(isPhoneCall ? 'CreatePhonecall' : 'CreateActivity', self.state.params);
						}}
						properties={self.state.params}
						relationData={self.state.relationData}
						type="activity"
					>
						<div className="popup-header bright-blue">
							{self.renderBackBtn('action')}
							{this.lang.action +
								': ' +
								(isPhoneCall ? this.lang.createPhonecall : this.lang.createActivity)}
							{self.renderRemoveBtn()}
						</div>
					</ActionPopup>
				);
				break;
			}

			case 'updateClient':
				plusClass += ' bright-blue';
				editClass = 'fa fa-home';

				content = (
					<ActionPopup
						onEdit={function () {
							self.openActionModal('UpdateClient', self.state.params);
						}}
						properties={self.state.params}
						relationData={self.state.relationData}
						type="account"
					>
						<div className="popup-header bright-blue">
							{self.renderBackBtn('action')}
							{this.lang.action + ' ' + this.lang.updateClient}
							{self.renderRemoveBtn()}
						</div>
					</ActionPopup>
				);
				break;

			case 'updateContact':
				plusClass += ' bright-blue';
				editClass = 'fa fa-user';

				content = (
					<ActionPopup
						onEdit={function () {
							self.openActionModal('UpdateContact', self.state.params);
						}}
						properties={self.state.params}
						relationData={self.state.relationData}
						type="contact"
					>
						<div className="popup-header bright-blue">
							{self.renderBackBtn('action')}
							{this.lang.action + ' ' + this.lang.updateContact}
							{self.renderRemoveBtn()}
						</div>
					</ActionPopup>
				);
				break;

			// SNOOZE
			case 'snooze':
				plusClass += ' dark';
				editClass = 'up-icon-snooze';
				content = (
					<div>
						<div className="popup-header-new white">
							{self.renderBackBtn()}
							{self.lang.add} {self.lang.snooze.toLowerCase()}
							{self.renderRemoveBtn()}
						</div>
						<div className="popup-content">
							{this.renderTimeComponent()}

							{this.renderSaveBtn(!this.validateWaitTime(this.state.waitTime) || !this.state.waitUnit)}
						</div>
					</div>
				);
				break;

			// PREVIEWS
			case 'abTestingPreview':
				isPreview = true;
				plusClass = 'preview-close';
				editClass = 'fa fa-times';

				content = (
					<ABTestingPopupPreview
						mainTitle={self.lang.abtesting}
						value={this.state.rangeValue}
						contacts={this.props.contacts}
						variantA={this.state.params.variantA}
						variantB={this.state.params.variantB}
					/>
				);

				break;
			case 'createActivityPreview': {
				const isPhoneCall = self.state.params.some(
					prop =>
						prop.name === 'ActivityType' && prop.value === Tools.AppService.getTodoTypes()?.PHONE_CALL?.id
				);

				isPreview = true;
				plusClass = 'preview-close';
				editClass = 'fa fa-times';

				content = isPhoneCall ? (
					<PhoneCallDrawer
						{..._.merge({}, self.props, {
							type: 'activity',
							stats: stats,
							properties: self.state.params,
							relationData: self.state.relationData || {}
						})}
					/>
				) : (
					<ContactTablePreview
						{..._.merge({}, self.props, {
							type: 'activity',
							stats: stats,
							properties: self.state.params,
							relationData: self.state.relationData || {}
						})}
					/>
				);
				break;
			}
			case 'assignPreview':
				isPreview = true;
				plusClass = 'preview-close';
				editClass = 'fa fa-times';

				var properties = _.filter(self.state.params, function (param) {
					return param.name !== 'sendEmail';
				});

				content = (
					<ContactTablePreview
						{..._.merge({}, self.props, {
							type: 'assign',
							stats: stats,
							properties: properties,
							relationData: self.state.relationData || {}
						})}
					/>
				);
				break;
			case 'updateClientPreview':
				isPreview = true;
				plusClass = 'preview-close';
				editClass = 'fa fa-times';

				content = (
					<ContactTablePreview
						{..._.merge({}, self.props, {
							type: 'account',
							stats: stats,
							properties: self.state.params,
							relationData: self.state.relationData || {}
						})}
					/>
				);

				break;
			case 'updateContactPreview':
				isPreview = true;
				plusClass = 'preview-close';
				editClass = 'fa fa-times';

				content = (
					<ContactTablePreview
						{..._.merge({}, self.props, {
							type: 'contact',
							stats: stats,
							properties: self.state.params,
							relationData: self.state.relationData || {}
						})}
					/>
				);
				break;
			case 'sendMailPreview':
				isPreview = true;
				plusClass = 'preview-close';
				editClass = 'fa fa-times';

				var templateName = null;
				if (!this.state.loadingRelation && this.state.relationData) {
					// Relation data can be a template or a mailCampaign
					// Mailcampaigns has sendDate
					if (this.state.relationData.hasOwnProperty('sendDate')) {
						templateName = (
							<h3>
								{this.state.relationData.template
									? this.state.relationData.template.name
									: self.lang.groupMail}
							</h3>
						);
					} else {
						templateName = <h3>{this.state.relationData.name}</h3>;
					}
				}

				content = (
					<SendEmailDrawer
						{..._.merge(
							{
								getHref: this.getHref
							},
							self.props,
							this.state.relationData
						)}
					/>
				);
				break;
			case 'formSubmitPreview':
				isPreview = true;
				plusClass = 'preview-close';
				editClass = 'fa fa-times';

				content = <FormSubmitDrawer {..._.merge({}, self.props, this.state.relationData)} />;

				break;
			case 'clickedMailPreview':
				isPreview = true;
				plusClass = 'preview-close';
				editClass = 'fa fa-times';

				var step = this.props.popup.step;

				if (step.isMerge) {
					var statsA = step.statsA;
					var statsB = step.statsB;
					statsA.click =
						statsA.link && statsA.link.length
							? statsA.clickedSpecificLinkInTime
							: statsA.clickedAnyLinkInTime;
					statsB.click =
						statsB.link && statsB.link.length
							? statsB.clickedSpecificLinkInTime
							: statsB.clickedAnyLinkInTime;
					var total = statsA.hasCompletedStep + statsB.hasCompletedStep;
					statsA.percentage = Math.round(total ? (statsA.hasCompletedStep / total) * 100 : 0);
					statsB.percentage = Math.round(total ? (statsB.hasCompletedStep / total) * 100 : 0);
				}

				content = (
					<ClickedMailDrawer
						{..._.merge(
							{
								getHref: this.getHref
							},
							self.props,
							this.state.relationData
						)}
					/>
				);

				break;
			case 'readMailPreview':
				isPreview = true;
				plusClass = 'preview-close';
				editClass = 'fa fa-times';

				content = (
					<ReadMailDrawer
						{..._.merge(
							{
								getHref: this.getHref
							},
							self.props,
							this.state.relationData
						)}
					/>
				);
				break;
			case 'snoozePreview':
				isPreview = true;
				plusClass = 'preview-close';
				editClass = 'fa fa-times';

				content = <SnoozePreview {..._.merge({}, self.props, this.state.relationData)} />;
				break;
			case 'endPreview':
				isPreview = true;
				plusClass = 'preview-close';
				editClass = 'fa fa-times';

				content = <EndPreview {..._.merge({}, self.props, this.state.relationData)} />;
				break;
		}

		return (
			<div className="popup">
				<div
					className={plusClass}
					onClick={isPreview ? this.props.closePopup : null}
					data-test-id="flow-step-popup-preview-close"
				>
					{this.props.popup.edit ? <b className={editClass} /> : <b className="fa fa-plus" />}
				</div>
				{content}
			</div>
		);
	}
});
