'use strict';

import { checkPortedResolve } from 'App/helpers/angularRouteHelpers';

angular.module('domain.soliditet', ['ngRoute', 'upResources', 'security.authorization']).config([
	'$stateProvider',
	'$upModalProvider',
	function ($stateProvider, $upModalProvider) {
		var sectionFn = function (accountSelf) {
			if (!accountSelf.products.crm && accountSelf.features.soliditet) {
				return 'market';
			}
			return 'sale';
		};

		$stateProvider
			.state('findProspects', {
				templateProvider: [
					'$http',
					'AppService',
					function ($http, AppService) {
						return AppService.loadedPromise.then(function () {
							var url = 'upsales/domain/soliditet/views/findProspects.html';

							return $http.get(url).then(function (res) {
								return res.data;
							});
						});
					}
				],
				url: '/:customerId/find-prospects/',
				controllerProvider: function () {
					return 'FindProspectsCtrl as FindProspectsCtrl';
				},
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					multiSoliditetActivated: [
						'AppService',
						'$q',
						function (AppService, $q) {
							return AppService.loadedPromise.then(function () {
								var self = AppService.getSelf();
								var multiSoliditetActive = self.userParams.multiSoliditetAccess;
								if (self.administrator || multiSoliditetActive) {
									return $q.when(true);
								}
								return $q.reject(false);
							});
						}
					],
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.placeholder(FeatureHelper.Feature.SOLIDITET);
						}
					],
					clientForms: [
						'ClientForm',
						function (ClientForm) {
							return ClientForm.find({}).then(function (res) {
								var clientFormsGroupedByGroupId = _.groupBy(res.data, function (clientForm) {
									return clientForm.group_id || 10;
								});

								return _.chain(clientFormsGroupedByGroupId)
									.map(function (clientForms, groupId) {
										var obj = {
											value: _.pluck(clientForms, 'id'),
											name: 'default.typesBusinessCode' + groupId
										};

										switch (groupId) {
											case '2':
												obj.key = 'State';
												obj.sortId = 2;

												break;
											case '3':
												obj.sortId = 3;

												break;
											case '5':
												obj.sortId = 1;
												obj.key = 'AB';

												break;
											case '8':
												obj.sortId = 5;

												break;
											case '9':
												obj.sortId = 4;

												break;
											case '10':
												obj.sortId = 6;
												break;
										}

										return obj;
									})
									.sortBy('sortId')
									.value();
							});
						}
					],
					ratioOfAccountsWithDunsNos: [
						'Account',
						'RequestBuilder',
						'$q',
						'AppService',
						function (Account, RequestBuilder, $q, AppService) {
							return AppService.loadedPromise.then(function () {
								var customerId = AppService.getCustomerId();

								var withDunsNoFilters = new RequestBuilder();

								withDunsNoFilters.addFilter(
									{ field: 'dunsNo' },
									withDunsNoFilters.comparisonTypes.NotEquals,
									null
								);

								var or = withDunsNoFilters.orBuilder();
								or.next();
								var groupBuilder = or.groupBuilder();
								groupBuilder.addFilter(
									{ field: 'address.type' },
									withDunsNoFilters.comparisonTypes.Equals,
									'Visit'
								);
								groupBuilder.addFilter(
									{ field: 'address.country' },
									withDunsNoFilters.comparisonTypes.Equals,
									['DK', 'FI', 'NO', 'SE']
								);
								groupBuilder.done();
								or.next();
								groupBuilder = or.groupBuilder();
								groupBuilder.addFilter(
									{ field: 'address.type' },
									withDunsNoFilters.comparisonTypes.Equals,
									'Visit'
								);
								groupBuilder.addFilter(
									{ field: 'address.country' },
									withDunsNoFilters.comparisonTypes.Equals,
									null
								);
								groupBuilder.done();
								or.done();

								withDunsNoFilters.limit = 0;

								var allFilters = new RequestBuilder();

								or = allFilters.orBuilder();
								or.next();
								groupBuilder = or.groupBuilder();
								groupBuilder.addFilter(
									{ field: 'address.type' },
									withDunsNoFilters.comparisonTypes.Equals,
									'Visit'
								);
								groupBuilder.addFilter(
									{ field: 'address.country' },
									withDunsNoFilters.comparisonTypes.Equals,
									['DK', 'FI', 'NO', 'SE']
								);
								groupBuilder.done();
								or.next();
								groupBuilder = or.groupBuilder();
								groupBuilder.addFilter(
									{ field: 'address.type' },
									withDunsNoFilters.comparisonTypes.Equals,
									'Visit'
								);
								groupBuilder.addFilter(
									{ field: 'address.country' },
									withDunsNoFilters.comparisonTypes.Equals,
									null
								);
								groupBuilder.done();
								or.done();

								allFilters.limit = 0;

								var promisses = {
									hasDunsNo: Account.customer(customerId).find(withDunsNoFilters.build()),
									all: Account.customer(customerId).find(allFilters.build())
								};

								return $q.all(promisses).then(function (res) {
									var all = res.all.metadata.total;
									var withDunsNo = res.hasDunsNo.metadata.total;

									return {
										all: all,
										withDunsNo: withDunsNo,
										ratio: withDunsNo / all
									};
								});
							});
						}
					],
					segment: [
						'SegmentHelper',
						function (SegmentHelper) {
							SegmentHelper.track('View find new prospects', { module: SegmentHelper.module.bisnode });
						}
					]
				}
			})
			.state('matcher', {
				templateUrl: require('App/upsales/domain/soliditet/views/matcher.html?file'),
				url: '/:customerId/soliditet/matcher/?campaignId',
				controller: 'SoliditetMatcherCtrl as SoliditetMatcherCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return checkPortedResolve('matcher', () => $routeGuard.requireUser());
						}
					],
					multiSoliditetActivated: [
						'AppService',
						'$q',
						'FeatureHelper',
						function (AppService, $q, FeatureHelper) {
							return checkPortedResolve('matcher', () => {
								return AppService.loadedPromise.then(function () {
									const self = AppService.getSelf();

									const hasMatcherFromSoliditet = self.userParams.soliditetIsActive;
									const hasMatcherFromProspecting = FeatureHelper.isAvailable(
										FeatureHelper.Feature.PROSPECTING_BASIC
									);

									const hasMatcher =
										(hasMatcherFromSoliditet || hasMatcherFromProspecting) &&
										(self.userParams.multiSoliditetAccess || self.administrator);

									if (hasMatcher) {
										return $q.when(true);
									}
									return $q.reject(false);
								});
							});
						}
					],
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return checkPortedResolve('matcher', () =>
								FeatureHelper.placeholder(FeatureHelper.Feature.SOLIDITET_MATCHER)
							);
						}
					],
					segment: [
						'SegmentHelper',
						function (SegmentHelper) {
							return checkPortedResolve('matcher', () =>
								SegmentHelper.track('View bisnode matcher', { module: SegmentHelper.module.bisnode })
							);
						}
					]
				}
			});

		$upModalProvider
			.modal('confirmSoliditetUpdate', {
				templateUrl: require('App/upsales/domain/soliditet/views/confirmSoliditetUpdate.html?file'),
				controller: 'ConfirmSoliditetUpdate as ConfirmSoliditetUpdate',
				style: 'success confirm',
				resolve: {
					meta: [
						'$modalParams',
						'ConfirmSoliditetUpdateMeta',
						function ($modalParams, ConfirmSoliditetUpdateMeta) {
							return ConfirmSoliditetUpdateMeta($modalParams);
						}
					]
				}
			})
			.modal('confirmSoliditetBuyParent', {
				templateUrl: require('App/upsales/domain/soliditet/views/confirmSoliditetBuyParent.html?file'),
				controller: 'ConfirmSoliditetBuyParent as ConfirmSoliditetBuyParent',
				style: 'form',
				resolve: {
					meta: [
						'$modalParams',
						'ConfirmSoliditetBuyParentMeta',
						function ($modalParams, ConfirmSoliditetBuyParentMeta) {
							return ConfirmSoliditetBuyParentMeta($modalParams);
						}
					]
				}
			})
			.modal('clientListModal', {
				templateUrl: require('App/upsales/domain/soliditet/views/clientListModal.html?file'),
				controller: 'clientListModal as Ctrl',
				style: 'success confirm'
			})
			.modal('confirmBuyProspects', {
				templateUrl: require('App/upsales/domain/soliditet/views/confirmBuyProspects.html?file'),
				controller: 'ConfirmBuyProspects as Ctrl',
				style: 'form'
			})
			.modal('confirmBuyProspect', {
				templateUrl: require('App/upsales/domain/soliditet/views/confirmBuyProspect.html?file'),
				controller: 'ConfirmBuyProspects as Ctrl',
				style: 'form-sm'
			})
			.modal('UpdateClientDuns', {
				templateUrl: require('App/upsales/domain/soliditet/views/updateClientDuns.html?file'),
				controller: 'UpdateClientDuns as UpdateClientDuns',
				style: 'success confirm',
				resolve: {
					meta: [
						'$modalParams',
						'UpdateClientDunsMeta',
						function ($modalParams, UpdateClientDunsMeta) {
							return UpdateClientDunsMeta($modalParams);
						}
					]
				}
			});
	}
]);
