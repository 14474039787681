import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import reduxStore from 'Store';
import { EVENT_TYPES } from 'App/babel/enum/appointmentEvents';
import moment from 'moment';
import AppointmentEvents from 'App/resources/AppointmentEvents';
import AppointmentModal from './AppointmentModal';
import buildTitle from './AppointmentController/buildTitle';
import participantSelect from './AppointmentController/participantSelect';
import getAccount from './AppointmentController/getAccount';
import getOpportunity from './AppointmentController/getOpportunity';
import createCampaign from './AppointmentController/createCampaign';
import createOpportunity from './AppointmentController/createOpportunity';
import createContact from './AppointmentController/createContact';
import DataStore from 'Services/DataStore';
import logError from 'App/babel/helpers/logError';
import DateChange from './AppointmentController/dateAndTimeFunctions';
import { appointmentOutcomeTracker } from 'App/babel/helpers/Tracker';
import tippy from 'tippy.js';
import { comparisonTypes } from 'Resources/RequestBuilder';
import AppointmentResource from 'Resources/Appointment';
import documentResource from 'Resources/document';
import ValidationService from 'Services/ValidationService';
import CommentResource from 'Resources/Comment';
import openModal from 'App/services/Modal';
import { generateDeferredPromise } from 'App/babel/helpers/promise';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

const DEFAULT_STATE = {
	users: [],
	account: {},
	contacts: [],
	emailAttendees: [],
	appointment: {
		contacts: [],
		emailAttendees: []
	},
	existingAppointment: null,
	invitedContacts: {},
	invitedEmailAttendees: {},
	participants: [],
	options: {
		contacts: [],
		companies: [],
		campaigns: [],
		opportunities: [],
		appointmentTypes: [],
		outcomes: []
	},
	time: null,
	endTime: null,
	saving: false,
	savingType: '',
	editable: true,
	outcomeAnswered: false,
	hideOutcome: false,
	dateAvailability: null,
	currentAppointmentLength: 60,
	participantSelect: { data: [] },
	calendarAppointments: [],
	title: buildTitle({ store: null, Tools }),
	hasDocumentTemplates: false,
	documentTemplates: [],
	isAvailable: {},
	fieldErrors: {
		Description: false,
		Project: false,
		Notes: false,
		Startdate: false,
		Enddate: false,
		Type: false
	},
	loadingContacts: true,
	loadingUsers: true,
	promptRemoval: false,
	calendarIsOpenOnFirstLoad: true,
	contactOpened: null,
	dateDirty: { isInvalid: false, storeKey: null },
	timeDirty: { isInvalid: false, storeKey: null },
	links: [],
	loadinglinks: true
};

const fixContactsOnLoad = appointment => {
	if (appointment.contacts?.length) {
		appointment.contacts = appointment.contacts.map(contact => {
			contact.$id = `contact-${contact.id}`;

			return contact;
		});
	}

	return appointment;
};

const getInvitedContacts = appointment => {
	const invitedContacts = {};
	if (appointment.contacts?.length) {
		appointment.contacts.forEach(contact => {
			if (contact.isInvited) {
				invitedContacts[contact.id] = 1;
			}
		});
	}
	return invitedContacts;
};

const getInvitedEmailAttendees = appointment => {
	const invitedEmailAttendees = {};
	if (appointment.emailAttendees?.length) {
		appointment.emailAttendees.forEach(attendee => {
			if (attendee.isInvited) {
				invitedEmailAttendees[attendee.email] = 1;
			}
		});
	}
	return invitedEmailAttendees;
};

const setInviteOnContactsAndEmailAttendees = (appointment, invitedContacts, invitedEmailAttendees, shouldInvite) => {
	if (appointment?.contacts?.length) {
		appointment.contacts.forEach(contact => {
			if (shouldInvite) {
				contact.isInvited = 1;
			} else {
				contact.isInvited = Number(invitedContacts[contact.id] ?? 0);
			}
		});
	}
	if (appointment?.emailAttendees?.length) {
		appointment.emailAttendees.forEach(attendee => {
			if (shouldInvite) {
				attendee.isInvited = 1;
			} else {
				attendee.isInvited = Number(invitedEmailAttendees[attendee.email] ?? 0);
			}
		});
	}
};

const selectOption = (value, label, data, render, disabled = false) => {
	return { value, label, data, render, disabled };
};

const filterMyOpportunities = (data, users) => {
	const list = _.filter(data, item => {
		return !!_.find(users, { id: item.user.id });
	});

	return list;
};

const changeDate = (store, value, options = {}) => {
	try {
		const { appointment, currentAppointmentLength } = store.pluck('appointment', 'currentAppointmentLength');
		const dateProps = DateChange(
			{
				date: appointment.date,
				endDate: appointment.endDate,
				currentAppointmentLength: currentAppointmentLength
			},
			value,
			options
		);

		appointment.date = dateProps.date;
		appointment.endDate = dateProps.endDate;

		store.setStore({
			appointment,
			currentAppointmentLength: dateProps.currentAppointmentLength,
			dateAvailability: dateProps.date,
			isDirty: true
		});
	} catch (error) {
		console.warn(error.message);
	}
};

export const validateFields = (requiredFields, appointment, extra) => {
	let valid = true;
	const errors = { Notes: false, Project: false, Description: false, Startdate: false, Enddate: false, Type: false };
	let faultyField;

	// Validate customfields
	const fields = Tools.AppService.getCustomFields('appointment');
	fields.forEach(f => {
		if (f.obligatoryField && f.editable && f.visible && f.$hasAccess) {
			// Find value
			const fieldVal = _.find(appointment.custom, { fieldId: f.id });
			if (!fieldVal || !fieldVal.value || !fieldVal.value.toString().length) {
				// Invalid
				valid = false;
				faultyField = f.name;
				errors['Custom_' + f.id] = true;
			}
		}
	});

	// Validate standardfields
	if (requiredFields.Notes && (!appointment.notes || !appointment.notes.length)) {
		valid = false;
		faultyField = 'default.notes';
		errors.Notes = true;
	}
	if (requiredFields.Description && (!appointment.description || !appointment.description.length)) {
		valid = false;
		faultyField = 'default.description';
		errors.Description = true;
	}
	if (requiredFields.Project && !appointment.project) {
		valid = false;
		faultyField = 'default.project';
		errors.Project = true;
	}
	if (!appointment.activityType) {
		valid = false;
		faultyField = 'default.activityType';
		errors.Type = true;
	}

	if (extra.timeDirty && extra.timeDirty.isInvalid) {
		valid = false;

		if (extra.timeDirty.storeKey === 'date') {
			faultyField = 'default.startDate';
			errors.Startdate = true;
		}

		if (extra.timeDirty.storeKey === 'endDate') {
			faultyField = 'default.endDate';
			errors.Enddate = true;
		}
	}

	if (extra.dateDirty && extra.dateDirty.isInvalid) {
		valid = false;

		if (extra.dateDirty.storeKey === 'date') {
			faultyField = 'default.startDate';
			errors.Startdate = true;
		}

		if (extra.dateDirty.storeKey === 'endDate') {
			faultyField = 'default.endDate';
			errors.Enddate = true;
		}
	}

	if (!valid) {
		Tools.NotificationService.addNotification({
			style: Tools.NotificationService.style.WARN,
			icon: 'warning',
			title: 'default.error',
			body: `${Tools.$translate('default.youHaveFormErrorsMissing')} ${Tools.$translate(
				faultyField
			).toLowerCase()}`
		});
	}

	return { valid, errors };
};

const saveAppointment = async (store, followup, inviteContacts) => {
	store.setStore({ saving: true, fieldErrors: {} });
	const { waitForPromises = {} } = store.pluck('waitForPromises');
	await Promise.all(Object.values(waitForPromises).map(p => p.promise));

	const {
		appointment,
		requiredFields,
		timeDirty,
		dateDirty,
		reschedule: isRescheduling,
		invitedContacts,
		invitedEmailAttendees
	} = store.pluck(
		'appointment',
		'requiredFields',
		'timeDirty',
		'dateDirty',
		'reschedule',
		'invitedContacts',
		'invitedEmailAttendees'
	);

	// check required Fields
	const fieldValidation = validateFields(requiredFields, appointment, { timeDirty, dateDirty });

	if (!fieldValidation.valid) {
		store.setStore({
			saving: false,
			fieldErrors: fieldValidation.errors,
			savingType: ''
		});
		return Promise.reject();
	}

	return new Promise(resolve => {
		Tools.ScriptService.appointment
			.save(appointment)
			.then(() => {
				appointment.description = appointment.description || store.get('defaultDesc');
				setInviteOnContactsAndEmailAttendees(
					appointment,
					invitedContacts,
					invitedEmailAttendees,
					inviteContacts
				);
				AppointmentResource.save(appointment, { params: { saveOutcomeAction: isRescheduling } })
					.then(response => {
						const { isDirty, options } = store.pluck('isDirty', 'options');

						if (!isDirty) {
							resolve({ appointment: response.data, shouldResolve: true });
						}

						const opportunitiesByUser = filterMyOpportunities(options.opportunities, appointment.users);

						if (
							!appointment.id &&
							!appointment.opportunity &&
							opportunitiesByUser.length > 0 &&
							!followup
						) {
							store.setStore({
								appointment: response.data,
								isDirty: false,
								created: true
							});

							store.set('saving', false);
							store.set('savingType', '');
							resolve({ appointment: response.data, shouldResolve: false });
						} else {
							store.set('isDirty', false);
							resolve({ appointment: response.data, shouldResolve: true });
						}
					})
					.catch(() => {
						store.set('saving', false);
						store.set('savingType', '');
					});
			})
			.catch(() => {
				store.set('saving', false);
				store.set('savingType', '');
			});
	});
};

const saveAppointmentNotes = async (store, notes) => {
	const appointment = store.get('appointment');
	store.setStore({ appointment: { ...appointment, notes } });
	const data = { id: appointment.id, notes };
	await AppointmentResource.save(data, { skipNotification: true, skipEvent: true });
};

export const resetCompanyScore = (clientId, customerId) => {
	Tools.ResetScore.customer(customerId).reset({
		clientId,
		userId: Tools.AppService.getSelf().id,
		customerId,
		synch: false
	});
};

const trackOutcome = outcome => {
	appointmentOutcomeTracker.track(appointmentOutcomeTracker.events.SET_OUTCOME, {
		location: 'modal',
		outcome
	});
};

function AppointmentCtrl(scope, modalParams, utils) {
	let rootNode;
	let store;
	const hasSubaccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');

	scope.$on('opportunity.added', (event, opportunity) => {
		const { appointment, options } = store.pluck('appointment', 'options');
		if (appointment?.client?.id === opportunity?.client?.id) {
			options.opportunities.push(opportunity);
			store.set('options', options);
		}
	});

	scope.$on('$destroy', () => {
		ReactDOM.unmountComponentAtNode(rootNode);
		rootNode = undefined;
	});

	const actions = {
		dateTimeDirty: () => {
			/*
				If someone tries to set a dirty time what will happen is just that you will get the old one
				so no need to update dirty state here
			*/
		},
		closeCalendar: () => {
			store.set('calendarIsOpenOnFirstLoad', false);
		},
		close: () => {
			if (store.get('isDirty')) {
				// Adding this here, but I'm not sure we actually get here anymore, since AppointmentModal handles the dirty check
				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('UnsavedChangesAlert', {
						onClose: async confirmed => {
							if (confirmed === undefined) {
								return;
							}
							if (confirmed) {
								await saveAppointment(store, true);
							}
							scope.reject();
						}
					});
					return;
				}

				Tools.$upModal
					.open('infoConfirm', {
						title: 'default.saveChanges',
						body: 'confirm.changesWillBeLost',
						icon: 'fa-exclamation-triangle',
						resolveFalse: 'default.save',
						resolveFalseBtnClass: 'btn-bright-blue',
						resolveTrue: 'default.discardChanges',
						resolveTrueBtnClass: 'btn-bright-blue btn-lined'
					})
					.then(function (skipSave) {
						if (skipSave === false) {
							saveAppointment(store, true);
							scope.reject();
						} else {
							scope.reject();
						}
					})
					.catch(error => {
						console.warn(error);
					});
			} else {
				scope.reject();
			}
		},
		reloadModalPosition: () => {
			scope.reloadModalPosition();
		},
		discardFromInline: () => {
			scope.reject();
		},
		hideOutcome: () => {
			store.set('hideOutcome', true);
		},
		setJourneyStep: (entity, object, journeyStep) => {
			const { customerId } = store.pluck('appointment', 'customerId');
			const Resource = { contact: Tools.Contact, client: Tools.Account };

			return Resource[entity]
				.customer(customerId)
				.save({
					id: object.id,
					journeyStep: journeyStep
				})
				.then(res => {
					if (entity === 'contact') {
						const currentContacts = store.get('appointment.contacts');
						const contactIndex = _.findIndex(currentContacts, { id: object.id });

						object.journeyStep = res.data.journeyStep;
						currentContacts.splice(contactIndex, 1, object);

						store.set('appointment.contacts', currentContacts);
					} else {
						object.journeyStep = res.data.journeyStep;
						store.set('account', object);
					}
				});
		},
		qualifyCompany: () => {
			const { appointment, customerId } = store.pluck('appointment', 'customerId');

			store.set('saving', true);

			Tools.Account.customer(customerId)
				.save(
					{
						id: appointment.client.id,
						journeyStep: 'sql'
					},
					{
						skipNotification: true
					}
				)
				.then(function () {
					appointment.client.journeyStep = 'sql';
					store.setStore({ appointment, saving: false });
				})
				.catch(err => {
					logError(err, 'Error saving account');
				});
		},
		disqualifyCompany: () => {
			const { appointment, customerId } = store.pluck('appointment', 'customerId');

			store.set('saving', true);
			Tools.Account.customer(customerId)
				.save(
					{
						id: appointment.client.id,
						journeyStep: 'disqualified'
					},
					{
						skipNotification: true
					}
				)
				.then(function () {
					appointment.client.journeyStep = 'disqualified';
					store.setStore({ appointment, saving: false });
					resetCompanyScore(appointment.client.id, customerId);
				})
				.catch(err => {
					logError(err, 'Error saving account');
				});
		},
		answerOutcome: isCompleted => {
			const { appointment, customerId } = store.pluck('appointment', 'customerId');

			appointment.outcome = isCompleted ? 'completed' : 'notCompleted';
			store.setStore({ appointment, outcomeAnswered: true });

			if (appointment.id) {
				if (!Tools.FeatureHelper.hasSoftDeployAccess('NEW_APPOINTMENT_OUTCOME_EVENTS')) {
					store.set('saving', true);
					Tools.Appointment.customer(customerId)
						.save({ id: appointment.id, outcome: appointment.outcome }, { skipNotification: true })
						.then(() => {
							store.set('saving', false);
							trackOutcome(appointment.outcome);
						})
						.catch(err => {
							logError(err, 'Error saving appointment');
						});
				}
			}
		},
		resolve: scope.resolve,
		firstBackSpace: bool => {
			const participants = store.get('participants');
			const lastParticipant = participants[participants.length - 1];
			if (lastParticipant) {
				lastParticipant.isInRemoveState = true;
				const updatedStore = { firstBackSpace: bool, participants };
				store.setStore(updatedStore);
			}
		},
		setContactOpened: value => store.set('contactOpened', value),
		link: url => {
			if (store.get('isDirty')) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('UnsavedChangesAlert', {
						onClose: async confirmed => {
							if (confirmed === undefined) {
								return;
							}
							if (confirmed) {
								await saveAppointment(store, true);
							}
							window.location.href = url;
						}
					});
					return;
				}

				// eslint-disable-next-line promise/catch-or-return
				Tools.$upModal
					.open('warningConfirm', {
						title: 'cancel',
						body: 'confirm.abortEdit',
						resolveTrue: 'default.abortEdit',
						no: 'default.returnToEdit',
						icon: 'fa-warning'
					})
					.then(function () {
						window.location.href = url;
					});
			} else {
				window.location.href = url;
			}
		},
		openContact: (event, url) => {
			event.preventDefault();
			if (store.get('isDirty')) {
				Tools.$upModal
					.open('infoConfirm', {
						title: 'default.saveChanges',
						body: 'confirm.changesWillBeLost',
						icon: 'fa-exclamation-triangle',
						resolveFalse: 'default.save',
						resolveFalseBtnClass: 'btn-bright-blue',
						resolveTrue: 'default.discardChanges',
						resolveTrueBtnClass: 'btn-bright-blue btn-lined'
					})
					.then(function (skipSave) {
						if (skipSave === false) {
							saveAppointment(store, true);
							window.location.href = url;
						} else {
							window.location.href = url;
						}
					})
					.catch(error => {
						console.warn(error);
					});
			} else {
				window.location.href = url;
			}
		},
		checkAgainstFieldErrors: (key, value, field) => {
			const fieldErrors = store.get('fieldErrors');

			let fieldErrorKey = key[0].toUpperCase() + key.substring(1);
			if (key === 'custom') {
				fieldErrorKey = 'Custom_' + field.id;
			}

			if (fieldErrors[fieldErrorKey] === true && !!value) {
				fieldErrors[fieldErrorKey] = false;
			}

			store.set('fieldErrors', fieldErrors);
		},
		addWaitForPromise: key => {
			const waitForPromises = store.get('waitForPromises') || {};
			waitForPromises[key] = generateDeferredPromise();
			store.set('waitForPromises', waitForPromises);
		},
		changeAppointmentParam: (key, value, isDirty) => {
			const appointment = store.get('appointment');

			if (key === 'agenda') {
				appointment.isAgendaDirty = isDirty;
			} else if (key === 'activityType') {
				actions.checkAgainstFieldErrors('type', value);
			}
			if (appointment[key] !== value) {
				appointment[key] = value;
				// Don't change dirty state if the edited field is "notes" - which is autosaved
				store.setStore({ appointment, ...(key === 'notes' && appointment?.id > 0 ? {} : { isDirty: true }) });
			}

			const waitForPromises = store.get('waitForPromises') || {};
			if (waitForPromises[key]) {
				waitForPromises[key]?.resolve?.();
			}
		},
		participantChange: value => {
			const appointment = store.get('appointment');

			const newState = {
				isDirty: true,
				participants: value.participants,
				appointment: appointment
			};

			if ((!appointment.contacts || !appointment.contacts.length) && value.contacts.length) {
				newState.contactOpened = value.contacts[0].id;
			}
			appointment.contacts = value.contacts;
			appointment.users = value.users;

			store.setStore(newState);
		},
		addEmailAttendee: email => {
			const appointment = store.get('appointment');
			if (!appointment.client?.id || !email || !ValidationService.validEmail(email)) {
				console.warn('No client or valid email, not adding email attendee');
				return;
			}
			if (appointment.emailAttendees?.find(attendee => attendee.email === email)) {
				return;
			}

			const newState = {
				isDirty: true,
				appointment: appointment
			};

			appointment.emailAttendees = [...(appointment.emailAttendees ?? []), { email, isInvited: 0 }];
			store.setStore(newState);
		},
		removeEmailAttendee: email => {
			const appointment = store.get('appointment');
			appointment.emailAttendees = appointment.emailAttendees.filter(attendee => attendee.email !== email);
			const newState = {
				isDirty: true,
				appointment: appointment
			};
			store.setStore(newState);
		},
		clearEmailAttendees: () => {
			const appointment = store.get('appointment');
			appointment.emailAttendees = [];
			const newState = {
				isDirty: true,
				appointment: appointment
			};
			store.setStore(newState);
		},
		setIsRescheduling: reschedule => {
			store.set('reschedule', reschedule);
		},
		isRescheduling: () => {
			if (!store) {
				return false;
			}
			return store.get('reschedule');
		},
		changeCustom: (field, value, skipSetDirty) => {
			const appointment = store.get('appointment');

			actions.checkAgainstFieldErrors('custom', value, field);
			appointment.custom = _.map(appointment.custom, item => {
				if (item.id === field.id) {
					item.value = value;
				}

				return item;
			});

			if (skipSetDirty) {
				return store.setStore({ appointment });
			}

			store.setStore({
				appointment,
				isDirty: true
			});
		},
		changeDayInAvailabilityTable: date => {
			store.setStore({
				dateAvailability: moment(date).toDate(),
				isDirty: true
			});
		},
		changeStartDate: (date, opts) => {
			changeDate(store, date, opts);
			store.set('calendarIsOpenOnFirstLoad', false);
		},
		changeStartTime: date => {
			changeDate(store, moment(date, 'L LT'), { setTime: true });
		},
		changeEndDate: date => {
			changeDate(store, date, { setEnd: true });
		},
		changeEndTime: date => {
			changeDate(store, moment(date, 'L LT'), { setEnd: true, setTime: true });
		},
		titleBuilder: injectedStore => {
			if (injectedStore) {
				injectedStore.title = buildTitle({ store: injectedStore, Tools });
				return injectedStore.title;
			}

			store.set('title', buildTitle({ store: null, Tools }).join(' '));
		},
		extendOpportunity: value => {
			getOpportunity({ opportunity: value })
				.then(OPPORTUNITY_DATA => {
					const appointment = store.get('appointment');
					appointment.opportunity = { ...appointment.opportunity, ...OPPORTUNITY_DATA };
					store.setStore({ appointment });
				})
				.catch(err => {
					logError(err, 'Error getting opportunity');
				});
		},
		accountChange: (value, init) => {
			if (!value) {
				const { appointment, options, participantSelect } = store.pluck(
					'appointment',
					'options',
					'participantSelect'
				);

				let participants = store.get('participants');

				appointment.contacts = [];
				appointment.emailAttendees = [];
				appointment.client = null;

				participantSelect.data[0].children = [];
				participantSelect.data[2].children = [];
				participants = participants.filter(p => {
					return p.hasOwnProperty('role');
				});

				options.contacts = [];
				store.setStore({
					account: null,
					appointment,
					participantSelect,
					contacts: [],
					options,
					participants
				});
			} else {
				const appointment = store.get('appointment');
				const previousAccount = store.get('account');

				if (!init) {
					appointment.contacts = [];
					appointment.emailAttendees = [];
				}
				appointment.client = value;
				store.setStore({
					account: value,
					appointment
				});

				if (previousAccount && previousAccount.hasOwnProperty('id') && previousAccount.id !== value.id) {
					// New account selected. Filter out non user participants
					const participants = store.get('participants');
					store.setStore({ participants: _.filter(participants, p => p.hasOwnProperty('role')) });
				}

				getAccount({ client: value })
					.then(ACCOUNT_DATA => {
						const appointment = store.get('appointment');
						if (!init) {
							appointment.contacts = [];
						}
						appointment.client = ACCOUNT_DATA;
						store.setStore({
							account: ACCOUNT_DATA,
							appointment
						});

						actions
							.getOpportunities(ACCOUNT_DATA)
							.then(opportunities => {
								const { appointment, options } = store.pluck('appointment', 'options');
								options.opportunities = opportunities;

								/* Dont do this on initial load of an edit */
								if (!(appointment.id && init)) {
									if (appointment.opportunity && appointment.opportunity.id) {
										appointment.opportunity =
											_.find(opportunities, {
												id: appointment.opportunity.id
											}) || appointment.opportunity;
									} else if (opportunities && opportunities.length === 1) {
										appointment.opportunity = opportunities[0];
										store.set('isDirty', true);
									}
								}

								store.setStore({
									appointment,
									options
								});
							})
							.catch(err => {
								logError(err, 'Error getting opportunities');
							});
					})
					.catch(err => {
						logError(err, 'Error getting account');
					});

				const operationalAccountId = hasSubaccounts ? value.operationalAccount?.id : undefined;
				participantSelect(value.id, store.get('users'), utils, operationalAccountId)
					.then(response => {
						const existingOpts = store.get('options');
						existingOpts.contacts = actions.makeOptions(response.contacts);

						const contacts = response.object.data[0].children;
						response.object.data[0].children = contacts.reduce((res, contact) => {
							if (!res.find(c => c.id === contact.id)) {
								res.push(contact);
							}
							return res;
						}, []);

						store.setStore({
							participantSelect: response.object,
							contacts: response.contacts,
							loadingContacts: false,
							options: existingOpts
						});
					})
					.catch(err => {
						logError(err, 'Error selecting participant');
					});
			}
		},
		makeOptions: (type, array) => {
			if (Array.isArray(type)) {
				return actions.transformOptions(array);
			}

			store.set(`options.${type}`, actions.transformOptions(array));
		},
		transformOptions: options =>
			_.reduce(
				options,
				(res, el) => {
					const parent = selectOption(el.name, el.name, el, <strong>{el.name}</strong>, true);
					const children = _.map(el.children, child => {
						return _.assign(
							{},
							selectOption(
								child.id,
								child.name,
								child,
								<span style={{ paddingLeft: 10 }}>{child.name}</span>
							),
							{ parent: el.name }
						);
					});

					// remove children that is already selected
					const filteredChildren = _.filter(children, child => {
						return !_.find(store.get('participants'), item => item.name === child.label);
					});

					return res.concat(parent).concat(filteredChildren);
				},
				[]
			),
		updateAppointmentLength: (returnInstead, init) => {
			const appointment = store.get('appointment');
			let currentAppointmentLength = moment(appointment.endDate).diff(appointment.date, 'minutes');

			if (currentAppointmentLength === 0) {
				currentAppointmentLength = 15;
			}

			if (returnInstead) {
				return currentAppointmentLength;
			}

			store.setStore({
				currentAppointmentLength,
				isDirty: !init
			});
		},
		createCampaign: () => {
			const { appointment, customerId } = store.pluck('appointment', 'customerId');
			createCampaign(customerId, appointment)
				.then(newCampaign => {
					const appointment = store.get('appointment');
					appointment.project = newCampaign;
					store.setStore({ appointment, isDirty: true });
				})
				.catch(err => {
					logError(err, 'Error creating campaign');
				});
		},
		createOpportunity: (isOrder = false, comment, skipTimelineRow) => {
			const { appointment, customerId, invitedContacts, invitedEmailAttendees } = store.pluck(
				'appointment',
				'customerId',
				'invitedContacts',
				'invitedEmailAttendees'
			);
			return new Promise((resolve, reject) => {
				return createOpportunity(customerId, appointment, isOrder)
					.then(async newOpportunity => {
						const appointment = store.get('appointment');
						appointment.opportunity = newOpportunity;
						store.setStore({ appointment, isDirty: true });

						if (appointment.id) {
							const hasNewEvents =
								Tools.FeatureHelper.hasSoftDeployAccess('NEW_APPOINTMENT_OUTCOME_EVENTS') &&
								Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
							store.set('saving', true);
							let saveFunc;
							if (!skipTimelineRow) {
								saveFunc = actions.saveAppointmentWithAction;
							} else {
								saveFunc = data => {
									setInviteOnContactsAndEmailAttendees(
										data,
										invitedContacts,
										invitedEmailAttendees,
										false
									);
									return AppointmentResource.save(data);
								};
							}
							let outcomeCommentId;
							if (comment) {
								try {
									({ id: outcomeCommentId } = await actions.saveComment(comment));
								} catch (err) {
									logError(err, 'Could not save comment');
								}
							}
							saveFunc(
								hasNewEvents
									? {
											id: appointment.id,
											outcome: appointment.outcome,
											outcomeAction: isOrder
												? EVENT_TYPES.ORDERCREATED
												: EVENT_TYPES.OPPORTUNITYCREATED,
											outcomeExtra: JSON.stringify({
												id: newOpportunity.id,
												description: newOpportunity.description
											}),
											opportunity: appointment.opportunity,
											...(outcomeCommentId ? { outcomeCommentId } : {})
									  }
									: {
											...appointment,
											outcomeAction: isOrder
												? EVENT_TYPES.ORDERCREATED
												: EVENT_TYPES.OPPORTUNITYCREATED
									  }
							)
								.then(() => {
									store.set('saving', false);
									resolve();
								})
								.catch(err => {
									logError(err, 'Error saving appointment');
									reject();
								});
						}
					})
					.catch(createOpportunityError => {
						console.warn(createOpportunityError);
						reject();
					});
			});
		},
		createContact: () => {
			const { account, customerId } = store.pluck('account', 'customerId');
			createContact(customerId, account)
				.then(newContact => {
					if (newContact.active) {
						const { appointment, participantSelect, participants } = store.pluck(
							'appointment',
							'participantSelect',
							'participants'
						);
						newContact.$id = `contact-${newContact.id}`;

						participantSelect.data[0].children.push(newContact);

						participants.push(newContact);

						if (Array.isArray(appointment.contacts)) {
							appointment.contacts.push(newContact);
						} else {
							appointment.contacts = [newContact];
						}

						store.setStore({
							participantSelect,
							participants,
							appointment,
							isDirty: true
						});
					}
				})
				.catch(err => {
					logError(err, 'Error creating contact');
				});
		},
		getAppointments: (month, returnInstead) => {
			let MOMENT_OBJECT;

			if (month) {
				MOMENT_OBJECT = moment(month);
			} else {
				MOMENT_OBJECT = moment(store.get('appointment.date'));
			}

			const START_DATE = MOMENT_OBJECT.clone().subtract(1, 'month').startOf('month').toDate();
			const END_DATE = MOMENT_OBJECT.clone().add(1, 'month').endOf('month').toDate();
			const AppointmentAttrs = Tools.Appointment.attr;

			var rb = new Tools.RequestBuilder();
			rb.addFilter(AppointmentAttrs.users, rb.comparisonTypes.Equals, Tools.AppService.getSelf().id);

			var orBuilder = rb.orBuilder();
			orBuilder.next();
			// Current appointment intercept with end of another one
			orBuilder.addFilter(AppointmentAttrs.date, rb.comparisonTypes.GreaterThanEquals, START_DATE);
			orBuilder.addFilter(AppointmentAttrs.date, rb.comparisonTypes.LessThan, END_DATE);
			orBuilder.next();
			// Current appointment spans over another one
			orBuilder.addFilter(AppointmentAttrs.date, rb.comparisonTypes.GreaterThanEquals, START_DATE);
			orBuilder.addFilter(AppointmentAttrs.endDate, rb.comparisonTypes.LessThan, END_DATE);
			orBuilder.next();
			// Current appointment intercepts with the end of another one
			orBuilder.addFilter(AppointmentAttrs.date, rb.comparisonTypes.LessThanEquals, START_DATE);
			orBuilder.addFilter(AppointmentAttrs.endDate, rb.comparisonTypes.LessThanEquals, END_DATE);
			orBuilder.addFilter(AppointmentAttrs.endDate, rb.comparisonTypes.GreaterThan, START_DATE);
			orBuilder.next();
			// Current appointment start and ends during another one
			orBuilder.addFilter(AppointmentAttrs.date, rb.comparisonTypes.LessThan, START_DATE);
			orBuilder.addFilter(AppointmentAttrs.endDate, rb.comparisonTypes.GreaterThan, END_DATE);
			orBuilder.done();

			if (returnInstead) {
				return new Promise(resolve => {
					Tools.Appointment.customer(Tools.AppService.getCustomerId())
						.find(rb.build())
						.then(function (res) {
							return resolve(res.data);
						})
						.catch(err => {
							logError(err, 'Error finding appointment');
						});
				});
			} else {
				Tools.Appointment.customer(Tools.AppService.getCustomerId())
					.find(rb.build())
					.then(function (res) {
						store.set('calendarAppointments', res.data);
					})
					.catch(err => {
						logError(err, 'Error finding appointment');
					});
			}
		},
		getOpportunities: CLIENT => {
			return new Promise((resolve, reject) => {
				if (CLIENT && CLIENT.id) {
					const Order = Tools.Order;
					const OrderAttr = Order.attr;
					const opportunityFilter = new Tools.RequestBuilder();
					const eq = opportunityFilter.comparisonTypes.Equals;
					const neq = opportunityFilter.comparisonTypes.NotEquals;

					opportunityFilter.addFilter(OrderAttr.account, eq, CLIENT.id);
					opportunityFilter.addFilter(OrderAttr.probability, neq, 100);
					opportunityFilter.addFilter(OrderAttr.probability, neq, 0);

					Order.customer(Tools.AppService.getCustomerId())
						.find(opportunityFilter.build())
						.then(response => {
							return resolve(response.data);
						})
						.catch(err => {
							logError(err, 'Error finding order');
						});
				} else {
					return reject('no appointment client..');
				}
			});
		},
		saveComment: async comment => {
			const appointment = store.get('appointment');
			const { data: createdComment } = await CommentResource.save({
				user: { id: Tools.AppService.getSelf().id },
				description: comment,
				client: appointment.client,
				appointment,
				outcomeType: appointment.outcome
			});
			return createdComment;
		},
		createFollowUp: async (isActivity, shouldCreateEvent, comment) => {
			const hasNewAppOutcomeEvents =
				Tools.FeatureHelper.hasSoftDeployAccess('NEW_APPOINTMENT_OUTCOME_EVENTS') &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
			const appointment = store.get('appointment');
			const getNewEntity = (response = {}) => {
				let item;

				if (isActivity) {
					item = Tools.Activity.new().data;
					item.description = appointment.description;
					item.contacts = appointment.contacts ? appointment.contacts[0] : null;
				} else {
					item = Tools.Appointment.new().data;
					item.activityType = null;
					item.custom = response.custom;
					item.sourceType = 'appointment';
					item.sourceId = appointment.id;
					item.contacts = appointment.contacts;
					if (item.contacts?.length) {
						item.contacts = item.contacts.map(contact => ({ ...contact, isInvited: 0 }));
					}
					item.emailAttendees = appointment.emailAttendees;
					if (item.emailAttendees?.length) {
						item.emailAttendees = item.emailAttendees.map(attendee => ({ ...attendee, isInvited: 0 }));
					}
				}

				item.notes = appointment.notes;
				item.client = appointment.client;
				item.project = appointment.project;
				item.users = [_.pick(Tools.AppService.getSelf(), ['id', 'name', 'role', 'email'])];

				if (appointment.closeDate) {
					actions.resolve(appointment);
				}
				return item;
			};
			const openActivityModal = (appointment, item = appointment) => {
				// eslint-disable-next-line promise/catch-or-return
				return Tools.$upModal.open('editActivity', { activity: item }).then(async res => {
					if (shouldCreateEvent && appointment.id) {
						if (hasNewAppOutcomeEvents) {
							let outcomeCommentId;
							if (comment) {
								try {
									({ id: outcomeCommentId } = await actions.saveComment(comment));
								} catch (err) {
									logError(err, 'Could not save comment');
								}
							}
							actions.saveAppointmentWithAction({
								id: appointment.id,
								outcome: appointment.outcome,
								outcomeAction: EVENT_TYPES.ACTIVITYCREATED,
								outcomeExtra: JSON.stringify({ id: res.id, description: res.description }),
								...(outcomeCommentId ? { outcomeCommentId } : {})
							});
						} else {
							AppointmentEvents.createEvent(appointment.id, {
								action: EVENT_TYPES.ACTIVITYCREATED,
								fields: { activity: { id: res.id, description: res.description } }
							});
						}
					}
				});
			};
			const openAppointmentModal = (appointment, item) => {
				// eslint-disable-next-line promise/catch-or-return
				return openEditAppointment({ appointment: item }).then(async res => {
					if (shouldCreateEvent && appointment.id) {
						if (hasNewAppOutcomeEvents) {
							let outcomeCommentId;
							if (comment) {
								try {
									({ id: outcomeCommentId } = await actions.saveComment(comment));
								} catch (err) {
									logError(err, 'Could not save comment');
								}
							}
							actions.saveAppointmentWithAction({
								id: appointment.id,
								outcome: appointment.outcome,
								outcomeAction: EVENT_TYPES.APPOINTMENTCREATED,
								outcomeExtra: JSON.stringify({ id: res.id, description: res.description }),
								...(outcomeCommentId ? { outcomeCommentId } : {})
							});
						} else {
							AppointmentEvents.createEvent(appointment.id, {
								action: EVENT_TYPES.APPOINTMENTCREATED,
								fields: {
									appointment: { id: res.id, description: res.description }
								}
							});
						}
					}
				});
			};
			try {
				if (hasNewAppOutcomeEvents) {
					if (isActivity) {
						return openActivityModal(appointment, getNewEntity());
					} else {
						return openAppointmentModal(appointment, getNewEntity());
					}
				} else {
					const response = await saveAppointment(store, true);
					if (!appointment.id) {
						store.set('appointment.id', response.id);
					}
					if (isActivity) {
						await openActivityModal(appointment, getNewEntity(response));
					} else {
						await openAppointmentModal(appointment, getNewEntity(response));
					}
					store.set('saving', false);
					return;
				}
			} catch (err) {
				// eslint-disable-next-line no-empty
			}
		},
		createDocument: template => {
			const params = {
				entityId: store.get('appointment.id'),
				templateId: template.id,
				type: 'appointment',
				name: template.name
			};

			if (!store.get('isDirty')) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
					return openModal('PreviewPdfModal', {
						isUploadedTemplate: true,
						documentResource: {
							resource: documentResource,
							entityId: params.entityId,
							templateId: params.templateId,
							type: params.type,
							documentName: params.name,
							accountId: params.accountId,
							contact: params.contact,
							contactId: params.contactId
						}
					});
				} else {
					return Tools.$upModal.open('pdfPreview', params);
				}
			}

			store.set('saving', true);

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('UnsavedChangesAlert', {
					title: 'default.saveChanges',
					body: 'confirm.saveAndCreateDocument',
					cancelButtonText: 'default.abort',
					onClose: confirmed => {
						if (confirmed) {
							actions
								.saveAppointment()
								.then(function () {
									store.setStore({ saving: false, isDirty: false });
									if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
										openModal('PreviewPdfModal', {
											isUploadedTemplate: true,
											documentResource: {
												resource: documentResource,
												entityId: params.entityId,
												templateId: params.templateId,
												type: params.type,
												documentName: params.name,
												accountId: params.accountId,
												contact: params.contact,
												contactId: params.contactId
											}
										});
									} else {
										Tools.$upModal.open('pdfPreview', params);
									}
								})
								.catch(err => {
									logError(err, 'Error saving appointment');
								});
						} else {
							store.setStore({ saving: false });
						}
					}
				});
				return;
			}

			Tools.$upModal
				.open('warningConfirm', {
					title: 'default.saveChanges',
					body: 'confirm.saveAndCreateDocument',
					resolveFalse: 'default.save',
					resolveFalseBtnClass: 'btn-submit',
					no: 'default.abort',
					icon: 'fa-warning'
				})
				.then(function () {
					actions
						.saveAppointment()
						.then(function () {
							store.setStore({ saving: false, isDirty: false });
							if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
								openModal('PreviewPdfModal', {
									isUploadedTemplate: true,
									documentResource: {
										resource: documentResource,
										entityId: params.entityId,
										templateId: params.templateId,
										type: params.type,
										documentName: params.name,
										accountId: params.accountId,
										contact: params.contact,
										contactId: params.contactId
									}
								});
							} else {
								Tools.$upModal.open('pdfPreview', params);
							}
						})
						.catch(err => {
							logError(err, 'Error saving appointment');
						});
				})
				.catch(() => {});
		},
		promptRemoval: opts => {
			if (opts.closePrompt) {
				store.set('promptRemoval', false);
			} else {
				store.set('promptRemoval', true);
			}
		},
		deleteAppointment: () => {
			const APPOINTMENT = store.get('appointment');

			if (APPOINTMENT.userRemovable) {
				scope.resolve();
				Tools.Appointment.customer(Tools.AppService.getCustomerId()).delete(APPOINTMENT);
			}
		},
		saveAppointment: followup => {
			return saveAppointment(store, followup).then(({ appointment, shouldResolve }) => {
				if (shouldResolve) {
					actions.resolve(appointment);
				}

				return { appointment, openSelectOpportunity: true };
			});
		},
		saveSelectedOpportunity: opportunity => {
			const { appointment, customerId } = store.pluck('appointment', 'customerId');
			Tools.Appointment.customer(customerId)
				.save({ id: appointment.id, opportunity: opportunity })
				.then(response => {
					return actions.resolve(response.data);
				})
				.catch(err => console.error(err));
		},
		saveAppointmentWithFiles: (newFiles, deletedFiles, inviteContacts) => {
			return saveAppointment(store, false, inviteContacts).then(({ appointment, shouldResolve }) => {
				return Promise.all([
					[...newFiles].map(file =>
						Tools.File.upload(file, {
							fileEntity: Tools.File.entityTypes.APPOINTMENT,
							fileId: appointment.id
						})
					),
					[...deletedFiles].map(file =>
						Tools.File.delete(file, {
							noConfirm: true
						})
					)
				])
					.then(() => {
						if (shouldResolve) {
							actions.resolve(appointment);
						}

						return { appointment, openSelectOpportunity: true };
					})
					.catch(err => {
						logError(err, 'Error saving appointment');
					});
			});
		},
		saveAppointmentNotes: notes => saveAppointmentNotes(store, notes),
		getContacts: () => {
			let appointmentContacts = store.get('appointment.contacts');
			if (!Array.isArray(appointmentContacts)) appointmentContacts = [];

			const contactIds = appointmentContacts.map(o => o.id);

			const rb = new Tools.RequestBuilder();
			rb.addFilter(Tools.Contact.attr.id, 'eq', contactIds);

			Tools.Contact.find(rb.build())
				.then(response => {
					if (Array.isArray(response.data)) {
						const contactMap = response.data?.reduce((map, contact) => {
							map[contact.id] = contact;
							return map;
						}, {});
						const fullInfoContacts = appointmentContacts.map(contact => ({
							...contact,
							...(contactMap[contact.id] ?? {})
						}));
						store.set('appointment.contacts', fullInfoContacts);
					} else {
						store.set('appointment.contacts', response.data);
					}
				})
				.catch(err => {
					logError(err, 'Error finding contact');
				});
		},
		getLinks: () => {
			const appointmentId = store.get('appointment.id');
			if (appointmentId) {
				Tools.Links.customer(Tools.AppService.getCustomerId())
					.get('appointment', appointmentId)
					.then(response => {
						store.set('links', response.data);
					})
					.catch(error => {
						store.set('linksError', error);
					})
					.finally(() => store.set('loadingLinks', false));
			} else {
				store.set('loadingLinks', false);
			}
		},
		saveContact: contact => {
			Tools.Contact.save(contact)
				.then(res => {
					if (!res.error && res.data) {
						const currentContacts = store.get('appointment.contacts');
						const contactIndex = _.findIndex(currentContacts, { id: contact.id });
						currentContacts.splice(contactIndex, 1, { ...contact, ...res.data });
						store.set('appointment.contacts', currentContacts);
					}
				})
				.catch(err => {
					logError(err, 'Error saving contact');
				});
		},
		setParticipantData: init => {
			const appointment = store.get('appointment');
			const client = appointment && appointment.client ? appointment.client : null;
			const operationalAccountId = hasSubaccounts && client ? client.operationalAccount?.id : undefined;

			participantSelect(client?.id ?? null, store.get('users'), utils, operationalAccountId)
				.then(response => {
					store.setStore({
						participantSelect: response.object,
						isDirty: !init
					});
				})
				.catch(err => {
					logError(err, 'Error selecting participant');
				});
		},
		getContactsFromOtherCompanies: async searchStr => {
			const appointment = store.get('appointment');
			const client = appointment && appointment.client ? appointment.client : null;
			if (!client?.id || !searchStr) {
				const participantSelect = store.get('participantSelect');
				participantSelect.data[2].children = [];
				store.setStore({
					participantSelect
				});
				return;
			}

			const { Contact } = Tools;
			const contactFilter = new Tools.RequestBuilder();

			contactFilter.addFilter(Contact.attr.name, comparisonTypes.Search, searchStr);
			contactFilter.addFilter(Contact.attr.active, comparisonTypes.Equals, 1);
			contactFilter.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
			contactFilter.addFilter({ field: 'client.id' }, comparisonTypes.NotEquals, client.id);

			contactFilter.limit = 20;
			contactFilter.addSort({ field: 'name' }, true);

			Contact.find(contactFilter.build())
				.then(res => {
					const participantSelect = store.get('participantSelect');
					res.data = res.data.map(c => ({ ...c, subtitle: c.client.name }));
					participantSelect.data[2].children = res.data;
					store.setStore({
						participantSelect
					});
				})
				.catch(e => logError(e, 'Error getting contacts from other companies'));
		},
		saveAppointmentWithAction: async (appointmentData, extraParams) => {
			const appointment = store.get('appointment');
			const invitedContacts = store.get('invitedContacts');
			const invitedEmailAttendees = store.get('invitedEmailAttendees');
			setInviteOnContactsAndEmailAttendees(appointmentData, invitedContacts, invitedEmailAttendees, false);
			const { data: newAppointment } = await AppointmentResource.save(appointmentData, {
				params: { saveOutcomeAction: appointmentData.outcomeAction ? true : false },
				...extraParams
			});

			store.setStore({
				appointment: { ...appointment, outcome: newAppointment.outcome },
				originalOutcome: newAppointment.outcome
			});
		},
		saveOutcomeComment: async outcomeCommentId => {
			const appointment = store.get('appointment');
			const isRescheduling = store.get('reschedule');

			let saveData = { id: appointment.id, outcome: appointment.outcome };
			if (isRescheduling) {
				saveData.date = appointment.date;
			}
			if (outcomeCommentId) {
				saveData = {
					...saveData,
					outcomeAction: EVENT_TYPES.COMMENTCREATED,
					...(outcomeCommentId ? { outcomeCommentId } : {})
				};
			}
			await actions.saveAppointmentWithAction(saveData);
		},
		setSavingType: savingType => {
			store.set('savingType', savingType);
		}
	};

	const render = props => {
		if (!rootNode) {
			return;
		}

		const dateScrollContainer = rootNode.getElementsByClassName('AppointmentTabs')[0];

		ReactDOM.render(
			<Provider store={reduxStore}>
				{React.createElement(AppointmentModal, { ...props, dateAnchor: rootNode, dateScrollContainer })}
			</Provider>,
			rootNode
		);
	};

	const setActiveUsers = modalParams => {
		const meta = modalParams.meta;

		const users = meta.users.data.map(user => {
			user.$id = `user-${user.id}`;

			return user;
		});

		return users.filter(user => user.active === 1);
	};

	const init = element => {
		const INITIAL_STATE = _.cloneDeep(DEFAULT_STATE);
		const metadata = Tools.AppService.getMetadata();
		const meta = modalParams.meta;
		INITIAL_STATE.utils = utils;
		INITIAL_STATE.users = setActiveUsers(modalParams);
		INITIAL_STATE.isDirty = false;
		INITIAL_STATE.time = meta.appointment.data.date;
		const appointment = fixContactsOnLoad(meta.appointment.data);
		INITIAL_STATE.invitedContacts = getInvitedContacts(meta.appointment.data);
		INITIAL_STATE.invitedEmailAttendees = getInvitedEmailAttendees(meta.appointment.data);
		if (!appointment.id) {
			appointment.userEditable = true;
		}
		INITIAL_STATE.preSelectedOutcome = meta.preSelectedOutcome;
		INITIAL_STATE.appointment = appointment;
		INITIAL_STATE.existingAppointment = _.cloneDeep(appointment);
		INITIAL_STATE.existingAppointment.contacts = [];
		INITIAL_STATE.existingAppointment.emailAttendees = [];
		INITIAL_STATE.existingAppointment.users = [];

		INITIAL_STATE.originalOutcome = appointment.outcome;
		if (meta.preSelectedOutcome) {
			INITIAL_STATE.appointment.outcome = meta.preSelectedOutcome;
		}

		INITIAL_STATE.endTime = meta.appointment.data.endDate;
		INITIAL_STATE.customerId = Tools.AppService.getCustomerId();
		INITIAL_STATE.dateAvailability = meta.appointment.data.date;
		INITIAL_STATE.requiredFields = metadata.requiredFields.Appointment;
		INITIAL_STATE.standardFields = metadata.standardFields.Appointment;
		INITIAL_STATE.activityTypes = Tools.AppService.getActivityTypes('appointment', true);
		INITIAL_STATE.participants = meta.appointment.data.users;
		INITIAL_STATE.options.outcomes = [
			{ id: 'planned', description: Tools.$translate('appointment.outcome.planned') },
			{ id: 'completed', description: Tools.$translate('appointment.outcome.completed') },
			{ id: 'notCompleted', description: Tools.$translate('appointment.outcome.notCompleted') }
		];

		if (metadata.integrations && metadata.integrations.uiElements) {
			INITIAL_STATE.uiElements = metadata.integrations.uiElements.editAppointment;
		}

		if (!INITIAL_STATE.participants) {
			INITIAL_STATE.participants = [];
		}
		if (!Array.isArray(INITIAL_STATE.participants)) {
			INITIAL_STATE.participants = [INITIAL_STATE.participants];
		}
		if (Array.isArray(INITIAL_STATE.appointment.contacts)) {
			INITIAL_STATE.participants = INITIAL_STATE.participants.concat(INITIAL_STATE.appointment.contacts);
		}

		INITIAL_STATE.hasDocumentTemplates = !!(meta.documentTemplates.data && meta.documentTemplates.data.length);
		INITIAL_STATE.documentTemplates = meta.documentTemplates.data;

		if (INITIAL_STATE.appointment.id) {
			// this is not a new appointment so we dont render calendar on start.
			INITIAL_STATE.calendarIsOpenOnFirstLoad = false;
		}

		// users
		if (INITIAL_STATE.appointment.users && !Array.isArray(INITIAL_STATE.appointment.users)) {
			INITIAL_STATE.appointment.users = [INITIAL_STATE.appointment.users];
		}
		if (INITIAL_STATE.appointment.users) {
			INITIAL_STATE.existingAppointment.users = [...INITIAL_STATE.appointment.users];
		}

		// contacts
		if (INITIAL_STATE.appointment.contacts) {
			if (!Array.isArray(INITIAL_STATE.appointment.contacts)) {
				INITIAL_STATE.appointment.contacts = [INITIAL_STATE.appointment.contacts];
			}
			if (INITIAL_STATE.appointment.contacts.length) {
				INITIAL_STATE.contactOpened = INITIAL_STATE.appointment.contacts[0].id;
			}
		}
		if (INITIAL_STATE.appointment.contacts) {
			INITIAL_STATE.existingAppointment.contacts = [...INITIAL_STATE.appointment.contacts];
		}

		// emailAttendees
		if (INITIAL_STATE.appointment.emailAttendees && !Array.isArray(INITIAL_STATE.appointment.emailAttendees)) {
			INITIAL_STATE.appointment.emailAttendees = [INITIAL_STATE.appointment.emailAttendees];
			INITIAL_STATE.existingAppointment.emailAttendees = [...INITIAL_STATE.appointment.emailAttendees];
		}
		if (INITIAL_STATE.appointment.emailAttendees) {
			INITIAL_STATE.existingAppointment.emailAttendees = [...INITIAL_STATE.appointment.emailAttendees];
		}

		INITIAL_STATE.isAvailable = {
			opportunity: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PIPELINE),
			document: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.DOCUMENTS)
		};

		Tools.ScriptService.appointment.init(INITIAL_STATE.appointment);
		rootNode = element;
		store = new DataStore(render, actions, INITIAL_STATE, undefined, { options: true });
		const INITIAL_TOOLTIP = {
			animation: 'fade',
			arrow: true,
			hideOnClick: false,
			interactive: true,
			maxWidth: '230px',
			placement: 'left',
			size: 'large',
			theme: 'standard-field',
			trigger: 'focus',
			zIndex: 11000
		};

		setTimeout(function () {
			tippy('#notes-area', Object.assign(INITIAL_TOOLTIP, { offset: '-335, 0' }));
		}, 200);

		if (INITIAL_STATE.appointment && INITIAL_STATE.appointment.client) {
			actions.accountChange(INITIAL_STATE.appointment.client, true);
		} else {
			// Account changed loads participants to so no need to do this in the case when we run it
			actions.setParticipantData(true);
		}

		if (INITIAL_STATE.appointment && INITIAL_STATE.appointment.opportunity) {
			actions.extendOpportunity(INITIAL_STATE.appointment.opportunity);
		}

		actions.updateAppointmentLength(false, true);
		actions.getAppointments();
		actions.getContacts();
		actions.getLinks();
	};

	scope.$on('modal.ready', (event, data) => {
		init(data.element[0]);
	});
}

window.AppointmentCtrl = AppointmentCtrl;
export default AppointmentCtrl;
