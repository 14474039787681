import openModal, { shouldOpenModal } from 'App/services/Modal';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';
import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';
import DetectedDuplicate from 'App/babel/resources/DetectedDuplicate';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';
import { openConfirmSoliditetUpdate } from 'App/components/ConfirmSoliditetUpdateModal';

angular.module('domain.account').controller('ListAccounts', [
	'$q',
	'$translate',
	'$upModal',
	'$state',
	'Account',
	'AccountAttributes',
	'$multiSelect',
	'$scope',
	'AppService',
	'FilterHelper',
	'MultiRunnerActions',
	'Onboarding',
	'$safeApply',
	'FeatureHelper',
	function (
		$q,
		$translate,
		$upModal,
		$state,
		Account,
		AccountAttributes,
		$multiSelect,
		$scope,
		AppService,
		FilterHelper,
		MultiRunnerActions,
		Onboarding,
		$safeApply,
		FeatureHelper
	) {
		var Ctrl = this;
		var self;
		var accounts = [];
		var customerId;
		var currentQuery = [];
		var listViewCtrl = null;

		Ctrl.attributes = AccountAttributes();
		Ctrl.disableAddFilter = false;
		Ctrl.listType = 'account';
		Ctrl.tableTemplate = 'accountRow';
		Ctrl.title = 'default.accounts';
		Ctrl.useMultiActions = true;
		Ctrl.customFields = [];
		Ctrl.total = 0;
		Ctrl.secondList = 'contacts';
		Ctrl.secondListText = 'default.contacts';
		Ctrl.headerExtensionPath = require('App/upsales/domain/account/views/listAccountsHeaderExtension.html?file');

		Ctrl.headerListLinks = [
			{
				type: 'account',
				state: 'accounts',
				text: 'default.clientRegister'
			},
			{
				type: 'contact',
				state: 'contacts',
				text: 'default.contacts'
			}
		];

		Ctrl.quickSearch = {
			getFilter: function (value) {
				return {
					filterName: 'ListSearch',
					inactive: !value,
					value: value
				};
			},
			getValue: function (props) {
				var clientNameFilter = props.activeFilters.ListSearch;
				return clientNameFilter && clientNameFilter.value ? clientNameFilter.value : '';
			},
			getPlaceholder: function () {
				return $translate.instant('filters.quickSearch');
			}
		};

		Ctrl.addButton = {
			getLabel: function () {
				return $translate.instant('default.addAccount');
			},
			createEntry: function () {
				Ctrl.createEntry();
				Onboarding.setListIntro('client');
			},
			isEnabled: function () {
				const self = AppService.getSelf();
				return self.createRights.Client !== 'NO';
			}
		};

		Ctrl.headerExtension = {
			path: 'upsales/domain/account/views/listAccountsHeaderExtension.html'
		};

		Ctrl.goTo = function (view) {
			if (view !== Ctrl.listType) {
				listViewCtrl.listType = view;
				if (view === 'contacts' && FeatureHelper.hasSoftDeployAccess('LIST_CONTACTS_REACT')) {
					view = 'react-root-contacts';
				}
				$state.go(view, { customerId: customerId });
			}
		};

		var updateMultiselectTotal = function () {
			$multiSelect.setTotal(Ctrl.total);
		};

		Ctrl.getReference = function (_listViewCtrl) {
			listViewCtrl = _listViewCtrl;
			listViewCtrl.listType = Ctrl.listType;
		};

		$scope.$on('account.added', function (e, added) {
			if (FilterHelper.match(currentQuery.q, added, 'account')) {
				Ctrl.total++;
				updateMultiselectTotal();
				accounts.push(added);
			}
		});

		$scope.$on('account.updated', function (e, updated) {
			var existingIndex = _.findIndex(accounts, { id: updated.id });
			if (existingIndex !== -1 && FilterHelper.match(currentQuery.q, updated, 'account')) {
				accounts[existingIndex] = updated;
			} else if (existingIndex !== -1) {
				Ctrl.total--;
				updateMultiselectTotal();
				accounts.splice(existingIndex, 1);
			}
		});

		$scope.$on('account.deleted', function (e, deleted) {
			var existingIndex = _.findIndex(accounts, { id: deleted.id });
			if (existingIndex !== -1) {
				Ctrl.total--;
				updateMultiselectTotal();
				accounts.splice(existingIndex, 1);
				$scope.$broadcast('listView.updateTableData', {
					data: accounts,
					total: Ctrl.total
				});
			}
		});

		$scope.$on('account.assigned', function (e, res) {
			var existingIndex = _.findIndex(accounts, { id: res.account.id });
			if (existingIndex !== -1) {
				accounts[existingIndex].assigned = !accounts[existingIndex].assigned
					? {}
					: accounts[existingIndex].assigned;
				accounts[existingIndex].assigned.user = res.user;
			}
		});

		$scope.$on('account.list.multiRemove', (e, deletedIds) => {
			if (!deletedIds?.length) {
				return;
			}
			accounts = accounts.filter(account => !deletedIds.includes(account.id));
			Ctrl.total -= deletedIds.length;
		});

		var updateClient = function (client) {
			var index = _.findIndex(accounts, { id: client.id });

			if (index === -1) {
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			Account.customer(customerId)
				.get(client.id)
				.then(function (client) {
					if (!client || !client.data) {
						return;
					}
					accounts[index] = client.data;
					$scope.$broadcast('renderTable', {});
				});
		};

		$scope.$on('activity.updated', function (e, activity) {
			updateClient(activity.client);
		});

		$scope.$on('activity.added', function (e, activity) {
			updateClient(activity.client);
		});

		$scope.$on('activity.deleted', function (e, activity) {
			if (activity.client) {
				updateClient(activity.client);
			}
		});

		$scope.$on('appointment.updated', function (e, appointment) {
			updateClient(appointment.client);
		});

		$scope.$on('appointment.added', function (e, appointment) {
			updateClient(appointment.client);
		});

		$scope.$on('appointment.deleted', function (e, appointment) {
			if (appointment.client) {
				updateClient(appointment.client);
			}
		});

		/**
		 * The add button
		 */
		Ctrl.createEntry = function () {
			if (shouldOpenModal('CreateAccount')) {
				openModal('CreateAccount');
			} else {
				var options = { customerId: customerId };
				$upModal.open('createAccount', options);
			}
		};

		/**
		 * The getter function {listView} will need
		 *
		 * Filters out the account with id == 0
		 * TODO: Fix this in the api?
		 */
		Ctrl.getEntries = function (query, rb) {
			currentQuery = rb.build();
			return Account.customer(customerId)
				.find(currentQuery, { mapCustom: true })
				.then(function (res) {
					// Fix 0-index account
					var index = _.findIndex(res.data, { id: 0 });
					if (index !== -1) {
						res.data.splice(index, 1);
					}

					Ctrl.total = res.metadata ? res.metadata.total : res.length;
					accounts = res.data;
					return res;
				});
		};

		Ctrl.getManagers = function (account) {
			return _.pluck(account.users.slice(1), 'name').join('\n');
		};

		Ctrl.loadDetectedDuplicates = function () {
			if (self.administrator) {
				// eslint-disable-next-line promise/catch-or-return
				DetectedDuplicate.find({ limit: 0, ignore: 0, entity: 'Client' }).then(function (data) {
					if (data.metadata.total > 0) {
						Ctrl.linkButton = {
							getLabel: function () {
								return data.metadata.total;
							},
							action: function () {
								$state.go('react-root-detected-duplicates', {
									entity: 'accounts',
									customerId: customerId
								});
							}
						};
					} else {
						Ctrl.linkButton = null;
					}
					$safeApply($scope);
				});
			}
		};

		Ctrl.init = function () {
			self = AppService.getSelf();
			customerId = AppService.getCustomerId();

			if (self.userParams.multiSoliditetAccess || self.administrator) {
				if (FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.FIND_HIDDEN_PROSPECTS)) {
					Ctrl.secondButton = {
						getLabel: function () {
							return `<b class="fa fa-search"></b>&nbsp;&nbsp;${$translate.instant(
								'default.findHiddenProspects'
							)}`;
						},
						action: function () {
							Tools.$upModal.open('findHiddenProspects');
						}
					};
				} else {
					Ctrl.secondButton = {
						getLabel: function () {
							return $translate.instant('default.findProspects');
						},
						action: function () {
							if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC)) {
								Ctrl.goTo('react-root-prospecting');
							} else {
								Ctrl.goTo('findProspects');
							}
						}
					};
				}
			}

			Ctrl.customFields = AppService.getCustomFields('account');
			Ctrl.showAddBtn = self.createRights.Client !== 'NO';
			Ctrl.addBtnLabel =
				$translate.instant('default.create') + ' ' + $translate.instant('default.account').toLowerCase();

			// Extra selectables
			Ctrl.selectables = Ctrl.attributes.extraSelectables;

			Ctrl.listOpts = {
				type: 'account',
				hasCheck: true,
				editable: true,
				placeholderKey: 'client',
				clickedEntry: function (account) {
					$state.go('account.dashboard', { id: account.id, customerId: customerId });
				},
				editEntry: function (account) {
					if (shouldOpenModal('EditClient')) {
						openModal('EditClient', { id: account.id });
					} else {
						var options = {
							customerId: customerId,
							id: account.id
						};

						$upModal.open('editAccount', options);
					}
				},
				removeEntry: function (account) {
					var confirmButtonText =
						$translate.instant('admin.modal.deleteEntity', {
							entity: $translate.instant('account').toLowerCase()
						}) + $translate.instant('admin.modal.delete.andRelatedObjects');
					var alertConfirmOptions = {
						type: 'confirm',
						reactive: true,
						fullscreen: true,
						hideAbort: false,
						dialog: AlertConfirm,
						id: 'confirm-delete-fields',
						body: React.createElement(AlertBody, {
							account: account,
							customerId: customerId,
							entity: 'Client',
							numSelected: 1
						}),
						confirmButtonText: confirmButtonText,
						confirmClass: 'btn-red',
						confirmationMode: 'text',
						confirmText: account.name,
						confirmFn: Account.customer(customerId)['delete'].bind(null, account)
					};

					if (FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						return openModal('Alert', {
							...alertConfirmOptions,
							title: getConfirmationTitle('Client', 1, account.name),
							onClose: confirmed => {
								if (confirmed) {
									alertConfirmOptions.confirmFn();
								}
							}
						});
					}

					return $upModal.open('alert', alertConfirmOptions);
				},
				refreshSoliditet: function (account) {
					openConfirmSoliditetUpdate({
						account: account,
						name: account.name,
						customerId: customerId,
						accountId: account.id,
						duns: account.dunsNo
					});
				},
				qualify: function (client) {
					var partialClient = _.pick(client, ['id', 'assigned']);

					if (!partialClient.assigned) {
						partialClient.assigned = {};
					}
					// eslint-disable-next-line promise/catch-or-return
					$upModal.open('assign', { account: client }).then(function (userId) {
						var users = AppService.getActiveUsers();
						var user = _.find(users, function (user) {
							return user.id === userId;
						});

						if (user !== undefined) {
							client.assigned = {
								user: user
							};

							$scope.$broadcast('renderTable', {});
						}
					});
				}
			};

			Ctrl.multiActions = MultiRunnerActions.get(MultiRunnerActions.type.ACCOUNT);
			Ctrl.loadDetectedDuplicates();
		};
		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(Ctrl.init);
	}
]);
