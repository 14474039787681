'use strict';

angular.module('upUi').factory('UserTreeFilterMeta', [
	'$q',
	function ($q) {
		return function (accessType, selectableRoles, noParentRef, includeInactive, userTypes) {
			var options = {
				accessType: accessType,
				selectableRoles: selectableRoles,
				noParentRef: noParentRef,
				includeInactive: includeInactive,
				userTypes: userTypes
			};
			var tree = window.UserRoleTree(options);

			return $q.when({ data: tree });
		};
	}
]);
