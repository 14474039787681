import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import bemClass from '@upsales/components/Utils/bemClass';
import {
	Button,
	TableRow,
	TableColumn,
	Card,
	Title,
	Icon,
	Table,
	TableHeader,
	Text,
	Input,
	OutsideClick
} from '@upsales/components';
import InlineDropDownMenu from 'Components/Dialogs/InlineDropDownMenu';
import SystemMail from '../SystemMail';
import { makeCancelable } from '../../helpers/promise';
import { reactRouteCompatibility } from 'App/helpers/angularRouteHelpers';

import MailDomainTable from './DomainTable';
import {
	DOMAIN_TYPES,
	getMailAccount,
	getEmailDomains,
	addEmailDomain,
	getSocialEventDomains,
	addSocialEventDomain,
	removeDomain,
	verifyEventDomain,
	sendSystemMail
} from 'Store/reducers/DomainReducer';
import './Domains.scss';
import config from 'App/babel/config';
import AdminHeader from '../Admin/AdminHeader';
import { openEditEventDomainModal as showEditEventDomainModal } from './EditEventDomain';

const mapStateToProps = state => ({
	emailDomains: state.Domains[DOMAIN_TYPES.EMAIL],
	socialEventDomains: state.Domains[DOMAIN_TYPES.SOCIAL_EVENTS],
	missingMailAccount: state.Domains.missingMailAccount,
	mailAccount: state.Domains.mailAccount
});

const mapDispatchToProps = {
	getMailAccount,
	getEmailDomains,
	addEmailDomain,
	getSocialEventDomains,
	addSocialEventDomain,
	removeDomain,
	verifyEventDomain,
	sendSystemMail
};

class Domains extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;
		this.lang = {
			header: {
				title: t('admin.domains.title'),
				desc: t('admin.domains.description')
			},
			verify: t('mail.validateDomain'),
			row: {
				verified: t('mail.validatedDomain'),
				notVerified: t('mail.notValidatedDomain'),
				verifyTitle: t('mail.verifyInfoSplit1'),
				verifyDesc: t('mail.verifyInfoSplit2'),
				verifyEmailDomainDesc: t('mail.verifyEmailDomainDesc'),
				dns: {
					type: t('mail.dnsType'),
					host: t('mail.dnsHost'),
					data: t('mail.dnsData')
				}
			},
			delete: t('default.delete'),
			deleteDomain: t('admin.deleteDomainInfo'),
			sendInstructionByEmail: t('admin.sendInstructionByEmail')
		};

		this.editTableHeader = [
			{ title: this.lang.row.dns.type },
			{ title: this.lang.row.dns.host },
			{ title: this.lang.row.dns.data }
		];

		this.renderDomainRow = this.renderDomainRow.bind(this);
		this.renderEmailInfoRow = this.renderEmailInfoRow.bind(this);
		this.renderEventInfoRow = this.renderEventInfoRow.bind(this);

		this.openEventDomainModal = domain => {
			showEditEventDomainModal({ domain });
		};

		this.state = {
			showMailInstruction: false
		};
	}

	componentDidMount() {
		this.props.getMailAccount();
	}

	componentWillUnmount() {
		if (this.hideTimer) {
			clearTimeout(this.hideTimer);
		}
		if (this.cancelablePromise) {
			this.cancelablePromise.cancel();
		}
	}

	setToggleState = () => {
		this.setState({
			showMailInstruction: !this.state.showMailInstruction
		});
	};

	sendEmail = async (email, domain) => {
		this.cancelablePromise = makeCancelable(this.props.sendSystemMail(email, domain));

		await this.cancelablePromise.promise;

		this.hideTimer = setTimeout(() => {
			this.setState({ showMailInstruction: false });
		}, 2000);
	};

	renderEmailInfoRow(closeVerify, domain) {
		const lang = this.lang.row;
		return (
			<TableRow key={`domain-row-verify-${domain.domain}`} className="DomainTable__verify-row">
				<TableColumn size="lg" className="DomainTable__validate-row" colSpan={4}>
					<div className="DomainTable__verify-wrapper">
						<div>
							<Icon name="times" onClick={() => closeVerify()} />
							<Title>{lang.verifyTitle}</Title>
							<Text>{lang.verifyEmailDomainDesc}</Text>
							<OutsideClick
								className="AdminDomains__SystemMail-wrapper"
								targetClass="SystemMail"
								outsideClick={this.setToggleState}
								listen={this.state.showMailInstruction}
							>
								<Button onClick={this.setToggleState}>{this.lang.sendInstructionByEmail}</Button>
								<SystemMail sendMail={email => this.sendEmail(email, domain.domain)} />
							</OutsideClick>
						</div>
						<Table className="DomainTable__dns-table">
							<TableHeader columns={this.editTableHeader} />
							{Object.values(domain.dns ?? {}).map(({ type, host, data }, index) => (
								<TableRow key={`domain-row-verify-${index}`}>
									<TableColumn size="lg" title={(type ?? '').toUpperCase()} />
									<TableColumn size="lg" title={<Input readOnly defaultValue={host ?? ''} />} />
									<TableColumn size="lg" title={<Input readOnly defaultValue={data ?? ''} />} />
								</TableRow>
							))}
						</Table>
					</div>
				</TableColumn>
			</TableRow>
		);
	}

	renderEventInfoRow(closeVerify, domain) {
		const lang = this.lang.row;
		const txt = 'upsales-domain-verification.' + domain.name;
		const txtValue = `upsales-domain-verification=${domain.hash}`;
		return (
			<TableRow key={`domain-row-verify-${domain.name}`} className="DomainTable__verify-row">
				<TableColumn size="lg" className="DomainTable__validate-row" colSpan={4}>
					<div className="DomainTable__verify-wrapper">
						<div>
							<Icon name="times" onClick={() => closeVerify()} />
							<Title>{lang.verifyTitle}</Title>
							<Text>{lang.verifyDesc}</Text>
						</div>
						<Table className="DomainTable__dns-table">
							<TableHeader columns={this.editTableHeader} />
							<TableRow key="domain-row-verify-cname">
								<TableColumn size="lg" title="CNAME" key={1} />
								<TableColumn size="lg" key={2}>
									<Input defaultValue={domain.name} readOnly />
								</TableColumn>
								<TableColumn size="lg" key={3}>
									<Input defaultValue={config.CUSTOM_DOMAIN_CNAME} readOnly />
								</TableColumn>
							</TableRow>
							<TableRow key="domain-row-verify-txt">
								<TableColumn size="lg" title="TXT" />
								<TableColumn size="lg">
									<Input defaultValue={txt} readOnly />
								</TableColumn>
								<TableColumn size="lg" key={3}>
									<Input defaultValue={txtValue} readOnly />
								</TableColumn>
							</TableRow>
						</Table>
						{!domain.verified ? (
							<Button onClick={() => this.props.verifyEventDomain(domain)}>{this.lang.verify}</Button>
						) : null}
					</div>
				</TableColumn>
			</TableRow>
		);
	}

	renderDelete(deleteType, domain, deleteFn) {
		return (
			<InlineDropDownMenu isLoading={domain.deleting} className="AdminDomain__inline-delete">
				<Card className="Domain__delete-dialog" onClick={e => e.stopPropagation()}>
					<Title block center size="sm">
						{this.lang.deleteDomain}
					</Title>
					<Button
						block
						color="red"
						onClick={e => {
							e.stopPropagation();
							deleteFn(domain, deleteType);
						}}
					>
						{this.lang.delete}
					</Button>
				</Card>
			</InlineDropDownMenu>
		);
	}

	renderDomainRow(domain, toggleVerified, editRow, nameKey, validKey, deleteType, i) {
		const lang = this.lang.row;
		const toolsColStyle = {};
		if (deleteType) {
			toolsColStyle.paddingRight = '50px';
		}

		return (
			<TableRow
				key={`domain-row-${domain[nameKey]}`}
				className="DomainTable__row"
				onClick={editRow ? () => editRow(domain.id, domain[nameKey]) : null}
			>
				<TableColumn size="lg" className={`DomainTable__row--${domain[validKey] ? '' : 'in'}valid`} />
				<TableColumn
					size="lg"
					title={domain[nameKey]}
					subtitle={domain[validKey] ? lang.verified : lang.notVerified}
					subtitleColor="grey-11"
				/>
				<TableColumn size="lg" align="right" className={deleteType ? '' : 'Domain__tools-col'}>
					{domain[validKey] ? null : (
						<Button
							loading={domain.adding}
							onClick={e => toggleVerified(e, i)}
							color="grey"
							shadow="none"
							size="sm"
							type="link"
						>
							<Icon name="info-circle" />
						</Button>
					)}
				</TableColumn>
				{deleteType ? (
					<TableColumn size="lg" align="right" style={toolsColStyle} className="Domain__tools-col">
						{this.renderDelete(deleteType, domain, this.props.removeDomain)}
					</TableColumn>
				) : null}
			</TableRow>
		);
	}

	render() {
		const {
			addEmailDomain,
			getEmailDomains,
			emailDomains,
			missingMailAccount,
			getSocialEventDomains,
			socialEventDomains,
			mailAccount
		} = this.props;
		const canDeleteEmailDomain = !!mailAccount?.useHalon;
		const hasCustomDomainsFeature =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SOCIAL_EVENTS) ||
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.LANDINGPAGE_CUSTOM_DOMAIN);
		const { header } = this.lang;
		const emailDomainProps = {
			addDomain: addEmailDomain,
			getDomains: getEmailDomains,
			domains: emailDomains,
			renderRow: (domain, toggleVerified, editRow, i) =>
				this.renderDomainRow(
					domain,
					toggleVerified,
					null,
					'domain',
					'valid',
					canDeleteEmailDomain ? DOMAIN_TYPES.EMAIL : null,
					i
				),
			title: 'admin.domains.emailDomains',
			addBtnText: 'mail.verify.new.mail.domain',
			renderVerifyRow: this.renderEmailInfoRow,
			missingMailAccount,
			hasDelete: canDeleteEmailDomain
		};
		const eventDomainProps = {
			onAdd: this.openEventDomainModal,
			getDomains: getSocialEventDomains,
			domains: socialEventDomains,
			renderRow: (domain, toggleVerified, editRow, i) =>
				this.renderDomainRow(
					domain,
					toggleVerified,
					() => this.openEventDomainModal(domain),
					'name',
					'verified',
					DOMAIN_TYPES.SOCIAL_EVENTS,
					i
				),
			title: 'admin.domains.socialEvent',
			addBtnText: 'admin.domains.addSocialEvent',
			renderVerifyRow: this.renderEventInfoRow,
			hasDelete: true
		};
		const { showMailInstruction } = this.state;
		const classes = new bemClass('AdminDomains');

		return (
			<div className={`${classes.b()} admin-root-wrapper`}>
				<AdminHeader title={header.title} description={header.desc} image="mail-settings.svg" />
				<div className={classes.elem('content').b()}>
					{hasCustomDomainsFeature ? (
						<div className={classes.elem('section').b()}>
							<MailDomainTable {...eventDomainProps} />
						</div>
					) : null}
					<div className={classes.elem('section').mod({ showMail: showMailInstruction }).b()}>
						<MailDomainTable {...emailDomainProps} />
					</div>
				</div>
			</div>
		);
	}
}

Domains.defaultProps = {
	emailDomains: [],
	socialEventDomains: [],
	mailAccount: {}
};

Domains.propTypes = {
	getMailAccount: PropTypes.func.isRequired,
	getEmailDomains: PropTypes.func.isRequired,
	addEmailDomain: PropTypes.func.isRequired,
	emailDomains: PropTypes.array.isRequired,
	getSocialEventDomains: PropTypes.func.isRequired,
	addSocialEventDomain: PropTypes.func.isRequired,
	socialEventDomains: PropTypes.array.isRequired,
	removeDomain: PropTypes.func.isRequired,
	verifyEventDomain: PropTypes.func.isRequired,
	sendSystemMail: PropTypes.func.isRequired,
	mailAccount: PropTypes.oneOf([PropTypes.object, null]).isRequired,
	missingMailAccount: PropTypes.bool
};

export const detached = Domains;
const Component = connect(mapStateToProps, mapDispatchToProps)(Domains);
export default Component;

const WrappedPortedAdminDomains = props => {
	return (
		<div id="admin" className="WrappedPortedAdminDomains">
			<div id="admin-root">
				<Component {...props} />
			</div>
		</div>
	);
};

export const PortedAdminDomains = reactRouteCompatibility(WrappedPortedAdminDomains, {
	featureFlag: 'PORT_ADMIN_DOMAINS',
	wrapInPage: false
});

window.AdminDomains = Component;
