'use strict';
import { Icon, Input, Toggle } from '@upsales/components';
import logError from 'App/babel/helpers/logError';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import EditorElement from './editorElement';
import { loader } from 'App/helpers/googleMapsLoader';

ReactTemplates.form = {};
ReactTemplates.form.editLandingpageRoot = window.ReactCreateClass({
	rowToolsOpen: false,
	insertRowAfterId: null,
	hoveredRowId: null,
	editorInited: false,
	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},
	getStateFromProps: function (props) {
		var toolsVisible = false;
		var selectedRowId = null;
		if (props.rootData.selectedRowId !== null && props.rootData.selectedRowId !== undefined) {
			selectedRowId = props.rootData.selectedRowId;
			toolsVisible = true;
		}

		var selectedColumnId = null;
		if (props.rootData.selectedColumnId !== null && props.rootData.selectedColumnId !== undefined) {
			selectedColumnId = props.rootData.selectedColumnId;
		}
		var view;
		if (!props.rootData.accountProfile) {
			view = 'noAccountProfile';
		} else if (props.rootData.editMode) {
			view = 'edit';
		} else {
			view = 'selectTemplate';
		}
		return {
			view: view,
			toolsVisible: toolsVisible,
			selectedRowId: selectedRowId,
			selectedColumnId: selectedColumnId
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setupAccountProfile();
		this.setState(this.getStateFromProps(nextProps));
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			newLandingPage: t('form.newLandingPage'),
			landingPageInfo: t('form.landingPageInfo'),
			saving: t('default.saving'),
			column: t('mail.column'),
			row: t('mail.row'),
			clearColumn: t('mail.clearColumn'),
			text: t('mail.text'),
			rowTools: t('mail.rowTools'),
			deleteRow: t('editor.deleteRow'),
			selectRowLayout: t('mail.selectRowLayout'),
			video: t('landingpage.video'),
			image: t('bannereditor.image'),
			paddings: t('mail.paddings'),
			horizontal: t('mail.horizontal'),
			vertical: t('mail.vertical'),
			addContentToColumn: t('landingpage.addContentToColumn'),
			chooseTemplate: t('form.chooseTemplate'),
			landingPageTitle: t('form.landingPageTitle'),
			form: t('form.form'),
			createLandingpage: t('landingpage.createLandingpage'),
			editLandingpage: t('landingpage.editLandingpage'),
			learnMoreAboutLandingpages: t('landingpage.learnMoreAboutLandingpages'),
			countdown: t('landingpage.countdown'),
			downloadFile: t('landingpage.downloadFile'),
			map: t('landingpage.map'),
			share: t('landingpage.socialMediaIcons'),
			addRow: t('landingpage.addRow'),
			setupAccountProfile: t('admin.setupAccountProfile'),
			thanksPage: t('form.thanksPage'),
			background: t('bannerEditor.background'),
			moveRow: t('landingpage.moveRow'),
			card: t('landingpage.card'),
			preview: t('form.preview'),
			customHtml: t('landingpage.customHtml'),
			header: t('mail.header'),
			showHeader: t('default.show') + ' ' + t('mail.header').toLowerCase(),
			abort: t('default.abort'),
			titleLabel: t('default.title'),
			textLabel: t('default.text')
		};

		this.setupAccountProfile();

		var paddingOpts = ['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
		this.paddingOpts = _.map(paddingOpts, function (padding, i) {
			return (
				<option key={'padding' + i} value={padding}>
					{t('landingpage.padding.' + padding)}
				</option>
			);
		});
	},
	setupAccountProfile: function () {
		var t = Tools.$translate;
		var accountProfile = this.props.rootData.accountProfile;
		// Setup profileColors
		var colorVariables = [
			{ title: t('admin.colors'), colors: {} },
			{ title: t('admin.extraColors'), colors: {} }
		];

		if (accountProfile) {
			angular.forEach(accountProfile.colors, function (value) {
				colorVariables[0].colors[value] = value;
			});
			angular.forEach(accountProfile.extraColors, function (color) {
				colorVariables[1].colors[color.value] = color.value;
			});
		}

		this.setState({
			colorVariables: colorVariables
		});
	},
	previewClick: function (e) {
		var target = jQuery(e.target);
		// Prevent click on links in the preview
		if (e.target.tagName.toLowerCase() === 'a') {
			e.preventDefault();
			return;
		}
		var column;
		if (target.hasClass('up-column')) {
			column = target;
		} else {
			column = target.parent('[data-up-col]');
		}

		/*
			If you have transparent mode you cant not freaking click the header so
			I made it so if you click in the logos bounding rect you open it...
		*/
		const logo = jQuery('.up-element#logo');
		if (logo.length) {
			const rect = logo[0].getBoundingClientRect();
			const x = e.clientX;
			const y = e.clientY;
			const isHit = x < rect.left || x >= rect.right || y < rect.top || y >= rect.bottom ? false : true;
			if (isHit) {
				column = [];
				target = logo;
			}
		}

		var rowId;
		if (column.length) {
			this.props.rootData.onSelectRow({
				row: column.parents('[data-up-row]').attr('data-row-id'),
				column: column.attr('data-col-id')
			});
		} else if (target.parents('[data-up-special]').length || target.attr('data-up-special') !== undefined) {
			if (target.attr('data-up-special') !== undefined) {
				rowId = target.attr('data-row-id');
			} else {
				rowId = target.parents('[data-up-special]').attr('data-row-id');
			}

			this.props.rootData.onSelectRow({
				row: rowId,
				column: null
			});
		} else if (
			target.hasClass('up-row') ||
			(target.hasClass('up-container') && target.parents('[data-up-row]').length)
		) {
			// <-- row click
			var col = target.find('[data-up-col]');
			if (!col.length) {
				return;
			}
			if (target.hasClass('up-row')) {
				rowId = target.attr('data-row-id');
			} else {
				rowId = target.parents('[data-up-row]').attr('data-row-id');
			}
			this.props.rootData.onSelectRow({
				row: rowId,
				column: col.eq(0).attr('data-col-id'),
				showRow: true
			});
		}
	},
	onPreviewHover: function (e) {
		var target = jQuery(e.target);
		var row = [];
		var hoveredRow = [];
		var isLast = false;

		if (this.rowToolsOpen || target.attr('id') === 'add-row-tools' || target.parents('#add-row-tools').length) {
			// Keep it
			return;
		} else if (target.attr('id') === 'fake-bottom-row') {
			isLast = true;
			row = target.prev().find('[data-up-row]').last();
		} else if (target.hasClass('up-row')) {
			row = target;
		} else {
			row = target.parents('[data-up-row]');
		}
		if (target.hasClass('up-row')) {
			hoveredRow = target;
		} else if (target.parents('#landingpage-move-tools').length) {
			var preview = this._preview;
			hoveredRow = jQuery(preview).find('[data-row-id=' + this.hoveredRowId + ']');
		} else {
			hoveredRow = target.parents('[data-up-row]');
		}

		var addTools = this._addRowTools;
		var moveTools = this._moveTools;
		if (
			hoveredRow.length &&
			!hoveredRow.parents('[data-up-special]').length &&
			hoveredRow.attr('data-up-special') === undefined
		) {
			this.hoveredRowId = hoveredRow.attr('data-row-id');
			var position = hoveredRow[0].offsetTop;
			moveTools.classList.add('visible');

			if ($(hoveredRow[0].previousElementSibling).attr('data-up-special') !== undefined) {
				position += hoveredRow[0].previousElementSibling.scrollHeight;
			}
			moveTools.style.top = position + 'px';
		} else {
			this.hoveredRowId = null;
			moveTools.classList.remove('visible');
		}

		if (row.length) {
			addTools.classList.add('visible');
			var yPos = e.offsetY / row[0].clientHeight;

			if (yPos > 0.5 || isLast) {
				addTools.style.top = row[0].clientHeight + row[0].offsetTop + 'px';
				this.insertRowAfterId = row.attr('data-row-id');
			} else {
				if (row.prev().hasClass('up-row')) {
					this.insertRowAfterId = row.prev().attr('data-row-id');
				} else {
					this.insertRowAfterId = null;
				}
				addTools.style.top = row[0].offsetTop + 'px';
			}
			var _preview = this._preview;
			addTools.style.width = _preview.scrollWidth + 'px';
		} else {
			addTools.classList.remove('visible');
		}
	},
	onPreviewOut: function (e) {
		var target = jQuery(e.relatedTarget);
		var addTools = this._addRowTools;
		var moveTools = this._moveTools;

		if (!this.rowToolsOpen && target.attr('id') !== 'add-row-tools' && !target.parents('#add-row-tools').length) {
			addTools.classList.remove('visible');
		}

		moveTools.classList.remove('visible');
	},
	componentWillUnmount: function () {
		if (this.editorInited) {
			this._preview.removeEventListener('mousemove', this.onPreviewHover);
			this._preview.removeEventListener('mouseout', this.onPreviewOut);
		}
	},
	componentDidMount: function () {
		this.setEditorEvents();
		this.updateMaps();
	},
	setEditorEvents: function () {
		if (!this.editorInited && this.props.rootData.editMode) {
			this._preview.addEventListener('mousemove', this.onPreviewHover);
			this._preview.addEventListener('mouseout', this.onPreviewOut);
			this.editorInited = true;
		}
	},
	updateMaps: function () {
		if (this._preview) {
			var preview = jQuery(this._preview);

			var maps = preview.find('.type-map');
			maps.each(function () {
				var elem = jQuery(this);
				var mapWrapper = elem.find('.map');
				if (mapWrapper.length) {
					var data = elem.data();
					var pos = { lat: data.lat, lng: data.lng };
					var mapOpts = {
						zoom: 16,
						center: pos,
						disableDefaultUI: true,
						mapTypeControl: data.showtools,
						zoomControl: data.showtools,
						scrollwheel: data.allowscrollzoom
					};

					mapWrapper.html('');
					loader
						.load()
						.then(() => {
							var map = new google.maps.Map(mapWrapper[0], mapOpts);
							new google.maps.Marker({
								position: pos,
								map: map
							});
							elem.data('map', map);
						})
						.catch(e => {
							logError(e, 'Failed to load Google');
						});
				}
			});
		}
	},
	moveFormToggles: function () {
		var self = this;
		if (self._preview) {
			var preview = jQuery(self._preview);
			var selectedColumn = preview.find('.selected-column');
			var selectedColumnElement = selectedColumn.find('.up-element');
			jQuery('#form-preview-toggle').remove();
			if (
				selectedColumnElement.length &&
				selectedColumnElement.hasClass('type-form') &&
				self.state.selectedColumnId !== null
			) {
				var rootData = self.props.rootData;
				var group = jQuery('<div id="form-preview-toggle" class="btn-group"></div>');
				var showThankUPage = rootData.thankUPaige;
				var selectedClass = 'btn btn-sm up-btn btn-bright-blue';
				var unselectedClass = 'btn btn-sm up-btn btn-light-grey';
				var btn1 = jQuery('<button type="button" />')
					.addClass(showThankUPage ? unselectedClass : selectedClass)
					.text(self.lang.form);
				var btn2 = jQuery('<button type="button" />')
					.addClass(showThankUPage ? selectedClass : unselectedClass)
					.text(self.lang.thanksPage);
				var missingThanksPage = !rootData.form.thankYouElement && !rootData.form.landingPage;
				if (missingThanksPage && rootData.submitted) {
					if (showThankUPage) {
						btn2.append('<b id="missing-thanks-page" class="fa fa-warning text-white"></b>');
						btn2.addClass('btn-orange').removeClass('btn-bright-blue');
					} else {
						btn2.append('<b id="missing-thanks-page" class="fa fa-warning text-red"></b>');
					}
				}
				group.append(btn1, btn2);

				btn1.on('click', function () {
					self.toggleFormPreview('form');
				});
				btn2.on('click', function () {
					self.toggleFormPreview('thanksPage');
				});

				if (showThankUPage) {
					selectedColumn.find('#up-form').hide();
					selectedColumn.find('#up-form-thanks').show();
				} else {
					selectedColumn.find('#up-form').show();
					selectedColumn.find('#up-form-thanks').hide();
				}

				selectedColumn.append(group);
			}
		}
	},
	componentDidUpdate: function () {
		this.setEditorEvents();
		this.updateMaps();
		if (this._preview) {
			var preview = jQuery(this._preview);
			preview.find('.selected-column').removeClass('selected-column');
			preview.find('.selected-row').removeClass('selected-row');
			if (this.state.selectedRowId !== null) {
				var selectedRow = preview.find('[data-row-id=' + this.state.selectedRowId + ']');
				if (selectedRow) {
					selectedRow.addClass('selected-row');
				}

				if (selectedRow && this.state.selectedColumnId !== null) {
					var selectedColumn = selectedRow.find('[data-col-id=' + this.state.selectedColumnId + ']');
					if (selectedColumn) {
						selectedColumn.addClass('selected-column');
					}

					if (this.props.rootData.scrollToForm) {
						selectedColumn[0]?.scrollIntoView();
					}
				}
			}
		}
		this.moveFormToggles();
	},
	toggleRowTools: function (e) {
		var preview = this._preview;
		var addTools = this._addRowTools;
		var list = addTools.querySelector('#column-size-menu');

		if (this.rowToolsOpen) {
			this.rowToolsOpen = false;
			addTools.classList.remove('open');
			addTools.classList.remove('center');
			addTools.classList.remove('above');
			addTools.classList.remove('visible');
		} else if (e.target.id === 'no-content-add-row-btn' || e.target.parentNode.id === 'no-content-add-row-btn') {
			addTools.classList.remove('center');
			addTools.classList.remove('above');

			this.rowToolsOpen = true;
			addTools.classList.add('open');
			addTools.classList.add('visible');
			list.style.top = '';
		} else {
			addTools.classList.remove('center');
			addTools.classList.remove('above');

			this.rowToolsOpen = true;
			addTools.classList.add('open');
			var fitsBelow = list.clientHeight + addTools.offsetTop < preview.clientHeight;
			var fitsAbove = addTools.offsetTop > preview.clientHeight;
			if (fitsBelow) {
				list.style.top = '';
			} else if (fitsAbove) {
				addTools.classList.add('above');
				list.style.top = '';
			} else {
				// place centered
				addTools.classList.add('center');
				list.style.top = '-' + list.clientHeight / 2 + 'px';
			}
		}
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	setToolTab: function (type) {
		this.props.rootData.onSetToolTab(type);
	},
	deselectColumn: function () {
		var preview = this._preview;
		preview.scrollLeft = 0;
		this.props.rootData.onDeselectColumn();
	},
	getColHeader: function () {
		if (!this.props.rootData.selectedColumn || !this.props.rootData.selectedColumn.elements.length) {
			return {
				empty: true,
				icon: <b className="fa fa-plus-circle" key="col-icon" />,
				title: <h3 key="col-title">{this.lang.addContentToColumn}</h3>
			};
		}
		var iconClass = 'fa ';
		var title = '';
		switch (this.props.rootData.selectedColumn.elements[0].type) {
			case 'text':
				iconClass += 'fa-align-center';
				title = this.lang.text;
				break;
			case 'video':
				iconClass += 'fa-play-circle';
				title = this.lang.video;
				break;
			case 'image':
				iconClass += 'fa-image';
				title = this.lang.image;
				break;
			case 'form':
				iconClass += 'fa-file-text';
				title = this.lang.form;
				break;
			case 'countdown':
				iconClass += 'fa-clock-o';
				title = this.lang.countdown;
				break;
			case 'file':
				iconClass += 'fa-cloud-download';
				title = this.lang.downloadFile;
				break;
			case 'map':
				iconClass += 'fa-map';
				title = this.lang.map;
				break;
			case 'card':
				iconClass += 'fa-address-card-o';
				title = this.lang.card;
				break;
			case 'share':
				iconClass += 'fa-share';
				title = this.lang.share;
				break;
		}

		return {
			empty: false,
			icon: <b className={iconClass} key="col-icon" />,
			title: <h3 key="col-title">{title}</h3>
		};
	},
	getToolsContentByType: function (type, isThankU) {
		var tools = null;
		var rootData = this.props.rootData;
		var props = {
			accountProfile: rootData.accountProfile,
			tools: this.props.tools,
			addPreset: rootData.addPreset,
			background: rootData.selectedRow.background,
			isSocialEvent: rootData.isSocialEvent
		};

		if (isThankU) {
			props.element = rootData.form.thankYouElement;
			props.onChange = rootData.onThankUChange;
		} else {
			props.element = rootData.selectedColumn.elements[0];
			props.onChange = rootData.onElementChange;
		}

		switch (type) {
			case 'text':
				props.colorVariables = this.state.colorVariables;
				props.strictTagList = this.props.rootData.strictTagList;
				tools = React.createElement(ReactTemplates.form.textContentType, props);
				break;
			case 'button':
				props.colorVariables = this.state.colorVariables;
				tools = React.createElement(ReactTemplates.form.buttonContentType, props);
				break;
			case 'image':
				props.colorVariables = this.state.colorVariables;
				tools = React.createElement(ReactTemplates.form.imageContentType, props);
				break;
			case 'video':
				props.colorVariables = this.state.colorVariables;
				tools = React.createElement(ReactTemplates.form.videoContentType, props);
				break;
			case 'form':
				if (!rootData.thankUPaige) {
					props.form = rootData.form;
					props.onFormChange = rootData.onFormChange;
					props.enableFormCopy = true;
					props.onEditAction = rootData.onEditAction;
					props.colorVariables = this.state.colorVariables;
					props.onSelectField = rootData.onSelectField;
					props.accountCustomFields = rootData.accountCustomFields;
					props.contactCustomFields = rootData.contactCustomFields;
					props.optIns = rootData.optIns;
					props.getAvailableOptIns = rootData.getAvailableOptIns;
					props.hasVerifyDomainAccess = rootData.hasVerifyDomainAccess;
					props.verifiedDomains = rootData.verifiedDomains;
					tools = React.createElement(ReactTemplates.form.formContentType, props);
				} else {
					tools = (
						<>
							{rootData.form.thankYouElement ? (
								this.getToolsContentByType(rootData.form.thankYouElement.type, true)
							) : (
								<ReactTemplates.form.selectColumnContent
									hasForm={true}
									isThankUElem={true}
									onSelect={rootData.onSetThankYouElem}
								/>
							)}
							{rootData.form.thankYouElement?.type !== 'text' &&
							rootData.form.thankYouElement?.type !== 'landingPage' ? (
								<div className="tool-main-section">
									<div className="tool-section">
										<label className="tool-label block-label">{this.lang.titleLabel}</label>
										<Input
											maxLength={100}
											name="thankYouTitle"
											type="text"
											size="md"
											defaultValue={rootData.form.thankYouTitle}
											onChange={e =>
												rootData.onThankYouTitleTextChange('thankYouTitle', e.target.value)
											}
										/>
										<label className="tool-label block-label">{this.lang.textLabel}</label>
										<Input
											maxLength={200}
											name="thankYouText"
											type="text"
											size="md"
											defaultValue={rootData.form.thankYouText}
											onChange={e =>
												rootData.onThankYouTitleTextChange('thankYouText', e.target.value)
											}
										/>
									</div>
								</div>
							) : null}
						</>
					);
				}
				break;
			case 'countdown':
				props.colorVariables = this.state.colorVariables;
				tools = React.createElement(ReactTemplates.form.countdownContentType, props);
				break;
			case 'file':
				props.colorVariables = this.state.colorVariables;
				tools = React.createElement(ReactTemplates.form.fileContentType, props);
				break;
			case 'map':
				props.skipSearch = ['{{Event.Location}}'];
				props.form = rootData.form;
				props.verifiedDomains = rootData.verifiedDomains;
				tools = React.createElement(ReactTemplates.form.mapContentType, props);
				break;
			case 'landingPage':
				tools = React.createElement(ReactTemplates.form.landingPage, props);
				break;
			case 'card':
				props.colorVariables = this.state.colorVariables;
				tools = React.createElement(ReactTemplates.form.cardContentType, props);
				break;
			case 'share':
				props.colorVariables = this.state.colorVariables;
				props.strictTagList = this.props.rootData.strictTagList;
				tools = React.createElement(window.ShareContentType, props);
				break;
		}
		return tools;
	},
	getColumnTools: function () {
		var self = this;
		if (!this.props.rootData.selectedColumn.elements.length) {
			return React.createElement(ReactTemplates.form.selectColumnContent, {
				hasForm: this.props.rootData.form.landingPageBody.hasForm,
				onSelect: this.props.rootData.onSetColumnType
			});
		}

		var tools = this.getToolsContentByType(this.props.rootData.selectedColumn.elements[0].type);

		var vPadding = this.props.rootData.selectedColumn.padding
			? this.props.rootData.selectedColumn.padding.top
			: 'sm';
		var hPadding = this.props.rootData.selectedColumn.padding
			? this.props.rootData.selectedColumn.padding.left
			: 'sm';
		return (
			<div>
				{tools}

				<div className="tool-header">
					<h3>{this.lang.paddings}</h3>
				</div>

				<div className="tool-main-section">
					<div className="tool-section" key="paddh-row">
						<label>{this.lang.horizontal}</label>
						<select
							className="form-control inline-select input-sm pull-right"
							value={hPadding}
							onChange={self.paddingChanged.bind(this, 'h')}
						>
							{self.paddingOpts}
						</select>
					</div>
					<div className="clearfix" />
					<div className="tool-section" key="paddv-row">
						<label>{this.lang.vertical}</label>
						<select
							className="form-control inline-select input-sm pull-right"
							value={vPadding}
							onChange={self.paddingChanged.bind(this, 'v')}
						>
							{self.paddingOpts}
						</select>
					</div>
					<div className="clearfix" />
				</div>
			</div>
		);
	},
	paddingChanged: function (direction, e) {
		var value = e.target.value;
		var padding = this.props.rootData.selectedColumn.padding || {};
		if (direction === 'h') {
			padding.right = value;
			padding.left = value;
		} else {
			padding.top = value;
			padding.bottom = value;
		}
		this.props.rootData.onPaddingChange(padding);
	},
	paddingChangedRow: function (direction, e) {
		var value = e.target.value;
		var padding = this.props.rootData.selectedRow.padding || {};
		if (direction === 'h') {
			padding.right = value;
			padding.left = value;
		} else {
			padding.top = value;
			padding.bottom = value;
		}
		this.props.rootData.onPaddingChangeRow(padding);
	},
	backgroundChange: function (data) {
		this.props.rootData.onRowBgChange(data);
	},
	clearColumn: function () {
		var rootData = this.props.rootData;
		if (rootData.selectedColumn.elements[0].type === 'form' && rootData.thankUPaige) {
			rootData.onClearThankPage();
		} else {
			rootData.onClearColumn();
		}
	},
	deleteRow: function (fromHover) {
		if (fromHover) {
			var moveTools = this._moveTools;
			moveTools.classList.remove('visible');
			this.props.rootData.onDeleteRow(this.hoveredRowId);
		} else {
			this.props.rootData.onDeleteRow();
		}
	},
	addRow: function (layout) {
		this.props.rootData.onAddRow(this.insertRowAfterId, layout);
		this.toggleRowTools();
	},
	setupProfile: function () {
		this.props.rootData.onSetupProfile();
	},
	toggleFormPreview: function (type) {
		this.props.rootData.onToggleFormPreview(type);
	},
	elementChanged: function (element) {
		this.props.rootData.onRowElementChange(element);
	},
	moveRow: function (direction, fromHover) {
		if (fromHover) {
			var moveTools = this._moveTools;
			moveTools.classList.remove('visible');
			this.props.rootData.onMoveRow(direction, this.hoveredRowId);
		} else {
			this.props.rootData.onMoveRow(direction);
		}
	},
	toggleHeaderVisibility: function () {
		this.props.rootData.toggleHeaderVisibility();
	},
	render: function () {
		var self = this;
		var rootData = this.props.rootData;
		var classes = 'card full-size';
		var cardStyle = {};
		var mainView = null;
		var mainClasses = '';

		if (rootData.isModal) {
			cardStyle = {
				top: 0,
				bottom: 0,
				left: 0,
				right: 0
			};
		}

		var templates = _.map(rootData.templates, function (template, i) {
			return React.createElement(ReactTemplates.form.landingPageTemplate, {
				form: rootData.form,
				accountProfile: rootData.accountProfile,
				template: template,
				key: i,
				onTemplateSelect: rootData.onTemplateSelect
			});
		});

		if (self.state.view === 'noAccountProfile') {
			mainView = (
				<div className="placeholder">
					<div className="inner-wrap">
						<h2>{self.lang.setupAccountProfile}</h2>
						<p />

						<div className="btn-wrap btn-wrap-3">
							<div className="btn-inner-wrap" />
							<div className="btn-inner-wrap">
								<button type="button" className="up-btn btn-bright-blue" onClick={self.setupProfile}>
									{self.lang.setupAccountProfile}
								</button>
							</div>
							<div className="btn-inner-wrap" />
						</div>
					</div>
				</div>
			);
		} else if (self.state.view === 'selectTemplate') {
			mainClasses += ' scrollable';
			mainView = (
				<div id="choose-template">
					<div className="header-box row">
						<div className="text">
							<div className="title"> {self.lang.landingPageTitle} </div>
							<div className="info">{self.lang.landingPageInfo}</div>
						</div>
						<div className="icon">
							<img className="img" src="/img/placeholder_landingPage.png" />
						</div>
					</div>
					<div className="cards row">
						<div className="card-label">{self.lang.chooseTemplate}</div>

						{templates}
					</div>
				</div>
			);
		} else {
			var previewHtml = { __html: rootData.html };
			var background;
			var toolsContent = null;
			var hideBtn = (
				<button id="editor-hide-tools" onClick={self.deselectColumn}>
					<b className="fa fa-chevron-right" />
				</button>
			);

			if (self.state.toolsVisible && rootData.selectedRow && rootData.selectedRow.special) {
				classes += ' tools-visible';

				var components = rootData.selectedRow.elements.map(function (element, i) {
					return (
						<EditorElement
							element={element}
							key={'elem-' + i}
							elementChanged={self.elementChanged}
							colorVariables={self.state.colorVariables}
							addPreset={self.props.rootData.addPreset}
						/>
					);
				});

				var bgOpts = {
					background: rootData.selectedRow.background,
					backgroundChange: self.backgroundChange,
					colorVariables: self.state.colorVariables,
					addPreset: self.props.rootData.addPreset
				};

				if (rootData.selectedRow.transparentHeaderOption) {
					bgOpts.transparentHeader = rootData.selectedRow.transparentHeader;
					bgOpts.transparentHeaderChanged = rootData.transparentHeaderChanged;
				}

				background = React.createElement(ReactTemplates.form.editorBackgroundElement, bgOpts);

				var vPadding = self.props.rootData.selectedRow.padding
					? self.props.rootData.selectedRow.padding.top
					: 'sm';
				var hPadding = self.props.rootData.selectedRow.padding
					? self.props.rootData.selectedRow.padding.left
					: 'sm';

				toolsContent = (
					<div className="editor-tools-panel">
						<div className="tool-header">
							<h3>{self.lang.header}</h3>
						</div>
						<div className="tool-main-section">
							<div className="tool-section">
								<label>{self.lang.showHeader}</label>
								<div className="pull-right">
									<Toggle
										checked={self.props.rootData.selectedRow.visible}
										onChange={self.toggleHeaderVisibility}
										className="toggle-bright-blue"
									/>
								</div>
							</div>
						</div>
						{rootData.selectedRow.visible && (
							<div>
								{components}
								<div className="tool-header">
									<h3>{self.lang.background}</h3>
								</div>

								{background}

								<div className="tool-header">
									<h3>{this.lang.paddings}</h3>
								</div>

								<div className="tool-main-section">
									<div className="tool-section" key="paddh-row">
										<label>{this.lang.horizontal}</label>
										<select
											className="form-control inline-select input-sm pull-right"
											value={hPadding}
											onChange={self.paddingChangedRow.bind(this, 'h')}
										>
											{self.paddingOpts}
										</select>
									</div>
									<div className="clearfix" />
									<div className="tool-section" key="paddv-row">
										<label>{this.lang.vertical}</label>
										<select
											className="form-control inline-select input-sm pull-right"
											value={vPadding}
											onChange={self.paddingChangedRow.bind(this, 'v')}
										>
											{self.paddingOpts}
										</select>
									</div>
									<div className="clearfix" />
								</div>
							</div>
						)}
					</div>
				);
			} else if (self.state.toolsVisible) {
				classes += ' tools-visible';

				var toolContent = null;
				var columnTools = self.getColumnTools();

				if (rootData.toolTab === 'column') {
					var header = self.getColHeader();
					toolContent = (
						<div>
							<div className="tool-main-section no-margin">
								<div className="tool-header">
									{header.icon} {header.title}
									{!header.empty ? (
										<button
											className="btn up-btn btn-sm btn-grey btn-hover-red btn-link pull-right btn-inline"
											onClick={self.clearColumn}
										>
											{self.lang.clearColumn}
										</button>
									) : null}
								</div>
							</div>
							{columnTools}
						</div>
					);
				} else {
					background = React.createElement(ReactTemplates.form.editorBackgroundElement, {
						background: self.props.rootData.selectedRow.background,
						backgroundChange: self.backgroundChange,
						colorVariables: self.state.colorVariables,
						addPreset: self.props.rootData.addPreset
					});
					toolContent = (
						<div>
							<div className="tool-main-section no-margin">
								<div className="tool-header">
									<b className="fa fa-sliders" />
									<h3>{self.lang.rowTools}</h3>
									<button
										className="btn up-btn btn-sm btn-grey btn-hover-red btn-link pull-right btn-inline"
										onClick={self.deleteRow.bind(self, false)}
									>
										{self.lang.deleteRow}
									</button>
								</div>
								<div className="tool-section">
									<label>{self.lang.moveRow}</label>
									<div className="pull-right btn-group">
										<button
											className="btn up-btn btn-xs btn-light-grey no-shadow btn-hover-green"
											onClick={self.moveRow.bind(self, 'up')}
										>
											<b className="fa fa-chevron-up" />
										</button>
										<button
											className="btn up-btn btn-xs btn-light-grey no-shadow btn-hover-green"
											onClick={self.moveRow.bind(self, 'down')}
										>
											<b className="fa fa-chevron-down" />
										</button>
									</div>
								</div>
							</div>

							{background}
						</div>
					);
				}

				toolsContent = (
					<div className="editor-tools-panel">
						<ul className="tool-tabs">
							<li
								className={rootData.toolTab === 'column' ? 'selected-tab' : ''}
								onClick={self.setToolTab.bind(self, 'column')}
							>
								{self.lang.column}
							</li>
							<li
								className={rootData.toolTab === 'row' ? 'selected-tab' : ''}
								onClick={self.setToolTab.bind(self, 'row')}
							>
								{self.lang.row}
							</li>
						</ul>

						{toolContent}
					</div>
				);
			}

			var addRowTools = (
				<div id="add-row-tools" ref={self.setRef.bind(self, '_addRowTools')}>
					<div id="add-row-curtain" onClick={self.toggleRowTools} />
					<button id="add-row-btn" onClick={self.toggleRowTools}>
						<b className="fa fa-plus" />
					</button>

					<div id="column-size-menu">
						<div className="columns-header">{self.lang.selectRowLayout}</div>
						<div className="columns-row" onClick={self.addRow.bind(self, [0])}>
							<table className="col-preview-table">
								<tbody>
									<tr>
										<td>{'100%'}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="columns-row" onClick={self.addRow.bind(self, [0, 0])}>
							<table className="col-preview-table">
								<tbody>
									<tr>
										<td>{'50%'}</td>
										<td>{'50%'}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="columns-row" onClick={self.addRow.bind(self, [0, 0, 0])}>
							<table className="col-preview-table">
								<tbody>
									<tr>
										<td>{'33%'}</td>
										<td>{'33%'}</td>
										<td>{'33%'}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="columns-row" onClick={self.addRow.bind(self, [0, 0, 0, 0])}>
							<table className="col-preview-table">
								<tbody>
									<tr>
										<td>{'25%'}</td>
										<td>{'25%'}</td>
										<td>{'25%'}</td>
										<td>{'25%'}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="columns-row" onClick={self.addRow.bind(self, [0, 2])}>
							<table className="col-preview-table">
								<tbody>
									<tr>
										<td style={{ width: '30%' }}>{'30%'}</td>
										<td>{'70%'}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="columns-row" onClick={self.addRow.bind(self, [2, 0])}>
							<table className="col-preview-table">
								<tbody>
									<tr>
										<td style={{ width: '70%' }}>{'70%'}</td>
										<td>{'30%'}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			);

			var moveTools = (
				<div id="landingpage-move-tools" ref={self.setRef.bind(self, '_moveTools')}>
					<button type="button" className="move-row-tools" onClick={self.deleteRow.bind(self, true)}>
						<b className="fa fa-times" />
					</button>
					<button type="button" className="move-row-tools" onClick={self.moveRow.bind(self, 'up', true)}>
						<b className="fa fa-chevron-up" />
					</button>
					<button type="button" className="move-row-tools" onClick={self.moveRow.bind(self, 'down', true)}>
						<b className="fa fa-chevron-down" />
					</button>
				</div>
			);

			var addRowBtn = null;
			if (!rootData.form.landingPageBody.rows.length) {
				addRowBtn = (
					<div id="no-content-add-row">
						<button
							id="no-content-add-row-btn"
							type="button"
							className="btn btn-lg up-btn btn-bright-blue"
							onClick={self.toggleRowTools}
						>
							<b className="fa fa-plus" /> {self.lang.addRow}
						</button>
					</div>
				);
			}

			var previewStyle = {
				backgroundColor: rootData.form.landingPageBody.backgroundColor
			};

			mainView = (
				<div className="card-content">
					<div
						className="preview"
						onClick={self.previewClick}
						ref={self.setRef.bind(self, '_preview')}
						style={previewStyle}
					>
						<div
							className="preview-inner"
							key="fdoihfdsoihdfwouh"
							dangerouslySetInnerHTML={previewHtml}
							ref={self.setRef.bind(self, '_previewInner')}
						/>
						<div id="fake-bottom-row" />
						{addRowTools}
						{addRowBtn}
						{moveTools}
					</div>

					{hideBtn}

					<div id="editor-toolbox" className="editor-tools">
						{toolsContent}
					</div>
				</div>
			);
		}

		var upLoader = null;
		if (rootData.saving) {
			upLoader = React.createElement(ReactTemplates.loader, { dark: true });
		}

		return (
			<div id="landingpage-editor" className={classes} ref={self.setRef.bind(self, '_root')} style={cardStyle}>
				<div className={'up-header up-header-sm' + (rootData.isModal ? '' : ' border-top fixed')}>
					<div id="title">
						<h2>
							{rootData.form.id ? self.lang.editLandingpage : self.lang.createLandingpage}{' '}
							<ReactTemplates.elevio articleId="630" sidebar="true" />
						</h2>
					</div>
					{rootData.editMode ? (
						<div className="actions">
							{rootData.isModal ? (
								<ThirdButton disabled={rootData.saving} onClick={rootData.abort}>
									{self.lang.abort}
								</ThirdButton>
							) : null}
							<ThirdButton disabled={rootData.saving} onClick={rootData.previewInNewWin}>
								<Icon name="laptop" space="mrs" />
								{'/'}
								<Icon name="mobile" space="mls mrs" style={{ fontSize: '12px' }} />
								{self.lang.preview}
							</ThirdButton>
							<ThirdButton disabled={rootData.saving} onClick={rootData.onOpenSettings}>
								<Icon name="cog" />
							</ThirdButton>
							<PrimaryButton loading={rootData.saving} onClick={rootData.onSave}>
								{rootData.saveBtnText}
							</PrimaryButton>
						</div>
					) : null}
				</div>

				<div id="card-main-view" className={mainClasses}>
					{mainView}
					{rootData.saving ? <div id="save-curtain">{upLoader}</div> : null}
				</div>
			</div>
		);
	}
});
