import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import T from '../../Helpers/translate';
import Bem from '@upsales/components/Utils/bemClass';
import {
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Card,
	CardHeader,
	Icon,
	Text,
	Input,
	Avatar
} from '@upsales/components';

import {
	getAllListViewsExceptStandard,
	adminSearchString,
	makeOwner,
	openModal
} from 'Store/reducers/SharedViewsReducer';
import AdminHeader from '../AdminHeader';

import './SharedViewsAdmin.scss';
import { reactRouteCompatibility } from 'App/helpers/angularRouteHelpers';

const mapStateToProps = state => ({
	allViews: state.SharedViews.allListViews,
	searchString: state.SharedViews.adminSearchString
});

const mapDispatchToProps = {
	getAllListViewsExceptStandard,
	adminSearchString,
	makeOwner,
	openModal
};

class SharedViewsAdmin extends React.Component {
	componentDidMount() {
		this.props.getAllListViewsExceptStandard();
	}

	row(view) {
		const userroles = [].concat(view.users).concat(view.roles);
		let sharedColumn = T('default.private');
		if (userroles.length === 1) {
			sharedColumn = `${userroles[0].name}`;
		}

		if (userroles.length > 1) {
			sharedColumn = `${userroles[0].name} + ${userroles.length - 1} ${T('default.more2').toLowerCase()}`;
		}

		return (
			<TableRow
				key={`${view.title}-${view.id}`}
				onClick={() => {
					this.props.openModal(view);
				}}
			>
				<TableColumn size="lg">
					<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
						<Text bold>{view.title}</Text>
						<Text size="sm" color="bright-blue" style={{ lineHeight: '12px' }}>
							{T(`sharedViews.subtitle.${view.type}`)}
						</Text>
					</div>
				</TableColumn>
				<TableColumn size="lg">
					<Text color="bright-blue">{sharedColumn}</Text>
				</TableColumn>
				<TableColumn size="lg" style={{ display: 'flex', alignItems: 'center' }}>
					<Avatar size="sm" initials={view.regBy.name} email={view.regBy.email} />
					<Text style={{ display: 'inline-block' }}>{view.regBy.name}</Text>
				</TableColumn>
				<TableColumn size="lg" align="right">
					<Icon name="pencil-square-o" />
				</TableColumn>
			</TableRow>
		);
	}

	render() {
		let { allViews, searchString, adminSearchString } = this.props;
		const MainClass = new Bem('SharedViewsAdmin');

		if (searchString.length) {
			allViews = allViews.filter(item => item.title.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
		}

		return (
			<div id="admin-root" className={MainClass}>
				<AdminHeader
					title={T('sharedViews.sharedViews')}
					image="admin-shared-views.svg"
					description={T('sharedViews.admin.headerDescription')}
				/>
				<div id="admin-content">
					<Card>
						<CardHeader title={T('sharedViews.viewsUsers')}>
							<Input
								noborder
								color="grey-2"
								icon="search"
								onChange={event => adminSearchString(event.target.value)}
								placeholder={T('sharedViews.searchView')}
							/>
						</CardHeader>
						<Table>
							<TableHeader
								columns={[
									{ title: T('sharedViews.cols.viewName') },
									{ title: T('sharedViews.cols.sharedWith') },
									{ title: T('sharedViews.cols.ownedBy') },
									{}
								]}
							/>
							{allViews.map(view => {
								return this.row(view);
							})}
						</Table>
					</Card>
				</div>
			</div>
		);
	}
}

SharedViewsAdmin.propTypes = {
	getAllListViewsExceptStandard: PropTypes.func,
	adminSearchString: PropTypes.func,
	allViews: PropTypes.array,
	makeOwner: PropTypes.func,
	searchString: PropTypes.string,
	openModal: PropTypes.func
};

export const detached = SharedViewsAdmin;
const ConnectComponent = connect(mapStateToProps, mapDispatchToProps)(SharedViewsAdmin);
window.SharedViewsAdmin = ConnectComponent;

const WrappedSharedViewsAdmin = props => {
	return (
		<div id="admin" className="WrappedSharedViewsAdmin">
			<ConnectComponent {...props} />
		</div>
	);
};
export const SharedViewsAdminPorted = reactRouteCompatibility(WrappedSharedViewsAdmin, {
	featureFlag: 'REACT_ADMIN_SHARED_VIEWS',
	wrapInPage: false
});
export default ConnectComponent;
