import { globalTracker } from 'App/babel/helpers/Tracker';
import logError from 'App/babel/helpers/logError';
import Prospecting from 'App/babel/resources/Prospecting';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import ValidationService from 'Services/ValidationService';
import {
	getAvailableCountries,
	getDefaultIndustryFieldFromCountry,
	StatusCategory
} from 'App/services/prospectingService';

window.CreateAccountCtrl = function ($scope) {
	var dataStore;
	var searchTimeouts = {};
	var AppService = Tools.AppService;
	var $q = Tools.$q;
	var RequestBuilder = Tools.RequestBuilder;
	var SoliditetClient = Tools.SoliditetClient;
	var Account = Tools.Account;
	var customerId = AppService.getCustomerId();
	var $upModal = Tools.$upModal;
	var FeatureHelper = Tools.FeatureHelper;
	var hasNewFields = FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.NEW_FIELDS);

	/*
		We have to send the countries we want metadata for to the integration
		as in the case of Bisnode international you will be able to choose
		what countries to search with in this modal.
	*/

	var customFields = AppService.getCustomFields('account');

	var orgFieldId = _.find(customFields, { alias: 'ORG_NO' });
	orgFieldId = orgFieldId ? orgFieldId.id : undefined;

	var LIMIT = 5;
	var requiredFields = _.filter(AppService.getCustomFields('account'), function (value) {
		return value.obligatoryField && value.$hasAccess && value.editable && value.alias !== 'ORG_NO';
	});

	var parseNumber = function (str) {
		return parseInt(str.replace(/\D/g, ''));
	};

	var searchExisting = function (dataSource, searchString) {
		var offset = dataSource.offset;

		var rb = new RequestBuilder();
		rb.limit = LIMIT;
		rb.offset = offset;

		var parsed = parseNumber(searchString);

		if (
			!isNaN(parsed) &&
			parsed.toString().length >= 8 &&
			parsed.toString().length <= 14 &&
			(orgFieldId || hasNewFields)
		) {
			var val;
			// is international --> first two characters are letters
			var tmp = parsed.toString();
			var first, second;
			if (isNaN(searchString[0]) && isNaN(searchString[1])) {
				first = tmp.slice(0, 2);
				second = tmp.slice(2, tmp.length);
				val = first + ' ' + second;
			} else {
				if (tmp.length > 5) {
					first = tmp.slice(0, 6);
					second = tmp.slice(6, tmp.length);
					val = first + '-' + second;
				} else {
					val = tmp;
				}
			}

			var orFilter = rb.orBuilder();

			if (orgFieldId) {
				orFilter.next();
				var groupBuilder = orFilter.groupBuilder();
				groupBuilder.addFilter({ field: 'custom.value' }, rb.comparisonTypes.Wildcard, tmp);
				groupBuilder.addFilter({ field: 'custom.fieldId' }, rb.comparisonTypes.Equals, orgFieldId);
				groupBuilder.done();
				orFilter.next();
				var groupBuilder2 = orFilter.groupBuilder();
				groupBuilder2.addFilter({ field: 'custom.value' }, rb.comparisonTypes.Wildcard, val);
				groupBuilder2.addFilter({ field: 'custom.fieldId' }, rb.comparisonTypes.Equals, orgFieldId);
				groupBuilder2.done();
			}
			if (hasNewFields) {
				orFilter.next();
				orFilter.addFilter({ field: 'orgNo' }, rb.comparisonTypes.Wildcard, tmp);
				orFilter.next();
				orFilter.addFilter({ field: 'orgNo' }, rb.comparisonTypes.Wildcard, val);
			}

			orFilter.done();
		} else {
			// first search for clients by name WCE
			rb.addFilter(Account.attr.name, rb.comparisonTypes.WildcardEnd, searchString);
		}

		return Account.customer(customerId)
			.find(rb.build())
			.then(function (res) {
				return {
					data: res.data,
					metadata: {
						ALL: res.metadata.total
					}
				};
			});
	};

	var matchExisting = function (config, res) {
		var values = _.pluck(res.data, config.integration);

		if (!values.length) {
			return res;
		}

		var rb = new RequestBuilder();
		rb.addFilter({ field: config.upsales }, rb.comparisonTypes.Equals, values);
		rb.addFilter(Account.attr.isExternal, rb.comparisonTypes.Equals, false);

		return findAll(Account.customer(customerId), rb).then(function (upsalesAccounts) {
			var valueMap = _.reduce(
				upsalesAccounts,
				function (res, upsalesAccount) {
					var key = upsalesAccount[config.upsales];
					res[key] = upsalesAccount;

					return res;
				},
				{}
			);

			var mappedData = _.map(res.data, function (dataSourceAccount) {
				var key = dataSourceAccount[config.integration];
				var match = valueMap[key];

				if (match) {
					dataSourceAccount.existing = true;
					dataSourceAccount.existingInactive = match.active ? false : true;
					dataSourceAccount.upsalesId = match.id;
				}

				return dataSourceAccount;
			});

			return { data: mappedData, metadata: res.metadata };
		});
	};

	var _searchSoliditet = function (searchString, limit, offset, country) {
		var filter = new RequestBuilder();
		filter.addSort('turnover', false);
		filter.addSort(SoliditetClient.attr.headquarters, false);

		var integration = dataStore.get('accounts.soliditet');
		var countries = integration.countries;

		if (country && countries.indexOf(country) !== -1) {
			filter.addFilter(SoliditetClient.attr.country, filter.comparisonTypes.Equals, country);
		} else {
			var orBuilder = filter.orBuilder();
			_.each(countries, function (country) {
				orBuilder.addFilter.bind(null, SoliditetClient.attr.country, filter.comparisonTypes.Equals, country);
				orBuilder.next();
			});
			orBuilder.done();
		}

		filter.limit = limit;
		filter.offset = offset;

		// If user enters number lte 10 digits we search for clients by org nr
		var parsed = parseNumber(searchString);

		if (!isNaN(parsed) && parsed.toString().length >= 8 && parsed.toString().length <= 14) {
			filter.addFilter(SoliditetClient.attr.orgNo, filter.comparisonTypes.Equals, parsed);
		} else {
			filter.addFilter(SoliditetClient.attr.name, filter.comparisonTypes.Search, searchString);
		}
		filter.addFilter(SoliditetClient.attr.status, filter.comparisonTypes.Match, 'Aktiv');

		return SoliditetClient.customer(customerId).find(filter.build());
	};

	var searchSoliditet = function (dataSource, searchString, fetchMetadata) {
		var countries = dataSource.countries;
		var currentTab = dataSource.currentTab;

		var promises = {
			soliditet: _searchSoliditet(searchString, LIMIT, dataSource.offset, currentTab)
		};

		if (fetchMetadata) {
			var promiseSE = countries.indexOf('SE') !== -1 ? _searchSoliditet(searchString, 0, 0, 'SE') : null;
			var promiseNO = countries.indexOf('NO') !== -1 ? _searchSoliditet(searchString, 0, 0, 'NO') : null;
			var promiseDK = countries.indexOf('DK') !== -1 ? _searchSoliditet(searchString, 0, 0, 'DK') : null;
			var promiseFI = countries.indexOf('FI') !== -1 ? _searchSoliditet(searchString, 0, 0, 'FI') : null;

			promises.soliditetMetadataALL = _searchSoliditet(searchString, 0, 0, null);
			promises.soliditetMetadataSE = promiseSE;
			promises.soliditetMetadataNO = promiseNO;
			promises.soliditetMetadataDK = promiseDK;
			promises.soliditetMetadataFI = promiseFI;
		}

		return Tools.$q.all(promises).then(function (res) {
			var result = {
				data: res.soliditet.data
			};

			if (fetchMetadata) {
				result.metadata = {
					ALL: res.soliditetMetadataALL ? res.soliditetMetadataALL.metadata.total : 0,
					SE: res.soliditetMetadataSE ? res.soliditetMetadataSE.metadata.total : 0,
					NO: res.soliditetMetadataNO ? res.soliditetMetadataNO.metadata.total : 0,
					DK: res.soliditetMetadataDK ? res.soliditetMetadataDK.metadata.total : 0,
					FI: res.soliditetMetadataFI ? res.soliditetMetadataFI.metadata.total : 0
				};
			}
			return result;
		});
	};

	var search = function (searchString, dataSource, limit, offset, fetchMetadata) {
		var promise;
		if (!searchString.length) {
			promise = $q.when({ data: [], metadata: {} });
		} else if (dataSource.id === 'soliditet') {
			promise = searchSoliditet(dataSource, searchString, fetchMetadata);
		} else if (dataSource.id === 'existing') {
			promise = searchExisting(dataSource, searchString);
		} else if (dataSource.id === 'prospecting') {
			promise = searchProspecting(dataSource, searchString, fetchMetadata);
		} else {
			var rb = new RequestBuilder();

			rb.limit = limit || 5;
			rb.offset = offset || 0;

			rb.addSort('turnover', false);

			if (searchString.match(/\D{2}\d{6,}|\d{8,}/)) {
				rb.addFilter(SoliditetClient.attr.orgNo, rb.comparisonTypes.WildcardEnd, searchString);
			} else {
				rb.addFilter(SoliditetClient.attr.name, rb.comparisonTypes.WildcardEnd, searchString);
			}

			rb.addFilter(SoliditetClient.attr.status, rb.comparisonTypes.Match, 'Aktiv');

			if (dataSource.currentTab !== 'ALL') {
				rb.addFilter(SoliditetClient.attr.country, rb.comparisonTypes.Equals, dataSource.currentTab);
			}

			var params = {
				type: 'client',
				integrationId: dataSource.id,
				data: rb.build()
			};

			if (fetchMetadata && dataSource.countries && dataSource.countries.length) {
				params.data.countries = dataSource.countries;
			}

			promise = Tools.DataSource.typeahead(params).then(function (res) {
				return {
					data: res.data,
					metadata: res.metadata
				};
			});
			promise.catch(error => {
				// This may happen if the integration is down or something like that
				console.log(error);
				dataStore.set('accounts.' + dataSource.id + '.hasError', true);
			});
		}

		if (dataSource.match) {
			return promise.then(function (res) {
				return matchExisting(dataSource.match, res);
			});
		} else {
			return promise;
		}
	};
	function searchProspecting(dataSource, searchString, fetchMetadata) {
		const country = dataSource.currentTab;
		const offset = dataSource.offset;
		const rb = new RequestBuilder();
		rb.limit = LIMIT;
		rb.offset = offset;
		rb.fields = [
			'id',
			'orgNumber',
			'cfar',
			'name',
			'headquarters',
			'visitingTown',
			'visitingCountry',
			'registeredTown',
			'registeredCountry',
			'postTown',
			'postCountry',
			'noEmployees',
			'revenue',
			'closestGroupMotherOrgnumber',
			'groupMotherOrgnumber',
			'groupSize',
			'sniCode',
			'ukSicCode',
			'naceCode',
			'countryCode'
		];

		rb.addFilter({ field: 'statusCategory' }, rb.comparisonTypes.Equals, [
			StatusCategory.Active,
			StatusCategory.ActiveWithWarning
		]);

		const possibleOrgNumber = ValidationService.validateOrgNumber(searchString, dataSource.currentTab);

		if (possibleOrgNumber) {
			const or = rb.orBuilder();
			or.next();
			or.addFilter({ field: 'orgNumber' }, rb.comparisonTypes.Equals, searchString);
			or.next();
			or.addFilter({ field: 'orgNumber' }, rb.comparisonTypes.Equals, searchString.replace('-', ''));
			or.done();
		} else {
			rb.addFilter({ field: 'name' }, rb.comparisonTypes.Search, searchString);
		}

		rb.addSort('revenue', false);
		rb.addSort('headquarters', false);
		rb.addSort('groupMotherOrgnumber', true);

		rb.extraParams.push({ key: 'country', value: country });
		const filters = rb.build();

		if (fetchMetadata && dataSource.countries && dataSource.countries.length) {
			filters.countries = dataSource.countries;
		}

		return Prospecting.find(filters).then(res => {
			for (const company of res.data) {
				if (company.matchInUpsales) {
					company.existing = true;
					company.existingInactive = !company.matchInUpsales.active;
					company.isExternal = company.matchInUpsales?.isExternal;
					company.upsalesId = company.matchInUpsales.id;
				}
			}
			return res;
		});
	}

	var searchAll = function (event) {
		var value = event.target.value;
		dataStore.set('search', value);

		var dataSources = dataStore.get('accounts');

		_.each(dataSources, function (dataSource) {
			if (searchTimeouts[dataSource.id]) {
				clearTimeout(searchTimeouts[dataSource.id]);
			}
			var waitTime = dataSource.waitTime || 300;

			searchTimeouts[dataSource.id] = setTimeout(function () {
				var key = 'accounts.' + dataSource.id;
				dataSource.loading = true;
				dataSource.offset = 0;
				dataStore.set('accounts', dataSources);

				var searchString = dataStore.get('search').trim();
				var fetchMetadata = true;

				search(searchString, dataSource, LIMIT, 0, fetchMetadata)
					.then(function (res) {
						var currentSearchString = dataStore.get('search').trim();

						// If another search has been made dont use the result from this one
						if (currentSearchString !== searchString) {
							return;
						}

						dataSource.data = res.data;
						dataSource.metadata = res.metadata;
						dataSource.loading = false;
						dataSource.hasError = false;
						dataStore.set(key, dataSource);
					})
					.catch(function () {
						var currentSearchString = dataStore.get('search').trim();

						// If another search has been made dont use the result from this one
						if (currentSearchString !== searchString) {
							return;
						}

						dataSource.data = [];
						dataSource.metadata = {};
						dataSource.loading = false;

						dataStore.set(key, dataSource);
					});
			}, waitTime);
		});
	};

	var loadMoreAccounts = function (dataSourceId) {
		var key = 'accounts.' + dataSourceId;
		var dataSource = dataStore.get(key);

		dataSource.isLoadingMore = true;
		dataSource.offset = dataSource.offset + LIMIT;

		dataStore.set(key, dataSource);

		var searchString = dataStore.get('search').trim();
		var fetchMetadata = false;

		return search(searchString, dataSource, LIMIT, dataSource.offset, fetchMetadata)
			.then(function (res) {
				var currentSearchString = dataStore.get('search').trim();

				// If another search has been made dont use the result from this one
				if (currentSearchString !== searchString) {
					return;
				}

				dataSource.data = dataSource.data.concat(res.data);
				dataSource.isLoadingMore = false;
				dataSource.hasError = false;

				dataStore.set('accounts.' + dataSource.id, dataSource);
			})
			.catch(function () {
				dataStore.set(key + '.isLoadingMore', false);
			});
	};

	var goToAccount = function (id) {
		$scope.close();
		Tools.$state.go('account.dashboard', { id: id });
	};

	var backToSearch = function () {
		dataStore.setStore({
			companyGroup: null,
			addingAccount: null,
			requiredFields: null
		});
	};

	var clearScroll = function () {
		dataStore.set('searchYPos', null);
	};

	function findAll(resource, rb) {
		return Tools.findAll(resource.find, rb.build());
	}

	function beginAddExternal(dataSourceId, account) {
		if (dataSourceId === 'prospecting') {
			var dataSource = dataStore.get('accounts.prospecting');

			var addingAccount = {
				account: account,
				externalId: _.get(account, dataSource.idField),
				dataSourceId: dataSource.id,
				isExternal: true
			};

			if (requiredFields.length !== 0) {
				dataStore.setStore({ addingAccount: addingAccount, requiredFields: requiredFields });
			} else {
				addAccount(addingAccount, []);
			}
		}
	}

	var beginAddAccount = function (dataSourceId, account, pricingKey) {
		var dataSource = dataStore.get('accounts.' + dataSourceId);

		if (dataSource) {
			var addingAccount = {
				externalId: _.get(account, dataSource.idField),
				dataSourceId: dataSourceId,
				pricingKey: pricingKey
			};

			if (requiredFields.length !== 0) {
				dataStore.setStore({ addingAccount: addingAccount, requiredFields: requiredFields });
			} else {
				addAccount(addingAccount, [], pricingKey);
			}
		}
	};

	function addAccount(addingAccount, customValues, pricingKey) {
		var requiredValues = customValues || [];
		var promise;
		if (addingAccount.dataSourceId === 'soliditet') {
			var opts = {
				updateExisting: false,
				skipProjects: false,
				skipAccountManagers: false,
				skipAddresses: false,
				skipCategories: false
			};

			promise = SoliditetClient.customer(customerId).buy(addingAccount.externalId, requiredValues, opts);
		} else if (addingAccount.dataSourceId === 'prospecting') {
			let id;
			if (addingAccount.isExternal) {
				id = addingAccount.account?.upsalesId || addingAccount.account?.id;
			}
			promise = Prospecting.save({
				id,
				prospectingId: addingAccount.externalId,
				customValues
			});
		} else {
			var method = 'buy';

			if (pricingKey) {
				method =
					dataStore.get('accounts.' + addingAccount.dataSourceId + '.pricing.' + pricingKey + '.endpoint') ||
					'buy';
			}

			var params = {
				type: 'client',
				integrationId: addingAccount.dataSourceId,
				data: {
					id: addingAccount.externalId,
					customValues: customValues
				}
			};
			promise = Tools.DataSource[method](params);
		}

		promise
			.then(function (createdAccount) {
				addingAccount.added = true;
				dataStore.set('addingAccount', addingAccount);

				if ($scope?.$modalParams?.onSave) {
					$scope.close();
					$scope.$modalParams.onSave(createdAccount.data);
				} else {
					goToAccount(createdAccount.data.id);
				}
			})
			.catch(function (err) {
				const errorKey = err.response?.data?.error?.key;
				addingAccount.added = false;
				addingAccount.adding = false;
				dataStore.set('addingAccount', addingAccount);
				let notificationBody;
				if (errorKey === 'NotEnoughCredits' && addingAccount.dataSourceId === 'prospecting') {
					notificationBody = 'prospecting.trial.limitReached';
				} else if (errorKey === 'NoSuchEntity') {
					notificationBody = 'errorNotFound.account';
				} else if (errorKey === 'NoEditRights') {
					notificationBody = 'noEditRights.client';
				} else {
					console.error(err);
				}
				if (notificationBody) {
					Tools.NotificationService.addNotification({
						style: Tools.NotificationService.style.ERROR,
						title: 'default.error',
						body: notificationBody,
						icon: 'times'
					});
				}
			});

		addingAccount.adding = true;
		dataStore.set('addingAccount', addingAccount);
	}

	var beginMerge = function (dataSourceId, account) {
		var dataSource = dataStore.get('accounts.' + dataSourceId);

		var addingAccount = {
			account: account,
			externalId: _.get(account, dataSource.idField),
			dataSourceId: dataSource.id,
			merging: true
		};

		dataStore.set('addingAccount', addingAccount);
	};

	var merge = function (addingAccount, upsalesAccount, pricingKey) {
		var promise;

		if (addingAccount.dataSourceId === 'soliditet') {
			var action = {
				action: 'buy',
				id: upsalesAccount.id,
				dunsNo: addingAccount.externalId
			};

			promise = SoliditetClient.customer(AppService.getCustomerId()).updateMatches({ buy: [action] });
		} else if (addingAccount.dataSourceId === 'prospecting') {
			promise = Prospecting.save({
				id: upsalesAccount.id,
				prospectingId: addingAccount.externalId
			});
		} else {
			var method = 'buy';

			if (pricingKey) {
				method =
					dataStore.get('accounts.' + addingAccount.dataSourceId + '.pricing.' + pricingKey + '.endpoint') ||
					'buy';
			}

			var params = {
				type: 'client',
				integrationId: addingAccount.dataSourceId,
				data: {
					id: addingAccount.externalId,
					mergeId: upsalesAccount.id,
					customValues: []
				}
			};
			promise = Tools.DataSource[method](params);
		}

		promise
			.then(function (res) {
				dataStore.set('addingAccount.added', true);

				if ($scope?.$modalParams?.onSave) {
					$scope.close();
					$scope.$modalParams.onSave(res?.data);
				} else {
					goToAccount(upsalesAccount.id);
				}
			})
			.catch(function (err) {
				dataStore.set('addingAccount.adding', false);
				console.error(err);
			});

		dataStore.set('addingAccount.adding', true);
	};

	var enterManually = function () {
		globalTracker.track('add', { type: 'account', location: 'manually' });

		const name = dataStore.get('search');

		if ($scope?.$modalParams?.onSave) {
			if (shouldOpenModal('EditClient')) {
				openModal('EditClient', {
					prefillData: { name },
					noRedirect: true,
					onClose: client => {
						if (client) {
							$scope.$modalParams.onSave(client);
						}
					}
				});
			} else {
				const account = Account.new();
				account.name = name;
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('editAccount', {
						account: account,
						customerId: customerId,
						fromModal: true
					})
					.then(client => {
						if (client) {
							$scope.$modalParams.onSave(client);
						}
					});
			}
		} else {
			if (shouldOpenModal('EditClient')) {
				openModal('EditClient', { prefillData: { name } });
			} else {
				const account = Account.new();
				account.name = name;
				$upModal.open('editAccount', {
					account: account,
					customerId: customerId
				});
			}
		}

		$scope.reject();
	};

	var changeTab = function (dataSourceId, country) {
		var key = 'accounts.' + dataSourceId;
		var dataSource = dataStore.get(key);
		dataSource.currentTab = country;
		dataSource.offset = 0;
		dataSource.loading = true;
		dataStore.set(key, dataSource);

		var searchString = dataStore.get('search').trim();
		var fetchMetadata = false;

		search(searchString, dataSource, LIMIT, 0, fetchMetadata)
			.then(function (res) {
				var newCurrentTab = dataStore.get(key + '.currentTab');
				var currentSearchString = dataStore.get('search').trim();
				dataSource.loading = false;
				dataSource.hasError = false;

				if (newCurrentTab === country && currentSearchString === searchString) {
					dataSource.data = res.data;
				}

				dataStore.set(key, dataSource);
			})
			.catch(function () {
				dataSource.data = [];
				dataSource.loading = false;

				dataStore.set(key, dataSource);
			});
	};

	var mapSoliditetToExistingUpsales = function (soliditetAccounts) {
		var dunses = _.map(soliditetAccounts, 'dunsNo');

		if (!dunses.length) {
			return soliditetAccounts;
		}

		const dunsChunks = _.chunk(dunses, 1000);
		const promises = dunsChunks.map(dunses => {
			const dunsFilter = new RequestBuilder();
			dunsFilter.addFilter(Account.attr.dunsNo, dunsFilter.comparisonTypes.Equals, dunses);
			dunsFilter.addFilter(Account.attr.isExternal, dunsFilter.comparisonTypes.Equals, false);
			return findAll(Account.customer(customerId), dunsFilter);
		});
		return Promise.all(promises)
			.then(_.flatten)
			.then(upsalesAccounts => {
				_.forEach(upsalesAccounts, upsalesClient => {
					const index = _.findIndex(soliditetAccounts, soliditetClient => {
						return parseInt(upsalesClient.dunsNo) === soliditetClient.dunsNo;
					});

					if (index !== -1) {
						upsalesClient.existing = true;
						upsalesClient.existingInactive = upsalesClient.active ? false : true;
						soliditetAccounts[index] = Object.assign({}, soliditetAccounts[index], upsalesClient);
					}
				});
				return soliditetAccounts;
			});
	};

	var openGroup = function (account, dataSourceId) {
		const key = 'accounts.' + dataSourceId;
		const dataSource = dataStore.get(key);
		const config = dataSource.companyGroup;

		const rootDuns = account[config.ultimateParentField]
			? account[config.ultimateParentField]
			: account[config.groupIdField];

		dataStore.set('companyGroup', {
			dataSourceId: dataSourceId,
			idField: dataSource.idField,
			config: config,
			rootDuns: rootDuns,
			openingGroup: true,
			groupAccount: account,
			data: null
		});

		const searchResult = document.getElementById('create-account-search-results');
		dataStore.set('searchYPos', searchResult.scrollTop);

		if (dataSourceId === 'prospecting') {
			const rb = new RequestBuilder();
			rb.addFilter({ field: 'prospectingId' }, rb.comparisonTypes.Equals, account.prospectingId);
			rb.extraParams.push({ key: 'country', value: dataSource.currentTab });
			Prospecting.findGroupstructure(rb.build())
				.then(res => {
					function getBranches(item) {
						let allBranches = item.branches;
						for (const child of item.children) {
							const childBranches = getBranches(child);
							allBranches = allBranches.concat(childBranches);
						}
						return allBranches;
					}

					let total = 0;

					function fixMatchInUpsales(item) {
						total++;
						if (item.matchInUpsales) {
							Object.assign(item, item.matchInUpsales, { existing: item.matchInUpsales });
						}
						for (const branch of item.branches) {
							total++;
							if (branch.matchInUpsales) {
								Object.assign(branch, branch.matchInUpsales, { existing: branch.matchInUpsales });
							}
						}
						for (const child of item.children) {
							fixMatchInUpsales(child);
						}
					}

					const tree = res.data[0];
					if (tree) {
						fixMatchInUpsales(tree);
					}
					const unknowns = tree ? getBranches(tree) : null;

					dataStore.set('companyGroup.unknownTotal', unknowns?.length ?? 0);
					dataStore.set('companyGroup.total', total);
					dataStore.set('companyGroup.data', []);
					dataStore.set('companyGroup.tree', tree);
					dataStore.set('companyGroup.unknowns', unknowns);
					dataStore.set('companyGroup.openingGroup', false);
				})
				.catch(e => {
					logError(e, 'Failed openGroup');
				});
		} else {
			const rb = new RequestBuilder();
			const or = rb.orBuilder();
			or.next();
			or.addFilter(SoliditetClient.attr.rootParentDuns, rb.comparisonTypes.Equals, rootDuns);
			or.next();
			or.addFilter(SoliditetClient.attr.dunsNo, rb.comparisonTypes.Equals, rootDuns);
			or.done();

			findAll(SoliditetClient.customer(customerId), rb)
				.then(mapSoliditetToExistingUpsales)
				.then(function (mappedAccounts) {
					dataStore.set('companyGroup.unknownTotal', null);
					dataStore.set('companyGroup.total', null);
					dataStore.set('companyGroup.unknowns', null);
					dataStore.set('companyGroup.tree', null);
					dataStore.set('companyGroup.data', mappedAccounts);
					dataStore.set('companyGroup.openingGroup', false);
				})
				.catch(e => {
					logError(e, 'Failed openGroup');
				});
		}
	};

	var abortMerge = function () {
		dataStore.set('addingAccount', null);
	};

	var sortId = 0;
	var getInitialState = function () {
		return {
			loading: false,
			isLoadingMore: false,
			hasError: false,
			sortId: sortId++,
			data: [],
			metadata: {},
			currentTab: 'ALL',
			offset: 0
		};
	};

	$scope.$on('modal.ready', function () {
		var rootNode = document.getElementById('create-new-account-modal');

		var render = function (props, callback) {
			if (rootNode) {
				ReactDOM.render(
					React.createElement(
						window.CreateNewAccountModal,
						Object.assign({}, props, { renderCallback: callback || function () {} })
					),
					rootNode,
					function () {}
				);
			}
		};

		var actions = {
			close: $scope.close,
			doSearch: searchAll,
			goToAccount: goToAccount,
			openGroup: openGroup,
			loadMoreAccounts: loadMoreAccounts,
			backToSearch: backToSearch,
			addAccount: addAccount,
			beginAddAccount: beginAddAccount,
			beginAddExternal: beginAddExternal,
			enterManually: enterManually,
			beginMerge: beginMerge,
			merge: merge,
			changeTab: changeTab,
			abortMerge: abortMerge,
			clearScroll: clearScroll
		};

		var metadata = AppService.getMetadata();
		var countryCodes = metadata.params.DnBCountryCodes;
		var validCountries =
			!countryCodes || !countryCodes.length || countryCodes === 'null'
				? ['SE', 'NO', 'DK', 'FI']
				: countryCodes.split(',');
		var user = AppService.getSelf();

		if (!FeatureHelper.isAvailable(FeatureHelper.Feature.SOLIDITET_MULTI_MARKET)) {
			var dnbCountryCodes = user.userParams.dnbCountryCodes;

			if (Array.isArray(dnbCountryCodes) && dnbCountryCodes.length) {
				validCountries = dnbCountryCodes;
			} else if (
				typeof dnbCountryCodes === 'string' &&
				dnbCountryCodes.length &&
				!!_.intersection([dnbCountryCodes], ['SE', 'FI', 'NO', 'DK']).length
			) {
				validCountries = dnbCountryCodes.split(',');
			} else {
				validCountries = ['SE'];
			}
		}

		var existingConfig = {
			id: 'existing',
			idField: 'id',
			companyGroup: null,
			match: null,
			type: 'existing',
			name: 'account.alreadyInUpsales',
			shortName: 'default.upsales',
			logo: null,
			countries: ['SE'],
			pagination: { enabled: true },
			pricing: null,
			waitTime: 300,
			fields: [
				{
					field: 'name',
					type: 'nameExisting',
					header: 'default.account',
					width: '55%'
				},
				{
					type: 'users',
					field: 'users',
					header: 'default.accountManager',
					width: '35%'
				},
				{
					type: 'view',
					field: 'view',
					header: '',
					width: '10%'
				}
			]
		};

		var soliditetConfig = {
			id: 'soliditet',
			idField: 'dunsNo',
			companyGroup: {
				groupIdField: 'dunsNo',
				parentField: 'parentDuns',
				ultimateParentField: 'rootParentDuns'
			},
			match: {
				upsales: 'dunsNo',
				integration: 'dunsNo'
			},
			type: 'soliditet',
			name: 'default.soliditet',
			logo: '/img/bisnode_square_logo.png',
			countries: validCountries,
			countryTabs: {
				enabled: true,
				showAll: true,
				showTotals: true
			},
			pagination: { enabled: true },
			pricing: null,
			waitTime: 300,
			fields: [
				{
					field: 'name',
					header: 'default.account',
					width: '25%',
					type: 'nameSoliditet'
				},
				{
					type: 'string',
					field: 'orgNo',
					header: 'default.orgNumberShort',
					width: '12%'
				},
				{
					type: 'string',
					field: 'sniCode',
					header: 'default.companyBranch',
					width: '25%',
					staticValue: 'sniCode'
				},
				{
					type: 'groupSize',
					field: 'groupSize',
					header: 'default.companySize',
					width: '12%'
				},
				{
					type: 'add',
					field: 'add',
					width: '28%'
				}
			]
		};

		const prospectingCountries = getAvailableCountries();
		const prosepctingCountryFields = prospectingCountries.reduce((fields, country) => {
			const industryField = getDefaultIndustryFieldFromCountry(country);

			fields[country] = [
				{
					field: 'name',
					header: 'default.account',
					width: '25%',
					type: 'nameProspecting'
				},
				{
					type: 'string',
					field: 'orgNumber',
					header: 'default.orgNumberShort',
					width: '12%'
				},
				{
					type: 'string',
					field: industryField,
					header: 'default.companyBranch',
					width: '25%',
					staticValue: industryField
				},
				{
					type: 'groupSize',
					field: 'groupSize',
					header: 'default.companySize',
					width: '12%'
				},
				{
					type: 'add',
					field: 'add',
					width: '28%'
				}
			];

			return fields;
		}, {});

		const prospectingConfig = {
			id: 'prospecting',
			idField: 'prospectingId',
			fields: prosepctingCountryFields,
			useCountryFields: true,
			companyGroup: {
				groupIdField: 'orgNumber',
				parentField: 'closestGroupMotherOrgnumber',
				ultimateParentField: 'groupMotherOrgnumber'
			},
			match: null,
			type: 'prospecting',
			name: 'Upsales prospecting',
			logo: '/img/u_square_logo.png',
			countries: prospectingCountries,
			countryTabs: {
				enabled: prospectingCountries.length > 1,
				showAll: false,
				showTotals: prospectingCountries.length > 1
			},
			pagination: { enabled: true },
			pricing: null,
			waitTime: 300
		};

		var accounts = {
			existing: _.assign(getInitialState(), existingConfig)
		};

		if (FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_BASIC)) {
			prospectingConfig.currentTab = prospectingConfig.countries[0];
			accounts.prospecting = Object.assign(getInitialState(), prospectingConfig);
		}

		if (Tools.AppService.getSelf().userParams.soliditetIsActive) {
			accounts.soliditet = _.assign(getInitialState(), soliditetConfig);
		}

		var store = {
			search: '',
			addingAccount: null,
			hasRequriedFields: requiredFields.length ? true : false,
			requiredFields: null,
			accounts: accounts,
			companyGroup: null,
			openingGroup: false
		};

		var integrations = _.filter(metadata.integrations.inits.dataSource, function (integration) {
			return integration.capabilities && integration.capabilities.typeahead;
		}).map(function (integration) {
			var state = {
				loading: false,
				isLoadingMore: false,
				sortId: sortId++,
				data: [],
				metadata: {},
				currentTab: 'ALL',
				offset: 0,
				invalidSettings: false
			};

			var defaultConfig = {
				idField: 'id',
				logo: '/img/icon_globe.png',
				fields: [{ field: 'name', header: 'default.account', width: '100%', type: 'string' }],
				countryTabs: { enabled: false, showTotals: true },
				pagination: { enabled: true },
				countries: null,
				match: null,
				pricing: null,
				waitTime: 300
			};

			var config = {
				id: integration.id,
				type: 'integration',
				name: integration.name,
				idField: _.get(integration, 'capabilities.typeahead.idField', defaultConfig.idField),
				logo: _.get(integration, 'capabilities.logo') || integration.imageLink || defaultConfig.logo,
				fields: _.get(integration, 'capabilities.typeahead.columns', defaultConfig.fields),
				countryTabs: _.get(integration, 'capabilities.typeahead.countryTabs', defaultConfig.countryTabs),
				pagination: _.get(integration, 'capabilities.typeahead.pagination', defaultConfig.pagination),
				countries: _.get(integration, 'capabilities.countries', defaultConfig.countries),
				match: _.get(integration, 'capabilities.match', defaultConfig.match),
				pricing: _.get(integration, 'capabilities.pricing', defaultConfig.pricing),
				waitTime: _.get(integration, 'capabilities.typeahead.waitTime', defaultConfig.waitTime)
			};
			var dataSource = _.assign(state, config);

			store.accounts[integration.id] = dataSource;
			return dataSource;
		});

		dataStore = new DataStore(render, actions, store);

		integrations.forEach(function (integration) {
			if (integration.countryTabs && integration.countryTabs.enabled) {
				var params = {
					type: 'client',
					integrationId: integration.id
				};

				Tools.DataSource.settings(params)
					.then(function (res) {
						var error = _.get(res, 'data.error');
						var countries = _.get(res, 'data.countries');

						if (error) {
							dataStore.set('accounts.' + integration.id + '.invalidSettings', true);
						} else if (countries) {
							var dataSource = dataStore.get('accounts.' + integration.id);
							dataSource.countries = countries;

							if (!integration.countryTabs.showAll && countries.length) {
								dataSource.currentTab = countries[0];
							}

							dataStore.set('accounts.' + integration.id, dataSource);
						}
					})
					.catch(error => {
						// This may happen if the integration is down or something like that
						console.log(error);
						dataStore.set('accounts.' + integration.id + '.hasError', true);
					});
			}
		});

		$scope.$on('$destroy', function () {
			ReactDOM.unmountComponentAtNode(rootNode);
			rootNode = undefined;
		});
	});
};
