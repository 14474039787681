'use strict';
import openModal from 'App/services/Modal';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

angular.module('domain.voice').controller('ListPhoneCalls', [
	'$upModal',
	'$translate',
	'PhoneCall',
	'PhoneCallAttributes',
	'listViews',
	'$scope',
	'AppService',
	function ($upModal, $translate, PhoneCall, PhoneCallAttributes, listViews, $scope, AppService) {
		var Ctrl = this;
		var entries = [];
		var currentQuery;

		Ctrl.attributes = PhoneCallAttributes();
		Ctrl.showAddBtn = false;
		Ctrl.listType = 'phoneCall';
		Ctrl.listViews = listViews;
		Ctrl.tableTemplate = 'phoneCallRow';
		Ctrl.title = 'voice.phoneCalls';
		Ctrl.useMultiActions = false;
		Ctrl.total = 0;

		Ctrl.getEntries = function (query) {
			currentQuery = query;

			return PhoneCall.find(currentQuery).then(function (res) {
				entries = res.data;
				Ctrl.total = res.metadata.total;

				return res;
			});
		};

		var init = function () {
			Ctrl.listOpts = {
				type: 'phoneCall',
				hasCheck: false,
				editable: true,
				placeholderKey: 'phoneCall',
				clickedEntry: function (item) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('OPEN_CALL_REACT')) {
						return openModal('OpenCall', item);
					} else {
						return $upModal.open('openCall', item);
					}
				},
				openRelated: function (related) {
					if (!related) {
						return;
					}
					switch (related.type) {
						case 'Activity':
							return $upModal.open('editActivity', { id: related.id });
						case 'Appointment':
							return openEditAppointment({ id: related.id });
						case 'Order':
						case 'Opportunity':
							return $upModal.open('editOrder', { id: related.id });
					}
				}
			};
		};

		AppService.loadedPromise.then(init);
	}
]);
