import { openConfirmSoliditetBuyParentModal } from 'App/components/ConfirmSoliditetBuyParent/ConfirmSoliditetBuyParent';
import JourneyStepDisplay from 'Components/JourneyStepDisplay';
import openModal from 'App/services/Modal';

ReactTemplates.editActivity.sidebarAccount = window.ReactCreateClass({
	focusedId: null,
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;
		this.lang = {
			account: t('default.account'),
			latestAccounts: t('default.latestAccounts'),
			noName: t('default.noName'),
			noSet: t('default.noSet'),
			phone: t('default.phone'),
			webpage: t('default.webpage'),
			parentCompany: t('default.parentCompany'),
			campaigns: t('default.campaigns'),
			categories: t('default.categories'),
			category: t('default.category'),
			campaign: t('default.campaign'),
			buyProspects: t('soliditet.buyProspects')
		};
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	accountChange: function (account) {
		this.props.onChange(account);
	},
	setFocus: function () {
		if (this.focusedId !== this.props.activity.id && !this.props.activity.client) {
			var input = jQuery('#account-select');
			if (input.length) {
				setTimeout(function () {
					input.select2('open');
				}, 200);
			}
		}
	},
	listModal: function (data, type) {
		var self = this;

		if (self.props.formDirty) {
			self.props.onSave(self.props.activity, false, true);
		}

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
			openModal('ListModal', {
				title: self.lang[type === 'category' ? 'categories' : 'campaigns'],
				columns: [{ title: 'default.name', value: 'name' }],
				data: data,
				onClose: function (row) {
					if (type === 'project') {
						window.Tools.$state.go('campaign.dashboard', { id: row.id });
					}
				}
			});
		} else {
			// eslint-disable-next-line promise/catch-or-return
			window.Tools.$upModal
				.open('list', {
					title: self.lang[type === 'category' ? 'categories' : 'campaigns'],
					columns: [{ title: 'default.name', value: 'name' }],
					data: data
				})
				.then(function (row) {
					if (type === 'project') {
						window.Tools.$state.go('campaign.dashboard', { id: row.id });
					}
				});
		}
	},
	componentDidMount: function () {
		this.setFocus();
	},
	componentDidUpdate: function () {
		this.setFocus();
	},
	parentCompanyClick: function (accountId) {
		window.Tools.$state.go('account.dashboard', { id: accountId });
	},
	getTooltip: function (items) {
		return _.map(items, function (item) {
			return item.name;
		}).join('\n');
	},
	generateLinkName: function (parent) {
		var self = this;
		if (parent.isDuns) {
			var tooltip = ReactTemplates.TOOLS.withTooltip(
				<span>{parent.name}</span>,
				self.lang.buyProspects + ' ' + parent.name,
				{ placement: 'top', key: parent.name + '-' + parent.id }
			);

			return tooltip;
		}

		return parent.name;
	},
	render: function () {
		var self = this;
		var activity = self.props.activity;
		var accountSelect = null;
		var accountDisplay = null;
		if (activity.client) {
			var client = activity.client;
			var visitAddr = client.$addresses && client.$addresses.Visit ? client.$addresses.Visit : null;
			var phoneClass = 'modal-sidebar-item';
			var webpageClass = 'modal-sidebar-item';
			var phoneLink = null;
			var hasParent =
				(client.parent && client.parent.id !== 0) ||
				(client.soliditet &&
					client.soliditet.profileData &&
					client.soliditet.profileData.parentCompanyName &&
					client.soliditet.profileData.parentCompanyDunsNumber);
			var canBuyParent = false;
			var parentName = {};

			if (hasParent) {
				canBuyParent =
					client.parent ||
					(client.soliditet &&
						client.soliditet.profileData &&
						client.soliditet.profileData.parentCompanyCountryCode === 'SE');

				if (client.parent) {
					parentName.name = client.parent.name;
					parentName.id = client.parent.id;
					parentName.isDuns = false;
				} else if (client.soliditet && client.soliditet.profileData) {
					if (canBuyParent) {
						parentName.isDuns = true;
					}
					parentName.name = client.soliditet.profileData.parentCompanyName;
					parentName.id = client.soliditet.profileData.parentCompanyDunsNumber;
				}
			}

			var related = {
				id: activity.id,
				type: 'Activity',
				closeDate: activity.closeDate
			};

			if (client.phone) {
				phoneClass += ' has-input';
				phoneLink = ReactTemplates.TOOLS.upVoice(client, 'client', client.phone, related);
			}

			if (client.webpage) {
				webpageClass += ' has-input';
			}

			var customerId = window.Tools.AppService.getCustomerId();
			accountDisplay = (
				<div className="sidebar-section no-margin">
					{client.userEditable ? (
						<button
							type="button"
							className="edit-account up-btn btn-link btn-grey"
							onClick={self.props.onEditAccount}
						>
							<b className="fa fa-edit"></b>
						</button>
					) : null}

					<h3 className="sidebar-account-title">
						<a href={'#/' + customerId + '/accounts/' + client.id + '/'}>
							{client.name.length ? client.name : self.lang.noName}
						</a>
					</h3>

					<JourneyStepDisplay
						id={client.id}
						journeyStep={client.journeyStep}
						onSetJourneyStep={this.props.onSetJourneyStep}
					/>

					{React.createElement(ReactTemplates.TOOLS.leadStatus, {
						activity: client.hasActivity,
						activityOld: client.hadActivity,
						appointment: client.hasAppointment,
						appointmentOld: client.hadAppointment,
						opportunity: client.hasOpportunity,
						opportunityOld: client.hadOpportunity,
						order: client.hasOrder,
						orderOld: client.hadOrder,
						size: 'sm'
					})}

					{React.createElement(ReactTemplates.TOOLS.eventTypes, {
						account: client,
						size: 'sm'
					})}

					{React.createElement(ReactTemplates.TOOLS.upRatingLabel, {
						account: client
					})}

					{visitAddr ? (
						<div className="sidebar-account-address">
							{visitAddr.country ? (
								<b
									style={{ marginRight: '4px' }}
									className={'icon flag-icon flag-icon-' + visitAddr.country.toLowerCase()}
								></b>
							) : null}
							{!visitAddr.country && visitAddr.city ? <b className="icon fa fa-map-marker"></b> : null}
							{visitAddr.city ? (
								<a href={'#/' + customerId + '/accounts/' + client.id + '/addresses/'}>
									{visitAddr.city}
								</a>
							) : null}
						</div>
					) : null}

					<div className={phoneClass} key="phone-section">
						<b className="fa fa-phone"></b>
						<span>
							{' '}
							{self.lang.noSet} {self.lang.phone.toLowerCase()}
						</span>
						{phoneLink ? (
							<a
								className="ellipsis"
								href={phoneLink.href}
								target={phoneLink.telTarget}
								onClick={phoneLink.onClick}
							>
								{client.phone}
							</a>
						) : null}
					</div>

					<div className={webpageClass} key="webpage-section">
						<b className="fa fa-globe"></b>
						<span>
							{' '}
							{self.lang.noSet} {self.lang.webpage.toLowerCase()}
						</span>
						<a
							className="ellipsis"
							target="_blank"
							href={client.webpage}
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								self.props.goToUrl(client.webpage, true);
							}}
						>
							{client.webpage}
						</a>
					</div>

					{hasParent ? (
						<div>
							<span>
								{self.lang.parentCompany}
								{':'}{' '}
							</span>
							{canBuyParent ? (
								<a
									onClick={function () {
										if (parentName.isDuns) {
											// eslint-disable-next-line promise/catch-or-return
											openConfirmSoliditetBuyParentModal({
												customerId: customerId,
												name: parentName.name,
												duns: parentName.id
											}).then(account => {
												const data = { id: client.id, parent: { id: account.id } };
												Tools.Account.customer(customerId).save(data, {
													skipNotification: true,
													skipErrorNotification: true
												});
												Tools.$state.go('account.dashboard', { id: account.id });
											});
										} else {
											self.parentCompanyClick(parentName.id);
										}
									}}
								>
									{self.generateLinkName(parentName)}
								</a>
							) : (
								<span>{parentName.name}</span>
							)}
						</div>
					) : null}

					{client.projects && client.projects.length
						? ReactTemplates.TOOLS.withTooltip(
								<button
									type="button"
									className="btn btn-sm up-btn btn-link btn-bright-blue sidebar-list-trigger"
									onClick={self.listModal.bind(self, client.projects, 'project')}
								>
									{client.projects.length}{' '}
									{client.projects.length === 1 ? self.lang.campaign : self.lang.campaigns}
								</button>,
								self.getTooltip(client.projects),
								{ placement: 'right' }
						  )
						: null}

					{client.categories && client.categories.length
						? ReactTemplates.TOOLS.withTooltip(
								<button
									type="button"
									className="btn btn-sm up-btn btn-link btn-bright-blue sidebar-list-trigger"
									onClick={self.listModal.bind(self, client.categories, 'category')}
								>
									{client.categories.length}{' '}
									{client.categories.length === 1 ? self.lang.category : self.lang.categories}
								</button>,
								self.getTooltip(client.categories),
								{ placement: 'right' }
						  )
						: null}
				</div>
			);
		} else {
			var selectClasses = 'sidebar-section form-group no-margin';

			if (this.props.formDirty || this.props.formSubmitted) {
				selectClasses += ' has-error';
			}

			accountSelect = (
				<div id="select-account-section" className={selectClasses}>
					<label htmlFor="account-select">
						{self.lang.account} <b className="text-danger">{'*'}</b>
					</label>

					{React.createElement(ReactTemplates.INPUTS.upAccounts, {
						id: 'account-select',
						name: 'account',
						value: activity.client,
						required: true,
						tabIndex: 1,
						onChange: self.accountChange
					})}
				</div>
			);
		}

		return (
			<div>
				{accountSelect}

				{accountDisplay}
			</div>
		);
	}
});
