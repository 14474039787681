import React, { lazy, Suspense, useMemo } from 'react';
import {
	matchPath,
	Switch,
	useRouteMatch,
	Redirect,
	useLocation,
	generatePath,
	Route as ReactRoute
} from 'react-router-dom';
import Route from './route';
const MailTemplates = lazy(() => import('App/pages/MailTemplates'));
import MailTemplatesAllowed from 'App/pages/MailTemplates/allowed';
const ListUserDefinedObjects = lazy(() => import('App/pages/ListUserDefinedObjects'));
import ListUserDefinedObjectsAllowed from 'App/pages/ListUserDefinedObjects/allowed';
const GroupMailEditor = lazy(() => import('Components/GroupMailEditor'));
const MailTemplateEditor = lazy(() => import('Components/MailTemplateEditor'));
const DocumentTemplateEditor = lazy(() => import('Components/DocumentTemplateEditor'));
const DetectedDuplicates = lazy(() => import('Components/DetectedDuplicates'));
const DetectedDuplicatesLandingPage = lazy(() => import('App/components/DetectedDuplicatesLandingPage'));
const CalendarView = lazy(() => import('Components/CalendarView/CalendarView'));
const ChangeBrands = lazy(() => import('Components/Brands/ChangeBrands'));
const MarketingBoard = lazy(() => import('App/pages/MarketingBoard'));
import MarketingBoardAllowed from 'App/pages/MarketingBoard/allowed';
const Salesboard = lazy(() => import('App/pages/Salesboard/SalesboardRouter'));
import SalesboardAllowed from 'App/pages/Salesboard/allowed';
const FormEditor = lazy(() => import('Components/FormEditor'));
const FormOverview = lazy(() => import('Components/FormOverview'));
const TodoList = lazy(() => import('App/pages/TodoList'));
import TodoListAllowed from 'App/pages/TodoList/allowed';
const TaskList = lazy(() => import('App/pages/TaskList'));
import TaskListAllowed from 'App/pages/TodoList/allowed';
const Prospecting = lazy(() => import('Components/Prospecting'));
import ProspectingAllowed from 'Components/Prospecting/allowed';
const Signals = lazy(() => import('App/pages/Prospecting/Signals'));
import SignalsAllowed from 'App/pages/Prospecting/Signals/allowed';
const EditSocialEvent = lazy(() => import('Components/SocialEvent/EditSocialEvent'));
import EditSocialEventAllowed from 'Components/SocialEvent/allowed';
const Reportcenter = lazy(() => import('App/pages/Reportcenter'));
import ReportcenterAllowed from 'App/pages/Reportcenter/allowed';
import history from './history';
import LoaderPage from './LoaderPage';
const ListCampaigns = lazy(() => import('../ListCampaigns'));
import CallListsAllowed from 'App/pages/ListCampaigns/CallListsAllowed';
const ListForms = lazy(() => import('App/pages/ListFormsAndLandingPages/ListForms'));
import ListFormsAllowed from 'App/pages/ListFormsAndLandingPages/allowed';
const ListLandingPages = lazy(() => import('App/pages/ListFormsAndLandingPages/ListLandingPages'));
import ListLandingPagesAllowed from 'App/pages/ListFormsAndLandingPages/allowed';
const ListSocialEvents = lazy(() => import('App/pages/ListSocialEvents'));
import ListSocialEventsAllowed from 'App/pages/ListSocialEvents/allowed';
import ListFlowsAllowed from '../ListFlows/allowed';
const ListFlows = lazy(() => import('../ListFlows'));
const ListMail = lazy(() => import('../ListMail'));
const ListMailCampaigns = lazy(() => import('../ListMailCampaigns'));
import ListMailCampaignsAllowed from '../ListMailCampaigns/allowed';
const AccountGrowth = lazy(() => import('App/pages/AccountGrowth'));
import AccountGrowthAllowed from 'App/pages/AccountGrowth/allowed';
const CompanyGroupCard = lazy(() => import('App/pages/CompanyGroupCard'));
import CompanyGroupCardAllowed from 'App/pages/CompanyGroupCard/allowed';
const ListSegments = lazy(() => import('../ListSegments'));
const FormSubmits = lazy(() => import('App/pages/ListFormSubmits'));
import FormSubmitsAllowed from 'App/pages/ListFormSubmits/allowed';
const ListOrders = lazy(() => import('../ListOrders'));
import ListOrdersAllowed from 'App/pages/ListOrders/allowed';
const ListVisitors = lazy(() => import('App/pages/ListVisits'));
import ListVisitorsAllowed from 'App/pages/ListVisits/allowed';
const ListContacts = lazy(() => import('App/pages/ListContacts'));
import ListContactsAllowed from 'App/pages/ListContacts/allowed';
const ListAgreements = lazy(() => import('App/pages/ListAgreements'));
import ListAgreementsAllowed from 'App/pages/ListAgreements/allowed';
const ListOpportunities = lazy(() => import('App/pages/ListOpportunities'));
import ListOpportunitiesAllowed from 'App/pages/ListOpportunities/allowed';
const ListPhoneCalls = lazy(() => import('App/pages/ListPhoneCalls'));
import ListPhoneCallsAllowed from 'App/pages/ListPhoneCalls/allowed';
const ListLeads = lazy(() => import('../ListLeads'));
import ListLeadsAllowed from '../ListLeads/allowed';
const ClientCard = lazy(() => import('../ClientCard'));
import ClientCardAllowed from '../ClientCard/allowed';
const CustomerPortfolio = lazy(() => import('../CustomerPortfolio'));
import CustomerPortfolioAllowed from '../CustomerPortfolio/allowed';
const AdvancedSearch = lazy(() => import('../AdvancedSearch'));
import AdvancedSearchAllowed from '../AdvancedSearch/allowed';
import { AccountSelf, Self } from 'App/resources/AllIWant';
const Admin = lazy(() => import('../Admin/AdminRoutes'));
import ListProjectPlanAllowed from 'App/pages/ListProjectPlans/allowed';
const ListProjectPlan = lazy(() => import('App/pages/ListProjectPlans'));
import ProjectBoardAllowed from 'App/pages/ProjectBoard/allowed';
const ProjectBoard = lazy(() => import('App/pages/ProjectBoard'));
const Matcher = lazy(() => import('../Matcher/Matcher'));
const SupportList = lazy(() => import('../SupportList'));
import SupportListAllowed from '../SupportList/allowed';
import InsightsAllowed from 'Components/Insights/InsightsAllowed';
import MatcherAllowed from '../Matcher/allowed';
const MailCampaign = lazy(() => import('App/pages/MailCampaign/MailCampaign'));
import MailCampaignAllowed from '../MailCampaign/allowed';
const Insights = lazy(async () => import('Components/Insights/Insights').then(m => ({ default: m.PortedInsights })));
const PortedManageAccount = lazy(async () =>
	import('App/pages/ManageAccount/ManageAccount').then(m => ({ default: m.PortedManageAccount }))
);
const PortedLandingpageEditorRoute = lazy(async () =>
	import('App/components/LandingpageEditor/LandingpageEditor').then(m => ({
		default: m.PortedLandingpageEditorRoute
	}))
);
const EditFlow = lazy(async () =>
	import('App/pages/EditFlowReactWrapper/EditFlowReactWrapper').then(m => ({ default: m.PortedEditFlow }))
);
const FlowEditor = lazy(async () => import('App/pages/FlowEditor/FlowEditor'));
const WhoCalled = lazy(() => import('App/pages/WhoCalled'));
const AdminInternalSalesboard = lazy(
	() => import('App/pages/Admin/subPages/AdminInternalSalesboard/AdminInternalSalesboard')
);

export const isAdmin = () => {
	const self = Tools.AppService.getSelf();
	return self.administrator;
};

// Notify analytics that the location have changed
export const notifyAnalytics = () => {
	// Disabled since this means we double track everything.
	// When all routes are in react we can do tracking from here
	// and remove the tracking in app/upsales/app.js
	// import Analytics from 'App/services/Analytics';
	// Analytics.page();
};

history.listen?.(() => notifyAnalytics());

export type RouteConfig = {
	path: string;
	component: React.LazyExoticComponent<any> | React.ComponentType<any>;
	sub?: string[];
	queryParams?: string;
	/** Used when setting up compatibility with angular */
	name?: string;
	/** Should be set to true for ported routes have the same path as an old angular route.
	 * This skips setting up an angular react-root-* compatibility state in app.js */
	sameAngularPath?: boolean;
	section?: 'sale' | 'market' | 'followup' | 'admin' | 'support';
	allowed?: () => boolean;
	public?: boolean;
};

export const routes: RouteConfig[] = [
	{
		path: '/group-mail-editor/:id',
		component: GroupMailEditor,
		sub: ['/design', '/recipients', '/settings'],
		section: 'market'
	},
	{
		path: '/calendar/',
		queryParams: '?date?selected',
		component: CalendarView,
		name: 'calendar',
		section: 'sale'
	},
	{
		path: '/mail-template-editor/:id',
		component: MailTemplateEditor,
		sub: ['/design', '/settings'],
		section: 'market'
	},
	{
		path: '/marketingboard',
		component: MarketingBoard,
		allowed: MarketingBoardAllowed,
		section: 'market',
		name: 'marketingboard'
	},
	{
		path: '/salesboard/:id',
		component: Salesboard,
		allowed: SalesboardAllowed,
		section: 'sale',
		name: 'salesboard'
	},
	{
		path: '/salesboard/',
		component: Salesboard,
		allowed: SalesboardAllowed,
		section: 'sale',
		name: 'salesboard'
	},
	{
		path: '/:customerId/detected-duplicates/:entity',
		component: DetectedDuplicates,
		allowed: isAdmin,
		name: 'detected-duplicates',
		section: 'sale'
	},
	{
		path: '/detected-duplicates-landing-page/:entity',
		component: DetectedDuplicatesLandingPage,
		allowed: isAdmin,
		name: 'detected-duplicates-landing-page',
		section: 'sale'
	},
	{
		path: '/changebrand/:id',
		component: ChangeBrands,
		name: 'change-brand',
		section: 'sale'
	},
	{
		path: '/form-editor/:id',
		component: FormEditor,
		section: 'market'
	},
	{
		path: '/form-overview/:id',
		component: FormOverview,
		name: 'form-overview',
		section: 'market'
	},
	{
		path: '/:customerId/events/edit/:id?',
		component: EditSocialEvent,
		name: 'editSocialEvent',
		allowed: EditSocialEventAllowed,
		section: 'market'
	},
	{
		path: '/todo/:view?',
		name: 'todo',
		component: TodoList,
		allowed: TodoListAllowed,
		section: 'sale'
	},
	{
		path: '/task',
		name: 'task',
		component: TaskList,
		allowed: TaskListAllowed,
		section: 'sale'
	},
	{
		path: '/mailtemplates',
		name: 'mailtemplates',
		component: MailTemplates,
		allowed: MailTemplatesAllowed,
		section: 'market'
	},
	{
		path: '/userdefinedobjects/:typeId',
		name: 'userdefinedobjects',
		component: ListUserDefinedObjects,
		allowed: ListUserDefinedObjectsAllowed,
		section: 'sale'
	},
	{
		path: '/rc/:dashboardId?',
		name: 'reportcenter',
		component: Reportcenter,
		allowed: ReportcenterAllowed,
		section: 'followup'
	},
	{
		path: '/signals',
		name: 'signals',
		component: Signals,
		allowed: SignalsAllowed,
		section: 'sale'
	},
	{
		path: '/campaigns',
		name: 'campaigns',
		component: ListCampaigns,
		section: 'sale'
	},
	{
		path: '/callLists',
		name: 'calllists',
		component: ListCampaigns,
		allowed: CallListsAllowed,
		section: 'sale'
	},
	{
		path: '/forms',
		name: 'forms',
		component: ListForms,
		allowed: ListFormsAllowed,
		section: 'market'
	},
	{
		path: '/landingpages',
		name: 'landingpages',
		component: ListLandingPages,
		allowed: ListLandingPagesAllowed,
		section: 'market'
	},
	{
		path: '/mail',
		name: 'mail',
		component: ListMail,
		section: 'sale'
	},
	{
		path: '/mail-campaigns',
		name: 'mail-campaigns',
		component: ListMailCampaigns,
		allowed: ListMailCampaignsAllowed,
		section: 'market'
	},
	{
		path: '/:customerId/mail-campaign/:id/:subPage?',
		name: 'mail-campaigns',
		queryParams: '?filter?drill',
		component: MailCampaign,
		sameAngularPath: true,
		allowed: MailCampaignAllowed,
		section: 'market'
	},
	{
		path: '/events',
		name: 'events',
		component: ListSocialEvents,
		allowed: ListSocialEventsAllowed,
		section: 'market'
	},
	{
		path: '/prospecting',
		queryParams: '?box',
		name: 'prospecting',
		component: Prospecting,
		allowed: ProspectingAllowed,
		section: 'sale'
	},
	{
		path: '/:customerId/soliditet/matcher',
		name: 'matcher',
		queryParams: '?campaignId',
		component: Matcher,
		allowed: MatcherAllowed,
		sameAngularPath: true,
		section: 'sale'
	},
	{
		path: '/flows',
		name: 'flows',
		component: ListFlows,
		allowed: ListFlowsAllowed,
		section: 'market'
	},
	{
		path: '/accountGrowth',
		name: 'accountGrowth',
		component: AccountGrowth,
		allowed: AccountGrowthAllowed,
		section: 'sale'
	},
	{
		path: '/companyGroup/:prospectingId/:view?',
		name: 'companyGroup',
		component: CompanyGroupCard,
		allowed: CompanyGroupCardAllowed,
		section: 'sale'
	},
	{
		path: '/segments',
		name: 'segments',
		component: ListSegments,
		section: 'market'
	},
	{
		path: '/form-submits',
		name: 'formSubmits',
		queryParams: '?f',
		component: FormSubmits,
		allowed: FormSubmitsAllowed,
		section: 'market'
	},
	{
		path: '/orders',
		name: 'orders',
		component: ListOrders,
		allowed: ListOrdersAllowed,
		section: 'sale'
	},
	{
		path: '/visitors',
		name: 'visitors',
		component: ListVisitors,
		allowed: ListVisitorsAllowed,
		section: 'market'
	},
	{
		path: '/leads2',
		name: 'leads',
		component: ListLeads,
		allowed: ListLeadsAllowed,
		section: 'market'
	},
	{
		path: '/companies',
		name: 'companies',
		component: AccountGrowth,
		allowed: AccountGrowthAllowed,
		section: 'sale'
	},
	{
		path: '/contacts',
		name: 'contacts',
		component: ListContacts,
		allowed: ListContactsAllowed,
		section: 'sale'
	},
	{
		path: '/agreements',
		name: 'agreements',
		component: ListAgreements,
		allowed: ListAgreementsAllowed,
		section: 'sale'
	},
	{
		path: '/projects',
		name: 'projects',
		component: ListProjectPlan,
		allowed: ListProjectPlanAllowed,
		section: 'sale'
	},
	{
		path: '/project-board',
		name: 'projectBoard',
		component: ProjectBoard,
		allowed: ProjectBoardAllowed,
		section: 'sale'
	},
	{
		path: '/opportunities',
		name: 'opportunities',
		component: ListOpportunities,
		allowed: ListOpportunitiesAllowed,
		section: 'sale'
	},
	{
		path: '/phoneCalls',
		name: 'phonecalls',
		component: ListPhoneCalls,
		allowed: ListPhoneCallsAllowed,
		section: 'sale'
	},
	{
		path: '/clientCard/:id/:page',
		name: 'clientCard',
		component: ClientCard,
		allowed: ClientCardAllowed,
		section: 'sale'
	},
	{
		path: '/document-template-editor/:id',
		component: DocumentTemplateEditor,
		sub: ['/details', '/design'],
		section: 'sale'
	},
	{
		path: '/customerPortfolio/:tab?',
		name: 'customerPortfolio',
		component: CustomerPortfolio,
		allowed: CustomerPortfolioAllowed,
		section: 'sale'
	},
	{
		path: '/advanced-search/:type?',
		name: 'advancedSearch',
		component: AdvancedSearch,
		allowed: AdvancedSearchAllowed,
		section: 'sale'
	},
	{
		path: '/support/:view?',
		name: 'support',
		component: SupportList,
		allowed: SupportListAllowed,
		section: 'support'
	},
	{
		path: '/admin/:page?/:subpage?',
		name: 'admin',
		sameAngularPath: true,
		component: Admin,
		section: 'admin'
	},
	{
		path: '/admin-internal/salesboard/:id',
		name: 'internalSalesboard',
		component: AdminInternalSalesboard,
		allowed: isAdmin,
		section: 'admin'
	},
	{
		path: '/:customerId/insights/:type?',
		queryParams: '?id?useNext',
		allowed: InsightsAllowed,
		sameAngularPath: true,
		component: Insights,
		section: 'followup'
	},
	{
		path: '/manageAccount',
		sameAngularPath: true,
		component: PortedManageAccount,
		section: 'sale'
	},
	{
		path: '/:customerId/forms/landingpageEditor/:id?',
		queryParams: '?copy',
		component: PortedLandingpageEditorRoute,
		section: 'market'
	},
	{
		path: '/:customerId/flows/:id/',
		component: EditFlow,
		sameAngularPath: true,
		section: 'market'
	},
	{
		path: '/:customerId/flow/standalone/:id',
		queryParams: '?preset?segmentId?showSelectionTooltip',
		component: FlowEditor,
		sameAngularPath: true,
		section: 'market'
	},
	{
		path: '/:customerId/flow/:segmentId',
		queryParams: '?preset?showSelectionTooltip',
		component: FlowEditor,
		sameAngularPath: true,
		section: 'market'
	},
	{
		path: '/who-called/:number',
		sameAngularPath: true,
		component: WhoCalled,
		section: 'sale'
	}
];

type RedirectRouteConfig = {
	from: string;
	/** Should match a valid react route (e.g. one from the list above) */
	to: string;
	/** Only used when setting up compatibility with angular */
	queryParams?: string;
	exact?: boolean;
	/** Function to decide if the reroute should happen. You can use this to check feature flags */
	shouldRedirect?: (scope: { params: Record<string, string | number>; search: URLSearchParams }) => boolean;
	paramMapper?: (scope: {
		params: Record<string, string | number>;
		search: URLSearchParams;
	}) => Record<string, string | number>;
	searchParamsMapper?: (scope: {
		params: Record<string, string | number>;
		search: URLSearchParams;
	}) => URLSearchParams;
	debug?: boolean;
};

// Routes that can redirect to a different url. This is mainly used for keeping compatibility with old angular routes
export const redirectRoutes: RedirectRouteConfig[] = [
	{
		from: '/:customerId/admin/salesboards',
		to: '/admin/salesboards',
		shouldRedirect: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_SALESBOARDS')
	},
	{
		from: '/:customerId/admin/salesboard/:id',
		to: '/admin-internal/salesboard/:id',
		shouldRedirect: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_INTERNAL_SALESBOARD')
	},
	{
		from: '/:customerId/up-admin/journeyEditor/:type?/:id?',
		to: '/admin/journeyEditor/:type?/:id?',
		shouldRedirect: () => Tools.FeatureHelper.hasSoftDeployAccess('PORT_ADMIN_JOURNEY_EDITOR')
	},
	{
		from: '/:customerId/up-admin/integrations/edit/:id?',
		to: '/admin/integrations/edit/:id?',
		shouldRedirect: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_EDIT_INTEGRATION')
	},
	{
		from: '/:customerId/up-admin/integrations/configure/:id/:configure?',
		to: '/admin/integrations/configure/:id/:configure?',
		queryParams: '?code?setOauthToNull?tab?oAuthFieldName',
		shouldRedirect: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_CONFIGURE_INTEGRATION')
	},
	{
		from: '/:customerId/up-admin/:page?/:subpage?',
		to: '/admin/:page/:subpage?',
		shouldRedirect: ({ params }) => {
			const { page = 'dashboard', subpage } = params;

			if (page === 'dashboard' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_DASHBOARD')) {
				return true;
			}
			if (page === 'domains' && Tools.FeatureHelper.hasSoftDeployAccess('PORT_ADMIN_DOMAINS')) {
				return true;
			}
			if (page === 'reminders' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_REMINDERS')) {
				return true;
			}
			if (
				page === 'default-mail-signature' &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_EMAIL_SIGNATURES')
			) {
				return true;
			}
			if (page === 'journeyStatuses' && Tools.FeatureHelper.hasSoftDeployAccess('PORT_ADMIN_JOURNEY_STATUSES')) {
				return true;
			}
			if (
				page === 'notification-center' &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_NOTIFICATIONS')
			) {
				return true;
			}
			if (page === 'optin' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_OPTIN')) {
				return true;
			}
			if (page === 'sign-in-stats' && Tools.FeatureHelper.hasSoftDeployAccess('PORT_LOGIN_STATS')) {
				return true;
			}
			if (page === 'triggers' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_TRIGGERS')) {
				return true;
			}
			if (
				page === 'appointment-availability' &&
				Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_APPOINTMENTAVAILABILITY')
			) {
				return true;
			}
			if (
				page === 'prospecting-signals' &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_PROSPECTING_SIGNALS')
			) {
				return true;
			}
			if (page === 'salesprocesses' && Tools.FeatureHelper.hasSoftDeployAccess('PORT_ADMIN_SALES_PROCESSES')) {
				return true;
			}
			if (page === 'automation' && Tools.FeatureHelper.hasSoftDeployAccess('PORT_ADMIN_AUTOMATION')) {
				return true;
			}
			if (page === 'profile' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_PROFILE')) {
				return true;
			}
			if (page === 'pipeline-goals' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_PIPELINEGOALS')) {
				return true;
			}
			if (page === 'anonymization' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_ANONYMIZATION')) {
				return true;
			}
			if (page === 'fields' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_ADMIN_FIELDS')) {
				return true;
			}
			if (page === 'activitytypes' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_ACTIVITY_TYPES')) {
				return true;
			}
			if (page === 'mailsettings' && Tools.FeatureHelper.hasSoftDeployAccess('PORT_ADMIN_MAIL_SETTINGS')) {
				return true;
			}
			if (page === 'optOut' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_OPTOUT')) {
				return true;
			}
			if (page === 'orderstages' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_ADMIN_ORDERSTAGES')) {
				return true;
			}
			if (page === 'automations' && Tools.FeatureHelper.hasSoftDeployAccess('PORT_ADMIN_AUTOMATIONS')) {
				return true;
			}
			if (page === 'products' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_PRODUCTS')) {
				return true;
			}
			if (page === 'activeInvites' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_ACTIVE_INVITES')) {
				return true;
			}
			if (page === 'looker' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_ADMIN_LOOKER')) {
				return true;
			}
			if (
				(page === 'scripts' || page === 'script') &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_SCRIPTS')
			) {
				return true;
			}
			if (
				page === 'completed-first-appointment-goals' &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_COMPLETED_FIRST_APPOINTMENT_GOALS')
			) {
				return true;
			}
			if (
				page === 'booked-first-appointment-goals' &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_BOOKED_FIRST_APPOINTMENT')
			) {
				return true;
			}
			if (
				page === 'security' &&
				subpage === 'apiKeys' &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_API_KEYS')
			) {
				return true;
			}
			if (page === 'sharedviews' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_SHARED_VIEWS')) {
				return true;
			}
			if (
				page === 'security' &&
				subpage === '2fa' &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_2FA')
			) {
				return true;
			}
			if (
				page === 'marketRejectlist' &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_MARKET_REJECT_LIST')
			) {
				return true;
			}
			if (page === 'agreement' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_AGREEMENT')) {
				return true;
			}
			if (page === 'usersAndRoles' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_USERS_AND_ROLES')) {
				return true;
			}
			if (page === 'currencies' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_ADMIN_CURRENCIES')) {
				return true;
			}
			if (page === 'billing' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_ADMIN_BILLING')) {
				return true;
			}
			if (
				page === 'documentTemplates' &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_DOCUMENT_TEMPLATES')
			) {
				return true;
			}
			if (
				page === 'integrations' &&
				!subpage &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_INTEGRATIONS')
			) {
				return true;
			}
			if (page === 'mailSignature' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_MAILSIGNATURE')) {
				return true;
			}
			if (page === 'visit' && Tools.FeatureHelper.hasSoftDeployAccess('PORT_ADMIN_VISIT_SETTINGS')) {
				return true;
			}
			if (page === 'brands' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_BRAND')) {
				return true;
			}
			if (page === 'accountProfile' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_ACCOUNT_PROFILE')) {
				return true;
			}
			if (page === 'ip-block' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_ADMIN_IP_BLOCK')) {
				return true;
			}
			if (
				page === 'shared-appointment-availability' &&
				subpage !== undefined &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_EASY_BOOKING_PRO_SETTINGS')
			) {
				return true;
			}

			if (
				page === 'userdefinedobjects' &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_USER_DEFINED_OBJECTS')
			) {
				return true;
			}
			if (page === 'salesboard-card' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_SALESBOARDCARD')) {
				return true;
			}
			if (page === 'oneoff-quota' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_GENERIC_QUOTA')) {
				return true;
			}
			if (page === 'subscription-quota' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_GENERIC_QUOTA')) {
				return true;
			}
			if (page === 'sales-margin-quota' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_GENERIC_QUOTA')) {
				return true;
			}
			if (page === 'pricelists' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_PRICELISTS')) {
				return true;
			}
			if (page === 'accountsettings' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_ACCOUNT_SETTINGS')) {
				return true;
			}
			if (
				page === 'activity-quota' &&
				Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_ADMIN_ACTIVITY_QUOTA')
			) {
				return true;
			}
			if (
				page === 'appointment-editor' &&
				Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_ADMIN_APPOINTMENT_EDITOR')
			) {
				return true;
			}
			if (page === 'sales-quota' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_ADMIN_SALES_QUOTA')) {
				return true;
			}
			if (page === 'salesboards' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_SALESBOARDS')) {
				return true;
			}
			if (page === 'deletelog' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_DELETE_LOGS')) {
				return true;
			}
			if (page === 'salesboard' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_SALESBOARD')) {
				return true;
			}
			if (
				page === 'completed-appointment-goals' &&
				Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_COMPLETED_APPOINTMENT_GOALS')
			) {
				return true;
			}
			if (page === 'dynamiclinks' && Tools.FeatureHelper.hasSoftDeployAccess('PORTED_ROUTE_DYNAMIC_LINKS')) {
				return true;
			}
			if (page === 'phonecall-goals' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_PHONECALL_GOALS')) {
				return true;
			}
			if (page === 'trigger' && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_TRIGGERS')) {
				return true;
			}

			return false;
		},
		paramMapper: ({ params }) => {
			const { page = 'dashboard' } = params;
			const paramRes = { page, subpage: params.subpage };

			if (page === 'default-mail-signature') {
				paramRes.page = 'email-signatures';
			}
			if (page === 'notification-center') {
				paramRes.page = 'notifications';
			}

			return paramRes;
		}
	}
];

const RedirectRoute = ({ redirectConfig }: { redirectConfig: (typeof redirectRoutes)[number] }) => {
	const { params } = useRouteMatch();
	const { search } = useLocation();

	const query = useMemo(() => new URLSearchParams(search), [search]);

	const {
		from,
		to,
		shouldRedirect = () => true,
		paramMapper = ({ params }) => params,
		searchParamsMapper = ({ search }) => search,
		debug = false
	} = redirectConfig;

	if (debug) {
		console.log('Hit redirect route', {
			from,
			to,
			params,
			search
		});
	}

	const routeScope = { params, search: query };

	if (!shouldRedirect(routeScope)) {
		if (debug) {
			console.log('shouldRedirect() returned false - aborting redirect');
		}
		return null;
	}

	const mappedParams = paramMapper(routeScope);
	const finalSearch = searchParamsMapper(routeScope)?.toString() || '';

	let finalPath = generatePath(to, mappedParams);

	if (finalSearch.length > 0) {
		finalPath += `?${finalSearch}`;
	}

	if (debug) {
		console.log(`Redirecting to ${finalPath}`, { mappedParams });
	}

	return <Redirect key={from} to={finalPath} />;
};

export const getSectionFromPath = (path: string, accountSelf: AccountSelf, self: Self) => {
	let section = null;

	// Match path against all out route configs
	section = routes.find(r => (matchPath(path, { path: r.path }) ? r : null))?.section;

	// As long as we have our angular routes we also need to look there
	if (!section) {
		const state = Tools.$state.current;
		section = state.section;
		// This is stupid and should probably be removed when we have all routes in react
		if (state.sectionFn && typeof state.sectionFn === 'function') {
			section = state.sectionFn(accountSelf, self, Tools.$state.params, Tools.$location);
		}
	}

	return section || null;
};

const Routes = () => {
	return (
		<Suspense fallback={<LoaderPage />}>
			<Switch>
				{redirectRoutes.map(r => (
					<ReactRoute
						key={r.from}
						path={r.from}
						exact={r.exact}
						component={() => <RedirectRoute redirectConfig={r} />}
					></ReactRoute>
				))}
				{routes.map(r => (
					<Route key={r.path} allowed={r.allowed} path={r.path} public={r.public} component={r.component} />
				))}
				{/* fallback route when in angular */}
				<Route component={() => null} />
			</Switch>
		</Suspense>
	);
};

export default Routes;
