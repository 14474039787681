'use strict';

(function () {
	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var componentDidMount = function (self) {
		var Campaign = Tools.Campaign;
		var AppService = Tools.AppService;
		var $state = Tools.$state;

		var opts = {
			multiple: self.props.multiple || false,
			required: self.props.required || false,
			onChange: self.props.onChange,
			disabled: self.props.disabled || false,
			resource: Campaign,
			cachedTotal: function () {
				if (self.props.showInactive === undefined) {
					return AppService.getTotals('activeCampaigns');
				} else {
					return AppService.getTotals('campaigns');
				}
			},
			placeholder: function () {
				return self.props.placeholder;
			},
			ajax: true,
			asIds: false,
			idAttr: Campaign.attr.id,
			titleAttr: Campaign.attr.name,
			addEvent: 'campaign.added',
			sorting: { field: Campaign.attr.name, ascending: true },
			filterType: 'campaign',
			goTo: function () {
				return $state.go('campaign.dashboard', { id: self.props.value.id }, { reload: true });
			},
			linked: self.props.linked || false,
			filters: function (rb) {
				if (self.props.showInactive === undefined) {
					rb.addFilter(Campaign.attr.active, rb.comparisonTypes.Equals, true);
				}
			},
			select2: self.props.select2 || {}
		};

		var input = jQuery(self._input);

		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
			input.select2(options);
		});
	};

	ReactTemplates.INPUTS.upCampaigns = window.ReactCreateClass({
		componentDidMount: function () {
			componentDidMount(this, false);
		},
		componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
		render: render
	});
})();
