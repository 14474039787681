import { Toggle } from '@upsales/components';
import Breadcrumbs, { BreadcrumbItem } from 'App/upsales/common/components/react/Breadcrumbs';

ReactTemplates.admin.script = window.ReactCreateClass({
	loaded: false,

	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},
	getStateFromProps: function (props) {
		return {
			script: props.rootData.pageData.script,
			fullscreen: false
		};
	},

	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			scripts: t('admin.scripts'),
			scriptsInfo: t('admin.scriptsInfo'),
			description: t('default.description'),
			active: t('default.active'),
			inactive: t('default.inactive'),
			newScript: t('admin.newScript'),
			noScripts: t('admin.noScripts'),
			triggers: {
				order_edit: t('admin.script.order_edit'),
				order_save: t('admin.script.order_save'),
				activity_edit: t('admin.script.activity_edit'),
				activity_save: t('admin.script.activity_save'),
				client_open: t('admin.script.client_open'),
				client_contacts_open: t('admin.script.client_contacts_open'),
				client_dashboard_open: t('admin.script.client_dashboard_open'),
				client_save: t('admin.script.client_save'),
				client_edit: t('admin.script.client_edit'),
				contact_open: t('admin.script.contact_open'),
				agreement_edit: t('admin.script.agreement_edit'),
				agreement_save: t('admin.script.agreement_save'),
				salesboard_open: t('admin.script.salesboard_open'),
				contact_save: t('admin.script.contact_save'),
				upsales_init: t('admin.script.upsales_init'),
				appointment_edit: t('admin.script.appointment_edit'),
				appointment_save: t('admin.script.appointment_save'),
				contact_edit: t('admin.script.contact_edit'),
				flash_edit: t('admin.script.flash_edit'),
				flash_save: t('admin.script.flash_save'),
				mail_edit: t('admin.script.mail_edit'),
				project_edit: t('admin.script.project_edit'),
				project_save: t('admin.script.project_save')
			},
			edit: t('default.edit'),
			scriptWhen: t('admin.scriptEntity'),
			scriptsActive: t('admin.scriptsActive'),
			scriptsRunning: t('admin.scriptsRunning'),
			nameYourScript: t('admin.scriptName'),
			editScript: t('admin.editScript')
		};

		this.selectOptions = {
			sort: {
				field: 'name',
				ascending: true
			},
			id: 'id',
			title: 'name',
			dropdownCssClass: 'script-select',
			matcher: function (term, undef, item) {
				return item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
			}
		};
	},
	onFieldChange: function (key, e) {
		var script = this.state.script;
		script[key] = e.target.value;
		this.setState({ script: script });
		this.props.rootData.onChange(script);
	},
	onToggleChange: function (key) {
		var script = this.state.script;
		if (script[key]) {
			script[key] = false;
		} else {
			script[key] = true;
		}

		this.setState({ script: script });
		this.props.rootData.onChange(script);
	},
	getWhen: function (type) {
		return this.lang.triggers[type] || type;
	},
	componentDidMount: function () {
		var self = this;
		self.editor = ace.edit(this._jsonEditor);
		self.editor.setTheme('ace/theme/monokai');
		self.editor.setShowPrintMargin(false);
		self.editor.$blockScrolling = Infinity;

		var editorSession = this.editor.getSession();
		editorSession.setUseWrapMode(false);
		editorSession.setMode('ace/mode/javascript');
		editorSession.on('change', function () {
			self.onFieldChange('code', { target: { value: self.editor.getValue() } });
		});

		self.editor.setValue(self.state.script.code, -1);
		this.editor.resize();

		setTimeout(function () {
			self._root.classList.add('visible');
			self.loaded = true;
		}, 200);
	},

	componentWillUnmount: function () {
		if (this.editor) {
			this.editor.destroy();
		}
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	toggleFullscreen: function () {
		this.setState({
			fullscreen: !this.state.fullscreen
		});
	},

	componentDidUpdate: function (prevProps, prevState) {
		var self = this;
		if (this.state.fullscreen !== prevState.fullscreen) {
			setTimeout(function () {
				self.editor.resize();
			}, 250);
		}
	},

	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;
		var script = rootData.pageData.script;

		var triggers = [
			'order_edit', // 75 scripts
			'order_save',
			'activity_edit',
			'activity_save',
			'client_open',
			'client_dashboard_open',
			'client_save',
			'client_edit',
			'contact_open',
			'agreement_edit',
			'agreement_save',
			'salesboard_open',
			'contact_save',
			'upsales_init',
			'contact_edit',
			'flash_edit',
			'mail_edit'
		];

		var data = _.map(triggers, function (item) {
			var when = self.getWhen(item);
			return { id: item, name: when };
		});

		var classNames = '';
		if (this.loaded) {
			classNames += ' visible';
		}
		if (this.state.fullscreen) {
			classNames += ' fullscreen';
		}

		return (
			<div
				id="admin-page-editscript"
				ref={function (r) {
					self._root = r;
				}}
				className={classNames}
			>
				<div id="edit-script-header">
					<Breadcrumbs light={true}>
						<BreadcrumbItem
							state="administration.scripts"
							params={{ customerId: Tools.AppService.getCustomerId() }}
							text={this.lang.scripts}
						/>
						<BreadcrumbItem text={script.id ? this.lang.editScript : this.lang.newScript} />
					</Breadcrumbs>
					<h2 className="page-header-title">
						{self.lang.editScript}
						<ReactTemplates.elevio articleId={409} sidebar={true} />
					</h2>

					<button id="fullscreen-toggle" type="button" onClick={this.toggleFullscreen}>
						<b className={this.state.fullscreen ? 'fa fa-compress' : 'fa fa-expand'}></b>
					</button>

					<div id="script-active">
						<label>{self.lang.scriptsActive}</label>
						<Toggle
							className="transparent"
							size="lg"
							color="medium-green"
							checked={script.active}
							onChange={self.onToggleChange.bind(self, 'active')}
						/>
					</div>

					<div className="script-name">
						<label>
							{self.lang.description}
							{':'}
						</label>
						<input
							type="text"
							value={script.description}
							onChange={self.onFieldChange.bind(self, 'description')}
							ref={function (r) {
								self._description = r;
							}}
							className={rootData.pageData.missingDesc ? 'has-error' : null}
							placeholder={self.lang.nameYourScript}
						/>
					</div>

					<div className="script-trigger">
						<label>{self.lang.scriptsRunning}</label>
						<ReactTemplates.INPUTS.upSelect
							className="form-control"
							placeholder={self.lang.scriptWhen}
							onChange={self.onFieldChange.bind(self, 'type')}
							tools={tools}
							options={self.selectOptions}
							data={data}
							defaultValue={script.type}
							required={true}
						/>
					</div>
				</div>

				<div id="editor-wrap" ref={self.setRef.bind(self, '_jsonEditor')}></div>
			</div>
		);
	}
});
