'use strict';

angular.module('upResources').factory('Product', [
	'$resource',
	'ProductAttributes',
	'$q',
	'URL',
	'API',
	'CacheService',
	'RequestBuilder',
	'ParseGeneric',
	'ResourceHelper',
	function Product(
		$resource,
		ProductAttributes,
		$q,
		URL,
		API,
		CacheService,
		RequestBuilder,
		ParseGeneric,
		ResourceHelper
	) {
		var Attribute = ProductAttributes().getAll();
		var helper = new ResourceHelper();
		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'product',
			createSuccessBody: 'saved.product',
			updateSuccessBody: 'updated.product',
			deleteSucccessBody: 'deleted.product',
			createErrorBody: 'saveError.product',
			updateErrorBody: 'saveError.product',
			deleteErrorBody: 'deleteError.product'
		});

		var Model = {
			customer: function (customer) {
				var instance = {};
				var Product = null;
				const resourceUrl = URL + API + customer + '/products/';
				Product = $resource(
					resourceUrl + ':id',
					{},
					{
						find: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseProduct', { isArray: true, skipDateConvert: true })
						},
						get: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseProduct', { isArray: false, skipDateConvert: true })
						},
						create: { method: 'POST', isArray: false },
						update: { method: 'PUT', isArray: false },
						updateMulti: { method: 'PUT', isArray: true },
						getProductTree: {
							method: 'GET',
							url: resourceUrl + 'tree'
						}
					}
				);

				instance.getProductTree = (onlyRecurring, searchString, currentValueId, usePriceLists) =>
					Product.getProductTree({ onlyRecurring, searchString, currentValueId, usePriceLists }).$promise;

				instance.find = function (filter) {
					return Product.find(filter).$promise;
				};

				instance.get = function (id, usePriceLists) {
					var defered = $q.defer();

					Product.get({ id: id, usePriceLists: usePriceLists }, function (response) {
						defered.resolve(response);
					});

					return defered.promise;
				};

				instance.save = function (product, options) {
					var defered = $q.defer();

					if (product.id) {
						//update

						// har simon b fixar detta med att man skickar in product istället för {product: product} ?
						Product.update(
							{ id: product.id },
							product,
							function (response) {
								defered.resolve(response);
							},
							function (response) {
								defered.reject(response);
							}
						);
					} else {
						//new
						Product.create(
							product,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					}

					defered.promise
						.then(function (res) {
							helper.onSave(options, res.data, !product.id);
						})
						.catch(function () {
							helper.onSaveError(options, !product.id);
						});

					return defered.promise;
				};

				instance.saveMulti = function (products, options) {
					var defered = $q.defer();

					if (!Array.isArray(products)) {
						return defered.reject('Not an array');
					}

					const updateMultiOptions = {
						allowSortIdChange: options?.allowSortIdChange
					};

					Product.updateMulti(
						updateMultiOptions,
						products,
						function (response) {
							if (response.error) {
								defered.reject(response);
							}

							defered.resolve(response);
						},
						defered.reject
					);

					defered.promise
						.then(function (res) {
							helper.onSave(options, res);
						})
						.catch(function () {
							helper.onSaveError(options);
						});

					return defered.promise;
				};

				instance.getAll = function () {
					var defered = $q.defer();
					var products = [];
					var limit = 1000;

					var get = function (offset) {
						var productsFilter = new RequestBuilder();
						productsFilter.limit = limit;
						productsFilter.offset = offset;
						productsFilter.addExtraParam('usePriceLists', true);
						productsFilter.addSort(Attribute.sortId, true);
						productsFilter.addSort(Attribute.name, true);
						instance
							.find(productsFilter.build())
							.then(function (res) {
								products.push(res.data);

								if (res.data.length === limit) {
									get(offset + limit);
								} else {
									defered.resolve(_.flatten(products));
								}
							})
							.catch(defered.reject);
					};

					get(0);

					return defered.promise;
				};

				instance.searchActive = function (term, options) {
					options = options || {};
					var defered = $q.defer();
					var rb = new Tools.RequestBuilder();
					rb.limit = options.limit || 500;
					rb.addFilter(Tools.Product.attr.active, rb.comparisonTypes.Equals, 1);
					rb.addFilter(Tools.Product.attr.name, rb.comparisonTypes.Search, term);
					Tools.Product.customer(Tools.AppService.getCustomerId())
						.find(rb.build())
						.then(function (res) {
							var data = _.filter(res.data, function (product) {
								return product.$hasAccess;
							});
							defered.resolve(data);
						})
						.catch(defered.reject);

					return defered.promise;
				};

				instance['delete'] = function (product, options) {
					return Product.delete({ id: product.id })
						.$promise.then(function (res) {
							helper.onDelete(options, product);
							return res;
						})
						.catch(function (err) {
							helper.onDeleteError(options, err);
							return $q.reject(err);
						});
				};

				return instance;
			},
			attr: Attribute
		};

		return Model;
	}
]);
