import { Flex, Text, Toggle } from '@upsales/components';
import React from 'react';
import { useTranslation } from 'Components/Helpers/translate';

type Props = {
	groupByCompany: boolean;
	onChange: () => void;
	groupingLabel: string;
};

const GroupCompanyToggle = (props: Props) => {
	const { groupByCompany, onChange, groupingLabel } = props;
	const { t } = useTranslation();

	return (
		<Flex space="pll">
			<Toggle checked={groupByCompany} onChange={onChange} />
			<Text>{`${t('default.groupBy')} ${groupingLabel.toLowerCase()}`}</Text>
		</Flex>
	);
};

export default GroupCompanyToggle;
