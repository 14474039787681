import { useFeatureAvailable } from 'App/components/hooks';
import { useSelf } from 'App/components/hooks/appHooks';
import { Feature } from 'Store/actions/FeatureHelperActions';

export default function MatcherAllowed() {
	const self = useSelf();
	const isAdmin = self?.administrator;

	const hasMatcherFromSoliditet = self?.userParams.soliditetIsActive;
	const hasMatcherFromProspecting = useFeatureAvailable(Feature.PROSPECTING_BASIC);
	const multiSoliditetActivated =
		(hasMatcherFromSoliditet || hasMatcherFromProspecting) && (self?.userParams.multiSoliditetAccess || isAdmin);

	const isAvailable = useFeatureAvailable(Feature.SOLIDITET_MATCHER);

	return (isAvailable && multiSoliditetActivated) || false;
}
