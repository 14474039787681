'use strict';

import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import logError from 'App/babel/helpers/logError';
import ClientCardActivities from 'App/pages/ClientCard/ContentPages/Activities/Activities';
import { openDrawer } from 'Services/Drawer';
import CreateTaskButton from 'App/components/CreateTaskButton';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';
import { Detached as ActivityTab } from 'App/pages/ActivityTab';

angular.module('domain.account').controller('AccountActivities', [
	'$scope',
	'$stateParams',
	'RequestBuilder',
	'ActivityList',
	'$translate',
	'AppService',
	'$q',
	'$state',
	'MailBodyParser',
	'$upModal',
	'FeatureHelper',
	'Activity',
	'meta',
	function (
		$scope,
		$stateParams,
		RequestBuilder,
		ActivityList,
		$translate,
		AppService,
		$q,
		$state,
		MailBodyParser,
		$upModal,
		FeatureHelper,
		Activity
	) {
		var accountActivitiesOpen = [];
		var accountActivitiesClosed = [];

		var AccountCtrl = $scope.AccountCtrl;
		var filter = new RequestBuilder();
		var filterClosed = new RequestBuilder();
		let customerId;

		AccountCtrl.CreateTaskButton = CreateTaskButton;

		$scope.accountActivities = {};
		$scope.isPriorityEnable = FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');
		$scope.isTodoEnable = FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		$scope.hasSplitButtonClientCard = FeatureHelper.hasSoftDeployAccess('SPLIT_BUTTON_CLIENT_CARD');

		AccountCtrl.useReactActivities = FeatureHelper.hasSoftDeployAccess('ACCOUNT_CARD_ACTIVITIES_USE_REACT_TAB');
		AccountCtrl.hasSubaccounts = FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		AccountCtrl.hasSubaccountsV2 = FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2');
		AccountCtrl.useNewActivityTab = AccountCtrl.hasSubaccounts && AccountCtrl.hasSubaccountsV2;
		AccountCtrl.useReactActivities = AccountCtrl.useNewActivityTab ? true : AccountCtrl.useReactActivities;

		AccountCtrl.ClientCardActivities = ClientCardActivities;
		AccountCtrl.ActivityTab = ActivityTab;

		var goToDashboardIfNoData = function () {
			// If no more activities - go to dashboard
			if (!accountActivitiesOpen.length && !accountActivitiesClosed.length) {
				$state.go('account.dashboard', { id: AccountCtrl.account.id });
			}
		};

		var deletedCb = function (isEmail, e, deleted) {
			var index = _.findIndex(accountActivitiesOpen, function (obj) {
				var shouldCompareId = (!isEmail && !obj.to) || (isEmail && obj.to);

				if (shouldCompareId && obj.id === deleted.id) {
					return true;
				}
				return false;
			});

			var index2 = _.findIndex(accountActivitiesClosed, function (obj) {
				var shouldCompareId = (!isEmail && !obj.to) || (isEmail && obj.to);

				if (shouldCompareId && obj.id === deleted.id) {
					return true;
				}
				return false;
			});

			if (index !== -1) {
				accountActivitiesOpen.splice(index, 1);
				$scope.activitiesTotal--;
				$scope.accountActivities.open.total--;

				goToDashboardIfNoData();
			} else if (index2 !== -1) {
				accountActivitiesClosed.splice(index2, 1);
				$scope.activitiesTotal--;
				$scope.accountActivities.closed.total--;

				goToDashboardIfNoData();
			}
		};
		$scope.$on('activity.deleted', deletedCb.bind(this, false));
		$scope.$on('appointment.deleted', deletedCb.bind(this, false));
		$scope.$on('mail.deleted', deletedCb.bind(this, true));

		var updatedCb = function (e, updated) {
			if (AccountCtrl.isThisAccountOrSubaccounts(updated.client)) {
				var activity = _.find($scope.accountActivities?.open?.data, function (obj) {
					return !obj.to && obj.id === updated.id;
				});

				var activity2 = _.find($scope.accountActivities?.closed?.data, function (obj) {
					return !obj.to && obj.id === updated.id;
				});

				if (activity || activity2) {
					$scope.getActivities();
				}
			}
		};
		$scope.$on('activity.updated', updatedCb);
		$scope.$on('appointment.updated', updatedCb);

		$scope.createAppointment = function () {
			var params = {
				appointment: {
					client: { id: AccountCtrl.account.id }
				}
			};

			openEditAppointment(params);
		};

		$scope.createActivity = function () {
			var options = {
				activity: {
					client: AccountCtrl.account
				}
			};

			$upModal.open('editActivity', options);
		};

		var addedCb = function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				var activity = _.find(accountActivitiesOpen, { id: added.id });
				var activity2 = _.find(accountActivitiesClosed, { id: added.id });

				if (!activity && !activity2) {
					$scope.getActivities();
				}
			}
		};
		$scope.$on('activity.added', addedCb);
		$scope.$on('appointment.added', addedCb);

		$scope.$on('account.merged', function (e, res) {
			if (res.merged.id === AccountCtrl.account.id) {
				// Reload stuff
				$scope.getActivities();
			}
		});

		$scope.getParticipants = function (activity) {
			return _.pluck(activity.contacts.slice(1), 'name').join('\n');
		};

		$scope.getUsers = function (activity) {
			return _.pluck(activity.users.slice(1), 'name').join('\n');
		};

		$scope.tableLoading = true;

		$scope.getClosedActivities = function () {
			return ActivityList.customer($scope.customerId)
				.find($scope.filterClosed, { includes: 'activities,appointments,mail', mapCustom: true })
				.then(function (resp) {
					var res = {
						data: resp.data,
						title: $translate.instant('default.history'),
						total: resp.metadata.total
					};

					const activityTypes = Tools.AppService.getTodoTypes();
					for (const activity of res.data) {
						if (activity.type) {
							activity.icon = 'envelope';
						} else if (_.some(activityTypes, { id: activity?.activityType?.id })) {
							if (activity.activityType.id === activityTypes.TODO.id) {
								activity.isTodo = true;
								activity.icon = 'activity';
							} else if (activity.activityType.id === activityTypes.PHONE_CALL.id) {
								activity.isPhonecall = true;
								activity.icon = 'phone';
							}
						} else if (_.some(Tools.AppService.getActivityTypes('appointment', true))) {
							activity.icon = 'calendar';
						}
					}

					accountActivitiesClosed = res.data;

					$scope.accountActivities.closed = res;
					return res;
				});
		};

		$scope.getOpenActivities = function () {
			return ActivityList.customer($scope.customerId)
				.find($scope.filter, { includes: 'activities,appointments,mail', mapCustom: true })
				.then(function (response) {
					const hasNoActivities =
						FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
						FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
					var res = {
						data: response.data,
						title: hasNoActivities
							? $translate.instant('default.openTasks')
							: $translate.instant('default.openActivities'),
						total: response.metadata.total
					};
					const activityTypes = Tools.AppService.getTodoTypes();
					for (const activity of res.data) {
						if (activity.type) {
							activity.icon = 'envelope-o';
						} else if (_.some(activityTypes, { id: activity?.activityType?.id })) {
							if (activity.activityType.id === activityTypes.TODO.id) {
								activity.isTodo = true;
								activity.icon = 'activity';
							} else if (activity.activityType.id === activityTypes.PHONE_CALL.id) {
								activity.isPhonecall = true;
								activity.icon = 'phone';
							}
						} else if (_.some(Tools.AppService.getActivityTypes('appointment', true))) {
							activity.icon = 'calendar';
						}
					}

					accountActivitiesOpen = res.data;
					$scope.accountActivities.open = res;
					return res;
				});
		};

		$scope.getActivities = function (delay) {
			var ms = delay ? 2000 : 1;

			setTimeout(function () {
				$q.all({ open: $scope.getOpenActivities(), closed: $scope.getClosedActivities() })
					.then(function (res) {
						$scope.activitiesTotal = res.open.total + res.closed.total;
						angular.element(window).scrollTop(0);
						$scope.tableLoading = false;
					})
					.catch(function (err) {
						logError(err, 'Failed to get activities');
					});
			}, ms);
		};

		$scope.parseSubject = function (mail) {
			return MailBodyParser.parseSubject(mail);
		};

		$scope.openMail = function (mail) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
				openDrawer('SentMail', { mail: { id: mail.id } });
			} else {
				$upModal.open('sentMail', { customerId: $stateParams.customerId, id: mail.id });
			}
		};

		$scope.setPriority = function (item) {
			const { priority } = item;

			return Activity.customer(customerId).save({
				id: item.id,
				priority: priority ? ACTIVITY_PRIORITY.NONE : ACTIVITY_PRIORITY.HIGH
			});
		};

		$scope.getPriorityTooltip = function (activity) {
			if (activity.priority === ACTIVITY_PRIORITY.HIGH) {
				return $translate.instant('activity.removeAsImportant');
			}
			return $translate.instant('activity.markAsImportant');
		};

		$scope.isHighPriority = function (activity) {
			return activity.priority === ACTIVITY_PRIORITY.HIGH;
		};
		var buildQuery = function (rb, isClosed) {
			var or = rb.orBuilder();

			or.next();
			or.addFilter(ActivityList.attr.isAppointment, rb.comparisonTypes.Equals, true);
			or.addFilter(
				ActivityList.attr.endDate,
				isClosed ? rb.comparisonTypes.LessThan : rb.comparisonTypes.GreaterThan,
				new Date()
			);
			or.next();
			or.addFilter(ActivityList.attr.isAppointment, rb.comparisonTypes.Equals, false);
			or.addFilter(
				ActivityList.attr.closeDate,
				isClosed ? rb.comparisonTypes.NotEquals : rb.comparisonTypes.Equals,
				null
			);
			if (isClosed) {
				or.next();
				or.addFilter({ field: 'isMap' }, rb.comparisonTypes.Equals, false);
				or.next();

				let groupBuilder = or.groupBuilder();
				groupBuilder.addFilter({ field: 'isMap' }, rb.comparisonTypes.Equals, true);
				groupBuilder.addFilter({ field: 'groupMailId' }, filter.comparisonTypes.Equals, null);
				groupBuilder.done();
				or.next();
				groupBuilder = or.groupBuilder();
				groupBuilder.addFilter({ field: 'isMap' }, rb.comparisonTypes.Equals, true);
				groupBuilder.addFilter({ field: 'groupMailId' }, filter.comparisonTypes.Equals, 0);
				groupBuilder.done();
			}
			or.done();

			rb.addFilter(ActivityList.attr.account, rb.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			rb.addFilter(ActivityList.attr.projectPlan.attr.id, rb.comparisonTypes.Equals, null);
			rb.limit = 10;
			rb.offset = 0;
			rb.addSort(ActivityList.attr.date);
		};

		var init = function () {
			customerId = AppService.getCustomerId();

			buildQuery(filter);
			buildQuery(filterClosed, true);
			$scope.filter = filter.build();
			$scope.filterClosed = filterClosed.build();

			$scope.getActivities();
		};

		AppService.loadedPromise.then(init).catch(err => {
			logError(err, 'Failed to init');
		});
	}
]);
