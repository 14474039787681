import { lazy } from 'react';
import * as Sentry from '@sentry/browser';
import ReduxListeners from '../babel/store/ReduxListeners';
import ManageAccount from 'Components/AccountManagement/ManageAccount';
import { setShowOnboarding } from 'Store/actions/AppActions';
import logError from 'App/babel/helpers/logError';
import { openDrawer } from 'Services/Drawer';
import { initFromId, getAndSaveOauthToken } from 'App/babel/store/reducers/ConfigIntegration';
import { goToStartPage } from './common/helpers/startpageHelper';
import config from 'App/babel/config';
import Analytics from 'App/services/Analytics';
import InAppChat from 'App/babel/services/InAppChat';
import T from 'Components/Helpers/translate';
import history from 'App/pages/routes/history';
import { closeElevioWidgets } from 'App/helpers/appHelper';
import { checkPortedResolve } from 'App/helpers/angularRouteHelpers';

const YourYearWithUpsales = lazy(() => import('../babel/components/YourYearWithUpsales'));
const MaxCeilingReachedOnSeats = lazy(() => import('../babel/components/MaxCeilingReachedOnSeats'));

let loadingRoute = false;

var modules = [
	'ui.select2',
	'ngResource',
	'ngCookies',
	'ngSanitize',
	'ngRoute',
	'ngAnimate',
	'LocalStorageModule',
	'security',
	'upDirectives',
	'ui.mask',
	'upAttributes',
	'upHelpers',
	'upUi',
	'upFilters',
	'services.search',
	'services.dateFormat',
	'services.filterParser',
	'services.listViewService',
	'domain.activity',
	'domain.account',
	'domain.order',
	'domain.lead',
	'domain.opportunity',
	'domain.campaign',
	'domain.contact',
	'domain.admin',
	'domain.mail',
	'domain.soliditet',
	'domain.scoreboard',
	'domain.form',
	'domain.looker',
	'domain.agreement',
	'domain.advancedSearch',
	'domain.engage',
	'domain.appointment',
	'domain.userdefobj',
	'domain.esign',
	'domain.invite',
	'domain.voice',
	'domain.onboarding',
	'domain.segment',
	'services.geocode',
	'services.countryCodes',
	'services.fixOrder',
	'services.accountRelations',
	'services.utils',
	'angular-translate-placeholder',
	'ui.router',
	'mgcrea.ngStrap',
	'mgcrea.ngStrap.select',
	'upListFilter.controllers',
	'upModals.controllers',
	'upModals.meta',
	'services.notifications',
	'services.latestAccountsService',
	'services.script',
	'services.customfieldMapper',
	'services.saveInline',
	'services.$safeApply',
	'services.automationTerms',
	'services.sniCodes',
	'services.editorCk',
	'services.templateCacher',
	'services.mailBodyParser',
	'services.urlParser',
	'highcharts-ng',
	'ui.utils',
	'services.actionProperties',
	'ui.validate',
	'pasvaz.bindonce',
	'services.cacheService',
	'services.cacheRefresher',
	'ui.sortable',
	'angularFileUpload',
	'services.appService',
	'services.locale',
	'up.datepicker',
	'up.position',
	'pdf',
	'services.browser',
	'ui.ace',
	'services.dynamicBindings',
	'services.ad',
	'ngCkeditor',
	'services.tagService',
	'services.templateParser',
	'services.inkyService',
	'services.voice',
	'upsales.fileUploaderPatch'
];

// This service is used to replace the old angular translator. It just points to the new one
angular
	.module('angular-translate-placeholder', [])
	.service('$translate', function () {
		function $translate(tag, opts) {
			return T(tag, opts);
		}
		$translate.instant = $translate;
		return $translate;
	})
	.filter('translate', function ($parse, $translate) {
		return function (translationId, interpolateParams, interpolation, forceLanguage) {
			if (!angular.isObject(interpolateParams)) {
				const ctx = this || {
					__SCOPE_IS_NOT_AVAILABLE:
						'More info at https://github.com/angular/angular.js/commit/8863b9d04c722b278fa93c5d66ad1e578ad6eb1f'
				};
				interpolateParams = $parse(interpolateParams)(ctx);
			}

			return $translate.instant(translationId, interpolateParams, interpolation, forceLanguage);
		};
	});

window.app = angular
	.module('upsalesApp', modules)
	.config(function ($sceDelegateProvider) {
		const whitelist = ['self', config.STATIC_URL].filter(Boolean);
		$sceDelegateProvider.resourceUrlWhitelist(whitelist);
	})
	.config([
		'$provide',
		'$httpProvider',
		'$stateProvider',
		'$upModalProvider',
		'$urlRouterProvider',
		'$datepickerProvider',
		'$compileProvider',
		'ENV',
		function (
			$provide,
			$httpProvider,
			$stateProvider,
			$upModalProvider,
			$urlRouterProvider,
			$datepickerProvider,
			$compileProvider,
			ENV
		) {
			// Had to do it like this or else i got a circular dep. from angular...
			const PdfGeneric = require('../babel/components/CreateAccount/PdfGeneric').default;
			const SetupComponent = require('App/babel/SetupRootComponent').default;
			const AssignModal = require('Components/AssignModal/AssignModal').default;
			const DesiredOutcome = require('Components/DesiredOutcome').default;
			const FileBrowser = require('Components/FileBrowser').default;
			const store = require('Store/index').default;
			const openModal = require('App/services/Modal').default;
			const routes = require('App/pages/routes').routes;

			$httpProvider.defaults.withCredentials = true;

			if (window.SENTRY_ENABLED) {
				$provide.decorator('$exceptionHandler', [
					'$delegate',
					function ($delegate) {
						return function (exception, cause) {
							$delegate(exception, cause);

							Sentry.withScope(function (scope) {
								scope.setExtra('cause', cause);
								Sentry.captureException(exception);
							});
						};
					}
				]);
			}

			// DEFAULT FALLBACK-ROUTE
			$urlRouterProvider.otherwise('/');

			const definedRoutes = {};
			const reactRoute = (i, name, path, section) => {
				// Cannot redefine same state twice in angular so need to keep track of them
				if (!definedRoutes[name || i]) {
					$stateProvider.state('react-root-' + (name || i), {
						url: path,
						template: '<div></div>',
						section: section
					});
					definedRoutes[name || i] = true;
				}
			};
			const setupReactRoutes = routes => {
				// This is for all the react-router routes
				for (var i = 0; i < routes.length; i++) {
					var route = routes[i];
					// Ported routes with the same path as angular states should not have a react-root
					if (route.sameAngularPath) {
						continue;
					}
					reactRoute(i, route.name, route.path + (route.queryParams || ''), route.section);
					if (route.sub) {
						for (var si = 0; si < route.sub.length; si++) {
							reactRoute(si, `${i}-${si}`, route.path + route.sub[si], route.section);
						}
					}
				}
			};

			setupReactRoutes(routes);

			$stateProvider.state('react-admin-fallback', {
				url: '/admin/*path',
				section: 'admin',
				template: ''
			});

			if (module.hot) {
				module.hot.accept('App/pages/routes', () => {
					const newRoutes = require('App/pages/routes').routes;
					setupReactRoutes(newRoutes);
				});
			}

			// TODO
			// Remove this when we have a proper startpage
			$stateProvider.state('tmpStart', {
				url: '/?externalSSO',
				section: 'sale',
				resolve: {
					checkForSSO: [
						'$state',
						'$stateParams',
						'$q',
						function ($state, $stateParams, $q) {
							if ($stateParams.externalSSO) {
								$state.go('externalSSO', { site: $stateParams.externalSSO });
								return $q.reject();
							}
						}
					],
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					]
				},
				controller: [
					'$state',
					'AppService',
					'$upModal',
					'$timeout',
					function ($state, AppService, $upModal, $timeout) {
						// eslint-disable-next-line promise/catch-or-return
						AppService.loadedPromise.then(function () {
							var meta = AppService.getMetadata();
							var self = AppService.getSelf();
							var customerId = AppService.getCustomerId();
							var accountSelf = AppService.getAccountSelf();
							var acceptTerms = AppService.getAcceptTerms();

							// I believe this might be broken, the array we get from getPaymentExtensions() contains all extends and locks made from master.
							// If we have a extension and then after that we have a lock, the extension will still be in the array and thus will be found by the .some() function
							// I think we should maybe only check the last one in the array instead / only check the ones after the latest lock.
							// However, it currently still works because when the account is locked the user isn't authed or something, so getPaymentExtensions() returns an empty array
							// and so it always returns false in that case.
							const hasPaymentExtension = AppService.getPaymentExtensions().some(pe =>
								moment().isSameOrBefore(pe.postponeDate)
							);
							// if account haven't paid their invoice..
							if (accountSelf?.client.delayedPayment) {
								var daysDelayed = moment().diff(accountSelf.client.delayedPayment, 'days');

								var contact = {
									email: ' billing@upsales.com',
									phone: '08-505 806 01'
								};

								if (
									(daysDelayed > 29 && !hasPaymentExtension) ||
									(self.billingAdmin && daysDelayed > 9)
								) {
									$timeout(function () {
										if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_DELAYED_PAYMENT')) {
											openModal('DelayedPayment', {
												icon: 'fa-plus',
												contact: contact,
												user: self,
												daysDelayed: daysDelayed,
												daysLeft: 30 - daysDelayed < 0 ? 0 : 30 - daysDelayed
											});
										} else {
											$upModal.open('delayedPayment', {
												icon: 'fa-plus',
												contact: contact,
												user: self,
												daysDelayed: daysDelayed,
												daysLeft: 30 - daysDelayed < 0 ? 0 : 30 - daysDelayed
											});
										}
									}, 750);
								}
							}

							const showOnboarding = meta.onboarding && meta.onboarding.length;
							const customerRegion = Tools.AppService.getMetadata().params.CustomerRegion;
							const hasERPOnboarding = Tools.FeatureHelper.hasSoftDeployAccess('ERP_ONBOARDING');
							const hasNOERPOnboarding = Tools.FeatureHelper.hasSoftDeployAccess('ERP_ONBOARDING_NO');

							if (showOnboarding) {
								if (
									(hasERPOnboarding && ['GB', 'SE'].includes(customerRegion)) ||
									(hasNOERPOnboarding && ['NO'].includes(customerRegion))
								) {
									store.dispatch(setShowOnboarding(true));
								} else {
									$state.go('onboarding', {
										customerId: customerId,
										openFullScreen: true
									});
								}
							} else if (meta.showUserSurvey && Tools.FeatureHelper.hasSoftDeployAccess('USER_SURVEY')) {
								const [userSurvey] = meta.userSurveyResult;
								openModal('UserSurvey', { userSurvey });
								goToStartPage();
							} else {
								goToStartPage();
							}

							if (acceptTerms.show) {
								openModal('AcceptTerms', { acceptTerms });
							}
						});
					}
				]
			});

			// Account management
			$stateProvider.state('manageAccount', {
				template: '<div></div>',
				resolve: {
					getAccountInformation: [
						'$stateParams',
						function ($stateParams) {
							return checkPortedResolve('manageAccount', () => {
								if ($stateParams.credentials) {
									return window.Tools.AccountInformation.getInformation($stateParams.credentials);
								}

								return Tools.AppService.loadedPromise.then(() => {
									var result = {
										data: {
											accountManager: Tools.AppService.getAccountSelf().accountManager,
											customerDetails: Tools.AppService.getAccountSelf().client,
											you: Tools.AppService.getSelf()
										}
									};
									result.data.customerDetails.provisioningTrial =
										Tools.AppService.getAccountSelf().client.isTrial;
									result.data.customerDetails.stillActive = true;
									return result;
								});
							});
						}
					]
				},
				controller: [
					'$scope',
					'$state',
					'$element',
					'$stateParams',
					'getAccountInformation',
					function ($scope, $state, $element, $stateParams, getAccountInformation) {
						if (!getAccountInformation) {
							return $state.go('login');
						}
						SetupComponent(
							$scope,
							ManageAccount,
							$element[0],
							{
								credentials: $stateParams.credentials,
								accountInformation: getAccountInformation.data
							},
							{ redux: true, ignoreAppService: true }
						);
					}
				],
				params: { credentials: null },
				url: '/manageAccount'
			});

			// SSO state
			$stateProvider.state('externalSSO', {
				url: '/externalSSO/:site',
				resolve: {
					authAndRedirect: [
						'$state',
						'$stateParams',
						'$http',
						'URL',
						'API',
						'$q',
						function ($state, $stateParams, $http, URL, API, $q) {
							return $http
								.get(URL + API + 'function/externalSSO/' + $stateParams.site)
								.then(function (res) {
									if (!res.data || !res.data.data) {
										return $q.reject();
									}
									window.location.href = res.data.data;
									return $q.reject();
								})
								.catch(function () {
									return $q.reject();
								});
						}
					]
				}
			});

			// App oauth redir
			$stateProvider.state('oauthLanding', {
				url: '/oauthLanding/:integrationId/:type?code?oAuthFieldName',
				resolve: {
					authAndRedirect: [
						'$stateParams',
						function ($stateParams) {
							// eslint-disable-next-line promise/catch-or-return
							Tools.AppService.loadedPromise.then(async function () {
								if (Tools.$cookies.importOauthApp) {
									const parts = Tools.$cookies.importOauthApp.split('-');
									Tools.$state.go('onboarding', {
										initialImportApp: parseInt(parts[0]),
										initialSubstepId: parseInt(parts[1]),
										oauthCode: $stateParams.code
									});
									return;
								} else if (Tools.$cookies.oauthRedirectState) {
									try {
										const stateObject = JSON.parse(Tools.$cookies.oauthRedirectState);
										const auth = {
											integrationId: $stateParams.integrationId,
											type: $stateParams.type === 'user' ? 'user' : 'client',
											code: $stateParams.code
										};
										if (stateObject.state) {
											await Tools.$state.go(
												stateObject.state,
												Object.assign(stateObject.stateParams || {}, { auth }),
												{ location: 'replace' }
											);
										}
										if (stateObject.modal) {
											Tools.$upModal.open(
												stateObject.modal,
												Object.assign(stateObject.modalParams || {}, { auth })
											);
										}
										if (stateObject.drawer) {
											await store.dispatch(initFromId(auth.integrationId));
											await store.dispatch(getAndSaveOauthToken(auth));
											openDrawer(stateObject.drawer);
										}
										return;
									} catch (error) {
										console.error('Failed redirecting to oauthRedirectState', error);
									}
								}
								const configure = $stateParams.type === 'user' ? 'user' : 'client';
								Tools.$state.go('administration.integration', {
									id: $stateParams.integrationId,
									configure: configure,
									code: $stateParams.code,
									oAuthFieldName: $stateParams.oAuthFieldName
								});
							});
							return Tools.$q.reject();
						}
					]
				}
			});

			$stateProvider.state('activateIntegration', {
				url: '/activate-app/:integrationAlias',
				resolve: {
					open: [
						'$stateParams',
						'$rootScope',
						async function ($stateParams, $rootScope) {
							if ($stateParams.integrationAlias === 'FORTNOX') {
								if (window.useReactInit) {
									history.replace('/login?activateFortnox=true');
								} else {
									$rootScope.fortnoxActivate = true;
								}
							}
							const rb = new Tools.RequestBuilder();
							rb.addFilter({ field: 'alias' }, rb.comparisonTypes.Equals, $stateParams.integrationAlias);
							// Get integrations
							const integrations = await Tools.StandardIntegration.find(rb.build());
							const integration = integrations.data[0];

							// eslint-disable-next-line promise/catch-or-return
							Tools.AppService.loadedPromise.then(async function () {
								if (Tools.$cookies.importOauthApp) {
									const parts = Tools.$cookies.importOauthApp.split('-');
									Tools.$state.go('onboarding', {
										initialImportApp: parseInt(parts[0]),
										initialSubstepId: parseInt(parts[1]),
										oauthCode: $stateParams.code
									});
									return;
								} else if (Tools.$cookies.oauthRedirectState) {
									try {
										const stateObject = JSON.parse(Tools.$cookies.oauthRedirectState);
										const auth = {
											integrationId: integration.id,
											type: $stateParams.type === 'user' ? 'user' : 'client',
											code: $stateParams.code
										};
										if (stateObject.state) {
											await Tools.$state.go(
												stateObject.state,
												Object.assign(stateObject.stateParams || {}, { auth }),
												{ location: 'replace' }
											);
										}
										if (stateObject.modal) {
											Tools.$upModal.open(
												stateObject.modal,
												Object.assign(stateObject.modalParams || {}, { auth })
											);
										}
										return;
									} catch (error) {
										console.error('Failed redirecting to oauthRedirectState', error);
									}
								}

								const configure = $stateParams.type === 'user' ? 'user' : 'client';
								Tools.$state.go('administration.integration', {
									id: integration.id,
									configure: configure,
									code: $stateParams.code
								});
							});

							return Tools.$q.reject();
						}
					]
				}
			});

			const routeToEntity = async (
				backgroundPage,
				entityName,
				entityObject,
				isDrawer = false,
				isOpenModal = false,
				backgroundPageParams = {}
			) => {
				// The magic setTimeout 0 that stops superseding route transitions. Don't know why the loadingRoute state isn't enough :shrug:
				setTimeout(
					() =>
						Tools.$state
							.go(backgroundPage, backgroundPageParams)
							.then(() => {
								if (isDrawer) {
									openDrawer(entityName, entityObject);
								} else if (isOpenModal) {
									openModal(entityName, entityObject);
								} else {
									// eslint-disable-next-line promise/catch-or-return
									Tools.$upModal.open(entityName, entityObject);
								}
							})
							.catch(err => logError(err, `Failed to go to ${err} ${backgroundPage}`)),
					0
				);
			};

			const reactRouteToEntity = async (backgroundPage, entityName, entityObject, isDrawer = false) => {
				setTimeout(() => {
					const backgroundPageUrl = backgroundPage.startsWith('/') ? backgroundPage : `/${backgroundPage}`;
					history.push(backgroundPageUrl);
					if (isDrawer) {
						openDrawer(entityName, entityObject);
					} else {
						Tools.$upModal.open(entityName, entityObject);
					}
				}, 0);
			};

			const getEntityRouteFromType = type => {
				switch (type) {
					case 'client':
						return 'account.dashboard';
					case 'contact':
						return 'contact.dashboard';
					case 'order':
						return 'orders';
					case 'activity':
						return 'activities';
					case 'appointment':
						return 'appointments';
					case 'agreement':
						return 'agreements';
					case 'mail':
						return 'react-root-mail';
					case 'phoneCall':
						return 'react-root-todo';
					case 'todo':
						return 'react-root-todo';
					case 'ticket': {
						const self = Tools.AppService.getSelf();
						const hasSupportList = self?.support || self?.administrator;
						return hasSupportList ? 'react-root-support' : 'react-root-companies';
					}
					case 'projectPlanComment':
						return 'react-root-projectBoard';
					case 'projectPlan':
						return 'react-root-projectBoard';
				}
			};

			// Open modals by linking to them
			$stateProvider.state('open', {
				url: '/open/:type/:id',
				resolve: {
					open: [
						'$stateParams',
						function ($stateParams) {
							if (loadingRoute) {
								return;
							} else {
								loadingRoute = true;
							}
							// Wait for application
							// eslint-disable-next-line promise/catch-or-return
							Tools.AppService.loadedPromise.then(function () {
								if ($stateParams.type === 'client') {
									Tools.$state.go('account.dashboard', { id: $stateParams.id });
									return;
								} else if ($stateParams.type === 'contact') {
									Tools.$state.go('contact.dashboard', { id: $stateParams.id });
									return;
								}

								// Go to matching page and open modal or drawer
								switch ($stateParams.type) {
									case 'order':
										reactRouteToEntity('orders', 'editOrder', { id: $stateParams.id });
										break;
									case 'activity':
										routeToEntity('activities', 'editActivity', { id: $stateParams.id });
										break;
									case 'appointment':
										routeToEntity('appointments', 'editAppointment', { id: $stateParams.id });
										break;
									case 'agreement':
										if (Tools.FeatureHelper.hasSoftDeployAccess('EDIT_SUBSCRIPTIONS')) {
											routeToEntity(
												'agreements',
												'EditSubscription',
												{ agreementId: Number($stateParams.id) },
												false,
												true
											);
										} else {
											routeToEntity('agreements', 'editAgreement', { id: $stateParams.id });
										}
										break;
									case 'mail':
										routeToEntity(
											'react-root-mail',
											'SentMail',
											{ mail: { id: $stateParams.id } },
											true
										);
										break;
									case 'phoneCall':
										routeToEntity(
											'react-root-todo',
											'EditPhonecall',
											{ activity: { id: $stateParams.id } },
											true
										);
										break;
									case 'todo':
										routeToEntity(
											'react-root-todo',
											'EditTodo',
											{ todo: { id: $stateParams.id } },
											true
										);
										break;
									case 'ticket': {
										const self = Tools.AppService.getSelf();
										const hasSupportList = self?.support || self?.administrator;
										routeToEntity(
											hasSupportList ? 'react-root-support' : 'react-root-companies',
											'EditTicket',
											{ ticketId: $stateParams.id },
											true
										);
										break;
									}
									case 'projectPlanComment': {
										routeToEntity(
											'react-root-projectBoard',
											'EditProjectPlan',
											{ projectPlanId: $stateParams.id, openedFromMention: true },
											true
										);
										break;
									}
									case 'projectPlan': {
										routeToEntity(
											'react-root-projectBoard',
											'EditProjectPlan',
											{ projectPlanId: $stateParams.id },
											true
										);
										break;
									}
								}
								loadingRoute = false;
							});
						}
					]
				}
			});

			// Open a modal with a specified background page
			$stateProvider.state('openWithBackground', {
				url: '/open/:type/:id/:backgroundType/:backgroundId',
				resolve: {
					open: [
						'$stateParams',
						function ($stateParams) {
							if (loadingRoute) {
								return;
							} else {
								loadingRoute = true;
							}
							// Wait for application
							// eslint-disable-next-line promise/catch-or-return
							Tools.AppService.loadedPromise.then(function () {
								// Go to matching page and open modal or drawer
								switch ($stateParams.type) {
									case 'projectPlanComment': {
										routeToEntity(
											getEntityRouteFromType($stateParams.backgroundType),
											'EditProjectPlan',
											{ projectPlanId: $stateParams.id, openedFromMention: true },
											true,
											false,
											{ id: $stateParams.backgroundId }
										);
										break;
									}
								}
								loadingRoute = false;
							});
						}
					]
				}
			});

			var setFeatureState = function (state, section) {
				$stateProvider.state(state, {
					controller: [
						'$scope',
						function ($scope) {
							$scope.goToUpsales = function () {
								var win = window.open('https://www.upsales.com/pris/crm/', '_blank');
								win.focus();
							};
						}
					],
					templateProvider: [
						'$stateParams',
						'$http',
						'URL',
						'API',
						'$q',
						function ($stateParams, $http, URL, API, $q) {
							var defer = $q.defer();
							$http
								.get(URL + API + 'placeholder/' + $stateParams.feature)
								.then(function (res) {
									defer.resolve(res.data);
								})
								.catch(function () {
									$http
										.get('upsales/common/components/ui/templates/featurePlaceholder.html')
										.then(function (res) {
											defer.resolve(res.data);
										})
										.catch(e => console.log('template not found', e));
								});

							return defer.promise;
						}
					],
					url: '/feature/' + section + '/:feature',
					section: section
				});
			};

			setFeatureState('saleFeature', 'sale');
			setFeatureState('marketFeature', 'market');
			setFeatureState('followupFeature', 'followup');

			// Allow phone stuff in href
			// Allow anything (until we can trust custom call-to prefixes on the fly)
			$compileProvider.aHrefSanitizationWhitelist(/(.*?):/);

			/*
				enableDebugInfo from localStorage can never make it so that you do not have debug mode enabled when you should as it only prevent turning it off. 
			
				It can how ever prevent disabeling of it even when we could disable it. 
				This will only happen if you switch between different accounts where one needs it and one does not. 
				So in reality the only one that potentially will notice this is our support agents or KAMs.
			
				And if a customer have had scripts that needs debug mode enabled and then disable them, it will self correct the next time the page is loaded,
				as enableDebugInfo from localStorage will be set to '0' at that point.
			*/
			const keepDebugInfoEnabled = localStorage.getItem('enableDebugInfo') === '1';

			// Need to check for scripts that need this. Fucking hell - why do we have scripts?
			if (ENV !== 'DEV' && !window.name.includes('NG_ENABLE_DEBUG_INFO') && !keepDebugInfoEnabled) {
				$compileProvider.debugInfoEnabled(false);
			}

			// $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension|sip|Web+3switch|web+3switch|tel|callto):/);

			// Some modal configs
			var modalStyles = ['default', 'info', 'success', 'error', 'warning'];

			// CONFIRMS
			angular.forEach(modalStyles, function (style) {
				$upModalProvider.modal(style + 'Confirm', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/genericConfirm.tpl.html?file'),
					style: 'confirm ' + style
				});

				$upModalProvider.modal(style + 'ConfirmLg', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/genericConfirm.tpl.html?file'),
					style: 'confirm form-sm ' + style
				});
			});

			// ALERTS
			angular.forEach(modalStyles, function (style) {
				$upModalProvider.modal(style + 'Alert', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/genericAlert.tpl.html?file'),
					style: 'notify ' + style
				});

				$upModalProvider.modal(style + 'Alertlg', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/genericConfirm.tpl.html?file'),
					style: 'confirm form-sm ' + style
				});
			});

			// PROMPTS
			angular.forEach(modalStyles, function (style) {
				$upModalProvider.modal(style + 'Prompt', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/genericPrompt.tpl.html?file'),
					style: 'prompt ' + style
				});

				$upModalProvider.modal(style + 'PromptLg', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/genericPrompt.tpl.html?file'),
					style: 'prompt form-sm ' + style
				});
			});

			$upModalProvider
				.modal('editListView', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/editListView.tpl.html?file'),
					controller: 'EditListView',
					style: 'confirm default bg-gray'
				})
				.modal('list', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/genericList.tpl.html?file'),
					style: 'confirm default'
				})
				.modal('pdfPreview', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/pdfPreview.tpl.html?file'),
					controller: 'PdfPreview',
					closeOnCurtain: true
				})
				.modal('createDocument', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/createDocument.tpl.html?file'),
					controller: 'CreateDocument as CreateDocument',
					style: 'confirm',
					resolve: {
						meta: function (CreateDocumentMeta, $modalParams) {
							return CreateDocumentMeta($modalParams);
						},
						openNewModal: (CreateDocumentMeta, $modalParams, FeatureHelper) => {
							if (FeatureHelper.hasSoftDeployAccess('CREATE_DOCUMENT_REACT')) {
								openModal('CreateDocumentModal', { ...$modalParams });
								return Promise.reject();
							}
						}
					}
				})
				.modal('viewVisit', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/viewVisit.tpl.html?file'),
					controller: 'ViewVisit',
					closeOnCurtain: true
				})
				.modal('viewSubmit', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/viewSubmit.tpl.html?file'),
					controller: 'ViewSubmit',
					closeOnCurtain: true
				})
				.modal('appWidget', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/appWidget.tpl.html?file'),
					controller: 'AppWidget',
					closeOnCurtain: true,
					noCompile: true,
					style: function ($modalParams) {
						if ($modalParams.fullscreen) {
							return 'default fullscreen';
						}
						if ($modalParams.size) {
							switch ($modalParams.size) {
								case 'sm':
									return 'form-sm';
								case 'md':
									return 'form-md';
								case 'lg':
									return 'form';
								case 'xl':
									return 'form-wide';
							}
						}
						return 'default';
					}
				})
				.modal('assign', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/assign.tpl.html?file'),
					controller: 'Assign as AssignCtrl',
					closeOnCurtain: true
				})
				.modal('newMarketEvent', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/newMarketEvent.tpl.html?file'),
					controller: 'NewMarketEvent as MarketEventCtrl',
					closeOnCurtain: true,
					style: 'default'
				})
				.modal('EditColumns', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/editColumns.tpl.html?file'),
					controller: 'EditColumns as EditColumns',
					style: 'form default',
					closeOnCurtain: true,
					closeOnEscape: true
				})
				.modal('ExportData', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/exportData.tpl.html?file'),
					controller: 'ExportData as ExportData',
					style: 'form default',
					closeOnCurtain: true,
					closeOnEscape: true
				})
				.modal('downloadList', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/downloadList.tpl.html?file'),
					style: 'confirm'
				})
				.modal('uploadFile', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/uploadFile.tpl.html?file'),
					controller: 'UploadFileCtrl as UploadFileCtrl',
					closeOnCurtain: true,
					closeOnEscape: true
				})
				.modal('uploadCreative', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/uploadCreative.tpl.html?file'),
					controller: 'UploadCreativeCtrl as UploadCreativeCtrl',
					closeOnCurtain: true,
					closeOnEscape: true
				})
				.modal('fileBrowser', {
					templateProvider: function (FeatureHelper, $http, AppService) {
						return AppService.loadedPromise.then(() => '<div></div>');
					},
					controllerProvider: function () {
						return function ($scope, $element, $modalParams) {
							SetupComponent($scope, FileBrowser, $element[0], $modalParams, {
								redux: false,
								modal: true
							});
						};
					},
					style: function () {
						return '';
					}
				})
				.modal('imageUrl', {
					templateProvider: (FeatureHelper, $http, AppService) => {
						return AppService.loadedPromise.then(() => '<div></div>');
					},
					controllerProvider: () => {
						return ($scope, $element, $modalParams) => {
							const ImageUrl = require('Components/ImageUrl').default;
							SetupComponent($scope, ImageUrl, $element[0], $modalParams, {
								redux: false,
								modal: true
							});
						};
					},
					style: 'prompt default'
				})
				.modal('insertTag', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/insertTag.tpl.html?file'),
					controller: 'InsertTag as InsertTag',
					style: 'confirm default bg-gray'
				})
				.modal('delayedPayment', {
					templateUrl: require('App/upsales/common/components/ui/modals/templates/delayedPayment.tpl.html?file'),
					style: 'prompt default no-border no-modal-overflow',
					closeOnCurtain: false,
					closeOnEscape: false
				})
				.modal('integrationModal', {
					templateProvider: function (URL, API, $modalParams, AppService, $templateCache, $http, $sce) {
						var src = URL + API + 'function/standardIntegrationIframe/' + $modalParams.name;
						src += '?typeId=' + $modalParams.type;
						src += '&integrationId=' + $modalParams.integrationId;
						src += '&objectId=' + $modalParams.objectId;
						src += '&userId=' + AppService.getSelf().id;

						src = $sce.trustAsResourceUrl(src);
						var iframe = '<iframe class="si-modal-iframe" ng-src="' + src + '"></iframe>';

						if ($modalParams.frameless) {
							return iframe;
						}

						return $http
							.get('upsales/common/components/ui/modals/templates/integrationModal.tpl.html', {
								cache: $templateCache
							})
							.then(function (res) {
								return res.data;
							});
					},
					style: function ($modalParams) {
						if ($modalParams.fullscreen) {
							return 'default fullscreen';
						}
						return 'default';
					},
					closeOnCurtain: false,
					closeOnEscape: false,
					noCompile: true,
					controller: function ($scope, $modalParams, URL, API, AppService, $sce, $safeApply) {
						var callback = function (e) {
							if (e.data && e.data.length > 0) {
								if (e.data[0] === 'closeModal' && e.data[e.data.length - 1] === $modalParams.name) {
									$scope.resolve(e.data.length >= 3 ? e.data[1] : null);
								} else if (
									e.data[0] === 'setTitle' &&
									e.data[e.data.length - 1] === $modalParams.name
								) {
									$scope.title = e.data[1];
									$safeApply($scope);
								}
							}
						};
						window.addEventListener('message', callback);

						$scope.$on('$destroy', function () {
							window.removeEventListener('message', callback);
						});

						if (!$modalParams.frameless) {
							$scope.title = '';
							var src = URL + API + 'function/standardIntegrationIframe/' + $modalParams.name;
							src += '?typeId=' + $modalParams.type;
							src += '&integrationId=' + $modalParams.integrationId;
							src += '&objectId=' + $modalParams.objectId;
							src += '&userId=' + AppService.getSelf().id;
							$scope.src = $sce.trustAsResourceUrl(src);
						}
					}
				})
				.modal('processedBy', {
					templateProvider: function () {
						return Tools.$q.when('<div></div>');
					},
					controller: function ($scope, $element, $modalParams) {
						SetupComponent(
							$scope,
							AssignModal,
							$element[0],
							{
								modalParams: {
									client: $modalParams.client,
									contactId: $modalParams.contactId,
									from: $modalParams.from,
									formFields: $modalParams.formFields,
									formId: $modalParams.formId
								}
							},
							{ redux: true, modal: true }
						);
					},
					initialHeight: 300,
					style: function () {
						return 'processed-by-modal modal1000 dynamic';
					}
				})
				// This can be removed when REACT_ALERT_MODAL is released
				.modal('alert', {
					template: '<div id="react_alert_modal"></div>',
					noCompile: true,
					initialHeight: 505,
					controller: function ($scope, $modalParams) {
						var prevent = true;
						let node;
						$scope.$on('modal.ready', function () {
							node = document.getElementById('react_alert_modal');
							if (!node) {
								return;
							}

							ReactDOM.render(
								React.createElement(
									$modalParams.dialog || ReactTemplates.alerts[$modalParams.type],
									Object.assign({ reloadModalPosition: $scope.reloadModalPosition }, $modalParams, {
										reject: function () {
											prevent = false;
											$scope.reject();
										},
										resolve: function (res) {
											if ($modalParams.confirmFn) {
												$modalParams.confirmFn().then($scope.resolve).catch($scope.reject);
											} else {
												$scope.resolve(res);
											}
										}
									})
								),
								node
							);
						});

						$scope.$on('$destroy', function () {
							ReactDOM.unmountComponentAtNode(node);
							node = undefined;
						});

						$scope.$on('modal.rejected', function (e) {
							if (prevent) {
								e.preventDefault();
								prevent = false;
								$scope.reject();
							}
						});
					},
					resolve: {
						addForm: function ($modalParams, $preCompileElement) {
							if ($modalParams.id === 'must-have-fields') {
								$preCompileElement.addClass('form');
							}

							if ($modalParams.className && $modalParams.className.length) {
								$preCompileElement.addClass($modalParams.className);
							}
						}
					},
					style: function ($modalParams) {
						return $modalParams.fullscreen ? ' new-full-screen' : 'default notify reactive-topbar';
					}
				})
				.modal('generic', {
					template: '<div id="generic-modal"></div>',
					initialHeight: 0,
					controller: function ($scope, $modalParams) {
						var prevent = true;
						let node;
						$scope.$on('modal.ready', function () {
							node = document.getElementById('generic-modal');
							if (!node) {
								return;
							}

							ReactDOM.render(
								React.createElement(
									$modalParams.Component,
									Object.assign({}, $modalParams, {
										reject: function () {
											prevent = false;
											$scope.reject();
										},
										resolve: function (res) {
											$scope.resolve(res);
										}
									})
								),
								node
							);
						});

						$scope.$on('$destroy', function () {
							ReactDOM.unmountComponentAtNode(node);
							node = undefined;
						});

						$scope.$on('modal.rejected', function (e) {
							if (prevent) {
								e.preventDefault();
								prevent = false;
								$scope.reject();
							}
						});
					},
					inlineStyle: function ($modalParams) {
						return $modalParams.inlineStyle;
					},
					style: function ($modalParams) {
						var style = 'generic-modal';
						if ($modalParams.className && $modalParams.className.length) {
							style += ' ' + $modalParams.className;
						}

						if ($modalParams.fullscreen) {
							style += ' new-full-screen';
						}

						return style;
					}
				})
				.modal('desiredOutcome', {
					template: '<div></div>',
					controller: function ($scope, $modalParams) {
						SetupComponent($scope, DesiredOutcome, null, $modalParams, {
							modal: true,
							redux: true
						});
					},
					style: 'form-wide default new-full-screen'
				})
				.modal('pdfGeneric', {
					template: '<div></div>',
					closeOnEscape: true,
					closeOnCurtain: true,
					controller: function ($scope, $modalParams) {
						SetupComponent($scope, PdfGeneric, null, $modalParams, {
							modal: true,
							ignoreAppService: true
						});
					},
					style: 'default pdf-preview more-z-index'
				})
				.modal('maxCeilingReachedOnSeats', {
					template: '<div></div>',
					closeOnEscape: false,
					noCloseOnStateChange: true,
					controller: function ($scope, $modalParams) {
						SetupComponent($scope, MaxCeilingReachedOnSeats, null, $modalParams, {
							modal: true,
							redux: true
						});
					},
					style: 'form-wide default new-full-screen'
				})
				.modal('yourYearWithUpsales', {
					template: '<div></div>',
					closeOnEscape: false,
					noCloseOnStateChange: true,
					controller: function ($scope, $modalParams) {
						SetupComponent($scope, YourYearWithUpsales, null, $modalParams, {
							modal: true,
							redux: true
						});
					},
					style: 'form-wide default new-full-screen no-padding'
				});

			/*/ GRAVATAR DEFAULTS
			gravatarServiceProvider.defaults = {
			"default": 'mm'  // Mystery man as default for missing avatars
			};
			
			// Use https endpoint
			gravatarServiceProvider.secure = true;*/

			angular.extend($datepickerProvider.defaults, {
				dateFormat: 'yyyy-mm-dd',
				startWeek: 1 // Default monday as first day of week
			});
		}
	])
	// form name dosn't interpolate  (ng-repeat > name="{{id}}" )
	// temp fix untill angular 1.3 beta-4 comes along
	// https://github.com/angular/angular.js/issues/1404
	.config([
		'$provide',
		function ($provide) {
			$provide.decorator('ngModelDirective', [
				'$delegate',
				function ($delegate) {
					var ngModel = $delegate[0],
						controller = ngModel.controller;
					ngModel.controller = [
						'$scope',
						'$element',
						'$attrs',
						'$injector',
						function (scope, element, attrs, $injector) {
							var $interpolate = $injector.get('$interpolate');
							attrs.$set('name', $interpolate(attrs.name || '')(scope));
							$injector.invoke(controller, this, {
								$scope: scope,
								$element: element,
								$attrs: attrs
							});
						}
					];
					return $delegate;
				}
			]);
			$provide.decorator('formDirective', [
				'$delegate',
				function ($delegate) {
					var form = $delegate[0],
						controller = form.controller;
					form.controller = [
						'$scope',
						'$element',
						'$attrs',
						'$injector',
						function (scope, element, attrs, $injector) {
							var $interpolate = $injector.get('$interpolate');
							attrs.$set('name', $interpolate(attrs.name || attrs.ngForm || '')(scope));
							$injector.invoke(controller, this, {
								$scope: scope,
								$element: element,
								$attrs: attrs
							});
						}
					];
					return $delegate;
				}
			]);
		}
	])

	.controller('IndexCtrl', function () {})

	.run([
		'$locale',
		'$rootScope',
		'$state',
		'$stateParams',
		'AppService',
		function ($locale, $rootScope, $state, $stateParams, AppService) {
			$locale.NUMBER_FORMATS.DECIMAL_SEP = ',';
			$locale.NUMBER_FORMATS.GROUP_SEP = ' ';

			$rootScope.times = [
				'06',
				'07',
				'08',
				'09',
				'10',
				'11',
				'12',
				'13',
				'14',
				'15',
				'16',
				'17',
				'18',
				'19',
				'20',
				'21',
				'22',
				'23',
				'00',
				'01',
				'02',
				'03',
				'04',
				'05'
			];
			$rootScope.timezone = localStorage.zone || 'europe/stockholm';
			$rootScope.$state = $state;
			$rootScope.$stateParams = $stateParams;
			$rootScope.pull = _.pull;
			$rootScope.$on('$stateChangeStart', function (e, toState, params) {
				var toStateParent = null;
				// Try to parse parent state
				try {
					var splt = toState.name.split('.');
					if (splt.length > 1) {
						toStateParent = $state.get(splt[0]);
					}
				} catch (e) {
					// error
				}
				// Check current state
				if (toState.url.indexOf(':customerId') !== -1 && (!params.customerId || params.customerId === '')) {
					params.customerId = AppService.getCustomerId();
				}
				// Check parent state if we have one
				if (
					toStateParent &&
					toStateParent.url.indexOf(':customerId') !== -1 &&
					(!params.customerId || params.customerId === '')
				) {
					params.customerId = AppService.getCustomerId();
				}
				if (toState.redirectTo) {
					e.preventDefault();
					$state.go(toState.redirectTo, params);
				}
				if (toState.modal) {
					e.preventDefault();
				}
			});

			// set current section on load and state change, also remove any open elevio popups
			$rootScope.$on('$stateChangeSuccess', function () {
				if (!document.body || !document.documentElement) {
					return;
				}

				document.body.scrollTop = document.documentElement.scrollTop = 0;
				$rootScope.currentSection = $state.current.section || 'sale';
				InAppChat.update();

				// Remove any open popup
				closeElevioWidgets();
			});

			// Notify analytics that the location have changed
			$rootScope.$on('$locationChangeSuccess', function () {
				Analytics.page($state.current.section, $state.current.name, {
					path: $state.current.url
				});
			});

			ReduxListeners.setRootScope($rootScope);
		}
	])
	.value('$strapConfig', {
		datepicker: {
			language: 'se',
			format: 'yyyy-mm-dd'
		}
	});
