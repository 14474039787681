'use strict';

angular.module('upResources').factory('MultiActions', [
	'$resource',
	'URL',
	'API',
	function MultiActions($resource, URL, API) {
		var Model = {
			customer: function (customer) {
				var instance = {};
				var MultiActions = null;
				var baseUrl = URL + API + customer + '/multi/:entity';

				MultiActions = $resource(
					baseUrl,
					{},
					{
						execute: { method: 'POST', isArray: false }
					}
				);

				instance.anonymize = function (options) {
					var body = {
						entity: 'anonymous',
						nrOfIds: options.objects.length,
						objects: options.objects,
						properties: options.properties,
						filter: options.filter,
						task: 'Update',
						action: 'Anonymize'
					};

					if (options.extraParams) {
						body = Object.assign(body, options.extraParams);
					}

					return MultiActions.execute({ entity: 'anonymous' }, body).$promise;
				};

				instance.delete = function (
					entity,
					nrOfIds,
					filter,
					name,
					useTriggers,
					notificationEntityNameExtension
				) {
					var body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: [{ name: 'useTriggers', value: useTriggers }],
						action: 'DeleteEntity',
						task: 'Delete'
					};

					if (name) {
						body.name = name;
					}
					if (notificationEntityNameExtension) {
						body.notificationEntityNameExtension = notificationEntityNameExtension;
					}

					return MultiActions.execute({ entity: entity }, body).$promise;
				};

				instance.updateAccount = function (entity, nrOfIds, properties, filter, extraParams) {
					var body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: properties,
						action: 'UpdateClient',
						task: 'Update'
					};

					if (extraParams) {
						body = Object.assign(body, extraParams);
					}

					return MultiActions.execute({ entity: entity }, body).$promise;
				};

				instance.createActivity = function (entity, nrOfIds, properties, filter, name, extraParams) {
					var body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: properties,
						action: 'CreateActivity',
						task: 'Create'
					};

					if (extraParams) {
						if (extraParams.source) {
							body.properties.push({ name: 'Source', value: extraParams.source });
							extraParams.source = undefined;
						}

						body = Object.assign(body, extraParams);
					}

					if (name) {
						body.name = name;
					}
					return MultiActions.execute({ entity: entity }, body).$promise;
				};

				instance.updateContact = function (entity, nrOfIds, properties, filter, extraParams) {
					var body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: properties,
						action: 'UpdateContact',
						task: 'Update'
					};

					if (extraParams) {
						body = Object.assign(body, extraParams);
					}

					return MultiActions.execute({ entity: entity }, body).$promise;
				};

				instance.updateClientContacts = function (entity, nrOfIds, properties, filter, extraParams) {
					var body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: properties,
						action: 'UpdateClientContacts',
						task: 'Update'
					};

					if (extraParams) {
						body = Object.assign(body, extraParams);
					}

					return MultiActions.execute({ entity: entity }, body).$promise;
				};

				instance.updateActivity = function (nrOfIds, properties, filter) {
					var body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: properties,
						action: 'UpdateActivity',
						task: 'Update'
					};

					return MultiActions.execute({ entity: 'Activity' }, body).$promise;
				};

				instance.updateOrder = function (entity, nrOfIds, properties, filter) {
					var body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: properties,
						action: 'UpdateOrder',
						task: 'Update'
					};

					return MultiActions.execute({ entity: entity }, body).$promise;
				};

				instance.updateAppointment = function (nrOfIds, properties, filter) {
					var body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: properties,
						action: 'UpdateAppointment',
						task: 'Update'
					};

					return MultiActions.execute({ entity: 'Appointment' }, body).$promise;
				};

				instance.updateFile = function (nrOfIds, properties, filter) {
					var body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: properties,
						action: 'UpdateFile',
						task: 'Update'
					};

					return MultiActions.execute({ entity: 'FileMetadata' }, body).$promise;
				};

				instance.updateMailtemplate = function (nrOfIds, properties, filter) {
					const body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: properties,
						action: 'UpdateMailTemplate',
						task: 'Update'
					};

					return MultiActions.execute({ entity: 'MailTemplate' }, body).$promise;
				};

				instance.updateForm = function (nrOfIds, properties, filter) {
					const body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: properties,
						action: 'UpdateForm',
						task: 'Update'
					};

					return MultiActions.execute({ entity: 'Form' }, body).$promise;
				};

				instance.updateMailCampaign = function (nrOfIds, properties, filter) {
					const body = {
						nrOfIds: nrOfIds,
						filter: filter,
						properties: properties,
						action: 'UpdateMailCampaign',
						task: 'Update'
					};

					return MultiActions.execute({ entity: 'MailCampaign' }, body).$promise;
				};

				instance.excludeContactsSocialEvent = function (nrOfIds, properties, filter) {
					var body = {
						nrOfIds: nrOfIds,
						filter: filter.build(),
						action: 'ExcludeFromSocialEvent',
						task: 'Delete',
						properties: properties
					};

					return MultiActions.execute({ entity: 'Contact' }, body).$promise;
				};

				instance.updateTicket = function (nrOfIds, properties, filter) {
					const body = {
						nrOfIds: nrOfIds,
						filter: filter,
						action: 'UpdateTicket',
						task: 'Update',
						properties: properties
					};

					return MultiActions.execute({ entity: 'Ticket' }, body).$promise;
				};

				instance.updateComment = function (nrOfIds, properties, filter) {
					const body = {
						nrOfIds: nrOfIds,
						filter: filter,
						action: 'UpdateComment',
						task: 'Update',
						properties: properties,
						skipNotification: true
					};

					return MultiActions.execute({ entity: 'Comment' }, body).$promise;
				};

				return instance;
			}
		};

		return Model;
	}
]);
