import { Text, Input, Block, Row, Column, Button, Loader, Flex } from '@upsales/components';
import CriteriaDrilldownWrapper from '../../../CriteriaDrilldownWrapper';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import React, { useState, useEffect, useRef } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import IndustryCPVRow from './IndustryCPVRow';
import T from 'Components/Helpers/translate';
import logError from 'Helpers/logError';
import { debounce } from 'lodash';

export interface DrilldownProps {
	selectData: {
		[key: string]: Promise<{ data: any[]; metadata: { total: number } }>;
	};
	onChange: (value: string[], filter: object, newFilter: object) => void;
	filter: {
		filterName: string;
		value: string[];
		cpvCodes?: string;
		type?: string;
	};
	open?: boolean;
}

const SearchInput = ({ placeholder, onChange }: { placeholder: string; onChange: (value: string) => void }) => {
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		onChange(searchValue);
	}, [searchValue]);

	return (
		<Input
			icon="search"
			placeholder={placeholder}
			value={searchValue}
			onChange={event => setSearchValue(event.target.value)}
			onClear={() => setSearchValue('')}
		/>
	);
};

const IndustryCPVDrilldown: React.FC<DrilldownProps> = ({ selectData, onChange, filter, open }) => {
	const [cpvData, setCpvData] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [expanded, setExpanded] = useState<Record<string, boolean>>({});
	const [searchValue, setSearchValue] = useState('');

	const dataRequest = useRef<CancelablePromise | null>(null);

	const classes = new bemClass('ProspectingDrilldown');
	const MAX_ITERATIONS = 1000;

	const onChangeLocal = (value: string[]) => {
		const newFilter = { ...filter, value };
		onChange(value, filter, newFilter);
	};

	useEffect(() => {
		if (!open || !selectData?.[filter.filterName]) return;

		setLoading(true);

		dataRequest.current?.cancel();

		dataRequest.current = makeCancelable(selectData[filter.filterName]);

		dataRequest.current.promise
			.then(result => {
				if (result?.data) {
					setCpvData(result.data);
				}
			})
			.catch(error => {
				if (!error.isCanceled) {
					logError('Failed to load CPV data:', error);
				}
			})
			.finally(() => setLoading(false));

		return () => {
			dataRequest.current?.cancel();
		};
	}, [selectData, filter.filterName, open]);

	useEffect(() => {
		if (!filter.value || !Array.isArray(filter.value)) {
			onChangeLocal([]);
		} else {
			const cleanedValue = filter.value.filter(v => v.length > 1 || /^\d+$/.test(v));
			if (cleanedValue.length !== filter.value.length) {
				onChangeLocal(cleanedValue);
			}
		}
	}, []);

	const lang = {
		cpvCode: T('default.companyBranch.cpv'),
		searchCpvBranch: T('default.search') + ' ' + T('default.companyBranch').toLowerCase(),
		cpvBranchName: T('default.companyBranchName').toUpperCase(),
		noOfCompanies: T('default.noOfCompanies').toUpperCase(),
		deselectAll: T('default.deselectAll')
	};

	const onSearchChanged = debounce((value: string) => {
		setSearchValue(value);
	}, 250);

	const getAllChildIds = (item: any): string[] => {
		if (!item.children?.length) {
			return [item.id];
		}

		const children =
			filter.cpvCodes === 'industriesWithSales'
				? item.children.filter((child: any) => child.orderValue > 0)
				: item.children;

		return children.reduce((acc: string[], child: any) => [...acc, ...getAllChildIds(child)], [item.id]);
	};

	const findParent = (items: any[], targetId: string): any => {
		for (const item of items) {
			if (item.children?.some((child: any) => child.id === targetId)) {
				return item;
			}
			if (item.children) {
				const found = findParent(item.children, targetId);
				if (found) return found;
			}
		}
		return null;
	};

	const checkParentStatus = (
		allChildIds: string[],
		selectedValues: string[]
	): { checked: boolean; half: boolean } => {
		const selectedCount = allChildIds.filter(id => selectedValues.includes(id)).length;
		return {
			checked: selectedCount === allChildIds.length && selectedCount > 0,
			half: selectedCount > 0 && selectedCount < allChildIds.length
		};
	};

	const onClick = (item: any, checked: boolean) => {
		const currentValue = Array.isArray(filter.value) ? filter.value : [];
		const allChildIds = getAllChildIds(item);
		let newValue: string[];

		if (!checked) {
			newValue = [...new Set([...currentValue, ...allChildIds])];
			onChangeLocal(newValue);
		} else {
			newValue = currentValue.filter(id => !allChildIds.includes(id));

			const visitedParents = new Set<string>();
			let parent = findParent(cpvData, item.id);
			let iterationCount = 0;

			while (parent && !visitedParents.has(parent.id)) {
				if (iterationCount >= MAX_ITERATIONS) {
					console.warn('Max iteration limit reached while processing parent nodes');
					break;
				}

				iterationCount++;
				visitedParents.add(parent.id);
				const siblingIds = getAllChildIds(parent).filter(id => id !== parent.id);
				const parentStatus = checkParentStatus(siblingIds, newValue);

				if (!parentStatus.checked && !parentStatus.half) {
					newValue = newValue.filter(id => id !== parent.id);
				}

				parent = findParent(cpvData, parent.id);
			}

			onChangeLocal(newValue);
		}
	};

	const checkStatus = (item: any) => {
		const currentValue = Array.isArray(filter.value) ? filter.value : [];
		const valueMap = currentValue.reduce((res: Record<string, boolean>, id: string) => {
			res[id] = true;
			return res;
		}, {});

		if (!item.children?.length) {
			return {
				checked: valueMap[item.id] || false,
				half: false
			};
		}

		const childIds = getAllChildIds(item).filter(id => id !== item.id);
		return checkParentStatus(childIds, currentValue);
	};

	const toggleExpanded = (parent: any) => {
		setExpanded(prev => ({
			...prev,
			[parent.id]: !prev[parent.id]
		}));
	};

	const itemVisible = (item: any) => {
		const lowerCasedSearchValue = searchValue.toLowerCase();
		const visible = (item: any) =>
			item.name.toLowerCase().includes(lowerCasedSearchValue) ||
			item?.codeAndName?.toLowerCase()?.includes(lowerCasedSearchValue);

		return item.children ? visible(item) || item.children.some((child: any) => visible(child)) : visible(item);
	};

	const deselectAll = () => {
		onChangeLocal([]);
	};

	const renderRows = (items: any[], level: number = 0): JSX.Element[] => {
		return items.reduce((rows: JSX.Element[], item: any) => {
			if (!item || !item.id) return rows;

			const { checked, half } = checkStatus(item);
			const isExpanded = expanded[item.id] || !!searchValue;
			const visible = itemVisible(item);

			if (visible) {
				rows.push(
					<IndustryCPVRow
						key={item.id}
						half={half}
						checked={checked}
						isExpanded={isExpanded}
						item={item}
						onSelect={onClick}
						toggleExpanded={item.children?.length ? toggleExpanded : undefined}
						level={level}
					/>
				);

				if (isExpanded && item.children?.length) {
					rows.push(...renderRows(item.children, level + 1));
				}
			}

			return rows;
		}, []);
	};

	const rows = renderRows(cpvData);

	return (
		<CriteriaDrilldownWrapper className={classes.mod('cpvcode').b()} open={!!open}>
			<td colSpan={3}>
				<Block className="drilldown-animation-wrapper">
					{loading ? (
						<Loader />
					) : (
						<table cellPadding={5}>
							<tbody>
								<tr>
									<td className="title-col">
										<Text>{lang.cpvCode}</Text>
									</td>
									<td className="table-col">
										<Flex>
											<Row>
												<Column fixedWidth={400}>
													<SearchInput
														placeholder={lang.searchCpvBranch}
														onChange={onSearchChanged}
													/>
												</Column>
												<Column>
													{filter.value?.length ? (
														<Button type="link" onClick={deselectAll}>
															<Text color="grey-11" italic={true}>
																{lang.deselectAll}
															</Text>
														</Button>
													) : null}
												</Column>
											</Row>
										</Flex>
									</td>
								</tr>
								<tr>
									<td className="title-col" />
									<td className="table-col">
										<Flex className="drilldown-row">
											<Block className={classes.elem('table').b()}>
												<Row>
													<Column fixedWidth={30} />
													<Column>
														<Text size="sm">{lang.cpvBranchName}</Text>
													</Column>
													<Column align="right">
														<Text size="sm">{lang.noOfCompanies}</Text>
													</Column>
													<Column fixedWidth={35} />
												</Row>
												{rows}
											</Block>
										</Flex>
									</td>
								</tr>
							</tbody>
						</table>
					)}
				</Block>
			</td>
		</CriteriaDrilldownWrapper>
	);
};

export default IndustryCPVDrilldown;
