import React, { Component, useEffect, useState } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Loader } from '@upsales/components';
import _ from 'lodash';

import AdminHeader from 'Components/Admin/AdminHeader';
import t from 'Components/Helpers/translate';
import Role from 'App/resources/Model/Role';
import { BasicUserWithPermissions } from 'App/resources/Model/User';
import RoleSelect from 'Components/RoleSelect';
import LookerDashboard, { Dashboard } from 'App/resources/LookerDashboard';
import logError from 'Helpers/logError';

import './Looker.scss';

type Props = {
	rootData: {
		editExploreAccess: (value: BasicUserWithPermissions[]) => void;
		toogleHideReportCenter: (value: boolean) => void;
		editDashboardAccess: (dashboardId: any, roles: any) => void;
		pageData: {
			hideReportCenter: boolean;
			editDashboardAccess: (dashboardId: any, roles: any) => void;
			exploreAccess: Function;
			dashboards: Dashboard[];
			roles: Role[];
		};
	};
};

class Looker extends Component<Props> {
	lang: { [key: string]: string };

	constructor(p: Props) {
		super(p);
		this.lang = {
			looker: t('admin.looker'),
			lookerInfo: t('admin.lookerInfo'),
			name: t('default.name'),
			type: t('admin.looker.type'),
			dashboards: t('admin.looker.dashboards'),
			explore: t('admin.looker.explore'),
			dashboardAccess: t('admin.looker.dashboardAccess'),
			hideReportCenter: t('admin.looker.hideReportCenter'),
			chooseRoles: t('admin.looker.chooseRoles'),
			chooseUsers: t('admin.looker.chooseUsers')
		};
	}

	render() {
		const rootData = this.props.rootData;
		const hasTreeSelect = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');

		const dashboards = rootData.pageData.dashboards.map((dashboard, i) => {
			const badTypeRoles = dashboard.roles as unknown as Optional<Role, 'userEditable' | 'userRemovable'>;
			return (
				<tr key={'dashboard-' + i}>
					<td>{dashboard.title}</td>
					<td>{t(dashboard.space)}</td>
					<td>
						{hasTreeSelect ? (
							<RoleSelect
								roles={rootData.pageData.roles}
								selectedRoles={dashboard.roles}
								onChange={rootData.editDashboardAccess.bind(this, dashboard.id)}
							/>
						) : (
							<ReactTemplates.INPUTS.upRoles
								tools={Tools}
								data={rootData.pageData.roles}
								value={badTypeRoles}
								multiple={true}
								asTree="true"
								placeholder={this.lang.chooseRoles}
								onChange={rootData.editDashboardAccess.bind(this, dashboard.id)}
							/>
						)}
					</td>
				</tr>
			);
		});

		return (
			<div id="admin-page-looker" className="AdminLooker">
				<AdminHeader
					title={this.lang.looker}
					description={this.lang.lookerInfo}
					image="looker.svg"
					toggle={true}
					toggleChecked={rootData.pageData.hideReportCenter}
					onToggleChange={rootData.toogleHideReportCenter}
					toggleLabel={this.lang.hideReportCenter}
				/>

				<div id="admin-content">
					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{this.lang.dashboards}</span>
						</div>

						<table>
							<thead>
								<tr>
									<th>{this.lang.name}</th>
									<th>{this.lang.type}</th>
									<th id="access-header">{this.lang.dashboardAccess}</th>
								</tr>
							</thead>
							<tbody>{dashboards}</tbody>
						</table>
					</div>

					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{this.lang.explore}</span>
							<div>
								<div className="selct2-holder">
									<ReactTemplates.INPUTS.upUsers
										multiple={true}
										className="form-control"
										value={rootData.pageData.exploreAccess}
										onChange={rootData.editExploreAccess}
										tools={Tools}
										placeholder={this.lang.chooseUsers}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export const WrappedLooker = () => {
	const [dashboards, setDashboards] = useState<Props['rootData']['pageData']['dashboards']>([]);
	const [hideReportCenter, setHideReportCenter] = useState<boolean>(
		Tools.AppService.getMetadata().params.HideReportCenter
	);

	const editDashboardAccess: Props['rootData']['editDashboardAccess'] = (dashboardId, roles) => {
		var roleIds = _.pluck(roles, 'id');
		var index = dashboards.findIndex(db => db.id === dashboardId);
		var oldDashboard = _.cloneDeep(dashboards[index]);
		setDashboards(prev => [...prev.slice(0, index), { ...oldDashboard, roles }, ...prev.slice(index + 1)]);

		LookerDashboard.save({ id: dashboardId, roleIds }, { notificationTitle: 'dsa' })
			.then(() => {
				Tools.NotificationService.addNotification({
					icon: 'check',
					style: Tools.NotificationService.style.SUCCESS,
					title: 'default.updated',
					body: 'updated.lookerDashboard'
				});
			})
			.catch(() => {
				setDashboards(prev => [...prev.slice(0, index), oldDashboard, ...prev.slice(index + 1)]);
				Tools.NotificationService.addNotification({
					icon: 'times',
					style: Tools.NotificationService.style.SUCCESS,
					title: 'default.error',
					body: 'saveError.lookerDashboard'
				});
			});
	};

	const editExploreAccess: Props['rootData']['editExploreAccess'] = users => {
		var userIdArray = _.pluck(users, 'id');
		var metadata = Tools.AppService.getMetadata();

		var method: keyof typeof Tools.ClientParam = 'save';
		if (metadata.params.LookerExploreAccess === null) {
			method = 'create';
		}
		Tools.ClientParam[method](210, userIdArray.join(','))
			.then(function () {
				metadata.params.LookerExploreAccess = userIdArray;
				Tools.AppService.setMetadata(metadata);
			})
			.catch(logError);
	};

	const toogleHideReportCenter: Props['rootData']['toogleHideReportCenter'] = value => {
		var metadata = Tools.AppService.getMetadata();
		var method: 'create' | 'save' = 'save';
		if (metadata.params.HideReportCenter === null) {
			method = 'create';
		}
		setHideReportCenter(value);
		Tools.ClientParam[method](222, value)
			.then(function () {
				metadata.params.HideReportCenter = value;
				Tools.AppService.setMetadata(metadata);
			})
			.catch(logError);
	};

	const exploreAccess: Props['rootData']['pageData']['exploreAccess'] = function () {
		var userIds: number[] = Tools.AppService.getMetadata().params.LookerExploreAccess || [];

		return userIds
			.map(userId => {
				return Tools.AppService.getActiveUsers().find(u => u.id === userId);
			})
			.filter(Boolean);
	};

	useEffect(() => {
		LookerDashboard.find()
			.then(res => {
				const dashboards = res.data || [];
				const newDashboards = dashboards.map(d => {
					const roles = Tools.AppService.getRoles().reduce(
						(acc: Optional<Role, 'userEditable' | 'userRemovable'>[], r) => {
							if (d.roleIds?.includes(r.id)) {
								acc.push(r);
							}
							return acc;
						},
						[]
					);
					return {
						...d,
						roles
					};
				});
				setDashboards(newDashboards);
			})
			.catch(() => {});
	}, []);

	const rootData = {
		editDashboardAccess,
		toogleHideReportCenter,
		editExploreAccess,
		pageData: {
			hideReportCenter,
			editDashboardAccess,
			exploreAccess: exploreAccess(),
			dashboards,
			roles: Tools.AppService.getRoles()
		}
	};

	const classes = new bemClass('WrappedLooker');

	if (!rootData || !dashboards.length) return <Loader className={classes.elem('loader').b()} />;

	return (
		<div id="admin" className={classes.b()}>
			<div id="admin-root">
				<Looker rootData={rootData} />
			</div>
		</div>
	);
};

export default Looker;
