import EasyBookingLinkDropdown from 'Components/EasyBookingLinkDropdown';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

angular.module('domain.appointment').controller('ListAppointment', [
	'$scope',
	'$translate',
	'$upModal',
	'AppointmentAttributes',
	'Appointment',
	'$multiSelect',
	'AppService',
	'FilterHelper',
	'FeatureHelper',
	'MultiRunnerActions',
	'Onboarding',
	'$safeApply',
	function (
		$scope,
		$translate,
		$upModal,
		AppointmentAttributes,
		Appointment,
		$multiSelect,
		AppService,
		FilterHelper,
		FeatureHelper,
		MultiRunnerActions,
		Onboarding,
		$safeApply
	) {
		var Ctrl = this;
		var listViewCtrl = {};
		const currentDay = new Date().getDay();
		const openModal = require('App/services/Modal').default;

		Ctrl.attributes = AppointmentAttributes();
		Ctrl.disableAddFilter = false;
		Ctrl.showAddBtn = true;
		Ctrl.listType = 'appointment';
		Ctrl.tableTemplate = 'appointmentRow';
		Ctrl.title = 'default.appointments';
		Ctrl.useMultiActions = true;
		Ctrl.total = 0;
		Ctrl.secondList = 'activities';
		Ctrl.secondListText = 'default.activities';

		Ctrl.headerExtensionPath = require('App/upsales/common/components/ui/templates/listAppointmentsHeaderExtension.html?file');
		Ctrl.headerExtension = { path: Ctrl.headerExtensionPath };
		Ctrl.removeActivities = FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
		Ctrl.headerListLinks = Ctrl.removeActivities
			? []
			: [
					{
						type: 'activity',
						state: 'activities',
						text: 'default.activities',
						icon: 'Icon Icon-activity'
					},
					{
						type: 'appointment',
						state: 'appointments',
						text: 'default.appointments',
						icon: 'fa-calendar'
					}
			  ];
		Ctrl.EasyBookingLinkDropdown = EasyBookingLinkDropdown;
		Ctrl.easyBookingLinkProps = {
			hasTooltip: false
		};

		const onResize = () => {
			const isFiltersOpen = !!document.querySelectorAll('#main-content.has-up-list-filters.filters-open').length;

			const breakPointFilters = 1450; // if adjusted, plz adjust in ui/app/styles/_list-view.scss
			const breakPointNoFilters = 1385; // if adjusted, plz adjust in ui/app/styles/_list-view.scss
			Ctrl.easyBookingLinkProps = {
				hasTooltip: window.innerWidth < (isFiltersOpen ? breakPointFilters : breakPointNoFilters)
			};
			$safeApply($scope);
		};

		Ctrl.quickDateFilters = 'Date';
		const weekFilter =
			currentDay >= 4 || currentDay === 0 // Thursday and bigger or Sunday
				? { value: 'nextWeek', title: $translate.instant('date.nextWeek') }
				: { value: 'currentWeek', title: $translate.instant('ads.graphSelect.currentWeek') };
		Ctrl.quickDateFilterOptions = [
			{ value: 'currentDay', title: $translate.instant('calendar.today') },
			{ value: 'nextDay', title: $translate.instant('date.nextDay') },
			weekFilter
		];
		Ctrl.addButton = {
			getLabel: function () {
				return (
					$translate.instant('default.add') + ' ' + $translate.instant('default.appointment').toLowerCase()
				);
			},
			createEntry: function () {
				Ctrl.createEntry();
				Onboarding.setListIntro('appointment');
			},
			isEnabled: function () {
				const self = AppService.getSelf();
				return self.createRights.Appointment !== 'NO';
			}
		};

		Ctrl.quickSearch = {
			getFilter: function (value) {
				return {
					filterName: 'ListSearch',
					inactive: !value,
					value: value
				};
			},
			getValue: function (props) {
				var filter = props.activeFilters.ListSearch;
				return filter && filter.value ? filter.value : '';
			},
			getPlaceholder: function () {
				return $translate.instant('filters.quickSearch');
			}
		};

		Ctrl.getReference = function (_listViewCtrl) {
			listViewCtrl = _listViewCtrl;
			listViewCtrl.listType = Ctrl.listType;
		};

		var appointments = [];
		var documentTemplates = [];
		var customerId;
		var currentQuery = [];

		var updateMultiselectTotal = function () {
			$multiSelect.setTotal(Ctrl.total);
		};

		// // Listen for order changes
		$scope.$on('order.updated', function (e, order) {
			var appointment = _.find(appointments, { opportunity: { id: order.id } });

			if (appointment) {
				appointment.opportunity = order;
			}
		});

		$scope.$on('appointment.added', function (e, added) {
			if (FilterHelper.match(currentQuery.q, added, 'appointment')) {
				Ctrl.total++;
				updateMultiselectTotal();
				appointments.push(added);
				$scope.$broadcast('listView.updateTableData', {
					data: appointments,
					total: Ctrl.total
				});
			}
		});

		$scope.$on('appointment.updated', function (e, updated) {
			var existingIndex = _.findIndex(appointments, { id: updated.id });
			if (existingIndex !== -1 && FilterHelper.match(currentQuery.q, updated, 'appointment')) {
				appointments[existingIndex] = updated;
			} else if (existingIndex !== -1) {
				Ctrl.total--;
				updateMultiselectTotal();
				appointments.splice(existingIndex, 1);
			}
			$scope.$broadcast('listView.updateTableData', {
				data: appointments,
				total: Ctrl.total
			});
		});

		$scope.$on('appointment.deleted', function (e, deleted) {
			var existingIndex = _.findIndex(appointments, { id: deleted.id });
			if (existingIndex !== -1) {
				Ctrl.total--;
				updateMultiselectTotal();
				appointments.splice(existingIndex, 1);
				$scope.$broadcast('listView.updateTableData', {
					data: appointments,
					total: Ctrl.total
				});
			}
		});

		$scope.$on('appointment.list.multiRemove', (e, deletedIds) => {
			if (!deletedIds?.length) {
				return;
			}
			Ctrl.total -= deletedIds.length;
			appointments = appointments.filter(appointment => !deletedIds.includes(appointment.id));
			$scope.$broadcast('listView.updateTableData', {
				data: appointments,
				total: Ctrl.total
			});
		});

		Ctrl.formatTotal = function () {
			if (Ctrl.total === 1) {
				return Ctrl.total + ' ' + $translate.instant('default.appointment').toLowerCase();
			} else if (Ctrl.total === 0) {
				return 0 + ' ' + $translate.instant('default.appointments').toLowerCase();
			} else {
				return Ctrl.total + ' ' + $translate.instant('default.appointments').toLowerCase();
			}
		};

		/**
		 * The add button
		 */
		Ctrl.createEntry = function () {
			var options = { customerId: customerId };

			openEditAppointment(options);
		};

		// The getter function {listView} will need
		Ctrl.getEntries = function (query) {
			currentQuery = query;
			Ctrl.total = 0;

			return Appointment.customer(customerId)
				.find(query)
				.then(function (res) {
					appointments = res.data;
					Ctrl.total = res.metadata ? res.metadata.total : res.length;
					return res;
				});
		};

		Ctrl.init = function () {
			// Set customer it now when we know we have it
			customerId = AppService.getCustomerId();

			// Document templates
			documentTemplates = AppService.getDocumentTemplates('activity');

			Ctrl.addBtnLabel =
				$translate.instant('default.add') + ' ' + $translate.instant('default.appointment').toLowerCase();

			Ctrl.customFields = AppService.getCustomFields('appointment');

			Ctrl.listOpts = {
				type: 'appointment',
				hasCheck: true,
				editable: true,
				placeholderKey: 'appointment',
				hasDocumentTemplates: !!(documentTemplates && documentTemplates.length),
				clickedEntry: function (appointment) {
					var options = {
						customerId: customerId,
						id: appointment.id
					};
					openEditAppointment(options);
				},
				editOrder: function (order, e) {
					e.stopPropagation();
					e.nativeEvent.stopImmediatePropagation();

					var type = order.probability === 0 || order.probabilit === 100 ? 'order' : 'opportunity';
					$upModal.open('editOrder', {
						id: order.id,
						customerId: customerId,
						type: type
					});
				},
				removeEntry: function (appointment) {
					return Appointment.customer(customerId)['delete'](appointment);
				},
				createDocument: function (appointment) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('CREATE_DOCUMENT_REACT')) {
						openModal('CreateDocumentModal', {
							type: 'appointment',
							id: appointment.id,
							accountId: appointment.client.id,
							templates: documentTemplates
						});
					} else {
						$upModal.open('createDocument', {
							type: 'appointment',
							id: appointment.id,
							accountId: appointment.client.id,
							templates: documentTemplates
						});
					}
				}
			};

			Ctrl.multiActions = MultiRunnerActions.get(MultiRunnerActions.type.APPOINTMENT);

			onResize();
			window.addEventListener('resize', onResize, true);
			window.addEventListener('filtersToggledInstant', onResize);

			$scope.$on('$destroy', () => {
				window.removeEventListener('resize', onResize, true);
				window.removeEventListener('filtersToggledInstant', onResize);
			});
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(Ctrl.init);
	}
]);
