import React from 'react';
import { Loader } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';

// This is deprecated, Use Loader!

ReactTemplates.loader = ({ className, noU, ...props }) => {
	const classes = new bemClass('upsales-loader', className);
	let size = 'md';

	if (noU) {
		size = 'sm';
	}

	return <Loader {...props} size={size} className={classes.b()} />;
};
