'use strict';

import ProcessedBy from 'App/components/columns/ProcessedBy';
import { openAssignModal } from 'Components/AssignModal/AssignModal';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

ReactTemplates.processedBy = {};

ReactTemplates.processedBy.closeactivity = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;

		this.lang = {
			closeActivity: t('default.closeActivity'),
			addNotes: t('default.addNotes'),
			title: t('processedBy.closeActivity'),
			notes: t('default.notes'),
			note: t('tag.account.notes'),
			regret: t('default.regret'),
			placeholder: t('processedBy.closeActivity.description')
		};
	},
	regret: function () {
		var props = this.props;
		var changeView = props.actions.changeView;

		changeView(props.Views.ACTIVITIES);
	},
	closeActivity: function () {
		var closeActivity = this.props.actions.closeActivity;
		var notes = this._notes.value;

		closeActivity(notes);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	render: function () {
		var lang = this.lang;

		return (
			<div className="pb-close-activity">
				<div className="pb-close-activity-wrap">
					<div className="pb-close-activity-title">{lang.title}</div>
					<label htmlFor="close-activity-textarea">{lang.note}</label>
					<textarea
						ref={this.setRef.bind(this, '_notes')}
						className="form-control"
						rows="5"
						id="close-activity-textarea"
						placeholder={lang.placeholder + '...'}
					/>
					<button
						data-test-id="processedby-modal-close-activity"
						className="btn up-btn btn-bright-blue no-shadow close-activity-button"
						onClick={this.closeActivity}
					>
						{lang.closeActivity}
					</button>
					<div className="pb-close-activity-regret-button">
						<button className="btn btn-link btn-link--nodecoration btn-link--gray" onClick={this.regret}>
							{lang.regret}
						</button>
					</div>
				</div>
			</div>
		);
	}
});

ReactTemplates.processedBy.assign = window.ReactCreateClass({
	getInitialState: function () {
		return {
			settingsExpanded: this.props.settingsExpanded || false,
			selectedUser: null,
			assigning: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;
		var props = this.props;

		this.lang = {
			noActivitiesOn: t('processedBy.NoActivitiesOn'),
			assignHelp: t('processedBy.AssignHelp'),
			assignUser: t('processedBy.AssignUser'),
			regret: t('default.regret'),
			settings: t('admin.settings'),
			hide: t('default.hide'),
			selectUser: t('default.selectUser'),
			createActivity: t('automationAction.CreateActivity'),
			replaceCurrentAccountManager: t('processedBy.replaceCurrentAccountManager'),
			settUserAsAccountManager: t('processedBy.settUserAsAccountManager'),
			sendNotificationMail: t('processedBy.sendNotificationMail'),
			activityDescription: t('processedBy.activityDescription'),
			willCreateActivity: t('processedBy.willCreateActivity')
		};

		this.updateSettingsSetUserAsAccountManager = props.actions.updateSettings.bind(this, 'setUserAsAccountManager');
		this.updateSettingsReplaceCurrentAccountManager = props.actions.updateSettings.bind(
			this,
			'replaceCurrentAccountManager'
		);
		this.updateSettingsSendNotificationMail = props.actions.updateSettings.bind(this, 'sendNotificationMail');
	},
	getAssignedUser: function (item) {
		return item.assigned && item.assigned.user ? item.assigned.user : null;
	},
	toggleShowsettings: function () {
		this.setState({
			settingsExpanded: !this.state.settingsExpanded
		});
	},
	onChange: function (user) {
		this.setState({
			selectedUser: user
		});
	},
	assign: function () {
		var assign = this.props.actions.assign;

		this.setState({
			assigning: true
		});

		assign(this.state.selectedUser);
	},
	updateSettingsActivityDescription: function (e) {
		var value = e.target.value;
		this.props.actions.updateSettings('activityDescription', value);
	},
	render: function () {
		var props = this.props;
		var state = this.state;
		var lang = this.lang;
		var title;

		var haveProcessedByUser = !!(
			props.client &&
			props.client.processedBy &&
			props.client.processedBy.user &&
			props.client.processedBy.user.id
		);

		if (!haveProcessedByUser) {
			title = (
				<div className="pb-assign-title no-activities">
					{lang.noActivitiesOn}
					<br />
					<span className="pb-assign-client-name">{props.client.name}</span>
				</div>
			);
		} else {
			title = <div className="pb-assign-title">{lang.assignUser}</div>;
		}

		var subtitle = <div className="pb-assign-subtitle">{lang.assignHelp}</div>;

		var userSelect = (
			<div className="pb-assign-userselect">
				{!props.clientLoading ? (
					<ReactTemplates.INPUTS.upUsers
						required={true}
						value={state.selectedUser}
						onChange={this.onChange}
						placeholder={lang.selectUser}
						data={props.assignableUsers}
					/>
				) : (
					<div className="pb-select2-dummy form-control" />
				)}
			</div>
		);

		var settingsButton = (
			<div
				data-test-id="processedby-modal-settings"
				onClick={this.toggleShowsettings}
				className="pb-assign-settings-button"
			>
				<button className="btn btn-link btn-link--nodecoration">
					{state.expanded ? lang.hide + ' ' + lang.settings.toLowerCase() : lang.settings}
				</button>
			</div>
		);

		var settings = null;

		if (state.settingsExpanded) {
			settings = (
				<div className="pb-assign-settings">
					<div className="pb-assign-settings-row">
						<i className="fa fa-check" aria-hidden="true" /> {this.lang.willCreateActivity}
					</div>
					<div className="pb-assign-settings-row">
						<ReactTemplates.upFilters.components.toggle
							data-test-id="processedby-modal-settings-setasaccountmanager"
							className="toggle-sm toggle-bright-blue"
							onChange={this.updateSettingsSetUserAsAccountManager}
							value={props.settings.setUserAsAccountManager}
						/>{' '}
						{lang.settUserAsAccountManager}
					</div>
					{props.settings.setUserAsAccountManager && props.teamAccountManager ? (
						<div className="pb-assign-settings-row">
							<ReactTemplates.upFilters.components.toggle
								data-test-id="processedby-modal-settings-replaceaccountmanager"
								className="toggle-sm toggle-bright-blue"
								onChange={this.updateSettingsReplaceCurrentAccountManager}
								value={props.settings.replaceCurrentAccountManager}
							/>{' '}
							{lang.replaceCurrentAccountManager}
						</div>
					) : null}
					<div className="pb-assign-settings-row">
						<ReactTemplates.upFilters.components.toggle
							data-test-id="processedby-modal-settings-mail"
							className="toggle-sm toggle-bright-blue"
							onChange={this.updateSettingsSendNotificationMail}
							value={props.settings.sendNotificationMail}
						/>{' '}
						{lang.sendNotificationMail}
					</div>
					<div className="pb-assign-settings-row">
						<label htmlFor="pb-notes">{lang.activityDescription}</label>
						<input
							id="pb-notes"
							data-test-id="processedby-modal-settings-description"
							className="form-control"
							onChange={this.updateSettingsActivityDescription}
							value={props.settings.activityDescription}
						/>
					</div>
				</div>
			);
		}

		var assignButton = (
			<div className="pb-assign-assign-button">
				<button
					data-test-id="processedby-modal-assign-submit"
					className="btn up-btn btn-bright-blue no-shadow"
					onClick={this.assign}
					disabled={this.state.assigning || state.selectedUser === null}
				>
					{lang.assignUser}
				</button>
			</div>
		);

		var regretButton = null;

		if (haveProcessedByUser) {
			regretButton = (
				<div className="pb-assign-regret-button">
					<button
						data-test-id="processedby-modal-assign-regret"
						className="btn btn-link btn-link--nodecoration btn-link--gray"
						onClick={props.actions.regretAssign}
					>
						{lang.regret}
					</button>
				</div>
			);
		}

		return (
			<div>
				{title}
				{subtitle}
				{userSelect}
				{settingsButton}
				{settings}
				{assignButton}
				{regretButton}
			</div>
		);
	}
});

ReactTemplates.processedBy.assigned = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;

		this.lang = {
			activityClosed: t('processedBy.activityClosed')
		};
	},
	render: function () {
		var lang = this.lang;

		return (
			<div className="pb-assigned">
				<div>
					<i className="fa fa-check" aria-hidden="true" />
				</div>
				<div>{lang.activityClosed}</div>
			</div>
		);
	}
});

ReactTemplates.processedBy.activityRow = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;
		var props = this.props;
		var item = props.item;

		this.lang = {
			chooseUser: t('assign.chooseSeller'),
			inactive: t('default.inactive'),
			closeActivity: t('default.closeActivity')
		};

		this.onActivityClick = item.isAppointment
			? props.actions.openAppointment.bind(this, item)
			: props.actions.openActivity.bind(this, item);
	},
	getClasses: function (allClasses, additionalClass, condition) {
		return condition ? allClasses + ' ' + additionalClass : allClasses;
	},
	closeActivity: function () {
		var props = this.props;
		var changeView = props.actions.changeView;

		changeView(props.Views.CLOSEACTIVITY, { editItem: props.item });
	},
	toggleExpand: function (column) {
		var props = this.props;
		var toggleExpand = props.actions.toggleExpand;
		var item = props.item;

		toggleExpand(item, column);
	},
	isClosedOrPassed: function () {
		var item = this.props.item;

		if (item.isAppointment) {
			return moment().isAfter(item.endDate);
		} else {
			return !!item.closeDate;
		}
	},
	getInitialState: function () {
		return {
			editUserOpen: false
		};
	},
	toggleEditUserOpen: function () {
		this.setState(function (state) {
			return {
				editUserOpen: !state.editUserOpen
			};
		});
	},
	componentDidUpdate: function (prevProps, prevState) {
		var item = this.props.item;

		if (this.state.editUserOpen && !prevState.editUserOpen) {
			$('#pb-role-input-' + item.id).select2('open');
		}
	},
	updateUser: function (users) {
		var props = this.props;
		var updateUser = props.actions.updateUser;
		var item = props.item;

		updateUser(item, users);
	},
	getSingleUserSelect: function (item) {
		var self = this;
		var props = this.props;
		var state = this.state;

		var fromSelect = false;

		/*
			Why not only have onClose? onClose was run before onChange and issued a new
			render before onChange could update the props. And in that render componentDidUpdate was
			called in the user select and overwrote the newly selected value with the old props.
		*/
		var eventListeners = {
			'select2-selecting': function () {
				fromSelect = true;
				self.toggleEditUserOpen();
			},
			'select2-close': function () {
				if (fromSelect) {
					fromSelect = false;
				} else {
					self.toggleEditUserOpen();
				}
			}
		};

		return (
			<div key={item.id + '-user-select'} style={{ display: state.editUserOpen ? 'block' : 'none' }}>
				<ReactTemplates.INPUTS.upUsers
					id={'pb-role-input-' + item.id}
					key={item.id + '-user-select'}
					data={props.assignableUsers}
					value={item.users[0]}
					required={true}
					placeholder={'lang.selectUser'}
					onChange={this.updateUser}
					eventListeners={eventListeners}
				/>
			</div>
		);
	},
	getSingleUserRow: function (item) {
		var state = this.state;
		var lang = this.lang;

		var user = item.users[0];
		var activeUsers = window.Tools.AppService.getActiveUsers();
		var active = _.find(activeUsers, { id: user.id }) !== undefined;

		var wrapClasses = 'pb-name-icon-wrap' + (active ? '' : ' pb-name-icon-wrap-inactive');

		return (
			<div
				className={wrapClasses}
				onClick={this.toggleEditUserOpen}
				key={item.id + '-user-column'}
				style={{ display: !state.editUserOpen ? 'block' : 'none' }}
			>
				<ReactTemplates.TOOLS.avatar
					key={user.id}
					user={user}
					size={25}
					style={{ marginTop: 0 }}
					className={active ? '' : 'pb-inactive-user'}
				/>
				<div className="pb-column-user-name">{user.name}</div>
				{!active ? <div className="pb-inactive-text">{lang.inactive}</div> : null}
				<i style={{ top: 2, verticalAlign: 'top' }} className="fa fa-sort-desc" />
			</div>
		);
	},
	getMultiUserSelect: function (item) {
		var self = this;
		var props = this.props;
		var state = this.state;

		var timeoutVariable;

		var eventListeners = {
			'select2-blur': function () {
				timeoutVariable = setTimeout(function () {
					self.toggleEditUserOpen();
				}, 200);
			},
			'select2-selecting': function () {
				clearTimeout(timeoutVariable);
			},
			'select2-opening': function () {
				clearTimeout(timeoutVariable);
			},
			'select2-focus': function () {
				clearTimeout(timeoutVariable);
			}
		};

		return (
			<div
				className="pb-multiple-user-column-selectwrap"
				key={item.id + '-user-select'}
				style={{ display: state.editUserOpen ? 'block' : 'none' }}
			>
				<ReactTemplates.INPUTS.upUsers
					id={'pb-role-input-' + item.id}
					key={item.id + '-user-select'}
					data={props.assignableUsers}
					value={item.users}
					required={true}
					multiple={true}
					onChange={this.updateUser}
					onBlur={this.toggleEditUserOpen}
					eventListeners={eventListeners}
				/>
			</div>
		);
	},
	getMultiUserRow: function (item) {
		var state = this.state;
		var lang = this.lang;

		var activeUsers = window.Tools.AppService.getActiveUsers();

		return (
			<div
				onClick={this.toggleEditUserOpen}
				key={item.id + '-user-column'}
				style={{ display: !state.editUserOpen ? 'block' : 'none' }}
			>
				<div className="pb-multiple-user-column-userwrap">
					{_.map(item.users, function (user) {
						var active = _.find(activeUsers, { id: user.id }) !== undefined;

						return (
							<ReactTemplates.TOOLS.avatar
								user={user}
								size={25}
								key={user.id}
								style={{ marginTop: 0 }}
								className={active ? '' : 'pb-inactive-user'}
							/>
						);
					})}
				</div>
				{item.users.length === 0 ? lang.chooseUser : null}
				<i style={{ top: -1 }} className="fa fa-sort-desc" />
			</div>
		);
	},
	getUserColumn: function (item) {
		var select = item.isAppointment ? this.getMultiUserSelect(item) : this.getSingleUserSelect(item);
		var row = item.users.length === 1 ? this.getSingleUserRow(item) : this.getMultiUserRow(item);

		var content = [select, row];
		var className = item.users.length === 1 ? ' pb-single-user-column' : ' pb-multiple-user-column';

		return (
			<td
				data-test-id="processedby-modal-user-column"
				className={'pb-table-column pb-column-user pb-column-clickable' + className}
				style={{ cursor: 'pointer' }}
				colSpan={item.isAppointment || this.isClosedOrPassed(item) ? 2 : null}
			>
				{content}
			</td>
		);
	},
	getCloseColumn: function (item) {
		if (item.isAppointment || this.isClosedOrPassed(item)) {
			return null;
		}

		return ReactTemplates.TOOLS.withTooltip(
			<td
				data-test-id="processedby-modal-close-column"
				onClick={this.closeActivity}
				className={'pb-table-column pb-column-clickable pb-column-tools'}
				key={'close-column'}
			>
				<div className="pb-tools-div">
					<i className="fa fa-check-square-o" />
				</div>
			</td>,
			this.lang.closeActivity,
			{ placement: 'right' }
		);
	},
	getDescriptionColumn: function (item) {
		var dateString;

		if (item.isAppointment) {
			dateString = moment(item.date).format('L LT') + ' - ' + moment(item.endDate).format('LT');
		}
		if (item.time) {
			dateString = moment(item.date).format('L LT');
		} else {
			dateString = moment(item.date).format('L');
		}

		return (
			<td
				data-test-id="processedby-modal-desciption-column"
				className="pb-table-column pb-column-clickable"
				onClick={this.onActivityClick}
				key={'description-column-' + item.id}
			>
				<div className="pb-column-info-description">{item.description}</div>
				<div className="pb-column-info-date">{dateString}</div>
			</td>
		);
	},
	getIconColumn: function (item) {
		return (
			<td data-test-id="processedby-modal-icon-column" className="pb-column-icon" key={'icon-column-' + item.id}>
				{item.isAppointment ? <i className="fa fa-calendar" /> : <i className="up-icon-activity" />}
			</td>
		);
	},
	render: function () {
		var props = this.props;
		var item = props.item;

		return (
			<tr
				data-test-id="processedby-modal-activity-row"
				key={item.id}
				className={this.getClasses('pb-table-row', 'pb-row-closed', this.isClosedOrPassed())}
			>
				{this.getIconColumn(item)}
				{this.getDescriptionColumn(item)}
				{this.getUserColumn(item)}
				{this.getCloseColumn(item)}
			</tr>
		);
	}
});

ReactTemplates.processedBy.activityList = window.ReactCreateClass({
	getRows: function (items) {
		var props = this.props;

		return _.map(items, function (item) {
			return (
				<ReactTemplates.processedBy.activityRow
					item={item}
					key={item.id}
					actions={props.actions}
					Views={props.Views}
				/>
			);
		});
	},
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;

		this.lang = {
			type: t('default.type'),
			user: t('default.user')
		};
	},
	render: function () {
		var lang = this.lang;
		var props = this.props;

		var rows = this.getRows(props.items);
		var tableClassNames = 'pb-table' + (props.className ? ' ' + props.className : '');

		return (
			<div className="pb-activity-list-section">
				{props.title ? <div className="pb-activity-list-heading">{props.title}</div> : null}
				<table className={tableClassNames}>
					<thead>
						<tr>
							<th className="pb-table-column pb-header-column">{lang.type}</th>
							<th className="pb-table-column pb-header-column" />
							<th className="pb-table-column pb-header-column">{lang.user}</th>
							<th className="pb-table-column pb-header-column pb-column-tools" />
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</table>
			</div>
		);
	}
});

ReactTemplates.processedBy.activities = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;

		this.lang = {
			planedActivitiesAndAppointments: t('processedBy.planedActivitiesAndAppointments'),
			assignUser: t('processedBy.AssignUser'),
			delayedActivities: t('processedBy.delayedActivities'),
			delayedActivity: t('processedBy.delayedActivity'),
			noActiveActivities: t('processedBy.noActiveActivities'),
			noActivitiesDescription: t('processedBy.noActivitiesDescription')
		};

		this.hasEventListener = false;
		this.stickyHeader = _.throttle(this.stickyHeader, 50);
	},
	goToAssign: function () {
		var props = this.props;
		var changeView = props.actions.changeView;

		changeView(props.Views.ASSIGN);
	},
	addEventListener: function () {
		if (!this.props.activitiesLoading && !this.hasEventListener && this._activityList) {
			this._activityList.addEventListener('scroll', this.stickyHeader);
			this.hasEventListener = true;
		}
	},
	componentDidUpdate: function () {
		this.addEventListener();
	},
	componentDidMount: function () {
		this.addEventListener();
	},
	componentWillUnmount: function () {
		this._activityList.removeEventListener('scroll', this.stickyHeader);
	},
	stickyHeader: function (e) {
		if (this.props.hasInTime) {
			var element = document.querySelector('.pb-header');
			if (!element) {
				return;
			}
			var classList = element.classList;

			if (e.target.scrollTop > 0) {
				classList.add('content-scrolled');
			} else {
				classList.remove('content-scrolled');
			}
		} else {
			var onlyDelayedHeight = document.querySelector('.pb-only-delayed-activities').clientHeight;

			if (e.target.scrollTop > onlyDelayedHeight - 50) {
				document.querySelector('.pb-header').classList.remove('pb-header-hide');
			} else {
				document.querySelector('.pb-header').classList.add('pb-header-hide');
			}
		}
	},
	render: function () {
		var self = this;
		var lang = this.lang;
		var props = this.props;

		var separatedItems = _.reduce(
			props.activitiesAndAppointments,
			function (res, item) {
				if (!item.isAppointment) {
					var date = moment(item.date);

					if (!item.time) {
						date.hours(23).minutes(59);
					}
					if (moment().isAfter(date)) {
						res.delayed.push(item);
					} else {
						res.inTime.push(item);
					}
				} else {
					res.inTime.push(item);
				}
				return res;
			},
			{
				delayed: [],
				inTime: []
			}
		);

		var delayedSection = null;

		if (separatedItems.delayed.length) {
			var title = separatedItems.delayed.length === 1 ? lang.delayedActivity : lang.delayedActivities;

			delayedSection = (
				<ReactTemplates.processedBy.activityList
					items={separatedItems.delayed}
					className={'pb-activity-list-inactive'}
					actions={props.actions}
					editItem={props.editItem}
					Views={props.Views}
					title={separatedItems.delayed.length + ' ' + title}
				/>
			);
		}

		var noActiveSection = null;
		var inTimeSection = null;

		if (separatedItems.inTime.length) {
			inTimeSection = (
				<ReactTemplates.processedBy.activityList
					items={separatedItems.inTime}
					actions={props.actions}
					editItem={props.editItem}
					Views={props.Views}
					title={null}
				/>
			);
		} else {
			noActiveSection = (
				<div className="pb-only-delayed-activities">
					<div>{lang.noActiveActivities}</div>
					<div>{lang.noActivitiesDescription}</div>
					<div>
						<button className="btn up-btn btn-bright-blue no-shadow" onClick={this.goToAssign}>
							{lang.assignUser}
						</button>
					</div>
				</div>
			);
		}

		return (
			<div
				className="pb-activity-list"
				ref={function (r) {
					self._activityList = r;
				}}
			>
				{noActiveSection}
				{inTimeSection}
				{delayedSection}
			</div>
		);
	}
});

ReactTemplates.processedBy.root = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;
		var props = this.props;

		this.lang = {
			accountManagers: t('default.accountManagers'),
			accountManager: t('default.accountManager'),
			noAccountManager: t('default.noAccountManager'),
			inactiveUser: t('default.inactiveUser'),
			planedActivitiesAndAppointments: t('processedBy.planedActivitiesAndAppointments'),
			delayedActivities: t('processedBy.delayedActivities'),
			delayedActivity: t('processedBy.delayedActivity'),
			assignUser: t('processedBy.AssignUser')
		};

		this.assignUserActions = _.pick(props.actions, ['updateSettings', 'regretAssign', 'assign', 'close']);

		this.activityListActions = _.pick(props.actions, [
			'changeView',
			'openAppointment',
			'openActivity',
			'closeActivity',
			'updateUser',
			'close'
		]);

		this.closeActivityActions = _.pick(props.actions, ['close', 'closeActivity', 'changeView', 'close']);

		this.assignedActions = _.pick(props.actions, ['close']);

		this.scrollbarWidth = window.Tools.browserService.scrollbarWidth;
	},
	hasInTime: function () {
		var props = this.props;

		return _.some(props.activitiesAndAppointments, function (item) {
			if (item.isAppointment) {
				return true;
			}
			var date = moment(item.date);

			if (!item.time) {
				date.hours(23).minutes(59);
			}
			if (date.isAfter(moment())) {
				return true;
			}
			return false;
		});
	},
	nrOverDue: function () {
		var props = this.props;

		return _.filter(props.activitiesAndAppointments, function (item) {
			if (item.isAppointment) {
				return false;
			}
			var date = moment(item.date);

			if (!item.time) {
				date.hours(23).minutes(59);
			}
			if (moment().isAfter(date)) {
				return true;
			}
			return false;
		}).length;
	},
	getUserRows: function (client) {
		var activeUsers = window.Tools.AppService.getActiveUsers();
		var self = this;

		return _.map(client.users, function (user) {
			var active = _.find(activeUsers, { id: user.id }) !== undefined;

			if (active) {
				return (
					<div key={user.id} className="pb-account-user">
						<ReactTemplates.TOOLS.avatar user={user} size={26} style={{ marginTop: '0px' }} />
						<span className="pb-account-user-name">{user.name}</span>
					</div>
				);
			} else {
				return ReactTemplates.TOOLS.withTooltip(
					<div key={user.id} className="pb-account-user pb-account-user-inactive">
						<ReactTemplates.TOOLS.avatar
							user={user}
							size={26}
							style={{ marginTop: '0px' }}
							className="pb-inactive-user"
						/>
						<span className="pb-account-user-name">{user.name}</span>
					</div>,
					self.lang.inactiveUser,
					{ placement: 'left' }
				);
			}
		});
	},
	goToAssign: function () {
		var props = this.props;
		var changeView = props.actions.changeView;

		changeView(props.Views.ASSIGN);
	},
	goToClient: function () {
		window.Tools.$state.go('account.dashboard', { id: this.props.client.id });
	},
	render: function () {
		var props = this.props,
			lang = this.lang,
			center = null,
			header = null;
		var client = props.client;
		var hasInTime = this.hasInTime();

		var classNames = 'up-modal-content has-sidebar' + (props.clientLoading ? ' client-loading' : '');
		classNames = classNames + (hasInTime ? '' : ' pb-only-overdue');

		var userRows = this.getUserRows(client);

		var sidebar = (
			<div className="pb-sidebar-content">
				<div className="pb-account-name" onClick={this.goToClient}>
					{client.name}
				</div>
				<div className="pb-account-history">
					<ReactTemplates.TOOLS.leadStatus
						theKey={client.id}
						activity={client.hasActivity}
						activityOld={client.hadActivity}
						appointment={client.hasAppointment}
						appointmentOld={client.hadAppointment}
						opportunity={client.hasOpportunity}
						opportunityOld={client.hadOpportunity}
						order={client.hasOrder}
						orderOld={client.hadOrder}
					/>

					<ReactTemplates.TOOLS.eventTypes theKey={client.id} account={client} />
				</div>
				<div className="pb-account-users">
					<div className="pb-account-users-title">
						{props.teamAccountManager ? lang.accountManagers : lang.accountManager}
					</div>
					{userRows.length ? userRows : lang.noAccountManager}
				</div>
			</div>
		);

		switch (props.view) {
			case props.Views.ACTIVITIES:
				if (props.activitiesLoading) {
					center = <ReactTemplates.loader />;
				} else {
					center = (
						<ReactTemplates.processedBy.activities
							activitiesAndAppointments={props.activitiesAndAppointments}
							actions={this.activityListActions}
							editItem={props.editItem}
							Views={props.Views}
							activitiesLoading={props.activitiesLoading}
							hasInTime={hasInTime}
						/>
					);
				}

				var headerClassNames = hasInTime ? 'pb-header' : 'pb-header pb-header-hide';
				var nrOverDue = this.nrOverDue();
				var title;

				if (hasInTime) {
					title = this.lang.planedActivitiesAndAppointments;
				} else if (nrOverDue === 1) {
					title = nrOverDue + ' ' + this.lang.delayedActivity;
				} else {
					title = nrOverDue + ' ' + this.lang.delayedActivities;
				}

				header = (
					<div className={headerClassNames} style={{ right: this.scrollbarWidth }}>
						<div className="pb-header-heading">{title}</div>
						{!props.activitiesLoading ? (
							<button
								className="btn up-btn btn-bright-blue no-shadow pb-activity-list-assign-button"
								onClick={this.goToAssign}
							>
								{lang.assignUser}
							</button>
						) : null}
						<a className="btn btn-link btn-link--gray modal-close" onClick={props.actions.close}>
							<i className="fa fa-times" />
						</a>
					</div>
				);

				break;
			case props.Views.ASSIGN:
				center = (
					<ReactTemplates.processedBy.assign
						client={client}
						clientLoading={props.clientLoading}
						assignableUsers={props.assignableUsers}
						settings={props.settings}
						editItem={props.editItem}
						view={props.view}
						Views={props.Views}
						actions={this.assignUserActions}
						teamAccountManager={props.teamAccountManager}
					/>
				);
				header = (
					<a className="btn btn-link btn-link--gray modal-close" onClick={props.actions.close}>
						<i className="fa fa-times" />
					</a>
				);
				break;
			case props.Views.CLOSEACTIVITY:
				center = (
					<ReactTemplates.processedBy.closeactivity
						actions={this.closeActivityActions}
						editItem={props.editItem}
						Views={props.Views}
					/>
				);
				header = (
					<a className="btn btn-link btn-link--gray modal-close" onClick={props.actions.close}>
						<i className="fa fa-times" />
					</a>
				);
				break;
			case props.Views.ASSIGNED:
				center = <ReactTemplates.processedBy.assigned actions={this.assignedActions} client={client} />;
				header = (
					<a className="btn btn-link btn-link--gray modal-close" onClick={props.actions.close}>
						<i className="fa fa-times" />
					</a>
				);
				break;
		}

		return (
			<div className={classNames}>
				<div className="modal-sidebar">{sidebar}</div>
				{header}
				<div className="modal-center">{center}</div>
			</div>
		);
	}
});

ReactTemplates.processedByColumn = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			title: t('processedby-title'),
			assign: t('default.assign'),
			assignShort: t('default.assign'),
			assignToUser: t('default.assignToUser'),
			updateUser: t('default.updateUser'),
			noactivities: t('processedbyNoActivities'),
			chooseUser: t('assign.chooseSeller'),
			activitiesAndAppointments: t('default.activitiesAndAppointments'),
			users: t('default.user'),
			handle: t('default.handle'),
			closeActivity: t('default.closeActivity'),
			addNotes: t('default.addNotes'),
			descriptionToSeller: t('assign.descriptionToSeller')
		};
	},
	openProcessedByModal: function (e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		var props = this.props;

		if (!props.disabled) {
			var item = props.item;
			var contactId = null;

			if (item.contactId && props.options?.showContact) {
				contactId = item.contactId;
			}
			if (item.contact && item.contact.id) {
				contactId = item.contact.id;
			}

			openAssignModal({
				client: this.props.client,
				contactId: contactId,
				from: 'listLeads',
				formFields: item.fieldValues,
				formId: item.formId
			});
		}
	},
	openActivity: function (e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		Tools.$upModal.open('editActivity', { id: this.props.client.processedBy.id });
	},
	openAppointment: function (e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		var customerId = Tools.AppService.getCustomerId();

		openEditAppointment({ customerId: customerId, id: this.props.item.client.processedBy.id });
	},
	render: function () {
		var props = this.props;
		const { client, item } = props;
		var tools = Tools;

		if (!client) {
			return ReactTemplates.TOOLS.emptyTD('', this.props.theKey);
		}

		var user = null;
		if (client.processedBy) {
			user = client.processedBy.user;
		}

		if (user) {
			var classes = !client.processedBy ? 'assigned-info no-date' : 'assigned-info';
			var haveInactiveUser = !user.active;
			var processedByElement = null;

			if (client.processedBy) {
				var isOverDue = false;

				if (client.processedBy.entityType === 'Activity') {
					var date = moment(client.processedBy.date);

					if (!client.processedBy.time) {
						date.hours(23).minutes(59);
					} else {
						var timeParts = client.processedBy.time.split(':');
						date.hours(timeParts[0]).minutes(timeParts[1]);
					}

					if (moment().isAfter(date)) {
						isOverDue = true;
					}
				}

				processedByElement = (
					<div className={isOverDue && !haveInactiveUser ? 'text-red' : ''}>
						<i className="up-icon-activity" /> {moment(client.processedBy.date).format('L')}
					</div>
				);
			}

			return (
				<td
					key={this.props.theKey}
					data-test-id="processedby-column"
					onClick={this.openProcessedByModal}
					className={
						(haveInactiveUser ? 'column-assigned inactive-user' : 'column-assigned') +
						' ' +
						(this.props.className || '')
					}
				>
					<div className="assigned-wrap">
						{haveInactiveUser ? (
							<div className="inactive-user-icon-wrap">
								<i className="fa fa-user-times" aria-hidden="true" />
							</div>
						) : (
							<div>
								<ReactTemplates.TOOLS.avatar
									key={user.id}
									user={user}
									size={20}
									avatarService={tools.avatarService}
									style={{ marginTop: '2px' }}
								/>
							</div>
						)}
						<div className={classes}>
							<div className="user-name">{user.name}</div>
							{processedByElement}
						</div>
					</div>
				</td>
			);
		}

		const assignBtn = (
			<ProcessedBy
				formFields={item.fieldValues}
				formId={item.formId}
				client={this.props.item.client || client}
				disabled={!!props.disabled}
			/>
		);

		return (
			<td
				key={this.props.theKey}
				data-test-id="processedby-column"
				className={'column-assigned' + ' ' + (this.props.className || '')}
				onClick={this.openProcessedByModal}
			>
				{assignBtn}
			</td>
		);
	}
});
