'use strict';
import pdfTemplateResource from 'App/babel/resources/pdfTemplates';
import logError from 'App/babel/helpers/logError';
import { validateSender } from 'App/babel/store/actions/helpers/sharedMailActions';
import openModal from 'App/services/Modal';
import T from 'Components/Helpers/translate';
import colorMappings from '@upsales/components/Utils/colorMappings';

angular.module('domain.admin').controller('SendEmailAction', [
	'$scope',
	'$modalParams',
	'$translate',
	'EditorCk',
	'AppService',
	'$safeApply',
	'MailAccount',
	function ($scope, $modalParams, $translate, EditorCk, AppService, $safeApply, MailAccount) {
		$scope.save = function () {
			const doSave = () => {
				$scope.saving = true;
				if ($scope.properties.FromAddress) {
					$scope.properties.FromAddress = $scope.properties.FromAddress.email;
				}
				$scope.properties.ToAddresses = $scope.properties.ToAddresses.join('; ');
				$scope.properties.HTMLContentType = 'true';
				if ($scope.selectedTemplate) {
					$scope.properties.Document = Number.isInteger($scope.selectedTemplate.id)
						? $scope.selectedTemplate.id
						: $scope.selectedTemplate.templateId;
					$scope.properties.TemplateLanguage = $scope.language ? $scope.language : '';
					$scope.properties.TemplateCountryRegion = $scope.countryRegion ? $scope.countryRegion.locale : '';
					$scope.properties.TemplateType = $scope.templateType ? $scope.templateType : '';
					$scope.properties.TemplateUuid = Number.isInteger($scope.selectedTemplate.id)
						? ''
						: $scope.selectedTemplate.id;
				} else {
					delete $scope.properties.Document;
				}

				// map properties
				var properties = [];
				angular.forEach($scope.properties, function (val, key) {
					var obj = {
						name: key,
						value: val
					};

					properties.push(obj);
				});

				$scope.resolve(properties);
			};

			// Check for tags that start or end with only one curly brace
			const tagPattern = /([^{]\{\w+\.\w+\}\})|(\{\{\w+\.\w+\}(?!\}))/g;
			const matches = $scope.properties.Body.match(tagPattern);

			if (matches?.length) {
				openModal('Alert', {
					title: T('alerts.invalidTagsDetectedTitle'),
					body: T('alerts.invalidTagsDetectedBody', { tags: matches.join('\n') }),
					confirmButtonText: T('alerts.saveAnyways'),
					headerIcon: 'warning',
					onClose: confirmed => {
						if (confirmed) {
							doSave();
						}
					}
				});
			} else {
				doSave();
			}
		};

		const displayTemplate = function (template) {
			return _.escape(template.name);
		};

		const displayEmail = function (user) {
			return _.escape(user.email);
		};

		const createSelect = function (templates) {
			if ($scope.properties.Document) {
				const selectedTemplate = $scope.documentTemplates.find(template => {
					if ($scope.properties.TemplateUuid) {
						return template.templateId === parseInt($scope.properties.Document);
					}
					return template.id === parseInt($scope.properties.Document);
				});
				$scope.properties.Document = selectedTemplate;
				$scope.selectedTemplate = $scope.properties.Document;
				const isPdfTemplate = selectedTemplate.languages ? true : false;
				if (isPdfTemplate) {
					$scope.showLanguageOptions = true;
					$scope.languageOptions = selectedTemplate.languages;
					$scope.templateType = selectedTemplate.templateType;
					const language = $scope.languageOptions.find(
						language => language.id === $scope.properties.TemplateLanguage
					);
					const countryCodes = Tools.CountryCodes.locales();
					const countryRegion = countryCodes.find(
						language => language.locale === $scope.properties.TemplateCountryRegion
					);
					$scope.countryRegion = countryRegion;
					$scope.language = language ? language.id : null;
					if (countryRegion) {
						$scope.changeNumberDateFormats(countryRegion);
					}
				}
			}
			$scope.allTemplates = {
				data: templates,
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				formatSelection: displayTemplate,
				formatResult: displayTemplate,
				allowClear: 1
			};

			$scope.allEmails = {
				data: function () {
					return { results: $scope.emails };
				},
				matcher: function (term, text, op) {
					return op.email.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				formatSelection: displayEmail,
				formatResult: displayEmail,
				allowClear: 1
			};
		};

		var init = function () {
			//PDF templates properties
			$scope.selectedTemplate = null;
			$scope.showLanguageOptions = false;
			$scope.languageOptions = [];
			$scope.language = null;
			$scope.countryRegion = null;
			$scope.exampleNumberFormat = null;
			$scope.exampleDateTimeFormat = null;
			$scope.templateType = null;
			const allTemplates = [];
			var prefilledFromAddress;

			// parse properties
			$scope.properties = {};
			$scope.isEdit = !!$modalParams.properties;
			$scope.removeAction = $modalParams.removeAction;
			$scope.actionIndex = $modalParams.actionIndex;
			$scope.timeoutVariable = false;
			$scope.isTrigger = $modalParams.isTrigger;
			$scope.tagEntity = $modalParams.tagEntity;
			$scope.emails = [{ id: '-1', email: 'no-reply@upsales.com' }];
			$scope.properties.FromAddress = $scope.emails[0];

			//Set loggedIn users locale as default country/region
			const userLocale = Tools.AppService.getSelf().userParams.locale;
			const countryCodes = Tools.CountryCodes.locales();
			const countryRegion = countryCodes.find(language => language.locale === userLocale);
			$scope.loggedinUserRegion = countryRegion;

			if ($scope.isTrigger && $scope.tagEntity) {
				$scope.documentTemplates = AppService.getDocumentTemplates('client');
				var documentEntity = $scope.tagEntity;
				documentEntity = documentEntity === 'appointment' ? 'activity' : documentEntity;
				if (documentEntity === 'activity' || documentEntity === 'order' || documentEntity === 'agreement') {
					var templates = AppService.getDocumentTemplates(documentEntity);
					if (templates.length) {
						$scope.documentTemplates = templates.concat($scope.documentTemplates);
					}
				}
				$scope.documentTemplates = _.map($scope.documentTemplates, function (template) {
					var langTag = 'default.' + template.type;
					if (template.type === 'client') {
						langTag = 'feature.companiesAndContacts';
					} else if (template.type === 'activity') {
						langTag = 'default.activityAndAppointment';
					}
					template.group = $translate.instant(langTag);
					return template;
				});

				allTemplates.push({ name: Tools.$translate('column.userId'), children: $scope.documentTemplates });

				if ($scope.tagEntity === 'order') {
					pdfTemplateResource
						.find()
						.then(response => {
							$scope.newDocumentTemplates = response.data;
							const PdfTemplates = [];
							_.forEach(response.data, function (template) {
								if (template.active === true) {
									PdfTemplates.push({
										id: template.templateData.id,
										templateId: template.id,
										name: template.templateData.template_name,
										roles: template.roles,
										group: Tools.$translate('admin.customTemplates'),
										type: 'order',
										templateType: template.type,
										languages: template.templateData.template_details.language || null
									});
								}
							});
							allTemplates.push({
								name: Tools.$translate('admin.customTemplates'),
								children: PdfTemplates
							});
							$scope.documentTemplates = $scope.documentTemplates.concat(PdfTemplates);
							createSelect(allTemplates);
						})
						.catch(function (err) {
							logError(err, 'Could not load pdfTemplates');
						});
				} else {
					createSelect(allTemplates);
				}

				const mailAccountPromise = MailAccount.get();
				Promise.all([
					mailAccountPromise,
					// This is so freaking slow when using sendgrid so I make sure we only do it if we are going to send with Halon
					mailAccountPromise.then(({ data: mailAccount }) =>
						mailAccount.useHalon ? MailAccount.domains() : { data: [] }
					)
				])
					.then(([{ data: mailAccount }, { data: domains }]) => {
						const users = AppService.getActiveUsers();
						const mappedDomains = domains.reduce(
							(domainMap, { domain, valid }) => ((domainMap[domain] = valid), domainMap),
							{}
						);
						users.forEach(user => {
							if (user.email && validateSender(mailAccount, mappedDomains, user.email).valid) {
								$scope.emails.push(user);
								if (prefilledFromAddress && prefilledFromAddress === user.email) {
									$scope.properties.FromAddress = user;
								}
							}
						});
						if (!$scope.properties.FromAddress) {
							$scope.properties.FromAddress = { id: '-1', email: 'no-reply@upsales.com' };
						}
					})
					.catch(function (err) {
						logError(err, 'Could not load email addresses');
					});
			}

			setTimeout(function () {
				$scope.timeoutVariable = true;
			}, 500);

			if ($modalParams.prefill) {
				if ($modalParams.prefill.subject) {
					$scope.properties.Subject = $modalParams.prefill.subject;
				}
				if ($modalParams.prefill.from) {
					$scope.properties.from = $modalParams.prefill.from;
				}
				if ($modalParams.prefill.to) {
					$scope.properties.to = $modalParams.prefill.to;
				}
				if ($modalParams.prefill.body) {
					$scope.properties.Body = $modalParams.prefill.body;
				}
			}
			angular.forEach($modalParams.properties, function (prop) {
				if (prop.name === 'FromAddress') {
					prefilledFromAddress = prop.value;
					$scope.properties.FromAddress = { id: -1, email: prop.value }; // Placeholder until it actually loads
				} else {
					$scope.properties[prop.name] = prop.value;
				}
			});

			if ($scope.properties.Body && typeof $scope.properties.Body === 'string') {
				$scope.properties.Body = $scope.properties.Body.replace(/\r?\n/g, '<br />');
				$scope.properties.Body = $scope.properties.Body.split('<br /><br />').join('<br />'); // Lucas style
				$scope.properties.Body = $scope.properties.Body.split('<br />		').join(''); // Lucas style 2.0
				$scope.properties.Body = $scope.properties.Body.split('<br />	').join(''); // Lucas style 2.1
				$scope.properties.Body = $scope.properties.Body.replace('<br />&nbsp;<br />', '<br />'); // etc
			}

			if ($scope.properties.ToAddresses && typeof $scope.properties.ToAddresses === 'string') {
				$scope.properties.ToAddresses = $scope.properties.ToAddresses.split(';');
			}

			var ckEditorOptions = {
				height: '400px',
				enterMode: CKEDITOR.ENTER_BR,
				fileEntity: 'mail',
				shiftEnterMode: CKEDITOR.ENTER_BR
			};

			if ($scope.tagEntity) {
				ckEditorOptions.tagEntity = $scope.tagEntity;
			}

			if (($modalParams.extraTags?.length ?? 0) > 0 && $modalParams.entity === 'form') {
				ckEditorOptions.extraTagGroups = {
					title: T('form.form'),
					group: 'FormValues',
					sort: 7,
					tags: $modalParams.extraTags.map(tag => ({
						name: tag.text,
						value: tag.value,
						insertTag: () => {
							const htmlObject = document.createElement('div');
							htmlObject.innerHTML = $scope.properties.Body;
							const table = htmlObject.querySelector('[field-table-exists-for-real=true]');
							if (table) {
								const length = table.rows.length;
								const backgroundColor =
									length % 4 === 0 ? colorMappings.get('white') : colorMappings.get('grey-2');
								const textRow = table.insertRow(length - 1);
								textRow.innerHTML = `<td style="font-family:arial;size:13px;padding:4px;background-color:${backgroundColor}"><font face="arial" size="2"><b>${tag.text}</b></font></td>`;
								const row = table.insertRow(length);
								row.innerHTML = `<td style="font-family:arial;size:13px;padding:4px;background-color:${backgroundColor}"><font face="arial" size="2">${tag.value}</font></td>`;
								$scope.properties.Body = htmlObject.innerHTML;
								$safeApply($scope);
								return true;
							}
							return false;
						}
					}))
				};
			}

			$scope.editorOptions = EditorCk.getOptions(ckEditorOptions);
		};

		$scope.selectTemplate = function () {
			if ($scope.properties.Document) {
				const selectedTemplate = $scope.documentTemplates.find(
					template => template.id === $scope.properties.Document.id
				);
				$scope.selectedTemplate = selectedTemplate;
				$scope.showLanguageOptions = selectedTemplate.templateId ? true : false;
				$scope.templateType = $scope.showLanguageOptions ? selectedTemplate.templateType : null;
				$scope.languageOptions = $scope.showLanguageOptions ? selectedTemplate.languages : [];
				$scope.language =
					selectedTemplate.languages && selectedTemplate.languages.length > 0
						? selectedTemplate.languages[0].id
						: null;
				$scope.countryRegion = $scope.loggedinUserRegion;
				$scope.changeNumberDateFormats($scope.countryRegion);
			} else {
				$scope.selectedTemplate = null;
				$scope.showLanguageOptions = false;
				$scope.templateType = null;
			}
		};

		$scope.languageChange = function (language) {
			$scope.language = language.id;
		};

		$scope.contryRegionChange = function (countryRegion) {
			$scope.countryRegion = countryRegion;
			$scope.changeNumberDateFormats(countryRegion);
		};

		$scope.changeNumberDateFormats = function (selectedLocale) {
			const exampleDateTimeFormat = moment().locale(selectedLocale.locale);
			$scope.exampleDateTimeFormat = exampleDateTimeFormat.format('L LT');
			const currencyOptions = {
				style: 'currency',
				currency: 'SEK',
				currencyDisplay: 'code'
			};

			$scope.exampleNumberFormat = new Intl.NumberFormat(selectedLocale.locale, currencyOptions).format(12345.99);
			$safeApply($scope);
		};

		init();
	}
]);
