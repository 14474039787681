import { PromoButton } from 'App/components/EditSubscription/SubscriptionPromo/SubscriptionPromo';
import { DropDownMenu, Icon, Text, Toggle } from '@upsales/components';
import { ClientIdNamePriceList } from 'App/resources/Model/Client';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import { PrimaryButton } from '@upsales/components/Buttons';
import { ContactIdName } from 'App/resources/Model/Contact';
import { useMetadata } from 'App/components/hooks/appHooks';
import { useSoftDeployAccess } from 'App/components/hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import { OldOrNewAgreement } from '../SubscriptionCards';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import moment from 'moment';
import React from 'react';

import './CardsHeader.scss';

type Props = {
	showInactive: boolean;
	setShowInactive: (value: boolean | ((prevValue: boolean) => boolean)) => void;
	client?: ClientIdNamePriceList;
	contact?: ContactIdName;
	agreementGroups: OldOrNewAgreement[];
	hasMRR: boolean;
	groupCompanyToggle?: React.ReactNode;
};

const createSubscription = (client?: ClientIdNamePriceList, contact?: ContactIdName) => {
	if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
		const options = { accountId: client?.id, contact, customerId: Tools.AppService.getCustomerId() };
		return Tools.$upModal.open('editAgreement', options);
	}

	const dontWait = Tools.FeatureHelper.hasSoftDeployAccess('DONT_WAIT_SUBSCRIPTIONS');
	openModal('CreateSubscription', { client, contact, dontWait, createdFrom: 'accountSalesAddButton' });
};

const createOrder = (clientId?: number, contact?: ContactIdName) => {
	Tools.$upModal.open('editOrder', { clientId, contactId: contact?.id });
};

const CardsHeader = ({
	showInactive,
	setShowInactive,
	client,
	contact,
	agreementGroups,
	hasMRR,
	groupCompanyToggle
}: Props) => {
	const classes = new BemClass('CardsHeader');
	const hasSplitButtonClientCard = useSoftDeployAccess('SPLIT_BUTTON_CLIENT_CARD');
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubAccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubAccounts;
	const { customerCurrencies } = useMetadata() ?? { customerCurrencies: [] };
	const masterCurrency = customerCurrencies.find(currency => currency.masterCurrency);

	const totalSum = agreementGroups.reduce((sum, current) => {
		const agreement = current.currentAgreement;
		const currentEndDate = agreement?.metadata.agreementEnddate;
		const startDate = agreement?.metadata.agreementStartdate;
		const isInactive = (!!currentEndDate && moment(currentEndDate).isBefore()) || moment(startDate).isAfter();

		if (!agreement || isInactive) return sum;

		const valueInMasterCurrency = (agreement.yearlyValueInMasterCurrency ?? 0) / (hasMRR ? 12 : 1);
		return sum + valueInMasterCurrency;
	}, 0);

	const showToggle = agreementGroups.length > 0;
	const currencyFormat = new CurrencyFormat(masterCurrency?.iso, undefined, 0);
	const value = `${currencyFormat.short(totalSum)} (${hasMRR ? 'MRR' : 'ARR'})`;

	return (
		<div className={classes.b()}>
			<div className={classes.elem('leftSide').b()}>
				<Text size="xl" bold>
					{T('default.agreement')}
					<Text color="grey-10" size="xl">
						{value}
					</Text>
				</Text>
			</div>
			<div className={classes.elem('rightSide').b()}>
				{showToggle ? (
					<>
						<Toggle checked={showInactive} onChange={() => setShowInactive(active => !active)}></Toggle>
						<Text>{`${T('default.show')} ${T('default.agreementsInactive').toLowerCase()}`}</Text>
					</>
				) : null}
				{hasSubAccountsV2 && showToggle ? groupCompanyToggle : null}
				<PromoButton client={client} contact={contact} />
				{hasSplitButtonClientCard || hasSubAccountsV2 ? (
					<PrimaryButton icon="plus" onClick={() => createSubscription(client)}>
						{T('agreementHistory.typeNewAgreement')}
					</PrimaryButton>
				) : (
					<DropDownMenu
						align="right"
						useAnimation
						noMinWidth
						renderTrigger={(expanded, setExpanded) => (
							<PrimaryButton className={classes.elem('buttonTrigger').b()} onClick={setExpanded}>
								<Icon color="super-light-green" name="plus" space="mrs" />
								{T('default.add')}
							</PrimaryButton>
						)}
					>
						<div className={classes.elem('buttonDropDown').b()}>
							<Text onClick={() => createOrder(client?.id, contact)}>
								<Icon name="dollar" />
								{T('default.sales')}
							</Text>
							<Text onClick={() => createSubscription(client, contact)}>
								<Icon name="subscription" />
								{T('default.agreement')}
							</Text>
						</div>
					</DropDownMenu>
				)}
			</div>
		</div>
	);
};

export default CardsHeader;
