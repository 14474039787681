import T from 'Components/Helpers/translate';
import logError from 'App/babel/helpers/logError';
import copyToClipboard from 'Services/copyToClipboard';
import MailCampaignResource from 'App/resources/MailCampaign';
import openModal from 'App/services/Modal';
import openNewSegmentModal from 'App/components/NewSegment/NewSegmentModal';

angular.module('domain.mail').controller('MailCampaign', [
	'$scope',
	'meta',
	'$upModal',
	'$stateParams',
	'MailCampaign',
	'$state',
	'RequestBuilder',
	'Mail',
	'AppService',
	'$q',
	'$sce',
	'$multiSelect',
	'FormSubmit',
	'MailCampaignInfo',
	'Visitor',
	'FilterHelper',
	'$safeApply',
	function (
		$scope,
		meta,
		$upModal,
		$stateParams,
		MailCampaign,
		$state,
		RequestBuilder,
		Mail,
		AppService,
		$q,
		$sce,
		$multiSelect,
		FormSubmit,
		MailCampaignInfo,
		Visitor,
		FilterHelper,
		$safeApply
	) {
		var MailCampaignCtrl = this;
		var customerId;

		var isSelectedState = function (stateName) {
			var name = stateName.split('.')[1];
			return $state.current.url.indexOf(name) !== -1 || (!$state.current.url && name === 'dashboard');
		};

		var getStateHref = function (stateName, params) {
			params = _.merge({}, params || {}, { customerId: customerId.toString() });
			return $state.href(stateName, params);
		};

		var runMultiAction = function (action) {
			var rb = MailCampaignCtrl.rootData.currentRb
				? MailCampaignCtrl.rootData.currentRb.clone()
				: new RequestBuilder();

			// Create fake listView
			var listView = { filters: {}, mailCampaignId: MailCampaignCtrl.rootData.mailCampaign.id };
			if (MailCampaignCtrl.rootData.pageComponent === 'recipients' && $multiSelect.allSelectedFilter) {
				const filter = FilterHelper.filter('MailCampaignStatus', 'contact');
				filter.value = {
					bounce: MailCampaignCtrl.rootData.pageData.filters.bounce,
					click: MailCampaignCtrl.rootData.pageData.filters.click,
					deliveredGroup: MailCampaignCtrl.rootData.pageData.filters.deliveredGroup,
					duplicates: MailCampaignCtrl.rootData.pageData.filters.duplicates,
					invalidemail: MailCampaignCtrl.rootData.pageData.filters.invalidemail,
					open: MailCampaignCtrl.rootData.pageData.filters.open,
					prevbounce: MailCampaignCtrl.rootData.pageData.filters.prevbounce,
					prevunsub: MailCampaignCtrl.rootData.pageData.filters.prevunsub,
					submit: MailCampaignCtrl.rootData.pageData.filters.submit,
					unsub: MailCampaignCtrl.rootData.pageData.filters.unsub,
					mailCampaignId: MailCampaignCtrl.rootData.mailCampaign.id
				};

				listView.filters.MailCampaignStatus = filter;
				listView.actionEntity = 'contact';
			}

			const runner = action.run(listView, rb.build(), rb, []);
			if (runner && runner.then) {
				// eslint-disable-next-line promise/catch-or-return
				runner.then(() => $multiSelect.selectNone());
			} else {
				$multiSelect.selectNone();
			}
			return runner;
		};

		var copy = function () {
			const mailData = { ...MailCampaignCtrl.rootData.mailCampaign };
			mailData.isArchived = false;
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_CREATE_GROUP_MAIL')) {
				openModal('CreateGroupMail', {
					copy: mailData
				});
			} else {
				Tools.$upModal.open('createGroupMail', { copy: mailData });
			}
		};

		var toggleArchive = function () {
			var mail = MailCampaignCtrl.rootData.mailCampaign;
			mail.isArchived = !mail.isArchived;

			MailCampaign.archive(mail.id, mail.isArchived);
		};

		var createSegment = function () {
			var segment = { filter: [{}] };
			var filters = FilterHelper.parseFilters(MailCampaignCtrl.rootData.mailCampaign.filterConfig, 'contact');

			segment.filter[0].body = { q: filters.build().q };
			segment.filter[0].config = MailCampaignCtrl.rootData.mailCampaign.filterConfig;
			var options = {
				customerId: customerId,
				segment: segment
			};
			openNewSegmentModal(options);
		};

		const onRetryEmailCampaign = () => {
			MailCampaignResource.retry(MailCampaignCtrl.rootData.mailCampaign.id)
				.then(() => {
					MailCampaignCtrl.rootData.mailCampaign.retryingErrors = true;
					$safeApply($scope);
				})
				.catch(e => logError(e, 'Failed to retry emailCampaign'));
		};

		const onRetryErrors = () => {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					body: T('mailCampaign.retryXFailedEmailsInfo', {
						value: MailCampaignCtrl.rootData.numRetryableErrors
					}),
					title: 'mailCampaign.retryFailedEmails',
					confirmButtonText: 'mail.sendNow',
					onClose: confirmed => {
						if (confirmed) {
							onRetryEmailCampaign();
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			Tools.$upModal
				.open('defaultConfirm', {
					type: 'confirm',
					body: T('mailCampaign.retryXFailedEmailsInfo', {
						value: MailCampaignCtrl.rootData.numRetryableErrors
					}),
					title: 'mailCampaign.retryFailedEmails',
					resolveTrue: 'mail.sendNow'
				})
				.then(() => {
					onRetryEmailCampaign();
				});
		};

		MailCampaignCtrl.rootData = {
			numRetryableErrors: 0,
			onRetryErrors,
			pageLoading: false,
			pageComponent: 'dashboard',
			pageData: null,
			isSelectedState: isSelectedState,
			getStateHref: getStateHref,
			listActionData: {
				selected: 0,
				actions: [],
				runAction: runMultiAction,
				allSelected: false,
				selectNone: function () {
					$multiSelect.selectNone();
				}
			},
			info: {
				clicks: {},
				forms: []
			},
			formSubmits: 0,
			stats: null,
			clicks: null,
			countries: null,
			devices: null,
			onCopy: copy,
			onArchive: toggleArchive,
			customerId: 0,
			createSegment: createSegment
		};

		$scope.$on('$multiSelect.toggle', function (e, data) {
			MailCampaignCtrl.rootData.listActionData.selected = data.selected.length;
		});

		$scope.$on('$multiSelect.selectAll', function (e, data) {
			MailCampaignCtrl.rootData.listActionData.selected = data.selected.length;
			MailCampaignCtrl.rootData.listActionData.allSelected = false;
		});

		$scope.$on('$multiSelect.selectAllVisible', function (e, data) {
			MailCampaignCtrl.rootData.listActionData.selected = data.selected.length;
			MailCampaignCtrl.rootData.listActionData.allSelected = false;
		});

		$scope.$on('$multiSelect.selectAllFilter', function () {
			MailCampaignCtrl.rootData.listActionData.selected = MailCampaignCtrl.rootData.pageData.total;
			MailCampaignCtrl.rootData.listActionData.allSelected = true;
		});

		$scope.$on('$multiSelect.selectNone', function () {
			MailCampaignCtrl.rootData.listActionData.selected = 0;
			MailCampaignCtrl.rootData.listActionData.allSelected = false;
		});

		MailCampaignCtrl.calculateTotalPages = function (itemsPerPage, totalItems) {
			var totalPages = itemsPerPage < 1 ? 1 : Math.ceil(totalItems / itemsPerPage);
			return Math.max(totalPages || 0, 1);
		};

		MailCampaignCtrl.rootData.openPreview = function () {
			openModal('MailPreview', {
				html: MailCampaignCtrl.rootData.mailCampaign.body,
				isPlainText: !MailCampaignCtrl.rootData.mailCampaign.bodyJson,
				onShare: () => {
					MailCampaignCtrl.rootData.copyShareableLink();
				}
			});
		};

		MailCampaignCtrl.rootData.copyShareableLink = function () {
			if (copyToClipboard(MailCampaignCtrl.rootData.mailCampaign.previewUrl)) {
				Tools.NotificationService.addNotification({
					icon: 'check',
					style: Tools.NotificationService.style.SUCCESS,
					title: 'default.linkCopiedToClipboard'
				});
			} else {
				Tools.NotificationService.addNotification({
					icon: 'times',
					style: Tools.NotificationService.style.ERROR,
					title: 'default.error'
				});
			}
		};
		var getStats = function () {
			return MailCampaignInfo.get($stateParams.id);
		};

		var getEventStats = function () {
			var rb = new RequestBuilder();
			rb.limit = 0;
			rb.addFilter(MailCampaignInfo.attr.groupMailId, rb.comparisonTypes.Equals, $stateParams.id);
			rb.addFilter(MailCampaignInfo.attr.type, rb.comparisonTypes.Equals, [
				'open',
				'click',
				'formsubmit',
				'unsub',
				'hard_bounce',
				'soft_bounce'
			]);

			return MailCampaignInfo.find(rb.build()); // res.metadata.total;
		};

		var getRecipientStats = function (hasError) {
			var rb = new RequestBuilder();
			rb.limit = 0;
			rb.addFilter(Mail.attr.groupMailId, rb.comparisonTypes.Equals, MailCampaignCtrl.rootData.mailCampaign.id);
			if (!hasError) {
				rb.addFilter(Mail.attr.errorCause, rb.comparisonTypes.Equals, null); // Only receivers that got the mail?
			} else {
				rb.addFilter(Mail.attr.errorCause, rb.comparisonTypes.NotEquals, null); // Only receivers that got the mail?
			}
			return Mail.find(rb.build()); // res.metadata.total;
		};

		var getVisitStats = function () {
			var rb = new RequestBuilder();
			rb.limit = 0;
			rb.addFilter(
				{ field: 'leadSource.value' },
				rb.comparisonTypes.Equals,
				MailCampaignCtrl.rootData.mailCampaign.id.toString()
			);
			return Visitor.find(rb.build());
		};

		var getFormSubmitStats = function () {
			var filters = new RequestBuilder();

			filters.addFilter(FormSubmit.attr.leadSource.attr.source, filters.comparisonTypes.Equals, 'upsalesmail');
			filters.addFilter(FormSubmit.attr.leadSource.attr.type, filters.comparisonTypes.Equals, 'email');
			filters.addFilter(
				FormSubmit.attr.leadSource.attr.value,
				filters.comparisonTypes.Equals,
				MailCampaignCtrl.rootData.mailCampaign.id.toString()
			);

			filters.limit = 0;
			return FormSubmit.find(filters.build());
		};

		var init = function () {
			customerId = AppService.getCustomerId();

			MailCampaignCtrl.rootData.mailCampaign = meta.mailCampaign.data;
			MailCampaignCtrl.rootData.customerId = customerId;

			var promises = {
				submits: getFormSubmitStats(),
				visits: getVisitStats(),
				recipients: getRecipientStats(),
				undelivered: getRecipientStats(true),
				events: getEventStats(),
				stats: getStats()
			};

			$q.all(promises)
				.then(function (res) {
					MailCampaignCtrl.rootData.formSubmits = res.submits.metadata.total;
					MailCampaignCtrl.rootData.visits = res.visits.metadata.total;
					MailCampaignCtrl.rootData.recipients = res.recipients.metadata.total;
					MailCampaignCtrl.rootData.undelivered = res.undelivered.metadata.total;
					MailCampaignCtrl.rootData.events = res.events.metadata.total;

					// stats
					MailCampaignCtrl.rootData.info = res.stats.data;
					MailCampaignCtrl.rootData.clicks = res.stats.data.clicks;
					MailCampaignCtrl.rootData.countries = res.stats.data.countryStatistics;

					var Devices = _.map(res.stats.data.deviceStatistics, function (item) {
						item.device = 'device.' + item.device;
						return item;
					});

					MailCampaignCtrl.rootData.devices = Devices;
					MailCampaignCtrl.rootData.stats = res.stats.data.events;
					MailCampaignCtrl.rootData.stats.total =
						MailCampaignCtrl.rootData.stats.send +
						res.stats.data.events.unsubscribed +
						res.stats.data.events.previous_unsubscribed +
						res.stats.data.events.previous_bounce +
						res.stats.data.events.hardBounce +
						res.stats.data.events.softBounce +
						res.stats.data.events.notActive +
						res.stats.data.events.duplicateEmail +
						res.stats.data.events.invalidEmail +
						res.stats.data.events.reject +
						res.stats.data.events.otherError;
					MailCampaignCtrl.rootData.stats.error =
						MailCampaignCtrl.rootData.stats.total -
						MailCampaignCtrl.rootData.stats.send -
						MailCampaignCtrl.rootData.stats.missingEmail;

					if (Tools.FeatureHelper.hasSoftDeployAccess('RETRY_MAIL_TAG_ERROR')) {
						MailCampaignCtrl.rootData.numRetryableErrors = res.stats.data.numRetryableErrors;
					}
				})
				.catch(e => {
					logError(e, 'Failed to load mailCampaign');
				});
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);
