import SalesGraph, { Groupings, WidgetType } from 'App/components/ClientSales/SalesGraph/SalesGraph';
import { OldOrNewAgreement } from 'App/components/SubscriptionCards/SubscriptionCards';
import { useCompanyGroupSelector } from '../Context/CompanyGroupContext';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import DefaultNoData from 'App/components/ListView/DefaultNoData';
import SubscriptionCards from 'App/components/SubscriptionCards';
import { currencyFormat } from 'Components/Filters/Currencies';
import { getFilterFn } from 'Components/ListTab/ListTabHelper';
import { useTranslation } from 'Components/Helpers/translate';
import { useMetadata } from 'App/components/hooks/appHooks';
import { NumberFormat } from 'App/babel/utils/numberFormat';
import GroupSubscriptionList from './GroupSubscriptionList';
import { useSoftDeployAccess } from 'App/components/hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import { Colors } from '@upsales/components/Utils/colors';
import { Toggle, Flex, Text } from '@upsales/components';
import GroupCompanyToggle from './GroupCompanyToggle';
import RequestBuilder from 'Resources/RequestBuilder';
import SalesTarget from './SalesTarget/SalesTarget';
import ButtonSelectGroup from './ButtonSelectGroup';
import ClientResource from 'App/resources/Client';
import Client from 'App/resources/Model/Client';
import logError from 'Helpers/logError';
import GrowthGrid from './GrowthGrid';
import SalesList from './SalesList';
import BigNumber from './BigNumber';

import './SalesTabGroup.scss';

type Props = {
	salesTotal: number | null;
	clientIds: number[];
	isGroupBySubAccount?: boolean;
	client?: Client;
	totalValuesThisYear?: {
		salesThisYear?: number;
		cmThisYear?: number;
		arrThisYear?: number;
		mrrThisYear?: number;
	};
};

export type BigNumberProps = {
	value: string;
	label: string;
	loading?: boolean;
	color?: Colors;
};

const aggregatedValues = ['arr', 'arrLast12Months', 'salesLast12Months'];

export const Detached = ({
	salesTotal,
	clientIds: initialClientIds,
	client,
	isGroupBySubAccount = false,
	totalValuesThisYear = {}
}: Props) => {
	const metadata = useMetadata();
	const currency = metadata?.defaultCurrency || { iso: 'SEK', rate: 1 };
	const { SalesModelOption, SalesModel: salesModel } = metadata?.params || {};
	const { t } = useTranslation();
	const hasRecurring = salesModel === 'rr';
	const hasMRR = hasRecurring && SalesModelOption === 'mrr';
	const hasSales = salesModel === 'sales';
	const numberFormat = new NumberFormat();

	const [grouping, setGrouping] = useState<Groupings>(Groupings.YEAR);
	const [widgetType, setWidgetType] = useState<WidgetType>(
		hasRecurring ? WidgetType.TOTAL_RECURRING : WidgetType.CASH_FLOW
	);
	const [agreementGroups, setAgreementGroups] = useState<OldOrNewAgreement[]>([]);
	const [aggregatedGrowth, setAggregatedGrowth] = useState<{ [key: string]: number }>({});
	const [groupByCompany, setGroupByCompany] = useState(false);
	const [clientIds, setClientIds] = useState<number[]>([]);
	const clientRequest = useRef<CancelablePromise<Awaited<ReturnType<typeof ClientResource.get>>> | null>(null);
	const groupingLabel = isGroupBySubAccount ? t('account.subaccount') : t('company');
	const hasClientTarget = useSoftDeployAccess('CLIENT_TARGET');

	const filterFn = getFilterFn(!isGroupBySubAccount, '');
	const { salesThisYear, cmThisYear, arrThisYear, mrrThisYear } = totalValuesThisYear;

	const operationalAccountId = client?.operationalAccount?.id;
	const numberOfSubaccounts = client?.numberOfSubaccounts;

	useEffect(() => {
		const initializeAggregatedGrowth = (keys: string[]): { [key: string]: number } => {
			const initialGrowth: { [key: string]: number } = {};
			keys.forEach(key => {
				initialGrowth[key] = 0;
			});
			return initialGrowth;
		};

		const initialGrowth = initializeAggregatedGrowth(aggregatedValues);
		if (!initialClientIds.length) {
			setAggregatedGrowth(initialGrowth);
			return;
		}
		const requestBuilder = new RequestBuilder();
		filterFn(requestBuilder, initialClientIds);
		requestBuilder.fields = ['id', 'growth'];

		clientRequest.current = makeCancelable(ClientResource.find(requestBuilder.build()));
		clientRequest.current.promise
			.then(({ data }: { data: Pick<Client, 'id' | 'growth'>[] }) => {
				const ids: number[] = [];
				data.forEach(client => {
					ids.push(client.id);
					Object.entries(client.growth ?? {}).forEach(([key, value]) => {
						if (!Number.isNaN(value)) {
							initialGrowth[key] = (initialGrowth[key] || 0) + (value as number);
						}
					});
				});
				setClientIds(ids);
				setAggregatedGrowth(initialGrowth);
			})
			.catch(error => {
				logError(error, 'Could not get client data');
			});

		return () => {
			clientRequest.current?.cancel();
		};
	}, [initialClientIds.join(',')]);

	let realGrouping = grouping;
	if (widgetType === WidgetType.PRODUCT) {
		realGrouping = Groupings.PRODUCT;
	}

	let realWidgetType = widgetType;
	if (widgetType === WidgetType.PRODUCT && salesModel !== 'rr') {
		realWidgetType = WidgetType.CASH_FLOW;
	}

	const showClientName = !operationalAccountId && numberOfSubaccounts !== 0;

	const content = useMemo(() => {
		switch (widgetType) {
			case WidgetType.RECURRING:
			case WidgetType.TOTAL_RECURRING:
				return groupByCompany && clientIds.length ? (
					<GroupSubscriptionList
						clientIds={clientIds}
						isGroupBySubAccount={isGroupBySubAccount}
						groupCompanyToggle={
							<Flex space="pll prl">
								<Toggle
									checked={groupByCompany}
									onChange={() => setGroupByCompany(!groupByCompany)}
									space="mrm"
								/>
								<Text>{`${t('default.groupBy')} ${groupingLabel.toLowerCase()}`}</Text>
							</Flex>
						}
						client={client}
						agreementGroups={agreementGroups}
					/>
				) : !client && clientIds.length ? (
					<SubscriptionCards
						showClientName={showClientName}
						hasMRR={hasMRR}
						clientId={clientIds[0]}
						subAccountIds={[...clientIds].splice(1)}
						isGroupBySubAccount={isGroupBySubAccount}
						setItems={setAgreementGroups}
						groupCompanyToggle={
							showClientName ? (
								<GroupCompanyToggle
									groupByCompany={groupByCompany}
									onChange={() => setGroupByCompany(!groupByCompany)}
									groupingLabel={groupingLabel}
								/>
							) : null
						}
					/>
				) : client && clientIds.length ? (
					<SubscriptionCards
						showClientName={showClientName}
						hasMRR={hasMRR}
						client={client as Client}
						subAccountIds={clientIds.filter(id => id !== client.id)}
						isGroupBySubAccount={isGroupBySubAccount}
						setItems={setAgreementGroups}
						groupCompanyToggle={
							showClientName ? (
								<GroupCompanyToggle
									groupByCompany={groupByCompany}
									onChange={() => setGroupByCompany(!groupByCompany)}
									groupingLabel={groupingLabel}
								/>
							) : null
						}
					/>
				) : null;
			case WidgetType.PRODUCT:
				return (
					<GrowthGrid
						clientIds={clientIds}
						setGrouping={setGrouping}
						grouping={grouping}
						isGroupBySubAccount={isGroupBySubAccount}
					/>
				);
		}
	}, [widgetType, grouping, groupByCompany, clientIds]);

	const listContent = useMemo(() => {
		switch (widgetType) {
			case WidgetType.PRODUCT:
				return null;
			default:
				return clientIds.length ? (
					<SalesList
						clientIds={clientIds}
						widgetType={realWidgetType}
						grouping={realGrouping}
						isGroupBySubAccount={isGroupBySubAccount}
						isSubaccount={!!operationalAccountId || numberOfSubaccounts === 0}
					/>
				) : null;
		}
	}, [widgetType, realGrouping, clientIds]);

	const classes = new BemClass('SalesTabGroup');

	let bigNumbers: BigNumberProps[] = [
		{
			loading: aggregatedGrowth.arr == null,
			value: currencyFormat(
				(hasMRR ? aggregatedGrowth.mrr : aggregatedGrowth.arr ?? 0) * currency.rate,
				currency.iso
			),
			label: hasMRR ? t('default.currentMRR') : t('default.currentARR')
		},
		{
			loading: aggregatedGrowth.arrLast12Months == null,
			value: `${
				(hasMRR ? aggregatedGrowth.mrrLast12Months : aggregatedGrowth.arrLast12Months ?? 0) >= 0 ? '+' : ''
			}${currencyFormat(
				(hasMRR ? aggregatedGrowth.mrrLast12Months : aggregatedGrowth.arrLast12Months ?? 0) * currency.rate,
				currency.iso
			)}`,
			label: hasMRR ? t('account.recurring.mrr12months') : t('account.recurring.arr12months'),
			color:
				(hasMRR ? aggregatedGrowth.mrrLast12Months : aggregatedGrowth.arrLast12Months ?? 0) >= 0
					? 'success-5'
					: 'red'
		}
	];

	if (salesModel === 'sales') {
		bigNumbers = [
			{
				loading: salesTotal == null,
				value: currencyFormat((salesTotal ?? 0) * currency.rate, currency.iso),
				label: t('default.sales12Months')
			}
		];
	} else if (salesModel === 'cm') {
		bigNumbers = [
			{
				loading: aggregatedGrowth.salesLast12Months == null,
				value: currencyFormat((aggregatedGrowth.salesLast12Months ?? 0) * currency.rate, currency.iso),
				label: t('default.sales12Months')
			},
			{
				loading: salesTotal == null,
				value: currencyFormat((salesTotal ?? 0) * currency.rate, currency.iso),
				label: t('default.contributionMargin12Months')
			},
			{
				loading: salesTotal == null || aggregatedGrowth.salesLast12Months == null,
				value: numberFormat.percent((salesTotal ?? 0) / (aggregatedGrowth.salesLast12Months || 1)),
				label: t('default.contributionMarginRatio12Months')
			}
		];
	}

	return (
		<Flex direction="column" className={classes.b()}>
			<Flex direction="column" className={classes.elem('topSection').b()} space="ptl pbxl">
				<Flex>
					<Flex
						direction="column"
						gap="u8"
						space="plxl prxl"
						className={classes.elem('topSection').elem('bigNumbers').b()}
					>
						{bigNumbers.map(({ value, label, loading, color }, index) => (
							<BigNumber key={index} value={value} label={label} loading={loading} color={color} />
						))}
						{isGroupBySubAccount && client && hasClientTarget ? (
							<SalesTarget
								quotas={client.clientQuotas}
								clientId={client.id}
								salesThisYear={salesThisYear}
								cmThisYear={cmThisYear}
								arrThisYear={arrThisYear}
								mrrThisYear={mrrThisYear}
							/>
						) : null}
					</Flex>
					{initialClientIds.length ? (
						<Flex
							direction="column"
							className={classes.elem('topSection').elem('widget').b()}
							space="ptl mrxl"
						>
							<ButtonSelectGroup
								grouping={grouping}
								widgetType={widgetType}
								onGroupingChange={v => {
									if (hasSales && (v === Groupings.PRODUCT || v === Groupings.PRODUCT_CATEGORY)) {
										setWidgetType(WidgetType.PRODUCT);
									} else if (hasSales) {
										setWidgetType(WidgetType.CASH_FLOW);
									}
									setGrouping(v);
								}}
								onWidgetChange={v => {
									setWidgetType(v);
									if (v === WidgetType.PRODUCT) {
										setGrouping(Groupings.PRODUCT);
									} else {
										setGrouping(Groupings.YEAR);
									}
								}}
							/>
							<SalesGraph
								hideGoal
								height={2}
								grouping={grouping}
								widgetType={realWidgetType}
								clientId={initialClientIds[0]}
								subAccountIds={[...initialClientIds].splice(1)}
								isGroupBySubAccount={isGroupBySubAccount}
							/>
						</Flex>
					) : null}
				</Flex>
			</Flex>
			{initialClientIds.length ? (
				<Flex direction="column">
					{content}
					{listContent}
				</Flex>
			) : (
				<Flex justifyContent="center">
					<DefaultNoData subtitle="" formatNoData={() => t('default.noResults')} />
				</Flex>
			)}
		</Flex>
	);
};

const SalesTab = ({ ...props }: Omit<Props, 'clientIds'>) => {
	const { clientIds } = useCompanyGroupSelector(({ clientIds }) => ({
		clientIds
	}));

	return <Detached {...props} clientIds={clientIds} />;
};

export default SalesTab;
