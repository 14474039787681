import React from 'react';
import PropTypes from 'prop-types';
import StateTabs from './StateTabs';
import { Icon, Help, Checkbox, Button, EllipsisTooltip, Tooltip } from '@upsales/components';
import AdminHeader from './AdminHeader';
import { openDrawer } from 'Services/Drawer';
import openModal from 'App/services/Modal';
import { hasTodoCustomFields } from 'App/babel/helpers/todo';
import { openEditCustomFieldModal } from 'Components/Modals/Fields/EditCustomField';
import { openEditStandardFieldModal } from './EditStandardFieldModal';
import { openTranslateFieldModal } from './TranslateFieldModal';
class Fields2 extends React.Component {
	static propTypes = {
		rootData: PropTypes.object.isRequired,
		type: PropTypes.string
	};

	state = {
		datatypes: [
			{ type: 'String', icon: 'A' },
			{ type: 'Date', icon: <i className="fa fa-calendar" /> },
			{ type: 'Integer', icon: '42', class: 'icon number' },
			{ type: 'Boolean', icon: 'Y/N', class: 'icon yn' },
			{ type: 'Percent', icon: <i className="fa fa-percent" /> },
			{ type: 'Phone', icon: <Icon name="phone" /> },
			{ type: 'Time', icon: <i className="fa fa-clock-o" /> },
			{ type: 'Currency', icon: <i className="fa fa-usd" /> },
			{ type: 'Select', icon: <i className="fa fa-list-ul" /> },
			{ type: 'Email', icon: <i className="fa fa-at" /> },
			{ type: 'Link', icon: <i className="fa fa-link" /> },
			{ type: 'Text', icon: <i className="fa fa-pencil" /> },
			{ type: 'Discount', icon: '25%', class: 'icon number' },
			{ type: 'Calculation', icon: <Icon name="calculator" /> },
			{ type: 'Users', icon: <i className="fa fa-user" /> },
			{ type: 'User', icon: <i className="fa fa-user" /> }
		]
	};

	componentDidUpdate() {
		this.refreshDragableTables();
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillMount() {
		var t = Tools.$translate;
		this.dragableTables = [];
		var type = this.props.rootData.pageData.customFieldType;
		var AppService = Tools.AppService;

		this.lang = {
			fields: t('admin.anonymization.customFields'),
			addField: t('soliditet.addField'),
			standardfields: t('admin.standardfields'),
			descriptionText: t('admin.fields.description'),
			description: t('default.description'),
			allFields: t('default.allFields'),
			all: t('default.all'),
			none: t('default.none'),
			title: t('default.title').toUpperCase(),
			type: t('default.type').toUpperCase(),
			permission: t('default.permissionToRole').toUpperCase(),
			default: t('default.default'),
			required: t('form.required').toUpperCase(),
			active: t('default.active').toUpperCase(),
			addresses: t('admin.fields.addresses'),
			types: {
				String: t('admin.customfieldType.String'),
				Date: t('admin.customfieldType.Date'),
				Integer: t('admin.customfieldType.Integer'),
				Boolean: t('admin.customfieldType.Checkbox'),
				Percent: t('admin.customfieldType.Percent'),
				Phone: t('admin.customfieldType.Phone'),
				Time: t('admin.customfieldType.Time'),
				Currency: t('admin.customfieldType.Currency'),
				Select: t('admin.customfieldType.Select'),
				Email: t('admin.customfieldType.Email'),
				Link: t('admin.customfieldType.Link'),
				Text: t('admin.customfieldType.Text'),
				Discount: t('admin.customfieldType.Discount'),
				Calculation: t('admin.customfieldType.Calculation'),
				Users: t('default.users'),
				User: t('column.userId')
			},
			standardFieldGroups: {
				Client: {
					standard: t('default.clientField'),
					social: t('visitor.type.social'),
					contactInfo: t('form.contact.standard')
				},
				Contact: {
					social: t('visitor.type.social')
				},
				Activity: {},
				Appointment: {},
				Order: {},
				Agreement: {}
			},
			deleteWarning1: t('admin.fields.deleteWarning1'),
			deleteWarning2: t('admin.fields.deleteWarning2'),
			confirm: t('default.confirm'),
			deleteField: t('soliditet.matcher.actionDelete') + ' ' + t('default.field').toLowerCase(),
			cannotDeleteIntegrationField: t('admin.fields.cannotDeleteIntegrationField'),
			ok: t('default.confirm'),
			defaultFormGroup: t('default.otherInfo'),
			formGroup: t('admin.fields.formGroup')
		};

		const metadata = AppService.getMetadata();
		const udos = metadata.params.UserDefinedObject;
		if (type.indexOf('userDefined') !== -1) {
			var id = parseInt(type[type.length - 1]);
			var udo = _.find(udos, { id: id });

			if (!udo) {
				this.title = '';
			}
			this.title = udo.name + ' ' + this.lang.fields.toLowerCase();
		} else if (type === 'orderrow') {
			this.title = t('filters.columns.orderRow');
		} else if (type === 'activity' && Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
			this.title = t('admin.fields.phonecalls');
		} else {
			this.title = t('filters.columns.' + type);
		}

		this.canShowFormGroups =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FORM_GROUPS) &&
			(type === 'order' || type === 'contact' || type === 'account');

		this.standardFieldWidths = [/* name */ '30%', /* desc */ '45%', /* required */ '15%', /* active  */ '10%'];

		this.hasTooltipFeature =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ADMIN_STANDARD_FIELD_TOOLTIP) &&
			Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.ADMIN_STANDARD_FIELD_TOOLTIP);
		this.hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');

		this.tabsArr = [
			{ type: 'account', title: 'default.account', feature: 'CLIENT_CUSTOM' },
			{ type: 'contact', title: 'default.contact', feature: 'CONTACT_CUSTOM' },
			{
				type: 'activity',
				title: Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					? 'admin.fields.phonecalls'
					: 'default.activity',
				feature: 'ACTIVITY_CUSTOM'
			},
			{
				type: 'todo',
				title: 'default.todos',
				feature: 'ACTIVITY_CUSTOM',
				available: () => hasTodoCustomFields()
			},
			{ type: 'appointment', title: 'default.appointment', feature: 'APPOINTMENT_CUSTOM' },
			{ type: 'orderAndOpportunities', title: 'admin.orderAndOpportunities', feature: 'ORDER_CUSTOM' },
			{ type: 'orderrow', title: 'default.orderrow', feature: 'ORDER_ROW_CUSTOM' },
			{ type: 'product', title: 'default.product', feature: 'PRODUCT_CUSTOM_FIELDS' },
			{ type: 'campaign', title: 'default.campaign', feature: 'PROJECT_CUSTOM' },
			{ type: 'user', title: 'default.user', feature: 'USER_CUSTOM' },
			{ type: 'agreement', title: 'default.agreement', feature: 'RECURRING_ORDER_CUSTOM' },
			{ type: 'projectPlan', title: 'default.projectPlan', feature: 'PROJECT_PLAN_CUSTOM' },
			{
				type: 'ticket',
				title: 'default.ticket',
				feature: Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT
			},
			...udos.map(udo => ({
				type: 'userDefined' + udo.id,
				title: udo.name || 'default.noName',
				feature: Tools.FeatureHelper.Feature.UDO
			}))
		];
	}

	setRef = (name, ref) => {
		this[name] = ref;
	};

	onNewField = () => {
		this.openModal(null);
	};

	openModal = field => {
		var rootData = this.props.rootData;

		openEditCustomFieldModal({
			field: field,
			customFields: this.props.rootData.pageData.customFields,
			type: this.props.rootData.pageData.customFieldType,
			title: this.title
		})
			.then(field => {
				if (field) {
					rootData.onChange('customField', field);
				}
			})
			.catch(err => {
				if (err) {
					console.error(err);
				}
			});
	};

	onClickRow = field => {
		this.openModal(field);
	};

	removeItem = (field, event) => {
		var self = this;
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();

		var modalBody = self.lang.deleteWarning1 + ' ' + field.name + '?\n' + self.lang.deleteWarning2;
		var listType = self.props.rootData.pageData.customFieldType;

		const alertConfirmOptions = {
			type: 'confirm',
			id: 'confirm-delete-fields',
			body: modalBody,
			title: this.lang.deleteField,
			confirmationMode: 'text',
			confirmFn: self.props.rootData.onSave.bind(null, 'customField', 'delete', field, listType)
		};

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			openModal('Alert', {
				...alertConfirmOptions,
				title: `${this.lang.confirm} ${this.lang.deleteField.toLowerCase()}`,
				onClose: confirmed => {
					if (confirmed) {
						alertConfirmOptions.confirmFn();
					}
				}
			});
		} else {
			Tools.$upModal.open('alert', alertConfirmOptions);
		}
	};

	translateField = standardField => {
		openTranslateFieldModal({
			fieldName: standardField.name.toLowerCase()
		});
	};

	translateFieldName = standardField => {
		// eslint-disable-next-line promise/catch-or-return
		openTranslateFieldModal({
			fieldName: standardField.name.toLowerCase(),
			defaultLang: Tools.AppService.getSelf().language,
			editMode: true
		}).then(this.props.rootData.reLoadStandardFields);
	};

	editStandardField = (standardField, fieldName) => {
		// Use edit custom field modal, for standard fields as well
		var rootData = this.props.rootData;
		// eslint-disable-next-line promise/catch-or-return
		openEditStandardFieldModal({
			field: standardField,
			name: fieldName
		}).then(function (field) {
			return rootData.onSave('standardField', 'save', field, field.name);
		});
	};

	onToggleRequired = (type, field, value, e) => {
		e.stopPropagation();
		if (type === 'standardField') {
			field.required = value;
			if (field.required) {
				field.active = true;
			}
			this.props.rootData.onSave('standardField', 'save', field);
		} else {
			field.obligatoryField = value ? 1 : 0;
			field.editable = 1;
			this.props.rootData.onSave('customField', 'save', field);
			if (field.obligatoryField && !field.visible) {
				field.visible = 1;
			}
		}
	};

	onToggleActive = (type, field, value, e) => {
		e.stopPropagation();
		field.active = value;
		if (!field.active) {
			field.required = false;
		}
		this.props.rootData.onSave('standardField', 'save', field);
	};

	updateSortIds = (oldIndex, newIndex, groupName) => {
		const customFields = this.props.rootData.pageData.proxyCustomFields;
		let newCustomFields;
		if (groupName === undefined) {
			newCustomFields = this.moveAndSortArray(customFields, oldIndex, newIndex);
		} else {
			newCustomFields = this.getRowsByGroup(customFields)
				.map(row => {
					if (row.name === groupName) {
						row.fields = this.moveAndSortArray(row.fields, oldIndex, newIndex);
					}
					return row;
				})
				.flatMap(item => item.fields);
		}
		this.props.rootData.onSave('customField', 'saveSort', newCustomFields);
	};

	iconMap = type => {
		if (type === undefined) {
			return '';
		}

		var icon = _.find(this.state.datatypes, function (d) {
			return d.type === type;
		});
		return <span className={icon && icon.class ? icon.class : 'icon'}>{icon && icon.icon}</span>;
	};

	getFieldIcon = field => {
		if (!field || !field.icon || !field.icon.class) {
			return '';
		}

		var iconClass = field.icon.class + (field.active ? '' : ' inactive');
		var iconValue = field.icon.value || '';

		return <i className={iconClass}>{iconValue}</i>;
	};

	getStandardFieldRow = sf => {
		var saving = this.props.rootData.saving;
		var editable = sf.editable && this.hasTooltipFeature;
		var translatable = this.hasNewFields && sf.type === 'SelectTranslate';
		var isFieldNameTranslatable = this.hasNewFields && sf.type === 'SelectFieldNameTranslate';
		var t = Tools.$translate;
		var fieldName = t(sf.nameTag);
		var onClick = editable ? this.editStandardField.bind(null, sf, fieldName) : null;
		const [language] = (Tools.AppService.getSelf().language || '').split('-');
		onClick = translatable ? this.translateField.bind(null, sf) : onClick;
		onClick = isFieldNameTranslatable ? this.translateFieldName.bind(null, sf) : onClick;
		onClick = sf.drawer ? () => openDrawer(sf.drawer) : onClick;

		var className = 'admin-table-row admin-table-standard-field-row';
		var descText = sf.descTag ? t(sf.descTag) : t('admin.customfieldType.' + sf.type);

		if (editable) {
			fieldName = <a>{fieldName}</a>;
		}

		if (isFieldNameTranslatable && sf.fieldNameTranslations) {
			fieldName = _.get(sf.fieldNameTranslations[language], 'value', null) || t(sf.nameTag);
		}

		var activeToggleVisibility = 'hidden';
		if (sf.canHide) {
			activeToggleVisibility = 'visible';
		}

		let requiredToggleVisble = 'hidden';
		if (sf.canMakeRequired) {
			requiredToggleVisble = 'visible';
		}

		return (
			<div
				key={'standard-field-' + sf.id}
				className={
					className +
					((sf.active && (editable || sf.drawer)) || translatable ? ' clickable' : '') +
					(sf.active ? ' active' : '')
				}
				onClick={onClick}
			>
				<div className="admin-table-cell" style={{ width: this.standardFieldWidths[0] }}>
					{<span className="icon-holder">{this.getFieldIcon(sf)}</span>}
					{fieldName}
				</div>
				<div className="admin-table-cell" style={{ width: this.standardFieldWidths[1] }}>
					{sf.elevioId ? <Help articleId={sf.elevioId} sidebar /> : null}
					<span style={{ fontSize: '12px', color: '#999', fontStyle: 'italic' }}>{descText}</span>
				</div>
				<div
					className="admin-table-cell checkbox-col"
					style={{ width: this.standardFieldWidths[2], visibility: requiredToggleVisble }}
				>
					<Checkbox
						size="sm"
						disabled={saving}
						checked={sf.required}
						onChange={this.onToggleRequired.bind(this, 'standardField', sf)}
					/>
				</div>
				<div
					className="admin-table-cell checkbox-col"
					style={{ width: this.standardFieldWidths[3], visibility: activeToggleVisibility }}
				>
					<Checkbox
						size="sm"
						disabled={saving}
						checked={sf.active}
						onChange={this.onToggleActive.bind(this, 'standardField', sf)}
					/>
				</div>
			</div>
		);
	};

	getFieldRow = (cf, hasPadding) => {
		var self = this;
		var onClick = this.onClickRow.bind(this, cf);
		var checked = cf.obligatoryField === 0 ? false : true;

		var removeBtn = null;

		if (this.props.rootData.pageData.canAdd) {
			var removeItem = self.removeItem.bind(self, cf);
			removeBtn = (
				<Tooltip
					disabled={!cf.integrationDependency?.integration}
					title={this.lang.cannotDeleteIntegrationField}
				>
					<button
						type="button"
						disabled={cf.integrationDependency?.integration}
						className="up-btn btn-link btn-grey delete-trigger"
						onClick={removeItem}
					>
						<b className="fa fa-trash-o" />
					</button>
				</Tooltip>
			);
		}

		return (
			<div
				key={'custom-field-' + cf.id}
				className={`admin-table-row clickable custom-fields ${hasPadding ? 'has-padding' : ''}`}
			>
				<div className="admin-table-cell color-grey is-draggable">
					<i className="fa fa-bars" />
				</div>
				<div className="admin-table-cell truncate" onClick={onClick} title={cf.name}>
					<EllipsisTooltip title={cf.name}>
						<div>{cf.name}</div>
					</EllipsisTooltip>
				</div>
				<div className="admin-table-cell" onClick={onClick}>
					{<span className="icon-holder">{this.iconMap(cf.datatype)}</span>}
					{this.lang.types[cf.datatype] || '@ ' + cf.datatype}
				</div>
				<div className="admin-table-cell truncate" onClick={onClick}>
					<ReactTemplates.admin.tableTokens additionalTokenSpace={35} items={cf.roles} />
				</div>
				<div className="admin-table-cell checkbox-col">
					{cf.datatype !== 'Calculation' ? (
						<Checkbox
							size="sm"
							checked={checked}
							onChange={this.onToggleRequired.bind(this, 'customField', cf)}
						/>
					) : null}
				</div>
				<div className="admin-table-cell admin-row-tools-wrap">
					<div className="admin-row-tools">{removeBtn}</div>
				</div>
			</div>
		);
	};

	moveAndSortArray = (array, oldIndex, newIndex) => {
		return array.reduce((res, item, index) => {
			if (index !== oldIndex) {
				if (index === newIndex) {
					if (oldIndex < newIndex) {
						res.push(item);
						res.push(array[oldIndex]);
					} else {
						res.push(array[oldIndex]);
						res.push(item);
					}
				} else {
					res.push(item);
				}
			}
			return res;
		}, []);
	};

	getRowsByGroup = rows => {
		return rows
			.sort((a, b) => a.sortId - b.sortId)
			.reduce((previous, row) => {
				let group = previous.find(group => group.name === row.formGroup);
				if (!group) {
					group = {
						name: row.formGroup,
						fields: []
					};
					previous.push(group);
				}
				group.fields.push(row);
				return previous;
			}, []);
	};

	updateGroupSorts = (oldIndex, newIndex) => {
		const customFields = this.props.rootData.pageData.proxyCustomFields;
		const newCustomFields = this.moveAndSortArray(this.getRowsByGroup(customFields), oldIndex, newIndex).flatMap(
			item => item.fields
		);

		this.props.rootData.onSave('customField', 'saveSort', newCustomFields);
	};

	getCustomFieldTableGroups = rows => {
		if (this.canShowFormGroups) {
			const rowsByGroup = this.getRowsByGroup(rows);
			let oldIndex;
			if (!(rowsByGroup.length === 0 || (rowsByGroup.length === 1 && rowsByGroup[0].name === null))) {
				this.createDraggableTable(true, {
					containment: 'parent',
					cursor: 'move',
					disabled: false,
					scroll: true,
					axis: 'y',
					items: '> .admin-table-group',
					handle: '.is-draggable-parent',
					tolerance: 'pointer',
					start: (e, ui) => {
						oldIndex = ui.item.index();
					},
					sort: function () {},
					stop: (e, ui) => {
						const newIndex = ui.item.index();
						if (oldIndex !== newIndex) {
							this.updateGroupSorts(oldIndex, newIndex);
						}
					}
				});
				return (
					<div className="admin-table-container" ref={this.dragableTableSetRef.bind(this, true)}>
						{rowsByGroup.map(group => {
							return (
								<details open className="admin-table-group" key={group.name}>
									<summary>
										<Icon name="chevron-down" className="cheveron"></Icon>
										<div className="admin-table-cell is-draggable-parent">
											<Icon name="bars"></Icon>
										</div>
										<div className="admin-table-group-title">
											{group.name ?? this.lang.defaultFormGroup}
										</div>
									</summary>
									<div className="admin-table-field-group admin-table-body">
										{this.getCustomFieldTable(group.fields, group.name, true)}
									</div>
								</details>
							);
						})}
					</div>
				);
			}
		}
		return this.getCustomFieldTable(rows);
	};

	createDraggableTable = (name, options) => {
		if (!this.dragableTables.find(table => table.name === name)) {
			this.dragableTables.push({ name, options, hasRun: false, table: undefined });
		}
	};

	refreshDragableTables = () => {
		this.dragableTables.forEach(dragableTable => {
			if (dragableTable.table && !dragableTable.hasRun) {
				dragableTable.hasRun = true;
				const table = jQuery(dragableTable.table);
				if (table.sortable) {
					table.sortable(dragableTable.options).disableSelection();
				}
			}
		});
	};

	dragableTableSetRef = (name, ref) => {
		const dragableTable = this.dragableTables.find(table => table.name === name);
		if (dragableTable) {
			dragableTable.table = ref;
		}
	};

	getCustomFieldTable = (rows, groupName, hasPadding) => {
		let oldIndex;

		this.createDraggableTable(groupName, {
			containment: 'parent',
			cursor: 'move',
			disabled: false,
			scroll: false,
			axis: 'y',
			items: '> .admin-table-row.custom-fields',
			handle: '.is-draggable',
			tolerance: 'pointer',
			start: (e, ui) => {
				oldIndex = ui.item.index() - 1;
			},
			sort: function () {},
			stop: (e, ui) => {
				const newIndex = ui.item.index() - 1;
				if (oldIndex !== newIndex) {
					this.updateSortIds(oldIndex, newIndex, groupName);
				}
			}
		});
		return (
			<div className="admin-table-body" ref={this.dragableTableSetRef.bind(this, groupName)}>
				<div key="header" className={`admin-table-header custom-fields ${hasPadding ? 'has-padding' : ''}`}>
					<div className="admin-table-cell" />
					<div className={'admin-table-cell'}>{this.lang.title}</div>
					<div className={'admin-table-cell'}>{this.lang.type}</div>
					<div className={'admin-table-cell'}>{this.lang.permission}</div>
					<div className={'admin-table-cell required-header'}>{this.lang.required}</div>
				</div>
				{rows.map(row => this.getFieldRow(row, hasPadding))}
			</div>
		);
	};

	getFieldGroup = (groupName, standardFields) => {
		var self = this;
		var rootData = this.props.rootData;
		var fieldType = rootData.pageData.standardFieldType;

		var standardFieldHeader = (
			<div className="admin-table-header">
				<div className={'admin-table-cell'} style={{ width: this.standardFieldWidths[0] }}>
					{self.lang.title}
				</div>
				<div className="admin-table-cell" style={{ width: this.standardFieldWidths[1] }}>
					{self.lang.description}
				</div>
				<div className={'admin-table-cell'} style={{ width: this.standardFieldWidths[2] }}>
					{self.lang.required}
				</div>
				<div className={'admin-table-cell'} style={{ width: this.standardFieldWidths[3] }}>
					{self.lang.active}
				</div>
			</div>
		);

		var standardFieldRows = [];
		standardFieldRows = _.reduce(
			standardFields,
			function (res, sf) {
				res.push(self.getStandardFieldRow(sf));

				return res;
			},
			[]
		);

		var title = this.lang.standardFieldGroups[fieldType] && this.lang.standardFieldGroups[fieldType][groupName];
		if (!title) {
			title = this.title;
		}

		return (
			<div key={'field-group-' + groupName} className="admin-table">
				<div className="admin-table-top">
					<span className="admin-table-title">{title}</span>
				</div>
				<div className="admin-table-body">
					{standardFieldHeader}
					{standardFieldRows}
				</div>
			</div>
		);
	};

	render() {
		var self = this;
		var rootData = this.props.rootData;

		rootData.pageData.standardFields = _.sortBy(rootData.pageData.standardFields, 'sortOrder');
		var standardFieldGroups = _.groupBy(rootData.pageData.standardFields, 'group');
		var standardGroups = [];

		_.forEach(standardFieldGroups, function (fields, groupName) {
			standardGroups.push(self.getFieldGroup(groupName, fields));
		});

		var addBtn = null;
		if (rootData.pageData.canAdd) {
			addBtn = (
				<Button onClick={self.onNewField} size="sm" data-test-id="admin-add-field">
					{self.lang.addField}
				</Button>
			);
		}

		return (
			<div id="admin-page-fields" className="admin-page-fields2">
				<AdminHeader
					title={this.title}
					description={this.lang.descriptionText}
					image="fields.svg"
					articleId={673}
				/>
				<StateTabs tabs={this.tabsArr} state="fields" type={this.props.type} />
				<div id="admin-content">
					{standardGroups}

					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{this.lang.fields}</span>
							<div className="pull-right">{addBtn}</div>
						</div>

						{this.getCustomFieldTableGroups(rootData.pageData.customFields)}
					</div>
				</div>
			</div>
		);
	}
}

export default Fields2;
