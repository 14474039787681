import UserDefinedObjectExport from 'App/enum/exportFields/udo';
import remove from 'App/helpers/multiActionRunnerHelpers/remove';
import planPhonecalls from 'App/helpers/multiActionRunnerHelpers/planPhonecalls';
import updateCompany from 'App/helpers/multiActionRunnerHelpers/updateCompany';
import anonymizeContacts from 'App/helpers/multiActionRunnerHelpers/anonymizeContacts';
import createMailCampaign from 'App/helpers/multiActionRunnerHelpers/createMailCampaign';
import updateContacts from 'App/helpers/multiActionRunnerHelpers/updateContacts';
import sendMail from 'App/helpers/multiActionRunnerHelpers/sendMail';
import { defaultFilterGetter } from 'App/helpers/multiActionRunnerHelpers/multiActionRunnerHelpers';
import addContactsToSegment from 'App/helpers/multiActionRunnerHelpers/addContactsToSegment';
import openModal from 'App/services/Modal';
import { openMultiUpdateContact } from 'App/components/MultiActionModal/MultiUpdateContact/OpenMultiUpdateContact';
import { openExportData } from 'App/components/ExportDataReact/ExportDataReact';
import { openMultiCloseActivity } from 'App/components/MultiActionModal/MultiCloseActivity/MultiCloseActivity';
import { convertToNewMultiSelect } from 'Helpers/multiModals';
import { openGenericModal } from 'App/components/GenericModal/GenericModal';
import { openCreateActivityModal } from 'Components/Modals/CreateActivity/CreateActivity';
import updateAppointments from 'App/helpers/multiActionRunnerHelpers/updateAppointments';
import { openMultiUpdateClientModal } from 'App/components/MultiActionModal/MultiUpdateClient/openMultiUpdateClientModal';
import { openMultiUpdateActivity } from 'App/components/MultiActionModal/MultiUpdateActivity/openMultiUpdateActivity';
import { openResetLeadMulti } from 'App/components/ResetLeadMulti/ResetLeadMulti';

angular.module('upUi').service('MultiRunnerActions', [
	'$rootScope',
	'$upModal',
	'$translate',
	'AppService',
	'FeatureHelper',
	'AccountAttributes',
	'FilterHelper',
	'$multiSelect',
	'MultiActions',
	'$q',
	function (
		$rootScope,
		$upModal,
		$translate,
		AppService,
		FeatureHelper,
		AccountAttributes,
		FilterHelper,
		$multiSelect,
		MultiActions,
		$q
	) {
		var MultiRunnerActions = this;
		var udo, noteFields, link;
		var typeActions = {};

		MultiRunnerActions.type = {
			ACCOUNT: 'ACCOUNT',
			CONTACT: 'CONTACT',
			AGREEMENT: 'AGREEMENT',
			MAIL: 'MAIL',
			FORMSUBMIT: 'FORMSUBMIT',
			VISIT: 'VISIT',
			ACTIVITY: 'ACTIVITY',
			APPOINTMENT: 'APPOINTMENT',
			USERDEFOBJ_CONTACT: 'USERDEFOBJ_CONTACT',
			USERDEFOBJ_PROJECT: 'USERDEFOBJ_PROJECT',
			USERDEFOBJ_CLIENT: 'USERDEFOBJ_CLIENT',
			LEAD: 'LEAD',
			ORDER: 'ORDER',
			OPPORTUNITY: 'OPPORTUNITY',
			SOCIALEVENT_DRAFT: 'SOCIALEVENT_DRAFT',
			SOCIALEVENT_LAUNCHED: 'SOCIALEVENT_LAUNCHED',
			PRODUCT: 'PRODUCT'
		};

		MultiRunnerActions.get = function (type, opts) {
			var self = AppService.getSelf();

			if (opts && opts.udo) {
				udo = opts.udo;
				udo.id = opts.udo.id === 1 ? '' : opts.udo.id;
				link = opts.udo.link;

				if (opts.udo.notes) {
					noteFields = opts.udo.notes.split(';');
					noteFields = noteFields.slice(0, 4); // MAX 4 note fields..
				} else {
					// DEFAULT AT LEAST ONE NOTE FIELD?!??!
					noteFields = [$translate.instant('default.notes')];
				}
			}

			var actions = typeActions[type];
			var returnActions = {};

			if (!opts) {
				opts = {};
			}

			if (!opts.ignore) {
				opts.ignore = [];
			}

			angular.forEach(actions, function (action, key) {
				if (opts.ignore.indexOf(key) !== -1) {
					return;
				}
				var exportActions = ['export', 'exportContacts', 'exportAccount'];

				if (exportActions.indexOf(key) !== -1 && !self.export) {
					action = Object.assign({}, action);
					action.disabled = true;
					action.tooltip = 'default.noExportRights';
					action.run = function () {
						return $q.when();
					};
				}

				if (typeof action.available === 'function' && !action.available()) {
					return;
				}

				returnActions[key] = action;

				if (typeof action.title === 'function') {
					returnActions[key].title = action.title();
				}
			});

			return _.cloneDeep(returnActions);
		};

		var actionsRuns = {
			updateContacts: (rb, clientContacts = false, entity, extraParams) =>
				updateContacts(rb, clientContacts, entity, extraParams, convertToNewMultiSelect($multiSelect)),
			planPhoneCalls: function (filters, entity, listView, extraParams) {
				const selected = $multiSelect.selected;
				return planPhonecalls(filters, entity, selected.length || listView.total, selected, extraParams);
			},
			createActivity: function (filters, entity, name, num, extraParams) {
				var opts = {
					customerId: AppService.getCustomerId(),
					filters: filters,
					entity: entity,
					name: name,
					numSelected: num
				};
				if (extraParams) {
					opts.extraParams = extraParams;
				}

				return openCreateActivityModal(opts);
			},
			remove: function (filters, entity, notifyEntity, name, removedCallback, onErrorCallback) {
				const numSelected = $multiSelect.allSelectedFilter
					? $multiSelect.getTotal()
					: $multiSelect.selected.length;
				return remove(
					filters,
					entity,
					notifyEntity,
					name,
					$multiSelect.selected,
					numSelected,
					removedCallback,
					onErrorCallback
				);
			},
			updateAccount: function (filters, entity, extraParams) {
				return updateCompany(filters, entity, extraParams);
			},
			export: function (
				filters,
				columns,
				selectables,
				customfields,
				entity,
				type,
				sortField,
				options,
				justExport
			) {
				return openExportData({
					optionsByType: !selectables && !customfields, // if not provided we can use default
					columns,
					selectables,
					customfields,
					filters,
					entity,
					exportType: type,
					sort: sortField,
					justExport,
					options,
					selectedIds: $multiSelect.selected,
					allSelected: $multiSelect.allSelectedFilter
				});
			},
			updateActivities: function (filters, entity) {
				var opts = {
					customerId: AppService.getCustomerId(),
					filters: filters,
					hideDate: false,
					multiSelect: convertToNewMultiSelect($multiSelect)
				};
				if (entity) {
					opts.entity = entity;
				}
				return openMultiUpdateActivity(opts);
			},
			closeActivities: function (filters, entity) {
				var opts = {
					customerId: AppService.getCustomerId(),
					filters: filters,
					entity: entity,
					multiSelect: convertToNewMultiSelect($multiSelect)
				};
				return openMultiCloseActivity(opts);
			},
			updateAppointments: function (filters, entity) {
				return updateAppointments(filters, entity, convertToNewMultiSelect($multiSelect));
			},
			resetScore: function (filters, entity, extraParams) {
				var opts = {
					customerId: AppService.getCustomerId(),
					filters: filters,
					hideDate: false,
					multiselect: $multiSelect
				};
				if (entity) {
					opts.entity = entity;
				}
				if (extraParams) {
					opts.extraParams = extraParams;
				}

				return openResetLeadMulti(opts);
			},
			sendGroupMail: function (filters, entity) {
				return createMailCampaign(filters, $multiSelect.allSelectedFilter, $multiSelect.selected, entity);
			},
			sendMail: function (filters = {}) {
				return sendMail(
					defaultFilterGetter(FilterHelper.parseFilters(filters, 'contact'), {
						allSelected: !$multiSelect.selected,
						selectedIds: $multiSelect.selected
					})
				);
			},
			addContactsToSegment: function (filters = {}) {
				return addContactsToSegment(
					defaultFilterGetter(FilterHelper.parseFilters(filters, 'contact'), {
						allSelected: !$multiSelect.selected,
						selectedIds: $multiSelect.selected
					})
				);
			},
			anonymize: function (rb) {
				return anonymizeContacts(rb, {
					selected: $multiSelect.selected?.length,
					allSelected: !$multiSelect.selected,
					selectedIds: $multiSelect.selected
				});
			},
			excludeContacts: function (nrOfIds, socialEventId, filter) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					return new Promise(resolve => {
						openModal('Alert', {
							title: 'socialEvent.excludeContacts',
							body: 'socialEvent.excludeContactsFromEvent',
							confirmButtonText: 'default.exclude',
							headerIcon: 'warning',
							onClose: async confirmed => {
								if (confirmed) {
									const res = await MultiActions.customer(
										AppService.getCustomerId()
									).excludeContactsSocialEvent(
										nrOfIds,
										[{ name: 'SocialEvent', value: socialEventId }],
										filter
									);
									resolve(res);
								} else {
									resolve();
								}
							}
						});
					});
				}

				return Tools.$upModal
					.open('warningConfirm', {
						title: 'socialEvent.excludeContacts',
						body: 'socialEvent.excludeContactsFromEvent',
						resolveTrue: 'default.exclude',
						no: 'cancel',
						icon: 'fa-warning'
					})
					.then(function () {
						return MultiActions.customer(AppService.getCustomerId()).excludeContactsSocialEvent(
							nrOfIds,
							[{ name: 'SocialEvent', value: socialEventId }],
							filter
						);
					});
			},
			updateProducts: function (idArray) {
				return openGenericModal({
					Component: window.MultiUpdateProductsModal,
					size: 'sm',
					// Angular only
					className: 'default modal400'
				}).then(function (options) {
					if (Object.keys(options)) {
						var customerId = Tools.AppService.getCustomerId();
						var productsToSave = idArray.map(function (id) {
							return Object.assign({ id: id }, options);
						});
						return Tools.Product.customer(customerId).saveMulti(productsToSave);
					}
				});
			}
		};

		var fixAccountFilter = function (rb) {
			var isExternalAttr = AccountAttributes().attr.isExternal;
			if (!rb.findFilter(isExternalAttr)) {
				rb.addFilter(isExternalAttr, rb.comparisonTypes.Equals, 0);
			}
			return rb;
		};

		typeActions.USERDEFOBJ_CLIENT = {
			updateClient: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					return actionsRuns.updateAccount(rb, 'UserDefinedObject' + udo.id);
				}
			},
			createActivity: {
				title: function () {
					return (
						$translate.instant('default.quickCreate') +
						' ' +
						$translate.instant('default.activity').toLowerCase()
					);
				},
				icon: 'fa-check',
				run: function (listView, filters, rb) {
					return actionsRuns.createActivity(rb, 'UserDefinedObject' + udo.id, udo.name);
				},
				available: function () {
					return (
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						)
					);
				}
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					return exportUdo(listView, filters, rb, columns, opts, justExport);
				}
			},
			remove: {
				title: function () {
					return $translate.instant('default.delete') + ' ' + $translate.instant('default.objects');
				},
				icon: 'fa-trash',
				run: function (listView, filters, rb) {
					return actionsRuns.remove(
						rb,
						'UserDefinedObject' + udo.id,
						'object',
						udo.name,
						multiRemoveCallback.bind(null, listView)
					);
				}
			}
		};

		typeActions.USERDEFOBJ_CONTACT = {
			updateContacts: {
				title: function () {
					return $translate.instant('default.updateContacts');
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return actionsRuns.updateContacts(rb, false, 'UserDefinedObject' + udo.id, null);
				}
			},
			createActivity: {
				title: function () {
					return (
						$translate.instant('default.quickCreate') +
						' ' +
						$translate.instant('default.activity').toLowerCase()
					);
				},
				icon: 'fa-check',
				run: function (listView, filters, rb) {
					return actionsRuns.createActivity(rb, 'UserDefinedObject' + udo.id, udo.name);
				},
				available: function () {
					return (
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						)
					);
				}
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					return exportUdo(listView, filters, rb, columns, opts, justExport);
				}
			},
			remove: {
				title: function () {
					return $translate.instant('default.delete') + ' ' + $translate.instant('default.objects');
				},
				icon: 'fa-trash',
				run: function (listView, filters, rb) {
					return actionsRuns.remove(
						rb,
						'UserDefinedObject' + udo.id,
						'object',
						udo.name,
						multiRemoveCallback.bind(null, listView)
					);
				}
			}
		};

		typeActions.USERDEFOBJ_PROJECT = {
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					return exportUdo(listView, filters, rb, columns, opts, justExport);
				}
			},
			remove: {
				title: function () {
					return $translate.instant('default.delete') + ' ' + $translate.instant('default.objects');
				},
				icon: 'fa-trash',
				run: function (listView, filters, rb) {
					return actionsRuns.remove(
						rb,
						'UserDefinedObject' + udo.id,
						'object',
						udo.name,
						multiRemoveCallback.bind(null, listView)
					);
				}
			}
		};

		typeActions.APPOINTMENT = {
			updateAppointments: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.appointments').toLowerCase()
					);
				},
				icon: 'fa-check',
				run: function (listView, filters, rb) {
					return actionsRuns.updateAppointments(rb, 'Appointment');
				}
			},
			remove: {
				title: function () {
					return $translate.instant('default.removeAppointments');
				},
				icon: 'fa-trash',
				run: function (listView, filters, rb) {
					return actionsRuns.remove(
						rb,
						'Appointment',
						'appointments',
						null,
						multiRemoveCallback.bind(null, listView)
					);
				}
			},
			updateClient: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					return actionsRuns.updateAccount(rb, 'Appointment');
				}
			},
			updateContacts: {
				title: function () {
					return $translate.instant('default.updateContacts');
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return actionsRuns.updateContacts(rb, false, 'Appointment', null);
				}
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					var sorting =
						listView && listView.selectedView
							? listView.selectedView.sorting[0]
							: { attribute: 'description' };
					return actionsRuns.export(
						filters,
						columns,
						null,
						null,
						'Appointment',
						'appointment',
						sorting,
						opts,
						justExport
					);
				}
			}
		};

		typeActions.ACTIVITY = {
			closeActivities: {
				title: function () {
					return $translate.instant('default.closeActivities');
				},
				icon: 'fa-check-square-o',
				run: function (listView, filters, rb) {
					return actionsRuns.closeActivities(rb, 'Activity');
				}
			},
			updateActivities: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.activities').toLowerCase()
					);
				},
				icon: 'Icon Icon-activity',
				run: function (listView, filters, rb) {
					return actionsRuns.updateActivities(rb, 'Activity');
				}
			},
			remove: {
				title: function () {
					return $translate.instant('default.removeActivities');
				},
				icon: 'fa-trash',
				run: function (listView, filters, rb) {
					return actionsRuns.remove(
						rb,
						'Activity',
						'activities',
						null,
						multiRemoveCallback.bind(null, listView)
					);
				}
			},
			updateClient: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					return actionsRuns.updateAccount(rb, 'Activity');
				}
			},
			updateContacts: {
				title: function () {
					return $translate.instant('default.updateContacts');
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return actionsRuns.updateContacts(rb, false, 'Activity', null);
				}
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					var sorting =
						listView && listView.selectedView
							? listView.selectedView.sorting[0]
							: { attribute: 'description' };
					return actionsRuns.export(
						filters,
						columns,
						null,
						null,
						'Activity',
						'activity',
						sorting,
						opts,
						justExport
					);
				}
			}
		};

		typeActions.ACCOUNT = {
			updateContacts: {
				title: function () {
					return $translate.instant('default.updateClientContacts');
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return actionsRuns.updateContacts(fixAccountFilter(rb), true, 'Client', null);
				}
			},
			createActivity: {
				title: function () {
					return (
						$translate.instant('default.quickCreate') +
						' ' +
						$translate.instant('default.activity').toLowerCase()
					);
				},
				icon: 'Icon Icon-activity',
				run: function (listView, filters, rb) {
					return actionsRuns.createActivity(fixAccountFilter(rb), 'Client');
				},
				available: function () {
					return (
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						)
					);
				}
			},
			planPhoneCalls: {
				title: function () {
					return $translate.instant('todo.planPhonecalls');
				},
				icon: 'Icon Icon-phone',
				run: function (listView, filters, rb) {
					return actionsRuns.planPhoneCalls(rb, 'Client', listView);
				},
				available: function () {
					return FeatureHelper.hasSoftDeployAccess('TODO_LIST');
				}
			},
			remove: {
				title: 'default.delete',
				icon: 'fa-trash',
				run: function (listView, filters, rb) {
					return actionsRuns.remove(
						fixAccountFilter(rb),
						'Client',
						'accounts',
						null,
						multiRemoveCallback.bind(null, listView)
					);
				}
			},
			updateAccount: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					return actionsRuns.updateAccount(fixAccountFilter(rb), 'Client');
				}
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					var sorting =
						listView && listView.selectedView ? listView.selectedView.sorting[0] : { attribute: 'name' };
					return actionsRuns.export(
						filters,
						columns,
						null,
						null,
						'Client',
						'client',
						sorting,
						opts,
						justExport
					);
				}
			}
		};

		typeActions.AGREEMENT = {
			updateContacts: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.clientContacts').toLowerCase()
					);
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return actionsRuns.updateContacts(rb, true, 'Agreement', null);
				}
			},
			remove: {
				title: 'default.delete',
				icon: 'fa-trash',
				run: function (listView, filters, rb) {
					return actionsRuns.remove(
						rb,
						'Agreement',
						'agreements',
						null,
						multiRemoveCallback.bind(null, listView)
					);
				}
			},
			createActivity: {
				title: function () {
					return (
						$translate.instant('default.quickCreate') +
						' ' +
						$translate.instant('default.activity').toLowerCase()
					);
				},
				icon: 'Icon Icon-activity',
				run: function (listView, filters, rb) {
					return actionsRuns.createActivity(rb, 'Agreement');
				},
				available: function () {
					return (
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						)
					);
				}
			},
			planPhoneCalls: {
				title: function () {
					return $translate.instant('todo.planPhonecalls');
				},
				icon: 'Icon Icon-phone',
				run: function (listView, filters, rb) {
					return actionsRuns.planPhoneCalls(rb, 'Agreement', listView);
				},
				available: function () {
					return FeatureHelper.hasSoftDeployAccess('TODO_LIST');
				}
			},
			updateAccount: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					return actionsRuns.updateAccount(rb, 'Agreement');
				}
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					var sorting =
						listView && listView.selectedView
							? listView.selectedView.sorting[0]
							: { attribute: 'description' };
					return actionsRuns.export(
						filters,
						columns,
						null,
						null,
						'Agreement',
						'agreement',
						sorting,
						opts,
						justExport
					);
				}
			}
		};

		typeActions.LEAD = {
			updateClientContacts: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.clientContacts').toLowerCase()
					);
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					var extraParams = {
						mysqlEntity: {
							entity: 'Lead',
							idField: 'clientId'
						}
					};
					return actionsRuns.updateContacts(rb, true, 'Client', extraParams);
				}
			},
			updateContacts: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.contacts').toLowerCase()
					);
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					var extraParams = {
						mysqlEntity: {
							entity: 'Lead',
							idField: 'contactId'
						},
						showContact: 'true'
					};
					return actionsRuns.updateContacts(rb, false, 'Contact', extraParams);
				}
			},
			createActivity: {
				title: function () {
					return (
						$translate.instant('default.quickCreate') +
						' ' +
						$translate.instant('default.activity').toLowerCase()
					);
				},
				icon: 'Icon Icon-activity',
				run: function (listView, filters, rb) {
					var extraParams = {
						mysqlEntity: {
							entity: 'Lead',
							idField: 'clientId'
						}
					};

					return actionsRuns.createActivity(rb, 'Client', 'Lead', undefined, extraParams);
				},
				available: function () {
					return (
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						)
					);
				}
			},
			resetScoreClient: {
				title: function () {
					return 'default.resetScore';
				},
				icon: 'fa-thumbs-down',
				run: function (listView, filters, rb) {
					var extraParams = {
						mysqlEntity: {
							entity: 'Lead',
							idField: 'clientId'
						}
					};

					return actionsRuns.resetScore(rb, 'Client', extraParams);
				}
			},
			resetScoreContact: {
				title: function () {
					return 'default.resetScore';
				},
				icon: 'fa-thumbs-down',
				run: function (listView, filters, rb) {
					var extraParams = {
						mysqlEntity: {
							entity: 'Lead',
							idField: 'contactId'
						},
						showContact: 'true'
					};

					return actionsRuns.resetScore(rb, 'Contact', extraParams);
				}
			},
			updateAccount: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					var extraParams = {
						mysqlEntity: {
							entity: 'Lead',
							idField: 'clientId'
						}
					};

					return actionsRuns.updateAccount(rb, 'Client', extraParams);
				}
			},
			exportContacts: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					var sorting =
						listView && listView.selectedView ? listView.selectedView.sorting[0] : { attribute: 'name' };
					var options = {
						extraParams: {
							mysqlEntity: {
								entity: 'Lead'
							},
							showContact: 'true'
						},
						idField: 'contactId'
					};

					return actionsRuns.export(
						filters,
						columns,
						null,
						null,
						'Lead',
						'lead',
						sorting,
						options,
						opts,
						justExport
					);
				}
			},
			exportAccount: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					var sorting =
						listView && listView.selectedView ? listView.selectedView.sorting[0] : { attribute: 'name' };
					var options = {
						extraParams: {
							mysqlEntity: {
								entity: 'Lead'
							}
						}
					};

					return actionsRuns.export(
						filters,
						columns,
						null,
						null,
						'Lead',
						'lead',
						sorting,
						options,
						justExport
					);
				}
			}
		};

		typeActions.CONTACT = {
			updateContacts: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.contacts').toLowerCase()
					);
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return actionsRuns.updateContacts(rb, false, 'Contact', { segment: true });
				}
			},
			planPhoneCalls: {
				title: function () {
					return $translate.instant('todo.planPhonecalls');
				},
				icon: 'Icon Icon-phone',
				run: function (listView, filters, rb) {
					return actionsRuns.planPhoneCalls(rb, 'Contact', listView);
				},
				available: function () {
					return FeatureHelper.hasSoftDeployAccess('TODO_LIST');
				}
			},
			createActivity: {
				title: function () {
					return (
						$translate.instant('default.quickCreate') +
						' ' +
						$translate.instant('default.activity').toLowerCase()
					);
				},
				icon: 'Icon Icon-activity',
				run: function (listView, filters, rb) {
					return actionsRuns.createActivity(rb, 'Contact');
				},
				available: function () {
					return (
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						)
					);
				}
			},
			remove: {
				title: 'default.delete',
				icon: 'fa-trash',
				run: function (listView, filters, rb) {
					return actionsRuns.remove(
						rb,
						'Contact',
						'contacts',
						null,
						multiRemoveCallback.bind(null, listView)
					);
				}
			},
			updateAccount: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					return actionsRuns.updateAccount(rb, 'Contact');
				}
			},
			anonymize: {
				title: 'admin.anonymization.startProcess',
				icon: 'fa-user-secret',
				run: function (listView, filters, rb) {
					return actionsRuns.anonymize(rb);
				},
				available: function () {
					return !!Tools.AppService.getSelf().administrator;
				}
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					var sorting =
						listView && listView.selectedView ? listView.selectedView.sorting[0] : { attribute: 'name' };
					return actionsRuns.export(
						filters,
						columns,
						null,
						null,
						'Contact',
						'contact',
						sorting,
						opts,
						justExport
					);
				}
			},
			sendGroupMail: {
				title: 'default.sendGroupMail',
				icon: 'fa-paper-plane',
				run: function (listView) {
					return actionsRuns.sendGroupMail(listView.filters, 'contact');
				},
				available: function () {
					return FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_MAIL);
				}
			},
			sendMail: {
				title: 'default.sendEmail',
				icon: 'fa-envelope',
				disabledTooltip: 'multi.sendMail.maxContacts',
				run: function (listView) {
					return actionsRuns.sendMail(listView.filters, 'contact');
				},
				available: function () {
					return FeatureHelper.isAvailable(FeatureHelper.Feature.EMAIL);
				},
				disabled: function () {
					return (
						($multiSelect.selected.length ? $multiSelect.selected.length : $multiSelect.getTotal()) > 100
					);
				}
			},
			addContactsToSegment: {
				title: 'default.addToSegment',
				icon: 'fa-code-fork',
				disabledTooltip: 'multi.addContactsToSegment.maxContacts',
				run: function (listView, filters, rb) {
					return actionsRuns.addContactsToSegment(listView.filters, 'contact', rb);
				},
				available: function () {
					return FeatureHelper.isAvailable(FeatureHelper.Feature.SEGMENT);
				},
				disabled: function () {
					return (
						($multiSelect.selected.length ? $multiSelect.selected.length : $multiSelect.getTotal()) > 100
					);
				}
			}
		};

		typeActions.MAIL = {
			createActivity: {
				title: function () {
					return (
						$translate.instant('default.quickCreate') +
						' ' +
						$translate.instant('default.activity').toLowerCase()
					);
				},
				icon: 'Icon Icon-activity',
				run: function (listView, filters, rb) {
					if (listView.mailCampaignId) {
						return actionsRuns.createActivity(rb, 'Mail', undefined, undefined, {
							source: { type: 'mailcampaign', id: listView.mailCampaignId }
						});
					} else {
						return actionsRuns.createActivity(rb, 'Mail');
					}
				},
				available: function () {
					return (
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						)
					);
				}
			},
			planPhoneCalls: {
				title: function () {
					return $translate.instant('todo.planPhonecalls');
				},
				icon: 'Icon Icon-phone',
				run: function (listView, filters, rb) {
					if (listView.mailCampaignId) {
						return actionsRuns.planPhoneCalls(rb, 'Mail', listView, {
							source: { type: 'mailcampaign', id: listView.mailCampaignId }
						});
					} else {
						return actionsRuns.planPhoneCalls(rb, 'Mail', listView);
					}
				},
				available: function () {
					return FeatureHelper.hasSoftDeployAccess('TODO_LIST');
				}
			},
			updateContacts: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.contacts').toLowerCase()
					);
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return actionsRuns.updateContacts(rb, false, 'Mail', null);
				}
			},
			updateAccount: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					return actionsRuns.updateAccount(rb, 'Mail');
				}
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					var sorting = listView && listView.selectedView ? listView.selectedView.sorting[0] : null;
					if (!sorting && rb && rb.sorting && rb.sorting[0]) {
						sorting = rb.sorting[0];
					} else {
						sorting = { attribute: 'date' };
					}
					return actionsRuns.export(filters, columns, null, null, 'Mail', 'mail', sorting, opts, justExport);
				}
			},
			sendGroupMail: {
				title: 'default.sendGroupMail',
				icon: 'fa-paper-plane',
				run: function (listView) {
					return actionsRuns.sendGroupMail(listView ? listView.filters : {}, listView.actionEntity ?? 'mail');
				}
			}
		};

		typeActions.FORMSUBMIT = {
			updateContacts: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.contacts').toLowerCase()
					);
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return actionsRuns.updateContacts(rb, false, 'FormSubmit', null);
				}
			},
			updateAccount: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					return actionsRuns.updateAccount(rb, 'FormSubmit');
				}
			}
		};

		typeActions.VISIT = {
			updateContacts: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.contacts').toLowerCase()
					);
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return actionsRuns.updateContacts(rb, false, 'Visit', null);
				}
			},
			updateAccount: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					return actionsRuns.updateAccount(rb, 'Visit');
				}
			},
			createActivity: {
				title: function () {
					return (
						$translate.instant('default.quickCreate') +
						' ' +
						$translate.instant('default.activity').toLowerCase()
					);
				},
				icon: 'Icon Icon-activity',
				run: function (listView, filters, rb) {
					return actionsRuns.createActivity(rb, 'Visit');
				},
				available: function () {
					return (
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						)
					);
				}
			}
		};

		typeActions.ORDER = {
			updateOrder: {
				title: function () {
					return (
						$translate.instant('default.update') + ' ' + $translate.instant('default.orders').toLowerCase()
					);
				},
				icon: 'fa-dollar',
				run: function (listView, filters, rb) {
					return $upModal.open('updateOrderMulti', {
						customerId: AppService.getCustomerId(),
						entity: 'Order',
						filters: rb
					});
				}
			},
			remove: {
				title: 'default.delete',
				icon: 'fa-trash',
				run: function (listView, filters, rb) {
					return actionsRuns.remove(rb, 'Order', 'orders', null, multiRemoveCallback.bind(null, listView));
				}
			},
			updateAccount: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					return openMultiUpdateClientModal({
						customerId: AppService.getCustomerId(),
						entity: 'Order',
						filters: rb
					});
				}
			},
			updateContacts: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.clientContacts').toLowerCase()
					);
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return openMultiUpdateContact({
						customerId: AppService.getCustomerId(),
						filters: rb,
						entity: 'Order',
						clientContacts: true
					});
				}
			},
			updateContact: {
				title: 'default.updateContacts',
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return openMultiUpdateContact({
						customerId: AppService.getCustomerId(),
						filters: rb,
						entity: 'Order'
					}).then(function () {
						//$scope.$broadcast('listView.refresh', {skipTimeout: true});
					});
				}
			},
			createActivity: {
				title: function () {
					return (
						$translate.instant('default.quickCreate') +
						' ' +
						$translate.instant('default.activity').toLowerCase()
					);
				},
				icon: 'Icon Icon-activity',
				run: function (listView, filters, rb) {
					return actionsRuns.createActivity(rb, 'Order');
				},
				available: function () {
					return (
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						)
					);
				}
			},
			planPhoneCalls: {
				title: function () {
					return $translate.instant('todo.planPhonecalls');
				},
				icon: 'Icon Icon-phone',
				run: function (listView, filters, rb) {
					return actionsRuns.planPhoneCalls(rb, 'Order', listView);
				},
				available: function () {
					return FeatureHelper.hasSoftDeployAccess('TODO_LIST');
				}
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					var sorting =
						listView && listView.selectedView ? listView.selectedView.sorting[0] : { attribute: 'name' };
					return actionsRuns.export(
						filters,
						columns,
						null,
						null,
						'Order',
						'order',
						sorting,
						opts,
						justExport
					);
				}
			}
		};

		typeActions.OPPORTUNITY = {
			// THIS CODE RUNS ONLY IN ADVANCED SEARCH. Opportunity list multi actions are defined in listOpportunity.js
			updateOpportunity: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.opportunities').toLowerCase()
					);
				},
				icon: 'opportunity',
				run: function (listView, filters, rb) {
					return $upModal
						.open('updateOrderMulti', {
							customerId: AppService.getCustomerId(),
							entity: 'Opportunity',
							allStages: true,
							filters: rb
						})
						.then(function () {
							// Update
							//$scope.$broadcast('listView.refresh', {skipTimeout: true});
						});
				}
			},
			remove: {
				title: 'default.delete',
				icon: 'fa-trash',
				run: function (listView, filters, rb) {
					return actionsRuns.remove(
						rb,
						'Opportunity',
						'opportunity',
						null,
						multiRemoveCallback.bind(null, listView)
					);
				}
			},
			updateAccount: {
				title: 'default.updateAccounts',
				icon: 'fa-home',
				run: function (listView, filters, rb) {
					openMultiUpdateClientModal({
						customerId: AppService.getCustomerId(),
						entity: 'Opportunity',
						filters: rb
					});
				}
			},
			updateContacts: {
				title: function () {
					return (
						$translate.instant('default.update') +
						' ' +
						$translate.instant('default.clientContacts').toLowerCase()
					);
				},
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return openMultiUpdateContact({
						customerId: AppService.getCustomerId(),
						entity: 'Opportunity',
						filters: rb,
						clientContacts: true
					});
				}
			},
			updateContact: {
				title: 'default.updateContacts',
				icon: 'fa-user',
				run: function (listView, filters, rb) {
					return openMultiUpdateContact({
						customerId: AppService.getCustomerId(),
						entity: 'Opportunity',
						filters: rb
					}).then(function () {
						// Update
						//$scope.$broadcast('listView.refresh', {skipTimeout: true});
					});
				}
			},
			createActivity: {
				title: function () {
					return (
						$translate.instant('default.quickCreate') +
						' ' +
						$translate.instant('default.activity').toLowerCase()
					);
				},
				icon: 'Icon Icon-activity',
				run: function (listView, filters, rb) {
					return actionsRuns.createActivity(rb, 'Opportunity');
				},
				available: function () {
					return (
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						)
					);
				}
			},
			planPhoneCalls: {
				title: function () {
					return $translate.instant('todo.planPhonecalls');
				},
				icon: 'Icon Icon-phone',
				run: function (listView, filters, rb) {
					return actionsRuns.planPhoneCalls(rb, 'Opportunity', listView);
				},
				available: function () {
					return FeatureHelper.hasSoftDeployAccess('TODO_LIST');
				}
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (listView, filters, rb, columns, opts, justExport) {
					var sorting =
						listView && listView.selectedView ? listView.selectedView.sorting[0] : { attribute: 'name' };
					return actionsRuns.export(
						filters,
						columns,
						null,
						null,
						'Order',
						'opportunity',
						sorting,
						opts,
						justExport
					);
				}
			}
		};

		typeActions.PRODUCT = {
			updateProducts: {
				title: 'admin.products.update',
				icon: 'fa-edit',
				run: actionsRuns.updateProducts
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (
					idArray,
					onlyActive,
					productCategory,
					includeSubscriptionProducts,
					includeOneOffProducts,
					includeBundles
				) {
					var sorting = { attribute: 'name' };
					const filter = { q: [] };
					if (idArray.length) {
						filter.q.push({ a: 'id', c: 'eq', v: idArray });
					} else {
						filter.q.push({ a: 'id', c: 'gt', v: 0 });
					}

					if (!includeBundles) {
						filter.q.push({ a: 'bundlePriceAdjustment', c: 'eq', v: null });
						if (!includeOneOffProducts) {
							filter.q.push({ a: 'isRecurring', c: 'eq', v: 1 });
						}
						if (!includeSubscriptionProducts) {
							filter.q.push({ a: 'isRecurring', c: 'eq', v: 0 });
						}
					} else {
						const orFilter = { q: [] };
						orFilter.q.push([{ a: 'bundlePriceAdjustment', c: 'ne', v: null }]);
						if (includeOneOffProducts) {
							orFilter.q.push([{ a: 'isRecurring', c: 'eq', v: 0 }]);
						}
						if (includeSubscriptionProducts) {
							orFilter.q.push([{ a: 'isRecurring', c: 'eq', v: 1 }]);
						}
						filter.q.push({ or: orFilter });
					}

					if (onlyActive) {
						filter.q.push({ a: 'active', c: 'eq', v: 1 });
					}
					if (productCategory) {
						filter.q.push({ a: 'category.id', c: 'eq', v: productCategory.id });
					}

					return actionsRuns.export(filter, [], null, null, 'Product', 'product', sorting, null, true);
				}
			}
		};

		typeActions.SOCIALEVENT_DRAFT = {
			excludeContacts: {
				title: 'socialEvent.contactControl.excludeContact',
				icon: 'fa-times-circle',
				run: function (num, socialEventId, filter) {
					return actionsRuns.excludeContacts(num, socialEventId, filter).then(function (res) {
						res.postAction = 'excludeContact';
						return res;
					});
				}
			}
		};

		typeActions.SOCIALEVENT_LAUNCHED = {
			createActivity: {
				title: function () {
					return (
						$translate.instant('default.quickCreate') +
						' ' +
						$translate.instant('default.activity').toLowerCase()
					);
				},
				icon: 'fa-check',
				run: function (num, socialEventId, filter) {
					if (socialEventId) {
						return actionsRuns.createActivity(filter, 'Contact', undefined, num, {
							source: { type: 'event', id: socialEventId }
						});
					} else {
						return actionsRuns.createActivity(filter, 'Contact');
					}
				},
				available: function () {
					return (
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						)
					);
				}
			},
			planPhoneCalls: {
				title: function () {
					return $translate.instant('todo.planPhonecalls');
				},
				icon: 'Icon Icon-phone',
				run: function (num, socialEventId, filter) {
					const listView = { total: num };
					if (socialEventId) {
						return actionsRuns.planPhoneCalls(filter, 'Contact', listView, {
							source: { type: 'event', id: socialEventId }
						});
					} else {
						return actionsRuns.planPhoneCalls(filter, 'Contact', listView);
					}
				},
				available: function () {
					return FeatureHelper.hasSoftDeployAccess('TODO_LIST');
				}
			},
			remove: {
				title: 'socialEvent.contactControl.excludeContact',
				icon: 'fa-trash',
				run: function (num, socialEventId, filter) {
					return actionsRuns.excludeContacts(num, socialEventId, filter).then(function (res) {
						res.postAction = 'excludeContact';
						return res;
					});
				}
			},
			export: {
				title: 'default.export',
				icon: 'fa-table',
				run: function (selected, socialEventId, filters, contactSort) {
					var sort = {
						attribute: contactSort.field,
						ascending: contactSort.asc
					};
					return actionsRuns.export(filters.build(), {}, null, null, 'Contact', 'contact', sort, {}, true);
				}
			}
		};

		function exportUdo(listView, filters, rb, columns) {
			var entityNr = udo.id === '' ? 1 : udo.id;
			var selectables = {};

			selectables['UserDefinedObject' + udo.id] = { ...UserDefinedObjectExport };

			_.forEach(noteFields, function (note, nr) {
				++nr;
				selectables['UserDefinedObject' + udo.id]['notes' + nr] = {
					field: 'notes' + nr,
					title: note
				};
			});

			if (link === 1) {
				selectables['UserDefinedObject' + udo.id].campaign = {
					title: 'default.project',
					field: 'project.name'
				};
			} else if (link === 6) {
				selectables['UserDefinedObject' + udo.id].contact = { title: 'default.contact', field: 'contact.name' };
				selectables['UserDefinedObject' + udo.id].client = { title: 'default.account', field: 'client.name' };
			} else {
				selectables['UserDefinedObject' + udo.id].client = { title: 'default.account', field: 'client.name' };
			}
			var customfields = {};
			customfields['UserDefinedObject' + udo.id] = AppService.getCustomFields('userDefined' + entityNr);
			return actionsRuns.export(
				filters,
				columns,
				selectables,
				customfields,
				'UserDefinedObject' + udo.id,
				udo.name,
				listView.selectedView.sorting[0]
			);
		}

		function multiRemoveCallback(listView, removedEntities) {
			listView.removeSelected(removedEntities);
			$rootScope.$broadcast(`${listView.listType}.list.multiRemove`, removedEntities);
			$multiSelect.selectNone();
		}

		return MultiRunnerActions;
	}
]);
