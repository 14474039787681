import React from 'react';
import { Tooltip, Title, Button, InputChip, Flag, Flex } from '@upsales/components';
import PropTypes from 'prop-types';
import openModal from 'App/services/Modal';
import { countries } from 'App/helpers/regionHelpers';
class Integration extends React.Component {
	state = {};

	constructor(props) {
		super(props);

		var t = Tools.$translate;
		this.lang = {
			publisher: t('integration.publisher'),
			description: t('default.description'),
			unknown: t('integration.unknown'),
			requires: t('apps.requires'),
			terms: t('app.terms'),
			buy: t('soliditet.buy'),
			purchased: t('default.purchased'),
			subscriptionEnds: t('default.subscriptionEnds'),
			subscriptionCancelled: t('default.subscriptionCancelled'),
			reportProblem: t('apps.reportProblem'),
			perMonth: t('integration.perMonth'),
			perMonthAndUser: t('apps.perMonthAndUser'),
			fullDescription: t('integration.fullDescription'),
			webPage: t('integration.webPage'),
			integrationIsActive: t('integration.integrationIsActive'),
			integrationIsInactive: t('integration.integrationIsInactive'),
			activate: t('integrations.activate'),
			cancelSubscription: t('integration.cancelSubscription'),
			testAndActivate: t('integrations.testAndActivate'),
			inactivate: t('integration.inactivate'),
			free: t('integration.free'),
			settings: t('integration.settings'),
			log: t('integration.log'),
			support: t('integration.support'),
			acceptTerms: t('integration.acceptTerms'),
			acceptTermsInfo: t('integration.acceptTermsInfo'),
			hasMissingFeatures: t('integration.hasMissingFeatures'),
			integrationIsConfiguredByEachUserHeader: t('integration.integrationIsConfiguredByEachUserHeader'),
			integrationIsConfiguredByEachUserInfo: t('integration.integrationIsConfiguredByEachUserInfo'),
			integrationIsConfiguredByEachUserButton: t('integration.integrationIsConfiguredByEachUserButton'),
			save: t('default.save'),
			testAndSave: t('integrations.testAndSave'),
			abort: t('default.abort'),
			logEmpty: t('integration.logEmpty'),
			failedToLoad: t('integration.failedToLoad'),
			dontHavePermissionsToToggle: t('integration.dontHavePermissionsToToggle'),
			logHeaders: {
				startDate: t('integration.log.startDate'),
				doneDate: t('integration.log.doneDate'),
				type: t('integration.log.type'),
				typeId: t('integration.log.typeId'),
				status: t('integration.log.status'),
				message: t('integration.log.message')
			},
			status: {
				fetching: t('integration.status.fetching'),
				bad: t('integration.status.bad'),
				badDescription: t('integration.status.badDescription'),
				badDescription2: t('integration.status.badDescription2'),
				evenWorse: t('integration.status.evenWorse'),
				evenWorseDescription: t('integration.status.evenWorseDescription'),
				evenWorseDescription2: t('integration.status.evenWorseDescription2'),
				unknown: t('integration.status.unknown'),
				unknownDescription: t('integration.status.unknownDescription'),
				unknownDescription2: t('integration.status.unknownDescription2'),
				good: t('integration.status.good'),
				goodDescription: t('integration.status.goodDescription'),
				goodDescription2: t('integration.status.goodDescription2'),
				devMsg: t('integration.status.devMsg'),
				noDevMsg: t('integration.status.noDevMsg'),
				noDevMsgDescription: t('integration.status.noDevMsgDescription'),
				showLog: t('integration.status.showLog')
			}
		};
	}
	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			var state = this.getStateFromProps(this.props);
			this.setState(state);
		}
	}
	getStateFromProps(props) {
		return {
			active: props.rootData.pageData.active
		};
	}
	getIntegrationLang = (element, type, fallback) => {
		var t = Tools.$translate;
		var tag =
			'integration.' + this.props.rootData.pageData.masterIntegration.langTagPrefix + '.' + element + '.' + type;
		var translated = t(tag);

		if (translated === tag) {
			return fallback;
		}

		return translated;
	};
	changeTab = tab => {
		this.props.rootData.pageData.setTab(tab);
	};
	onFieldChange = (key, e) => {
		var integration = this.state.integration;
		integration[key] = e.target.value;
		this.setState({ integration: integration });
		this.props.rootData.pageData.onChange(integration);
	};
	setPage(item) {
		this.props.rootData.pageData.setPage(item.state, item.stateParams || {});
	}
	// Return true if hex is dark
	isDark(hex) {
		hex = hex.substring(1);
		// We always want 6 digit hex
		if (hex.length === 3) {
			hex = hex + '' + hex;
		}

		var rgb = parseInt(hex, 16); // convert rrggbb to decimal
		var r = (rgb >> 16) & 0xff; // extract red
		var g = (rgb >> 8) & 0xff; // extract green
		var b = (rgb >> 0) & 0xff; // extract blue

		var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

		if (luma < 150) {
			return true;
		}

		return false;
	}
	getLogMessage = message => {
		try {
			const json = JSON.parse(message);
			if (!json) {
				return message;
			}
			return <pre>{JSON.stringify(json, undefined, 2)}</pre>;
		} catch (e) {
			return message;
		}
	};
	getTabClass(tab) {
		var classNames = 'app-tab';
		if (this.props.rootData.pageData.tab === tab) {
			classNames += ' selected-tab';
		} else {
			classNames += ' opacity';
		}
		if (tab === 'log') {
			classNames += ' right';
		}
		return classNames;
	}
	onDeactivate = async () => {
		if (this.props.rootData.pageData.masterIntegration.userConfigurable) {
			openModal('Alert', {
				title: 'alerts.deactivateApp.title',
				body: this.props.rootData.pageData.configureForUser
					? 'alerts.deactivateApp.description.user'
					: 'alerts.deactivateApp.description.client',
				confirmButtonText: 'integration.inactivate',
				headerIcon: 'warning',
				onClose: confirmed => {
					if (confirmed) {
						// If integration id is calendar and we are configuring for a specific user, check if ezbooking is active
						if (
							this.props.rootData.pageData.configureForUser &&
							_.some(Tools.AppService.getCalendarIntegrations(), {
								id: this.props.rootData.pageData.masterIntegration.id
							})
						) {
							const { easyBooking } = Tools.AppService.getSelf().userParams;

							if (easyBooking) {
								Tools.NotificationService.addNotification({
									style: Tools.NotificationService.style.ERROR,
									icon: 'times',
									title: 'default.error',
									body: 'admin.appointmentAvailability.deActivateCalendar.error'
								});
								return;
							}
						}
						this.props.rootData.pageData.onDeactivate();
					}
				}
			});
		} else {
			this.props.rootData.pageData.onDeactivate();
		}
	};
	render() {
		var self = this;
		var tools = this.props.tools;
		var rootData = self.props.rootData;
		var masterIntegration = rootData.pageData.masterIntegration;
		var t = tools.$translate;
		var hostingCountry =
			masterIntegration?.hostingLocation &&
			countries.find(({ code }) => code === masterIntegration.hostingLocation);

		const otherCalendarApp = [...Tools.AppService.getActiveCalendarIntegrations()].find(
			calendarApp => masterIntegration && calendarApp.id !== masterIntegration.id
		);
		const isCalendarAppAndHasCalendarAppInstalled =
			masterIntegration &&
			masterIntegration.standardIntegrationInit.find(
				init => init.type === 'calendar' || init.type === 'legacyCalendar'
			) &&
			otherCalendarApp;

		const otherMailApp = Tools.AppService.getMailIntegration();
		const isMailAppAndHasMailAppInstalled =
			masterIntegration &&
			otherMailApp &&
			_.find(masterIntegration.standardIntegrationInit, { type: 'mail' }) &&
			otherMailApp.id !== masterIntegration.id
				? true
				: false;

		var loader = null;
		var error = null;
		var topBar = null;
		var content = null;
		var supportStatus = null;
		var supportDevMsg = null;
		var supportLog = null;

		var statusStyle = 'status-circle fa fa-circle unknown';
		if (rootData.pageData.status && rootData.pageData.status.status === 1) {
			statusStyle = 'status-circle fa fa-circle good';
		}
		if (rootData.pageData.status && rootData.pageData.status.status < 0) {
			statusStyle = 'status-circle fa fa-circle bad';
		}

		if (rootData.pageData.error) {
			error = (
				<div className="main-section guide-section">
					<Title size="lg" align="center">
						{self.lang.failedToLoad}
					</Title>
				</div>
			);
		} else if (!rootData.pageLoading) {
			var price;
			var hasCost = false;
			var notAvailable = rootData.pageData.notAvailable;
			if (notAvailable) {
				price = <span>{self.lang.unknown}</span>;
			} else if (
				(!rootData.pageData.price && (!masterIntegration.price || masterIntegration.price === '0')) ||
				!masterIntegration.alias
			) {
				price = <span>{self.lang.free}</span>;
			} else if (rootData.pageData.price) {
				hasCost = true;
				price = (
					<span>
						{Tools.$filter('numberFormat')(rootData.pageData.price)}
						<span className="currency opacity">{'SEK'}</span>
					</span>
				);
			} else {
				hasCost = true;
				price = (
					<span>
						{Tools.$filter('numberFormat')(masterIntegration.price)}
						<span className="currency opacity">{'SEK'}</span>
					</span>
				);
			}

			var logoStyle = { backgroundImage: 'url(' + masterIntegration.imageLink + ')' };
			var bg = masterIntegration.color || '#fff';
			var hoverColor;
			var shadow;
			var color = 'green';
			var isDark = self.isDark(bg);

			if (rootData.pageData.active) {
				hoverColor = 'red';
				if (isDark) {
					color = 'white';
				} else {
					shadow = 'none';
					color = 'light-grey';
				}
			}

			var hasMissingFeatures = rootData.pageData.missingFeatures && rootData.pageData.missingFeatures.length;
			var hasPermissions = rootData.pageData.configureForUser || rootData.pageData.isAdmin;
			var activating = rootData.pageData.activating;
			var cancelling = rootData.pageData.cancelling;
			var purchasing = rootData.pageData.purchasing;

			var missingFeatures = _.map(rootData.pageData.missingFeatures || [], function (feature, i) {
				var separator = '';
				if (
					rootData.pageData.missingFeatures.length > 1 &&
					i === rootData.pageData.missingFeatures.length - 2
				) {
					separator = ' & ';
				} else if (
					rootData.pageData.missingFeatures.length > 1 &&
					i < rootData.pageData.missingFeatures.length - 2
				) {
					separator = ', ';
				}
				return (
					<span>
						{t('feature.' + feature).toLowerCase()}
						{separator}
					</span>
				);
			});

			var provisioning = rootData.pageData.provisioning;
			var activePriced = rootData.pageData.active && hasCost;
			var cancelled = provisioning && provisioning.cancelled;
			var endDate = provisioning && provisioning.endDate;

			var showBuyButton = activePriced && (!provisioning || cancelled || endDate);
			var showCancelButton = activePriced && provisioning && !cancelled && !endDate;

			var topBarClass = [];
			if (isDark) {
				topBarClass.push('dark');
			}
			if (this.state.active) {
				topBarClass.push('has-tabs');
			}
			if (['#fff', '#ffffff'].indexOf(masterIntegration.color.toLowerCase()) !== -1) {
				topBarClass.push('white');
			}

			let tooltip = '';
			if (isCalendarAppAndHasCalendarAppInstalled) {
				tooltip = t('integration.isCalendarAppAndHasCalendarAppInstalled', {
					otherApp: otherCalendarApp.name,
					thisApp: masterIntegration.name
				});
			} else if (isMailAppAndHasMailAppInstalled) {
				tooltip = t('integration.isCalendarAppAndHasCalendarAppInstalled', {
					otherApp: otherMailApp.name,
					thisApp: masterIntegration.name
				});
			}

			topBar = (
				<div id="app-overview-header" style={{ backgroundColor: bg }} className={topBarClass.join(' ')}>
					<div className="app-logo" style={logoStyle} />
					<div className="app-title">
						<div className="title">{masterIntegration.name}</div>
						{missingFeatures.length ? (
							<div className="requirements">
								{self.lang.requires} {missingFeatures}
							</div>
						) : null}
					</div>
					<div className="app-description opacity">
						{self.getIntegrationLang('integration', 'description', masterIntegration.description)}
					</div>
					<div className="app-info">
						{!rootData.pageData.isMainApp ? (
							<div>
								<div className="price">{price}</div>
								{hasCost ? <div className="price-info opacity">{self.lang.perMonth}</div> : null}

								{rootData.pageData.active ? (
									<div>
										<Button
											block={true}
											type="lined"
											className="faded"
											color={color}
											shadow="none"
											disabled={!hasPermissions}
											onClick={this.onDeactivate}
										>
											{self.lang.inactivate}
										</Button>
									</div>
								) : (
									<Tooltip title={tooltip}>
										<Button
											block={true}
											shadow={shadow}
											color={color}
											loading={activating}
											hoverColor={hoverColor}
											disabled={
												notAvailable ||
												hasMissingFeatures ||
												!hasPermissions ||
												isCalendarAppAndHasCalendarAppInstalled ||
												isMailAppAndHasMailAppInstalled
											}
											onClick={rootData.pageData.onActivate}
										>
											{hasCost ? self.lang.buy : self.lang.activate}
										</Button>
									</Tooltip>
								)}
								{showCancelButton && (
									<Button
										block={true}
										className="faded"
										shadow={shadow}
										color={color}
										loading={cancelling}
										disabled={!hasPermissions}
										hoverColor={hoverColor}
										onClick={rootData.pageData.onCancel}
									>
										{self.lang.cancelSubscription}
									</Button>
								)}
								{showBuyButton && (
									<Button
										block={true}
										className="faded"
										shadow={shadow}
										color="green"
										disabled={!hasPermissions}
										loading={purchasing}
										onClick={rootData.pageData.onPurchase}
									>
										{self.lang.buy}
									</Button>
								)}
							</div>
						) : null}

						{activePriced && endDate && (
							<div className="app-info-header opacity">
								{self.lang.subscriptionEnds + ': ' + moment(endDate).format('L')}
							</div>
						)}

						<div className="app-info-header opacity">{self.lang.publisher}</div>
						<a href={masterIntegration.url} target="_blank">
							{masterIntegration.publisherName}
						</a>
						<a href={'mailto:' + masterIntegration.supportEmail} target="_blank">
							{self.lang.reportProblem}
						</a>
						<a
							onClick={function (e) {
								e.preventDefault();
								// This opens a modal, catch is for closing
								// eslint-disable-next-line promise/catch-or-return
								rootData.pageData
									.showTerms(!rootData.pageData.active)
									.then(rootData.pageData.onAcceptTerms)
									.then(rootData.pageData.activate)
									.then(() => {
										Tools.CacheRefresher.refresh([Tools.CacheRefresher.types.METADATA]);
									});
							}}
						>
							{self.lang.terms}
						</a>
					</div>

					{this.state.active ? (
						<div className="app-tabs">
							<div
								className={self.getTabClass('description')}
								onClick={self.changeTab.bind(self, 'description')}
								key="description"
							>
								{self.lang.description}
							</div>
							<div
								className={self.getTabClass('settings')}
								onClick={self.changeTab.bind(self, 'settings')}
								key="settings"
							>
								{self.lang.settings}
							</div>
							{rootData.pageData.showSupport ? (
								<div
									className={self.getTabClass('log')}
									onClick={self.changeTab.bind(self, 'log')}
									key="log"
								>
									<i className={statusStyle} /> {self.lang.support}
								</div>
							) : null}
						</div>
					) : null}

					<Flex dir="row" gap="u1" space="mtm">
						{hostingCountry ? (
							<InputChip
								title={t('integrations.hosting.hostedIn', { name: t(hostingCountry.lang) })}
								renderLeft={() => <Flag code={hostingCountry.code.toLowerCase()} />}
							/>
						) : null}
						{masterIntegration.hostingProvider ? (
							<InputChip
								title={t('integrations.hosting.hostedIn', {
									name: t(`integrations.hosting.${masterIntegration.hostingProvider}`)
								})}
							/>
						) : null}
					</Flex>
				</div>
			);

			if (rootData.pageData.missingFeatures && rootData.pageData.missingFeatures.length) {
				missingFeatures = (
					<div className="guide-section">
						<div className="guide-section-header">
							<div className="title">
								<h2>{self.lang.hasMissingFeatures}</h2>
							</div>
						</div>
					</div>
				);
			} else {
				if (self.props.rootData.pageData.tab === 'log') {
					const rows = rootData.pageData.log.map(function (log, i) {
						if (!log) {
							return null;
						}
						return (
							<tr key={'log-' + i}>
								<td>{log.startDate ? moment(log.startDate).format('L LT') : ''}</td>
								<td>
									{''}
									{log.callbackStatus}
								</td>
								<td>{log.initType}</td>
								<td>{self.getLogMessage(log.callbackMessage)}</td>
							</tr>
						);
					});

					if (!rootData.pageData.status) {
						supportStatus = (
							<div className="app-status-info-wrapper">
								<div className="app-status-info">
									<div className="text">
										<h2>{self.lang.status.fetching}</h2>
										<div className="info">
											<i className="fa fa-circle-o-notch fa-spin" />
										</div>
									</div>
								</div>
							</div>
						);
					}

					if (rootData.pageData.status && rootData.pageData.status.status === 0) {
						supportStatus = (
							<div className="app-status-info-wrapper">
								<div className="app-status-info">
									<div className="text">
										<h2>{self.lang.status.unknown}</h2>
										<div className="info">
											<p>{self.lang.status.unknownDescription}</p>
											<p>{self.lang.status.unknownDescription2}</p>
										</div>
									</div>
									<div className="illustration unknown">
										<div className="img unknown" />
									</div>
								</div>
							</div>
						);
					}

					if (rootData.pageData.status && rootData.pageData.status.status === -1) {
						supportStatus = (
							<div className="app-status-info-wrapper">
								<div className="app-status-info">
									<div className="text">
										<h2>{self.lang.status.bad}</h2>
										<div className="info">
											<p>{self.lang.status.badDescription}</p>
											<p>
												{rootData.pageData.status.message
													? self.lang.status.badDescription2
													: null}
											</p>
										</div>
									</div>
									<div className="illustration bad">
										<div className="img bad" />
									</div>
								</div>
							</div>
						);
					}

					if (rootData.pageData.status && rootData.pageData.status.status === -2) {
						supportStatus = (
							<div className="app-status-info-wrapper">
								<div className="app-status-info">
									<div className="text">
										<h2>{self.lang.status.evenWorse}</h2>
										<div className="info">
											<p>{self.lang.status.evenWorseDescription}</p>
											<p>{self.lang.status.evenWorseDescription2}</p>
										</div>
									</div>
									<div className="illustration bad">
										<div className="img bad" />
									</div>
								</div>
							</div>
						);
					}

					if (rootData.pageData.status && rootData.pageData.status.status === 1) {
						supportStatus = (
							<div className="app-status-info-wrapper">
								<div className="app-status-info">
									<div className="text">
										<h2>{self.lang.status.good}</h2>
										<div className="info">
											<p>{self.lang.status.goodDescription}</p>
											<p>
												{rootData.pageData.status.message
													? self.lang.status.goodDescription2
													: null}
											</p>
										</div>
									</div>
									<div className="illustration good">
										<div className="img good" />
									</div>
								</div>
							</div>
						);
					}

					if (rootData.pageData.status && rootData.pageData.status.message) {
						var devMsgMd = { __html: markdown.toHTML(rootData.pageData.status.message) };
						supportDevMsg = (
							<div className="app-dev-info-wrapper">
								<div className="app-dev-msg">
									<div className="text">
										<h2>{self.lang.status.devMsg}</h2>
										<div className="info">
											<div dangerouslySetInnerHTML={devMsgMd} />
										</div>
									</div>
								</div>
							</div>
						);
					}

					if (!rootData.pageData.showLog && rootData.pageData.isAdmin) {
						supportLog = (
							<div className="showLogLink">
								<a onClick={rootData.pageData.onShowLog}>{self.lang.status.showLog}</a>
							</div>
						);
					} else if (rootData.pageData.showLog) {
						supportLog = (
							<div className="app-dev-info-wrapper">
								<div className="app-dev-msg app-dev-msg-table">
									<div className="text">
										<h2>{self.lang.log}</h2>
										<div className="info" />
										<div className="table">
											<table id="log-table" className="main-table table-striped">
												<thead className="white-header">
													<th width="150">{self.lang.logHeaders.startDate}</th>
													<th width="70">{self.lang.logHeaders.status}</th>
													<th width="150">{self.lang.logHeaders.type}</th>
													<th>{self.lang.logHeaders.message}</th>
												</thead>
												<tbody>
													{rows.length ? (
														rows
													) : (
														<tr className="no-result-row">
															<td colSpan="6">{self.lang.logEmpty}</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						);
					}

					content = null;
				} else if (self.props.rootData.pageData.tab === 'settings') {
					var userConfigureInfo = null;
					var fields = null;
					var appItem = {
						state: 'integration',
						stateParams: {
							tab: null,
							id: rootData.pageData.masterIntegration.id.toString(),
							configure: 'user'
						}
					};

					if (!rootData.pageData.configureForUser && rootData.pageData.masterIntegration.userConfigurable) {
						userConfigureInfo = (
							<div className="user-config-info-wrapper">
								<div className="user-config-info">
									<div className="text">
										<h2>
											{masterIntegration.name} {self.lang.integrationIsConfiguredByEachUserHeader}
										</h2>
										<div className="info">{self.lang.integrationIsConfiguredByEachUserInfo}</div>
										<div className="button" onClick={self.setPage.bind(self, appItem)}>
											<a>{self.lang.integrationIsConfiguredByEachUserButton}</a>
										</div>
									</div>
									<div className="illustration">
										<div className="img" />
									</div>
								</div>
							</div>
						);
					}

					if (rootData.pageData.active && (rootData.pageData.isNew || rootData.pageData.configure)) {
						fields = (
							<div>
								<div className="app-fields">
									{React.createElement(ReactTemplates.admin.standardIntegrations.fields, {
										fields: rootData.pageData.fields,
										config: rootData.pageData.config,
										integration: rootData.pageData.masterIntegration,
										onChange: rootData.pageData.onFieldChange,
										gettingOauth: rootData.pageData.gettingOauth,
										oauthErr: rootData.pageData.oauthErr,
										oauth: rootData.pageData.oauth,
										gettingOauthErr: rootData.pageData.gettingOauthErr,
										onRequestOauth: rootData.pageData.onRequestOauth,
										onRequestOauth2: rootData.pageData.onRequestOauth2,
										getIntegrationLang: self.getIntegrationLang,
										tools: tools,
										onBtnClick: rootData.onButtonClick
									})}
								</div>
							</div>
						);
					}

					content = (
						<div>
							{userConfigureInfo}
							{fields}
						</div>
					);
				}
			}
		}

		var innerMd = { __html: masterIntegration ? markdown.toHTML(masterIntegration.descriptionLong) : null };
		var appMd = masterIntegration ? (
			<div
				className="app-md-wrapper"
				style={{ display: self.props.rootData.pageData.tab === 'description' ? 'block' : 'none' }}
			>
				<div className="app-markdown" dangerouslySetInnerHTML={innerMd} />
			</div>
		) : null;

		return (
			<div id="app-overview">
				{topBar}

				<div id="configure-integration">
					{loader}
					{error}
					{missingFeatures}
					{appMd}
					{supportStatus}
					{supportDevMsg}
					{supportLog}
					{content}
				</div>
			</div>
		);
	}
}
Integration.propTypes = {
	rootData: PropTypes.shape({
		pageData: PropTypes.shape({
			onFieldChange: PropTypes.func.isRequired,
			onChange: PropTypes.func,
			onDeactivate: PropTypes.func.isRequired,
			masterIntegration: PropTypes.shape({
				id: PropTypes.number.isRequired,
				langTagPrefix: PropTypes.string.isRequired,
				standardIntegrationInit: PropTypes.array,
				name: PropTypes.string.isRequired,
				description: PropTypes.string,
				color: PropTypes.string.isRequired,
				userConfigurable: PropTypes.bool
			}),
			tab: PropTypes.string,
			active: PropTypes.bool,
			configureForUser: PropTypes.bool,
			setTab: PropTypes.func.isRequired,
			setPage: PropTypes.func.isRequired
		})
	}),
	tools: PropTypes.shape({})
};
export default Integration;
