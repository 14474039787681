'use strict';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

angular.module('domain.activity').controller('openCall', [
	'$upModal',
	'AppService',
	'$modalParams',
	'URL',
	'API',
	function ($upModal, AppService, $modalParams, URL, API) {
		var PhoneCall = this;

		PhoneCall.callDate = moment($modalParams.date).format('DD MMMM YYYY - HH:mm');
		PhoneCall.user = $modalParams.user ? $modalParams.user : null;
		PhoneCall.client = $modalParams.client ? $modalParams.client : null;
		PhoneCall.contact = $modalParams.contact ? $modalParams.contact : null;
		PhoneCall.phoneNumber = $modalParams.phoneNumber ? $modalParams.phoneNumber : '';
		PhoneCall.durationInS = $modalParams.durationInS ? $modalParams.durationInS : 0;
		PhoneCall.related = $modalParams.related ? $modalParams.related : null;
		PhoneCall.activities = {};
		PhoneCall.src = null;

		PhoneCall.generateRelatedLink = function (item) {
			if (!item) {
				return;
			}
			switch (item.type) {
				case 'Activity':
					return $upModal.open('editActivity', { id: item.id });
				case 'Order':
					return $upModal.open('editOrder', { id: item.id });
				case 'Appointment':
					return openEditAppointment({ id: item.id });
				case 'Opportunity':
					return $upModal.open('editOrder', { id: item.id });
			}
		};

		var init = function () {
			var integration = AppService.getPhoneIntegration();
			PhoneCall.customerId = AppService.getCustomerId();
			PhoneCall.activities.hasActivity = $modalParams.meta?.data.hasActivity;
			PhoneCall.activities.hadActivity = $modalParams.meta?.data.hadActivity;
			PhoneCall.activities.hasOpportunity = $modalParams.meta?.data.hasOpportunity;
			PhoneCall.activities.hadOpportunity = $modalParams.meta?.data.hadOpportunity;
			PhoneCall.activities.hasOrder = $modalParams.meta?.data.hasOrder;
			PhoneCall.activities.hadOrder = $modalParams.meta?.data.hadOrder;
			PhoneCall.callObj = PhoneCall.contact || PhoneCall.client;

			if (PhoneCall.callObj) {
				PhoneCall.callObj.phone = PhoneCall.phoneNumber;
			}
			PhoneCall.callType = PhoneCall.contact ? 'contact' : 'client';
			if (integration && integration.capabilities && integration.capabilities.noRecordings) {
				return;
			}
			if ($modalParams.conversationUrl) {
				PhoneCall.src =
					URL +
					API +
					'function/voice/recording/?id=' +
					$modalParams.conversationUrl +
					'&type=recording&integrationId=' +
					integration.id;
			}
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);
