import TextInput from './Input/Text';
import NumberInput from './Input/Number';
import TimeInput from './Input/Time';
import LookupInput from './Input/Lookup';
import BinaryInput from './Input/Binary';
import DateInput from './Input/DateInput';
import OrderInput from './Input/Order';
import AgreementInput from './Input/Agreement';
import OpportunityInput from './Input/Opportunity';
import ActivityInput from './Input/Activity';
import AppointmentInput from './Input/Appointment';
import MailCampaignInput from './Input/MailCampaign';
import VisitInput from './Input/Visit';
import FormSubmitInput from './Input/FormSubmit';
import List from './Input/List';
import Segment from './Input/Segment';
import SocialEventInput from './Input/SocialEvent';
import AddressInput from './Input/Address';
import ProspectingFinancialInput from './Input/Prospecting/Financials';
import ProspectingEmployeeInput from './Input/Prospecting/Employees';
import ProspectingIndustryInput from './Input/Prospecting/Industry';
import ProspectingAddressGBInput from './Input/Prospecting/AddressGB';
import ProspectingAddressNOInput from './Input/Prospecting/AddressNO';
import ProspectingIndustryCPVInput from './Input/Prospecting/IndustryCPV';
import React from 'react';

const typeMap = {
	text: TextInput,
	range: NumberInput,
	lookUp: LookupInput,
	radio: BinaryInput,
	order: OrderInput,
	agreement: AgreementInput,
	opportunity: OpportunityInput,
	activity: ActivityInput,
	appointment: AppointmentInput,
	mailCampaign: MailCampaignInput,
	visit: VisitInput,
	formSubmit: FormSubmitInput,
	dateRange: DateInput,
	list: List,
	listLazy: List,
	listShortSingle: BinaryInput,
	time: TimeInput,
	segment: Segment,
	socialEvent: SocialEventInput,
	address: AddressInput,
	prospectingFinancials: ProspectingFinancialInput,
	prospectingEmployees: ProspectingEmployeeInput,
	prospectingIndustry: ProspectingIndustryInput,
	prospectingAddressGB: ProspectingAddressGBInput,
	prospectingAddressNO: ProspectingAddressNOInput,
	prospectingIndustryCPV: ProspectingIndustryCPVInput
};

type Props = {
	type: keyof typeof typeMap;
	config: { component?: string | ((opts: { isSegment: boolean }) => string) };
	filter: { locked: boolean };
};

export default function InputType(props: Props): JSX.Element {
	let Component: React.ElementType = typeMap[props.type];

	if (props.config.component) {
		var c = props.config.component;
		if (typeof c === 'function') {
			c = c({ isSegment: true });
		}
		Component = window[c as any] as unknown as React.ElementType;
	}

	if (!Component) {
		throw new Error(`No input type found for: ${props.type}`);
	}

	return <Component {...props} locked={props.filter.locked} />;
}
