import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import store from 'Store';

import { retrieveData, loadLanguage, clearState } from 'Store/reducers/BillingReducer';
import { reactRouteCompatibility } from 'App/helpers/angularRouteHelpers';
import BemClass from '@upsales/components/Utils/bemClass';
import './BillingRoot.scss';

const Billing = lazy(() => import('./Billing'));

export default class BillingRoot extends React.Component {
	constructor(props) {
		super(props);

		const languageObject = {
			none: Tools.$translate('none'),
			pleaseNote: Tools.$translate('default.pleaseNote'),
			pleaseNoteText: Tools.$translate('admin.billing.pleaseNoteText'),
			credits: Tools.$translate('mail.credits'),
			credit: Tools.$translate('soliditet.credit'),
			billing: Tools.$translate('admin.billing'),
			managePlan: Tools.$translate('admin.billing.managePlan'),
			pickPlan: Tools.$translate('admin.billing.pickPlan'),
			buyUpsales: Tools.$translate('admin.billing.buyUpsales'),
			cancelUpsales: Tools.$translate('admin.billing.cancelUpsales'),
			yourPlan: Tools.$translate('admin.billing.yourPlan'),
			amount: Tools.$translate('admin.billing.amount'),
			billingPeriod: Tools.$translate('admin.billing.billingPeriod'),
			nextBilling: Tools.$translate('admin.billing.nextBilling'),
			nextBillingAmount: Tools.$translate('admin.billing.nextBillingAmount'),
			monthlyCost: Tools.$translate('admin.billing.monthlyCost'),
			oneTimeCost: Tools.$translate('admin.billing.oneTimeCost'),
			licencesToAdd: Tools.$translate('admin.billing.licencesToAdd'),
			licenceCost: Tools.$translate('admin.billing.licenceCost'),
			licenceAgreement: Tools.$translate('admin.billing.licences.agreement'),
			licencesUsed: Tools.$translate('admin.billing.licences.used'),
			licence: Tools.$translate('default.licence'),
			licences: Tools.$translate('admin.licences'),
			contactsUsed: Tools.$translate('admin.billing.contacts.used'),
			subscription: Tools.$translate('agreement'),
			period: {
				month: Tools.$translate('admin.billing.period.month'),
				quarter: Tools.$translate('admin.billing.period.quarter'),
				halfyear: Tools.$translate('admin.billing.period.halfyear'),
				year: Tools.$translate('admin.billing.period.year')
			},
			addon: {
				upsales_tv: Tools.$translate('admin.billing.addon.upsalesTV'),
				extra_seats: Tools.$translate('admin.billing.addon.extraSeats'),
				extra_contacts: Tools.$translate('admin.billing.addon.extraContacts'),
				looker: Tools.$translate('admin.billing.addon.looker')
			},
			days: Tools.$translate('days'),
			trialPart1: Tools.$translate('admin.billing.trial.part1'),
			trialPart2: Tools.$translate('admin.billing.trial.part2'),
			trialPart3: Tools.$translate('admin.billing.trial.part3'),
			addSeats: Tools.$translate('admin.billing.addSeats'),
			addContacts: Tools.$translate('admin.billing.addContacts'),
			purchase: Tools.$translate('default.purchase'),
			confirmPurchase: Tools.$translate('default.confirmPurchase'),
			plan: Tools.$translate('admin.billing.plan'),
			activeAddons: Tools.$translate('admin.billing.activeAddons'),
			quantity: Tools.$translate('default.quantity'),
			price: Tools.$translate('default.price'),
			total: Tools.$translate('default.total'),
			totalCost: Tools.$translate('admin.billing.totalCost'),
			totalCost2: Tools.$translate('admin.billing.totalCost2'),
			costPerCredit: Tools.$translate('admin.billing.costPerCredit'),
			creditsToAdd: Tools.$translate('admin.billing.creditsToAdd'),
			creditsSmallestAmount: Tools.$translate('admin.billing.creditsSmallestAmount'),
			change: Tools.$translate('default.change'),

			download: Tools.$translate('landingpage.clickToDownload'),
			billingMethodHistory: Tools.$translate('admin.billing.billingMethodHistory'),
			billingMethod: Tools.$translate('admin.billing.billingMethod'),
			expires: Tools.$translate('default.expires'),
			date: Tools.$translate('default.date'),
			description: Tools.$translate('default.description'),
			type: Tools.$translate('default.type'),
			successful: Tools.$translate('default.successful'),
			failed: Tools.$translate('default.failed'),
			succeeded: Tools.$translate('default.succeeded'),
			charge: Tools.$translate('default.charge'),
			payment: Tools.$translate('default.payment'),
			adjustment: Tools.$translate('default.adjustment'),
			name: Tools.$translate('column.name'),
			payedAmount: Tools.$translate('admin.billing.payedAmount'),
			pastDue: Tools.$translate('admin.billing.pastDue'),
			current: Tools.$translate('admin.billing.current'),
			status: Tools.$translate('admin.billing.status'),
			incomingBalance: Tools.$translate('admin.billing.incomingBalance'),
			headerDescription: Tools.$translate('admin.billing.headerDescription'),
			addCredits: Tools.$translate('admin.billing.addCredits'),
			billingAddress: Tools.$translate('address.invoice'),
			sendInvoiceTo: Tools.$translate('admin.billing.invoiceSentTo'),
			month: Tools.$translate('date.month')
		};

		store.dispatch(clearState());
		store.dispatch(loadLanguage(languageObject));
		store.dispatch(retrieveData());
	}

	render() {
		return (
			<Suspense fallback={<div />}>
				<Provider store={store}>
					<Billing props={this.props} />
				</Provider>
			</Suspense>
		);
	}
}

const WrappedBillingRoot = props => {
	const classes = new BemClass('BillingRoot');

	return (
		<div className={classes.b()} id="admin" key={props?.initialTab}>
			<BillingRoot {...props} />
		</div>
	);
};

export const PortedAdminBilling = reactRouteCompatibility(WrappedBillingRoot, {
	featureFlag: 'PORTED_ROUTE_ADMIN_BILLING',
	onMount: ({ params }) => {
		return { initialTab: params.initialTab };
	},
	wrapInPage: false
});
