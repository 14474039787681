import { handleIntegrationIframeMessage, nameRequiredEvents, postEventToFrame } from 'App/helpers/uiElementsHelper';
import { openAppWidgetModal } from 'Components/Modals/AppWidgetModal/AppWidgetModal';
import _ from 'lodash';

angular.module('upDirectives').directive('uiElements', [
	'StandardIntegration',
	'AppService',
	'URL',
	'API',
	'$upModal',
	'$state',
	'$translate',
	'ScriptService',
	function (StandardIntegration, AppService, URL, API, $upModal, $state, $translate, ScriptService) {
		return {
			restrict: 'A',
			link: function ($scope, $elem, $attrs) {
				var iframes;

				var getObject = function () {
					return $scope.$eval($attrs.object);
				};

				var getUtils = function () {
					return $scope.$eval($attrs.utils);
				};

				var getUser = function () {
					var self = AppService.getSelf();
					return { id: self.id, name: self.name };
				};

				var openAppAdmin = function (integrationId, tab, forUser) {
					$state.go('administration.integration', {
						tab: tab ? tab : null,
						id: integrationId,
						customerId: AppService.getCustomerId(),
						configure: forUser ? 'user' : 'client'
					});
				};

				var iframeMessage = function (elements, e) {
					// Try to figure out integration id by name from the event if this was provided
					// If we got a name but can not find a config, or if we find multiple configs with same name, we skip to run the callback
					let integrationId = null;
					if (e.data && nameRequiredEvents.includes(e.data[0])) {
						const name = e.data[e.data.length - 1];
						const res = elements.filter(conf => conf.name === name);
						if (res.length > 1) {
							return;
						}
						integrationId = res[0]?.integrationId || null;
					}

					handleIntegrationIframeMessage($attrs.type, integrationId, getObject, getUtils(), e);
				};

				var clickWidgetRow = function (element, widget, widgetLoading, div, text, elementConfig, name, obj) {
					text.html('');
					text.append('<i class="fa fa-spin fa-circle-o-notch"></i>');
					div.children().attr('disabled', '');
					var data = {
						type: 'widgetRow',
						data: { object: obj, user: getUser() },
						typeId: name,
						integrationId: elementConfig.integrationId
					};
					StandardIntegration.data(AppService.getCustomerId())
						.run(data)
						.then(function (res) {
							div.html('').append(
								renderWidgetRow(element, widget, widgetLoading, elementConfig, obj, res.data)
							);
						})
						.catch(function () {
							div.html('<span class="widget-errorRow">Failed loading app row</span>');
						});
				};

				function renderWidgetRow(element, widget, widgetLoading, elementConfig, obj, row) {
					var html;
					switch (row.type) {
						case 'iframe':
							var classes = 'widget-iframe-wrapper';
							if (row.noMargin) {
								classes += ' widget-iframe-wrapper-no-margin';
							}
							var iframeWrapper = angular.element('<div class="' + classes + '"></div>');
							var iframe = angular.element(
								'<iframe sandbox="allow-scripts" name="ui-element-' +
									elementConfig.integrationId +
									'-' +
									(row.id || '0') +
									'" class="widget-iframe"></iframe>'
							);
							if (row.url) {
								iframe.attr('src', row.url);
							} else if (row.html) {
								iframe.attr('srcdoc', row.html);
							}
							if (row.height && typeof row.height === 'number') {
								iframe.css('height', row.height + 'px');
							}
							if (row.width && typeof row.width === 'number') {
								iframe.css('width', row.width + 'px');
							}
							if (row.scrolling === false) {
								row.scrolling = 'no';
							}
							iframe.attr('scrolling', row.scrolling || 'auto');
							iframeWrapper.append(iframe);
							return iframeWrapper;
						case 'markdown':
							var mdWrapper = angular.element('<div class="widget-md-wrapper"></div>');
							var htmlStr = markdown.toHTML(row.markdown).replace(/<a/g, '<a target="_blank"');
							var md = angular.element('<div class="widget-md"></div>').html(htmlStr);
							if (row.style) {
								md.addClass(row.style);
							}
							mdWrapper.append(md);
							return mdWrapper;
						case 'text':
							var strWrapper = angular.element('<div class="widget-text-wrapper"></div>');
							var str = angular.element('<span class="widget-text"></span>').text(row.text);
							if (row.translateKey) {
								str.append($translate.instant(row.translateKey));
							}
							var icon;
							if (row.icon && row.icon.indexOf('http') !== 0) {
								icon = angular.element('<i class="str-icon fa"></i>').addClass(row.icon);
							} else if (row.icon) {
								icon = angular.element('<img class="str-icon" />').attr('src', row.icon);
							}
							if (row.style) {
								str.addClass(row.style);
							}
							if (row.click) {
								str.addClass('clickable');
								str.on('click', function ($event) {
									if ($event && $event.stopPropagation) {
										$event.stopPropagation();
									}
									if (row.click.type === 'widgetRow') {
										return clickWidgetRow(
											element,
											widget,
											widgetLoading,
											html,
											undefined,
											elementConfig,
											row.click.name,
											obj
										);
									}
									clickWidget(element, widget, widgetLoading, row, elementConfig);
								});
							}
							if (icon) {
								str.prepend(icon);
							}
							strWrapper.append(str);
							return strWrapper;

						case 'image':
							var imgWrapper = angular.element('<div class="widget-image"></div>');
							var img = angular.element('<img />').attr('src', row.url);
							html = angular.element('<div></div>');
							if (row.center) {
								imgWrapper.addClass('image-center');
							}
							if (row.click) {
								img.addClass('clickable');
								img.on('click', function ($event) {
									if ($event && $event.stopPropagation) {
										$event.stopPropagation();
									}
									if (row.click.type === 'widgetRow') {
										return clickWidgetRow(
											element,
											widget,
											widgetLoading,
											html,
											img,
											elementConfig,
											row.click.name,
											obj
										);
									}
									clickWidget(element, widget, widgetLoading, row, elementConfig);
								});
							}
							imgWrapper.append(img);
							html.append(imgWrapper);
							return html;

						case 'buttons':
							var btnWrapper = angular.element('<div class="widget-buttons-wrapper"></div>');
							_.forEach(row.options, function (button) {
								var btn = angular.element(
									'<button class="btn up-btn no-shadow btn-lined btn-xs" type="button" bs-tooltip data-title="LOL"></button>'
								);
								var icon = '';
								var text = angular.element('<span></span>').text(button.text);

								if (button.color) {
									btn.addClass('btn-' + button.color);
								} else {
									btn.addClass('btn-bright-blue');
								}

								if (button.fullWidth) {
									btn.addClass('btn-block');
								} else {
									btn.addClass('multi');
								}

								if (button.link) {
									btn.addClass('btn-left').addClass('btn-link').removeClass('btn-lined');
								}
								if (button.left) {
									btn.addClass('btn-left');
								}

								btn.attr('data-title', 'TOOLTIP');

								if (button.icon && button.icon.indexOf('http') !== 0) {
									icon = angular.element('<i class="btn-icon fa"></i>').addClass(button.icon);
								} else if (button.icon) {
									icon = angular.element('<img />').attr('src', button.icon);
								}

								if (button.click) {
									btn.on('click', function ($event) {
										if ($event && $event.stopPropagation) {
											$event.stopPropagation();
										}
										if (button.click.type === 'widgetRow') {
											return clickWidgetRow(
												element,
												widget,
												widgetLoading,
												btnWrapper,
												text,
												elementConfig,
												button.click.name,
												obj
											);
										}
										clickWidget(element, widget, widgetLoading, button, elementConfig);
									});
								}

								btn.append(icon).append(text);
								btnWrapper.append(btn);
							});
							return btnWrapper;

						case 'dropdown':
							var dropdown = angular.element('<div class="btn-group btn-block dropdown"></div>');
							var dropdownMenu = angular.element('<ul class="dropdown-menu up-dropdown-white"></ul>');
							var dropdownButton = angular.element(
								'<button data-toggle="dropdown" type="button" class="btn btn-block up-btn btn-xs btn-bright-blue no-shadow btn-lined dropdown-toggle"></button>'
							);
							dropdownButton.append(angular.element('<span></span>').text(row.text));
							dropdownButton.append(angular.element('<b class="fa fa-caret-down"></b>'));

							_.forEach(row.options, function (action) {
								var act = angular
									.element('<li></li>')
									.append(angular.element('<a></a>').text(action.text));
								act.on('click', function ($event) {
									if ($event && $event.stopPropagation) {
										$event.stopPropagation();
									}
									if (action.click.type === 'widgetRow') {
										return clickWidgetRow(
											element,
											widget,
											widgetLoading,
											dropdown,
											dropdownButton,
											elementConfig,
											action.click.name,
											obj
										);
									}
									clickWidget(element, widget, widgetLoading, action, elementConfig);
								});
								dropdownMenu.append(act);
							});

							return dropdown.append(dropdownButton).append(dropdownMenu);
					}
				}

				var renderWidget = function (element, elementConfig, name, widget, widgetLoading) {
					var theWidget = function (element, elementConfig, name, widget, widgetLoading, obj, data) {
						var icon = angular.element('<div class="integration-icon"></div>');
						widget = angular.element('<div class="ui-element-widget"></div>');
						if (data.click) {
							widget.addClass('clickable');
							widget.on('click', function ($event) {
								if ($event && $event.stopPropagation) {
									$event.stopPropagation();
								}
								clickWidget(element, widget, widgetLoading, data, elementConfig);
							});
						}
						if (data.icon) {
							if (data.icon.indexOf('http') === 0) {
								icon.append(angular.element('<img class="integration-icon" />').attr('src', data.icon));
							} else {
								icon.append(angular.element('<span></span>').text(data.icon));
							}
							widget.append(icon);
						}

						_.forEach(data.rows, function (row) {
							widget.append(renderWidgetRow(element, widget, widgetLoading, elementConfig, obj, row));
						});

						widgetLoading.hide();
						element.html(widgetLoading).append(widget);
					};

					element.append(widgetLoading);
					var obj = getObject();
					var data = {
						type: 'widget',
						data: { object: obj, user: getUser(), widgetType: elementConfig.widgetType },
						typeId: name,
						integrationId: elementConfig.integrationId
					};
					StandardIntegration.data(AppService.getCustomerId())
						.run(data)
						.then(function (res) {
							theWidget(element, elementConfig, name, widget, widgetLoading, obj, res.data);
						})
						.catch(function (e) {
							var errorWidget;

							if (e.status === 404) {
								if ($attrs.type !== 'modal') {
									return element.html('').hide();
								}
								errorWidget = {
									rows: [
										{
											type: 'text',
											text: $translate.instant('integration.widgetNoMoreInfo')
										}
									]
								};
							} else {
								errorWidget = {
									rows: [
										{
											type: 'buttons',
											options: [
												{
													text:
														elementConfig.integrationName +
														$translate.instant('integration.trouble') +
														' ' +
														$translate.instant('integration.troubleRetry'),
													color: 'grey',
													link: true,
													click: {
														type: 'widget',
														name: name
													}
												}
											]
										},
										{
											type: 'buttons',
											options: [
												{
													text: $translate.instant('integration.checkStatusAndRetry'),
													link: true,
													color: 'grey',
													click: {
														type: 'navigate',
														to: 'config',
														tab: 'support'
													}
												}
											]
										}
									]
								};
							}
							theWidget(element, elementConfig, name, widget, widgetLoading, obj, errorWidget);
						});
				};

				function clickWidget(element, widget, widgetLoading, data, elementConfig) {
					switch (data.click.type) {
						case 'widget':
							widgetLoading.show();
							renderWidget(element, elementConfig, data.click.name, widget, widgetLoading);
							break;
						case 'modal':
							openAppWidgetModal({
								fullscreen: !!data.click.fullscreen,
								obj: getObject(),
								integrationId: elementConfig.integrationId,
								title: elementConfig.integrationName,
								name: data.click.name,
								size: data.click.size
							});
							break;
						case 'closeModal':
							document.querySelector('.up-modal-curtain')?.click();
							break;
						case 'window':
							if (data.click.window === 'self') {
								window.open(data.click.url, '_self');
							} else {
								window.open(data.click.url, '_blank');
							}
							break;
						case 'reload':
							renderWidget(element, elementConfig, elementConfig.name, widget, widgetLoading);
							break;
						case 'navigate':
							if (data.click.to === 'config') {
								openAppAdmin(elementConfig.integrationId.toString(), data.click.tab, data.click.user);
							} else if (ScriptService.open[data.click.to]) {
								ScriptService.open[data.click.to](data.click.id, data.click.tab);
							}
							break;
						case 'create':
							if (ScriptService.create[data.click.entity]) {
								ScriptService.create[data.click.entity](data.click.options);
							}
							break;
					}
				}

				var renderElement = function (elementConfig, position) {
					var element;
					var coords;
					if (position) {
						coords = {
							lat: position.coords.latitude,
							long: position.coords.longitude,
							accuracy: position.accuracy
						};
					}

					switch (elementConfig.type) {
						case 'widget':
							var widgetLoading = angular.element(
								'<div class="ui-element-widget-loading"><i class="fa fa-circle-o-notch fa-spin"></i></div>'
							);
							var widget = angular.element('<div class="ui-element-widget"></div>');
							element = angular.element('<div class="ui-element-widget-wrapper"></div>');
							renderWidget(
								element,
								elementConfig,
								elementConfig.name,
								widget,
								widgetLoading,
								renderElement
							);
							break;

						case 'postIframe':
							var frameData = {
								type: 'uiElement',
								data: JSON.stringify({
									name: elementConfig.name,
									object: getObject(),
									user: getUser(),
									coords: coords
								}),
								typeId: $attrs.type,
								integrationId: elementConfig.integrationId
							};

							var target = 'ui-element-' + elementConfig.integrationId + '-' + elementConfig.name;
							var url = URL + API + 'function/standardIntegrationIframe/';

							var frame = angular.element(
								'<iframe class="si-sidebar-iframe" src="#" name="' +
									target +
									'" style="display:none;"></iframe>'
							);
							$elem.append(frame);
							var form = angular.element(
								'<form action="' + url + '" method="post" target="' + target + '"></form>'
							);
							_.each(Object.keys(frameData), function (k) {
								var input = angular
									.element('<input type="hidden" name="' + k + '" />')
									.val(frameData[k]);
								form.append(input);
							});
							$elem.append(frame);
							angular.element('body').append(form);
							form.submit().remove();

							break;

						case 'iframe':
							var src = URL + API + 'function/standardIntegrationIframe/' + elementConfig.name;
							src += '?typeId=' + $attrs.type;
							src += '&integrationId=' + elementConfig.integrationId;
							src += '&objectId=' + getObject().id;
							src += '&userId=' + getUser().id;
							element = angular.element(
								'<iframe class="si-sidebar-iframe" src="' +
									src +
									'" name="ui-element-' +
									elementConfig.integrationId +
									'-' +
									elementConfig.name +
									'"></iframe>'
							);
							iframes.push(element[0]);
							break;
					}

					if (element) {
						$elem.append(element);
					}

					// Trigger initial onchange when loaded
					if (elementConfig.type === 'iframe') {
						element[0].onload = function () {
							postEventToFrame(elementConfig, 'onchange', getObject());
						};
					}
				};

				var init = function (elements) {
					if (!_.isArray(elements)) {
						return;
					}

					iframes = [];

					_.each(elements, function (elementConfig) {
						if (elementConfig.requireLocation) {
							if (!navigator.geolocation) {
								return;
							}
							navigator.geolocation.getCurrentPosition(function (position) {
								renderElement(elementConfig, position);
							});
						} else {
							renderElement(elementConfig);
						}
					});

					const messageCb = iframeMessage.bind(null, elements || []);

					window.addEventListener('message', messageCb);

					$scope.$on('$destroy', function () {
						window.removeEventListener('message', messageCb);
					});
				};

				var unsubWatch = $scope.$watch($attrs.uiElements, function (elements) {
					if (elements) {
						init(elements);
						unsubWatch();
					}
				});

				let onChangeTimer;

				// Watch related object
				if ($attrs.reRenderCounter) {
					$scope.$watch($attrs.reRenderCounter, function () {
						const object = getObject();
						if (object !== undefined) {
							clearTimeout(onChangeTimer);
							onChangeTimer = setTimeout(() => {
								$elem.find('.si-sidebar-iframe').each(function () {
									if (this.contentWindow) {
										this.contentWindow.postMessage(['onchange', JSON.stringify(object)], '*');
									}
								});
							}, 1000);
						}
					});
				} else {
					$scope.$watch(
						$attrs.object,
						function (object) {
							if (object !== undefined) {
								clearTimeout(onChangeTimer);
								onChangeTimer = setTimeout(() => {
									$elem.find('.si-sidebar-iframe').each(function () {
										if (this.contentWindow) {
											this.contentWindow.postMessage(['onchange', JSON.stringify(object)], '*');
										}
									});
								}, 1000);
							}
						},
						true
					);
				}
			}
		};
	}
]);
