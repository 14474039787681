'use strict';

/* eslint-disable no-mixed-spaces-and-tabs */
angular.module('upsalesApp').run([
	'AppointmentType',
	'userDefinedDefinition',
	'MarketRejectlist',
	'ResetScore',
	'AccountRelation',
	'LatestAccountsService',
	'ProductTreeFilterMeta',
	'StandardIntegration',
	'localStorageService',
	'NotificationService',
	'UserTreeFilterMeta',
	'MultiRunnerActions',
	'GroupMailCategory',
	'MailCampaignInfo',
	'PushNotifications',
	'currenciesISO4217',
	'CategoryAtributes',
	'UserDefinedObject',
	'StandardCreative',
	'ActionProperties',
	'LookerDashboard',
	'accountRelations',
	'LANDINGPAGEPATH',
	'SoliditetClient',
	'STATIC_MAPS_KEY',
	'ListViewService',
	'ListAttributes',
	'RequestBuilder',
	'browserService',
	'AccountProfile',
	'CacheRefresher',
	'LeaderAccount',
	'FeatureHelper',
	'SearchService',
	'avatarService',
	'FilterService',
	'localeService',
	'ScriptService',
	'StandardField',
	'LookerExplore',
	'TriggerHelper',
	'CountryCodes',
	'MailCampaign',
	'VoiceService',
	'$stateParams',
	'CacheService',
	'ActivityType',
	'FilterHelper',
	'MultiActions',
	'$multiSelect',
	'MailAccount',
	'ClientParam',
	'ProductTree',
	'Appointment',
	'$datepicker',
	'LEAD_SOURCE',
	'Opportunity',
	'Quicksearch',
	'TagsService',
	'DisplayType',
	'LookerLook',
	'FilterType',
	'AppService',
	'TagService',
	'ClientForm',
	'$translate',
	'$rootScope',
	'FormSubmit',
	'LeaderPage',
	'InkyParser',
	'Onboarding',
	'OrderStage',
	'InviteCode',
	'LookerSSO',
	'$location',
	'$injector',
	'Agreement',
	'FORMPATH',
	'Category',
	'$upModal',
	'Campaign',
	'ListView',
	'Activity',
	'$cookies',
	'Contract',
	'SniCodes',
	'AllIWant',
	'EditorCk',
	'Currency',
	'Visitor',
	'Session',
	'Product',
	'Account',
	'Segment',
	'Contact',
	'$filter',
	'Lookup',
	'ApiKey',
	'$state',
	'Report',
	'Import',
	'OptIn',
	'Esign',
	'Order',
	'Event',
	'utils',
	'Lead2',
	'$http',
	'Links',
	'Role',
	'Self',
	'Mail',
	'Flow',
	'Form',
	'File',
	'User',
	'$sce',
	'URL',
	'Ads',
	'API',
	'ENV',
	'$q',
	'ReportView',
	'UserParam',
	'ActivityList',
	'StandardMailTemplate',
	'ContactRelation',
	'CustomField',
	'LeadSource',
	'AgreementSettings',
	'DocumentTemplate',
	'GenericQuota',
	'ProductCategory',
	function (
		AppointmentType,
		userDefinedDefinition,
		MarketRejectlist,
		ResetScore,
		AccountRelation,
		LatestAccountsService,
		ProductTreeFilterMeta,
		StandardIntegration,
		localStorageService,
		NotificationService,
		UserTreeFilterMeta,
		MultiRunnerActions,
		GroupMailCategory,
		MailCampaignInfo,
		PushNotifications,
		currenciesISO4217,
		CategoryAtributes,
		UserDefinedObject,
		StandardCreative,
		ActionProperties,
		LookerDashboard,
		accountRelations,
		LANDINGPAGEPATH,
		SoliditetClient,
		STATIC_MAPS_KEY,
		ListViewService,
		ListAttributes,
		RequestBuilder,
		browserService,
		AccountProfile,
		CacheRefresher,
		LeaderAccount,
		FeatureHelper,
		SearchService,
		avatarService,
		FilterService,
		localeService,
		ScriptService,
		StandardField,
		LookerExplore,
		TriggerHelper,
		CountryCodes,
		MailCampaign,
		VoiceService,
		$stateParams,
		CacheService,
		ActivityType,
		FilterHelper,
		MultiActions,
		$multiSelect,
		MailAccount,
		ClientParam,
		ProductTree,
		Appointment,
		$datepicker,
		LEAD_SOURCE,
		Opportunity,
		Quicksearch,
		TagsService,
		DisplayType,
		LookerLook,
		FilterType,
		AppService,
		TagService,
		ClientForm,
		$translate,
		$rootScope,
		FormSubmit,
		LeaderPage,
		InkyParser,
		Onboarding,
		OrderStage,
		InviteCode,
		LookerSSO,
		$location,
		$injector,
		Agreement,
		FORMPATH,
		Category,
		$upModal,
		Campaign,
		ListView,
		Activity,
		$cookies,
		Contract,
		SniCodes,
		AllIWant,
		EditorCk,
		Currency,
		Visitor,
		Session,
		Product,
		Account,
		Segment,
		Contact,
		$filter,
		Lookup,
		ApiKey,
		$state,
		Report,
		Import,
		OptIn,
		Esign,
		Order,
		Event,
		utils,
		Lead2,
		$http,
		Links,
		Role,
		Self,
		Mail,
		Flow,
		Form,
		File,
		User,
		$sce,
		URL,
		Ads,
		API,
		ENV,
		$q,
		ReportView,
		UserParam,
		ActivityList,
		StandardMailTemplate,
		ContactRelation,
		CustomField,
		LeadSource,
		AgreementSettings,
		DocumentTemplate,
		GenericQuota,
		ProductCategory
	) {
		/* eslint-enable no-mixed-spaces-and-tabs */

		// Services
		window.Tools.AccountRelation = AccountRelation;
		window.Tools.MultiActions = MultiActions;
		window.Tools.$multiSelect = $multiSelect;
		window.Tools.NotificationService = NotificationService;
		window.Tools.UserTreeFilterMeta = UserTreeFilterMeta;
		window.Tools.MultiRunnerActions = MultiRunnerActions;
		window.Tools.ActionProperties = ActionProperties;
		window.Tools.currenciesISO4217 = currenciesISO4217;
		window.Tools.CategoryAtributes = CategoryAtributes;
		window.Tools.SoliditetClient = SoliditetClient;
		window.Tools.RequestBuilder = RequestBuilder;
		window.Tools.browserService = browserService;
		window.Tools.AccountProfile = AccountProfile;
		window.Tools.FeatureHelper = FeatureHelper;
		window.Tools.SearchService = SearchService;
		window.Tools.avatarService = avatarService;
		window.Tools.$stateParams = $stateParams;
		window.Tools.CacheService = CacheService;
		window.Tools.FilterHelper = FilterHelper;
		window.Tools.FilterType = FilterType;
		window.Tools.ProductTree = ProductTree;
		window.Tools.TagsService = TagsService;
		window.Tools.Appointment = Appointment;
		window.Tools.$datepicker = $datepicker;
		window.Tools.DisplayType = DisplayType;
		window.Tools.$rootScope = $rootScope;
		window.Tools.LeaderPage = LeaderPage;
		window.Tools.LeaderAccount = LeaderAccount;
		window.Tools.AppService = AppService;
		window.Tools.TagService = TagService;
		window.Tools.$translate = $translate.instant;
		window.Tools.$location = $location;
		window.Tools.$injector = $injector;
		window.Tools.$upModal = $upModal;
		window.Tools.$filter = $filter;
		window.Tools.$state = $state;
		window.Tools.$q = $q;
		window.Tools.InkyParser = InkyParser;
		window.Tools.$sce = $sce;
		window.Tools.$http = $http;
		window.Tools.Onboarding = Onboarding;
		window.Tools.PushNotifications = PushNotifications;
		window.Tools.CountryCodes = CountryCodes;
		window.Tools.LEAD_SOURCE = LEAD_SOURCE;
		window.Tools.FilterService = FilterService;
		window.Tools.localeService = localeService;
		window.Tools.ListAttributes = ListAttributes;
		window.Tools.VoiceService = VoiceService;
		window.Tools.LatestAccountsService = LatestAccountsService;
		window.Tools.ProductTreeFilterMeta = ProductTreeFilterMeta;
		window.Tools.ScriptService = ScriptService;
		window.Tools.$cookies = $cookies;
		window.Tools.localStorageService = localStorageService;
		window.Tools.EditorCk = EditorCk;
		window.Tools.utils = utils;
		window.Tools.ListViewService = ListViewService;
		window.Tools.InviteCode = InviteCode;
		window.Tools.Session = Session;
		window.Tools.Self = Self;

		// Resources
		window.Tools.AppointmentType = AppointmentType;
		window.Tools.MarketRejectlist = MarketRejectlist;
		window.Tools.ResetScore = ResetScore;
		window.Tools.userDefinedDefinition = userDefinedDefinition;
		window.Tools.UserDefinedObject = UserDefinedObject;
		window.Tools.Agreement = Agreement;
		window.Tools.ActivityType = ActivityType;
		window.Tools.MailCampaign = MailCampaign;
		window.Tools.OrderStage = OrderStage;
		window.Tools.Category = Category;
		window.Tools.Activity = Activity;
		window.Tools.Campaign = Campaign;
		window.Tools.Product = Product;
		window.Tools.Account = Account;
		window.Tools.Contact = Contact;
		window.Tools.ListView = ListView;
		window.Tools.OptIn = OptIn;
		window.Tools.Segment = Segment;
		window.Tools.ApiKey = ApiKey;
		window.Tools.Mail = Mail;
		window.Tools.User = User;
		window.Tools.Flow = Flow;
		window.Tools.Report = Report;
		window.Tools.Import = Import;
		window.Tools.Form = Form;
		window.Tools.File = File;
		window.Tools.Esign = Esign;
		window.Tools.Event = Event;
		window.Tools.Visitor = Visitor;
		window.Tools.FormSubmit = FormSubmit;
		window.Tools.Ads = Ads;
		window.Tools.Lead2 = Lead2;
		window.Tools.Role = Role;
		window.Tools.Order = Order;
		window.Tools.Lookup = Lookup;
		window.Tools.StandardIntegration = StandardIntegration;
		window.Tools.Quicksearch = Quicksearch;
		window.Tools.Opportunity = Opportunity;
		window.Tools.Contract = Contract;
		window.Tools.GroupMailCategory = GroupMailCategory;
		window.Tools.MailCampaignInfo = MailCampaignInfo;
		window.Tools.Links = Links;
		window.Tools.SniCodes = SniCodes;
		window.Tools.AllIWant = AllIWant;
		window.Tools.ClientForm = ClientForm;
		window.Tools.StandardField = StandardField;
		window.Tools.MailAccount = MailAccount;
		window.Tools.ClientParam = ClientParam;
		window.Tools.UserParam = UserParam;
		window.Tools.LeadSource = LeadSource;
		window.Tools.LookerDashboard = LookerDashboard;
		window.Tools.LookerSSO = LookerSSO;
		window.Tools.LookerLook = LookerLook;
		window.Tools.LookerExplore = LookerExplore;
		window.Tools.TriggerHelper = TriggerHelper;
		window.Tools.CacheRefresher = CacheRefresher;
		window.Tools.StandardCreative = StandardCreative;
		window.Tools.AccountRelations = accountRelations;
		window.Tools.ActivityList = ActivityList;
		window.Tools.ReportView = ReportView;
		window.Tools.StandardMailTemplate = StandardMailTemplate;
		window.Tools.ContactRelation = ContactRelation;
		window.Tools.CustomField = CustomField;
		window.Tools.AgreementSettings = AgreementSettings;
		window.Tools.DocumentTemplate = DocumentTemplate;
		window.Tools.Currency = Currency;
		window.Tools.GenericQuota = GenericQuota;
		window.Tools.ProductCategory = ProductCategory;

		window.Tools.URL = URL;
		window.Tools.API = API;
		window.Tools.LANDINGPAGEPATH = LANDINGPAGEPATH;
		window.Tools.FORMPATH = FORMPATH;
		window.Tools.STATIC_MAPS_KEY = STATIC_MAPS_KEY;
		window.Tools.ENV = ENV;
	}
]);
