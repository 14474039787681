'use strict';

import React from 'react';
import { ModalHeader, ModalControls } from '@upsales/components';

ReactTemplates.form.validDomainModal = window.ReactCreateClass({
	getInitialState: function () {
		return {
			domain: '',
			tld: '',
			original: ''
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			nonValidDomains: t('form.nonValidDomains'),
			addDomain: t('form.addDomain'),
			add: t('default.add'),
			save: t('default.save'),
			saving: t('default.saving'),
			cancel: t('default.abort'),
			putDomain: t('form.putDomain'),
			all: t('default.all'),
			nonValidCompanyDomains: t('form.nonValidCompanyDomains'),
			allDomainsText: t('form.allDomainsText'),
			prefix: t('form.emailExample'),
			noInvalidDomains: t('form.noInvalidDomains'),
			tldInfo: t('form.validDomain.tld.info'),
			alreadyExists: t('default.alreadyExist')
		};
	},
	saveDomains: function () {
		this.props.save();
	},
	input: function (type, event) {
		if (type === 'tld') {
			var tld = event.target.value;

			if (!tld.length) {
				this.setState({
					tld: tld,
					domain: this.state.original
				});
			} else {
				this.setState({
					tld: tld
				});
			}
		} else {
			var o = this.parseDomain(event.target.value);
			this.setState(o);
		}
	},
	parseDomain: function (domain) {
		domain = domain.toLowerCase();
		var tld;
		var originalDomain = _.cloneDeep(domain);
		if (domain.indexOf('.') !== -1) {
			var domainSplit = domain.split('.');
			if (domainSplit.length > 2) {
				var tempArray;

				if (domainSplit.length > 3) {
					if (domainSplit[2] + domainSplit[3] === 'couk') {
						tempArray = [domainSplit[2], domainSplit[3]];
						tld = tempArray.join('.');
						domain = [domainSplit[0], domainSplit[1]].join('.');
					}
				} else {
					if (domainSplit[1] + domainSplit[2] === 'couk') {
						tempArray = [domainSplit[1], domainSplit[2]];
						tld = tempArray.join('.');
						domain = domainSplit[0];
					} else {
						tempArray = [domainSplit[0], domainSplit[1]];
						tld = domainSplit[2];
						domain = tempArray.join('.');
					}
				}

				return { domain: domain, tld: tld, original: originalDomain };
			} else {
				return { domain: domainSplit[0], tld: domainSplit[1], original: originalDomain };
			}
		}

		return { domain: domain, tld: null, original: originalDomain };
	},
	addDomain: function () {
		this.props.addDomain({
			name: this.state.domain,
			topLevel: this.state.tld
		});

		this.setState(
			{
				domain: '',
				tld: '',
				original: ''
			},
			function () {
				var domain = this.refs.domain;
				domain.focus();
			}
		);
	},
	handleKeyPress: function (e) {
		if (e.key === 'Enter') {
			this.addDomain();
		}
	},
	render: function () {
		var self = this;
		var domains = null;
		var listElement = null;

		if (self.props.loading) {
			listElement = <ReactTemplates.loader className="list-spinner" />;
		} else if (self.props.domains && self.props.domains.length) {
			var allTopDomains = self.lang.allDomainsText.toLowerCase();
			domains = _.map(self.props.domains, function (domain, i) {
				return React.createElement(ReactTemplates.form.domainRow, {
					key: `${i}-${domain}`,
					domain: domain,
					deleteRow: self.props.deleteRow,
					allDomainsText: allTopDomains,
					prefix: self.lang.prefix
				});
			});

			listElement = (
				<div>
					<div className="list-title">
						{self.lang.nonValidCompanyDomains}
						{':'}
					</div>
					<div className="list-container">
						<table className="list">
							<tbody>{domains}</tbody>
						</table>
					</div>
				</div>
			);
		} else {
			listElement = <div className="list-title empty-result">{self.lang.noInvalidDomains}</div>;
		}

		var btnClasses = 'btn btn-bright-blue no-shadow up-btn';
		if (self.props.saving || self.props.loading) {
			btnClasses += ' disabled';
		}

		return (
			<div id="valid-domain-modal">
				<ModalHeader title={self.lang.nonValidDomains} onClose={self.props.closeModal} />
				<div className="up-panel">
					<div className="up-panel-content">
						<label>
							{self.lang.addDomain}
							<span>
								<ReactTemplates.elevio articleId="431" sidebar="true" />
							</span>
						</label>
						<div className="input-group">
							<span className="input-group-addon">{self.lang.prefix}</span>
							{ReactTemplates.TOOLS.withTooltip(
								<input
									type="text"
									ref="domain"
									onKeyPress={self.handleKeyPress}
									placeholder={self.lang.putDomain}
									className="form-control"
									onChange={self.input.bind(self, 'domain')}
									value={self.state.original}
									data-testid="domain-input"
								/>,
								self.lang.tldInfo,
								{ placement: 'bottom' }
							)}
							<button
								type="button"
								onClick={self.addDomain}
								className={btnClasses}
								data-testid="add-domain-button"
							>
								{self.lang.add}
							</button>
						</div>
						{self.props.hasError && (
							<div className="errorHolder">
								<p>{self.props.error}</p>
							</div>
						)}
						{listElement}
					</div>
				</div>
				<ModalControls>
					<button type="submit" className={btnClasses} onClick={self.saveDomains}>
						{self.props.saving ? self.lang.saving : self.lang.save}
					</button>
					<button
						className="btn up-btn btn-link btn-sm btn-grey"
						onClick={self.props.closeModal}
						style={{
							width: 'auto',
							margin: 0
						}}
					>
						{self.lang.cancel}
					</button>
				</ModalControls>
			</div>
		);
	}
});

ReactTemplates.form.domainRow = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			remove: t('default.delete')
		};
	},
	deleteRow: function () {
		this.props.deleteRow(this.props.domain);
	},
	render: function () {
		var topLevelDomain = this.props.domain.topLevel ? (
			<span>
				{'.'}
				{this.props.domain.topLevel}
			</span>
		) : (
			<span className="grey-text">{' ' + this.props.allDomainsText}</span>
		);
		return (
			<tr>
				<td>
					<span className="grey-text">{this.props.prefix}</span>
					<span>{this.props.domain.name}</span>
					{topLevelDomain}
				</td>
				<td>
					{ReactTemplates.TOOLS.withTooltip(
						<b className="fa fa-trash delete-trigger" onClick={this.deleteRow}></b>,
						this.lang.remove,
						{ placement: 'top' }
					)}
				</td>
			</tr>
		);
	}
});
