import { globalTracker } from 'App/babel/helpers/Tracker';
import ListEsignRow from 'App/components/ListEsign';
import logError from 'App/babel/helpers/logError';
import openModal from 'App/services/Modal';
import openEditEsign from 'App/components/Esign/EditEsign/EditEsign';

angular.module('domain.esign').controller('ListEsign', [
	'$scope',
	'$upModal',
	'Esign',
	'EsignAttributes',
	'AppService',
	'RequestBuilder',
	'$location',
	'Onboarding',
	'StandardIntegration',
	function (
		$scope,
		$upModal,
		Esign,
		EsignAttributes,
		AppService,
		RequestBuilder,
		$location,
		Onboarding,
		StandardIntegration
	) {
		var ListEsign = this;
		var esignAppPlaceholderRoot = document.getElementById('esign-app-list-placeholder');
		var esignAppPlaceholderProps = {
			visible: false,
			loading: false,
			integrations: []
		};

		var renderAppPlaceholder = function () {
			if (!esignAppPlaceholderRoot) {
				return;
			}
			ReactDOM.render(
				React.createElement(ReactTemplates.esignAppPlaceholder, Object.assign({}, esignAppPlaceholderProps)),
				esignAppPlaceholderRoot
			);
		};

		$scope.$on('$destroy', function () {
			ReactDOM.unmountComponentAtNode(esignAppPlaceholderRoot);
			esignAppPlaceholderRoot = undefined;
		});

		ListEsign.attributes = EsignAttributes();
		ListEsign.disableAddFilter = false;
		ListEsign.showAddBtn = true;
		ListEsign.listType = 'esign';
		ListEsign.title = 'default.eSigns';
		ListEsign.useMultiActions = true;
		ListEsign.tableTemplateComponent = props => <ListEsignRow {...props} />;
		ListEsign.page = 1;
		ListEsign.total = 0;
		ListEsign.offset = 0;
		ListEsign.limit = 100;
		ListEsign.loading = false;
		ListEsign.user = null;
		ListEsign.state = null;
		ListEsign.sorting = [{ attribute: 'sortDate', ascending: false }];
		ListEsign.columns = ['sortDate', 'client', 'file', 'opportunity', 'state', 'involved'];

		var esigns = [];
		var customerId;
		var currentQuery = [];
		var self;
		var getDataTimer = null;

		// listen for order changes
		var orderUpdate = function (e, order) {
			var esign = _.find(esigns, { opportunity: { id: order.id } });

			if (esign) {
				esign.opportunity = order;
			}
		};

		var mapSorting = function () {
			var str = '';

			if (!ListEsign.sorting || !ListEsign.sorting.length) {
				return null;
			}

			var sorting = ListEsign.sorting[0];

			if (!sorting || !sorting.attribute) {
				return null;
			}

			str += sorting.attribute;

			if (sorting.ascending) {
				str += ':' + sorting.ascending;
			}

			return str;
		};

		var parseSorting = function (str) {
			var arr = str.split(':');

			if (!str.length) {
				return null;
			}
			var sorting = [{ attribute: arr[0], ascending: arr[1] || false }];

			return sorting;
		};

		$scope.$on('opportunity.updated', orderUpdate);
		$scope.$on('order.updated', orderUpdate);

		$scope.$on('esign.added', function (e, added) {
			if (!ListEsign.user || added.user.id === ListEsign.user) {
				ListEsign.total++;
				esigns.push(added);
				ListEsign.esigns = esigns;
			}
		});

		$scope.$on('esign.updated', function (e, updated) {
			var existingIndex = _.findIndex(esigns, { id: updated.id });
			var matchesFilter = !ListEsign.user || updated.user.id === ListEsign.user;
			if (existingIndex !== -1 && matchesFilter) {
				esigns[existingIndex] = updated;
			} else if (matchesFilter) {
				esigns.push(updated);
			} else if (existingIndex !== -1) {
				ListEsign.total--;
				esigns.splice(existingIndex, 1);
			}
		});

		$scope.$on('esign.deleted', function (e, deleted) {
			var existingIndex = _.findIndex(esigns, { id: deleted.id });

			if (existingIndex !== -1) {
				ListEsign.total--;
				esigns.splice(existingIndex, 1);
			}
		});

		/**
		 * The add button
		 */
		ListEsign.createEntry = function () {
			globalTracker.track('list_add_esign');
			Onboarding.setListIntro('esign');
			openModal('FindClient', {
				onClose: client => {
					if (client) {
						// this is always a draft esign
						openEditEsign({ esign: { client: { id: client.id } } });
					}
				}
			});
		};

		// The getter function
		ListEsign.getEntries = function () {
			// Clear timeout
			if (getDataTimer) {
				clearTimeout(getDataTimer);
			}

			getDataTimer = setTimeout(function () {
				var rb = new RequestBuilder();
				rb.limit = ListEsign.limit;
				rb.offset = ListEsign.limit * (ListEsign.page - 1);
				rb.sorting = ListEsign.sorting;

				if (ListEsign.user === 'self') {
					rb.addFilter(Esign.attr.user.attr.id, rb.comparisonTypes.Equals, Tools.AppService.getSelf().id);
				} else if (ListEsign.user) {
					rb.addFilter(Esign.attr.user.attr.id, rb.comparisonTypes.Equals, ListEsign.user);
				}

				if (ListEsign.state) {
					rb.addFilter(Esign.attr.state, rb.comparisonTypes.Equals, ListEsign.state);
				}

				ListEsign.total = 0;
				ListEsign.loading = true;

				currentQuery = rb.build();

				Esign.find(currentQuery)
					.then(function (res) {
						ListEsign.total = res.metadata ? res.metadata.total : res.data.length;
						esigns = res.data;
						ListEsign.esigns = res.data;
						ListEsign.loading = false;
					})
					.catch(error => logError(error, 'Could not find Esign'));
			}, 50);
		};

		// ListEsign.sort = function() {};

		ListEsign.init = function () {
			var standardSorting = mapSorting(ListEsign.sorting);

			var esignIntegrations = AppService.getEsignIntegrations();
			esignAppPlaceholderProps.visible = !esignIntegrations.length;
			ListEsign.hasAppPlaceholder = esignAppPlaceholderProps.visible;
			renderAppPlaceholder();

			// Get apps
			if (esignAppPlaceholderProps.visible) {
				esignAppPlaceholderProps.loading = true;
				renderAppPlaceholder();
				var filters = {
					onlyPublished: true,
					init: 'esign'
				};
				StandardIntegration.find(filters)
					.then(function (res) {
						esignAppPlaceholderProps.integrations = res.data.splice(0, 3);
						esignAppPlaceholderProps.loading = false;
						renderAppPlaceholder();
					})
					.catch(error => logError(error, 'Could not find StandardIntegration'));
			}

			// Set customer it now when we know we have it
			customerId = AppService.getCustomerId();
			self = AppService.getSelf();

			ListEsign.user = self.id;

			ListEsign.listOpts = {
				type: 'esign',
				hasCheck: false,
				editable: true,
				placeholderKey: 'esign',
				featureInactive: false,
				hasDocumentTemplates: false,
				clickedEntry: function (esign) {
					if (!esign || esign.state === Esign.stateEnum.DRAFT) {
						openEditEsign({ id: esign.id });
					} else {
						if (Tools.FeatureHelper.hasSoftDeployAccess('CONFIRM_ESIGN_REACT')) {
							openModal('ConfirmEsignModal', {
								id: esign.id
							});
						} else {
							$upModal.open('confirmEsign', { id: esign.id });
						}
					}
				},
				removeEntry: function (esign) {
					// Delete file here
					return Esign.delete(esign);
				},
				editOrder: function (order, e) {
					e.stopPropagation();
					e.nativeEvent.stopImmediatePropagation();

					var type = order.probability === 0 || order.probabilit === 100 ? 'order' : 'opportunity';
					$upModal.open('editOrder', {
						id: order.id,
						customerId: customerId,
						type: type
					});
				}
			};

			// On hash change
			// Run the update data function
			$scope.$watch(
				function () {
					return {
						hash: $location.search(),
						path: $location.$$path
					};
				},
				function (location, oldLocation) {
					// Only trigger change if we still are on the same page
					if (location.path === oldLocation.path) {
						var hash = location.hash;
						// Reset page if sorting or user changed, else set it to hash.p or 1
						if (hash.s !== oldLocation.hash.s || hash.u !== oldLocation.hash.u) {
							ListEsign.page = 1;
						} else {
							ListEsign.page = hash.p || 1;
						}

						// Get user from hash
						if (hash.u === 'all') {
							ListEsign.user = null;
						} else if (!hash.u) {
							ListEsign.user = self.id;
						} else {
							ListEsign.user = hash.u;
						}

						// Get sorting from hash
						if (hash.s) {
							ListEsign.sorting = parseSorting(hash.s);
						} else {
							ListEsign.sorting = parseSorting(standardSorting);
						}

						// Get state from hash
						if (hash.st) {
							ListEsign.state = hash.st;
						} else {
							ListEsign.state = null;
						}

						ListEsign.getEntries();
					}
				},
				true
			);

			// Watch for filter and prop changes
			$scope.$watch(
				function () {
					return {
						user: ListEsign.user,
						state: ListEsign.state,
						page: ListEsign.page,
						sorting: ListEsign.sorting || []
					};
				},
				function () {
					// Set hash to trigger data get
					if (!ListEsign.user) {
						$location.search('u', 'all');
					} else if (ListEsign.user === self.id) {
						$location.search('u', null);
					} else {
						$location.search('u', ListEsign.user);
					}

					$location.search('st', ListEsign.state);

					var mappedSorting = mapSorting();
					if (mappedSorting && mappedSorting !== standardSorting) {
						$location.search('s', mappedSorting);
					} else {
						$location.search('s', null);
					}
				},
				true
			); // Watch deep
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(ListEsign.init);
	}
]);
