import logError from 'App/babel/helpers/logError';
import {
	getTierFromOrderRow,
	getTierPriceByCurrency,
	isTieredOrderRow,
	isTieredTotalOrderRow
} from 'App/babel/helpers/order';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import EditOrderSaveButton from 'App/components/EditOrderSaveButton';
import LostOpportunity from 'App/components/LostOpportunity';
import OpportunityWinLossButtons from 'App/components/OpportunityWinLossButtons';
import WonOpportunity from 'App/components/WonOpportunity';
import EditProductBundleOrder from 'App/components/EditProductBundleOrder';
import RiskChip from 'App/components/RiskChip';
import AppsHistory from 'Components/AppsHistory';
import FieldSync from 'Components/FieldSync';
import { getRelatedClientIds, binaryGroup } from 'App/helpers/accountsHelper';
import OpportunityAI from 'Components/OpportunityAI';
import OpportunityTodos from 'Components/OpportunityTodos';
import CommentInput from 'App/components/Inputs/CommentInput';
import Comment from 'Resources/Comment';
import CopyableId from 'App/components/CopyableId';
import _ from 'lodash';
import { checkValidityOrder as checkIfPeriodizationIsValid } from 'Store/reducers/PeriodizationReducer';
import tippy from 'tippy.js';
import pdfTemplateResource from 'Resources/pdfTemplates';
import documentResource from 'Resources/document';
import OrderHistory from 'App/components/OrderHistory';
import SalesCoach from 'Resources/SalesCoach';
import store from 'Store';
import SimilarCustomers from 'App/components/SimilarCustomers';
import ValidationService from 'Services/ValidationService';
import openModal from 'App/services/Modal';
import { getOrderForecastingRisk, ForecastingRiskLevel } from 'App/enum/ForecastingRisks';
import {
	resetAndGetBundleOrderRow,
	getMaxDiscount,
	getRecurringValue
} from 'App/components/OrderRows/Context/OrderContextHelpers';
import OnOrderEditHelpers from 'App/helpers/onOrderEditHelpers';
import getOrderChanges, { customFieldsChanged } from 'App/helpers/getOrderChanges';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import T from 'Components/Helpers/translate';
import UiElements from 'Components/UiElements';
import editOrderFormHtmlPath from 'App/upsales/domain/order/views/editOrderForm.html?file';
import editOrderDashboardHtmlPath from 'App/upsales/domain/order/views/editOrderDashboard.html?file';
import editOrderFilesHtmlPath from 'App/upsales/domain/order/views/editOrderFiles.html?file';
import editOrderProjectsHtmlPath from 'App/upsales/domain/order/views/editOrderProjects.html?file';
import InvoiceRelatedClient from 'App/components/InvoiceRelatedClient';
import { getAllActiveAgreements, getRecurringProductDefaultInterval } from 'App/helpers/subscriptionHelper';
import AccountPlanSidebar from 'App/components/AccountPlanSidebar';
import { getCMWithRROption, hasRRWithCM } from 'App/helpers/salesModelHelpers';
import getSpeedTracker from 'App/helpers/speedTracker';
import TimelineEntityNote from 'Components/TimelineRow/TimelineEntityNote/TimelineEntityNote';
import TicketWidget from 'App/components/TicketWidget';
import ProjectPlanHandler from 'App/components/ProjectPlanOrderHandler';
import Product from 'Resources/Product';
import { bundleUpdateHandler } from 'App/components/OrderRows/Context/OrderContextHelpers';
import { ClientContactSelectWrapper } from 'Components/ClientContactSelect/ClientContactSelect';
import { openAccountRelationModal } from 'Components/Account/AccountRelationModal/AccountRelationModal';
import { openPreviewPdfModal } from 'Components/Admin/DocumentTemplate/PreviewPdf/PreviewPdf';
import { openGenericModal } from 'App/components/GenericModal/GenericModal';
import RelationsSignalWidget from 'App/components/RelationsSignalWidget/RelationsSignalWidget';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';
import { openConfirmSoliditetBuyParentModal } from 'App/components/ConfirmSoliditetBuyParent/ConfirmSoliditetBuyParent';

import openEditEsign from 'App/components/Esign/EditEsign/EditEsign';
angular.module('domain.order').controller('EditOrder', [
	'$scope',
	'$q',
	'Order',
	'Links',
	'$translate',
	'$modalParams',
	'$stateParams',
	'RequestBuilder',
	'Contact',
	'accountRelations',
	'$state',
	'$upModal',
	'$rootScope',
	'EventService',
	'ScriptService',
	'Activity',
	'Account',
	'Appointment',
	'AppService',
	'utils',
	'FeatureHelper',
	'File',
	'MapHelpers',
	'selectHelper',
	'Esign',
	'$safeApply',
	'ParseFormula',
	'$parse',
	'NotificationService',
	function (
		$scope,
		$q,
		Order,
		Links,
		$translate,
		$modalParams,
		$stateParams,
		RequestBuilder,
		Contact,
		accountRelations,
		$state,
		$upModal,
		$rootScope,
		EventService,
		ScriptService,
		Activity,
		Account,
		Appointment,
		AppService,
		utils,
		FeatureHelper,
		File,
		MapHelpers,
		selectHelper,
		Esign,
		$safeApply,
		ParseFormula,
		$parse,
		NotificationService
	) {
		var EditOrder = this;
		var meta = $modalParams.meta;
		var customerId;
		var hasRequiredContactFields;
		var integrations = AppService.getEsignIntegrations();
		$scope.showInlineAction = 'none';
		$scope.parseFormula = ParseFormula;
		$scope.hasFormulaVisible = Tools.FeatureHelper.hasSoftDeployAccess('FORMULA_VISIBLE');
		const metadata = AppService.getMetadata();
		const self = AppService.getSelf();
		const productCategories = AppService.getProductCategories();
		const hasMultiCurrency = !!metadata.params.MultiCurrency;

		EditOrder.hasAppFrameworkListenTo = Tools.FeatureHelper.hasSoftDeployAccess('APP_FRAMEWORK_LISTEN_TO');
		EditOrder.visibleWon = false;
		EditOrder.visibleLost = false;
		EditOrder.savedThroughWinLossFlow = false;
		EditOrder.skipButtonOnWonModal = false;
		EditOrder.fetchingAgreements = false;
		EditOrder.editOrderFormHtmlPath = editOrderFormHtmlPath;
		EditOrder.editOrderDashboardHtmlPath = editOrderDashboardHtmlPath;
		EditOrder.editOrderProjectsHtmlPath = editOrderProjectsHtmlPath;
		EditOrder.editOrderFilesHtmlPath = editOrderFilesHtmlPath;
		EditOrder.reRenderCounter = 1;
		EditOrder.isSupportUser = self?.support && !self?.crm;
		EditOrder.disabledSaveReasons = {
			pendingIntegrationRequest: $translate.instant('order.saveDisabled.pendingIntegrationRequest')
		};
		EditOrder.TimelineEntityNote = TimelineEntityNote;

		EditOrder.disabledFields = {
			order: {}
		};
		EditOrder.hasAppValidationWithOrderEdit = Tools.FeatureHelper.hasSoftDeployAccess(
			'APP_VALIDATION_WITH_ORDER_EDIT'
		);
		EditOrder.hasUnifiedClientContactSelect = Tools.FeatureHelper.hasSoftDeployAccess(
			'UNIFIED_CLIENT_CONTACT_SELECTOR'
		);

		EditOrder.SimilarCustomers = SimilarCustomers;
		EditOrder.hasSimilarCustomers = Tools.FeatureHelper.hasSoftDeployAccess('AI_SIMILAR_CUSTOMERS');

		EditOrder.ClientContactSelect = ClientContactSelectWrapper;
		EditOrder.RelationsSignalWidget = RelationsSignalWidget;

		EditOrder.isDisabledFieldAppMaster = (fieldPath, isDisabled, rowFieldPath, rowSortFieldPath) => {
			const defaultIsDisabled = isDisabled === undefined ? false : isDisabled;
			const formattedFieldPath = fieldPath.replaceAll(' ', '').toLowerCase();
			const fieldDisabled = _.get(EditOrder.disabledFields, formattedFieldPath);
			const hasDisabledStateForField = fieldDisabled !== undefined;
			if (hasDisabledStateForField) {
				return fieldDisabled;
			}

			if (!rowFieldPath) {
				return defaultIsDisabled;
			}

			const formattedRowFieldPath = rowFieldPath.replaceAll(' ', '').toLowerCase();
			const formattedRowSortFieldPath = rowSortFieldPath?.replaceAll?.(' ', '')?.toLowerCase() ?? '';
			const rowFieldDisabled = _.get(EditOrder.disabledFields, formattedRowFieldPath, undefined);
			const rowSortFieldDisabled = _.get(EditOrder.disabledFields, formattedRowSortFieldPath, undefined);

			if (rowFieldDisabled !== undefined) {
				return rowFieldDisabled;
			}
			if (rowSortFieldDisabled !== undefined) {
				return rowSortFieldDisabled;
			}
			return defaultIsDisabled;
		};

		EditOrder.isDisabledField = (fieldPath, isDisabled, rowFieldPath, rowSortFieldPath) => {
			if (EditOrder.hasAppValidationWithOrderEdit) {
				return EditOrder.isDisabledFieldAppMaster(fieldPath, isDisabled, rowFieldPath, rowSortFieldPath);
			}

			if (isDisabled) {
				return true;
			}
			const formattedFieldPath = fieldPath.replaceAll(' ', '').toLowerCase();
			const fieldDisabled = _.get(EditOrder.disabledFields, formattedFieldPath, false);
			if (fieldDisabled || !rowFieldPath) {
				return fieldDisabled;
			}
			const formattedRowFieldPath = rowFieldPath.replaceAll(' ', '').toLowerCase();
			const formattedRowSortFieldPath = rowSortFieldPath?.replaceAll(' ', '').toLowerCase() ?? '';
			const rowFieldDisabled =
				_.get(EditOrder.disabledFields, formattedRowFieldPath, false) ||
				_.get(EditOrder.disabledFields, formattedRowSortFieldPath, false);
			return rowFieldDisabled;
		};

		const transformFieldsToLowerCase = fields => {
			try {
				return Object.fromEntries(
					Object.entries(fields).map(([key, value]) => {
						if (typeof value === 'object') {
							return [key.toLowerCase(), transformFieldsToLowerCase(value)];
						}
						return [key.toLowerCase(), value];
					})
				);
			} catch (err) {
				logError(err, 'OnOrderEdit transformFieldsToLowerCase', fields);
				return fields;
			}
		};

		EditOrder.visibleFields = {
			order: {}
		};
		EditOrder.isVisibleField = (fieldPath, isVisible, rowFieldPath, hiddenByProductCategory, rowSortFieldPath) => {
			if (hiddenByProductCategory) {
				return false;
			}
			const defaultIsVisible = isVisible === undefined ? true : isVisible;
			const formattedFieldPath = fieldPath.replaceAll(' ', '').toLowerCase();
			const fieldVisible = _.get(EditOrder.visibleFields, formattedFieldPath);
			const hasVisibleStateForField = fieldVisible !== undefined;
			if (hasVisibleStateForField) {
				return fieldVisible;
			}

			if (!rowFieldPath) {
				return defaultIsVisible;
			}

			const formattedRowFieldPath = rowFieldPath.replaceAll(' ', '').toLowerCase();
			const formattedRowSortFieldPath = rowSortFieldPath?.replaceAll?.(' ', '')?.toLowerCase() ?? '';
			const rowFieldVisible = _.get(EditOrder.visibleFields, formattedRowFieldPath, undefined);
			const rowSortFieldVisible = _.get(EditOrder.visibleFields, formattedRowSortFieldPath, undefined);

			if (rowFieldVisible !== undefined) {
				return rowFieldVisible;
			}
			if (rowSortFieldVisible !== undefined) {
				return rowSortFieldVisible;
			}
			return defaultIsVisible;
		};

		const disableSaveButton = disabledTooltip => {
			const tooltipUnchanged = EditOrder.saveDisabledTooltip === disabledTooltip;
			if (EditOrder.saveDisabled && tooltipUnchanged) {
				return;
			}
			EditOrder.saveDisabled = true;
			EditOrder.saveDisabledTooltip = disabledTooltip;
			$safeApply($scope);
		};

		const enableSaveButton = () => {
			if (!EditOrder.saveDisabled || EditOrder.appDisabledSaveButton) {
				return;
			}
			EditOrder.saveDisabled = false;
			EditOrder.saveDisabledTooltip = '';
			$safeApply($scope);
		};

		const integrationCalls = {};

		const getOrderRowWithIdOrSortId = (existingOrderRow = {}, orderRowToFind = {}) => {
			const bothHasRowId = existingOrderRow.id && orderRowToFind.id;
			if (bothHasRowId) {
				return existingOrderRow.id === orderRowToFind.id;
			}
			return existingOrderRow.sortId === orderRowToFind.sortId;
		};

		const getProducts = async productIds => {
			try {
				const { data: products } = await Product.find({ id: productIds, usePriceLists: true });
				return products;
			} catch (err) {
				logError(err, 'Failed to get products');
				return [];
			}
		};

		const updateOrderFromApp = async (fieldActions, orderBefore, updatedOrder, orderChanges) => {
			try {
				const hasCurrencyPicker = EditOrder.hasCurrencyPicker();
				let reRender = false;

				if (!_.isEmpty(fieldActions?.disabled?.order)) {
					if (fieldActions.disabled.save) {
						EditOrder.appDisabledSaveButton = true;
						disableSaveButton(fieldActions.disabled.saveTooltip);
					} else {
						EditOrder.appDisabledSaveButton = false;
					}

					EditOrder.disabledFields = transformFieldsToLowerCase(fieldActions.disabled.order);
					reRender = true;
				}

				if (!_.isEmpty(fieldActions?.visible?.order)) {
					EditOrder.visibleFields = transformFieldsToLowerCase(fieldActions.visible.order);
					EditOrder.visibleFields = OnOrderEditHelpers.omitForcedVisibleFields(EditOrder.visibleFields);
					reRender = true;
				}

				if (fieldActions?.updated?.order) {
					const orderAfterUpdate = await OnOrderEditHelpers.updateOrder(
						EditOrder.hasOnOrderEditPerformanceEnhanced ? _.cloneDeep(updatedOrder) : EditOrder.order,
						fieldActions.updated.order,
						EditOrder.relatedClients,
						EditOrder.availableSalescoaches,
						EditOrder.hasContributionMargin,
						productCategories
					);

					EditOrder.order = orderAfterUpdate;

					/*
						I would have wanted this in the OnOrderEditHelpers.updateOrder but there
						is a lot of calculations done in this controller which overwrites all potential
						changes done in the helper function.
						So it is easier and less impact to handle the currency change here for now.
						But when we port to react, it would be nice if we could make the currency change
						a bit more modular.
					*/
					if (
						hasCurrencyPicker &&
						fieldActions.updated.order?.currency &&
						fieldActions.updated.order.currency !== EditOrder.selectedCurrency.iso
					) {
						const availableCurrencies = metadata.customerCurrencies;
						const matchedCurrency = availableCurrencies.find(currency => {
							return currency.iso === fieldActions.updated.order?.currency;
						});
						if (matchedCurrency) {
							EditOrder.selectedCurrency = matchedCurrency;
							EditOrder.changeCurrency();
						}
					}

					for (const orderRowUpdate of fieldActions.updated.order?.orderRow ?? []) {
						const updatedOrderRowIndex = EditOrder.order.orderRow.findIndex(row => {
							return getOrderRowWithIdOrSortId(row, orderRowUpdate);
						});
						const updatedOrderRow = EditOrder.order.orderRow[updatedOrderRowIndex];
						if (!updatedOrderRow) {
							continue;
						}
						if (_.has(orderRowUpdate, 'listPrice')) {
							updatedOrderRow.$listPrice = updatedOrderRow.listPrice;
						}
						if (_.has(orderRowUpdate, 'discount')) {
							EditOrder.calc.discountChange(updatedOrderRow);
						}

						if (_.has(orderRowUpdate, '$discountPercent')) {
							EditOrder.calc.discountPercentChange(updatedOrderRow);
						}
						if (orderRowUpdate.price !== undefined && EditOrder.priceList && updatedOrderRow.product) {
							EditOrder.calc.priceChange(updatedOrderRow);
						}
						if (_.has(orderRowUpdate, 'product')) {
							EditOrder.calc.productChange(updatedOrderRow);
						}
						if (_.has(orderRowUpdate, 'bundleRows')) {
							EditOrder.editProductBundleProps = { uuid: updatedOrderRowIndex };
							EditOrder.editProductBundleSave(updatedOrderRow, true);
						}
					}

					// The view is using the recurringInterval at EditOrder and not on EditOrder.order
					// When porting to react, make sure that the recurring interval is read from the order instead
					EditOrder.recurringInterval = updatedOrder.recurringInterval;

					// salesCoach is an array, but this view expects the salesCoach to be the first item in array
					if (Array.isArray(EditOrder.order.salesCoach)) {
						EditOrder.order.salesCoach = EditOrder.order.salesCoach[0] || null;
					}

					reRender = true;
				}

				if (fieldActions?.added?.order?.orderRow && EditOrder.priceList) {
					EditOrder.stopOnOrderEditListening = true;

					if (!EditOrder.orderProducts) {
						EditOrder.orderProducts = [];
					}

					const missingProductIds = fieldActions.added?.order?.orderRow?.reduce((acc, row) => {
						const productId = row?.product?.id || row?.productId;
						if (!productId) {
							return acc;
						}
						const foundProduct = EditOrder.orderProducts.find(product => product.id === productId);
						if (!foundProduct) {
							acc.push(productId);
						}
						return acc;
					}, []);

					if (missingProductIds.length) {
						const missingProducts = await getProducts(missingProductIds);
						EditOrder.orderProducts = [...EditOrder.orderProducts, ...missingProducts];
					}

					fieldActions.added?.order?.orderRow.forEach((addedRow = {}) => {
						const newOrderRow = Order.newRow();
						const orderRowToAdd = {
							...newOrderRow,
							...addedRow,
							custom: newOrderRow.custom.map(customField => {
								const customFieldToAdd = addedRow.custom?.find(
									custom => custom.fieldId === customField.fieldId
								);
								if (customFieldToAdd) {
									customField.value = customFieldToAdd.value;
								}
								return customField;
							})
						};

						if (orderRowToAdd.custom?.length) {
							for (const custom of orderRowToAdd.custom) {
								if (custom.fieldId && orderRowToAdd.$mappedCustom[custom.fieldId]) {
									orderRowToAdd.$mappedCustom[custom.fieldId].value = custom.value;
								}
							}
						}

						orderRowToAdd.product = EditOrder.orderProducts.find(
							product => product.id === (orderRowToAdd.product?.id || orderRowToAdd?.productId)
						);
						EditOrder.addOrderRow(orderRowToAdd, true);
						if (!EditOrder.hasOnOrderEditPerformanceEnhanced) {
							const changes = getOrderChanges(EditOrder.order, orderBefore);
							EditOrder.lastChangesFromEditListener = JSON.stringify(changes);
						}
					});

					reRender = true;
				}
				if (EditOrder.hasOnOrderEditPerformanceEnhanced) {
					if (!orderChanges) {
						orderChanges = getOrderChanges(EditOrder.order, orderBefore);
					}
					EditOrder.lastChangesFromEditListener = JSON.stringify(orderChanges);
				}

				if (fieldActions?.removed?.order?.orderRow) {
					fieldActions.removed.order.orderRow.forEach(removedRow => {
						if (removedRow?.id || removedRow?.sortId) {
							const foundOrderRow = EditOrder.order.orderRow.find(row =>
								getOrderRowWithIdOrSortId(row, removedRow)
							);
							if (foundOrderRow) {
								EditOrder.deleteOrderRow(foundOrderRow);
							}
						}
					});
				}

				if (reRender) {
					$scope.reloadModalPosition();
					$safeApply($scope);
				}
			} catch (err) {
				logError(err, `updateOrderFromApp error ${updatedOrder?.id}`);
			}
		};

		const setupOrderListenTo = () => {
			const listenTo = EditOrder.editOrderListeners.reduce(
				(acc, integration) => {
					if (acc.all) {
						return acc;
					}
					if (!integration.listenTo) {
						acc.all = true;
						return acc;
					}
					acc.fields = [...acc.fields, ...integration.listenTo];
					return acc;
				},
				{ fields: [], all: false }
			);

			if (!listenTo.all) {
				EditOrder.listenTo = listenTo.fields;
			} else {
				EditOrder.listenTo = undefined;
			}
		};

		const callEditOrderListeners = _.debounce(async (order, previousOrder, orderChanges) => {
			if (!EditOrder.editOrderListeners?.length) {
				return;
			}

			disableSaveButton(EditOrder.disabledSaveReasons.pendingIntegrationRequest);
			for (const integration of EditOrder.editOrderListeners) {
				try {
					if (!integrationCalls[integration.id]) {
						integrationCalls[integration.id] = {};
					}
					integrationCalls[integration.id].currentOrderEditCall?.cancel();

					const previousOrderForIntegration = integrationCalls[integration.id].orderBefore || previousOrder;

					integrationCalls[integration.id].currentOrderEditCall = makeCancelable(
						Tools.StandardIntegration.data(EditOrder.customerId, { params: { hideBodyLog: true } })
							.run({
								data: {
									order,
									previousOrder: previousOrderForIntegration,
									changes: previousOrderForIntegration
										? getOrderChanges(order, previousOrderForIntegration)
										: null
								},
								type: 'orderedit',
								integrationId: integration.id
							})
							.catch(() => {
								// eslint-disable-next-line no-undef
								enableSaveButton();
								integrationCalls[integration.id].currentOrderEditCall?.cancel();
								return;
							})
					);

					integrationCalls[integration.id].orderBefore = { ..._.cloneDeep(order) };

					const fieldActions = (await integrationCalls[integration.id].currentOrderEditCall.promise).data;

					if (EditOrder.hasAppFrameworkListenTo && fieldActions.listenTo?.order) {
						integration.listenTo = OnOrderEditHelpers.getKeysAsPaths(
							fieldActions.listenTo.order,
							EditOrder.order,
							true,
							undefined,
							undefined,
							true
						);
					}

					if (EditOrder.hasOnOrderEditPerformanceEnhanced) {
						if (JSON.stringify(fieldActions) !== integrationCalls[integration.id]?.previousFieldActions) {
							await updateOrderFromApp(
								fieldActions,
								integrationCalls[integration.id].orderBefore,
								order,
								orderChanges
							);
						}

						integrationCalls[integration.id].previousFieldActions = JSON.stringify(fieldActions);
					} else {
						await updateOrderFromApp(
							fieldActions,
							integrationCalls[integration.id].orderBefore,
							order,
							orderChanges
						);
					}
				} catch (err) {
					logError(err, `OnOrderEdit error for integration ${integration.id}`);
				}
			}
			enableSaveButton();

			if (EditOrder.hasAppFrameworkListenTo) {
				setupOrderListenTo();
			}
		}, 500);

		const callEditOrderListenersIfChanged = (updatedOrder, previousOrder) => {
			const orderChanges = getOrderChanges(updatedOrder, previousOrder);

			if (EditOrder.listenTo) {
				const shouldRunCallEditOrderListeners = EditOrder.listenTo.some(field => {
					if (!orderChanges) {
						return false;
					}

					if (field.includes('orderRow') && orderChanges.orderRow) {
						return orderChanges.orderRow.some(orderRowChange => {
							if (field.includes('custom')) {
								if (!orderRowChange.custom) {
									return false;
								}

								return orderRowChange.custom.some(customFieldChange => {
									const customFieldId = OnOrderEditHelpers.getCustomFieldIdFromPath(field);
									if (!customFieldId) {
										return false;
									}
									return customFieldId === customFieldChange.id;
								});
							} else {
								const attribute = field.split('.').pop();
								return _.has(orderRowChange, attribute);
							}
						});
					}

					if (field.includes('custom')) {
						if (!orderChanges.custom) {
							return false;
						}
						const customFieldId = OnOrderEditHelpers.getCustomFieldIdFromPath(field);
						return orderChanges.custom.some(customFieldChange => {
							return customFieldId === customFieldChange.id;
						});
					}
					return _.has(orderChanges, field);
				});

				if (!shouldRunCallEditOrderListeners) {
					return;
				}
			}

			if (orderChanges) {
				if (!EditOrder.lastChangesFromEditListener) {
					// eslint-disable-next-line no-undef
					callEditOrderListeners(_.cloneDeep(updatedOrder), previousOrder, orderChanges);
				} else {
					// This check is needed to prevent infinite loop, since updates from the callEditOrderListeners will trigger this watcher again
					const changesFromEditListener =
						JSON.stringify(orderChanges || '') === EditOrder.lastChangesFromEditListener;
					if (!changesFromEditListener) {
						// eslint-disable-next-line no-undef
						callEditOrderListeners(_.cloneDeep(updatedOrder), previousOrder, orderChanges);
					}
				}
			}
		};

		EditOrder.onOrderRowCustomFieldChange = _.debounce((orderRowId, orderRowSortId, updatedMappedCustomFields) => {
			if (!EditOrder.editOrderListeners?.length) {
				return;
			}

			const orderRowIndex = EditOrder.order.orderRow.findIndex(
				row => (row.id && row.id === orderRowId) || row.sortId === orderRowSortId
			);
			if (orderRowIndex === -1) {
				return;
			}

			const orderRow = EditOrder.order.orderRow[orderRowIndex];
			const updatedCustomFields = Object.values(updatedMappedCustomFields);
			let changedCustomFields = customFieldsChanged(updatedCustomFields, orderRow.custom);
			if (EditOrder.hasOnOrderEditPerformanceEnhanced) {
				changedCustomFields = changedCustomFields?.filter(changedCustomField => {
					const field = `custom.${changedCustomField.id}`;
					const fieldName = `orderrow.${field}`;
					const orderRowFieldName = `orderrow@${orderRowId}.${field}`;
					const orderRowSortFieldName = `orderrow#${orderRowSortId}.${field}`;
					return EditOrder.isVisibleField(
						fieldName,
						true,
						orderRowFieldName,
						undefined,
						orderRowSortFieldName
					);
				});
			}

			if (!changedCustomFields?.length) {
				return;
			}

			const updatedOrderRows = [...EditOrder.order.orderRow];
			updatedOrderRows[orderRowIndex] = { ...orderRow, custom: updatedCustomFields };
			const updatedOrder = {
				...EditOrder.order,
				orderRow: updatedOrderRows
			};

			callEditOrderListenersIfChanged(updatedOrder, EditOrder.order);
		}, 500);

		EditOrder.validateEmail = mail => {
			if ((mail?.length ?? 0) === 0) {
				return true;
			}

			return ValidationService.validEmail(mail);
		};

		EditOrder.getPastDate = () => {
			if (!EditOrder.oldDateCondition(false, true)) {
				return null;
			}
			return EditOrder.order.date;
		};

		EditOrder.openOpportunityWithSubs = preSelectedStage => {
			openModal('WonOpportunityWithSubs', {
				order: EditOrder.order,
				client: EditOrder.order.client,
				currentStage: EditOrder.order.stage.name,
				preSelectedStage: preSelectedStage,
				activeAgreementGroups: EditOrder.activeAgreementGroups,
				openWonModalFollowedByDiffOrder: () => {
					EditOrder.skipButtonOnWonModal = true;
					EditOrder.visibleWon = true;
					setTimeout(() => {
						$upModal.open('editOrder', { id: EditOrder.order.id });
					}, 2500);
					$safeApply($scope);
				},
				saveWithStageAndOpenWonModal: async stage => {
					EditOrder.skipButtonOnWonModal = true;
					EditOrder.order.stage = stage;
					EditOrder.order.probability = stage.probability;
					await EditOrder.save(true, true, true);
					EditOrder.visibleWon = true;
					$safeApply($scope);
				}
			});
		};

		EditOrder.onWinLoss = async (stage, date) => {
			if (date) {
				EditOrder.order.date = date;
			}

			const oldStage = { ...EditOrder.order.stage };
			const oldProbability = EditOrder.order.probability;

			if (EditOrder.hasOrderCustomStages) {
				EditOrder.order.stage = stage;
				EditOrder.order.probability = stage.probability;

				EditOrder.stageSelectChanged();
				$safeApply($scope);
				if (!EditOrder.validateRequiredFields(stage)) {
					return;
				}
			}
			EditOrder.savedThroughWinLossFlow = true;

			const shouldOpenNewModal = EditOrder.wonOpportunityWithSubs();
			if (shouldOpenNewModal && stage.probability === 100) {
				openModal('WonOpportunityWithSubs', {
					order: EditOrder.order,
					client: EditOrder.order.client,
					currentStage: oldStage.name,
					activeAgreementGroups: EditOrder.activeAgreementGroups,
					openWonModalFollowedByDiffOrder: () => {
						EditOrder.skipButtonOnWonModal = true;
						EditOrder.visibleWon = true;
						setTimeout(() => {
							$upModal.open('editOrder', { id: EditOrder.order.id });
						}, 2500);
						$safeApply($scope);
					},
					saveWithStageAndOpenWonModal: async stage => {
						EditOrder.skipButtonOnWonModal = true;
						EditOrder.order.stage = stage;
						EditOrder.order.probability = stage.probability;
						await EditOrder.save(true, true, true);
						EditOrder.visibleWon = true;
						$safeApply($scope);
					},
					cancel: () => {
						EditOrder.order.stage = oldStage;
						EditOrder.order.probability = oldProbability;
						EditOrder.stageSelectChanged();
						EditOrder.savedThroughWinLossFlow = false;

						$safeApply($scope);
					}
				});
			} else {
				EditOrder.order.stage = stage;
				EditOrder.order.probability = stage.probability;

				await EditOrder.save(true, true, true);
				if (!EditOrder.saveError) {
					if (stage.probability === 100) {
						EditOrder.visibleWon = true;
					} else {
						EditOrder.visibleLost = true;
					}
				}
			}
			$safeApply($scope);
		};

		EditOrder.changeOrderCloseDate = date => {
			EditOrder.order.closeDate = date;
			return EditOrder.save(true, true, true);
		};

		EditOrder.changeInvoiceRelatedClient = value => {
			EditOrder.order.invoiceRelatedClient = value;
			$safeApply($scope);
		};

		EditOrder.saveLostReason = (lostReason, competitorId) => {
			EditOrder.order.lostReason = lostReason;
			EditOrder.order.competitorId = competitorId;
			return EditOrder.save(true, true, true);
		};

		const getRiskChipProps = order => {
			const risk = getOrderForecastingRisk(order);

			if (!risk) {
				return null;
			}

			return {
				title: $translate.instant(`forecastingRisk.${risk.type}`),
				chipType: risk.type === ForecastingRiskLevel.High ? 'danger' : 'alert',
				tooltip: risk.tooltip(order),
				riskName: risk.field,
				riskOccured: order.risks[risk.field]
			};
		};

		EditOrder.EditProductBundleComponent = EditProductBundleOrder;
		EditOrder.WonOpportunity = WonOpportunity;
		EditOrder.RiskChip = RiskChip;
		EditOrder.LostOpportunity = LostOpportunity;
		EditOrder.SaveButton = EditOrderSaveButton;
		EditOrder.OrderHistory = OrderHistory;
		EditOrder.InvoiceRelatedClient = InvoiceRelatedClient;
		EditOrder.hasMarketContribution = Tools.FeatureHelper.hasSoftDeployAccess('ORDER_MARKETING_CONTRIBUTION');
		EditOrder.hasSubAccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		EditOrder.hasTodo = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		EditOrder.hasPriceLists =
			FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.PRICE_LISTS) &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.PRICE_LISTS);
		EditOrder.hasProductBundles =
			FeatureHelper.hasSoftDeployAccess('PRODUCT_BUNDLE') &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.PRODUCT_BUNDLES);
		EditOrder.notesFilterOrder = ['opportunity', 'client'];
		EditOrder.OpportunityTodos = OpportunityTodos;
		EditOrder.OpportunityCommentInput = CommentInput;
		EditOrder.CopyableId = CopyableId;
		EditOrder.OpportunityAI = OpportunityAI;
		EditOrder.OpportunityWinLossButtons = OpportunityWinLossButtons;
		EditOrder.AppsHistory = AppsHistory;
		EditOrder.AccountPlanSidebar = AccountPlanSidebar;
		EditOrder.TicketWidget = TicketWidget;
		EditOrder.ProjectPlanHandler = ProjectPlanHandler;
		EditOrder.hasSupportAccess =
			FeatureHelper.hasSoftDeployAccess('SUPPORT_SERVICE_V2') &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.CUSTOMER_SUPPORT);
		EditOrder.documentTemplates = null;
		EditOrder.editable = true;
		EditOrder.saving = false;
		EditOrder.editContact = false;
		EditOrder.editContactModel = {};
		EditOrder.contactFormIsEdit = false;
		EditOrder.customerId = AppService.getCustomerId();
		EditOrder.relatedClients = [];
		EditOrder.probabilityActive = false;
		EditOrder.hideProbability = true;
		EditOrder.hasAccountPlan = FeatureHelper.hasSoftDeployAccess('ACCOUNT_PLAN');
		EditOrder.hasPeriodization = FeatureHelper.isAvailable(FeatureHelper.Feature.PERIODIZATION);
		EditOrder.hasStakeholders = FeatureHelper.isAvailable(FeatureHelper.Feature.STAKEHOLDERS);
		EditOrder.hasSalesProcessFeature = FeatureHelper.isAvailable(FeatureHelper.Feature.SALES_PROCESS);
		EditOrder.hasSalesProcessProFeature = FeatureHelper.isAvailable(FeatureHelper.Feature.SALES_PROCESS_PRO);
		EditOrder.salesCoachDecisionMaker = {};
		EditOrder.newTitleCategories = [];
		EditOrder.decisionMakersEnabled = false;
		EditOrder.oldStakeholdersEnabled = false;
		EditOrder.hasSalesCoach = false;
		EditOrder.hasEsignActivated = integrations.length > 0 ? true : false;
		EditOrder.hasOrderCustomStages = Tools.FeatureHelper.isAvailable(FeatureHelper.Feature.ORDER_CUSTOM_STAGES);
		EditOrder.FieldSync = FieldSync;
		EditOrder.isPeriodizing = false;
		EditOrder.hasProjectPlanFeature = FeatureHelper.isAvailable(FeatureHelper.Feature.PROJECT_PLAN);
		EditOrder.productsWithProjectPlanTemplates = [];
		EditOrder.editProductBundleProps = null;
		EditOrder.UiElements = UiElements;
		EditOrder.hasUiElementsOpenNewOpportunity = FeatureHelper.hasSoftDeployAccess(
			'APP_FRAMEWORK_UI_ELEMENTS_NEW_OPPORTUNITY'
		);
		EditOrder.hasProductSearch =
			FeatureHelper.hasSoftDeployAccess('PRODUCT_SEARCH') &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.PRODUCT_SEARCH);
		EditOrder.hasActivitiesAndAppointments = FeatureHelper.isAvailable(
			FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS
		);
		EditOrder.quantityPriceTitle = () => {
			let quantityPriceTitle = '';
			const quantityIsVisible = EditOrder.isVisibleField('orderrow.quantity');
			const priceIsVisible = EditOrder.isVisibleField('orderrow.price');

			if (quantityIsVisible) {
				quantityPriceTitle += $translate.instant('default.quantity');
			}
			if (quantityIsVisible && priceIsVisible) {
				quantityPriceTitle += ' / ';
			}
			if (priceIsVisible) {
				quantityPriceTitle += `${$translate.instant('default.price')} (${$translate.instant(
					'default.netAmount'
				)})`;
			}

			return quantityPriceTitle;
		};

		EditOrder.discountTitle = () => {
			const discountVisible = EditOrder.isVisibleField('orderrow.discount');
			const discountPercentVisible = EditOrder.isVisibleField('orderrow.discountpercent');
			const showTitle = discountVisible || discountPercentVisible;
			return showTitle ? $translate.instant('default.discount') : '';
		};

		EditOrder.hasAppFrameworkOnOrderEdit = FeatureHelper.hasSoftDeployAccess('APP_FRAMEWORK_ON_ORDER_EDIT');
		EditOrder.hasOnOrderEditPerformanceEnhanced = Tools.FeatureHelper.hasSoftDeployAccess(
			'ON_ORDER_EDIT_PERFORMANCE_ENHANCED'
		);
		EditOrder.editOrderListeners = EditOrder.hasAppFrameworkOnOrderEdit
			? metadata.integrations.inits.after_order_edit
			: [];

		if (Tools.FeatureHelper.hasSoftDeployAccess('INPUT_DEBOUNCE_SETTING')) {
			const appsWithInputDebounce = Tools.AppService.getMetadata().integrations.active.filter(
				app => !!app.inputDebounceInMs
			);
			if (appsWithInputDebounce.length > 0) {
				//use max value for now but since you can have different values for different integrations
				//but maybe you need a input debounce for each integration in the future instead of just one
				EditOrder.inputDebounceSetting = Math.max(...appsWithInputDebounce.map(app => app.inputDebounceInMs));
			}
		}

		EditOrder.editProductBundleOpen = uuid => {
			const { client, currency, orderRow, recurringInterval, priceListId } = EditOrder.order;

			// Add uuid as it is required by OrderContext
			EditOrder.editProductBundleProps = {
				uuid,
				isAgreement: false,
				order: {
					client,
					priceListId,
					currency,
					recurringInterval,
					orderRow: _.cloneDeep(orderRow).map((orderRow, index) => ({
						...orderRow,
						uuid: index,
						priceListId,
						tierQuantity: orderRow.tierQuantity ?? orderRow.quantity,
						quantity: isTieredTotalOrderRow(orderRow) && orderRow.quantity !== 0 ? 1 : orderRow.quantity, // Should not have to do this but this controller sets quantity === tierQuantity....
						bundleRows: orderRow.bundleRows?.map((bundleRow, index) => ({
							...bundleRow,
							uuid: index,
							priceListId,
							latestDiscountChange: 'percent'
						}))
					}))
				},

				save: product => {
					const orderRow = EditOrder.order.orderRow[EditOrder.editProductBundleProps.uuid];
					orderRow.discount = orderRow.$discount;
					orderRow.discountPercent = orderRow.$discountPercent;
					const updatedOrderRow = bundleUpdateHandler(
						{ orderInterval: recurringInterval },
						orderRow,
						product,
						currency
					);
					EditOrder.editProductBundleSave(updatedOrderRow);
				}
			};

			openModal('EditProductBundleOrder', EditOrder.editProductBundleProps);

			$safeApply($scope);
		};

		EditOrder.editProductBundleSave = (updatedOrderRow, skipRender) => {
			const orderRow = EditOrder.order.orderRow[EditOrder.editProductBundleProps.uuid];
			// This is how angular works
			Object.assign(orderRow, updatedOrderRow);

			orderRow.$listPrice = orderRow.listPrice;
			orderRow.$discount = orderRow.discount;
			orderRow.$discountRaw = orderRow.discount;
			if (!skipRender) {
				$safeApply($scope);
			}
		};

		EditOrder.onSave = () => {
			EditOrder.save(false, false, true);
		};

		EditOrder.onSaveAndContinue = () => {
			EditOrder.save(true, false, true);
		};

		EditOrder.customerHasUiScripts =
			Tools.FeatureHelper.isAvailable(FeatureHelper.Feature.UI_SCRIPTS) &&
			Tools.AppService.getScripts().some(
				script => script.active && ['order_edit', 'order_save'].includes(script.type)
			);

		EditOrder.customerHasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.NEW_FIELDS);

		EditOrder.showWinLossButtons = () => {
			return (
				EditOrder.editable &&
				EditOrder.order.id &&
				!EditOrder.isOrder &&
				!EditOrder.customerHasUiScripts &&
				EditOrder.customerHasNewFields &&
				metadata.standardFields?.Order.OpportunityWonLost?.active
			);
		};

		EditOrder.copyButtonVisible =
			!Tools.FeatureHelper.hasSoftDeployAccess('HIDE_COPY_ORDER_BUTTON') ||
			metadata.standardFields?.Order.ShowCopyButton?.active;

		/*
			Stupid hack to make react-driver not have to watch the whole object.
			I am setting the EditOrder.reRenderCounter variable on the big watch on the full order object
			in this controller, and then watching that one. These functions are then sent into react-driver
			to fetch the retrive this data there without having to watch it.
		*/
		EditOrder.getNoneWatchedProps = {
			OpportunityTodos: () => ({
				selectUser: true,
				opportunity: EditOrder.order
			}),
			UiElements: () => ({
				object: EditOrder.order,
				type: 'editOrder',
				utils: EditOrder.uiElementUtils,
				elements: EditOrder.uiElements.sidebar
			}),
			OpportunityAI: () => ({
				opportunity: EditOrder.order,
				updateOrder: EditOrder.updateOrder,
				updateProbability: EditOrder.updateProbability,
				setCreateData: EditOrder.setCreateData
			})
		};

		EditOrder.getPartialOrder = () => {
			const { id, client, date, closeDate, user, oneOffValue, monthlyValue, annualValue, source } =
				EditOrder.order;
			return {
				id,
				client,
				date,
				closeDate,
				user,
				source,
				oneOffValue,
				monthlyValue,
				annualValue
			};
		};

		EditOrder.cachedProductSearches = {};
		EditOrder.cacheProductSearch = (query, results) => {
			if (Tools.FeatureHelper.hasSoftDeployAccess('SPEED_EDIT_ORDER_PRODUCT_SEARCH')) {
				EditOrder.cachedProductSearches[query] = results;
			}
		};

		EditOrder.fixWebpage = () => {
			if (EditOrder.account.webpage && EditOrder.account.webpage.indexOf('http') !== 0) {
				EditOrder.account.webpage = 'http://' + EditOrder.account.webpage;
			}
			return EditOrder.account.webpage;
		};

		EditOrder.links = [];

		// When a file is uploaded we check if it is connected to this order
		$scope.$on('file.uploaded', function (e, file) {
			if (file.entity === 'Order' && file.entityId === EditOrder.order.id) {
				EditOrder.files.push(file);
			}
		});

		$scope.$on('file.deleted', function (e, deletedFile) {
			_.remove(EditOrder.files, { id: deletedFile.id });
		});

		$scope.$on('modal.rejected', function (evt) {
			const speedTracker = getSpeedTracker('EditOrder');
			speedTracker?.endTracking();

			if (EditOrder.tabs.isActive('form') && $scope.OrderForm.$dirty) {
				evt.preventDefault();
				$scope.showInlineAction = 'bottom';
			} else {
				$scope.showInlineAction = 'none';
				$scope.reject();
			}
		});

		const saveDecisionMakers = function (isTitleCategories) {
			const decisionMakersData = isTitleCategories
				? { id: EditOrder.order.id, titleCategories: EditOrder.order.titleCategories, skipTriggers: true }
				: { id: EditOrder.order.id, stakeholders: EditOrder.order.stakeholders, skipTriggers: true };

			Order.customer($stateParams.customerId).save(decisionMakersData, { skipNotification: true });
		};

		var activityAppointmentEvent = function (e, added) {
			if (added.opportunity && added.opportunity.id === EditOrder.order.id) {
				if (!EditOrder.activityUpcoming) {
					EditOrder.activityUpcoming = [];
				}
				if (!EditOrder.activityHistory) {
					EditOrder.activityHistory = [];
				}

				var event = EventService.create.Activity(added);
				event.date = added.date;

				// If this was an update we find and remove the old event first
				var foundEvent;
				if (e.name === 'activity.updated' || e.name === 'appointment.updated') {
					// Look for open activity that matches
					foundEvent = _.find(EditOrder.activityUpcoming, { entityId: added.id });

					// Look some more
					if (foundEvent) {
						_.remove(EditOrder.activityUpcoming, foundEvent);
					} else {
						foundEvent = _.find(EditOrder.activityHistory, { entityId: added.id });
						if (foundEvent) {
							_.remove(EditOrder.activityHistory, foundEvent);
						}
					}
				}

				// Then we add it to the correct list
				if (!added.isAppointment && added.closeDate === null) {
					EditOrder.activityUpcoming.push(event);
				} else if (!added.isAppointment && added.closeDate !== null) {
					EditOrder.activityHistory.push(event);
				} else if (added.isAppointment && moment(added.date) >= moment()) {
					EditOrder.activityUpcoming.push(event);
				} else if (added.isAppointment && moment(added.date) < moment()) {
					EditOrder.activityHistory.push(event);
				}

				// If we save a sales process with title categories then we send the contact to the decision makers sidebar
				if (
					EditOrder.decisionMakersEnabled &&
					EditOrder.salesCoachDecisionMaker?.hasTitleCategories &&
					added.contacts.length > 0 &&
					(added.date === null || moment(added.date) >= moment())
				) {
					let addedTitleCategories = added.contacts.map(contact => {
						return {
							contactId: contact.id,
							tagId: contact.titleCategory || null,
							contact: { id: contact.id, name: contact.name }
						};
					});
					addedTitleCategories = addedTitleCategories.filter(
						addedTitleCategory =>
							addedTitleCategory.tagId &&
							!EditOrder.order.titleCategories.find(
								existingTitleCategory =>
									existingTitleCategory.contactId === addedTitleCategory.contactId
							)
					);
					if (addedTitleCategories.length > 0) {
						const newTitleCategories = [...EditOrder.order.titleCategories, ...addedTitleCategories];
						EditOrder.order.titleCategories = newTitleCategories; // Update order
						EditOrder.newTitleCategories = newTitleCategories; // Update decision maker sidebar
						saveDecisionMakers(true);
					}
				}

				// update modal position
				$scope.reloadModalPosition();
			}
		};

		EditOrder.setCreateData = createData => {
			createData.count = _.get(EditOrder.createData, 'count', 0) + 1;
			EditOrder.createData = createData;
			$safeApply($scope);
		};

		EditOrder.onSetJourneyStepClient = function (journeyStep) {
			if (!EditOrder.account) {
				return $q.resolve();
			}
			return Account.customer(customerId)
				.save({
					id: EditOrder.account.id,
					journeyStep: journeyStep
				})
				.then(function (res) {
					EditOrder.account.journeyStep = res.data.journeyStep;
					EditOrder.account = angular.copy(EditOrder.account);
				});
		};

		$scope.$on('activity.added', activityAppointmentEvent);
		$scope.$on('activity.updated', activityAppointmentEvent);
		$scope.$on('appointment.added', activityAppointmentEvent);
		$scope.$on('appointment.updated', activityAppointmentEvent);

		EditOrder.apps = metadata.integrations.active;

		// Tabs
		EditOrder.tabs = {
			dashboard: 'upsales/domain/order/views/editOrderDashboard.html',
			form: 'upsales/domain/order/views/editOrderForm.html',
			files: 'upsales/domain/order/views/editOrderFiles.html',
			apps: 'upsales/domain/order/views/editOrderApps.html',
			projects: 'upsales/domain/order/views/editOrderProjects.html',
			isActive: function (tab) {
				return this[tab] === EditOrder.activeTab;
			},
			setActive: function (tab) {
				EditOrder.activeTab = this[tab];
			}
		};

		EditOrder.togglePeriodization = function (saveFirst) {
			if (!saveFirst) {
				EditOrder.isPeriodizing = !EditOrder.isPeriodizing;
				$safeApply($scope);
			} else {
				EditOrder.save(true, false, true)
					.then(function () {
						EditOrder.isPeriodizing = !EditOrder.isPeriodizing;
						$safeApply($scope);
					})
					.catch(err => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			}
		};

		EditOrder.savePeriodization = function () {
			EditOrder.togglePeriodization();
		};

		EditOrder.removePeriodization = function () {
			EditOrder.togglePeriodization();
		};

		EditOrder.updateStakeholders = function (stakeholders) {
			EditOrder.order.stakeholders = stakeholders;
			if (EditOrder.order.id && EditOrder.order.salesCoach) {
				saveDecisionMakers(false);
			}
		};

		EditOrder.updateTitleCategories = function (titleCategories) {
			EditOrder.order.titleCategories = titleCategories;
			if (EditOrder.order.id && EditOrder.order.salesCoach) {
				saveDecisionMakers(true);
			}
		};

		EditOrder.isPeriodized = function () {
			return !!EditOrder.order.periodization;
		};

		// Order row calculation functions
		EditOrder.calc = {
			totalGross: function () {
				return EditOrder.order.orderRow.reduce(function (prev, current) {
					const price = current.price > current.$listPrice ? current.price : current.$listPrice;
					if (isTieredTotalOrderRow(current)) {
						return prev + price * 1;
					} else {
						return prev + price * current.quantity;
					}
				}, 0);
			},
			totalDiscount: function () {
				return EditOrder.order.orderRow.reduce(function (prev, current) {
					var val = prev + parseFloat(current.$discountRaw);

					return val;
				}, 0);
			},
			totalNet: function () {
				return EditOrder.order.orderRow.reduce(function (prev, current) {
					return (
						prev + (isTieredTotalOrderRow(current) ? current.price * 1 : current.price * current.quantity)
					);
				}, 0);
			},
			totalContributionMargin: function () {
				return EditOrder.order.orderRow.reduce(function (val, row) {
					if (!row) {
						return val;
					}

					if (isTieredTotalOrderRow(row)) {
						return val + ((row.price || 0) - (row.purchaseCost || 0) * row.quantity);
					} else {
						return val + ((row.price || 0) - (row.purchaseCost || 0)) * row.quantity;
					}
				}, 0);
			},
			totalContributionMarginPercentage: () => {
				const totalNet = EditOrder.calc.totalNet();

				if (!totalNet || Math.abs(totalNet) < 1e-10) {
					return 0;
				}
				const per = EditOrder.calc.totalContributionMargin() / totalNet;
				return !isNaN(per) ? (per * 100).toFixed(2) : 0;
			},
			totalAccountSales: function () {
				return EditOrder.accountTotal;
			},
			convertedNet: function () {
				return this.totalNet() * (1 / EditOrder.order.currencyRate);
			},
			totalRR: function () {
				return EditOrder.order.orderRow.reduce(function (val, row) {
					if (!row || !row.product || !row.product.isRecurring) {
						return val;
					}

					const monthlyValue = (row.price || 0) / EditOrder.order.recurringInterval;
					const annualValue = monthlyValue * 12;

					const value = EditOrder.salesModelOption === 'mrr' ? monthlyValue : annualValue;
					const quantity = isTieredTotalOrderRow(row) ? 1 : row.quantity;

					return val + (value || 0) * quantity;
				}, 0);
			},
			totalOneOff: function () {
				return EditOrder.order.orderRow.reduce(function (val, row) {
					if (!row || !row.product || row.product.isRecurring) {
						return val;
					}

					const quantity = isTieredTotalOrderRow(row) ? 1 : row.quantity;

					return val + (row.price || 0) * quantity;
				}, 0);
			},
			productChange: function (row, keepQuantity) {
				const priceList = EditOrder.priceList;
				const selectedCurrency = EditOrder.selectedCurrency;
				row.priceListId = priceList.id;
				if (!row.product) {
					return;
				}

				if (row.product.bundle?.length) {
					// Reset discount when chaning product or price list
					row.discountPercent = 0;
					row.discount = 0;
					row.bundleFixedPrice = row.product.bundleFixedPrice;

					const bundleOrderRow = resetAndGetBundleOrderRow(
						row,
						selectedCurrency.iso,
						EditOrder.order.recurringInterval,
						keepQuantity ? row.quantity : undefined,
						true
					);

					// This is how angular works
					Object.assign(row, bundleOrderRow);

					row.$discount = row.discount;
					row.$listPrice = row.listPrice;
					row.category = row.product.category;
				} else {
					row.bundleRows = [];

					// Set listprice here and overwrite if multicurrency and selected prod has multi values
					row.listPrice = row.product.listPrice;
					row.$listPrice = row.product.listPrice;

					row.category = row.product.category;
					if (!keepQuantity) {
						row.quantity = row.tierQuantity = row.oldQuantity = 1;
					}
					row.$discount = 0;

					// Check if we have a matching tier and set price
					const tierObj = getTierFromOrderRow(row);
					const priceObj = _.find(row.product.currencies, {
						currency: selectedCurrency.iso,
						priceListId: priceList.id
					});

					const fallbackValue = selectedCurrency.masterCurrency ? row.product.purchaseCost : 0;
					row.purchaseCost = priceObj?.purchaseCost || fallbackValue;

					if (priceObj) {
						const listPrice = priceObj.price;
						row.$listPrice = row.listPrice = listPrice;

						if (EditOrder.showPriceListDiscount) {
							row.price = listPrice;
							const defaultPriceObj = row.product.currencies.find(
								currency =>
									currency.currency === selectedCurrency.iso &&
									currency.priceListId === EditOrder.defaultPriceListId
							);
							if (defaultPriceObj && defaultPriceObj.price > listPrice) {
								row.$listPrice = row.listPrice = defaultPriceObj.price;
							}
						}
					} else if (
						!tierObj &&
						(row.product.isMultiCurrency || hasMultiCurrency) &&
						!selectedCurrency.masterCurrency
					) {
						row.$listPrice = row.listPrice = 0;
					}

					if (tierObj) {
						const listPrice = getTierPriceByCurrency(tierObj.tier, selectedCurrency.iso);
						row.$listPrice = row.listPrice = listPrice;
						row.price = listPrice;

						if (EditOrder.showPriceListDiscount) {
							const defaultListTierObj = getTierFromOrderRow(row, EditOrder.defaultPriceListId);
							const defaultPriceObj = _.find(defaultListTierObj.tier.currencies, {
								currency: selectedCurrency.iso
							});

							if (defaultPriceObj && defaultPriceObj.value > listPrice) {
								row.$listPrice = row.listPrice = defaultPriceObj.value;
							}
						}
						if (tierObj.tier.isTotalPrice) {
							row.tierQuantity = row.quantity;
						}
					}

					row.price = EditOrder.calc.adjustPriceIfRecurring(row.price, row.product);
					row.listPrice = EditOrder.calc.adjustPriceIfRecurring(row.listPrice, row.product);
					row.$listPrice = EditOrder.calc.adjustPriceIfRecurring(row.$listPrice, row.product);
				}

				row = fixProductFields(row);
				if (EditOrder.showPriceListDiscount) {
					row.$discountPercent = undefined;
					EditOrder.calc.calculateDiscount(row);
				}

				row.maxDiscount = getMaxDiscount(row);
				EditOrder.calc.discountChange(row);
				$scope.OrderForm.$setDirty(true);
			},
			hasExcessiveDiscount: function (orderRow) {
				const percentage =
					orderRow.$discountPercent ??
					(isNaN(orderRow.$percentPlaceholder) ? 0 : Number(orderRow.$percentPlaceholder)) ??
					0;
				return (
					orderRow.maxDiscount !== null &&
					orderRow.maxDiscount !== undefined &&
					percentage > orderRow.maxDiscount
				);
			},
			// On discountChange
			discountChange: function (orderRow) {
				if (orderRow.$discount !== undefined && !isNaN(orderRow.$discount)) {
					orderRow.$discountPercent = undefined;
					orderRow.$discountRaw = parseFloat(orderRow.$discount);
					let quantity = orderRow.quantity;

					if (orderRow.$discount !== 0 && orderRow.$listPrice === 0) {
						orderRow.$percentPlaceholder = '∞';
					} else {
						if (isTieredTotalOrderRow(orderRow)) {
							quantity = 1;
						}

						orderRow.$percentPlaceholder = (
							(orderRow.$discountRaw / (orderRow.$listPrice * quantity)) *
							100
						).toFixed(EditOrder.numOfDecimalsPrice);

						if (isNaN(orderRow.$percentPlaceholder)) {
							orderRow.$percentPlaceholder = 0;
						}
					}

					// calc price
					orderRow.price = orderRow.$listPrice - orderRow.$discountRaw / quantity;
					orderRow.listPrice = orderRow.$listPrice;

					if (isNaN(orderRow.price)) {
						orderRow.price = 0;
					}
					if (orderRow.$discount < 0) {
						orderRow.$discount = orderRow.$discountRaw = orderRow.$percentPlaceholder = 0;
					}
					orderRow.hasExcessiveDiscount = EditOrder.calc.hasExcessiveDiscount(orderRow);

					// Used by order context (need for bundle summaries)
					orderRow.orderRowTotal = (orderRow.price ?? 0) * (orderRow.quantity ?? 0);
					if (orderRow.product?.isRecurring) {
						orderRow.recurringValue = getRecurringValue(
							orderRow.orderRowTotal,
							EditOrder.order.recurringInterval
						);
					}
				}
			},

			// On discount percent
			discountPercentChange: function (orderRow) {
				if (orderRow.$discountPercent !== undefined && !isNaN(orderRow.$discountPercent)) {
					orderRow.$discount = undefined;

					let quantity = orderRow.quantity;
					if (isTieredTotalOrderRow(orderRow)) {
						quantity = 1;
					}

					orderRow.$discountRaw = parseFloat(
						((orderRow.$discountPercent / 100) * orderRow.$listPrice * quantity).toFixed(
							EditOrder.numOfDecimalsPrice
						)
					);

					if (isNaN(orderRow.$discountRaw)) {
						orderRow.$discountRaw = 0;
					}

					// calc price
					orderRow.price = orderRow.$listPrice - orderRow.$discountRaw / quantity;
					orderRow.listPrice = orderRow.$listPrice;

					if (isNaN(orderRow.price) || orderRow.$discountPercent === 100) {
						orderRow.price = 0;
					}
					orderRow.hasExcessiveDiscount = EditOrder.calc.hasExcessiveDiscount(orderRow);

					// Used by order context (need for bundle summaries)
					orderRow.orderRowTotal = (orderRow.price ?? 0) * (quantity ?? 0);
					if (orderRow.product?.isRecurring) {
						orderRow.recurringValue = getRecurringValue(
							orderRow.orderRowTotal,
							EditOrder.order.recurringInterval
						);
					}
				}
			},
			adjustPriceIfRecurring: (price, product, oldInterval = undefined) => {
				const productInterval = product.recurringInterval ?? getRecurringProductDefaultInterval(metadata);
				const hasRecurringInterval =
					product.isRecurring &&
					productInterval &&
					EditOrder.order.recurringInterval &&
					EditOrder.hasRecurringInterval;
				if (!hasRecurringInterval) {
					return price;
				}
				return price * (EditOrder.order.recurringInterval / (oldInterval ?? productInterval));
			},
			quantityChange: function (orderRow) {
				let quantity = orderRow.quantity;

				// Check if we have a matching tier and update price
				const tierObj = getTierFromOrderRow({ ...orderRow, tierQuantity: quantity });
				let listPrice, hasDiscount, discountCreatedByPriceList;
				if (tierObj) {
					listPrice = getTierPriceByCurrency(tierObj.tier, EditOrder.selectedCurrency.iso);
					listPrice = EditOrder.calc.adjustPriceIfRecurring(listPrice, orderRow.product);

					if (EditOrder.showPriceListDiscount) {
						discountCreatedByPriceList = isDiscountCreatedByPriceList();
					}

					hasDiscount = parseFloat(orderRow.$discountRaw) !== 0 && !discountCreatedByPriceList;

					if (!hasDiscount) {
						orderRow.price = listPrice;
					}
					orderRow.listPrice = listPrice;
					orderRow.$listPrice = listPrice;

					if (EditOrder.showPriceListDiscount) {
						if (!hasDiscount) {
							orderRow.price = listPrice;
						}

						const defaultListTierObj = getTierFromOrderRow(
							{ ...orderRow, tierQuantity: orderRow.quantity },
							EditOrder.defaultPriceListId
						);
						let defaultListPrice = getTierPriceByCurrency(
							defaultListTierObj.tier,
							EditOrder.selectedCurrency.iso
						);
						defaultListPrice = EditOrder.calc.adjustPriceIfRecurring(defaultListPrice, orderRow.product);

						if (defaultListPrice > listPrice) {
							if (!hasDiscount) {
								orderRow.price = listPrice;
							}
							orderRow.$listPrice = orderRow.listPrice = defaultListPrice;

							listPrice = defaultListPrice;
						}
					}

					if (isTieredTotalOrderRow({ ...orderRow, tierQuantity: quantity })) {
						quantity = 1;
						orderRow.tierQuantity = orderRow.quantity;
					}

					if (isNaN(orderRow.price)) {
						orderRow.price = 0;
					}
					orderRow.maxDiscount = getMaxDiscount(orderRow);
				}

				if (hasDiscount) {
					// Keep discount per unit
					orderRow.price = listPrice - parseFloat(orderRow.$discountRaw) / orderRow.oldQuantity;
				}

				EditOrder.calc.calculateDiscount(orderRow);

				if (tierObj) {
					// calc price
					orderRow.price = listPrice - orderRow.$discountRaw / (quantity || 1);
					orderRow.oldQuantity = quantity;
				}

				// Used by order context (need for bundle summaries)
				orderRow.orderRowTotal = (orderRow.price ?? 0) * (quantity ?? 0);
				if (orderRow.product?.isRecurring) {
					orderRow.recurringValue = getRecurringValue(
						orderRow.orderRowTotal,
						EditOrder.order.recurringInterval
					);
				}

				function isDiscountCreatedByPriceList() {
					if (!orderRow.oldQuantity) {
						orderRow.oldQuantity = quantity;
					}
					const oldTierObj = getTierFromOrderRow({
						...orderRow,
						tierQuantity: orderRow.oldQuantity,
						quantity: orderRow.oldQuantity
					});
					let oldTierPrice = oldTierObj
						? getTierPriceByCurrency(oldTierObj.tier, EditOrder.selectedCurrency.iso)
						: undefined;

					oldTierPrice = EditOrder.calc.adjustPriceIfRecurring(oldTierPrice, orderRow.product);

					return parseFloat(orderRow.price).toFixed(1) === oldTierPrice.toFixed(1);
				}
			},
			priceChange: function (orderRow) {
				function getProductPrice() {
					const priceListId = EditOrder.showPriceListDiscount
						? EditOrder.defaultPriceListId
						: EditOrder.priceList.id;
					const tierObj = getTierFromOrderRow({ ...orderRow, tierQuantity: orderRow.quantity }, priceListId);

					if (tierObj) {
						return getTierPriceByCurrency(tierObj.tier, EditOrder.selectedCurrency.iso);
					} else {
						const fallback =
							EditOrder.selectedCurrency.masterCurrency && EditOrder.priceList.isDefault
								? orderRow.product.listPrice
								: 0;

						const currencyObj = _.find(orderRow.product.currencies, {
							currency: EditOrder.selectedCurrency.iso,
							priceListId: priceListId
						});
						return currencyObj?.price || fallback;
					}
				}

				orderRow.$priceListId = EditOrder.priceList.id;
				orderRow.$discountPercent = undefined;

				if (getProductPrice() === 0) {
					orderRow.$listPrice = orderRow.price;
					orderRow.$discount = 0;
					orderRow.$discountRaw = 0;
					orderRow.$percentPlaceholder = 0;
				} else {
					EditOrder.calc.calculateDiscount(orderRow);
				}

				// Used by order context (need for bundle summaries)
				orderRow.orderRowTotal = (orderRow.price ?? 0) * (orderRow.quantity ?? 0);
				if (orderRow.product?.isRecurring) {
					orderRow.recurringValue = getRecurringValue(
						orderRow.orderRowTotal,
						EditOrder.order.recurringInterval
					);
				}
			},

			calculateDiscount: orderRow => {
				const percentFocused = orderRow.$discountPercent !== undefined && !isNaN(orderRow.$discountPercent);
				const percent = percentFocused
					? orderRow.$discountPercent
					: parseFloat(
							(100 - (orderRow.price / orderRow.$listPrice) * 100).toFixed(EditOrder.numOfDecimalsPrice)
					  );

				if (isNaN(percent)) {
					return;
				}

				const quantity = isTieredTotalOrderRow(orderRow) ? 1 : orderRow.quantity;
				if (orderRow.price > orderRow.$listPrice || orderRow.$listPrice === 0) {
					orderRow.$discount = undefined;
					orderRow.$discountRaw = 0;
					orderRow.$percentPlaceholder = 0;
				} else {
					if (percentFocused) {
						orderRow.$discountRaw = (orderRow.$listPrice * quantity * (percent / 100)).toFixed(
							EditOrder.numOfDecimalsPrice
						);
						orderRow.$discount = undefined;
					} else {
						orderRow.$discountRaw = ((orderRow.$listPrice - orderRow.price) * quantity).toFixed(
							EditOrder.numOfDecimalsPrice
						);
						orderRow.$percentPlaceholder = orderRow.$discountRaw !== 0 ? percent : 0;
						orderRow.$discount = parseFloat(orderRow.$discountRaw);
					}
				}
				orderRow.hasExcessiveDiscount = EditOrder.calc.hasExcessiveDiscount(orderRow);
			}
		};

		var getLinks = function () {
			EditOrder.links = [];
			if (!EditOrder.order || !EditOrder.order.id) {
				return;
			}
			EditOrder.loadingLinks = true;
			Links.customer(customerId)
				.get('order', EditOrder.order.id)
				.then(function (response) {
					EditOrder.links = response.data;
					EditOrder.loadingLinks = false;
				})
				.catch(function (err) {
					EditOrder.loadingLinks = false;
					EditOrder.linksErr = err;
				});
		};

		const setClientOrderRelationTitle = () => {
			let clientOrderRelationTitle = $translate.instant('default.clientConnection');
			if (
				!EditOrder.order.clientConnection ||
				!FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') ||
				!metadata.standardFields?.Order?.ClientOrderRelation?.active
			) {
				EditOrder.clientOrderRelationTitle = clientOrderRelationTitle;
				return;
			}

			// When the clientConnection is saved to the opportunity/order, we only have name and id.
			// So we need to lookup the full clientConnection object from the relatedClients array
			const clientConnection = EditOrder.relatedClients.find(
				relatedClient => relatedClient.id === EditOrder.order.clientConnection.id
			);

			if (!clientConnection) {
				EditOrder.clientOrderRelationTitle = clientOrderRelationTitle;
				return;
			}

			const foundFieldTranslationForRelation = Object.entries(EditOrder.clientOrderRelationTranslations).reduce(
				(foundTranslation, [, translations = []]) => {
					if (foundTranslation) {
						return foundTranslation;
					}
					const relationMatch = translations.find(
						translation => translation.value === clientConnection.descriptionChildParent
					);
					if (!relationMatch) {
						return null;
					}
					const language = self.language.split('-')[0];
					const foundMatchingLanguageTranslation = translations.find(
						translation => translation.language === language
					);
					if (!foundMatchingLanguageTranslation) {
						return null;
					}
					foundTranslation = foundMatchingLanguageTranslation;
					return foundTranslation;
				},
				null
			);

			if (!foundFieldTranslationForRelation) {
				EditOrder.clientOrderRelationTitle = clientOrderRelationTitle;
				return;
			}

			clientOrderRelationTitle += `- ${foundFieldTranslationForRelation.value}`;
			EditOrder.clientOrderRelationTitle = clientOrderRelationTitle;
		};

		const setClientOrderRelation = () => {
			// Set clientOrderRelation variable
			const clientRelationField = _.get(metadata.standardFields, 'Order.ClientOrderRelation', {});

			if (FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') && clientRelationField.active) {
				const [language] = (self.language || '').split('-');
				EditOrder.clientOrderRelation =
					_.get(clientRelationField, `fieldNameTranslations[${language}].value`) ||
					$translate.instant(clientRelationField.nameTag);
			} else {
				EditOrder.clientOrderRelation = metadata.params.clientOrderRelation;
			}
			setClientOrderRelationTitle();
		};

		var getConnectedClients = function () {
			if (!EditOrder.clientOrderRelation || !EditOrder.order.client || !EditOrder.order.client.id) {
				return;
			}
			EditOrder.relatedClients = [];

			accountRelations
				.get(EditOrder.account, true)
				.then(function (res) {
					EditOrder.relatedClients = _.unique(res, 'id');
					EditOrder.select.relatedClients.data.splice(0, EditOrder.select.relatedClients.data.length);
					EditOrder.select.relatedClients.data.push.apply(
						EditOrder.select.relatedClients.data,
						EditOrder.relatedClients
					);
					setClientOrderRelation();
				})
				.catch(function () {});
		};

		var resetEditContactModel = function () {
			EditOrder.editContactModel.id = null;
			EditOrder.editContactModel.name = '';
			EditOrder.editContactModel.firstName = '';
			EditOrder.editContactModel.lastName = '';
			EditOrder.editContactModel.title = '';
			EditOrder.editContactModel.titleCategory = {};
			EditOrder.editContactModel.phone = '';
			EditOrder.editContactModel.cellPhone = '';
			EditOrder.editContactModel.email = '';
		};

		$scope.closeModal = function (position) {
			if (EditOrder.tabs.isActive('form') && $scope.OrderForm?.$dirty) {
				$scope.showInlineAction = position;
				$safeApply($scope);
			} else {
				$scope.showInlineAction = 'none';
				$scope.reject();
			}
		};

		const unregisterCloseOpportunity = $rootScope.$on('opportunity:close', () => {
			if (!$scope.OrderForm) {
				return;
			}
			if (!$scope.OrderForm.$dirty) {
				unregisterCloseOpportunity();
				setTimeout(() => {
					$rootScope.$broadcast('opportunity:close:done');
					$scope.resolve();
				}, 100);
			} else {
				$scope.OrderForm.$setDirty(false);
				$scope.OrderForm.$setPristine(true);
				EditOrder.save(true, true, true)
					.then(function () {
						unregisterCloseOpportunity();

						$rootScope.$broadcast('opportunity:close:done');
						$scope.resolve();
					})
					.catch(err => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			}
		});

		$scope.rejectChanges = function () {
			$scope.OrderForm.$setPristine();
			$scope.reject();
		};

		$scope.saveChanges = function () {
			EditOrder.save(false, false, true);
			$scope.showInlineAction = 'none';
		};

		$scope.closeInlineAction = function () {
			$scope.showInlineAction = 'none';
			$safeApply($scope);
		};

		EditOrder.isOldDate = function () {
			return moment(EditOrder.order.date).startOf('day') < moment().startOf('day');
		};

		EditOrder.getCloseDateDiff = function () {
			let days;
			if (EditOrder.isOld) {
				days = moment().diff(EditOrder.order.date, 'day');
			} else {
				days = moment(EditOrder.order.date).diff(moment().startOf('day'), 'day');
			}

			if (days < 0) {
				// When changing close date you can see a negativ number before the modal closes. This prevents it :)
				return '';
			} else if (days === 1) {
				return $translate.instant(EditOrder.isOld ? 'opportunity.dayLate' : 'opportunity.dayLeft', { days });
			}
			return $translate.instant(EditOrder.isOld ? 'opportunity.daysLate' : 'opportunity.daysLeft', { days });
		};

		EditOrder.isNotToday = function () {
			return (
				moment(EditOrder.order.date).startOf('day') > moment().startOf('day') ||
				moment(EditOrder.order.date).startOf('day') < moment().startOf('day')
			);
		};

		EditOrder.handleDateChange = function () {
			EditOrder.save(true, true);
		};

		const getOrderFormCustomField = (custom, isRow, rowIdx) => {
			let fieldName = 'upCustomField_' + custom.fieldId;
			if (isRow) {
				fieldName = 'upCustomRowField_' + custom.fieldId + '_' + rowIdx;
			}
			return $scope.OrderForm?.[fieldName];
		};

		const customFieldMapFn = (custom, isRow, rowIdx) => {
			if (custom.hasOwnProperty('alwaysRequired') && !custom.alwaysRequired) {
				custom.obligatoryField = false;

				if (isRow) {
					const orderRow = EditOrder.order.orderRow[rowIdx];
					orderRow.$mappedCustom[custom.fieldId] = custom;
				}
			}

			const currentStageRequired = custom.stages?.some(
				stage => stage.id === EditOrder.order.stage.id && stage.required
			);
			if (currentStageRequired) {
				custom.alwaysRequired = custom.obligatoryField;
				custom.obligatoryField = true;

				if (isRow) {
					const orderRow = EditOrder.order.orderRow[rowIdx];
					orderRow.$mappedCustom[custom.fieldId] = custom;
				}
			} else if (!custom.obligatoryField) {
				const orderFormField = getOrderFormCustomField(custom, isRow, rowIdx);
				if (orderFormField) {
					orderFormField.$setValidity('required', true);
				}
			}
			return custom;
		};

		EditOrder.updateCustomFieldRequiredness = function () {
			if (FeatureHelper.isAvailable(FeatureHelper.Feature.ORDER_CUSTOM_STAGES)) {
				EditOrder.order.custom = EditOrder.order.custom.map(custom => customFieldMapFn(custom));

				for (const [idx, row] of EditOrder.order.orderRow.entries()) {
					row.custom = row.custom.map(custom => customFieldMapFn(custom, true, idx));
				}
			}
		};

		EditOrder.stageSelectChanged = function () {
			EditOrder.order.probability = EditOrder.order.stage.probability;
			EditOrder.hideProbability =
				EditOrder.order.stage?.probability === 0 ||
				EditOrder.order.stage?.probability === 100 ||
				(EditOrder.isAvailable.newFields && !EditOrder.probabilityActive);
			EditOrder.updateCustomFieldRequiredness();
		};

		EditOrder.saveComment = function (comment) {
			if (!comment.length) return;

			Comment.save({
				user: { id: self.id },
				description: comment,
				client: { id: EditOrder.order.client.id },
				opportunityId: EditOrder.order.id
			}).catch(e => {
				logError(e, 'Could not save comment');
			});
		};

		EditOrder.marketingContributionChanged = function (value) {
			EditOrder.order.marketingContribution = value;
		};

		EditOrder.salesCoachChanged = function () {
			EditOrder.order.stakeholders = [];
			EditOrder.order.titleCategories = [];
			if (!EditOrder.order.salesCoach?.decisionMakers?.active) {
				EditOrder.salesCoachDecisionMaker = {};
				EditOrder.decisionMakersEnabled = false;
			} else {
				EditOrder.decisionMakersEnabled = true;
				const salesCoach = EditOrder.order.salesCoach;
				EditOrder.salesCoachDecisionMaker = {
					salesCoachId: salesCoach.id,
					hasTitleCategories: salesCoach.decisionMakersPro.typeOfDecisionMakers === 'titleCategories'
				};
			}

			// Should not save when creating an opportunity
			if (EditOrder.order.id) {
				Order.customer($stateParams.customerId).save(
					{
						id: EditOrder.order.id,
						order: EditOrder.order,
						skipTriggers: true
					},
					{ skipNotification: true }
				);
			}
		};

		const getRowCustomFields = (rowProduct, rowCustomFields) => {
			const productCategoryId = rowProduct?.category?.id;
			if (!productCategoryId || !rowCustomFields.length) {
				return rowCustomFields;
			}
			const productCategory = productCategories.find(pc => pc.id === productCategoryId);
			if (!productCategory?.orderRowFields) {
				return rowCustomFields;
			}
			return rowCustomFields.filter(cf => productCategory.orderRowFields.find(rf => rf.id === cf.id));
		};

		EditOrder.validateForm = () => {
			if (!$scope.notifyErrorSubmit(null, true)) {
				EditOrder.saving = false;
				$safeApply($scope);
				return false;
			}

			return true;
		};

		EditOrder.validateRequiredFields = stage => {
			let hasRequiredRowFieldsWithoutValue = false;
			for (const row of EditOrder.order.orderRow) {
				const rowCustomFields = getRowCustomFields(row.product, row.custom);
				for (const custom of rowCustomFields) {
					const isRequiredStage = _.find(custom.stages, s => s.id === stage.id && s.required);
					if (
						isRequiredStage &&
						!custom.value &&
						custom.value !== 0 &&
						custom.$hasAccess &&
						custom.visible &&
						custom.editable
					) {
						hasRequiredRowFieldsWithoutValue = true;
						break;
					}
				}
			}
			let requiredFieldsWithoutValue = false;
			if (!hasRequiredRowFieldsWithoutValue) {
				requiredFieldsWithoutValue = _.filter(EditOrder.order.custom, custom => {
					const isRequiredStage = _.find(custom.stages, s => s.id === stage.id && s.required);
					return (
						isRequiredStage &&
						!custom.value &&
						custom.value !== 0 &&
						custom.$hasAccess &&
						custom.visible &&
						custom.editable
					);
				});
			}
			if (hasRequiredRowFieldsWithoutValue || requiredFieldsWithoutValue.length) {
				EditOrder.updateCustomFieldRequiredness();
				NotificationService.addNotification({
					title: 'saveError.order',
					body: 'enter_required_fields',
					style: NotificationService.style.ERROR,
					icon: 'times'
				});
				return false;
			}
			return true;
		};

		EditOrder.selectStage = function (e) {
			var index = _.findIndex(EditOrder.stages, { id: EditOrder.order.stage.id });
			if (EditOrder.stages[index]) {
				EditOrder.stages[index].$$selected = true;
			}

			function openStageListModal($modalParams, e) {
				return new Promise((resolve, reject) => {
					if (Tools.FeatureHelper.hasSoftDeployAccess('STAGE_LIST_REACT')) {
						openModal('StageListModal', {
							...$modalParams,
							onClose: stage => {
								if (stage?.id) {
									resolve(stage);
								} else {
									reject();
								}
							}
						});
					} else {
						$upModal.open('stageList', $modalParams, e).then(resolve).catch(reject);
					}
				});
			}

			openStageListModal(
				{
					hideHeader: true,
					highlight: true,
					hideControls: true,
					title: 'default.stages',
					columns: [
						{ title: 'default.name', value: 'name' },
						{ title: 'p', value: 'probability' }
					],
					data: _.filter(EditOrder.stages, function (stage) {
						// User can allways se the selected stage
						if (EditOrder.order.stage.id === stage.id) {
							return true;
						}
						// Check for role access
						return stage.$hasAccess;
					}),
					selected: EditOrder.order.stage.id
				},
				e
			)
				.then(function (stage) {
					if (EditOrder.stages[index]) {
						EditOrder.stages[index].$$selected = false;
					}
					EditOrder.order.stage = stage;
					EditOrder.order.probability = stage.probability;
					EditOrder.hideProbability =
						stage.probability === 0 ||
						stage.probability === 100 ||
						(EditOrder.isAvailable.newFields && !EditOrder.probabilityActive);

					if (FeatureHelper.isAvailable(FeatureHelper.Feature.ORDER_CUSTOM_STAGES)) {
						const validRequiredFields = EditOrder.validateRequiredFields(stage);
						if (!validRequiredFields) {
							return;
						}
					}

					EditOrder.updateCustomFieldRequiredness();
					EditOrder.save();
				})
				.catch(err => {
					if (err instanceof Error) {
						logError(err);
					}
				});
		};

		// create activity function
		EditOrder.createActivity = function () {
			var options = {
				activity: {
					client: EditOrder.order.client
				}
			};

			if (EditOrder.order.probability !== 0 && EditOrder.order.probability !== 100) {
				options.activity.opportunity = { id: EditOrder.order.id, description: EditOrder.order.description };
			}

			$upModal.open('editActivity', options);
		};

		// create activity function
		EditOrder.createAppointment = function () {
			var options = {
				appointment: {
					client: EditOrder.order.client
				}
			};

			if (EditOrder.order.probability !== 0 && EditOrder.order.probability !== 100) {
				options.appointment.opportunity = { id: EditOrder.order.id };
				options.appointment.sourceType = 'opportunity';
				options.appointment.sourceId = EditOrder.order.id;
			}

			openEditAppointment(options);
		};

		EditOrder.addRelation = function () {
			var params = {
				account: EditOrder.order.client,
				customerId: customerId
			};

			openAccountRelationModal(params)
				.then(function (conn) {
					if (conn && conn.client) {
						EditOrder.order.clientConnection = conn.client;
						if (EditOrder.order.client.connectedClients) {
							EditOrder.order.client.connectedClients.push({ relatedToClientId: conn.client.id });
						}
					}

					EditOrder.relatedClients.push(conn.client);
					EditOrder.select.relatedClients.data.splice(0, EditOrder.select.relatedClients.data.length);
					EditOrder.select.relatedClients.data.push.apply(
						EditOrder.select.relatedClients.data,
						EditOrder.relatedClients
					);

					EditOrder.relatedAccountChange();
				})
				.catch(err => {
					if (err instanceof Error) {
						logError(err);
					}
				});
		};

		EditOrder.promptRemoveEntry = function () {
			EditOrder.promptRemoval = !EditOrder.promptRemoval;
		};

		// Delete order
		EditOrder.deleteOrder = function () {
			if (!EditOrder.order.id) {
				return;
			}
			EditOrder.saving = true;
			Order.customer(customerId)
				.delete(EditOrder.order)
				.then(function () {
					$scope.OrderForm.$setPristine();
					$scope.reject();
				})
				.catch(err => {
					if (err instanceof Error) {
						logError(err);
					}
				})
				.finally(function () {
					EditOrder.saving = false;
				});
		};

		const handleInactivePriceList = priceListId => {
			const priceList = Tools.AppService.getPriceLists().find(priceList => priceList.id === priceListId);
			if (!priceList) {
				// Price list somehow doesn't exist so we use the default list
				// This shouldn't happen since you cannot delete a price list
				EditOrder.priceList = EditOrder.priceLists.find(priceList => priceList.isDefault);
			} else {
				if (!priceList.active) {
					priceList.name = priceList.name + ' (' + T('default.inactive') + ')';
				}
				EditOrder.priceList = priceList;
			}
		};

		// Use order > client > default price list
		const updatePriceList = (init, updatePrice = true) => {
			if (EditOrder.priceLists) {
				if (EditOrder.order.priceListId && init) {
					EditOrder.priceList = EditOrder.priceLists.find(
						priceList => priceList.id === EditOrder.order.priceListId
					);
					if (!EditOrder.priceList) {
						// The price list on the order has been inactivated
						handleInactivePriceList(EditOrder.order.priceListId);
					}
				} else if (EditOrder.account && EditOrder.account.priceListId !== null) {
					EditOrder.priceList = EditOrder.priceLists.find(
						priceList => priceList.id === EditOrder.account.priceListId
					);

					if (!EditOrder.priceList) {
						// The price list on the account has been inactivated
						EditOrder.priceList = EditOrder.priceLists.find(priceList => priceList.isDefault);
					}
				} else {
					EditOrder.priceList = EditOrder.priceLists.find(priceList => priceList.isDefault);
				}
				EditOrder.changePriceList(init, updatePrice);
			}
		};

		// Both EditOrder.accountChange and EditOrder.relatedAccountChange are async but don't depend on each other
		EditOrder.accountChangeReact = ({ client, contact }) => {
			if (client) {
				EditOrder.order.client = client;
			} else {
				EditOrder.order.client = null;
			}
			EditOrder.accountChange();

			if (contact && contact.id) {
				EditOrder.order.contact = contact;
			} else {
				EditOrder.order.contact = null;
			}
			EditOrder.contactChange();
			$safeApply($scope);
		};

		// Function to get new contacts when the client field changes
		EditOrder.accountChange = function (init) {
			const changedBackClient =
				EditOrder.order.client && EditOrder.order.client.id === EditOrder.order.lastSuccess.client?.id;
			if (!init && !changedBackClient) {
				EditOrder.order.contact = undefined;
				EditOrder.order.clientConnection = undefined;
			}
			EditOrder.contacts = [];
			if (EditOrder.order.client && EditOrder.order.client.id && EditOrder.order.client.id > 0) {
				EditOrder.fetchingAgreements = true;
				getAllActiveAgreements(EditOrder.order.client.id)
					.then(activeAgreements => {
						EditOrder.activeAgreementGroups = activeAgreements;
					})
					.catch(err => {
						if (err instanceof Error) {
							logError(err, 'Could not fetch agreements');
						}
					})
					.finally(() => {
						EditOrder.fetchingAgreements = false;
						$safeApply($scope);
					});

				if (init) {
					fixClientStuff(meta.client, init);
				} else {
					if (!changedBackClient) {
						Account.customer(customerId)
							.get(EditOrder.order.client.id)
							.then(res => fixClientStuff(res, init))
							.catch(err => {
								logError(err, 'EditOrder.accountChange fetch client failed');
								NotificationService.addNotification({
									title: 'default.error',
									body: 'fetchError.client',
									style: NotificationService.style.ERROR,
									icon: 'times'
								});

								if (!EditOrder.order.lastSuccess.client) {
									EditOrder.order.client = null;
								} else {
									EditOrder.order.client = EditOrder.order.lastSuccess.client;
									EditOrder.order.clientConnection = EditOrder.order.lastSuccess.clientConnection;
									$scope.$broadcast('editOrder.accountChanged');
									EditOrder.accountChange(false);
								}
							});
					}
				}
			} else {
				EditOrder.useAjaxContactSelect = false;
			}

			if (EditOrder.editContact) {
				EditOrder.abortContactEdit();
			}
		};

		function getClientIds() {
			const clientIds = getRelatedClientIds(EditOrder.order, EditOrder.hasSubAccounts);
			clientIds.unshift(EditOrder.order.client.id);

			return clientIds;
		}

		function groupContacts(contacts, includeNoContact = true) {
			if (!EditOrder.hasSubAccounts) {
				if (includeNoContact) {
					contacts.unshift({ id: null, name: 'default.noContact' });
				}

				return contacts;
			}

			const predicate = contact => contact.client.id === EditOrder.order.client.operationalAccount?.id;
			const [mainContacts, regularContacts] = binaryGroup(contacts, predicate);

			if (includeNoContact) {
				regularContacts.unshift({ id: null, name: 'default.noContact' });
			}
			if (mainContacts.length > 0) {
				regularContacts.push({
					name: 'default.fromMainAccount',
					children: mainContacts
				});
			}

			return regularContacts;
		}

		function fixClientStuff(res, init) {
			EditOrder.order.lastSuccess.client = EditOrder.order.client;
			EditOrder.account = res.data;

			if (EditOrder.hasAppFrameworkListenTo) {
				EditOrder.order.client = EditOrder.account;
			}

			const isCopy = !!$modalParams.copy;
			if (!isCopy || (isCopy && init)) {
				updatePriceList(init, !isCopy);
			}

			if (EditOrder.hasMaxDiscount) {
				EditOrder.order.orderRow.forEach(row => {
					row.maxDiscount = getMaxDiscount(row);
					if (isCopy) {
						row.hasExcessiveDiscount = EditOrder.calc.hasExcessiveDiscount(row);
					}
				});
			}

			getConnectedClients();

			const clientIds = getClientIds();

			var fields = ['id', 'name', 'email', 'cellPhone', 'phone', 'client', 'title', 'titleCategory'];
			utils.req
				.clientContactsAndRelations(clientIds, fields)
				.then(function (res) {
					if (res.metadata.total > 1000) {
						EditOrder.useAjaxContactSelect = true;
					} else {
						EditOrder.useAjaxContactSelect = false;
					}

					const contacts = groupContacts(res.data);

					EditOrder.contacts = contacts;

					EditOrder.select.contact.data.splice(0, EditOrder.select.contact.data.length);
					EditOrder.select.contact.data.push.apply(EditOrder.select.contact.data, contacts);

					// find contact and set it on the order so we get contact email
					if (init && EditOrder.order.contact) {
						var found = _.find(res.data, { id: EditOrder.order.contact.id });
						if (found) {
							EditOrder.order.contact = found;
						}
					}

					if (contacts.length && !init) {
						$scope.$broadcast('editOrder.accountChanged');
					}
				})
				.catch(err => {
					if (err instanceof Error) {
						logError(err);
					}
				});
		}

		EditOrder.changeAccount = function () {
			EditOrder.changingAccount = !EditOrder.changingAccount;

			if (EditOrder.changingAccount) {
				EditOrder.initialClientId = EditOrder.order.client.id;
				EditOrder.initialClientName = EditOrder.order.client.name;
			} else {
				EditOrder.order.client.name = EditOrder.initialClientName;
				EditOrder.order.client.id = EditOrder.initialClientId;
				EditOrder.accountChange(true);

				if (meta.contact && !meta.missingContactRights) {
					EditOrder.order.contact = meta.contact.data;
					EditOrder.contactPerson = meta.contact.data;
					EditOrder.lockedContact = true;
				}
			}
		};

		EditOrder.relatedAccountChange = function () {
			if (EditOrder.order.client && EditOrder.order.client.id && EditOrder.order.client.id > 0) {
				const clientIds = getClientIds();

				var filters = new RequestBuilder();
				const orBuilder = filters.orBuilder();
				orBuilder.next();
				orBuilder.addFilter(Contact.attr.account.attr.id, filters.comparisonTypes.Equals, clientIds);
				orBuilder.next();
				orBuilder.addFilter(Contact.attr.connectedClients, filters.comparisonTypes.Equals, clientIds);
				orBuilder.done();
				filters.addFilter(Contact.attr.active, filters.comparisonTypes.Equals, 1);
				filters.addSort(Contact.attr.name, true);

				Contact.customer(customerId)
					.find(filters.build())
					.then(function (res) {
						if (res.data && res.data.length) {
							if (res.metadata.total > 1000) {
								EditOrder.useAjaxContactSelect = true;
							} else {
								EditOrder.useAjaxContactSelect = false;
							}

							const contacts = groupContacts(res.data);
							EditOrder.contacts = contacts;

							EditOrder.select.contact.data.splice(0, EditOrder.select.contact.data.length);
							EditOrder.select.contact.data.push.apply(EditOrder.select.contact.data, contacts);

							// Remove contact if it's not in the list anymore
							if (EditOrder.decisionMakersEnabled) {
								if (EditOrder.salesCoachDecisionMaker.hasTitleCategories) {
									EditOrder.order.titleCategories = EditOrder.order.titleCategories.filter(
										titleCategory => EditOrder.contacts.some(c => c.id === titleCategory.contactId)
									);
								} else {
									EditOrder.order.stakeholders = EditOrder.order.stakeholders.filter(stakeholder =>
										EditOrder.contacts.some(c => c.id === stakeholder.contactId)
									);
								}
							}

							if (EditOrder.order.contact) {
								var found = _.find(EditOrder.select.contact.data, { id: EditOrder.order.contact.id });

								if (found) {
									EditOrder.order.contact = found;
									EditOrder.contactPerson = found;
								} else {
									EditOrder.order.contact = null;
									EditOrder.contactPerson = null;
								}
							}
							setClientOrderRelation();
						}
					})
					.catch(err => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			} else {
				EditOrder.useAjaxContactSelect = false;
			}
			EditOrder.order.lastSuccess.clientConnection = EditOrder.order.clientConnection;
		};

		EditOrder.parentCompanyClick = function () {
			// Go to existing parent
			if (EditOrder.order.client.parent) {
				return $state.go('account.dashboard', { id: EditOrder.order.client.parent.id });
			}
			// Check for company in db
			var duns = EditOrder.order.client.soliditet.profileData.parentCompanyDunsNumber;
			var filters = new RequestBuilder();
			filters.addFilter(Account.attr.dunsNo, filters.comparisonTypes.Equals, duns.toString());
			filters.limit = 1;
			filters.fields = ['id'];

			Account.customer(customerId)
				.find(filters.build())
				.then(function (results) {
					// If results
					if (results.data.length) {
						// Save account with found parent and go there
						var data = { id: EditOrder.order.client.id, parent: { id: results.data[0].id } };
						Account.customer(customerId).save(data, {
							skipNotification: true,
							skipErrorNotification: true
						});
						$state.go('account.dashboard', { id: results.data[0].id });
					} else {
						// Ask to buy the account from soliditet
						openConfirmSoliditetBuyParentModal({
							customerId: customerId,
							name: EditOrder.order.client.soliditet.profileData.parentCompanyName,
							duns: duns
						})
							.then(account => {
								// Set parent on account after buy
								if (account) {
									const data = { id: EditOrder.order.client.id, parent: { id: account.id } };
									Account.customer(customerId).save(data, {
										skipNotification: true,
										skipErrorNotification: true
									});
									// Go to account
									$state.go('account.dashboard', { id: account.id });
								}
							})
							.catch(err => {
								if (err instanceof Error) {
									logError(err);
								}
							});
					}
				})
				.catch(err => {
					if (err instanceof Error) {
						logError(err);
					}
				});
		};

		// function to select yourself§
		EditOrder.selectMe = function () {
			EditOrder.order.user = EditOrder.self;
		};

		// Triggered when the order currency is changes
		EditOrder.changeCurrency = function () {
			if (EditOrder.selectedCurrency) {
				if (EditOrder.priceList) {
					angular.forEach(EditOrder.order.orderRow, function (row) {
						EditOrder.calc.productChange(row);
					});
				}
				EditOrder.order.currency = EditOrder.selectedCurrency.iso;
				EditOrder.order.currencyRate = EditOrder.selectedCurrency.rate;
				EditOrder.convert = EditOrder.order.currency !== EditOrder.masterCurrency;
			}
		};

		EditOrder.changePriceList = function (init, updatePrice = true) {
			let priceList = EditOrder.priceList;
			if (!EditOrder.hasPriceLists) {
				priceList = EditOrder.priceLists.find(priceList => priceList.isDefault);
				EditOrder.priceList = priceList;
			}

			if (priceList) {
				EditOrder.showPriceListDiscount =
					priceList.showDiscount && priceList.id !== EditOrder.defaultPriceListId;
				EditOrder.order.priceListId = priceList.id;

				angular.forEach(EditOrder.order.orderRow, function (row) {
					row.priceListId = priceList.id;
					if (!init && EditOrder.hasPriceLists && updatePrice) {
						EditOrder.calc.productChange(row, true);
					}
				});
			}
		};

		EditOrder.openProductSearch = function () {
			openModal('ProductSearch', { order: EditOrder.order, addOrderRow: EditOrder.addOrderRowWithProduct });
		};

		// add order row function
		EditOrder.addOrderRow = function (row, skipRender) {
			var orderRow = row || Order.newRow();
			orderRow.priceListId = EditOrder.priceList.id;
			var length = EditOrder.order.orderRow.push(orderRow);

			// Find prev row
			if (EditOrder.order.orderRow[length - 2]) {
				// Set sort id on added row to one more than prev
				orderRow.sortId = EditOrder.order.orderRow[length - 2].sortId + 1;
			}
			orderRow.custom = orderRow.custom.map(custom => customFieldMapFn(custom, true, length - 1));

			$scope.OrderForm.$setDirty(true);

			if (!skipRender) {
				// update modal position
				$scope.reloadModalPosition();
			}
		};

		EditOrder.addOrderRowWithProduct = function (product, quantity) {
			let orderRow;
			let isNewRow = false;
			if (EditOrder.order.orderRow.length === 1 && !EditOrder.order.orderRow[0].product) {
				orderRow = EditOrder.order.orderRow[0];
			} else {
				orderRow = Order.newRow();
				isNewRow = true;
			}
			orderRow.product = product;
			orderRow.priceListId = EditOrder.priceList.id;
			const length = isNewRow ? EditOrder.order.orderRow.push(orderRow) : 1;

			// Find prev row
			if (EditOrder.order.orderRow[length - 2]) {
				// Set sort id on added row to one more than prev
				EditOrder.order.orderRow[length - 1].sortId = EditOrder.order.orderRow[length - 2].sortId + 1;
			}

			EditOrder.calc.productChange(orderRow);
			$scope.OrderForm.$setDirty(true);
			if (quantity !== null && quantity !== undefined) {
				orderRow.quantity = quantity;
			}
			// update modal position
			$scope.reloadModalPosition();
			$safeApply($scope);
		};

		// delete order row
		EditOrder.deleteOrderRow = function (orderRow) {
			_.pull(EditOrder.order.orderRow, orderRow);
			if (!EditOrder.order.orderRow.length) {
				EditOrder.addOrderRow();
				EditOrder.order.orderRow[0].sortId = 1;
			}
			$scope.OrderForm.$setDirty(true);
			// update modal position
			$scope.reloadModalPosition();
		};

		// copy order row
		EditOrder.copyOrderRow = function (orderRow) {
			var copy = _.cloneDeep(orderRow);
			delete copy.id;
			delete copy.$$hashKey;
			copy.hasExcessiveDiscount = EditOrder.calc.hasExcessiveDiscount(copy);
			EditOrder.addOrderRow(copy);

			// update modal position
			$scope.reloadModalPosition();
		};

		const changeRowOrder = (index, newIndex) => {
			const [row] = EditOrder.order.orderRow.splice(index, 1);
			EditOrder.order.orderRow.splice(newIndex, 0, row);
			EditOrder.order.orderRow.forEach((r, i) => (r.sortId = i + 1));
			$scope.OrderForm.$setDirty(true);
			$safeApply($scope);
		};

		EditOrder.sortRowUp = (row, index, enabled) => {
			if (!enabled) {
				return;
			}
			changeRowOrder(index, index - 1);
		};
		EditOrder.sortRowDown = (row, index, enabled) => {
			if (!enabled) {
				return;
			}
			changeRowOrder(index, index + 1);
		};

		// Create document
		EditOrder.createDocument = function (template) {
			if (template.uuid) {
				openPreviewPdfModal({
					pdfTemplateResource: {
						resource: pdfTemplateResource,
						uuid: template.uuid,
						orderId: EditOrder.order.id,
						type: 'order',
						pdfName: template.name,
						supportedLanguages: template.supportedLanguages,
						templateType: template.type,
						contact: EditOrder.order.contact || null
					}
				}).catch(() => {});
			} else {
				var params = {
					entityId: EditOrder.order.id,
					templateId: template.id,
					type: 'order',
					name: template.name,
					filename: `${EditOrder.order.client.name}-${moment().format('YYYY-MM-DD HH:mm')}.pdf`,
					accountId: EditOrder.order.client.id
				};

				if (EditOrder.order.contact) {
					params.contactId = EditOrder.order.contact.id;
					params.contact = EditOrder.order.contact;
				}
				if (!$scope.OrderForm.$dirty) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
						return openModal('PreviewPdfModal', {
							isUploadedTemplate: true,
							documentResource: {
								resource: documentResource,
								entityId: params.entityId,
								templateId: params.templateId,
								type: params.type,
								documentName: params.name,
								accountId: params.accountId,
								contact: params.contact,
								contactId: params.contactId
							}
						});
					} else {
						return $upModal.open('pdfPreview', params);
					}
				} else {
					$scope.OrderForm.$setDirty(false);
					$scope.OrderForm.$setPristine(true);
					EditOrder.save(true, false, true)
						.then(function () {
							if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
								openModal('PreviewPdfModal', {
									isUploadedTemplate: true,
									documentResource: {
										resource: documentResource,
										entityId: params.entityId,
										templateId: params.templateId,
										type: params.type,
										documentName: params.name,
										accountId: params.accountId,
										contact: params.contact,
										contactId: params.contactId
									}
								});
							} else {
								$upModal.open('pdfPreview', params);
							}
						})
						.catch(err => {
							if (err instanceof Error) {
								logError(err);
							}
						});
				}
			}
		};

		EditOrder.oldDateCondition = (doNotNotifyDate, omitClosedOrderCheck) => {
			return (
				!doNotNotifyDate &&
				!EditOrder.isOrder &&
				(omitClosedOrderCheck || EditOrder.order.probability === 100 || EditOrder.order.probability === 0) &&
				EditOrder.isNotToday()
			);
		};

		EditOrder.recurringProductCondition = hasRecurringProducts => {
			return !EditOrder.isOrder && EditOrder.order.probability === 100 && hasRecurringProducts;
		};

		// Save function
		EditOrder.save = function (doNotResolve, doNotNotifyDate, doValidate) {
			if (EditOrder.saving) {
				return $q.reject('Already saving');
			}

			EditOrder.saving = true;
			if (doValidate) {
				if (!EditOrder.validateForm()) {
					return $q.reject('Invalid form');
				}
			}

			if (!doNotResolve && FeatureHelper.isAvailable(FeatureHelper.Feature.PERIODIZATION)) {
				const haveValidPeriodization = store.dispatch(checkIfPeriodizationIsValid(EditOrder.order));

				if (!haveValidPeriodization) {
					NotificationService.addNotification({
						title: 'saveError.order',
						body: 'order.periodization.error',
						style: NotificationService.style.ERROR,
						icon: 'times'
					});
					EditOrder.saving = false;
					$scope.OrderForm.$setDirty(true);
					$safeApply($scope);
					return $q.reject('Invalid periodization');
				}
			}

			if (!EditOrder.savedThroughWinLossFlow) {
				// If order closeDate is passed we ask user if we should set it to today
				const hasRecurringProducts = EditOrder.hasRecurringProduct();
				const oldCondition = EditOrder.oldDateCondition(doNotNotifyDate);
				const recurringProductCondition = EditOrder.recurringProductCondition(hasRecurringProducts);

				if (oldCondition || recurringProductCondition) {
					const shouldOpenNewModal = EditOrder.wonOpportunityWithSubs();
					const shouldOpenLostOppModal =
						EditOrder.showWinLossButtons() &&
						EditOrder.order.stage.probability === 0 &&
						!EditOrder.order.lostReason;
					return openGenericModal({
						Component: window.CloseOpportunityModal,
						stage: EditOrder.order.stage,
						description: EditOrder.order.description,
						showToggle: hasRecurringProducts && !shouldOpenNewModal,
						// Angular only
						className: 'CloseOpportunityModal--root no-animate'
					})
						.then(function (params) {
							if (params.setToday) {
								EditOrder.order.date = new Date();
							}

							return save(doNotResolve).then(function () {
								if (!EditOrder.saveError) {
									if (shouldOpenLostOppModal) {
										openModal('LostOpportunityModal', {
											order: EditOrder.order,
											onSaveLostReason: (lostReason, competitorId) => {
												EditOrder.order.lostReason = lostReason;
												EditOrder.order.competitorId = competitorId;
												return save(doNotResolve);
											}
										});
									} else if (shouldOpenNewModal && EditOrder.order.stage.probability === 100) {
										EditOrder.openOpportunityWithSubs(EditOrder.order.stage);
									} else if (params.createSubscription) {
										var opts = {
											customerId: customerId,
											orderId: EditOrder.order.id,
											createFromOrder: true
										};

										if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
											return $upModal.open('editAgreement', opts);
										}

										openModal('CreateSubscription', {
											order: EditOrder.order,
											createdFrom: 'saveOrder'
										});
									}
								}
							});
						})
						.catch(() => {
							EditOrder.saving = false;
							$safeApply($scope);
						});
				}
			}
			if ($scope.OrderForm.$invalid) {
				EditOrder.saving = false;
				$safeApply($scope);
				return $q.reject('Invalid form');
			}
			return save(doNotResolve);
		};

		// UploadFile
		EditOrder.uploadFile = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_UPLOAD_FILE_MODAL')) {
				return openModal('UploadFileModal', { orderId: EditOrder.order.id });
			}
			$upModal.open('uploadFile', {
				orderId: EditOrder.order.id
			});
		};

		// DownloadFile
		EditOrder.downloadFile = function (file) {
			File.download(file.id);
		};

		EditOrder.removeFile = function (file) {
			return File.customer(customerId).delete(file);
		};

		EditOrder.emailContact = function (e, contact) {
			e.preventDefault();
			e.stopPropagation();
			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
				openNewMailWithContact(contact);
			} else {
				$upModal.open('sendEmail', { customerId: customerId, contactId: contact.id, contact: contact }, e);
			}
		};

		EditOrder.contactChange = function () {
			if (EditOrder.order.contact && EditOrder.order.contact.id) {
				// Get all data for contact and save in variable
				Contact.customer(customerId)
					.get(EditOrder.order.contact.id)
					.then(function (res) {
						EditOrder.contactPerson = res.data;
					})
					.catch(err => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			} else {
				EditOrder.contactPerson = null;
				EditOrder.order.contact = null;
			}
			if (EditOrder.order.id && EditOrder.order.salesCoach) {
				Order.customer($stateParams.customerId).save(
					{
						id: EditOrder.order.id,
						contact: EditOrder.order.contact,
						skipTriggers: true
					},
					{ skipNotification: true }
				);
			}
		};

		EditOrder.userChange = function () {
			getAvailableSalesCoaches();
		};

		EditOrder.abortContactEdit = function () {
			EditOrder.editContact = false;
			// Clear all models
			resetEditContactModel();
		};

		EditOrder.showContactEdit = function (edit) {
			if (!EditOrder.canEditContact()) {
				return;
			}
			EditOrder.editContact = true;
			EditOrder.contactFormIsEdit = edit;
			// Set all models
			if (edit && EditOrder.contactPerson) {
				EditOrder.editContactModel.id = EditOrder.contactPerson.id;
				EditOrder.editContactModel.name = EditOrder.contactPerson.name;
				EditOrder.editContactModel.firstName = EditOrder.contactPerson.firstName;
				EditOrder.editContactModel.lastName = EditOrder.contactPerson.lastName;
				EditOrder.editContactModel.title = EditOrder.contactPerson.title;
				EditOrder.editContactModel.titleCategory = EditOrder.contactPerson.titleCategory;
				EditOrder.editContactModel.phone = EditOrder.contactPerson.phone;
				EditOrder.editContactModel.cellPhone = EditOrder.contactPerson.cellPhone;
				EditOrder.editContactModel.email = EditOrder.contactPerson.email;
			} else {
				resetEditContactModel();
			}

			$scope.$emit('createFocus');
		};

		EditOrder.canEditContact = function () {
			return (
				!EditOrder.contactPerson ||
				EditOrder.contactPerson?.userEditable ||
				(meta?.contact?.data?.id &&
					EditOrder.contactPerson?.id === meta.contact.data.id &&
					meta.contact.data.userEditable)
			);
		};

		EditOrder.onSetJourneyStepContact = function (journeyStep) {
			if (!EditOrder.contactPerson) {
				return $q.resolve();
			}
			return Contact.customer(customerId)
				.save({
					id: EditOrder.contactPerson.id,
					journeyStep: journeyStep
				})
				.then(function (res) {
					EditOrder.contactPerson.journeyStep = res.data.journeyStep;
					EditOrder.contactPerson = angular.copy(EditOrder.contactPerson);
				});
		};

		EditOrder.saveContactOnEnter = function (event) {
			if (event && event.keyCode === 13) {
				event.preventDefault();
				event.stopPropagation();
				EditOrder.contactEditSave();
			}
		};

		EditOrder.createContact = function () {
			// if contact har required fields we open real modal, else show quickCreate
			if (hasRequiredContactFields) {
				$upModal
					.open('editContact', { account: EditOrder.order.client })
					.then(function (addedContact) {
						// Set created contact as selected
						EditOrder.select.contact.data.push(addedContact);
						EditOrder.order.contact = addedContact;
						EditOrder.contactPerson = addedContact;
					})
					.catch(err => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			} else {
				EditOrder.showContactEdit();
			}
		};

		EditOrder.createEsign = function () {
			var esign = {
				client: EditOrder.order.client,
				user: EditOrder.order.user
			};

			if (!EditOrder.isOrder) {
				esign.opportunity = {
					id: EditOrder.order.id,
					description: EditOrder.order.description
				};
			}

			if (EditOrder.order.contact) {
				esign.involved = [Esign.newInvolved({ contact: EditOrder.order.contact })];
			}

			openEditEsign(esign);
		};

		EditOrder.contactEditSaveDisabled = function () {
			return (
				EditOrder.editContactLoading ||
				!(
					(!EditOrder.isAvailable.newFields && EditOrder.editContactModel.name) ||
					(EditOrder.isAvailable.newFields &&
						EditOrder.editContactModel.firstName &&
						EditOrder.editContactModel.lastName)
				) ||
				(EditOrder.requiredFieldsContact.Email && !EditOrder.editContactModel.email) ||
				(EditOrder.requiredFieldsContact.Phone && !EditOrder.editContactModel.phone) ||
				(EditOrder.requiredFieldsContact.Cellphone && !EditOrder.editContactModel.cellPhone) ||
				(EditOrder.requiredFieldsContact.Title && !EditOrder.editContactModel.title) ||
				(EditOrder.requiredFieldsContact.TitleCategory && !EditOrder.editContactModel.titleCategory) ||
				!EditOrder.validateEmail(EditOrder.editContactModel.email)
			);
		};

		EditOrder.contactEditSave = function () {
			if (EditOrder.contactEditSaveDisabled()) {
				return;
			}
			EditOrder.editContactLoading = true;

			const contactClientId = EditOrder.contactPerson?.client.id || EditOrder.order.client.id;

			var contact = {
				name: EditOrder.editContactModel.name,
				firstName: EditOrder.editContactModel.firstName,
				lastName: EditOrder.editContactModel.lastName,
				title: EditOrder.editContactModel.title,
				titleCategory: EditOrder.editContactModel.titleCategory,
				phone: EditOrder.editContactModel.phone,
				cellPhone: EditOrder.editContactModel.cellPhone,
				email: EditOrder.editContactModel.email,
				client: { id: contactClientId },
				...(EditOrder.editContactModel.id && { id: EditOrder.editContactModel.id })
			};

			if (!EditOrder.contactFormIsEdit) {
				contact.active = true;
			}

			Contact.customer(customerId)
				.save(contact)
				.then(function (res) {
					EditOrder.contactPerson = res.data;
					EditOrder.editContact = false;
					EditOrder.editContactLoading = false;

					// Update list of contacts
					if (EditOrder.contactFormIsEdit) {
						// find contact in list and update
						var i = _.findIndex(EditOrder.select.contact.data, { id: res.data.id });

						if (i !== -1) {
							EditOrder.select.contact.data[i] = res.data;
						}
					} else {
						// Add contact to list
						EditOrder.select.contact.data.push(res.data);
						EditOrder.order.contact = res.data;
						if (EditOrder.order.id) {
							Order.customer($stateParams.customerId).save(
								{
									id: EditOrder.order.id,
									contact: EditOrder.order.contact,
									skipTriggers: true
								},
								{ skipNotification: true }
							);
						}
					}
				})
				.catch(function () {
					EditOrder.editContactLoading = false;
				});
		};

		EditOrder.createCopy = function () {
			if (!EditOrder.order.id) {
				return;
			}

			var opts = {
				customerId: customerId,
				copy: EditOrder.order.id,
				type: 'opportunity'
			};
			if (!$scope.OrderForm.$dirty) {
				$upModal.open('editOrder', opts);
				$scope.resolve();
			} else {
				$scope.OrderForm.$setDirty(false);
				$scope.OrderForm.$setPristine(true);
				EditOrder.save(true, true, true)
					.then(function () {
						$upModal.open('editOrder', opts);
						$scope.resolve();
					})
					.catch(err => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			}
			// update modal position
			$scope.reloadModalPosition();
		};

		EditOrder.eSign = function () {
			var esign = {
				esign: {
					client: { id: EditOrder.order.client.id },
					contact: EditOrder.order.contact,
					opportunityId: EditOrder.order.id
				}
			};

			if (!$scope.OrderForm.$dirty) {
				openEditEsign(esign);
			} else {
				$scope.OrderForm.$setDirty(false);
				$scope.OrderForm.$setPristine(true);
				EditOrder.save(true, true, true)
					.then(function () {
						openEditEsign(esign);
					})
					.catch(err => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			}
		};

		EditOrder.createSubscription = function () {
			if (!EditOrder.order.id) {
				return;
			}

			var opts = {
				customerId: customerId,
				orderId: EditOrder.order.id,
				createFromOrder: true
			};

			if (!$scope.OrderForm.$dirty) {
				if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
					$upModal.open('editAgreement', opts);
				} else {
					openModal('CreateSubscription', { order: EditOrder.order, createdFrom: 'order' });
				}
				$scope.resolve();
			} else {
				$scope.OrderForm.$setDirty(false);
				$scope.OrderForm.$setPristine(true);

				EditOrder.save(true, true, true)
					.then(function () {
						if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
							$upModal.open('editAgreement', opts);
						} else {
							openModal('CreateSubscription', { order: EditOrder.order, createdFrom: 'order' });
						}

						$scope.resolve();
					})
					.catch(err => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			}
		};

		EditOrder.hasClientRelations = function () {
			return EditOrder.clientOrderRelation && EditOrder.isAvailable.companyRelations;
		};

		EditOrder.hasCurrencyPicker = function () {
			return !EditOrder.edit && EditOrder.hasMultiCurrency && EditOrder.select.currency.data.length > 1;
		};

		EditOrder.hasRecurringProduct = function () {
			if (!EditOrder.hasRecurringProducts) {
				return false;
			}

			return _.some(EditOrder.order.orderRow, function (row) {
				return row.product?.isRecurring;
			});
		};

		EditOrder.shouldShowButtonOnWonModal = function () {
			return !EditOrder.skipButtonOnWonModal && EditOrder.hasRecurringProduct();
		};

		EditOrder.wonOpportunityWithSubs = function () {
			const hasRecurringProducts = EditOrder.hasRecurringProduct();
			return hasRecurringProducts && EditOrder.activeAgreementGroups.length;
		};

		EditOrder.intervalChanged = function () {
			const oldInterval = EditOrder.order.recurringInterval;
			EditOrder.order.recurringInterval = EditOrder.recurringInterval;

			for (const row of EditOrder.order.orderRow) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('ORDER_INTERVAL_DISCOUNT')) {
					if (!row.product) continue;
					row.price = EditOrder.calc.adjustPriceIfRecurring(row.price, row.product, oldInterval);
					row.listPrice = EditOrder.calc.adjustPriceIfRecurring(row.listPrice, row.product, oldInterval);
					row.$listPrice = EditOrder.calc.adjustPriceIfRecurring(row.$listPrice, row.product, oldInterval);
					EditOrder.calc.calculateDiscount(row);

					// Was like this and PM didn't want it changed until proper solution with setting, see LINE-7825
					if (row.product.bundle?.length) {
						row.purchaseCost = EditOrder.calc.adjustPriceIfRecurring(
							row.purchaseCost,
							row.product,
							oldInterval
						);
					}
				} else {
					EditOrder.calc.productChange(row, true);
				}
			}
		};

		EditOrder.updateProbability = probability => {
			EditOrder.order.probability = probability;
			EditOrder.order.stage = EditOrder.stages.find(stage => stage.probability === probability);
			EditOrder.hideProbability =
				EditOrder.order.stage?.probability === 0 ||
				EditOrder.order.stage?.probability === 100 ||
				(EditOrder.isAvailable.newFields && !EditOrder.probabilityActive);
			EditOrder.updateCustomFieldRequiredness();
			save(false);
		};

		EditOrder.updateOrder = obj => {
			EditOrder.order = Object.assign(EditOrder.order, obj);
			save(true);
		};

		EditOrder.onProjectPlanChange = projectPlanOptions => {
			EditOrder.order.projectPlanOptions = projectPlanOptions;
			$safeApply($scope);
		};

		function save(doNotResolve) {
			EditOrder.saving = true;
			EditOrder.saveError = null;
			calcAge();
			$safeApply($scope);

			// Remove empty rows
			EditOrder.order.orderRow = _.filter(EditOrder.order.orderRow, function (row) {
				return row.product && row.product.id;
			});

			var order = angular.copy(EditOrder.order);
			order.account = order.client;
			order.value = EditOrder.calc.totalNet();
			var sortId = 1;

			order.date = moment(order.date).format('YYYY-MM-DD');

			order.orderRow = _.sortBy(order.orderRow, 'sortId');

			// remove empty orderRows(no product)
			_.remove(order.orderRow, function (row) {
				// Remove rows with missing product
				if (!row.product) {
					return true;
				}

				row.custom = MapHelpers.mapCustom(row.$mappedCustom);
				row.sortId = sortId++;
				row.price = row.price / order.currencyRate;
				row.listPrice = row.$listPrice / order.currencyRate;
				row.purchaseCost = row.purchaseCost / order.currencyRate;

				// Set back quantity to 1 for tiered isTotalPrice products and keep "real quantity" in tierQuantity
				if (isTieredOrderRow(row)) {
					row.tierQuantity = row.quantity;
					if (isTieredTotalOrderRow(row)) {
						row.quantity = 1;
					}
				}

				return false;
			});

			if (!order.description || order.description === '') {
				order.description = '<<' + $translate.instant('default.noDescription') + '>>';
			}

			EditOrder.orderValue = order.value;
			return ScriptService.order
				.save(order)
				.then(function () {
					if (Tools.FeatureHelper.hasSoftDeployAccess('LIGHTER_ROWS_ON_ORDER_SAVE')) {
						order.orderRow = order?.orderRow?.map(row => ({ ...row, product: { id: row.product.id } }));
					}

					return Order.customer($stateParams.customerId)
						.save(order)
						.then(function (response) {
							// Go to overview tab
							EditOrder.edit = true;
							EditOrder.order.id = response.data.id;
							EditOrder.order.annualValue = response.data.annualValue;
							EditOrder.order.monthlyValue = response.data.monthlyValue;
							EditOrder.isOrder = response.data.probability === 0 || response.data.probability === 100;

							if (EditOrder.order.$$activityId) {
								var activity = {
									id: EditOrder.order.$$activityId,
									opportunity: { id: EditOrder.order.id }
								};
								Activity.customer(customerId).save(activity, { skipNotification: true });
							}
							if (EditOrder.order.$$appointmentId) {
								var appointment = {
									id: EditOrder.order.$$appointmentId,
									opportunity: { id: EditOrder.order.id }
								};
								Appointment.customer(customerId).save(appointment, { skipNotification: true });
							}

							if ($scope && $scope.OrderForm) {
								$scope.OrderForm.$setDirty(false);
								$scope.OrderForm.$setPristine(true);
							}

							const responseOrderRows = _.sortBy(response.data.orderRow || [], 'sortId');

							EditOrder.order.orderRow = EditOrder.order.orderRow.map((row, index) => {
								if (responseOrderRows[index]) {
									row.id = responseOrderRows[index].id;
								}
								return row;
							});

							if (!doNotResolve) {
								$scope.resolve(response.data);
							}
							EditOrder.afterSave?.(response.data);

							EditOrder.saving = false;

							calcAge();

							EditOrder.riskChipProps = getRiskChipProps(response.data);
						})
						.catch(function (e) {
							if (EditOrder.quickCreate) {
								delete EditOrder.quickCreate;
							}
							EditOrder.saving = false;
							EditOrder.saveError = e;
						});
				})
				.catch(function (e) {
					EditOrder.saving = false;
					EditOrder.saveError = e;
				});
		}

		var quickCreate = function () {
			// set active tab
			EditOrder.activeTab = EditOrder.tabs.form;
			EditOrder.order = $modalParams.quickCreate;

			if (!EditOrder.order.client) {
				EditOrder.order.client = EditOrder.order.account;
				EditOrder.order.lastSuccess.client = EditOrder.order.client;
			}

			// find contact and set it on the order so we get contact email
			if (EditOrder.order.contact && meta.contacts) {
				var found = _.find(meta.contacts.data, { id: EditOrder.order.contact.id });
				if (found) {
					EditOrder.order.contact = found;
				}
			}
		};

		function calcAge() {
			if (EditOrder.isOrder) {
				EditOrder.age = moment(EditOrder.order.closeDate).diff(EditOrder.order.regDate, 'day');
			} else {
				EditOrder.age = moment().diff(EditOrder.order.regDate, 'day');
			}
			EditOrder.isOld = EditOrder.isOldDate();
		}

		function getAvailableSalesCoaches() {
			EditOrder.availableSalescoaches.splice(0, EditOrder.availableSalescoaches.length);

			for (const salesCoach of EditOrder.allSalescoaches) {
				const salesCoachUsers = salesCoach?.users;
				const salesCoachRoles = salesCoach?.roles;
				// If both lists are empty then by default it is available for all
				if (salesCoachUsers?.length === 0 && salesCoachRoles?.length === 0) {
					EditOrder.availableSalescoaches.push(salesCoach);
				} else {
					const includesUser = salesCoachUsers?.includes(EditOrder.order.user.id);
					const includesRole = salesCoachRoles?.includes(EditOrder.order.user.role?.id);

					if (includesUser || includesRole) {
						EditOrder.availableSalescoaches.push(salesCoach);
					}
				}
			}

			EditOrder.hasSalesCoach = EditOrder.availableSalescoaches.length && !EditOrder.order.closeDate;
			if (EditOrder.availableSalescoaches.length === 0) {
				if (EditOrder.order.salesCoach) {
					EditOrder.order.salesCoach = null;
				}
			} else if (EditOrder.availableSalescoaches.length === 1) {
				EditOrder.order.salesCoach = EditOrder.availableSalescoaches[0];
			} else if (EditOrder.order.salesCoach) {
				const currentCoach = EditOrder.availableSalescoaches.find(
					coach => coach.id === EditOrder.order.salesCoach.id
				);
				if (!currentCoach) {
					EditOrder.order.salesCoach = null;
				}
			}
		}

		var initStages = function (type) {
			// Stages
			if (type === 'order') {
				EditOrder.stageType = 'order';
			} else {
				EditOrder.stageType = 'all';
				if (EditOrder.order && EditOrder.order.id && EditOrder.order.probability === 0) {
					EditOrder.stageType = 'lost';
				}
			}
			var stages = AppService.getStages(EditOrder.stageType);

			stages = _.sortBy(stages, function (stage) {
				if (stage.probability === 0) {
					return 101; // Any number greater than 100 to make sure they end up last.
				}
				return stage.probability;
			});
			EditOrder.stages = stages;
		};
		// if you remove this function, you need to add product.isRecurring to order index (and obv. fix product fields)
		function fixProductFields(row) {
			if (row.product) {
				var product = _.find(EditOrder.orderProducts, { id: row.product.id });
				if (product) {
					product.custom = _.map(product.custom, function (c) {
						var field = _.find(meta.productCustomFields, { id: c.fieldId });
						if (field) {
							c.datatype = field.datatype;
						}
						return c;
					});
					row.product = product;
				}
			}

			return row;
		}

		EditOrder.uiElementUtils = {
			updateObjectCustom: customArray => {
				EditOrder.order.custom = customArray;
				EditOrder.updateCustomFieldRequiredness();
				$safeApply($scope);
			},
			addOrderRow: (productId, quantity, price, listPrice) => {
				const row = Order.newRow();
				if (productId) {
					const product = _.find(EditOrder.orderProducts, { id: productId });
					if (product) {
						row.product = product;
					}
				}
				if (quantity) {
					row.quantity = quantity;
				}
				if (price) {
					row.price = price;
				}
				if (listPrice) {
					row.listPrice = listPrice;
				}
				EditOrder.addOrderRow(row);
				EditOrder.calc.productChange(row, !!quantity);
				$safeApply($scope);
			},
			updateOrderRowPrice: (index, price) => {
				const row = EditOrder.order.orderRow[index];
				row.price = price;
				EditOrder.calc.priceChange(row);
				$safeApply($scope);
			},
			updateOrderRowListPrice: (index, listPrice) => {
				const row = EditOrder.order.orderRow[index];
				row.listPrice = listPrice;
				row.$listPrice = listPrice;
				row.$discount = listPrice - row.price;
				EditOrder.calc.priceChange(row);
				EditOrder.calc.discountChange(row);
				$safeApply($scope);
			},
			updateOrderRowProduct: (index, productId) => {
				const row = EditOrder.order.orderRow[index];
				const product = _.find(EditOrder.orderProducts, { id: productId });
				if (product) {
					row.product = product;
				}
				EditOrder.calc.productChange(row);
				$safeApply($scope);
			},
			setOrderRowCustomFieldValue: (index, mappedCustom) => {
				const row = EditOrder.order.orderRow[index];
				row.$mappedCustom = mappedCustom;
				EditOrder.updateCustomFieldRequiredness();
				$safeApply($scope);
			},
			updateOrderFromApp: (fieldActions, order) => {
				const updatedOrder = !order || _.isEmpty(order) ? EditOrder.order : order;
				updateOrderFromApp(fieldActions, EditOrder.order, updatedOrder);
			},
			save: keepOpenAfterSave => {
				return EditOrder.save(keepOpenAfterSave, true, true);
			}
		};

		// Init function
		var init = function () {
			customerId = AppService.getCustomerId();
			EditOrder.requiredFields = metadata.requiredFields.Order;
			EditOrder.requiredFieldsContact = metadata.requiredFields.Contact;
			EditOrder.hasSoftDeployContactTitleCategoryAccess = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
			EditOrder.hasSoftDeployUseSubscriptionInterval =
				Tools.FeatureHelper.hasSoftDeployAccess('USE_SUBSCRIPTION_INTERVAL');

			const cmWithrrOption = getCMWithRROption(metadata);

			const hasRecurringOrder = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER);
			const hasRecurringSalesModel = metadata.params.SalesModel === 'rr';
			EditOrder.hasRecurringInterval = (hasRecurringOrder && hasRecurringSalesModel) || !!cmWithrrOption;
			EditOrder.salesModelOption = cmWithrrOption ?? metadata.params.SalesModelOption;

			const defaultInterval = metadata.params.SubscriptionDefaultInterval || 1;
			const hasInterval = EditOrder.hasSoftDeployUseSubscriptionInterval;
			const theInterval = hasInterval ? defaultInterval : EditOrder.salesModelOption === 'mrr' ? 1 : 12;
			EditOrder.defaultInterval = EditOrder.hasRecurringInterval ? theInterval : 0;

			EditOrder.hasMaxDiscount =
				Tools.FeatureHelper.hasSoftDeployAccess('MAX_DISCOUNT') &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAX_DISCOUNT);

			const PE_ID = 105;

			EditOrder.apps = metadata.integrations.active.filter(app => {
				if (app.id === PE_ID) {
					// Hide PE app for opportunities
					return app.inits.includes('app_status_order') && meta.order.data.probability === 100;
				} else {
					return app.inits.includes('app_status_order');
				}
			});

			EditOrder.showAppsSyncLogs = EditOrder.apps && EditOrder.apps.length > 0;

			$scope.activateSyncLogs = () => ($scope.renderSyncLogs = true);
			$scope.renderSyncLogs = false;

			EditOrder.appsProps = {
				order: meta.order.data,
				apps: EditOrder.apps
			};

			EditOrder.clientOrderRelationTranslations = meta.clientOrderRelationTranslations ?? {};

			EditOrder.changeAccountText =
				$translate.instant('change') + ' ' + $translate.instant('account').toLowerCase();

			EditOrder.type = meta.type;

			EditOrder.hasBothRRAndCM = hasRRWithCM(metadata) || !!cmWithrrOption;

			EditOrder.hasContributionMargin = metadata.params.UseContributionMargin || EditOrder.hasBothRRAndCM;

			EditOrder.orderProducts = meta.products;

			// Files
			EditOrder.hasFiles = FeatureHelper.isAvailable(FeatureHelper.Feature.DOCUMENTS);
			EditOrder.hasEsign = FeatureHelper.isAvailable(FeatureHelper.Feature.ESIGN);
			EditOrder.files = meta.files.data;

			EditOrder.isAvailable = {
				orderRowCustom: FeatureHelper.isAvailable(FeatureHelper.Feature.ORDER_ROW_CUSTOM),
				companyRelations: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANY_RELATIONS),
				newFields: FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS)
			};

			// FieldSync
			$scope.highlightedFields = [];
			$scope.fieldsDescription = {};
			$scope.fieldsSyncDir = {};
			$scope.syncedToApp = '';

			$scope.appsWithFieldSync = metadata.integrations.active.filter(app => {
				return app.inits.includes('show_field_sync');
			});

			const toggleSync = async integration => {
				const inteFields = [];
				const inteDesc = {};
				const syncDir = {};

				if (integration) {
					await Tools.StandardIntegration.data(customerId)
						.run({ type: 'fields/order', integrationId: integration.id })
						.then(res => {
							if (res.data && res.data.fields) {
								res.data.fields.forEach(field => {
									if (!field?.upsalesField) return;
									if (!inteFields.includes(field.upsalesField)) {
										inteFields.push(field.upsalesField);
										inteDesc[field.upsalesField] = field.description;
										syncDir[field.upsalesField] = field.direction;
									}
								});
							}
						})
						.catch(err => {
							if (err instanceof Error) {
								logError(err, 'Failed to get fields');
							}
						});
				}

				$scope.highlightedFields = inteFields;
				$scope.fieldsDescription = inteDesc;
				$scope.fieldsSyncDir = syncDir;
				$scope.syncedToApp =
					integration && Tools.$translate('integration.onlySyncedToApp', { app: integration.name });
				$safeApply($scope);
			};

			EditOrder.fieldSyncProps = {
				apps: $scope.appsWithFieldSync,
				toggleSync: toggleSync
			};

			// set the order
			EditOrder.order = meta.order.data;
			EditOrder.order.lastSuccess = {};
			EditOrder.order.projectPlanOptions = EditOrder.order.projectPlanOptions ?? [];
			EditOrder.products = _.pluck(EditOrder.order.orderRow, 'product');
			EditOrder.order.$$activityId = $modalParams.activityId;
			EditOrder.order.$$appointmentId = $modalParams.appointmentId;
			if (!EditOrder.order.id && $modalParams.notes) {
				EditOrder.order.notes = $modalParams.notes;
			}
			if (!EditOrder.order.id && $modalParams.description) {
				EditOrder.order.description = $modalParams.description;
			}

			EditOrder.recurringInterval = EditOrder.order.recurringInterval
				? EditOrder.order.recurringInterval
				: EditOrder.defaultInterval;
			EditOrder.order.recurringInterval = EditOrder.recurringInterval;

			EditOrder.hasRecurringProducts =
				metadata.params.AgreementEnabled &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER);

			// ClientParam for number of decimals on price
			EditOrder.numOfDecimalsPrice = metadata.params.OrderedProductPriceDecimals || 2;

			EditOrder.products = EditOrder.order.orderRow;

			if (EditOrder.hasProjectPlanFeature) {
				EditOrder.productsWithProjectPlanTemplates =
					EditOrder.order.orderRow
						?.filter(orderRow => orderRow.product?.projectPlanTemplate)
						?.map(orderRow => orderRow.product) ?? [];
			}

			EditOrder.afterSave = $modalParams.afterSave;

			// set edit/create
			EditOrder.edit = EditOrder.order.id;
			EditOrder.quickCreate = !!$modalParams.quickCreate;

			// Self
			EditOrder.self = self;

			// Master currency
			EditOrder.masterCurrency = meta.masterCurrency;

			// Available contacts
			EditOrder.contacts = meta.contacts ? _.sortBy(meta.contacts.data, 'name') : [];

			// Customfields
			EditOrder.customFields = meta.customFields;
			EditOrder.canShowFormGroups = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FORM_GROUPS);
			if (EditOrder.canShowFormGroups) {
				EditOrder.formGroups = Array.from(
					EditOrder.customFields
						.sort((a, b) => a.sortId - b.sortId)
						.reduce((previous, field) => {
							previous.add(field.formGroup);
							return previous;
						}, new Set())
				);
			} else {
				EditOrder.formGroups = [null];
			}
			EditOrder.hasUpdateCustomFieldsOnBlur =
				Tools.FeatureHelper.hasSoftDeployAccess('UPDATE_CUSTOM_FIELDS_ON_BLUR');

			EditOrder.customFieldsLength = _.filter(meta.customFields, function (f) {
				return f.$hasAccess && (f.editable || f.visible);
			}).length;

			EditOrder.orderRowCustomFields = meta.orderrowCustomFields;
			EditOrder.orderRowCustomFieldsLength = _.filter(meta.orderrowCustomFields, function (f) {
				return f.$hasAccess && (f.editable || f.visible);
			}).length;

			if (meta.priceLists) {
				EditOrder.priceLists = meta.priceLists;
				EditOrder.defaultPriceListId = meta.priceLists.find(priceList => priceList.isDefault).id;
			}

			if (meta.salesCoaches) {
				const salesCoaches = meta.salesCoaches.data;
				if (salesCoaches?.length > 0) {
					EditOrder.hasSalesCoach = true;
				}
				if (salesCoaches?.length === 1 && !EditOrder.order.salesCoach) {
					EditOrder.order.salesCoach = salesCoaches[0];
				}

				if (
					salesCoaches?.length === 1 &&
					EditOrder.order.salesCoach?.length === 0 &&
					EditOrder.edit &&
					EditOrder.order.probability !== 100 &&
					EditOrder.order.probability !== 0
				) {
					EditOrder.order.salesCoach = salesCoaches[0];
					Order.customer($stateParams.customerId).save(
						{
							id: EditOrder.order.id,
							salesCoach: salesCoaches[0],
							skipTriggers: true
						},
						{ skipNotification: true }
					);
				}
			}

			if (EditOrder.order.salesCoach && Array.isArray(EditOrder.order.salesCoach)) {
				EditOrder.order.salesCoach = EditOrder.order.salesCoach[0];
			}

			const hasSpeedEditOrderInit = Tools.FeatureHelper.hasSoftDeployAccess('SPEED_EDIT_ORDER_INIT');

			// if this is an edit
			if (EditOrder.edit) {
				if (!hasSpeedEditOrderInit) {
					EditOrder.totalSales = meta.salesReport.data.value;
				}

				EditOrder.activityUpcoming = [];
				EditOrder.activityHistory = [];

				// Order activities
				angular.forEach(meta.activities.data, function (activity) {
					var event = EventService.create.Activity(activity);
					event.date = activity.date;

					if (
						(!activity.isAppointment && activity.closeDate) ||
						(activity.isAppointment && new Date(activity.date).getTime() < new Date().getTime())
					) {
						EditOrder.activityHistory.push(event);
					} else if (activity.isAppointment || (!activity.isAppointment && activity.closeDate === null)) {
						EditOrder.activityUpcoming.push(event);
					}
				});

				EditOrder.orderActivities = meta.activities.data;

				// Set editable
				EditOrder.editable = EditOrder.order.userEditable;

				// if the modal got an initialView we try to use it
				if ($modalParams.initView && EditOrder.tabs[$modalParams.initView]) {
					// set active tab
					EditOrder.activeTab = EditOrder.tabs[$modalParams.initView];
				} else {
					// Default to form
					EditOrder.activeTab = EditOrder.tabs.form;
				}

				// set currency
				EditOrder.selectedCurrency = _.find(metadata.customerCurrencies, { iso: meta.order.data.currency });

				// is order
				EditOrder.isOrder = EditOrder.order.probability === 100 || EditOrder.order.probability === 0;

				calcAge();

				initStages(EditOrder.isOrder ? 'order' : 'opportunity');

				if ($modalParams.stage) {
					EditOrder.order.stage = $modalParams.stage;
					EditOrder.order.probability = $modalParams.stage.probability;
				}
			} else if (EditOrder.quickCreate) {
				EditOrder.activeTab = EditOrder.tabs.form;
				quickCreate();

				initStages(EditOrder.type);
			} else {
				// set active tab
				EditOrder.activeTab = EditOrder.tabs.form;

				initStages(EditOrder.type);

				// set default stage
				if (!EditOrder.order.stage) {
					if (metadata.params.defaultStageId) {
						// Select default stage if we have one and we can find it else select first
						EditOrder.order.stage =
							_.find(EditOrder.stages, { id: metadata.params.defaultStageId }) || EditOrder.stages[0];
					} else {
						// Select first
						EditOrder.order.stage = EditOrder.stages[0];
					}
				}
				EditOrder.order.probability = EditOrder.order.stage.probability;
			}

			EditOrder.allSalescoaches = meta.salesCoaches.data;
			EditOrder.availableSalescoaches = [];
			getAvailableSalesCoaches();

			if (EditOrder.order.salesCoach?.id) {
				SalesCoach.get(EditOrder.order.salesCoach.id)
					.then(({ data }) => {
						if (data.decisionMakers.active) {
							EditOrder.salesCoachDecisionMaker = {
								salesCoachId: data.id,
								hasTitleCategories: data.decisionMakersPro?.typeOfDecisionMakers === 'titleCategories'
							};
							EditOrder.decisionMakersEnabled = true;
						}
					})
					.catch(e => logError(e, 'Failed to get SalesCoach'));
			}

			EditOrder.lockedActivity = false;

			var order = meta.order.data;

			if ($parse('order.data.id')(meta)) {
				order.contacts = $parse('contacts[0]')(order);
				EditOrder.lockedActivity = !!order.closeDate;
				EditOrder.contactPerson = order.contacts;

				if (EditOrder.contactPerson) {
					EditOrder.contactPerson.manualOptins = _.filter(EditOrder.contactPerson.optins, function (o) {
						return o.type === 'manual';
					});
				}
			}

			if (EditOrder.order.orderRow) {
				var sortId = 0;
				EditOrder.order.orderRow.forEach(function (row) {
					sortId++;
					row.$listPrice = row.listPrice * EditOrder.order.currencyRate;

					row.$discount = parseFloat(
						((row.listPrice - row.price) * row.quantity * EditOrder.order.currencyRate).toFixed(
							EditOrder.numOfDecimalsPrice
						)
					);

					EditOrder.calc.discountChange(row);

					if (row.sortId === undefined) {
						row.sortId = sortId;
					}

					// Set purchaseCost
					row.purchaseCost =
						(row.purchaseCost === null ? 0 : row.purchaseCost) * EditOrder.order.currencyRate;

					// fixes custom fields and adds properties that aren't mapped in the order request. e.g isRecurring
					row = fixProductFields(row);

					row.bundleRows?.forEach(bundleRow => {
						const product = _.find(EditOrder.orderProducts, { id: bundleRow.productId });
						bundleRow.product = product ?? {};
						const bundle = row.product?.bundle?.find(b => b.product?.id === bundleRow.product.id);
						bundleRow.adjustable = !!bundle?.adjustable;
						bundleRow.bundleRowPrice = bundleRow.price;
						bundleRow.bundleRowCost = bundleRow.purchaseCost;
					});

					// "fix quantity" for tiered products
					// if a product is tiered we set quantity = tierQuantity so that the editor will work
					// Before save we set tierQuantity = quantity and quantity = 1 so that backend calculations and integrations will work
					if (isTieredOrderRow(row)) {
						row.quantity = row.tierQuantity;
					}
				});

				// Sort by sortId on init
				EditOrder.order.orderRow = _.sortBy(EditOrder.order.orderRow, 'sortId');
			}

			if (!EditOrder.order.orderRow) {
				EditOrder.order.orderRow = [];
			}

			// This is loaded by accountChange that is run in init
			EditOrder.activeAgreementGroups = [];

			// Set order client if we got one and lock account
			if (meta.client && meta.client.data) {
				EditOrder.order.client = meta.client.data;
				EditOrder.order.lastSuccess.client = EditOrder.order.client;
				EditOrder.lockedAccount = true;
			}

			// Set order contact if we have one and lock contact
			if (meta.contact?.data && !meta.missingContactRights) {
				EditOrder.order.contact = meta.contact.data;
				EditOrder.contactPerson = meta.contact.data;
				EditOrder.lockedContact = true;
			}

			EditOrder.hasMultiCurrency = metadata.params.MultiCurrency;

			// Set selected currency
			EditOrder.selectedCurrency = _.find(metadata.customerCurrencies, { iso: meta.order.data.currency });

			// Set up some rights
			if (meta.type === 'opportunity') {
				EditOrder.ownRightsId =
					EditOrder.self.createRights.Opportunity === 'OWN' ? EditOrder.self.id : undefined;
			} else {
				EditOrder.ownRightsId = EditOrder.self.createRights.Order === 'OWN' ? EditOrder.self.id : undefined;
			}

			setClientOrderRelation();

			// Trigger account change on init
			EditOrder.accountChange(true);

			// Setup select2 configs
			EditOrder.select = {
				user: {
					formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
					formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
					data: meta.users.data,
					matcher: function (term, undef, obj) {
						return obj.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
					}
				},
				salesCoaches: {
					formatSelection: (obj, container, encode) => encode(obj.name),
					formatResult: (obj, container, query, escape) => escape(obj.name),
					data: EditOrder.availableSalescoaches,
					matcher: function (term, undef, obj) {
						return obj.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
					}
				},
				priceLists: {
					formatSelection: (obj, container, encode) => encode(obj.name),
					formatResult: (obj, container, query, escape) => escape(obj.name),
					data: EditOrder.priceLists,
					matcher: function (term, undef, obj) {
						return obj.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
					}
				},
				relatedClients: {
					allowClear: 1,
					formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
					formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
					data: EditOrder.relatedClients,
					matcher: function (term, undef, obj) {
						return obj.name.toUpperCase().indexOf(term.toUpperCase()) === 0;
					}
				},
				currency: {
					formatSelection: (obj, container, escape) => escape(_.property('iso')(obj)),
					formatResult: (obj, container, query, escape) => escape(_.property('iso')(obj)),
					data: metadata.customerCurrencies.filter(currency => currency.active),
					id: 'iso',
					matcher: function (term, undef, obj) {
						return obj.iso.toUpperCase().indexOf(term.toUpperCase()) >= 0;
					}
				},
				contact: {
					data: EditOrder.contacts,
					formatSelection: selectHelper.wrapFormatSelectionLink(
						function (contact) {
							return utils.select2.clientContactsAndRelations.formatSelection(
								contact,
								EditOrder.order.client ? EditOrder.order.client.id : null
							);
						},
						function (contact, $state) {
							$state.go('contact.dashboard', { id: contact.id });
						}
					),
					formatResult: function (contact) {
						if (!contact.id) {
							return '<i class="grey">' + $translate.instant(contact.name) + '</i>';
						}
						return utils.select2.clientContactsAndRelations.formatResult(
							contact,
							EditOrder.order.client ? EditOrder.order.client.id : null
						);
					},
					minimumResultsForSearch: 8,
					allowClear: true,
					matcher: function (term, undef, obj) {
						return obj.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
					}
				},
				contactAjax: {
					data: [],
					formatSelection: selectHelper.wrapFormatSelectionLink(
						function (contact) {
							return utils.select2.clientContactsAndRelations.formatSelection(
								contact,
								EditOrder.order.client ? EditOrder.order.client.id : null
							);
						},
						function (contact, $state) {
							$state.go('contact.dashboard', { id: contact.id });
						}
					),
					formatResult: function (contact) {
						if (!contact.id) {
							return '<i class="grey">' + $translate.instant(contact.name) + '</i>';
						}
						return utils.select2.clientContactsAndRelations.formatResult(
							contact,
							EditOrder.order.client ? EditOrder.order.client.id : null
						);
					},
					minimumResultsForSearch: 8,
					minimumInputLength: 1,
					allowClear: true,
					matcher: function (term, undef, obj) {
						return obj.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
					},
					ajax: {
						data: function (term) {
							return term;
						},
						transport: function (query) {
							if (query.data && EditOrder.order.client) {
								const clientIds = getClientIds();

								var contactFilter = new RequestBuilder();
								contactFilter.addFilter(
									Contact.attr.name,
									contactFilter.comparisonTypes.Search,
									query.data
								);
								contactFilter.addFilter(
									Contact.attr.client.attr.id,
									contactFilter.comparisonTypes.Equals,
									clientIds
								);

								return Contact.customer(customerId)
									.find(contactFilter.build())
									.then(res => {
										const data = groupContacts(res.data, false);
										return query.success({ data });
									});
							}

							return query.success({
								data: []
							});
						},
						results: function (res) {
							return {
								results: res.data
							};
						}
					}
				}
			};

			EditOrder.convert = EditOrder.order.currency !== EditOrder.masterCurrency;

			// Use discount or not
			EditOrder.useDiscount = metadata.params.UseDiscount;

			EditOrder.isOld = EditOrder.isOldDate();

			// Links
			getLinks();

			// Check if contact has required fields
			var defaultRequiredFields = false;
			angular.forEach(metadata.requiredFields.Contact, function (required, field) {
				if (field !== 'Email' && required) {
					defaultRequiredFields = true;
				}
			});

			var customRequiredFields = _.filter(meta.contactCustomFields, 'obligatoryField').length;
			hasRequiredContactFields = defaultRequiredFields || customRequiredFields;

			// SI ui-elements
			if (metadata.integrations && metadata.integrations.uiElements) {
				EditOrder.uiElements = metadata.integrations.uiElements.editOrder;
			}

			EditOrder.updateCustomFieldRequiredness();

			EditOrder.standardFields = metadata.standardFields.Client;

			setTimeout(function () {
				tippy('#notes', {
					animation: 'fade',
					arrow: true,
					hideOnClick: false,
					interactive: true,
					maxWidth: '230px',
					placement: 'left',
					size: 'large',
					theme: 'standard-field',
					trigger: 'focus',
					zIndex: 11000
				});
			});

			EditOrder.recurringIntervals = AppService.getStaticValues('recurringInterval');

			EditOrder.documentTemplates = AppService.getDocumentTemplates('order');
			EditOrder.hasDocumentTemplates = EditOrder.documentTemplates && EditOrder.documentTemplates.length;

			//Binding standard templates to old ui props
			pdfTemplateResource
				.find()
				.then(response => {
					const activeDocuments = [];
					response.data.forEach(element => {
						if (
							(element.type === 'standard' && element.active) ||
							(element.type === 'custom' && element.templateData.published === 'true')
						) {
							const documentDetails = {
								id: element.id,
								name: element.templateData.template_name,
								uuid: element.uuid,
								roles: element.roles,
								supportedLanguages: element.templateData.template_details.language,
								type: element.type
							};
							activeDocuments.push(documentDetails);
						}
					});
					EditOrder.documentTemplates = [...EditOrder.documentTemplates, ...activeDocuments];
				})
				.catch(error => {
					if (error instanceof Error) {
						logError(error, 'Failed to get document templates');
					}
				})
				.finally(() => {
					EditOrder.hasDocumentTemplates = EditOrder.documentTemplates && EditOrder.documentTemplates.length;
					$safeApply($scope);
				});

			ScriptService.order.init(EditOrder.order);
			callEditOrderListeners(EditOrder.order);

			if ($modalParams.showPeriodizing) {
				EditOrder.isPeriodizing = true;
			}

			EditOrder.probabilityActive = metadata.standardFields.Order?.Probability?.active;
			const isOrderStage = EditOrder.order.stage?.probability === 0 || EditOrder.order.stage?.probability === 100;
			EditOrder.hideProbability =
				isOrderStage || (EditOrder.isAvailable.newFields && !EditOrder.probabilityActive);

			EditOrder.riskChipProps = getRiskChipProps(EditOrder.order);

			if ($modalParams.products && $modalParams.products.length) {
				$modalParams.products.forEach(relProduct => {
					const product = _.find(EditOrder.orderProducts, { id: relProduct.id });
					EditOrder.addOrderRowWithProduct(product, relProduct.quantity);
				});
			}

			const speedTracker = getSpeedTracker('EditOrder');
			speedTracker?.publishResults([
				{ type: 'pageLoad', selector: '.TimelineCard' },
				{ type: 'interactable', selector: '.fake-product-select' }
			]);
		};

		$scope.$on('modal.ready', function () {
			AppService.loadedPromise
				.then(function () {
					EditOrder.hasNotes = Tools.FeatureHelper.hasSoftDeployAccess('NOTES');
				})
				.catch(err => {
					if (err instanceof Error) {
						logError(err);
					}
				});
		});

		// run modifying scripts on order changes
		$scope.$watch(
			'EditOrder.order',
			function (item, oldItem) {
				let hasExcessiveDiscount = false;
				EditOrder.order.orderRow.forEach(function (row) {
					if (row.hasExcessiveDiscount) {
						hasExcessiveDiscount = true;
					}

					row.custom.forEach(function (field) {
						if (field.datatype === 'Calculation' && FeatureHelper.isAvailable('CALCULATING_FIELDS')) {
							var newValue = window.calculateField.calculate(field.formula, row);
							if (field.value !== newValue) {
								field.value = newValue;
							}
						}
					});
				});

				$scope.OrderForm.$setValidity('excessiveDiscount', !hasExcessiveDiscount || self.administrator);

				EditOrder.order.custom.forEach(function (field) {
					if (field.datatype === 'Calculation' && FeatureHelper.isAvailable('CALCULATING_FIELDS')) {
						var newValue = window.calculateField.calculate(field.formula, EditOrder.order);
						if (field.value !== newValue) {
							field.value = newValue;
						}
					}
				});

				const hasEditOrderChangeListeners =
					Tools.ScriptFramework.listeners[Tools.ScriptFramework.events.editOrderChange].length;
				if (hasEditOrderChangeListeners) {
					Tools.ScriptFramework.run(Tools.ScriptFramework.events.editOrderChange, item, oldItem)
						.then(function (data) {
							EditOrder.saveDisabled = data.saveDisabled;
							$safeApply($scope);
						})
						.catch(err => {
							if (err instanceof Error) {
								logError(err);
							}
						});
				}

				if (EditOrder.editOrderListeners?.length) {
					callEditOrderListenersIfChanged(item, oldItem);
				}

				if (EditOrder.hasProjectPlanFeature) {
					EditOrder.productsWithProjectPlanTemplates =
						EditOrder.order.orderRow
							?.filter(product => product.product?.projectPlanTemplate)
							?.map(product => product.product) ?? [];

					EditOrder.order.projectPlanOptions =
						EditOrder.order.projectPlanOptions?.filter(
							({ projectPlanTemplateId }) =>
								EditOrder.order.orderRow?.some(
									orderRow => orderRow.product?.projectPlanTemplate?.id === projectPlanTemplateId
								) ?? false
						) ?? [];
				}

				EditOrder.reRenderCounter = EditOrder.reRenderCounter + 1;
			},
			true
		);

		const unsubscribe = $scope.$on('modal.ready', function () {
			AppService.loadedPromise
				.then(() => {
					init();
					EditOrder.controllerDone = true;
				})
				.catch(err => {
					if (err instanceof Error) {
						logError(err);
					}
				});
		});
		$scope.$on('$destroy', function () {
			unsubscribe();
		});
	}
]);

// Måste finliras olch flyttas innan vi kan använda
angular.module('upDirectives').directive('autosize', function () {
	return {
		restrict: 'A',
		link: function (scope, elem, attrs) {
			var turnOff = scope.$watch(attrs.ngModel, function (val) {
				if (val !== undefined) {
					if (elem[0].scrollHeight > elem.outerHeight()) {
						elem.css('height', elem[0].scrollHeight + 'px');
					}
					turnOff();
				}
			});
		}
	};
});
