import { reactRouteCompatibility } from 'App/helpers/angularRouteHelpers';
import AdminHeader from 'Components/Admin/AdminHeader';
import OptInList from 'Components/OptInList/OptInList';

ReactTemplates.admin.optIns = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			optInSettings: t('admin.optInSettings'),
			optInInfo: t('admin.optInInfo'),
			title: t('default.title'),
			cancel: t('cancel'),
			save: t('default.save')
		};
	},

	render: function () {
		return (
			<div id="admin-page-optins">
				<AdminHeader
					title={this.lang.optInSettings}
					description={this.lang.optInInfo}
					image="optin-settings.svg"
					articleId={522}
				/>
				<div id="admin-content">
					<OptInList />
					<OptInList manual />
				</div>
			</div>
		);
	}
});

const WrappedOptIns = () => {
	return (
		<div id="admin" className="WrappedOptIns">
			<div id="admin-root">
				<ReactTemplates.admin.optIns />
			</div>
		</div>
	);
};

export const PortedOptIns = reactRouteCompatibility(WrappedOptIns, {
	featureFlag: 'REACT_ADMIN_OPTIN',
	wrapInPage: false
});
