import { status } from 'App/resources/MailCampaign';
import history from 'App/pages/routes/history';
import { Text, Link } from '@upsales/components';
import logError from 'App/babel/helpers/logError';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

const MAIL_CAMPAIGN_STATUS = status;

ReactTemplates.navbar.notificationRow = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			didSign: t('esign.didSign').toLowerCase(),
			viewed: t('esign.viewed').toLowerCase(),
			rejected: t('esign.rejected').toLowerCase(),
			undelivered: t('esign.undelivered').toLowerCase(),
			closedDeal: t('default.closedDeal').toLowerCase(),
			sharedView: t('notifications.sharedView').toLowerCase(),
			sharedViewWithYou: t('notifications.sharedView.withYou').toLowerCase(),
			with: t('default.with').toLowerCase(),
			createdAgreement: t('default.createdAgreement').toLowerCase(),
			yearlyValue: t('default.yearlyValue').toLowerCase(),
			didAssign: t('default.didAssign').toLowerCase(),
			mail: {
				soft_bounce: t('mail.bounced').toLowerCase(),
				hard_bounce: t('mail.bounced').toLowerCase(),
				error: t('mail.failed').toLowerCase()
			},
			toYou: t('default.toYou').toLowerCase(),
			at: t('default.at').toLowerCase(),
			to: t('default.to').toLowerCase(),
			theMail: t('default.theMail'),
			formSubmited: t('event.form.submited').toLowerCase(),
			worth: t('notifications.order.value.worth').toLowerCase(),
			oneOffValue: t('default.oneOffValue'),
			monthlyValue: t('default.monthlyValue'),
			annualValue: t('default.annualValue'),
			contributionMargin: t('default.contributionMarginShort'),
			visited: t('event.visited').toLowerCase(),
			failed: t('noti.failed').toLowerCase(),
			done: t('default.done').toLowerCase(),
			export: t('export.export').toLowerCase(),
			hadMarketingEvent: t('event.hadMarketingEvent'),
			clickDownload: t('noti.export.clickDownload').toLowerCase(),
			contacts: t('default.contacts2'),
			clients: t('default.clients')
		};
	},
	getRootClass: function () {
		var notification = this.props.notification;
		var className = [];

		if (!notification.read) {
			className.push('unread');
		}
		if (notification.type === 'Order') {
			className.push('order-notification');
		}
		if (notification.type === 'ListView' || notification.type === 'ReportView') {
			className.push('shared-view-notification');
		}
		if (notification.type === 'Agreement') {
			className.push('agreement-notification');
		}
		if (notification.type === 'Assigned') {
			className.push('assign-notification');
		}
		if (notification.type === 'Submit') {
			className.push('form-notification');
		}
		if (notification.type === 'Visit') {
			className.push('visit-notification');
		}
		if (notification.type === 'Mail') {
			className.push('mail-notification');

			if (notification.action.indexOf('bounce') >= 0) {
				className.push('failed-notification-mail');
			}
		}
		if (notification.type === 'MarketingCustom') {
			className.push('marketingcustom-notification');
		}
		if (notification.type === 'AppCallback') {
			className.push('appcallback-notification');
		}
		if (notification.type === 'System' && notification.action === 'message') {
			className.push('system-notification');
		}
		if (notification.type === 'System' && notification.action === 'alert') {
			className.push('alert-notification');
		}
		if (notification.type === 'Job') {
			className.push('job-notification');
		}
		if (notification.type === 'Export') {
			className.push('export-notification');
		}
		if (notification.type === 'Import') {
			className.push('import-notification');
		}
		if (notification.type === 'Job' && notification.status === -1) {
			className.push('failed-notification');
		}
		if (notification.type === 'MailCampaign' && notification.status === -1) {
			className.push('failed-notification');
		}
		if (notification.type === 'Provisioning') {
			className.push('provisioning-notification');
		}

		return className.join(' ');
	},
	getIconClass: function () {
		var notification = this.props.notification;
		var className = ['fa'];

		if (notification.type === 'Order' || notification.type === 'Agreement') {
			className.push('Icon Icon-sales');
		}
		if (notification.type === 'ListView' || notification.type === 'ReportView') {
			className.push('fa-eye');
		}
		if (notification.type === 'Assigned') {
			className.push('fa-star');
		}
		if (notification.type === 'Submit') {
			className.push('fa-file');
		}
		if (notification.type === 'Visit') {
			className.push('fa-globe');
		}
		if (notification.type === 'Mail') {
			className.push('fa-envelope');
		}
		if (notification.type === 'MarketingCustom') {
			className.push('fa-bullseye');
		}
		if (notification.type === 'AppCallback') {
			if (notification.message && notification.message.includes('"easybooking"')) {
				className.push('fa-calendar');
			} else {
				className.push('fa-info-circle');
			}
		}
		if (notification.type === 'Job' && notification.status === 100) {
			className.push('fa-check-square-o');
		}
		if (notification.type === 'Job' && notification.status === -1) {
			className.push('fa-times');
		}
		if (notification.type === 'Export' && notification.status !== -1) {
			className.push('fa-check-square-o');
		}
		if (notification.type === 'Export' && notification.status === -1) {
			className.push('fa-times');
		}
		if (notification.type === 'Import' && notification.status !== -1) {
			className.push('fa-check-square-o');
		}
		if (notification.type === 'Import' && notification.status === -1) {
			className.push('fa-times');
		}
		if (notification.type === 'System' && notification.action === 'message') {
			className.push('fa-exclamation-circle');
		}
		if (notification.type === 'System' && notification.action === 'alert') {
			className.push('fa-exclamation-triangle');
		}
		if (notification.type === 'Esign' && notification.action === 'seen') {
			className.push('fa-eye');
		}
		if (notification.type === 'Esign' && notification.action === 'signed') {
			className.push('fa-edit');
		}
		if (notification.type === 'Esign' && notification.action === 'declined') {
			className.push('fa-times');
		}
		if (notification.type === 'Default') {
			className.push('fa-info-circle');
		}
		if (notification.type === 'MailCampaign' && notification.status === -1) {
			className.push('fa-exclamation-triangle');
		}
		if (notification.type === 'Provisioning') {
			className.push('Icon Icon-tiers');
		}

		return className.join(' ');
	},
	getSecond: function () {
		var noti = this.props.notification;
		var getStateHref = this.props.getStateHref;
		var currencyFormat = Tools.$filter('currencyFormat');
		var t = Tools.$translate;
		var exportUrl = this.props.exportUrl;
		var cn = 'second';
		var contact = null;
		var metadata = Tools.AppService.getMetadata();
		var defaultCurrencyIso = _.find(metadata.customerCurrencies, { masterCurrency: true }).iso;

		switch (noti.type) {
			case 'Esign':
				var status = '';
				switch (noti.action) {
					case 'signed':
						status = this.lang.didSign;
						break;
					case 'seen':
						status = this.lang.viewed;
						break;
					case 'declined':
						status = this.lang.rejected;
						break;
					case 'undelivered':
						status = this.lang.undelivered;
						break;
				}
				var fileName = '';
				if (noti.esign) {
					if (noti.esign.file) {
						fileName = noti.esign.file.filename;
					} else if (noti.esign.title) {
						fileName = noti.esign.title;
					}
				}
				return (
					<div className={cn}>
						<b>
							{noti.contact ? (
								<a href={getStateHref('contact.dashboard', { id: noti.contact.id })}>
									{noti.contact.name}
								</a>
							) : (
								<span>{noti.registeredBy ? noti.registeredBy.name : ''}</span>
							)}
						</b>
						<span> {status}</span>
						<b> {fileName}</b>
					</div>
				);
			case 'Order':
				var salesModel = metadata.params.SalesModel;
				var salesModelOption = metadata.params.SalesModelOption;
				var showContributionMargin = salesModel === 'cm' && salesModelOption === 'cm';
				var showRecurring = salesModel === 'rr';
				var showARR = showRecurring && salesModelOption === 'arr';

				var orderValue = 0;
				var recurringValue = 0;
				var oneOffValue = 0;
				var displayRecurring, displayARR, displayMRR, displayOneOff, displayCM;

				if (noti.order) {
					let formatted;
					orderValue = noti.order.orderValue;

					if (showContributionMargin) {
						formatted = formatted = currencyFormat(
							noti.order.contributionMarginLocalCurrency,
							noti.order.currency || defaultCurrencyIso
						);
						displayCM = `${formatted} (${this.lang.contributionMargin})`;
					} else if (showRecurring) {
						if (showARR) {
							recurringValue = noti.order.annualValue;
							formatted = currencyFormat(
								noti.order.annualValue,
								noti.order.currency || defaultCurrencyIso
							);
							displayARR = recurringValue ? `${formatted} (${this.lang.annualValue})` : null;
						} else {
							recurringValue = noti.order.monthlyValue;
							formatted = currencyFormat(
								noti.order.monthlyValue,
								noti.order.currency || defaultCurrencyIso
							);
							displayMRR = recurringValue ? `${formatted} (${this.lang.monthlyValue})` : null;
						}

						oneOffValue = noti.order.oneOffValue;
						formatted = currencyFormat(noti.order.oneOffValue, noti.order.currency || defaultCurrencyIso);
						displayOneOff = oneOffValue ? `${formatted} (${this.lang.oneOffValue})` : null;

						displayRecurring = displayARR || displayMRR;
						if (displayOneOff) {
							if (displayRecurring) {
								displayRecurring = displayRecurring + ' & ' + displayOneOff;
							} else {
								displayRecurring = displayOneOff;
							}
						}
					}
				}

				return (
					<div className={cn}>
						<b>{noti.registeredBy ? noti.registeredBy.name : ''} </b>
						<span>
							{this.lang.closedDeal} {this.lang.with}
						</span>
						<b>
							{noti.client ? (
								<a href={getStateHref('account.dashboard', { id: noti.client.id })}>
									{' '}
									{noti.client.name}
								</a>
							) : null}
						</b>
						<b>
							{' ' + this.lang.worth + ' '}
							<span>
								{showRecurring ? displayRecurring : null}
								{showContributionMargin ? displayCM : null}
								{!showRecurring && !showContributionMargin
									? currencyFormat(
											orderValue,
											(noti.order && noti.order.currency) || defaultCurrencyIso
									  )
									: null}
							</span>
						</b>
					</div>
				);
			case 'ReportView':
			case 'ListView':
				return (
					<div className={cn}>
						<b>{noti.registeredBy ? noti.registeredBy.name : ''} </b>
						<span>{this.lang.sharedView}</span>
						<b> {noti.message}</b>
						<span>{' ' + this.lang.sharedViewWithYou + '.'}</span>
					</div>
				);
			case 'Agreement':
				return (
					<div className={cn}>
						<b>{noti.registeredBy ? noti.registeredBy.name : ''} </b>
						{this.lang.createdAgreement} {this.lang.with}
						<b>
							{noti.client ? (
								<a href={getStateHref('account.dashboard', { id: noti.client.id })}>
									{' '}
									{noti.client.name}
								</a>
							) : null}
						</b>
						<b>
							{' '}
							{'('}
							<span>
								{this.lang.yearlyValue}{' '}
								{currencyFormat(
									noti.agreement ? noti.agreement.yearlyValue : 0,
									(noti.agreement ? noti.agreement.currency : null) || defaultCurrencyIso
								)}
							</span>
							{')'}
						</b>
					</div>
				);
			case 'Assigned':
				return (
					<div className={cn}>
						<b>{noti.registeredBy ? noti.registeredBy.name : ''}</b> {this.lang.didAssign}
						<b>
							{noti.client ? (
								<a href={getStateHref('account.dashboard', { id: noti.client.id })}>
									{' '}
									{noti.client.name}
								</a>
							) : null}
						</b>{' '}
						{this.lang.toYou}
					</div>
				);
			case 'Submit':
				return (
					<div className={cn}>
						{noti.contact ? (
							<b>
								<a href={getStateHref('contact.dashboard', { id: noti.contact.id })}>
									{' '}
									{noti.contact.name}
								</a>
							</b>
						) : null}{' '}
						{this.lang.at}
						<b>
							{noti.client ? (
								<a href={getStateHref('account.dashboard', { id: noti.client.id })}>
									{' '}
									{noti.client.name}
								</a>
							) : null}
						</b>{' '}
						{this.lang.formSubmited}
						<b>{noti.form ? <a href={`#/form-overview/${noti.form.id}`}> {noti.form.name}</a> : null}</b>
					</div>
				);
			case 'Visit':
				contact = null;
				if (noti.contact) {
					contact = (
						<span>
							<b>
								<a href={getStateHref('contact.dashboard', { id: noti.contact.id })}>
									{noti.contact.name}
								</a>
							</b>{' '}
							{this.lang.at}{' '}
						</span>
					);
				}
				return (
					<div className={cn}>
						{contact}
						<b>
							{noti.client ? (
								<a href={getStateHref('account.dashboard', { id: noti.client.id })}>
									{noti.client.name}
								</a>
							) : null}
						</b>{' '}
						{this.lang.visited}
					</div>
				);
			case 'Mail':
				return (
					<div className={cn}>
						{this.lang.theMail + ' ' + this.lang.to + ' '}
						{noti.contact ? (
							<React.Fragment>
								<a href={getStateHref('contact.dashboard', { id: noti.contact.id })}>
									{noti.contact.name}
								</a>
								{' ' + this.lang.at + ' '}
							</React.Fragment>
						) : null}
						{noti.client ? (
							<a href={getStateHref('account.dashboard', { id: noti.client.id })}>{noti.client.name}</a>
						) : null}{' '}
						{this.lang.mail[noti.action]}
					</div>
				);
			case 'AppCallback':
				if (noti.message && noti.message.includes('"easybooking"')) {
					try {
						const message = JSON.parse(noti.message);
						const { contact, appointment } = message.easybooking;
						return (
							<div className={cn}>
								<Link href={getStateHref('contact.dashboard', { id: contact.id })}>{contact.name}</Link>
								{` ${Tools.$translate('default.booked').toLowerCase()} `}
								<Link onClick={() => openEditAppointment({ id: appointment.id })}>
									{appointment.description}
								</Link>
								{` ${Tools.$translate('notifications.sharedView.withYou')} @ ${moment(
									appointment.date
								).format('YYYY-MM-DD - HH:mm')}`}
							</div>
						);
					} catch (e) {
						logError(e, 'Could not parse json');
					}
				}
				return (
					<div className={cn}>
						<b>{noti.action}</b> <i>{noti.message ? Tools.$translate(noti.message) : ''}</i>
					</div>
				);
			case 'Default':
				return (
					<div className={cn}>
						<i>{noti.message ? Tools.$translate(noti.message) : ''}</i>
					</div>
				);
			case 'MailCampaign':
				if (noti.action === MAIL_CAMPAIGN_STATUS.NO_UNSUB && noti.mailCampaign) {
					return (
						<div className={cn}>
							{Tools.$translate('groupMail.wasNotSentMissingUnsub', { NAME: noti.mailCampaign.name })}{' '}
							<a onClick={() => history.push(`/group-mail-editor/${noti.mailCampaign.id}`)}>
								{Tools.$translate('groupMail.clickHereToEdit')}
							</a>
						</div>
					);
				}
				break;
			case 'MarketingCustom':
				contact = null;
				if (noti.contact) {
					contact = (
						<span>
							<b>
								<a href={getStateHref('contact.dashboard', { id: noti.contact.id })}>
									{noti.contact.name}
								</a>
							</b>{' '}
							{this.lang.at}{' '}
						</span>
					);
				}
				return (
					<div className={cn}>
						{contact}
						<b>
							{noti.client ? (
								<a href={getStateHref('account.dashboard', { id: noti.client.id })}>
									{noti.client.name}
								</a>
							) : null}
						</b>{' '}
						{this.lang.hadMarketingEvent} <i>{noti.message ? noti.message : ''}</i>
					</div>
				);
			case 'System':
				return <div className={cn}>{noti.message}</div>;
			case 'Provisioning': {
				const nameLangTag = `admin.billing.addon.${noti.message}.name`;
				const translatedAddonName = Tools.$translate(nameLangTag);
				const missingName = translatedAddonName === nameLangTag;
				// Make sure we do not show lang tag if missing name
				const addonName = missingName ? Tools.$translate('admin.billing.addon.anAddon') : translatedAddonName;
				return (
					<div className={cn}>
						<Text size="sm">
							{noti.status === 100
								? Tools.$translate('admin.billing.addon.reachedNextTierOn')
								: Tools.$translate('admin.billing.addon.boutToReachNextTierOn')}{' '}
						</Text>
						<Text size="sm" bold={!missingName}>
							{addonName}
							{'. '}
						</Text>
						<Link size="sm" href={getStateHref('administration.billing')}>
							{Tools.$translate('admin.billing.addon.manageAddons')}
						</Link>
					</div>
				);
			}
			case 'Job':
				var content = null;
				var udoName = noti.udo ? noti.udo.name : '';
				if (noti.name) {
					if (noti.status === -1) {
						content = (
							<span>
								{t(noti.action)} {noti.name} {this.lang.failed}
							</span>
						);
					} else if (noti.status === 100) {
						content = (
							<span>
								{t(noti.action)} {noti.name} {this.lang.done}
							</span>
						);
					}
				} else {
					if (noti.status === -1) {
						content = (
							<span>{t('noti.' + noti.action + '-failed', { total: noti.message, name: udoName })}</span>
						);
					} else if (noti.status === 100) {
						var splt = (noti.message || '').toString().split(':');
						if (splt && splt.length === 2) {
							content = (
								<span>
									{t('noti.' + noti.action + '-partial-done', {
										changed: splt[0],
										total: splt[1],
										name: udoName
									})}{' '}
									<br /> {t('default.missingRights')}
								</span>
							);
							/* eslint-disable eqeqeq */
						} else if (noti.message == 0) {
							/* eslint-enable eqeqeq */
							content = (
								<span>
									{t('noti.' + noti.action + '-noop-done', { total: noti.message, name: udoName })}{' '}
									<br /> {t('default.missingRights')}
								</span>
							);
						} else {
							content = (
								<span>
									{t('noti.' + noti.action + '-done', { total: noti.message, name: udoName })}
								</span>
							);
						}
					}
				}
				return <div className={cn}>{content}</div>;
			case 'Export':
				var text = null;
				if (noti.name) {
					switch (noti.status) {
						case -1:
							text = (
								<span>
									{noti.name} {this.lang.export}
									{this.lang.failed}
								</span>
							);
							break;
						case 0:
							text = (
								<span>
									{noti.name} {this.lang.export}
								</span>
							);
							break;
						case 100:
							var exportSplt = noti.message ? noti.message.split(',') : [];
							text =
								exportSplt.length === 1 ? (
									<span>
										{noti.name} {this.lang.export}{' '}
										<a href={exportUrl + noti.message} target="_blank">
											{this.lang.clickDownload}
										</a>
									</span>
								) : (
									<span>
										{noti.name} {this.lang.export} {this.lang.done} <a>{this.lang.clickDownload}</a>{' '}
										{'('}
										{exportSplt.length}
										{')'}
									</span>
								);
							break;
					}
				} else {
					switch (noti.status) {
						case -1:
							text = (
								<span>
									{noti.name} {t('noti.export.' + noti.action + '-failed')}
								</span>
							);
							break;
						case 0:
							text = <span>{t('noti.export.' + noti.action)}</span>;
							break;
						case 100:
							var split = noti.message ? noti.message.split(',') : [];
							text =
								split.length === 1 ? (
									<span>
										{t('noti.export.' + noti.action + '-done')}{' '}
										<a href={exportUrl + noti.message} target="_blank">
											{this.lang.clickDownload}
										</a>
									</span>
								) : (
									<span>
										{t('noti.export.' + noti.action + '-done')} <a>{this.lang.clickDownload}</a>{' '}
										{'('}
										{split.length}
										{')'}
									</span>
								);
							break;
					}
				}
				return (
					<div className={cn}>
						<div>{text}</div>
					</div>
				);
			case 'Import':
				var importText = null;
				switch (noti.status) {
					case -1:
						importText = (
							<span>
								{noti.name} {t('noti.import-failed')}
							</span>
						);
						break;
					case 0:
						var total = ((noti.message || 'r0').split(':')[0] || 'r0').replace('r', '');
						importText = <span>{t('noti.importingRows', { total: total })}</span>;
						break;
					case 100:
						var numContacts = 0;
						var numClients = 0;
						var totalSplit = (noti.message || 'r0:co0:cl0').split(':');
						if (totalSplit.length === 3) {
							numContacts = totalSplit[1].replace('co', '');
							numClients = totalSplit[2].replace('cl', '');
						}
						var btnStyle = {
							display: 'block',
							width: '100%',
							height: '20px',
							lineHeight: '10px',
							textAlign: 'right',
							padding: '0'
						};
						importText = (
							<div>
								<div className="pull-left">
									<div>{t('noti.import-done')}</div>
									<div style={{ fontSize: '10px' }}>
										{numContacts} {this.lang.contacts}
										{','} {numClients} {this.lang.clients}
									</div>
								</div>
								<div className="pull-right">
									<button
										type="button"
										className="up-btn btn-xs btn-bright-blue btn-link"
										style={btnStyle}
										key="contacts"
										onClick={this.showImport.bind(this, noti, 'contact')}
									>
										{t('default.showContacts')}
									</button>
									<button
										type="button"
										className="up-btn btn-xs btn-bright-blue btn-link"
										style={btnStyle}
										key="clients"
										onClick={this.showImport.bind(this, noti, 'client')}
									>
										{t('default.showClients')}
									</button>
								</div>
							</div>
						);
						break;
				}
				return (
					<div className={cn}>
						<div>{importText}</div>
					</div>
				);
		}
	},
	showImport: function (notification, type, e) {
		e.stopPropagation();
		this.props.showImport(notification, type);
	},
	formatDate: function () {
		var dateMoment = moment(this.props.notification.date);
		if (moment().isSame(dateMoment, 'day')) {
			return dateMoment.format('HH:mm');
		} else {
			return dateMoment.format('DD MMM').toLowerCase();
		}
	},
	render: function () {
		var self = this;
		var rootClass = self.getRootClass();
		var iconClass = self.getIconClass();
		var second = self.getSecond();

		return (
			<li className={rootClass} onClick={self.props.onClick}>
				<div className="notification">
					<div className="first">
						<div className="timestamp">
							<b className={iconClass} />
							<br />
							<span>{self.formatDate()}</span>
						</div>
					</div>

					{second}
				</div>
			</li>
		);
	}
});
