import React from 'react';
import { Text, Checkbox, Row, Column, Icon, Button, Tooltip } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

interface IndustryCPVRowProps {
    checked: boolean;
    item: {
        children?: any[];
        codeAndName?: string;
        name: string;
        orderValue?: any;
        count: number;
    };
    onSelect: (item: any, checked: boolean) => void;
    isExpanded?: boolean;
    half?: boolean;
    toggleExpanded?: (item: any) => void;
    level?: number;
}

const IndustryCPVRow: React.FC<IndustryCPVRowProps> = ({
    half = false,
    checked,
    isExpanded,
    item,
    onSelect,
    toggleExpanded,
    level = 0
}) => {
    const $filter = getAngularModule('$filter');
    const t = getAngularModule('$translate');

    const lang = {
        dollarSignTooltip: t('soliditet.dollarSignTooltip')
    };

    const numberFormat = $filter('numberFormat');

    const formatNumberValue = (value: number) => numberFormat(value);

    const classes = new Bem('IndustryCPVRow');

    return (
        <Row
            className={classes.mod({ 
                child: level > 0,
                [`level-${level}`]: true
            }).b()}
            align="center"
            onClick={(e) => {
                if (toggleExpanded) {
                    e.preventDefault();
                    toggleExpanded(item);
                }
            }}
        >
            <Column fixedWidth={30}>
                <Checkbox
                    key={(half || checked).toString() + half.toString()}
                    size="xs"
                    onClick={e => {
                        e.stopPropagation();
                        onSelect(item, checked); 
                    }}
                    checked={half || checked}
                    half={half}
                />
            </Column>
            <Column className="text-ellipsis">
                <Text className="Text--Inline">{item.codeAndName || item.name}</Text>
                {item.orderValue ? (
                    <Tooltip title={lang.dollarSignTooltip}>
                        <Icon name="dollar-circle" color="bright-green" space="mls" />
                    </Tooltip>
                ) : null}
            </Column>
            <Column fixedWidth={100} align="right">
                <Text>{formatNumberValue(item.count)}</Text>
            </Column>
            <Column align="right" fixedWidth={35}>
                {item.children && item.children.length > 0 ? (
                    <Button 
                        size="sm" 
                        type="link" 
                        color="grey" 
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleExpanded?.(item);
                        }}
                    >
                        <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} />
                    </Button>
                ) : null}
            </Column>
        </Row>
    );
};

export default IndustryCPVRow;