import React, { useMemo, useState, useEffect } from 'react';
import ProjectPlanResource from 'Resources/ProjectPlan';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import ProjectPlanModel from 'App/resources/Model/ProjectPlan';
import logError from 'App/babel/helpers/logError';
import Client from 'App/resources/Model/Client';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import t from 'Components/Helpers/translate';
import { Table, TableColumn, TableHeader, TableRow, Block, Text, Paginator } from '@upsales/components';
import SubaccountsColumn from 'Components/Account/AccountListContacts/Columns/SubaccountsColumn';
import BemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import { openDrawer } from 'Services/Drawer';

import './ProjectPlan.scss';
import { useSoftDeployAccess } from 'App/components/hooks';
import { useProjectPlanStages } from 'App/components/hooks/appHooks';

type Props = {
	client: Client;
	subAccountIds?: number[];
};

type TableProps = {
	client: Client;
	projectPlanStage: {
		ids: number[];
		name: string;
	};
	subAccountIds?: number[];
};

const LIMIT = 5;

const ProjectPlanTable = ({ projectPlanStage, client, subAccountIds }: TableProps) => {
	const [projectPlans, setProjectPlans] = useState<ProjectPlanModel[]>([]);
	const [offset, setOffset] = useState(0);
	const [total, setTotal] = useState(0);
	const promises = React.useRef<CancelablePromise | null>(null);
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const isOperationalAccount = subAccountIds && subAccountIds.length > 0;

	const lang = useMemo(
		() => ({
			name: t('column.name'),
			startDate: t('default.startDate'),
			endDate: t('default.endDate'),
			projectManager: t('projectPlan.projectManager'),
			noProjects: t('account.projectPlan.noProjects'),
			subaccounts: t('account.subaccount'),
			stage: t('default.stage')
		}),
		[]
	);

	const columns = [
		{ title: lang.name },
		{ title: lang.startDate },
		{ title: lang.endDate },
		{ title: lang.projectManager },
		{ title: lang.stage }
	];

	if (hasSubAccounts && isOperationalAccount) {
		columns.splice(1, 0, { title: lang.subaccounts });
	}

	const fetchProjects = () => {
		const rb = new RequestBuilder();
		rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, [client.id, ...(subAccountIds || [])]);
		rb.addFilter({ field: 'projectPlanStage.id' }, comparisonTypes.Equals, projectPlanStage.ids);
		rb.addSort('endDate', true);
		rb.offset = offset;
		rb.limit = LIMIT;
		const cancableFindProjectPlan = makeCancelable(ProjectPlanResource.find(rb.build()));

		cancableFindProjectPlan.promise
			.then(({ data, metadata }) => {
				setProjectPlans(data);
				setTotal(metadata.total);
			})
			.catch(error => {
				logError(error, 'Could not fetch project plan:');
			});

		return cancableFindProjectPlan;
	};

	useEffect(() => {
		promises.current?.cancel();
		promises.current = fetchProjects();
		return () => promises.current?.cancel();
	}, [offset]);

	useEffect(() => {
		const isAccountOrSubAccount = (clientEntity: Client | null) =>
			clientEntity &&
			(clientEntity.id === client.id ||
				(hasSubAccounts && subAccountIds && subAccountIds.includes(clientEntity.id)));

		const projectPlanUpdateListener = Tools.$rootScope.$on('projectPlan.updated', (event, data) => {
			const projectPlan = projectPlans.find(projectPlan => projectPlan.id === data.id);

			if (projectPlanStage.ids.includes(data.projectPlanStage.id) || projectPlan) {
				promises.current?.cancel();
				promises.current = fetchProjects();
			}
		});

		const comapnyMergeListener = Tools.$rootScope.$on('account.merged', function (event, data) {
			if (data.merged.id === client.id) {
				promises.current?.cancel();
				promises.current = fetchProjects();
			}
		});

		const addedProjectPlanListener = Tools.$rootScope.$on('projectPlan.added', (event, data) => {
			if (isAccountOrSubAccount(data.client)) {
				promises.current?.cancel();
				promises.current = fetchProjects();
			}
		});

		const deletedProjectPlanListener = Tools.$rootScope.$on('projectPlan.deleted', (event, data) => {
			setProjectPlans(projectPlans.filter(projectPlan => projectPlan.id !== data.id));
		});

		return () =>
			[
				projectPlanUpdateListener,
				comapnyMergeListener,
				addedProjectPlanListener,
				deletedProjectPlanListener
			].forEach(unsubscribe => unsubscribe());
	}, [projectPlans]);

	return (
		<Block>
			<Text space="mll ptxl" size="xl">
				{projectPlanStage.name}
			</Text>
			<Table>
				<TableHeader columns={columns}></TableHeader>
				{projectPlans.length > 0 ? (
					projectPlans.map(projectPlan => (
						<TableRow
							key={projectPlan.id}
							onClick={() =>
								openDrawer('EditProjectPlan', {
									projectPlanId: projectPlan.id
								})
							}
						>
							<TableColumn size="lg">{projectPlan.name}</TableColumn>
							{hasSubAccounts && isOperationalAccount ? (
								<TableColumn>
									{projectPlan.client.id !== client.id ? (
										<SubaccountsColumn client={projectPlan.client} />
									) : null}
								</TableColumn>
							) : null}
							<TableColumn>
								{projectPlan.startDate ? moment(projectPlan.startDate).format('L') : ''}
							</TableColumn>
							<TableColumn>
								{projectPlan.endDate ? moment(projectPlan.endDate).format('L') : ''}
							</TableColumn>
							<TableColumn>{projectPlan.user?.name ? projectPlan.user.name : ''}</TableColumn>
							<TableColumn>{projectPlan.projectPlanStage.name}</TableColumn>
						</TableRow>
					))
				) : (
					<TableRow>
						<TableColumn colSpan={5} size="lg" align="center">
							<Text space="mtxl mbxl" size="lg" color="grey-10">
								{' '}
								{lang.noProjects}
							</Text>
						</TableColumn>
					</TableRow>
				)}
				{total > LIMIT ? (
					<TableRow>
						<TableColumn colSpan={4} size="lg" align="center">
							<Paginator
								onChange={val => {
									setOffset(val);
								}}
								total={total}
								limit={LIMIT}
								offset={offset}
								align="center"
							/>
						</TableColumn>
					</TableRow>
				) : null}
			</Table>
		</Block>
	);
};

const ProjectPlan = ({ client, subAccountIds }: Props) => {
	const classes = new BemClass('ClientCardContent__ProjectPlan');

	const projectStages = useProjectPlanStages();
	const projectStageOptions = {
		open: {
			ids: projectStages.filter(stage => stage.category !== 'DONE').map(({ id }) => id),
			name: t('default.open')
		},
		done: {
			ids: projectStages.filter(stage => stage.category === 'DONE').map(({ id }) => id),
			name: t('projectPlan.done')
		}
	};

	return (
		<Block className={classes.b()}>
			<ProjectPlanTable
				projectPlanStage={projectStageOptions.open}
				client={client}
				subAccountIds={subAccountIds}
			/>
			<ProjectPlanTable
				projectPlanStage={projectStageOptions.done}
				client={client}
				subAccountIds={subAccountIds}
			/>
		</Block>
	);
};

export default ProjectPlan;
