import { Loader, ClickableItem, Flex } from '@upsales/components';
import ClientIndustryResource from 'Resources/ClientIndustry';
import React, { useState, useEffect, useRef } from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import { makeCancelable } from 'Helpers/promise';
import Client from 'App/resources/Model/Client';
import T from 'Components/Helpers/translate';
import { openDrawer } from 'Services/Drawer';
import logError from 'Helpers/logError';
import './ClientIndustry.scss';

interface IndustryData {
	code: string;
	description: string;
}

interface CpvResponse {
	data: { cpvCode: string; description: string } | null;
}

type Props = {
	client: Client;
	bold?: boolean;
	renderTitle?: boolean;
	variant?: 'account' | 'editOrder' | 'editPhonecall';
};

const ClientIndustry = ({ client, bold = true, renderTitle = false, variant = 'account' }: Props) => {
	const classes = new Bem('ClientIndustry');
	const useSni = Tools.FeatureHelper.hasSoftDeployAccess('AI_CLIENT_INDUSTRY_SNI');
	const hasNewClientCard = Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD');
	const [industryData, setIndustryData] = useState<IndustryData | null>(null);
	const [loading, setLoading] = useState(false);
	const getSummaryPromise = useRef<any>(null);

	const getSpacing = () => {
		switch (variant) {
			case 'editPhonecall':
			case 'editOrder':
				return 'mts mbs';
			case 'account':
			default:
				return hasNewClientCard ? 'mtm mbs' : 'mtn mbs';
		}
	};

	const fetchIndustryData = () => {
		if (useSni && client.sniCode) {
			setIndustryData({
				code: client.sniCode,
				description: T(`default.sniCode.${client.sniCode}`) || ''
			});
			return;
		}
		setIndustryData(null);
		if (!client.prospectingId) return;

		setLoading(true);
		getSummaryPromise.current = makeCancelable(ClientIndustryResource.get(client.prospectingId));

		getSummaryPromise.current.promise
			.then((response: CpvResponse) => {
				if (response.data) {
					setIndustryData({
						code: response.data.cpvCode,
						description: response.data.description
					});
				}
			})
			.catch((err: Error) => logError(err, 'Failed to fetch account summary'))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		fetchIndustryData();

		return () => {
			getSummaryPromise.current?.cancel();
		};
	}, [client.prospectingId, client.sniCode, useSni]);

	if (loading) return <Loader size="sm" />;

	return (
		<>
			{industryData ? (
				<Flex direction="column" space={getSpacing()}>
					<ClickableItem
						bold={variant === 'account' && bold}
						onClick={() =>
							openDrawer('IndustryInsightsDrawer', {
								code: industryData.code,
								description: industryData.description,
								client: client
							})
						}
						title={renderTitle ? T('default.companyBranch.cpv') : undefined}
						titleTooltip={T(`default.companyBranch.tooltip`)}
						subtitle={useSni ? T(`default.sniCode.${industryData.code}`) : industryData.description}
						textSize="sm"
						subtitleSize="sm"
						color="black"
						className={classes.elem('item').b()}
					/>
				</Flex>
			) : null}
		</>
	);
};

export default ClientIndustry;
