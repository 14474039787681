import React, { useCallback, useState } from 'react';
import { DisplayType } from 'App/babel/attributes/Attribute';
import type Contact from 'App/resources/Model/Contact';
import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import { TableColumn, TableRow, Toggle, Tooltip } from '@upsales/components';
import { ListViewTableProvided } from 'App/components/ListView/ListViewTable/ListViewTable';
import ActionColumn, { Action } from '../../CompanyGroupCard/ActionColumn';
import ListView from 'App/components/ListView';
import ContactAttributes from 'App/babel/attributes/Contact';
import contactFilters from 'App/babel/filterConfigs/Contact';
const ContactFilters = contactFilters();
import ContactResource from 'App/resources/Contact';
import { useTranslation } from 'Components/Helpers/translate';
import RequestBuilder, { comparisonTypes as ComparisonTypes } from 'Resources/RequestBuilder';
import BemClass from '@upsales/components/Utils/bemClass';
import _ from 'lodash';

import './ContactList.scss';

type Props = {
	columns?: string[];
	clientIds: number[];
	hideHeader?: boolean;
	showInactive?: boolean;
	getDataFilter: (rb: RequestBuilder, clientIds: number[]) => void;
	setShowInactive?: (showInactive: boolean) => void;
};

const ContactList = (props: Props) => {
	const {
		clientIds,
		hideHeader,
		showInactive,
		setShowInactive,
		getDataFilter,
		columns = ['name', 'title', 'operationalAccount', 'history', 'email', 'phone', 'cellPhone', '']
	} = props;

	const { t } = useTranslation();
	const classes = new BemClass('ContactList');
	const [initialSorting, setInitialSorting] = useState([{ attribute: 'name', ascending: true }]);

	const renderTableRow = (contact: Contact, { columns, attributes }: ListViewTableProvided<Contact>) => {
		const goTo = (contact: Contact) => {
			Tools.$state.go('contact.dashboard', { id: contact.id });
		};

		const columnElements = columns.map(column => {
			let content = null;
			switch (column) {
				case '': {
					content = <ActionColumn object={contact} entity="contact" actions={[Action.Edit, Action.Delete]} />;
					break;
				}

				default: {
					return (
						<ListViewDefaultColumn<Contact>
							key={column}
							item={contact}
							attributes={attributes}
							column={column}
						/>
					);
				}
			}
			return <TableColumn key={column + contact.id}>{content}</TableColumn>;
		});

		return (
			<TableRow inactive={!contact.active} key={contact.id} onClick={() => goTo(contact)}>
				{columnElements}
			</TableRow>
		);
	};

	const getData = useCallback(
		async (rb: RequestBuilder) => {
			if (clientIds.length === 0) {
				return {
					data: [],
					metadata: { total: 0, limit: 50, offset: 0 }
				};
			}

			if (!showInactive) {
				rb.addFilter({ field: 'active' }, ComparisonTypes.Equals, true);
			}

			getDataFilter(rb, clientIds);

			return ContactResource.find(rb.build());
		},
		[showInactive, clientIds]
	);

	const modifiedContactAttributes = _.cloneDeep(ContactAttributes);
	modifiedContactAttributes.email.displayType = DisplayType.EllipsisText;
	modifiedContactAttributes.name.displayType = DisplayType.ContactTitle;
	modifiedContactAttributes.title.displayType = DisplayType.EllipsisText;

	return (
		<ListView<Contact>
			key={initialSorting.map(sort => `${sort.ascending}`).join('-')}
			filterConfigs={ContactFilters}
			hideFilters
			className={classes.b()}
			broadcastType="contact"
			getData={getData}
			attributes={modifiedContactAttributes}
			renderTableRow={renderTableRow}
			columns={columns}
			skipSortById
			renderHeader={hideHeader ? () => null : undefined}
			quickSearchFilter={setShowInactive ? 'ListSearch' : undefined}
			quickSearchPlaceholder={t('agreement.moveSubscriptionContactSelectPlaceholder')}
			renderToolsColumn={false}
			canSortCustomFields={false}
			initialSorting={initialSorting}
			onSortChange={({ field }) => {
				if (field === 'client.operationalAccount.name') {
					setInitialSorting(prev =>
						prev.map(sort => ({
							...sort,
							ascending: !sort.ascending,
							missing: sort.ascending ? 'first' : 'last'
						}))
					);
				}
			}}
			renderHeaderLastExtra={provided => {
				if (!setShowInactive) {
					return null;
				}

				return (
					<Tooltip title={t('default.showInactiveContacts')} disabled={showInactive}>
						<Toggle
							checked={showInactive}
							onChange={val => {
								setShowInactive?.(val);
								provided.onFilterChange({}, { silent: true });
							}}
							icon="user-slash"
						/>
					</Tooltip>
				);
			}}
		/>
	);
};

export default ContactList;
