import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Text, Button, Toggle, FloatingLabel, Input, Row, Column, Label } from '@upsales/components';
import FloatingSaveButton from 'Components/FloatingSaveButton';
import Bem from '@upsales/components/Utils/bemClass';
import {
	init,
	updateSalesboard,
	addNewColumn,
	saveSalesboard,
	sortColumns
} from 'Store/reducers/AdminEditSalesboardReducer';
import SalesboardColumn from './SalesboardColumn';
import Breadcrumbs, { BreadcrumbItem } from 'App/upsales/common/components/react/Breadcrumbs';

import './EditSalesboard.scss';
import { reactRouteCompatibility } from 'App/helpers/angularRouteHelpers';
import BemClass from '@upsales/components/Utils/bemClass';

const mapStateToProps = state => ({ ...state.AdminEditSalesboard });
const mapDispatchToProps = { init, updateSalesboard, addNewColumn, saveSalesboard, sortColumns };

class EditSalesboard extends React.Component {
	constructor(props) {
		super(props);

		const { id, init } = this.props;
		const t = Tools.$translate;
		this.selectableRoles = Tools.AppService.getRoles();
		this.selectableTypes = [
			{ id: 'order', name: t('default.orders') },
			{ id: 'opportunity', name: t('default.opportunities') },
			{ id: 'appointment', name: t('default.appointments') }
		];

		this.lang = {
			whatWouldYouLikeToSee: t('admin.salesboard.whatWouldYouLikeToSee'),
			whatWouldYouLikeToSeeDesc: t('admin.salesboard.whatWouldYouLikeToSeeDesc'),
			roles: t('default.roles'),
			default: t('default.default'),
			salesboards: t('default.salesboards'),
			editSalesboard: t('admin.editSalesboard'),
			newSalesboard: t('admin.newSalesboard'),
			name: t('default.name'),
			createNew: t('admin.salesboard.createNew'),
			selectColumnType: t('default.chooseAColumnType'),
			save: t('default.save'),
			newColumn: t('admin.salesboard.newColumn')
		};

		this.createNewSalesboard = this.createNewSalesboard.bind(this);
		this.onNameInputBlur = this.onNameInputBlur.bind(this);

		this.state = { nameFieldTouched: false };
		this.onDragEnd = this.onDragEnd.bind(this);

		init(id);
	}

	createNewSalesboard() {
		Tools.$state.go('administration.salesboard', { id: null });
	}

	onNameInputBlur() {
		this.setState({ nameFieldTouched: true });
	}

	isDirty() {
		const { salesboard, referenceSalesboard } = this.props;
		const compareSalesboard = { ...salesboard, columns: salesboard.columns.filter(column => !column.isNew) };
		return !_.isEqual(compareSalesboard, referenceSalesboard) && salesboard.title && salesboard.columns.length
			? true
			: false;
	}

	onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		this.props.sortColumns(result.source.index, result.destination.index);
	}

	render() {
		const { salesboard, saving, updateSalesboard, addNewColumn, saveSalesboard, openColumns } = this.props;

		const nameFieldTouched = this.state.nameFieldTouched;
		const lang = this.lang;
		const MainClass = new Bem('EditSalesboard');

		const columns = salesboard.columns.reduce((res, column, index) => {
			if (column.type !== 'report') {
				res.push(
					<Draggable key={column.$id} draggableId={column.$id.toString()} index={index}>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								style={provided.draggableProps.style}
							>
								<SalesboardColumn isDragging={snapshot.isDragging} column={column} />
							</div>
						)}
					</Draggable>
				);
			}
			return res;
		}, []);

		const saveDisabled = Object.keys(openColumns).length > 0;

		return (
			<div id="admin-root" className={MainClass.b()} key={salesboard.id}>
				<div className="admin-page-header">
					{salesboard && salesboard.id ? (
						<Button
							type="link"
							className={MainClass.elem('createNewButton').b()}
							onClick={this.createNewSalesboard}
						>
							{lang.createNew}
						</Button>
					) : null}
					<Breadcrumbs key="bread">
						<BreadcrumbItem
							state="administration.listSalesboards"
							params={{ customerId: Tools.AppService.getCustomerId() }}
							text={lang.salesboards}
						/>
						<BreadcrumbItem text={salesboard && salesboard.id ? lang.editSalesboard : lang.newSalesboard} />
					</Breadcrumbs>
					<div style={{ maxWidth: 900 }}>
						<Row>
							<Column size={6}>
								<Label maxLength={50} value={salesboard.title} />
								<FloatingLabel text={lang.name} required={true}>
									<Input
										maxLength={50}
										state={nameFieldTouched && !salesboard.title ? 'error' : undefined}
										autoFocus
										size="md"
										inline={true}
										value={salesboard.title}
										onChange={event => updateSalesboard({ title: event.target.value })}
										onBlur={this.onNameInputBlur}
									/>
								</FloatingLabel>
							</Column>
							<Column size={1}>
								<Label>{lang.default}</Label>
								<Toggle
									color="bright-blue"
									size="lg"
									checked={salesboard.default}
									onChange={value => updateSalesboard({ default: value })}
								/>
							</Column>
							<Column size={5} />
						</Row>
					</div>
				</div>
				<div id="admin-content">
					<Row>
						<DragDropContext onDragEnd={this.onDragEnd}>
							<Droppable droppableId="droppable" direction="horizontal">
								{provided => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
										{...provided.droppablePlaceholder}
										className={MainClass.elem('dragContainer').b()}
									>
										{columns}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
						{columns.length < 10 ? (
							<Column key={columns.length}>
								<div className={MainClass.elem('typeSelect-wrap').b()}>
									<Text bold={true}>{lang.newColumn}</Text>
									<ReactTemplates.INPUTS.upSelect
										onChange={event => setTimeout(() => addNewColumn(event.target.value), 0)}
										options={{
											placeholder: lang.selectColumnType,
											minimumResultsForSearch: -1,
											allowClear: false
										}}
										className={MainClass.elem('typeSelect').b()}
										data={this.selectableTypes}
									/>
								</div>
							</Column>
						) : null}
						{columns.length ? null : (
							<Column className={MainClass.elem('help-text').b()}>
								<Text style={{ marginBottom: 10 }} size="xl" bold={true}>
									{lang.whatWouldYouLikeToSee}
								</Text>
								<Text style={{ whiteSpace: 'pre' }} size="lg" color="grey-10">
									{lang.whatWouldYouLikeToSeeDesc}
								</Text>
							</Column>
						)}
						<Column fixedWidth={20} />
					</Row>
				</div>
				<FloatingSaveButton
					visible={this.isDirty()}
					loading={saving}
					onClick={saveSalesboard}
					disabled={saveDisabled}
					text={lang.save}
				/>
			</div>
		);
	}
}

EditSalesboard.propTypes = {
	salesboard: PropTypes.object,
	referenceSalesboard: PropTypes.object,
	init: PropTypes.func,
	sortColumns: PropTypes.func,
	updateSalesboard: PropTypes.func,
	addNewColumn: PropTypes.func,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	saving: PropTypes.bool,
	saveSalesboard: PropTypes.func,
	$scope: PropTypes.object,
	openColumns: PropTypes.object
};

export const detached = EditSalesboard;
const Component = connect(mapStateToProps, mapDispatchToProps)(EditSalesboard);

const className = new BemClass('WrappedPortedAdminEditSalesboard');

const WrappedEditSalesboard = props => {
	return (
		<div id="admin" className={className.b()}>
			<div id="admin-root">
				<Component {...props} />
			</div>
		</div>
	);
};

export const PortedAdminEditSalesboard = reactRouteCompatibility(WrappedEditSalesboard, {
	featureFlag: 'REACT_ADMIN_SALESBOARD',
	wrapInPage: false,
	onMount: ({ params }) => {
		return { id: params.id };
	}
});

export default Component;

window.AdminEditSalesboard = Component;
