import FieldTranslations from 'Resources/FieldTranslations';
import logError from 'App/babel/helpers/logError';
import { openEditCampaignModal } from 'App/components/EditCampaign/EditCampaign';

angular.module('domain.admin').controller('UpdateContactAction', [
	'$scope',
	'$modalParams',
	'$upModal',
	'$translate',
	'$q',
	'AppService',
	'ActionProperties',
	'TagsService',
	'RequestBuilder',
	'CampaignAttributes',
	'Campaign',
	function (
		$scope,
		$modalParams,
		$upModal,
		$translate,
		$q,
		AppService,
		ActionProperties,
		TagsService,
		RequestBuilder,
		CampaignAttributes,
		Campaign
	) {
		var arrayProperties = ['AddProject', 'RemoveProject', 'AddCategories', 'RemoveCategories', 'AddSegment'];

		$scope.tagsVisible = false;
		$scope.modalParams = $modalParams;
		$scope.isTrigger = $modalParams.isTrigger;
		$scope.customFields = [];

		$scope.toggleAvailableTags = function () {
			$scope.tagsVisible = !$scope.tagsVisible;
		};

		$scope.modalTagListProps = {
			modalParams: $modalParams,
			onClose: function () {
				$scope.tagsVisible = false;
				$scope.$apply();
			}
		};

		$scope.save = function () {
			$scope.saving = true;

			// map properties
			var properties = [];

			// map custom
			ActionProperties.mapCustomFields(properties, $scope.customFields, 'Contact');

			ActionProperties.map(properties, $scope.properties, arrayProperties);

			const journeyStepProperty = _.find(properties, { name: 'JourneyStep' });
			if (journeyStepProperty) {
				properties.push({ name: 'ForceInvalidPropertyChange', value: true });
			}

			const parsedProps = properties.map(property => {
				if (property.name === 'TitleCategory') {
					return { name: 'TitleCategory', value: property.value.tagId };
				}
				return property;
			});

			$scope.resolve(parsedProps);
		};

		$scope.addField = function (field) {
			_.remove($scope.availableFields, { id: field.id });
			$scope.customFields.push(field);
			$scope.fieldToAdd = null;
		};

		$scope.removeField = function (field) {
			_.remove($scope.customFields, { id: field.id });
			$scope.availableFields.push(field);
			$scope.reloadModalPosition();
		};

		$scope.createCampaign = function () {
			openEditCampaignModal({ customerId: AppService.getCustomerId(), noRedirect: true })
				.then(function (campaign) {
					$scope.campaigns.push(campaign);
					if ($scope.properties.AddProject && Array.isArray($scope.properties.AddProject)) {
						$scope.properties.AddProject.push(campaign.id);
					} else {
						$scope.properties.AddProject = [campaign.id];
					}
				})
				.catch(error => {
					console.error(error);
				});
		};

		$scope.isSaveDisabled = function () {
			var hasProperty = _.reduce(
				$scope.properties,
				function (res, property) {
					if (res) {
						return res;
					} else if (Array.isArray(property)) {
						return !!property.length;
					} else if (typeof property === 'number') {
						return true;
					} else {
						return !!property;
					}
				},
				false
			);

			return (
				$scope.saving ||
				($scope.myForm.$invalid && $scope.myForm.$dirty) ||
				(!hasProperty && !$scope.customFields.length)
			);
		};

		$scope.showErrorMessage = function (form) {
			for (var key in form.$error) {
				if (key !== 'required') {
					return true;
				} else {
					var requiredArr = form.$error.required;
					for (var itr = 0; itr < requiredArr.length; ++itr) {
						var obj = requiredArr[itr];
						if (!obj.$pristine) {
							return true;
						}
					}
				}
			}
			return false;
		};

		$scope.changeCustomDate = function (field) {
			if (field.value && field.value.tag === 'RegularDate') {
				field.dates = undefined;
			}
		};

		var loadData = function () {
			var campaignFilter = new RequestBuilder();
			var campaignAttr = CampaignAttributes().attr;
			campaignFilter
				.addSort(campaignAttr.name, true)
				.addFilter(campaignAttr.active, campaignFilter.comparisonTypes.Equals, 1);

			var promises = {
				contactCategories: $q.when({ data: AppService.getCategories('contact') }),
				contactCustomFields: $q.when({ data: AppService.getCustomFields('contact') }),
				campaigns: Campaign.customer(AppService.getCustomerId()).find(campaignFilter.build())
			};

			return $q.all(promises);
		};

		var init = function (res) {
			$scope.isEdit = !!$modalParams.properties;
			$scope.removeAction = $modalParams.removeAction;
			$scope.actionIndex = $modalParams.actionIndex;
			$scope.showJourneyStep = $modalParams.tagEntity !== 'order' && $modalParams.tagEntity !== 'agreement';

			$scope.hasJourney = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS);
			if ($scope.hasJourney) {
				$scope.journeySteps = Tools.AppService.getJourneySteps();
			}

			$scope.showTitleCategory =
				Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') &&
				AppService.getMetadata().standardFields.Contact.TitleCategory?.active;

			$scope.availableFields = angular.copy(res.contactCustomFields.data);
			if (!$scope.isTrigger) {
				$scope.availableFields = _.filter($scope.availableFields, 'editable');
			}
			$scope.customFieldLength = $scope.availableFields.length;
			$scope.market = Tools.FeatureHelper.isAvailableProduct(Tools.FeatureHelper.Product.MA);

			// parse properties
			$scope.properties = {};

			ActionProperties.parse($scope.properties, $modalParams.properties, arrayProperties);

			if ($scope.properties.NewMarketScore && !isNaN(parseInt($scope.properties.NewMarketScore))) {
				$scope.properties.NewMarketScore = parseInt($scope.properties.NewMarketScore);
			}

			// Date
			var dateTags = [];

			if ($scope.properties.TitleCategory) {
				FieldTranslations.find({ type: 'titlecategory', tagId: $scope.properties.TitleCategory })
					.then(res => {
						$scope.properties.TitleCategory = res.data[0];
					})
					.catch(err => {
						logError(err, 'Failed to fetch category titles');
					});
			}

			var customDateTypes = TagsService.getTagsByEntity($modalParams.entity || $modalParams.tagEntity).reduce(
				function (result, value) {
					if (value.type !== 'Date') {
						return result;
					}

					if (!result[value.entity]) {
						result[value.entity] = {
							name: $translate.instant('default.' + value.entity),
							children: []
						};
					}

					result[value.entity].children.push(value);
					dateTags.push(value);
					return result;
				},
				{}
			);

			if (customDateTypes.general && customDateTypes.general.children) {
				var clearField = { name: $translate.instant('tag.general.clearfield'), tag: 'General.ClearField' };
				customDateTypes.general.children.push(clearField);
				customDateTypes.general.children.push({
					name: $translate.instant('tag.special.regularDate'),
					tag: 'RegularDate'
				});
				dateTags.push(clearField);
			}

			$scope.availableFields = _.map($scope.availableFields, function (field) {
				if (customDateTypes && field.datatype === 'Date') {
					field.dates = Object.values(customDateTypes);
				}
				return field;
			});

			$scope.customDateTypes = {
				data: Object.values(customDateTypes),
				formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
				formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				id: _.property('tag')
			};

			// parse custom
			ActionProperties.parseCustomFields(
				$scope.availableFields,
				$scope.customFields,
				$scope.properties,
				'Contact',
				dateTags
			);

			$scope.campaignSelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.campaign').toLowerCase()
			};
			$scope.campaigns = res.campaigns.data;

			$scope.categorySelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.category').toLowerCase()
			};
			$scope.categories = res.contactCategories.data;

			$scope.hideRemoveFromSegment = true;
		};

		AppService.loadedPromise
			.then(loadData)
			.then(init)
			.catch(error => console.error(error));
	}
]);
