import { Text } from '@upsales/components';
import openNewSegmentModal from 'App/components/NewSegment/NewSegmentModal';
import UpSegment from 'Components/Inputs/upSegment';

/** @type {{body: any, root: any}} */
ReactTemplates.flow = { body: {} };

ReactTemplates.flow.root = window.ReactCreateClass({
	getStateFromProps: function () {
		return {
			cogOpen: false,
			targetElement: null
		};
	},
	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			created: t('default.created'),
			by: t('default.of').toLowerCase(),
			contacts: t('segment.contacts.title'),
			createNew: t('create_new'),
			change: t('default.change'),
			segment: t('default.segment'),
			noName: t('default.noName'),
			noSegmentConnected: t('segment.sidebar.noSegmentConnected'),
			createdBy: t('default.createdBy'),
			days: t('flow.days'),
			workDays: t('flow.workDays'),
			anyDay: t('flow.anyDay'),
			times: t('flow.times'),
			timezone: t('flow.timezone'),
			mailsAreSent: t('flow.mailsAreSent')
		};
	},
	componentDidMount: function () {
		this.props.renderCallback();
	},
	componentDidUpdate: function () {
		this.props.renderCallback();
	},
	editDropdown: function () {
		this.setState({
			cogOpen: !this.state.cogOpen
		});
	},
	render: function () {
		var self = this;
		var flow = self.props.flowComponent.flow;
		const { numberOfContacts } = self.props.flowComponent;
		var tools = self.props.tools;
		var createdBy = _.find(Tools.AppService.getUsers(), { id: flow.regBy });

		var sidebar = (
			<div id="edit-flow-sidebar">
				<div className="section">
					<div className="section-name" data-test-id="flow-name">
						{flow.name ? flow.name : <i className="grey">{self.lang.noName}</i>}
					</div>
					<div className="section-created">
						<span className="title">{self.lang.createdBy}</span>
						<div className="user-holder">
							<div className="user-image">
								<ReactTemplates.TOOLS.avatar
									size={31}
									user={createdBy}
									avatarService={tools.avatarService}
								/>
							</div>
							<div className="user-user">
								<span className="user-name">{createdBy && createdBy.name}</span>
								<span className="creation-date">{moment(flow.regDate).format('L LT')}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="section">
					<div className="section-contacts">
						<div>
							{!flow.segment ? (
								<div>
									<span className="no-segment-connected">
										{self.lang.noSegmentConnected.toUpperCase()}
									</span>
								</div>
							) : null}
							<div className="pick-segment-wrapper">
								<span className="segment-label">{self.lang.segment}</span>
								{flow.segment ? (
									<Text bold>{flow.segment.name}</Text>
								) : (
									<>
										<button
											className="btn btn-link"
											onClick={function () {
												var options = {
													customerId: Tools.AppService.getCustomerId(),
													fromFlow: true
												};

												openNewSegmentModal(options)
													.then(segment => {
														self.props.actions.setSegmentToFlow({
															id: segment.id,
															name: segment.name
														});
													})
													.catch(() => {});
											}}
										>
											{self.lang.createNew}
										</button>
										<UpSegment
											onlyActive
											onlyWithoutFlows
											onChange={segment => {
												self.props.actions.setSegmentToFlow({
													id: segment.id,
													name: segment.name
												});
											}}
											placeholder={Tools.$translate('flow.newFlow.connectToSegment')}
											formatSelection={(object, container, escape) => {
												return escape(object.name);
											}}
										/>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="section">
					<div className="section-selection">
						<span>{self.lang.mailsAreSent}</span>
					</div>

					<div className="section-mail-settings">
						<div>
							<div>
								{self.lang.days}
								<b>{flow.skipWeekends ? self.lang.workDays : self.lang.anyDay}</b>
							</div>
							{flow.startTime && flow.endTime && (
								<div>
									{self.lang.times}
									<b>
										{moment(flow.startTime, 'HH:mm').format('LT') +
											' - ' +
											moment(flow.endTime, 'HH:mm').format('LT')}
									</b>
								</div>
							)}
							{flow.timezone && (
								<div>
									{self.lang.timezone}
									<b>{flow.timezone}</b>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);

		var props = _.assign({}, self.props, {
			editDropdown: this.editDropdown,
			cogOpen: this.state.cogOpen,
			totalContacts: numberOfContacts
		});
		var content = React.createElement(ReactTemplates.segment.flowPreview, props);

		var classNames = ['card', 'has-sidebar-sm', 'full-size'];

		if (flow.paused) {
			classNames.push('inactive-flow');
		}

		return (
			<div className={classNames.join(' ')} data-test-id="flow-root">
				{sidebar}
				<div id="edit-flow-body">{content}</div>
			</div>
		);
	}
});
