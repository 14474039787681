'use strict';

angular.module('upResources').factory('MarketRejectlist', [
	'$resource',
	'ResourceHelper',
	'$q',
	'URL',
	'API',
	function ContactCategory($resource, ResourceHelper, $q, URL, API) {
		var helper = new ResourceHelper();

		var eventPrefix = 'rejectlist';

		helper.setDefaults({
			eventPrefix: eventPrefix,
			createSuccessBody: 'saved.rejectList',
			deleteSucccessBody: 'deleted.rejectList',
			createErrorBody: 'saveError.rejectList',
			deleteErrorBody: 'deleteError.rejectList'
		});

		var Resource = $resource(
			URL + API + 'marketRejectlist/:id',
			{},
			{
				delete: { method: 'DELETE' },
				create: { method: 'POST' },
				list: { method: 'GET' },
				get: { method: 'GET' }
			}
		);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			list: function () {
				return Resource.list().$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (obj, options) {
				if (!obj.id) {
					return Resource.create(obj)
						.$promise.then(function (res) {
							helper.onSave(options, res.data, !obj.id);
							return res;
						})
						.catch(function () {
							helper.onSaveError(options, !obj.id);
						});
				}
			},

			delete: function (obj, options) {
				if (!obj.id) {
					return;
				}
				return Resource['delete']({ id: obj.id })
					.$promise.then(function () {
						helper.onDelete(options, obj);
					})
					.catch(function () {
						helper.onDeleteError(options);
					});
			},
			events: {
				added: eventPrefix + '.added',
				deleted: eventPrefix + '.deleted'
			}
		};

		return Model;
	}
]);
