import { checkPortedResolve } from 'App/helpers/angularRouteHelpers';

angular.module('domain.looker', ['ngRoute', 'upResources', 'security.authorization']).config([
	'$stateProvider',
	'$upModalProvider',
	function ($stateProvider, $upModalProvider) {
		$stateProvider.state('looker', {
			templateProvider: [
				'FeatureHelper',
				'$http',
				'AppService',
				function (FeatureHelper, $http, AppService) {
					return AppService.loadedPromise.then(function () {
						return '<div></div>';
					});
				}
			],
			url: '/:customerId/insights/:type?/:id?useNext',
			controllerProvider: function () {
				return function ($scope, $element) {
					window.SetupComponent($scope, window.Insights, $element[0], {}, { redux: true });
				};
			},
			section: 'followup',
			resolve: {
				isAvailable: function () {
					return checkPortedResolve('looker', () => {
						if (Tools.AppService.getMetadata()?.params?.LookerDashboardExeptionIds) return true;
						return Tools.FeatureHelper.isAvailablePromise(Tools.FeatureHelper.Feature.LOOKER);
					});
				},
				authUser: [
					'$routeGuard',
					function ($routeGuard) {
						return checkPortedResolve('looker', () => {
							$routeGuard.requireUser();
						});
					}
				],
				segment: [
					'SegmentHelper',
					function (SegmentHelper) {
						checkPortedResolve('looker', () => {
							SegmentHelper.track('View looker', { module: SegmentHelper.module.reportView });
						});
					}
				]
			},
			params: {
				dashboards: { value: null },
				explores: { value: null },
				looks: { value: null }
			}
		});

		$upModalProvider.modal('createLookerReport', {
			template: '<div></div>',
			controller: function ($scope, $element) {
				window.SetupComponent($scope, window.CreateReportModal, $element[0], {}, { redux: true, modal: true });
			},
			style: 'form-wide default new-full-screen'
		});
	}
]);
