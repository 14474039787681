'use strict';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

angular.module('upDirectives').directive('upwNextActivity', [
	'$upModal',
	function ($upModal) {
		return {
			restrict: 'A',
			replace: true,
			scope: {
				widget: '=upwNextActivity'
			},
			controller: [
				'$scope',
				'$rootScope',
				'ActivityList',
				'RequestBuilder',
				'Account',
				'$state',
				function ($scope, $rootScope, ActivityList, RequestBuilder, Account, $state) {
					$scope.$evalAsync(function () {
						var customerId = $rootScope.$stateParams.customerId;
						var filter = new RequestBuilder();
						var eq = filter.comparisonTypes.Equals;

						$scope.editActivity = function (activity, e) {
							e.stopPropagation();
							var params = {};

							if (activity) {
								params.id = activity.id;
							} else if ($scope.widget.accountId) {
								params.activity = { client: { id: $scope.widget.accountId } };
							} else if ($scope.widget.contactId) {
								params.activity = { contacts: { id: $scope.widget.contactId } };
							}

							if (activity && activity.isAppointment) {
								if (params.activity && params.activity.contacts) {
									params.activity.contacts = [params.activity.contacts];
								}
								openEditAppointment(params);
							} else {
								$upModal.open('editActivity', params);
							}
						};

						$scope.goToList = function () {
							$state.go($scope.link, { id: $scope.widget.accountId || $scope.widget.contactId });
						};

						var orBuilder = filter.orBuilder();
						orBuilder.next();
						orBuilder.addFilter(ActivityList.attr.closeDate, eq, null);
						orBuilder.addFilter(ActivityList.attr.isAppointment, eq, false);
						orBuilder.next();
						orBuilder.addFilter(ActivityList.attr.isAppointment, eq, true);
						orBuilder.addFilter(
							ActivityList.attr.date,
							filter.comparisonTypes.GreaterThanEquals,
							new Date()
						);

						if ($scope.widget.accountId) {
							filter.addFilter(ActivityList.attr.account, eq, $scope.widget.accountId);
							$scope.link = 'account.activities';
						} else if ($scope.widget.contactId) {
							filter.addFilter(ActivityList.attr.contacts, eq, $scope.widget.contactId);
							$scope.link = 'contact.activities';
						}

						filter.limit = 1;
						filter.fields = ['id', 'description', 'date', 'activityType', 'users', 'isAppointment'];
						filter.addSort('date', true);

						var query = filter.build();
						query.q = query.q || [];
						query.q.push(orBuilder.getQuery());

						function goFetch(timeout) {
							$scope.loading = true;
							setTimeout(function () {
								ActivityList.customer(customerId)
									.find(query)
									.then(function (response) {
										$scope.activity = response.data[0];
										$scope.loading = false;
									});
							}, timeout);
						}

						$scope.$on('activity.deleted', function (e, deleted) {
							if (deleted.id === $scope.activity.id) {
								$scope.activity = null;
							}
							goFetch(2000);
						});
						$scope.$on('activity.updated', function () {
							goFetch(1000);
						});
						$scope.$on('activity.added', function () {
							goFetch(1000);
						});

						if ($scope.widget.accountId) {
							$scope.$on('account.merged', function (e, res) {
								if ($scope.widget.accountId === res.merged.id) {
									goFetch(1000);
								}
							});
						}

						goFetch(0);
					});
				}
			],
			link: function ($scope, $element) {
				// Fix scope leak
				$element.on('$destroy', function () {
					$scope.$destroy();
				});
			},
			template:
				'<div class="up-widget info linked" ng-click="goToList();">' +
				'<span class="title">{{ (widget.title || \'widget.nextActivity\') | translate }}</span>' +
				'<div class="loader" ng-if="loading" class="fade-animation out"><b class="fa fa-refresh fa-spin"></b></div>' +
				'<span ng-if="activity != undefined && !loading" class="fade-animation in">' +
				'<span class="value">' +
				'<a ng-click="editActivity(activity, $event);" ng-bind="activity.activityType.name"></a>' +
				'</span>' +
				'<div bs-tooltip data-title="{{activity.users[0].name}}" ng-if="activity.users.length" up-avatar user="activity.users[0]" size="20" round="true"></div>' +
				'<span class="subtitle" style="margin-left:20px;">' +
				'{{ activity.date | upsalesDate }}' +
				'</span>' +
				'</span>' +
				'<span ng-if="activity == undefined && !loading" class="fade-animation in">' +
				"<span class=\"value red\">{{ 'default.noSet' | translate }} {{ 'default.activity' | translate | lowercase }}</span>" +
				'<span class="subtitle"><a ng-click="editActivity(null, $event);">{{ \'default.createOne\' | translate }}</a></span>' +
				'</span>' +
				'</div>'
		};
	}
]);
