import Todo from 'App/resources/Model/Todo';
import moment from 'moment';
import RequestBuilder, { comparisonTypes as ComparisonTypes } from 'Resources/RequestBuilder';
import { getFilterFn } from 'Components/ListTab/ListTabHelper';

export const clientIdsFilter = (query: RequestBuilder, clientIds: number[]) => {
	const hasSubAccountsV2 =
		Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS') &&
		Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2');

	if (hasSubAccountsV2) {
		getFilterFn(true)(query, clientIds);
	} else {
		getFilterFn(false)(query, clientIds);
	}
};

export const openActivitiesFilter = (query: RequestBuilder) => {
	const orBuilder = query.orBuilder();
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.Equals, null);
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, true);
	orBuilder.addFilter({ field: 'outcome' }, ComparisonTypes.Equals, 'planned');
	orBuilder.addFilter({ field: 'endDate' }, ComparisonTypes.GreaterThan, moment().format());
	orBuilder.done();
};

export const overdueActivitiesFilter = (query: RequestBuilder) => {
	// Activities without a specified time needs to be compared to the start of today (00:00) to determine if they are overdue.
	// If an activity's date is today and has no time, it should not be considered overdue if the day has not passed (which happens if you don't compare it with the start of today).
	// If an activity has both a date and a time today that has already passed, it will also not be overdue until the day has passed though.
	// The filter is the same as in other parts such as ordinary task list and client card activity list, so it is consitent at least.
	const orBuilder = query.orBuilder();
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.Equals, null);
	orBuilder.addFilter({ field: 'time' }, ComparisonTypes.NotEquals, null);
	orBuilder.addFilter({ field: 'date' }, ComparisonTypes.LessThan, moment().format());
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.Equals, null);
	orBuilder.addFilter({ field: 'time' }, ComparisonTypes.Equals, null);
	orBuilder.addFilter({ field: 'date' }, ComparisonTypes.LessThan, moment().format('YYYY-MM-DD'));
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, true);
	orBuilder.addFilter({ field: 'outcome' }, ComparisonTypes.Equals, 'planned');
	orBuilder.addFilter({ field: 'endDate' }, ComparisonTypes.LessThan, moment().format());
	orBuilder.done();
};

export const closedActivitiesFilter = (query: RequestBuilder) => {
	const orBuilder = query.orBuilder();
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.NotEquals, null);
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, true);
	orBuilder.addFilter({ field: 'outcome' }, ComparisonTypes.NotEquals, 'planned');
	orBuilder.done();
};

export const fetchNumbersForWidget = (filter: string, clientIds: number[]) => {
	const rb = new RequestBuilder();

	clientIdsFilter(rb, clientIds);
	rb.addFilter({ field: 'projectPlan.id' }, ComparisonTypes.Equals, null);
	if (filter === 'open') {
		openActivitiesFilter(rb);
	}
	if (filter === 'overdue') {
		overdueActivitiesFilter(rb);
	}
	if (filter === 'closed') {
		closedActivitiesFilter(rb);
	}
	rb.limit = 0;
	return rb;
};

const isOverdue = (todo: Todo) => {
	const date = todo.date;
	const startOfToday = moment().startOf('day').format();

	if (todo.type === 'appointment') {
		return moment(date).isBefore(startOfToday) && todo.outcome !== 'completed' && todo.outcome !== 'notCompleted';
	}

	return moment(date).isBefore(startOfToday);
};

export const getDateFlags = (item: Todo) => {
	const hasDate = !!item.date;
	const overdue = hasDate && isOverdue(item);
	const closed =
		!!item.closeDate ||
		(item.type === 'appointment' && (item.outcome === 'completed' || item.outcome === 'notCompleted'));
	return { overdue, closed };
};
