import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FullScreenPage from 'App/pages/FullScreenPage';
import ImportColumn from './ImportColumn';
import ImportColumnShape from '../../propTypes/ImportColumnShape';
import Select from 'react-select';
import {
	Row,
	Column,
	Button,
	Icon,
	Progressbar,
	Text,
	DropDownMenu,
	Tooltip,
	Toggle,
	Flex,
	ButtonBox
} from '@upsales/components';
import UpSelect from 'Components/Inputs/UpSelect';
import { getProductCategoryTree } from 'App/babel/helpers/product';
import { openEditCampaignModal } from 'App/components/EditCampaign/EditCampaign';

const emailRegex =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const tagPropShape = PropTypes.shape({
	description: PropTypes.string,
	import: PropTypes.bool,
	name: PropTypes.string,
	parent: PropTypes.string,
	tag: PropTypes.string,
	type: PropTypes.string
});
const customFieldShape = PropTypes.shape({
	id: PropTypes.number,
	name: PropTypes.string
});

window.ImportPage = class extends Component {
	static propTypes = {
		tools: PropTypes.object,
		rootData: PropTypes.shape({
			changeMatch: PropTypes.func.isRequired,
			importChange: PropTypes.func.isRequired,
			changeBisnode: PropTypes.func.isRequired,
			setOnlyUpdate: PropTypes.func.isRequired,
			setImportType: PropTypes.func.isRequired,
			setFieldAction: PropTypes.func.isRequired,
			pageData: PropTypes.shape({
				obj: PropTypes.shape({
					fields: PropTypes.arrayOf(
						PropTypes.shape({
							field: PropTypes.string,
							action: PropTypes.string,
							value: PropTypes.arrayOf(PropTypes.object)
						})
					),
					columns: PropTypes.arrayOf(ImportColumnShape),
					type: PropTypes.string,
					preview: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
				}),
				customFields: PropTypes.shape({
					client: PropTypes.shape(customFieldShape),
					contact: PropTypes.shape(customFieldShape)
				}),
				teamAccountManager: PropTypes.bool,
				columnErrors: PropTypes.shape({
					hasContactMatchButNoClientMatch: PropTypes.bool,
					missingRequiredMatches: PropTypes.arrayOf(PropTypes.string)
				}),
				tags: PropTypes.shape({
					client: PropTypes.arrayOf(tagPropShape),
					contact: PropTypes.arrayOf(tagPropShape),
					product: PropTypes.arrayOf(tagPropShape)
				}),
				selectedUpdateOption: PropTypes.bool
			})
		})
	};

	state = {
		page: [
			{ name: 'type', active: true },
			{ name: 'match', active: false },
			{ name: 'settings', active: false },
			{ name: 'review', active: false }
		],
		currentPage: 'type',
		nextPage: {
			text: 'match',
			step: 1
		},
		showUpload: false,
		expandedIssues: {
			missingValues: false,
			missingMatches: false,
			wrongDataTypes: false
		},
		active: false,
		productCategories: []
	};

	componentDidMount() {
		const productCategories = getProductCategoryTree();
		this.setState({ productCategories });
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillMount() {
		var t = Tools.$translate;
		var hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);

		this.lang = {
			connectToUpsalesField: t('import.connectToUpsalesField'),
			titles: {
				type: t('import.titles.type'),
				match: t('import.titles.match'),
				settings: t('import.titles.settings'),
				review: t('import.titles.review')
			},
			importTypes: {
				CLIENT_AND_CONTACT: t('import.type.CLIENT_AND_CONTACT'),
				PRODUCT: t('import.type.PRODUCT')
			},
			templateTypes: {
				CLIENT_AND_CONTACT: t('import.template.type.CLIENT_AND_CONTACT'),
				PRODUCT: t('import.template.type.PRODUCT')
			},
			updateSettingTitle: t('import.updateSetting.title'),
			updateSettingSubtitle: t('import.updateSetting.subtitle'),
			download: t('default.download'),
			matchDescription: t('import.desc.match'),
			column: t('mail.column').toLowerCase(),
			columns: t('filters.columns.phoneCall'),
			remove: t('default.remove'),
			allColumnsDone: t('import.allColumnsDone'),
			addFieldsToContacts: t('import.addFieldsToContacts'),
			addFieldsToClients: t('import.addFieldsToClients'),
			noImport: t('import.noImport'),
			importTitle: t('import.pagetitle'),
			nextStep: t('import.nextStep'),
			pickFile: t('file.uploadHelpText_split1'),
			acceptedTypes: t('file.acceptedTypes'),
			uploadNewFile: t('import.uploadNewFile'),
			checkingFile: t('import.checkingFile'),
			newFileNeedsMatching: t('import.newFileNeedsMatching'),
			newFileNeedsMatchingTitle: t('import.newFileNeedsMatchingTitle'),
			rows: t('default.rows').toLowerCase(),
			saveDraft: t('mail.saveAsDraft'),
			createAndUpdateOrUpdateOnlyTitle: t('import.settings.createAndUpdateOrUpdateOnlyTitle'),
			createAndUpdate: t('import.updateSetting.createAndUpdateTitle'),
			createAndUpdateDescription: t('import.updateSetting.createAndUpdateDesc'),
			updateOnly: t('import.updateSetting.updateOnlyTitle'),
			updateOnlyDescription: t('import.updateSetting.updateOnlyDesc'),
			fieldsHeader: {
				'Client.UserId': t('import.fieldsHeader.client'),
				'Client.Project': t('import.fieldsHeader.project'),
				'Contact.Project': t('import.fieldsHeader.contactProject'),
				'Client.Category': t('import.fieldsHeader.category'),
				'Contact.Category': t('import.fieldsHeader.contactCategory'),
				'Contact.Optins': t('import.fieldsHeader.contactOptins'),
				'Common.CreateCampaign': t('import.fieldsHeader.createCampaign'),
				'Common.CreateProductCategory': t('import.fieldsHeader.createNewProductCategory'),
				'Product.Category': t('import.fieldsHeader.product')
			},
			fields: {
				'Client.UserId': {
					title: t('import.fields.client.title'),
					icon: 'up-icon-assign-user'
				},
				'Client.Project': {
					title: t('import.fields.project.title'),
					icon: 'fa fa-bullhorn'
				},
				'Contact.Project': {
					title: t('import.fields.contactProject.title'),
					icon: 'fa fa-bullhorn'
				},
				'Client.Category': {
					title: t('import.fields.category.title'),
					icon: 'fa fa-tags'
				},
				'Contact.Category': {
					title: t('import.fields.contactCategory.title'),
					icon: 'fa fa-tags'
				},
				'Contact.Optins': {
					title: t('import.fields.contactOptins.title'),
					icon: 'fa fa-tags'
				},
				'Product.Category': {
					title: t('import.fields.product.title'),
					icon: 'fa fa-tags'
				},
				bisnode: {
					title: t('import.fields.bisnode.title'),
					icon: 'fa fa-link'
				},
				statusReport: {
					title: t('import.fields.statusReport.title'),
					icon: 'fa fa-envelope'
				}
			},
			otherFields: t('filters.columns.other'),
			bisnode: {
				title: t('import.bisnode.title'),
				desc: t('import.bisnode.desc'),
				toggleText: t('import.bisnode.toggleText')
			},
			reportTitle: t('import.statusReport.title'),
			import: {
				title: t('import.import.title'),
				desc: t('import.import.desc'),
				contains: {
					title: t('import.import.contains.title')
				},
				returns: {
					title: t('import.import.returns.title')
				}
			},
			rowsWith: t('import.rowsWith'),
			startImport: t('import.startImport'),
			matching: t('import.matching'),
			addContactFields: t('import.addContactFields'),
			addClientFields: t('import.addClientFields'),
			onlyNewClients: t('import.onlyNewClients'),
			error: {
				hasContactMatchButNoClientMatch: t('import.error.hasContactMatchButNoClientMatch'),
				noColumnsInFirstRow: t('import.error.noColumnsInFirstRow')
			},
			contact: t('default.contact'),
			contacts: t('default.contacts'),
			client: t('default.client'),
			clients: t('default.clients'),
			product: t('default.product'),
			products: t('default.products'),
			onMultiMatch: t('import.onMultiMatch'),
			onMultiMatchProduct: t('import.onMultiMatchProduct'),
			skipRow: t('import.skipRow'),
			createNew: t('import.createNew'),
			clientName: t('default.accountName'),
			contactName: hasNewFields ? t('import.fullContactName') : t('import.contactName'),
			importSettings: t('import.importSettings'),
			importSettingsCategoryDesc: t('import.importSettingsCategoryDesc'),
			importSettingsDesc: t('import.importSettingsDesc'),
			importSettingsProductDesc: t('import.importSettingsProductDesc'),
			importSettingsProductDup: t('import.importSettingsProductDup'),
			otherAccountFields: t('default.otherAccountFields'),
			otherContactFields: t('default.otherContactFields'),
			multimatchInfo: t('import.multimatchInfo'),
			multimatchInfoProduct: t('import.multimatchInfoProduct'),
			readMore: t('default.readMore'),
			add: t('default.add'),
			setAsOnlyAccountManager: t('multi.setAsOnlyAccountManager'),
			deduplication: t('import.deduplication'),
			clientFieldsToMatch: t('import.clientFieldsToMatch'),
			contactFieldsToMatch: t('import.contactFieldsToMatch'),
			productFieldsToMatch: t('import.productFieldsToMatch'),
			deduplicationDescription: t('import.deduplicationDescription'),
			deduplicationDescriptionProduct: t('import.deduplicationDescriptionProduct'),
			inYourFile: t('import.review.inYourFile'),
			fileHealth: t('import.review.fileHealth'),
			ofTheFileHasPotentialIssues: t('import.review.ofTheFileHasPotentialIssues'),
			ofTheFileHasValues: t('import.review.ofTheFileHasValues'),
			potentialIssues: t('import.review.potentialIssues'),
			createNewWarning: t('import.createNewWarning'),
			topbarTooltip: t('import.helpTooltip.prepareData'),
			templateTooltip: {
				CLIENT_AND_CONTACT: t('import.helpTooltip.downloadCompanyContactsTemplate'),
				PRODUCT: t('import.helpTooltip.downloadProductTemplate')
			},
			oneRowHasEmptyMatch: t('import.review.issue.oneRowHasEmptyMatch'),
			rowsHaveEmptyMatch: t('import.review.issue.rowsHaveEmptyMatch'),
			columnMissingMatchDesc: t('import.review.issue.columnMissingMatchDesc'),
			oneRowCantBeImported: t('import.review.issue.oneRowCantBeImported'),
			someRowsCantBeImported: t('import.review.issue.someRowsCantBeImported'),
			preview: {
				check: t('import.review.previewCheck'),
				loading: t('import.review.previewLoading')
			},
			productCategory: t('default.productCategory'),
			productCategoryTooltip: t('import.fields.productCategory.tooltip'),
			chooseCategory: t('admin.chooseCategory')
		};
	}

	setRef = (name, ref) => {
		this[name] = ref;
	};

	importToggle = (id, skip) => {
		if (skip) {
			skip = false;
		} else {
			skip = true;
		}

		this.props.rootData.importChange(id, skip);
	};

	getDataTransferFiles = event => {
		var dataTransferItemsList = [];
		if (event.dataTransfer) {
			var dt = event.dataTransfer;
			if (dt.files && dt.files.length) {
				dataTransferItemsList = dt.files;
			} else if (dt.items && dt.items.length) {
				dataTransferItemsList = dt.items;
			}
		} else if (event.target && event.target.files) {
			dataTransferItemsList = event.target.files;
		}

		return Array.prototype.slice.call(dataTransferItemsList);
	};

	nextStep = nextStep => {
		var currentPage = this.state.page;
		var newCurrentPage = this.state.currentPage;

		_.map(currentPage, function (page, index) {
			if (index === nextStep) {
				newCurrentPage = page.name;
				page.active = true;
				return page;
			}

			page.active = false;
			return page;
		});

		var maxSteps = currentPage.length;
		var nextStepIndex = nextStep + 1;
		var nextStepText = maxSteps === nextStepIndex ? currentPage[nextStep].name : currentPage[nextStepIndex].name;

		this.setState({
			page: currentPage,
			currentPage: newCurrentPage,
			nextPage: {
				text: nextStepText,
				step: nextStepIndex
			}
		});
	};

	changeBisnode = () => {
		this.props.rootData.changeBisnode();
	};

	expandIssue = (issue, value) => {
		this.setState({
			expandedIssues: {
				...this.state.expandedIssues,
				[issue]: value
			}
		});
	};

	getField = key => {
		var fields = this.props.rootData.pageData.obj.fields;

		var fie = _.find(fields, function (f) {
			return f.field === key;
		});

		return fie;
	};

	getFieldValue = key => {
		var field = this.getField(key);
		if (field) {
			return field.value;
		}

		return [];
	};

	getFieldOnlyNewValue = key => {
		var field = this.getField(key);
		if (field) {
			return field.onlyNew || false;
		} else if (key === 'Client.UserId') {
			// Default only new to true for accountManager
			return true;
		}

		return false;
	};

	getFieldActionValue = key => {
		var field = this.getField(key);
		if (field) {
			return field.action;
		} else if (key === 'Client.UserId') {
			// Default only new to true for accountManager
			return this.props.rootData.pageData.teamAccountManager ? 'add' : 'set';
		}

		return null;
	};

	getCustomFields = () => {
		var fields = [];
		_.each(this.props.rootData.pageData.obj.fields, function (field) {
			if (field.field.indexOf('custom_') !== -1) {
				fields.push(field);
			}
		});
		return fields;
	};

	getColumnError = () => {
		var msg = [];
		var columnErrors = this.props.rootData.pageData.columnErrors;
		const key = 1;
		if (columnErrors.noColumnsInFirstRow) {
			msg.push(
				<span key={`${key}-${this.lang.error.noColumnsInFirstRow}`}>
					{this.lang.error.noColumnsInFirstRow}
					<br />
				</span>
			);
		}

		if (columnErrors.missingRequiredMatches) {
			columnErrors.missingRequiredMatches.forEach(missingRequiredMatch => {
				msg.push(
					<span
						key={`${key}-${Tools.$translate('import.error.missingRequiredMatch', {
							field: missingRequiredMatch
						})}`}
					>
						{Tools.$translate('import.error.missingRequiredMatch', {
							field: missingRequiredMatch
						})}
						<br />
					</span>
				);
			});
		}

		if (columnErrors.hasContactMatchButNoClientMatch) {
			msg.push(
				<span key={`${key}-${this.lang.error.hasContactMatchButNoClientMatch}`}>
					{this.lang.error.hasContactMatchButNoClientMatch}
					<br />
				</span>
			);
		}

		if (msg.length) {
			return <span key={`${key}-123`}>{msg}</span>;
		}

		return null;
	};

	getTagLabel = tagName => {
		var tags = null;
		if (tagName.indexOf('Product.') === 0) {
			tags = this.props.rootData.pageData.tags.product;
		} else if (tagName.indexOf('Client.') === 0) {
			tags = this.props.rootData.pageData.tags.client;
		} else {
			tags = this.props.rootData.pageData.tags.contact;
		}

		var tag = _.find(tags, { tag: tagName });
		if (tag) {
			return <span>{tag.name}</span>;
		}

		return tagName;
	};

	filterTag = tag => {
		var nameNotSelected;

		if (tag.tag === 'Contact.Name') {
			nameNotSelected = _.findIndex(this.props.rootData.pageData.obj.columns, { field: tag.tag }) === -1;
			var firstNameNotSelected =
				_.findIndex(this.props.rootData.pageData.obj.columns, { field: 'Contact.FirstName' }) === -1;
			var lastNameNotSelected =
				_.findIndex(this.props.rootData.pageData.obj.columns, { field: 'Contact.LastName' }) === -1;

			return nameNotSelected && firstNameNotSelected && lastNameNotSelected;
		}
		if (tag.tag === 'Contact.FirstName' || tag.tag === 'Contact.LastName') {
			var tagNotSelected = _.findIndex(this.props.rootData.pageData.obj.columns, { field: tag.tag }) === -1;
			nameNotSelected = _.findIndex(this.props.rootData.pageData.obj.columns, { field: 'Contact.Name' }) === -1;

			return tagNotSelected && nameNotSelected;
		}

		return _.findIndex(this.props.rootData.pageData.obj.columns, { field: tag.tag }) === -1;
	};

	getAvailableTags = () => {
		var self = this;
		switch (this.props.rootData?.pageData?.obj.type) {
			case 'PRODUCT':
				return [
					{
						name: self.lang.product,
						scrollId: 'product',
						children: _.reduce(
							self.props.rootData.pageData.tags.product,
							function (result, tag) {
								if (self.filterTag(tag)) {
									result.push(tag);
								}

								return result;
							},
							[]
						)
					}
				];
			default:
				return [
					{
						name: self.lang.contact,
						scrollId: 'contact',
						children: _.reduce(
							self.props.rootData.pageData.tags.contact,
							function (result, tag) {
								if (self.filterTag(tag)) {
									tag.name = tag.tag === 'Contact.Name' ? self.lang.contactName : tag.name;

									result.push(tag);
								}

								return result;
							},
							[]
						)
					},
					{
						name: self.lang.client,
						scrollId: 'client',
						children: _.reduce(
							self.props.rootData.pageData.tags.client,
							function (result, tag) {
								if (self.filterTag(tag)) {
									tag.name = tag.tag === 'Client.Name' ? self.lang.clientName : tag.name;

									result.push(tag);
								}

								return result;
							},
							[]
						)
					}
				];
		}
	};

	missingMatch = () => {
		const columns = this.props.rootData.pageData.obj.columns;
		const contactTags = this.props.rootData.pageData.tags.contact;

		let missingMatch = true;
		let missingContactMatch = true;
		let hasMatchableContactFields = false;
		columns.forEach(col => {
			// If importing contact fields that are matchable, you are required to match at least one of them
			if (col.field.indexOf('Contact.') === 0 && contactTags.find(tag => tag.tag === col.field)?.importMatch) {
				hasMatchableContactFields = true;
				if (col.isMatch) {
					missingContactMatch = false;
				}
			} else if (col.isMatch) {
				missingMatch = false;
			}
		});

		if (hasMatchableContactFields) {
			return missingMatch || missingContactMatch;
		}
		return missingMatch;
	};

	hasErrors = () => {
		var columnErrors = this.props.rootData.pageData.columnErrors;
		return {
			step2: columnErrors.missingRequiredMatches?.length > 0 || columnErrors.hasContactMatchButNoClientMatch,
			step3: this.missingMatch()
		};
	};

	saveDraft = () => {
		// eslint-disable-next-line
		this.props.rootData.onSave().then(() => {
			Tools.$state.go('administration.imports');
		});
	};

	renderCustomField = (type, fieldConf) => {
		var self = this;
		if (type === 'contact' && fieldConf.field.indexOf('Contact.') !== 0) {
			return null;
		}
		if (type === 'client' && fieldConf.field.indexOf('Client.') !== 0) {
			return null;
		}

		var id = fieldConf.field.split('_')[1];
		var field = _.find(this.props.rootData.pageData.customFields[type], { id: parseInt(id) });

		if (!field) {
			return null;
		}
		field.value = fieldConf.value;
		var isSelect = field.datatype === 'Select';
		var cf = React.createElement(ReactTemplates.INPUTS.customFieldInput, {
			key: 'cf-' + id,
			field: field,
			multiple: false,
			className: isSelect ? null : 'form-control up-input',
			style: { width: '100%' },
			skipSelectPlaceholder: true,
			filter: false,
			onChange: function (value) {
				self.props.rootData.addField(fieldConf.field, value);
			}
		});

		return (
			<div key={'cf-' + id + '-col'} className="custom-field-item">
				<label className="settings-label">
					{field.name}{' '}
					<button
						type="button"
						className="btn-inline btn-sm up-btn btn-link btn-bright-blue pull-right"
						onClick={function () {
							self.props.rootData.addField(fieldConf.field, '', true);
						}}
					>
						{self.lang.remove}
					</button>
				</label>
				{cf}
			</div>
		);
	};

	renderFieldPreview = column => {
		var title;

		var icon = 'user';
		if (column.field.indexOf('Client') === 0) {
			icon = 'home';
		} else if (column.field.indexOf('Product') === 0) {
			icon = 'cube';
		}

		if (column.skip) {
			title = <span className="nomatch">{this.lang.noImport}</span>;
		} else {
			title = (
				<span>
					<b className={'fa fa-' + icon} />
					{this.getTagLabel(column.field)}
				</span>
			);
		}

		return (
			<div className="field-preview" key={'field-' + column.index}>
				{title}
				<div className="column-name">
					{this.lang.inYourFile} {column.name}
				</div>
				{column.isMatch ? (
					<span className="match">
						<i className="fa fa-link" />
						{this.lang.matching}
					</span>
				) : null}
			</div>
		);
	};

	renderActionItem = (icon, title, value) => {
		return (
			<div className="action-item" key={'action-' + title}>
				<b className={'icon ' + icon} />
				{title}
				{value}
			</div>
		);
	};

	renderImportPreview = rootData => {
		const { preview, type } = rootData.pageData.obj;
		let result = [];
		if (preview === '') {
			result = [
				<div
					className="preview-item preview-start"
					key={'preview-start'}
					onClick={() => rootData.runImportPreview()}
				>
					<Icon name="refresh" />
					{this.lang.preview.check}
				</div>
			];
		} else if (preview === 'START_PREVIEW' || preview?.running) {
			result = [
				<div
					className={'preview-item preview-progress' + (!preview?.running ? ' progress-waiting' : '')}
					key={'import-preview-progress'}
				>
					<Text>{this.lang.preview.loading}</Text>
					<Progressbar value={preview?.running ? preview.progress * 100 : 0} color="bright-blue" size="sm" />
				</div>
			];
		} else if (preview?.done) {
			switch (type) {
				case Tools.Import.type.PRODUCT:
					result = [
						this.renderPreviewItem(
							'plus-circle',
							Tools.$translate('import.review.previewWillBeCreated', {
								count: preview.product?.created ?? 0,
								entity: (preview.product?.created === 1
									? this.lang.product
									: this.lang.products
								).toLowerCase()
							})
						),
						this.renderPreviewItem(
							'plus-circle',
							Tools.$translate('import.review.previewWillBeUpdated', {
								count: preview.product?.updated ?? 0,
								entity: (preview.product?.updated === 1
									? this.lang.product
									: this.lang.products
								).toLowerCase()
							})
						)
					];
					break;
				default: {
					result = [
						this.renderPreviewItem(
							'plus-circle',
							Tools.$translate('import.review.previewWillBeCreated', {
								count: preview.client?.created ?? 0,
								entity: (preview.client?.created === 1
									? this.lang.client
									: this.lang.clients
								).toLowerCase()
							})
						),
						this.renderPreviewItem(
							'refresh',
							Tools.$translate('import.review.previewWillBeUpdated', {
								count: preview.client?.updated ?? 0,
								entity: (preview.client?.updated === 1
									? this.lang.client
									: this.lang.clients
								).toLowerCase()
							})
						),
						this.renderPreviewItem(
							'plus-circle',
							Tools.$translate('import.review.previewWillBeCreated', {
								count: preview.contact?.created ?? 0,
								entity: (preview.contact?.created === 1
									? this.lang.contact
									: this.lang.contacts
								).toLowerCase()
							})
						),
						this.renderPreviewItem(
							'refresh',
							Tools.$translate('import.review.previewWillBeUpdated', {
								count: preview.contact?.updated ?? 0,
								entity: (preview.contact?.updated === 1
									? this.lang.contact
									: this.lang.contacts
								).toLowerCase()
							})
						)
					];
				}
			}
		}
		return result;
	};

	renderPreviewItem = (icon, text) => (
		<div className="preview-item" key={'preview-' + text}>
			<Icon name={icon} />
			{text}
		</div>
	);

	renderActionCustomfield = (type, fieldConf) => {
		if (type === 'contact' && fieldConf.field.indexOf('Contact.') !== 0) {
			return null;
		}
		if (type === 'client' && fieldConf.field.indexOf('Client.') !== 0) {
			return null;
		}
		var id = fieldConf.field.split('_')[1];
		var field = _.find(this.props.rootData.pageData.customFields[type], { id: parseInt(id) });

		if (!field) {
			return null;
		}
		var value = fieldConf.value;
		if (field.datatype === 'Date') {
			value = fieldConf.value && moment(fieldConf.value).isValid() ? moment(value).format('L') : '';
		}
		return (
			<div className="value">
				{field.name}
				{' - '}
				{value}
			</div>
		);
	};

	setManagerAction = value => {
		this.props.rootData.setFieldAction('Client.UserId', value);
	};

	mapAttrToCorrectField = (attr, entity) => {
		var attrToFieldMap = {
			webpage: 'www'
		};

		var field = attrToFieldMap[attr.field] ? attrToFieldMap[attr.field] : attr.field;
		return entity + '.' + this.toFirstUpperCase(field);
	};

	toFirstUpperCase = str => {
		return str[0].toUpperCase() + str.substr(1, str.length);
	};

	renderIssues = (columns, emptyCells, wrongDataTypeCells) => {
		const issues = [];

		// Missing value for matches
		const missingMatchValue = columns.filter(column => {
			let wrongValues;
			if (column.field === 'Client.Id') {
				wrongValues = column.sampleValues.filter(value => {
					return isNaN(value);
				});
			}
			return column.isMatch && !column.skip && (column.emptyRows || wrongValues?.length > 0);
		});
		if (missingMatchValue.length) {
			const idColumn = missingMatchValue.find(value => value.field === 'Client.Id');
			const wrongValues = idColumn?.sampleValues.filter(value => {
				return isNaN(value);
			});

			const missingMatchIssue = (
				<div
					className={'issue-item' + (this.state.expandedIssues.missingMatches ? ' issue-item-expanded' : '')}
					key="missingMatch"
				>
					<div
						className="issue-item-content"
						onClick={() => this.expandIssue('missingMatches', !this.state.expandedIssues.missingMatches)}
					>
						<Icon name="exclamation-triangle" color="orange" />
						<div className="issue-item-title">
							{missingMatchValue.length === 1 &&
							(missingMatchValue[0].emptyRows === 1 || wrongValues?.length === 1)
								? this.lang.oneRowCantBeImported
								: this.lang.someRowsCantBeImported}
						</div>
						<Icon name={this.state.expandedIssues.missingMatches ? 'angle-up' : 'angle-down'} />
					</div>

					{this.state.expandedIssues.missingMatches ? (
						<ul className="issue-item-extended-content">
							{missingMatchValue.map(column => (
								<li key={'missingMatch-' + column.field}>
									{(column.emptyRows || wrongValues?.length) +
										' ' +
										(column.emptyRows === 1 || wrongValues?.length === 1
											? this.lang.oneRowHasEmptyMatch
											: this.lang.rowsHaveEmptyMatch)}
									<b>
										{Tools.$translate('import.review.issue.columnMissingMatch', {
											column: column.index + 1,
											name: column.name
										})}
									</b>
									{this.lang.columnMissingMatchDesc}
								</li>
							))}
						</ul>
					) : null}
				</div>
			);
			issues.push(missingMatchIssue);
		}

		if (emptyCells > 0) {
			// Missing values
			const missingValueIssues = columns.reduce(function (issues, column) {
				if (column.emptyRows && !column.skip) {
					const issue = (
						<li key={column.index}>
							{Tools.$translate(
								column.emptyRows === 1
									? 'import.review.issue.columnMissingOneValue'
									: 'import.review.issue.columnMissingXValues',
								{
									column: column.index + 1,
									name: column.name,
									emptyCells: column.emptyRows
								}
							)}
						</li>
					);
					issues.push(issue);
				}
				return issues;
			}, []);

			const missingValueIssue = (
				<div
					className={'issue-item' + (this.state.expandedIssues.missingValues ? ' issue-item-expanded' : '')}
					key="missingValues"
				>
					<div
						className="issue-item-content"
						onClick={() => this.expandIssue('missingValues', !this.state.expandedIssues.missingValues)}
					>
						<Icon name="exclamation-triangle" color="orange" />
						<div className="issue-item-title">
							{emptyCells === 1
								? Tools.$translate('import.review.issue.oneCellHasMissingValues')
								: Tools.$translate('import.review.issue.cellsHaveMissingValues', { emptyCells })}
						</div>
						<Icon name={this.state.expandedIssues.missingValues ? 'angle-up' : 'angle-down'} />
					</div>

					{this.state.expandedIssues.missingValues ? (
						<ul className="issue-item-extended-content">{missingValueIssues}</ul>
					) : null}
				</div>
			);
			issues.push(missingValueIssue);
		}

		if (wrongDataTypeCells > 0) {
			const wrongDataTypeIssues = columns.reduce(function (issues, column) {
				if (column.field === 'Client.Id' && !column.skip) {
					const issue = (
						<li key={column.index}>
							{Tools.$translate(
								wrongDataTypeCells === 1
									? 'import.review.issue.columnHasOneWrongType'
									: 'import.review.issue.columnHasXWrongTypes',
								{
									column: column.index + 1,
									name: column.name,
									wrongDataTypeCells
								}
							)}
						</li>
					);
					issues.push(issue);
				}
				return issues;
			}, []);
			const wrongDataTypeIssue = (
				<div
					className={'issue-item' + (this.state.expandedIssues.missingValues ? ' issue-item-expanded' : '')}
					key="wrongDataTypes"
				>
					<div
						className="issue-item-content"
						onClick={() => this.expandIssue('wrongDataTypes', !this.state.expandedIssues.wrongDataTypes)}
					>
						<Icon name="exclamation-triangle" color="orange" />
						<div className="issue-item-title">
							{wrongDataTypeCells === 1
								? Tools.$translate('import.review.issue.oneCellHasWrongType')
								: Tools.$translate('import.review.issue.cellsHasWrongTypes', {
										wrongDataTypeCells
								  })}
						</div>
						<Icon name={this.state.expandedIssues.wrongDataTypes ? 'angle-up' : 'angle-down'} />
					</div>

					{this.state.expandedIssues.wrongDataTypes ? (
						<ul className="issue-item-extended-content">{wrongDataTypeIssues}</ul>
					) : null}
				</div>
			);
			issues.push(wrongDataTypeIssue);
		}
		return issues;
	};

	updateMatch = (type, values) => {
		const pageData = this.props.rootData.pageData;

		const ifActive = pageData.obj.columns.find(column => column.field === 'Product.Active');

		const active = ifActive && values.length === 1 && values[0].value === ifActive.id;
		this.setState({ active });

		const oldMatches = pageData.obj.columns
			.filter(column => column.isMatch && column.field.indexOf(type) === 0)
			.map(column => column.id);
		const added = values.filter(value => !oldMatches.some(oldMatch => oldMatch === value.value));
		const removed = oldMatches.filter(oldMatch => !values.some(value => oldMatch === value.value));

		added.forEach(value => {
			this.props.rootData.changeMatch(value.value, true);
		});

		removed.forEach(value => {
			this.props.rootData.changeMatch(value, false);
		});
	};

	createCampaign = () => {
		// eslint-disable-next-line promise/catch-or-return
		openEditCampaignModal({
			customerId: Tools.AppService.getCustomerId(),
			noRedirect: true
		});
	};

	createProductCategory = () => {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('AddProductCategoryModal', {
				type: 'AddProductCategory',
				productCategory: null,
				noRedirect: true
			})
			.then(res => {
				this.setState({ productCategories: [...this.state.productCategories, res] });
			});
	};

	onUpload = e => {
		if (e.target.files.length === 1) {
			const file = e.target.files[0];
			if (
				file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
				file.type === 'application/vnd.ms-excel' ||
				(file.name && file.type === '' && file.name.slice(-3) === 'xls') ||
				file.name.slice(-4) === 'xlsx'
			) {
				// eslint-disable-next-line
				this.props.rootData.onUploadNewFile(file).then(() => {
					this.showUpload(false);

					const columnsLeft = this.props.rootData.pageData.obj.columns.reduce(
						(total, column) => (column.field || column.skip ? 0 : 1),
						0
					);
					if (columnsLeft > 0 && this.state.nextPage.step > 1) {
						this.nextStep(1);
						this.newFileNeedsMatching(true);
					} else {
						this.newFileNeedsMatching(false);
					}
				});
			} else {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'file.wrongFileType'
				});
			}
		}
		e.target.value = null;
	};

	triggerUpload = () => {
		if (this.uploadInput) {
			this.uploadInput.click();
		}
	};

	showUpload = display => {
		this.setState({
			showUpload: display
		});
	};

	newFileNeedsMatching = value => {
		this.setState({
			newFileNeedsMatching: value
		});
	};

	importTemplates = (currentLanguage, importType) => {
		const customerId = Tools.AppService.getCustomerId();
		const fileUrl = {
			[Tools.Import.type.PRODUCT]: {
				en: '/files/static?path=import/Import-Template-Products-EN.xlsx',
				sv: '/files/static?path=import/Import-Template-Products-SV.xlsx'
			},
			[Tools.Import.type.CLIENT_AND_CONTACT]: {
				en: '/files/static?path=import/Template-en.xlsx',
				sv: '/files/static?path=import/Template-sv.xls'
			}
		};
		const templates = [
			{
				lang: 'en-GB',
				url: Tools.URL + Tools.API + customerId + fileUrl[importType].en
			},
			{
				lang: 'sv-SE',
				url: Tools.URL + Tools.API + customerId + fileUrl[importType].sv
			}
		];

		const defaultTemplate =
			templates.find(temp => temp.lang === currentLanguage) || templates.find(temp => temp.lang === 'en-GB');
		return {
			default: defaultTemplate,
			templates: templates.filter(temp => temp.lang !== defaultTemplate.lang)
		};
	};

	renderTemplateButton = (template, importType, setExpanded, isExpanded) => (
		<Button
			color="grey"
			shadow="none"
			type="link"
			className="import-type-template-btn"
			key={'template-' + template.lang + importType}
			onClick={() => window.open(template.url)}
		>
			<div className={'template-lang'}>
				<Icon name="file-excel" /> {template.lang.substr(0, 2)}
			</div>
			{`${this.lang.download} ${this.lang.templateTypes[importType].toLowerCase()}`}
			{setExpanded ? (
				<Icon
					onClick={setExpanded}
					className={'import-type-template-icon' + (isExpanded ? ' expanded' : '')}
					name="sort-down"
				/>
			) : null}
		</Button>
	);

	availableImportTypes = selectedType => {
		const availableImportTypes = [Tools.Import.type.CLIENT_AND_CONTACT, Tools.Import.type.PRODUCT];

		return (
			<Flex gap="u4" space="mbxl">
				{availableImportTypes.map(type => (
					<ButtonBox
						key={'select-' + type.title}
						title={this.lang.importTypes[type]}
						selected={type === selectedType}
						onClick={() => this.props.rootData.setImportType(type)}
					/>
				))}
			</Flex>
		);
	};

	renderUpdateSelect = onlyUpdate => {
		const types = [
			{
				title: this.lang.updateOnly,
				subtitle: this.lang.updateOnlyDescription,
				img: 'img/admin-import-update.svg',
				value: true
			},
			{
				title: this.lang.createAndUpdate,
				subtitle: this.lang.createAndUpdateDescription,
				img: 'img/admin-import-create-and-update.svg',
				value: false
			}
		];

		return (
			<Flex gap="u4">
				{types.map(type => {
					const selected =
						type.value === onlyUpdate && this.props.rootData.pageData.selectedUpdateOption !== false;
					return (
						<ButtonBox
							className="import-update-select"
							key={'select-' + type.title}
							onClick={() => this.props.rootData.setOnlyUpdate(type.value)}
							selected={selected}
						>
							<img src={type.img} />
							<Text size="lg" bold>
								{type.title}
							</Text>
							<Text color="grey-11">{type.subtitle}</Text>
						</ButtonBox>
					);
				})}
			</Flex>
		);
	};

	renderContent = () => {
		var self = this;
		var rootData = this.props.rootData;
		var pageData = rootData.pageData;
		var tools = this.props.tools;

		if (rootData.pageLoading) {
			return null;
		}

		var pageIndex = 1;
		var columns = pageData.obj.columns;
		var columnsLeft = columns.length;
		let selectedAnyColumn = false;
		var ulHeight = 0;
		_.each(columns, function (col) {
			var height = 30;
			_.each(col.sampleValues, function (row, i) {
				if (i < 5) {
					height += 31;
				}
			});
			if (ulHeight < height) {
				ulHeight = height;
			}
		});

		var clientIdPresent = !!_.find(columns, { field: 'Client.Id' });
		var col = _.map(columns, function (column, index) {
			if (column.field) {
				--columnsLeft;
				selectedAnyColumn = true;
			} else if (column.skip) {
				--columnsLeft;
			}

			// if (column.field.length < 1) {
			// 	_.each(Tools.TagsService.getImportTags('client'), function(tag) {
			// 		var isSemiMatch = tag.name.toLowerCase().indexOf(column.name.toLowerCase()) !== -1;

			// 		if ((tag.type === 'String' || tag.type === 'Integer') && isSemiMatch) {
			// 			if (tag.tag === 'Client.Name') {
			// 				tag.name = Tools.$translate('default.accountName');
			// 			}
			// 			column.field = tag.tag;
			// 			rootData.setField(tag, column.id);
			// 		}
			// 	});
			// }

			return (
				<ImportColumn
					key={'col-' + column.name + '-' + index}
					column={column}
					index={index}
					height={ulHeight}
					importToggle={self.importToggle}
					getAvailableTags={self.getAvailableTags}
					refTags={{
						client: pageData.tags.client,
						contact: pageData.tags.contact,
						product: pageData.tags.product
					}}
					clientIdPresent={clientIdPresent}
					setField={rootData.setField}
					tools={self.props.tools}
					numRows={pageData.obj.numRows}
					importType={pageData.obj.type}
				/>
			);
		});

		var hasErrors = self.hasErrors();

		let topbarBtnSubText;
		let topbarBtnText;
		if (this.state.currentPage === 'review') {
			topbarBtnText = this.lang.startImport;
		} else {
			topbarBtnText = this.lang.titles[this.state.nextPage.text];
			topbarBtnSubText = this.lang.nextStep;
		}

		let currentArticle = 865;
		switch (this.state.currentPage) {
			case 'match':
				currentArticle = 869;
				break;
			case 'settings':
				currentArticle = 866;
				break;
		}

		var topbar = (
			<div className={'import-topbar-wrap'}>
				<div id="import-topbar">
					<h2>{this.lang.importTitle}</h2>
					<div id="import-tabs">
						{_.map(this.state.page, function (page, index) {
							var classNames = '';
							var disabled = false;
							if (page.active) {
								classNames += ' is-active';
							}
							if (
								((columnsLeft || !selectedAnyColumn || hasErrors.step2) && index > 1) ||
								(hasErrors.step3 && index > 2) ||
								(index === 1 && self.props.rootData.pageData.selectedUpdateOption === false) ||
								self.state.active
							) {
								classNames += ' is-disabled';
								disabled = true;
							}

							return (
								<div
									className={'import-tab' + classNames}
									key={page.name + '-' + index}
									onClick={function () {
										if (!disabled) {
											self.nextStep(index);
										}
									}}
								>
									<div className={'import-tabs-bullet'}>{pageIndex++}</div>
									{self.lang.titles[page.name]}
								</div>
							);
						})}
					</div>

					<Button className={'import-save-button'} type="link" color="grey" onClick={this.saveDraft}>
						{this.lang.saveDraft}
					</Button>

					<Button
						className={'import-next-button'}
						onClick={function () {
							if (self.state.currentPage === 'review') {
								rootData.onImportStart();
							} else {
								self.nextStep(self.state.nextPage.step);
							}
						}}
						disabled={
							(self.state.nextPage.step > 1 && (columnsLeft > 0 || !selectedAnyColumn)) ||
							(hasErrors.step2 && self.state.nextPage.step !== 1) ||
							(hasErrors.step3 && self.state.nextPage.step === 3) ||
							rootData.pageData.importStarting ||
							rootData.pageData.selectedUpdateOption === false ||
							self.state.active
						}
					>
						<div className={'import-next-button-text'}>
							<div className={'import-next-button-subtitle'}>{topbarBtnSubText}</div>
							<div className={'import-next-button-title'}>{topbarBtnText}</div>
						</div>
						<Icon name="angle-right" />
					</Button>
				</div>
				<div id="topbar-file">
					{this.state.showUpload ? (
						<div className={'topbar-file-upload'}>
							<svg
								width="80"
								height="100"
								viewBox="0 0 120 151"
								className="file-drop-icon"
								xmlns="http://www.w3.org/2000/svg"
								xmlnsXlink="http://www.w3.org/1999/xlink"
							>
								<g className="card" fill="none" fillRule="evenodd">
									<path
										className="paper"
										fill="#E4EDF7"
										d="M13,3.00972645 C13,1.34750043 14.3424077,0 16.0020839,0 L85,0 L107,22 L107,113.999935 C107,115.656825 105.653127,117 104.005268,117 L15.9947322,117 C14.3407873,117 13,115.665878 13,113.990274 L13,3.00972645 Z"
									/>
									<path
										className="arrow"
										fill="#4A90E2"
										d="M73.0412946,61.2142857 C73.0412946,61.8058065 72.8348235,62.3080336 72.421875,62.7209821 L61.5234375,73.6361607 C61.0881675,74.0491092 60.58036,74.2555804 60,74.2555804 C59.4084792,74.2555804 58.9062521,74.0491092 58.4933036,73.6361607 L47.5948661,62.7209821 C47.1707568,62.3191944 46.9587054,61.8169673 46.9587054,61.2142857 C46.9587054,60.6227649 47.1707568,60.1149575 47.5948661,59.6908482 L48.8337054,58.4352679 C49.2689754,58.0223194 49.7767828,57.8158482 50.3571429,57.8158482 C50.9486637,57.8158482 51.4508908,58.0223194 51.8638393,58.4352679 L56.7857143,63.3571429 L56.7857143,51.5714286 C56.7857143,50.9910685 56.9977657,50.4888414 57.421875,50.0647321 C57.8459843,49.6406229 58.3482114,49.4285714 58.9285714,49.4285714 L61.0714286,49.4285714 C61.6517886,49.4285714 62.1540157,49.6406229 62.578125,50.0647321 C63.0022343,50.4888414 63.2142857,50.9910685 63.2142857,51.5714286 L63.2142857,63.3571429 L68.1361607,58.4352679 C68.5491092,58.0223194 69.0513363,57.8158482 69.6428571,57.8158482 C70.2232172,57.8158482 70.7310246,58.0223194 71.1662946,58.4352679 L72.421875,59.6908482 C72.8348235,60.1261182 73.0412946,60.6339257 73.0412946,61.2142857 Z"
									/>
									<path
										className="fold"
										fill="#4A90E2"
										opacity=".2"
										d="M84,0.0459127487 C84,-0.511600348 84.3143399,-0.649214134 84.7031002,-0.260453851 L106.789944,21.8263904 C107.178256,22.2147019 107.044718,22.5294906 106.483578,22.5294906 L85.0094668,22.5294906 C84.4519537,22.5294906 84,22.0811639 84,21.5200238 L84,0.0459127487 Z"
									/>
								</g>
								<ellipse
									className="shadow"
									id="shadow"
									cx="60"
									cy="147"
									fill="#000000"
									opacity=".1"
									rx="60"
									ry="4"
								/>
							</svg>
							{rootData.uploading ? (
								<div className={'topbar-file-upload-text'}>
									{this.lang.checkingFile}
									<Progressbar size="sm" color="bright-blue" value={30} />
								</div>
							) : (
								<div className={'topbar-file-upload-text'}>
									<div>
										<Button type="link" onClick={this.triggerUpload}>
											{this.lang.pickFile}
										</Button>
									</div>
									<div className={'subtitle'}>
										{this.lang.acceptedTypes}
										{': .xls, .xlsx'}
									</div>
								</div>
							)}
							<Button className={'upload-close-btn'} type="link" onClick={() => this.showUpload(false)}>
								<Icon name="times" />
							</Button>
						</div>
					) : (
						<div className="topbar-file-content">
							<Icon name="file-excel" />
							<span className="topbar-subtitle">
								<span className="filename">{`${
									pageData.obj.onlyUpdate ? this.lang.updateOnly : this.lang.createAndUpdate
								}: ${self.lang.importTypes[pageData.obj.type]}`}</span>
								<span className="file-info">
									{pageData.obj.file && pageData.obj.file.filename} {' - '}
									{pageData.obj.numRows} {this.lang.rows}
								</span>
							</span>
							{this.state.newFileNeedsMatching && columnsLeft > 0 ? (
								<div className={'new-file-error'}>
									<Icon name="warning" />
									<div className={'new-file-error-text'}>
										<div className={'title'}>{this.lang.newFileNeedsMatchingTitle}</div>
										<div className={'subtitle'}>{this.lang.newFileNeedsMatching}</div>
									</div>
								</div>
							) : null}
							<Button className={'show-upload-btn'} type="link" onClick={() => this.showUpload(true)}>
								<Icon name="refresh" />
								{this.lang.uploadNewFile}
							</Button>
							<div className="topbar-help-icon">
								<Tooltip title={this.lang.topbarTooltip}>
									<ReactTemplates.elevio sidebar={true} articleId={currentArticle} />
								</Tooltip>
							</div>
						</div>
					)}

					<input
						type="file"
						className={'upload-input'}
						ref={ref => (this.uploadInput = ref)}
						onChange={this.onUpload}
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
					/>
				</div>
			</div>
		);

		switch (this.state.currentPage) {
			case 'type': {
				const importType = self.props.rootData.pageData.obj.type;
				const availableImportTypes = self.availableImportTypes(importType);
				const onlyUpdate = self.props.rootData.pageData.obj.onlyUpdate;
				const lang = Tools.AppService.getSelf().language;
				const templates = self.importTemplates(lang, importType);

				return (
					<div id="single-import" key="type-content">
						{topbar}
						<div className="import-type-content">
							<h2>{self.lang.titles.type + ': ' + self.lang.importTypes[importType]}</h2>
							<div className="import-type-bar">
								<Tooltip title={self.lang.templateTooltip[importType]}>
									<DropDownMenu
										className="import-type-template"
										renderTrigger={(isExpanded, setExpanded) =>
											self.renderTemplateButton(
												templates.default,
												importType,
												setExpanded,
												isExpanded
											)
										}
									>
										{templates.templates.map(temp => self.renderTemplateButton(temp, importType))}
									</DropDownMenu>
								</Tooltip>
							</div>
							{availableImportTypes}
							<h2>{self.lang.updateSettingTitle}</h2>
							<div className="import-type-subtitle">{self.lang.updateSettingSubtitle}</div>
							{self.renderUpdateSelect(onlyUpdate)}
						</div>
					</div>
				);
			}
			case 'match':
				var error = self.getColumnError();

				var instructions;

				var iconClass = 'fa-check';
				if (
					rootData.pageData.columnErrors.missingRequiredMatches ||
					rootData.pageData.columnErrors.hasContactMatchButNoClientMatch ||
					rootData.pageData.columnErrors.noColumnsInFirstRow
				) {
					iconClass = 'fa-info';
				}
				instructions = (
					<div className={'instructions' + (!error ? ' one-line' : '')}>
						<b className={'fa ' + iconClass + ' instruction-icon'} />
						{rootData.pageData.columnErrors.noColumnsInFirstRow ? null : columnsLeft > 0 ? (
							<div>
								{columnsLeft} {columnsLeft === 1 ? self.lang.column : self.lang.columns}{' '}
								{self.lang.connectToUpsalesField}
							</div>
						) : !selectedAnyColumn ? (
							<div>
								{'1'} {self.lang.column} {self.lang.connectToUpsalesField}
							</div>
						) : (
							<div>{self.lang.allColumnsDone}</div>
						)}
						{rootData.pageData.columnErrors.noColumnsInFirstRow ? (
							<span>{this.lang.error.noColumnsInFirstRow}</span>
						) : (
							error
						)}
					</div>
				);

				return (
					<div id="single-import">
						{topbar}
						{instructions}
						<div id="import-scroll-wrapper" key="match-content">
							<div id="inner-wrapper">
								<div className="columns-holder">
									<div className="scroll-gradient" />
									<div className="columns-inner-holder">
										<div className="columns-inner" style={{ width: col.length * 242 + 52 + 'px' }}>
											{col}
										</div>
									</div>
									<div className="scroll-gradient" />
								</div>
							</div>
						</div>
					</div>
				);

			case 'settings': {
				var contactCustomfields = _.compact(
					_.map(self.getCustomFields(), function (fieldConf) {
						return self.renderCustomField('contact', fieldConf);
					})
				);

				var clientCustomfields = _.compact(
					_.map(self.getCustomFields(), function (fieldConf) {
						return self.renderCustomField('client', fieldConf);
					})
				);

				var avClientCustom = rootData.getAvailableCustomFields('client');
				var avContactCustom = rootData.getAvailableCustomFields('contact');
				var managerAction = self.getFieldActionValue('Client.UserId');
				const importCategoryFromFile = !!pageData.obj.columns.find(col => col.field === 'Product.CategoryName');
				const { contactFields, clientFields, productFields, hasMatch } = pageData.obj.columns.reduce(
					(result, column) => {
						if (column.skip) {
							return result;
						}

						let tags, arr;
						if (column.field.indexOf('Product.') === 0) {
							arr = result.productFields;
							tags = pageData.tags.product;
						} else if (column.field.indexOf('Contact.') === 0) {
							arr = result.contactFields;
							tags = pageData.tags.contact;
						} else {
							arr = result.clientFields;
							tags = pageData.tags.client;
						}
						const tag = tags.find(tag => tag.tag === column.field);
						if (!tag?.importMatch) {
							return result;
						}

						if (column.isMatch) {
							result.hasMatch = true;
						}

						arr.push({
							value: column.id || column.field,
							label: tag.name + ' - ' + column.name,
							isMatch: column.isMatch,
							clearableValue:
								column.field !== 'Client.Id' &&
								column.field !== 'Contact.Id' &&
								column.field !== 'Product.Id'
						});

						return result;
					},
					{ contactFields: [], clientFields: [], productFields: [], hasMatch: false }
				);

				const productCategoryFieldValue = self.getFieldValue('Product.Category');

				return (
					<div id="single-import" key="settings-content">
						{topbar}
						<div id="admin-content">
							<div className="admin-card">
								<div className="admin-card-top">
									<div className="admin-card-title admin-card-title-lg">
										{this.lang.importSettings}
									</div>
									<div className="admin-card-top-description">
										{pageData.obj.type === 'PRODUCT'
											? this.lang.importSettingsProductDesc
											: this.lang.importSettingsDesc}
									</div>
								</div>
								{pageData.obj.type === 'CLIENT_AND_CONTACT' ? (
									<div className="admin-card-content centered-border">
										<div className="half-row">
											<div className="half-column">
												<div className="setting-type-header">
													<b className="fa fa-user" /> {this.lang.addFieldsToContacts}
												</div>

												<div style={{ marginBottom: '10px' }}>
													<Row>
														<Column>
															<label className="settings-label">
																{self.lang.fieldsHeader['Contact.Project']}
															</label>
														</Column>
														<Column align="right">
															<label
																className="settings-label campaign-link"
																onClick={() => this.createCampaign()}
															>
																{self.lang.fieldsHeader['Common.CreateCampaign']}
															</label>
														</Column>
													</Row>
													{React.createElement(ReactTemplates.INPUTS.upCampaigns, {
														multiple: true,
														allowClear: true,
														onChange: function (obj) {
															rootData.addField('Contact.Project', obj);
														},
														value: self.getFieldValue('Contact.Project'),
														tools: tools
													})}
												</div>

												<div style={{ marginBottom: '10px' }}>
													<label className="settings-label">
														{self.lang.fieldsHeader['Contact.Category']}
													</label>
													{React.createElement(ReactTemplates.INPUTS.upCategories, {
														multiple: true,
														onChange: function (obj) {
															rootData.addField('Contact.Category', obj);
														},
														value: self.getFieldValue('Contact.Category'),
														type: 'contact',
														showParents: true,
														tools: tools
													})}
												</div>

												<div style={{ marginBottom: '10px' }}>
													<label className="settings-label">
														{self.lang.fieldsHeader['Contact.Optins']}
													</label>
													{React.createElement(ReactTemplates.INPUTS.upOptins, {
														value: self.getFieldValue('Contact.Optins'),
														multiple: true,
														onChange: function (obj) {
															rootData.addField('Contact.Optins', obj);
														},
														placeholder: '',
														optinType: 'manual'
													})}
												</div>
											</div>
											<div className="half-column">
												<div className="setting-type-header">
													<b className="fa fa-home" /> {this.lang.addFieldsToClients}
												</div>

												<div style={{ marginBottom: '10px' }}>
													<label className="settings-label" style={{ marginTop: 0 }}>
														{self.lang.fieldsHeader['Client.UserId']}
													</label>

													{rootData.pageData.teamAccountManager ? (
														<div key="user-sub" className="manager-settigns">
															<input
																type="radio"
																name="manager-type"
																style={{ marginRight: '3px' }}
																checked={!managerAction || managerAction === 'add'}
																onClick={this.setManagerAction.bind(null, 'add')}
															/>
															{this.lang.add}

															<input
																type="radio"
																name="manager-type"
																style={{ marginRight: '3px', marginLeft: '10px' }}
																checked={managerAction === 'set'}
																onClick={this.setManagerAction.bind(null, 'set')}
															/>
															{this.lang.setAsOnlyAccountManager}
														</div>
													) : null}

													{React.createElement(ReactTemplates.INPUTS.upUsers, {
														multiple: rootData.pageData.teamAccountManager,
														onChange: function (obj) {
															if (rootData.pageData.teamAccountManager) {
																rootData.addField(
																	'Client.UserId',
																	obj,
																	false,
																	managerAction
																);
															} else {
																rootData.addField(
																	'Client.UserId',
																	[obj],
																	false,
																	managerAction
																);
															}
														},
														value: rootData.pageData.teamAccountManager
															? self.getFieldValue('Client.UserId')
															: self.getFieldValue('Client.UserId')[0],
														tools: tools
													})}
													<div key="user-subx" className="manager-settigns">
														<Toggle
															size="sm"
															space="mrs"
															color="medium-green"
															checked={self.getFieldOnlyNewValue('Client.UserId')}
															onChange={function (value) {
																rootData.setOnlyNew('Client.UserId', value);
															}}
														/>
														<span>{self.lang.onlyNewClients}</span>
														<ReactTemplates.elevio sidebar={true} articleId={512} />
													</div>
												</div>

												<div style={{ marginBottom: '10px' }}>
													<Row>
														<Column>
															<label className="settings-label">
																{self.lang.fieldsHeader['Client.Project']}
															</label>
														</Column>
														<Column align="right">
															<label
																className="settings-label campaign-link"
																onClick={() => this.createCampaign()}
															>
																{self.lang.fieldsHeader['Common.CreateCampaign']}
															</label>
														</Column>
													</Row>
													{React.createElement(ReactTemplates.INPUTS.upCampaigns, {
														multiple: true,
														allowClear: true,
														onChange: function (obj) {
															rootData.addField('Client.Project', obj);
														},
														value: self.getFieldValue('Client.Project'),
														tools: tools
													})}
												</div>

												<div style={{ marginBottom: '10px' }}>
													<label className="settings-label">
														{self.lang.fieldsHeader['Client.Category']}
													</label>
													{React.createElement(ReactTemplates.INPUTS.upCategories, {
														multiple: true,
														onChange: function (obj) {
															rootData.addField('Client.Category', obj);
														},
														value: self.getFieldValue('Client.Category'),
														type: 'account',
														showParents: true,
														tools: tools
													})}
												</div>
											</div>
										</div>

										<div className="half-row">
											<div className="half-column">
												{avContactCustom.length ? (
													<div>
														<div className="setting-type-header">
															{this.lang.otherContactFields}
														</div>

														{contactCustomfields}

														<Button
															shadow="none"
															onClick={rootData.addCustomField.bind(null, false)}
															style={
																contactCustomfields.length
																	? { marginTop: '20px' }
																	: null
															}
														>
															<b className="fa fa-plus" /> {self.lang.addContactFields}
														</Button>
													</div>
												) : null}
											</div>
											<div className="half-column">
												{avClientCustom.length ? (
													<div>
														<div className="setting-type-header">
															{this.lang.otherAccountFields}
														</div>

														{clientCustomfields}

														<Button
															shadow="none"
															onClick={rootData.addCustomField.bind(null, true)}
															style={
																clientCustomfields.length ? { marginTop: '20px' } : null
															}
														>
															<b className="fa fa-plus" /> {self.lang.addClientFields}
														</Button>
													</div>
												) : null}
											</div>
										</div>
									</div>
								) : null}
								{pageData.obj.type === 'PRODUCT' ? (
									<div className="admin-info-row admin-info-row-flex">
										<div className="info-row-content">
											<Fragment>
												<Row>
													<Column>
														<h5>{this.lang.productCategory}</h5>
													</Column>
													<Column align="right">
														{
															importCategoryFromFile ? (
																<Tooltip title={this.lang.productCategoryTooltip}>
																	<Icon name="question-circle" />
																</Tooltip>
															) : null
															//TODO: not completely working yet. Will be fixed in other ticket
															// <label
															// 	className="settings-label campaign-link"
															// 	onClick={() => this.createProductCategory()}
															// >
															// 	{self.lang.fieldsHeader['Common.CreateProductCategory']}
															// </label>
														}
													</Column>
												</Row>
												<UpSelect
													data={this.state.productCategories}
													placeholder={this.lang.chooseCategory}
													key="category-select-product"
													className={'category-select form-control'}
													onChange={e =>
														rootData.addField(
															'Product.Category',
															e.target.added
																? [
																		{
																			name: e.target.added.name,
																			id: e.target.added.id,
																			$hasAccess: true
																		}
																  ]
																: []
														)
													}
													defaultValue={
														productCategoryFieldValue.length > 0
															? productCategoryFieldValue[0].id
															: null
													}
													getId={cat => cat.id}
													allowClear={true}
													disabled={importCategoryFromFile}
												/>
											</Fragment>
										</div>
										<div className="info-row-info">{this.lang.importSettingsCategoryDesc}</div>
									</div>
								) : null}
							</div>

							<div className="admin-card has-info">
								<div className="info-background" />
								<div className="admin-card-top">
									<span className="admin-card-title">{this.lang.deduplication}</span>
								</div>
								<div className="admin-card-top-description">
									{pageData.obj.type === 'PRODUCT' ? this.lang.importSettingsProductDup : null}
								</div>
								{clientFields.length || contactFields.length || productFields.length ? (
									<div className="admin-info-row admin-info-row-flex">
										<div className="info-row-content field-select-container">
											{clientFields.length ? (
												<Fragment>
													<h5>{this.lang.clientFieldsToMatch + ' *'}</h5>
													<Select
														value={clientFields.filter(field => field.isMatch)}
														isMulti={true}
														isClearable={false}
														onChange={values => {
															this.updateMatch('Client.', values);
														}}
														options={clientFields}
														className={
															!clientFields.find(field => field.isMatch)
																? 'select-error'
																: ''
														}
													/>
												</Fragment>
											) : null}
											{contactFields.length ? (
												<Fragment>
													<h5>{this.lang.contactFieldsToMatch + ' *'}</h5>
													<Select
														value={contactFields.filter(field => field.isMatch)}
														isMulti={true}
														isClearable={false}
														onChange={values => {
															this.updateMatch('Contact.', values);
														}}
														options={contactFields}
														className={
															!contactFields.find(field => field.isMatch)
																? 'select-error'
																: ''
														}
													/>
												</Fragment>
											) : null}
											{productFields.length ? (
												<Fragment>
													<h5>{this.lang.productFieldsToMatch + ' *'}</h5>
													<Select
														key="product-select"
														value={productFields.filter(field => field.isMatch)}
														isMulti={true}
														isClearable={false}
														onChange={values => {
															this.updateMatch('Product.', values);
														}}
														options={productFields}
														className={
															!productFields.find(field => field.isMatch)
																? 'select-error'
																: ''
														}
													/>
												</Fragment>
											) : null}
										</div>
										<div className="info-row-info">
											{pageData.obj.type === 'PRODUCT'
												? this.lang.deduplicationDescriptionProduct
												: this.lang.deduplicationDescription}
										</div>
									</div>
								) : null}
								{hasMatch && !pageData.obj.onlyUpdate ? (
									<div className="admin-info-row admin-info-row-flex">
										<div className="info-row-content">
											<h5>
												{pageData.obj.type === 'PRODUCT'
													? this.lang.onMultiMatchProduct
													: this.lang.onMultiMatch}
											</h5>
											<div className="btn-group">
												<Button
													color={pageData.obj.onMultiMatch === 'new' ? 'green' : 'light-grey'}
													key="new"
													shadow="none"
													className="btn"
													onClick={function () {
														rootData.setMultiMatch('new');
													}}
												>
													{self.lang.createNew}
												</Button>
												<Button
													color={
														pageData.obj.onMultiMatch === 'skip' ? 'green' : 'light-grey'
													}
													key="skip"
													shadow="none"
													className="btn"
													onClick={function () {
														rootData.setMultiMatch('skip');
													}}
												>
													{self.lang.skipRow}
												</Button>
											</div>
											{pageData.obj.onMultiMatch === 'new' && (
												<div className="duplicate-warning">
													<Text size="lg">
														<Icon name="warning" color="red" space="mrl mtm" />
													</Text>
													<Text size="md" color="grey-11" bold>
														{' '}
														{this.lang.createNewWarning}{' '}
													</Text>
												</div>
											)}
										</div>
										<div className="info-row-info">
											{pageData.obj.type === 'PRODUCT'
												? this.lang.multimatchInfoProduct
												: this.lang.multimatchInfo}
											<br />
											<ReactTemplates.elevio sidebar={true} articleId={511}>
												<a>{this.lang.readMore}</a>
											</ReactTemplates.elevio>
										</div>
									</div>
								) : null}
							</div>

							<div className="admin-card">
								<div className="admin-card-top">
									<div className="admin-card-title">{this.lang.reportTitle}</div>
								</div>
								<div className="admin-card-content">
									{React.createElement(ReactTemplates.INPUTS.upSelect, {
										multiple: true,
										className: 'form-control notification-email-input',
										data: tools.AppService.getActiveUsers(),
										defaultValue: rootData.pageData.obj.statusEmail,
										id: function (u) {
											return u.email;
										},
										formatResult: function (item, container, query, escape) {
											if (!item.name) {
												return Tools.$translate('default.add') + ': ' + _.escape(item.email);
											}
											return escape(item.name);
										},
										formatSelection: function (item, container, escape) {
											return (
												escape(item.email) +
												(escape(item.name)
													? '<span style="font-size:10px;"> (' +
													  escape(item.name) +
													  ')</span>'
													: '')
											);
										},
										createSearchChoice: function (term) {
											if (emailRegex.test(term)) {
												return { id: term, email: term };
											} else {
												return null;
											}
										},
										formatNoMatches: function () {
											return Tools.$translate('default.enterEmail');
										},
										// eslint-disable-next-line
										matcher: function (trm, undefined, obj) {
											var nameMatch =
												obj.name && obj.name.toLowerCase().indexOf(trm.toLowerCase()) !== -1;
											var emailMatch =
												obj.email && obj.email.toLowerCase().indexOf(trm.toLowerCase()) !== -1;

											return nameMatch || emailMatch;
										},
										onChange: function (e) {
											var users = tools.AppService.getActiveUsers();
											var values = _.reduce(
												e.target.value,
												function (result, value) {
													// This is an email
													if (isNaN(value)) {
														result.push(value);
													} else {
														var user = _.find(users, { id: parseInt(value) });
														if (user) {
															result.push(user.email);
														}
													}

													return result;
												},
												[]
											);
											rootData.setStatusEmail(values);
										},
										initSelection: function (element, cb) {
											var users = tools.AppService.getActiveUsers();
											var value = _.map(rootData.pageData.obj.statusEmail, function (m) {
												var user = _.find(users, { email: m });
												if (user) {
													return { id: user.email, email: user.email, name: user.name };
												}
												return { id: m, email: m };
											});
											cb(value);
										},
										tools: tools
									})}
								</div>
							</div>
						</div>
					</div>
				);
			}
			case 'review':
				var emptyCells = pageData.obj.columns.reduce(
					(total, column) => total + (column.skip ? 0 : column.emptyRows ?? 0),
					0
				);
				var totalCells = pageData.obj.columns.reduce(
					(total, column) => total + (column.skip ? 0 : pageData.obj.numRows),
					0
				);

				var wrongDataTypeId = pageData.obj.columns.find(value => value.field === 'Client.Id');

				wrongDataTypeId =
					wrongDataTypeId?.sampleValues.filter(value => {
						return isNaN(value);
					}).length ?? 0;

				var issues = this.renderIssues(pageData.obj.columns, emptyCells, wrongDataTypeId);

				var fields = _.chain(pageData.obj.columns).sortBy('skip').map(this.renderFieldPreview).value();

				var contactCustomfieldsActions = _.compact(
					_.map(self.getCustomFields(), function (fieldConf) {
						return self.renderActionCustomfield('contact', fieldConf);
					})
				);

				var clientCustomfieldsActions = _.compact(
					_.map(self.getCustomFields(), function (fieldConf) {
						return self.renderActionCustomfield('client', fieldConf);
					})
				);

				var actionFields = _.map(pageData.obj.fields, function (field) {
					if (field.field.indexOf('.custom_') !== -1) {
						return null;
					}
					var values = [];
					if (field.field === 'Client.UserId') {
						if (field.onlyNew) {
							values.push(<div className="value">{self.lang.onlyNewClients}</div>);
						}
						values.push(<div className="value">{_.pluck(field.value || [], 'name').join(', ')}</div>);
					} else if (
						[
							'Contact.Project',
							'Contact.Category',
							'Client.Project',
							'Client.Category',
							'Contact.Optins',
							'Product.Category'
						].indexOf(field.field) !== -1
					) {
						values.push(<div className="value">{_.pluck(field.value || [], 'name').join(', ')}</div>);
					}
					return self.renderActionItem(
						self.lang.fields[field.field].icon,
						self.lang.fields[field.field].title,
						values
					);
				});

				if (pageData.obj.statusEmail.length) {
					var emailValue = <div className="value">{pageData.obj.statusEmail.join(', ')}</div>;
					actionFields.push(
						this.renderActionItem(
							self.lang.fields['statusReport'].icon,
							self.lang.fields['statusReport'].title,
							emailValue
						)
					);
				}

				if (Tools.FeatureHelper.hasSoftDeployAccess('IMPORT_PREVIEW')) {
					actionFields = actionFields.concat(this.renderImportPreview(rootData));
				}

				return (
					<div id="single-import" key="review-content">
						{topbar}
						<div id="admin-content">
							<div className="admin-card admin-card-review">
								<div className="admin-card-top import-review">
									<div className="admin-card-top-text-wrap">
										<div className="admin-card-title">{this.lang.import.title}</div>
										<div className="admin-card-top-description">{this.lang.import.desc}</div>
									</div>
									<div className="admin-card-top-image-wrap">
										<div className="admin-card-top-image"></div>
									</div>
								</div>
								<div className="admin-card-content centered-border">
									<div className="half-row">
										<div className="half-column">
											<div className="setting-type-header">{this.lang.import.contains.title}</div>
											<div className="setting-type-header-description">
												{pageData.obj.numRows} {self.lang.rowsWith}{' '}
												{pageData.obj.columns.length} {self.lang.columns.toLowerCase()}
											</div>
											<div>{fields}</div>
										</div>
										<div className="half-column">
											<div className="setting-type-header">{this.lang.fileHealth}</div>
											<div className="settings-file-health">
												<div>
													<span className="settings-file-health-percentage">
														{totalCells
															? Math.floor(
																	((totalCells - emptyCells - wrongDataTypeId) /
																		totalCells) *
																		100
															  )
															: 0}
														{'%'}
													</span>
													<span>{this.lang.ofTheFileHasValues}</span>
												</div>
												<div>
													<span className="settings-file-health-percentage">
														{totalCells
															? Math.ceil(
																	((emptyCells + wrongDataTypeId) / totalCells) * 100
															  )
															: 0}
														{'%'}
													</span>
													<span>{this.lang.ofTheFileHasPotentialIssues}</span>
												</div>
											</div>
											{issues?.length ? (
												<div className="potential-issues-wrap">
													<div className="setting-type-header">
														{this.lang.potentialIssues}
													</div>
													<div>{issues}</div>
												</div>
											) : null}

											{actionFields.length > 0 ||
											clientCustomfieldsActions.length > 0 ||
											contactCustomfieldsActions.length > 0 ? (
												<div className="setting-type-header">
													{this.lang.import.returns.title}
												</div>
											) : null}
											{contactCustomfieldsActions.length
												? this.renderActionItem(
														'fa fa-user',
														this.lang.addFieldsToContacts,
														contactCustomfieldsActions
												  )
												: null}
											{clientCustomfieldsActions.length
												? this.renderActionItem(
														'fa fa-home',
														this.lang.addFieldsToClients,
														clientCustomfieldsActions
												  )
												: null}

											<div>{actionFields}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
		}
	};

	render() {
		return <FullScreenPage>{this.renderContent()}</FullScreenPage>;
	}
};
