angular
	.module('domain.activity', [
		'ngRoute',
		'upResources',
		'security.authorization',
		'services.customer',
		'services.templateCacher'
	])
	.config([
		'$stateProvider',
		'$routeGuardProvider',
		'$httpProvider',
		'$upModalProvider',
		function ($stateProvider, $routeGuardProvider, $httpProvider, $upModalProvider) {
			$stateProvider.state('activities', {
				templateUrl: require('App/upsales/common/directives/templates/upListView2.tpl.html?file'),
				url: '/:customerId/activities/',
				controller: 'ListActivities as listViewHelper',
				section: 'sale',
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					listViews: [
						'AppService',
						function (AppService) {
							return AppService.loadedPromise.then(function () {
								return AppService.getListViews('activity');
							});
						}
					],
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.placeholder(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS);
						}
					]
				}
			});

			$upModalProvider
				.modal('editActivity', {
					templateUrl: require('App/upsales/domain/activity/views/editActivity.html?file'),
					style: 'form default dynamic initially-hidden',
					controller: 'EditActivity as EditActivity',
					invisibleCurtain: true,
					rememberHeight: true,
					resolve: {
						meta: function ($modalParams, EditActivityMeta) {
							return EditActivityMeta($modalParams);
						}
					}
				})
				.modal('CreateActivityMulti', {
					template: '<div></div>',
					controller: function ($scope, $element, $modalParams) {
						window.SetupComponent($scope, window.CreateActivity, $element[0], $modalParams, {
							redux: false,
							modal: true
						});
					},
					style: 'confirm',
					resolve: {
						meta: function ($modalParams, CreateActivityMultiMeta) {
							return CreateActivityMultiMeta($modalParams);
						}
					}
				})
				.modal('closeActivityMulti', {
					templateUrl: require('App/upsales/domain/activity/views/closeActivityMulti.html?file'),
					style: 'confirm default',
					controller: 'CloseActivityMulti'
				})
				.modal('updateActivityMulti', {
					templateUrl: require('App/upsales/domain/activity/views/updateActivityMulti.html?file'),
					style: 'form-md default',
					controller: 'UpdateActivityMulti',
					resolve: {
						meta: function ($modalParams, UpdateActivityMultiMeta) {
							return UpdateActivityMultiMeta($modalParams);
						}
					}
				})
				.modal('selectOpportunity', {
					templateUrl: require('App/upsales/domain/activity/views/selectOpportunity.html?file'),
					style: 'confirm'
				})
				.modal('moveOrCloseActivity', {
					templateUrl: require('App/upsales/domain/activity/views/moveOrCloseActivity.html?file'),
					style: 'info confirm',
					controller: 'MoveOrCloseActivity'
				});
		}
	]);
