import async from 'async';
import openModal from 'App/services/Modal';
import history from 'App/pages/routes/history';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

angular.module('services.script', []).service('ScriptService', [
	'$rootScope',
	'$stateParams',
	'$translate',
	'$upModal',
	'$state',
	'$q',
	'Account',
	'Contact',
	'Campaign',
	'Activity',
	'Appointment',
	'User',
	'Order',
	'Opportunity',
	'Agreement',
	'AppService',
	'NotificationService',
	function (
		$rootScope,
		$stateParams,
		$translate,
		$upModal,
		$state,
		$q,
		Account,
		Contact,
		Campaign,
		Activity,
		Appointment,
		User,
		Order,
		Opportunity,
		Agreement,
		AppService,
		NotificationService
	) {
		// these vars will be declared inside the function to avoid them being overwritten
		var blockedVars =
			'var AppService, $scope, $stateParams, $upModal, $state,' +
			'app, angulartics,' +
			'Account, Contact, Campaign, Activity, Appointment, User, Order, Opportunity, Agreement, NotificationService, $translate, angular,' +
			'open, warning, notify, get, runScript,' +
			'obj, objName, input, scripts, script, scriptCb, callback, defer, $q;';

		var getById = function (type, id, cb) {
			var method = 'get';
			if (!id) {
				return cb('no id provided' + id);
			}
			if (typeof id === 'object') {
				method = 'find';
			}
			type.customer($stateParams.customerId)
				[method](id)
				.then(function (res) {
					res = res.data;
					return cb(null, res);
				})
				.catch(function () {
					return cb('no ' + type + ' with id ' + id);
				});
		};

		var saveObj = function (type, obj, cb) {
			var method = 'save';
			type.customer($stateParams.customerId)
				[method](obj)
				.then(function (res) {
					res = res.data;
					return cb(null, res);
				})
				.catch(function () {
					return cb('failed saving');
				});
		};

		// a way to fetch other upsales data
		var Get = function () {
			return {
				account: function (id, cb) {
					getById(Account, id, cb);
				},
				client: function (id, cb) {
					getById(Account, id, cb);
				},
				contact: function (id, cb) {
					getById(Contact, id, cb);
				},
				campaign: function (id, cb) {
					getById(Campaign, id, cb);
				},
				activity: function (id, cb) {
					getById(Activity, id, cb);
				},
				appointment: function (id, cb) {
					getById(Appointment, id, cb);
				},
				order: function (id, cb) {
					getById(Order, id, cb);
				},
				opportunity: function (id, cb) {
					getById(Opportunity, id, cb);
				},
				agreement: function (id, cb) {
					getById(Agreement, id, cb);
				},
				user: function (id, cb) {
					getById(User, id, cb);
				}
			};
		};

		// a way to save other upsales data
		var Save = function () {
			return {
				account: function (obj, cb) {
					saveObj(Account, obj, cb);
				},
				client: function (obj, cb) {
					saveObj(Account, obj, cb);
				},
				contact: function (obj, cb) {
					saveObj(Contact, obj, cb);
				},
				campaign: function (obj, cb) {
					saveObj(Campaign, obj, cb);
				},
				activity: function (obj, cb) {
					saveObj(Activity, obj, cb);
				},
				appointment: function (obj, cb) {
					saveObj(Appointment, obj, cb);
				},
				order: function (obj, cb) {
					saveObj(Order, obj, cb);
				},
				opportunity: function (obj, cb) {
					saveObj(Opportunity, obj, cb);
				},
				agreement: function (obj, cb) {
					saveObj(Agreement, obj, cb);
				},
				user: function (obj, cb) {
					saveObj(User, obj, cb);
				}
			};
		};

		// a way to navigate
		// this is also used by uiElements
		var Open = function () {
			const openClient = function (id, opts, params = {}) {
				return $state.go('account.' + (opts ? opts : 'dashboard'), {
					id: id,
					customerId: $stateParams.customerId,
					...params
				});
			};
			return {
				account: openClient,
				company: openClient,
				client: openClient,
				salesboard: function (id) {
					history.push(`/salesboard/${id}`);
				},
				accounts: function (id) {
					return $state.go('accounts', { id: id, customerId: $stateParams.customerId });
				},
				campaigns: function (id) {
					return $state.go(!id ? 'react-root-campaigns' : 'campaign.dashboard', {
						id: id,
						customerId: $stateParams.customerId
					});
				},
				forms: function (id) {
					return $state.go('forms', { id: id, customerId: $stateParams.customerId });
				},
				activities: function (id) {
					return $state.go('activities', { id: id, customerId: $stateParams.customerId });
				},
				calendar: function (id) {
					return $state.go('react-root-calendar', { id: id, customerId: $stateParams.customerId });
				},
				contact: function (id, opts) {
					return $state.go('contact.' + (opts ? opts : 'dashboard'), {
						id: id,
						customerId: $stateParams.customerId
					});
				},
				campaign: function (id, opts) {
					return $state.go('campaign.' + (opts ? opts : 'dashboard'), {
						id: id,
						customerId: $stateParams.customerId
					});
				},
				form: function (id, opts) {
					return $state.go('form.' + (opts ? opts : 'dashboard'), {
						id: id,
						customerId: $stateParams.customerId
					});
				},
				activity: function (opts) {
					var params = {};
					opts = opts || {};
					if (typeof opts === 'number') {
						opts.id = opts;
					}
					params.id = opts.id || null;
					$upModal.open('editActivity', params);
				},
				appointment: function (opts) {
					var params = {};
					opts = opts || {};
					if (typeof opts === 'number') {
						opts.id = opts;
					}
					params.id = opts.id || null;
					openEditAppointment(params);
				}
			};
		};

		// a way to create
		// this is also used by uiElements
		var Create = function () {
			return {
				activity: function (opts) {
					var params = {};
					params.activity = opts || {};
					$upModal.open('editActivity', params);
				},
				appointment: function (opts) {
					var params = {};
					params.appointment = opts || {};
					openEditAppointment(params);
				},
				opportunity: opts => {
					const params = {};
					params.type = 'opportunity';
					params.order = opts || {};
					const openOpportunityModal = $('#edit-order-modal').length;
					if (openOpportunityModal) {
						$rootScope.$broadcast('opportunity:close');
						const unregisterDoneListener = $rootScope.$on('opportunity:close:done', () => {
							$upModal.open('editOrder', params);
							unregisterDoneListener();
						});
					} else {
						$upModal.open('editOrder', params);
					}
				}
			};
		};

		// some kind of alert
		var warning = function (msg) {
			msg = msg !== undefined ? msg : '';

			if (msg && typeof msg === 'object') {
				msg = {
					icon: msg.icon || 'fa-info-circle',
					title: msg.title || 'default.info',
					body: msg.msg
				};
			} else {
				msg = {
					icon: 'fa-info-circle',
					title: 'default.info',
					body: msg
				};
			}
			if (Tools?.FeatureHelper?.hasSoftDeployAccess?.('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					headerIcon: msg?.icon || 'info-circle',
					confirmButtonText: 'alertModal.defaultConfirm',
					...msg
				});
				return;
			}
			$upModal.open('warningConfirm', msg);
		};

		// a function to send notifications
		var notify = function (msg) {
			msg = msg !== undefined ? msg : '';
			if (msg && typeof msg === 'object') {
				msg = {
					style: msg.style || NotificationService.style.INFO,
					icon: msg.icon || 'info',
					title: msg.title || 'default.info',
					body: msg.msg
				};
			} else {
				msg = {
					style: NotificationService.style.INFO,
					icon: 'info',
					title: 'default.info',
					body: msg
				};
			}
			NotificationService.addNotification(msg);
		};

		var runScript = function (objName, input, scripts, isCallbackScript) {
			var defer = $q.defer();
			if (!scripts || !scripts.length) {
				defer.resolve();
				return defer.promise;
			}

			async.eachSeries(
				scripts,
				function (script, scriptCb) {
					if (!script || !script.code || !script.active) {
						return scriptCb();
					}

					// the upsales object for user
					/* eslint-disable */
					var upsales = {
						me: angular.copy(AppService.getSelf()),
						get: new Get(),
						save: new Save(),
						open: new Open(),
						create: new Create(),
						notify: notify,
						warning: warning
					};
					/* eslint-enable */

					// run the code
					(function (obj, cb) {
						// this is the function which will run the script
						// its parameter is the objName
						// cb will also available in the script
						var variables = [objName];
						var values = ['obj'];

						if (objName === 'upsales') {
							variables.push('registerListener');
							values.push('Tools.ScriptFramework.export.registerListener');
							// TODO: implement this
							// } else if(['order'].indexOf(objName) !== -1) {
							// 	variables.push('addElement');
							// 	values.push('Tools.ScriptFramework.export.addElement');
						}

						try {
							eval(
								'(function (' +
									variables.join(',') +
									') {' +
									(isCallbackScript ? '' : 'var cb;\n') + // if not a callback script, make a local cb
									blockedVars +
									'\n' + // make local instances of blocked vars
									script.code +
									'\n' +
									'})(' +
									values.join(',') +
									');'
							);
						} catch (err) {
							console.error(err);
						}
						// if this isnt a callback script, we must manually continue the async loop
						// in callback scripts the callback shall be executed in script.code
						if (!isCallbackScript) {
							return cb();
						}
					})(input, function (err) {
						if (err) {
							NotificationService.addNotification({
								style: NotificationService.style.ERROR,
								icon: 'times',
								title: 'saveError.' + objName,
								body: err
							});
						}
						return scriptCb(err);
					});
				},
				function (err) {
					if (err) {
						defer.reject(err);
					} else {
						defer.resolve();
					}
					return;
				}
			);
			return defer.promise;
		};

		var getScripts = function () {
			return AppService.getScripts() || [];
		};

		var instance = {
			order: {
				init: function (order) {
					var script = _.where(getScripts(), { type: 'order_edit', active: true });
					return runScript('order', order, script);
				},
				save: function (order) {
					var script = _.where(getScripts(), { type: 'order_save' });
					return runScript('order', order, script, true);
				}
			},

			agreement: {
				init: function (agreement) {
					var script = _.where(getScripts(), { type: 'agreement_edit', active: true });
					return runScript('agreement', agreement, script);
				},
				save: function (agreement) {
					var script = _.where(getScripts(), { type: 'agreement_save' });
					return runScript('agreement', agreement, script, true);
				}
			},

			activity: {
				init: function (activity) {
					var script = _.where(getScripts(), { type: 'activity_edit', active: true });
					return runScript('activity', activity, script);
				},
				save: function (activity) {
					var script = _.where(getScripts(), { type: 'activity_save' });
					return runScript('activity', activity, script, true);
				}
			},

			account: {
				edit: function (account) {
					var script = _.where(getScripts(), { type: 'client_edit' });
					return runScript('account', account, script);
				},
				open: function (account) {
					var script = _.where(getScripts(), { type: 'client_open' });
					return runScript('account', account, script);
				},
				openContacts: function (accountContacts) {
					var script = _.where(getScripts(), { type: 'client_contacts_open' });
					return runScript('accountContacts', accountContacts, script);
				},
				openDashboard: function (account) {
					var script = _.where(getScripts(), { type: 'client_dashboard_open' });
					return runScript('account', account, script);
				},
				save: function (account) {
					var script = _.where(getScripts(), { type: 'client_save' });
					return runScript('account', account, script, true);
				}
			},

			contact: {
				open: function (contact) {
					var script = _.where(getScripts(), { type: 'contact_open' });
					return runScript('contact', contact, script);
				},
				save: function (contact) {
					var script = _.where(getScripts(), { type: 'contact_save' });
					return runScript('contact', contact, script, true);
				},
				edit: function (contact) {
					var script = _.where(getScripts(), { type: 'contact_edit' });
					return runScript('contact', contact, script, true);
				}
			},

			salesboard: {
				open: function () {
					var script = _.where(getScripts(), { type: 'salesboard_open' });
					return runScript('salesboard', null, script);
				}
			},

			upsales: {
				init: function () {
					var script = _.where(getScripts(), { type: 'upsales_init', active: true });
					return runScript('upsales', null, script);
				}
			},

			appointment: {
				init: function (appointment) {
					var script = _.where(getScripts(), { type: 'appointment_edit', active: true });
					return runScript('appointment', appointment, script);
				},
				save: function (appointment) {
					var script = _.where(getScripts(), { type: 'appointment_save' });
					return runScript('appointment', appointment, script, true);
				}
			},

			flash: {
				init: function (activity) {
					var script = _.where(getScripts(), { type: 'flash_edit', active: true });
					return runScript('activity', activity, script);
				},
				save: function (activity) {
					var script = _.where(getScripts(), { type: 'flash_save' });
					return runScript('activity', activity, script, true);
				}
			},

			mail: {
				init: function (mail) {
					var script = _.where(getScripts(), { type: 'mail_edit', active: true });
					return runScript('mail', mail, script);
				}
			},

			project: {
				init: function (project) {
					var script = _.where(getScripts(), { type: 'project_edit', active: true });
					return runScript('project', project, script);
				},
				save: function (project) {
					var script = _.where(getScripts(), { type: 'project_save' });
					return runScript('project', project, script, true);
				}
			},

			open: new Open(),
			create: new Create()
		};

		return instance;
	}
]);
