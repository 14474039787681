import React, { Component } from 'react';
import AdminHeader from 'Components/Admin/AdminHeader';
import { Button } from '@upsales/components';
import _ from 'lodash';
import t from 'Components/Helpers/translate';
import Link, { Entity } from 'App/resources/Model/Links';

type Props = {
	rootData: {
		editDynamiclink: (link?: Link) => void;
		removeLink: (link: Link) => void;
		pageData: {
			links: Link[];
		};
	};
};

type Sort = 'link' | 'type';

type State = { sort: Sort; descending: boolean };

class DynamicLinks extends Component<Props, State> {
	lang: { [key: string]: string | { [key in Entity]?: string } };
	constructor(p: Props) {
		super(p);
		this.lang = {
			dynamiclinks: t('default.dynamiclinks'),
			dynamiclinksInfo: t('admin.dynamiclinksInfo'),
			type: t('default.type'),
			link: t('default.link'),
			newDynamiclink: t('admin.newDynamiclink'),
			entity: {
				client: t('default.client'),
				contact: t('default.contact'),
				appointment: t('default.appointment'),
				activity: t('default.activity'),
				order: t('default.order'),
				sidebar: t('default.sidebar')
			}
		};
		this.state = { descending: false, sort: 'type' };
	}

	updateSort(sort: Sort) {
		const descending = sort === this.state.sort && !this.state.descending;
		this.setState({
			sort: sort,
			descending: descending
		});
	}

	getSortClass(name: string) {
		const sort = this.state.sort;
		const descending = this.state.descending;

		if (sort !== name) {
			return 'fa fa-sort';
		} else {
			return descending ? 'fa fa-sort-alpha-desc' : 'fa fa-sort-alpha-asc';
		}
	}

	removeLink(link: Link, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		e.stopPropagation();
		this.props.rootData.removeLink(link);
	}

	render() {
		const rootData = this.props.rootData;

		let chain = _.chain(rootData.pageData.links).sortBy(link => {
			if (this.state.sort === 'type') {
				return (this.lang.entity as { [key in Entity]: string })[link.entity];
			} else {
				return link.value;
			}
		});

		if (this.state.descending) {
			chain = chain.reverse();
		}

		const links = chain
			.map((link: Link, i) => {
				let rowBorder = undefined;
				if (link.color) {
					rowBorder = {
						borderLeft: '2px solid #' + link.color
					};
				}

				return (
					<tr
						key={'link-' + i}
						className="clickable two-lines"
						onClick={() => rootData.editDynamiclink(link)}
					>
						<td className="text-grey no-padding-right" style={rowBorder}>
							{link.icon ? (
								<b style={{ color: '#' + link.color }} className={'fa ' + link.icon}></b>
							) : null}
						</td>

						<td>{(this.lang.entity as { [key in Entity]: string })[link.entity]}</td>

						<td>
							<div className="ellipsis-wrap" style={{ height: '100%' }}>
								<div className="ellipsis" style={{ top: '5px' }}>
									{link.value}
								</div>
								<div className="subtitle ellipsis" style={{ bottom: 0 }}>
									{link.href}
								</div>
							</div>
						</td>

						<td className="admin-row-tools-wrap">
							<div className="admin-row-tools">
								<button
									className="up-btn btn-link btn-grey delete-trigger"
									onClick={e => this.removeLink(link, e)}
								>
									<b className="fa fa-trash-o"></b>
								</button>
							</div>
						</td>
					</tr>
				);
			})
			.value();

		return (
			<div id="admin-page-dynamiclinks">
				<AdminHeader
					title={this.lang.dynamiclinks as string}
					description={this.lang.dynamiclinksInfo as string}
					image="dynamic-links.svg"
					articleId={454}
				/>

				<div id="admin-content">
					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{this.lang.dynamiclinks}</span>
							<div className="pull-right">
								<Button onClick={() => rootData.editDynamiclink()} size="sm">
									{this.lang.newDynamiclink}
								</Button>
							</div>
						</div>

						<table>
							<thead>
								<tr>
									<th style={{ width: '40px' }}></th>
									<th
										onClick={() => this.updateSort('type')}
										style={{ width: '150px' }}
										className={'sortable' + (this.state.sort === 'type' ? ' active' : '')}
									>
										{this.lang.type} <i className={this.getSortClass('type')} />
									</th>
									<th
										onClick={() => this.updateSort('link')}
										className={'sortable' + (this.state.sort === 'link' ? ' active' : '')}
									>
										{this.lang.link} <i className={this.getSortClass('link')} />
									</th>
									<th style={{ width: '60px' }}></th>
								</tr>
							</thead>
							<tbody>{links}</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

export default DynamicLinks;
