import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { rotateByOffset } from 'App/babel/store/helpers/array';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { momentFiscalYear } from 'App/babel/helpers/moment';
import { Icon, Tooltip } from '@upsales/components';

import TotalTableRow from './TotalTableRow';
import { CurrencyFormat, NumberFormat } from 'App/babel/utils/numberFormat';

class QuotaTable extends Component {
	constructor(props) {
		super(props);

		const t = getAngularModule('$translate');
		const metadata = getAngularModule('AppService').getMetadata();
		this.t = t;
		this.brokenFiscalYearEnabled = metadata.params.brokenFiscalYearEnabled;
		this.brokenFiscalYearOffset = metadata.params.brokenFiscalYearOffset;

		const months = [
			t('date.january'),
			t('date.february'),
			t('date.march'),
			t('date.april'),
			t('date.may'),
			t('date.june'),
			t('date.july'),
			t('date.august'),
			t('date.september'),
			t('date.october'),
			t('date.november'),
			t('date.december')
		];

		this.lang = {
			user: t('default.user'),
			total: t('default.total'),
			months: this.brokenFiscalYearEnabled ? rotateByOffset(months, this.brokenFiscalYearOffset) : months,
			masterCurrency: t('admin.masterCurrency')
		};
	}

	saveQuota(quota) {
		this.props.rootData.updateQuota(quota);
	}

	renderUserTableHeader() {
		return (
			<thead>
				<tr className="admin-table-header">
					<th className="admin-table-cell">{this.lang.user}</th>
				</tr>
			</thead>
		);
	}

	renderUserTableRow(user, firstQuota) {
		const t = this.t;
		const currencies = Tools.AppService.getMetadata().customerCurrencies;
		const masterCurrency = _.find(currencies, { masterCurrency: true });
		const currentRate = firstQuota.currency ? currencies.find(c => c.iso === firstQuota.currency).rate : null;
		if (this.props.rootData.pageData.showCurrency && currencies.length) {
			return (
				<tr key={user.id} className={currencies.length > 1 ? 'two-lines' : null}>
					<td className="QuotaTableUserTableCell">
						<div className="ellipsis">{user.name}</div>
						<div className="QuotaTableUserTableRow">
							{currencies.length > 1 ? (
								<div className="inline-select">
									<select
										value={firstQuota.currency || masterCurrency.iso}
										onChange={this.switchCurrency.bind(this, user.id)}
									>
										{_.map(currencies, currency => {
											return (
												<option key={currency.iso} value={currency.iso}>
													{currency.iso +
														(currency.masterCurrency
															? ' - ' + this.lang.masterCurrency
															: '')}
												</option>
											);
										})}
									</select>
									<i className="fa fa-caret-down" />
								</div>
							) : null}
							{firstQuota.currency && firstQuota.currency !== masterCurrency.iso ? (
								<div className="QuotaTableUserTableRow__Text" color="bright-blue">
									<Tooltip
										title={`1 ${firstQuota.currency} = ${(1 / firstQuota.currencyRate).toFixed(
											2
										)} ${masterCurrency.iso}`}
									>
										{`1 ${firstQuota.currency} = ${(1 / firstQuota.currencyRate).toFixed(2)} ${
											masterCurrency.iso
										}`}{' '}
									</Tooltip>
									{firstQuota.currencyRate !== currentRate ? (
										<Tooltip
											title={t('admin.salesQuota.updateCurrencyRate', { rate: 1 / currentRate })}
										>
											<Icon
												className="QuotaTableUserTableRow__Icon"
												onClick={() => this.updateCurrencyRate(user.id)}
												name="refresh"
											></Icon>
										</Tooltip>
									) : null}
								</div>
							) : null}
						</div>
					</td>
				</tr>
			);
		}

		return (
			<tr key={user.id}>
				<td className="QuotaTableUserTableCell">
					<div className="ellipsis">{user.name}</div>
				</td>
			</tr>
		);
	}

	updateCurrencyRate(userId) {
		const userQuotas = _.find(this.props.rootData.pageData.userQuotas, userQuota => {
			return userQuota.user.id === userId;
		});
		const currencies = Tools.AppService.getMetadata().customerCurrencies;
		const currency = _.find(currencies, { iso: userQuotas.quotas[0].currency });

		const updates = userQuotas.quotas.map(quota => {
			const update = {};
			const oldRate = quota.currencyRate;
			update.currencyRate = currency.rate;
			update.value = (quota.value * oldRate) / update.currencyRate;
			const newQuota = Object.assign({}, quota, update);
			return newQuota;
		});

		this.props.rootData.updateQuota(updates);
	}

	switchCurrency(userId, e) {
		const value = e.target.value;
		const userQuotas = _.find(this.props.rootData.pageData.userQuotas, userQuota => {
			return userQuota.user.id === userId;
		});
		const currencies = Tools.AppService.getMetadata().customerCurrencies;
		const currency = _.find(currencies, { iso: value });

		const updates = _.reduce(
			userQuotas.quotas,
			(result, quota) => {
				if (quota.currency !== value) {
					const update = {};
					update.currency = value;

					if (currency) {
						update.currencyRate = currency.rate;
					}

					const newQuota = Object.assign({}, quota, update);
					result.push(newQuota);
				}
				return result;
			},
			[]
		);

		this.props.rootData.updateQuota(updates);
	}

	renderInlineEdit(quota, userQuota, index) {
		const { showCurrency, showPreviousYear, masterCurrency, quotaType } = this.props.rootData.pageData;
		const currencyRate = quota.currencyRate;
		const quotaCurrency = showCurrency ? quota.currency || masterCurrency?.iso : null;

		let formattedSubtitle = '';
		if (showPreviousYear && userQuota.previous) {
			formattedSubtitle = showCurrency
				? (userQuota.previous[index] || 0) * currencyRate
				: userQuota.previous[index] || 0;

			if (showCurrency) {
				formattedSubtitle = Tools.$filter('currencyFormat')(Math.round(formattedSubtitle), quotaCurrency);
			} else {
				formattedSubtitle = Tools.$filter('numberFormat')(Math.round(formattedSubtitle));
			}
		}
		return (
			<ReactTemplates.admin.quotaInput
				field={quotaType}
				value={quota}
				onChange={quota => {
					this.saveQuota(quota);
				}}
				buttonAction={(userId, value) => {
					this.useForAll(index, userId, value);
				}}
				buttonValue={quota.user.id}
				tools={Tools}
				showSubtitle={showPreviousYear}
				subtitle={formattedSubtitle}
				showCurrency={showCurrency}
				quotaCurrency={quotaCurrency}
				max={1699605031}
				currencyRate={currencyRate}
				isSaving={quota.isSaving}
			/>
		);
	}

	useForAll(index, userId, value) {
		const { userQuotas, quotaType } = this.props.rootData.pageData;

		const userQuota = _.find(userQuotas, userQuota => {
			return userQuota.user.id === userId;
		});

		const updates = _.reduce(
			userQuota.quotas.slice(index),
			(result, quota) => {
				if (quota[quotaType] !== value) {
					const update = {};
					update[quotaType] = value;
					const newQuota = Object.assign({}, quota, update);
					result.push(newQuota);
				}
				return result;
			},
			[]
		);

		this.props.rootData.updateQuota(updates);
	}

	renderQuotaTableHeader() {
		const style = {};
		style.width = '130px';

		const year = this.props.rootData.pageData.year;
		const fiscalMoment = momentFiscalYear();
		const monthIterator = fiscalMoment().startOf('fiscalYear').fiscalYear(year);

		return (
			<thead>
				<tr className="admin-table-header">
					{_.map(this.lang.months, (month, index) => {
						const year = monthIterator.year();
						monthIterator.add(1, 'month');

						const text = this.brokenFiscalYearEnabled ? `${month} (${year})` : month;

						return (
							<th className="admin-table-cell" key={index} style={style}>
								{text}
							</th>
						);
					})}
				</tr>
			</thead>
		);
	}

	renderQuotaTableColumn(quota, userQuota, monthIndex) {
		return <td key={monthIndex}>{this.renderInlineEdit(quota, userQuota, monthIndex)}</td>;
	}

	renderTotalTableHeader() {
		return (
			<thead>
				<tr className="admin-table-header">
					<th className="admin-table-cell">{this.lang.total}</th>
				</tr>
			</thead>
		);
	}

	addTotalRows(
		userTableRows,
		totalTableRows,
		quotaTableRows,
		totalBudgetCurrentYear,
		totalResultPreviousYear,
		totalMonths,
		totalMonthsPrevious
	) {
		const { showCurrency, showPreviousYear, defaultCurrency, totalTitle, year, resultTitle } =
			this.props.rootData.pageData;
		const currencies = Tools.AppService.getMetadata().customerCurrencies;

		userTableRows.push(
			<tr key={'total-row'} className={currencies.length > 1 ? 'total-row two-lines' : 'total-row'}>
				<td>
					<span className="big-value">
						{totalTitle || ''} {year}
					</span>
					{showPreviousYear && (
						<span className="small-value">
							{resultTitle || ''} {moment({ year }).subtract(1, 'year').format('YYYY')}
						</span>
					)}
				</td>
			</tr>
		);

		quotaTableRows.push(
			<tr key="total-row" className={showPreviousYear ? 'total-row two-lines' : 'total-row'}>
				{totalMonths.map((value, index) => {
					let formattedValue;
					let formattedPreviousYear;
					if (showCurrency) {
						const currencyFormat = new CurrencyFormat(defaultCurrency.iso);
						formattedValue = currencyFormat.short(Math.round(value || 0));
						formattedPreviousYear = currencyFormat.short(Math.round(totalMonthsPrevious[index] || 0));
					} else {
						const numberFormat = new NumberFormat();
						formattedValue = numberFormat.short(Math.round(value || 0));
						formattedPreviousYear = numberFormat.short(Math.round(totalMonthsPrevious[index] || 0));
					}
					return (
						<td key={index}>
							<span className="big-value">{formattedValue}</span>
							{showPreviousYear ? <span className="small-value">{formattedPreviousYear}</span> : null}
						</td>
					);
				})}
			</tr>
		);

		totalTableRows.push(
			<TotalTableRow
				key="total-row"
				className="total-row"
				totalSum={totalBudgetCurrentYear}
				previousSum={totalResultPreviousYear}
				showCurrency={showCurrency}
				defaultCurrency={defaultCurrency}
				showPreviousYear={showPreviousYear}
			/>
		);
	}

	render() {
		var quotaTableHeader = this.renderQuotaTableHeader();
		var quotaTableRows = [];
		var userTableHeader = this.renderUserTableHeader();
		var userTableRows = [];
		var totalTableHeader = this.renderTotalTableHeader();
		var totalTableRows = [];

		const { showCurrency, userQuotas, quotaType, showPreviousYear, tableLoading, defaultCurrency } =
			this.props.rootData.pageData;

		var totalBudgetCurrentYear = 0;
		var totalResultPreviousYear = 0;
		const totalMonths = [];
		const totalMonthsPrevious = [];

		_.forEach(userQuotas, userQuota => {
			userTableRows.push(this.renderUserTableRow(userQuota.user, userQuota.quotas[0]));

			var quotaTableCols = [];
			var totalSum = 0;
			var previousSum = 0;
			_.forEach(userQuota.quotas, (quota, monthIndex) => {
				quotaTableCols.push(this.renderQuotaTableColumn(quota, userQuota, monthIndex));
				let rate = 1;
				if (quota.hasOwnProperty('currency')) {
					rate = quota.currency === defaultCurrency.iso ? quota.currencyRate : defaultCurrency.rate;
				}
				totalSum += quota[quotaType] * rate;
				const previous = showPreviousYear && userQuota.previous ? userQuota.previous[monthIndex] * rate : 0;
				previousSum += previous;
				totalBudgetCurrentYear += quota[quotaType] * rate;
				totalResultPreviousYear += previous;
				if (!totalMonths[monthIndex]) {
					totalMonths[monthIndex] = 0;
				}
				if (!totalMonthsPrevious[monthIndex]) {
					totalMonthsPrevious[monthIndex] = 0;
				}
				totalMonths[monthIndex] += quota[quotaType] * rate;
				totalMonthsPrevious[monthIndex] +=
					showPreviousYear && userQuota.previous ? userQuota.previous[monthIndex] : 0;
			});
			quotaTableRows.push(<tr key={userQuota.user.id}>{quotaTableCols}</tr>);
			totalTableRows.push(
				<TotalTableRow
					key={userQuota.user.id}
					totalSum={totalSum}
					previousSum={previousSum}
					showCurrency={showCurrency}
					defaultCurrency={defaultCurrency}
					showPreviousYear={showPreviousYear}
				/>
			);
		});

		this.addTotalRows(
			userTableRows,
			totalTableRows,
			quotaTableRows,
			totalBudgetCurrentYear,
			totalResultPreviousYear,
			totalMonths,
			totalMonthsPrevious
		);

		var userTable = (
			<div className="quota-user-table-wrap">
				<table className={'quota-user-table' + (tableLoading ? ' hide' : '')} key="user-table">
					{userTableHeader}
					<tbody>{userTableRows}</tbody>
				</table>
			</div>
		);

		var quotaTable = (
			<div
				className="quota-data-table-wrap"
				ref={r => {
					this._dataTable = r;
				}}
			>
				<table className={'quota-data-table' + (tableLoading ? ' hide' : '')} key="data-table">
					{quotaTableHeader}
					<tbody>{quotaTableRows}</tbody>
				</table>
			</div>
		);

		var totalTable = (
			<div className="quota-total-table-wrap">
				<table className={'quota-total-table' + (tableLoading ? ' hide' : '')} key="total-table">
					{totalTableHeader}
					<tbody>{totalTableRows}</tbody>
				</table>
			</div>
		);

		return (
			<div className="table-wrapper">
				{userTable}
				{quotaTable}
				{totalTable}
			</div>
		);
	}
}

QuotaTable.propTypes = {
	rootData: PropTypes.shape({
		updateQuota: PropTypes.func.isRequired,
		pageData: PropTypes.shape({
			showCurrency: PropTypes.bool,
			userQuotas: PropTypes.arrayOf(PropTypes.object),
			showPreviousYear: PropTypes.bool,
			masterCurrency: PropTypes.object,
			quotaType: PropTypes.string,
			defaultCurrency: PropTypes.object,
			tableLoading: PropTypes.bool,
			totalTitle: PropTypes.string,
			year: PropTypes.string,
			resultTitle: PropTypes.string
		})
	})
};

export default QuotaTable;
