import React, { useEffect } from 'react';
import CriteriaRowWrapper from '../../../CriteriaRowWrapper';
import IndustryCPVDrilldown from './Drilldown';
import T from 'Components/Helpers/translate';
import './IndustryCPV.scss';
import { Button, Flex } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

export interface IndustryCPVInputProps {
	registerDrilldown: (filterName: string, component: React.ComponentType<any>) => void;
	toggleDrilldown: (filterName: string) => void;
	hasOpenDrilldown: boolean;
	filter: {
		filterName: string;
		value: string[];
		type: string;
		cpvCodes?: string;
	};
	onChange: (filterName: string, filter: any) => void;
	selectData: {
		[key: string]: Promise<{ data: any[]; metadata: { total: number } }>;
	};
	locked?: boolean;
}

const IndustryCPVInput: React.FC<IndustryCPVInputProps> = ({
	registerDrilldown,
	toggleDrilldown,
	hasOpenDrilldown,
	filter,
	onChange,
	selectData,
	locked
}) => {
	const classes = new BemClass('IndustryCPVInput');
	const lang = {
		lessOptions: T('default.lessOptions'),
		moreOptions: T('default.moreOptions')
	};

	useEffect(() => {
		const DrilldownWithProps = (props: any) => (
			<IndustryCPVDrilldown
				{...props}
				selectData={selectData}
				onChange={(filterName: string, newFilter: any) => {
					// Ensure we never pass non-CPV codes in the value array
					// When the table in creditsafe is fixed and only contains correct CPV-codes,
					// this is probably not needed anymore.
					const sanitizedFilter = {
						...newFilter,
						value: Array.isArray(newFilter.value)
							? newFilter.value.filter((v: string) => /^\d{8}(-\d)?$/.test(v))
							: []
					};
					onChange(filterName, sanitizedFilter);
				}}
			/>
		);
		registerDrilldown(filter.filterName, DrilldownWithProps);
	}, [filter.filterName, registerDrilldown, selectData]);

	return (
		<CriteriaRowWrapper rowFlex={true} className={classes.b()} max800w={false}>
			<Flex gap="u2" alignItems="center">
				<Button
					className={classes.elem('alternative-button').b()}
					color="bright-blue"
					type="link"
					onClick={() => toggleDrilldown(filter.filterName)}
				>
					{hasOpenDrilldown ? lang.lessOptions : lang.moreOptions}
				</Button>
			</Flex>
		</CriteriaRowWrapper>
	);
};

export default IndustryCPVInput;
