import Role from './Model/Role';
import ResourceTyped from 'Resources/ResourceTyped';

export type Dashboard = {
	id: number;
	isFavorite: boolean;
	roles: Optional<Role, 'userEditable' | 'userRemovable'>[];
	useNext: boolean;
	space: string;
	title: string;
	roleIds?: number[];
};
class LookerDashboard extends ResourceTyped<Dashboard> {
	constructor() {
		super('looker/dashboards');
	}
}

const LookerDashboardResource = new LookerDashboard();

export default LookerDashboardResource;
