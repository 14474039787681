import { globalTracker } from 'App/babel/helpers/Tracker';
import logError from 'App/babel/helpers/logError';
import entityName from 'Components/Helpers/EntityName';
import openModal from 'App/services/Modal';
import { countParticipants } from 'App/services/SegmentService';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';
import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';
import ScoreSum from 'App/resources/ScoreSum';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';
import history from 'App/pages/routes/history';
import openNewSegmentModal from 'App/components/NewSegment/NewSegmentModal';

angular.module('domain.segment').controller('EditSegment', [
	'AppService',
	'$translate',
	'$state',
	'$filter',
	'$location',
	'$scope',
	'Contact',
	'RequestBuilder',
	'$upModal',
	'MailCampaign',
	'NotificationService',
	'$q',
	'MailTemplate',
	'$stateParams',
	'FilterHelper',
	'Segment',
	'meta',
	'Flow',
	'Form',
	'avatarService',
	'ListViewService',
	'MultiRunnerActions',
	'FeatureHelper',
	function (
		AppService,
		$translate,
		$state,
		$filter,
		$location,
		$scope,
		Contact,
		RequestBuilder,
		$upModal,
		MailCampaign,
		NotificationService,
		$q,
		MailTemplate,
		$stateParams,
		FilterHelper,
		Segment,
		meta,
		Flow,
		Form,
		avatarService,
		ListViewService,
		MultiRunnerActions,
		FeatureHelper
	) {
		var customerId,
			dataStore,
			selectData = {};
		var rootNode = document.getElementById('edit-segment-root');

		var handleStatChangeOnIncludeExclude = function (oldStats, newStats) {
			if (oldStats.total !== newStats.total) {
				$('#edit-segment-root .segment-status-bar .segment-result-filter.total:not(.filter-active)').addClass(
					'blink'
				);
			}
			if (oldStats.fromSelection !== newStats.fromSelection) {
				$(
					'#edit-segment-root .segment-status-bar .segment-result-filter.fromSelection:not(.filter-active)'
				).addClass('blink');
			}
			if (oldStats.manuallyExcluded !== newStats.manuallyExcluded) {
				$(
					'#edit-segment-root .segment-status-bar .segment-result-filter.manuallyExcluded:not(.filter-active)'
				).addClass('blink');
			}
			if (oldStats.manuallyIncluded !== newStats.manuallyIncluded) {
				$(
					'#edit-segment-root .segment-status-bar .segment-result-filter.manuallyIncluded:not(.filter-active)'
				).addClass('blink');
			}

			setTimeout(function () {
				$('#edit-segment-root .segment-status-bar .segment-result-filter').removeClass('blink');
			}, 250);
		};

		var hasActiveSelection = function (filters) {
			return _.some(filters, function (filter) {
				return (
					!filter.isExclude &&
					_.some(filter.config, function (filter) {
						return !filter.inactive;
					})
				);
			});
		};

		var getFiltersWithAtleastOneActiveConfig = function (filters) {
			return _.filter(filters, function (filter) {
				return _.some(filter.config, function (config) {
					return !config.inactive;
				});
			});
		};

		var getHasIncludeFilter = function (filter) {
			return _.some(filter || dataStore.get('segment.filter'), function (filter) {
				return !filter.isExclude;
			});
		};

		var getManuallFilter = function (isExclude, segment, index) {
			var fn = index ? _.findIndex : _.find;
			var key = segment.version === 2 ? 'Id' : 'IncludeId';

			return fn(segment.filter, function (filter) {
				if (
					Object.keys(filter.config).length === 1 &&
					filter.config.hasOwnProperty(key) &&
					filter.isExclude === isExclude
				) {
					return true;
				}
				return false;
			});
		};

		var getManuallyExcludedFilter = getManuallFilter.bind(null, true);
		var getManuallyIncludedFilter = getManuallFilter.bind(null, false);

		var getAllNoneManualFilters = function (segment) {
			var key = segment.version === 2 ? 'Id' : 'IncludeId';

			return _.filter(segment.filter, function (filter) {
				return Object.keys(filter.config).length === 1 && filter.config.hasOwnProperty(key) ? false : true;
			});
		};

		var getFilter = function (isExclude, segment) {
			return _.find(segment.filter, function (filterObj) {
				return (
					filterObj.isExclude === isExclude &&
					!(Object.keys(filterObj.config).length === 1 && filterObj.config.hasOwnProperty('Id'))
				);
			});
		};

		var getExcludeFilter = getFilter.bind(null, true);
		var getIncludeFilter = getFilter.bind(null, false);

		var removeContactFromManualList = function (fn, contactId) {
			var store = dataStore.pluck('segment', 'listState', 'filterSelection');
			var segment = store.segment;
			var listState = store.listState;
			var filterSelection = store.filterSelection;
			var filter = fn(segment);

			if (filter) {
				var filterFn = function (value) {
					return value !== contactId;
				};

				filter.config.Id.value = _.filter(filter.config.Id.value, filterFn);
				filter.body.q[0].v = _.filter(filter.body.q[0].v, filterFn);

				/* Set filter to inactive if empty */
				if (!filter.config.Id.value.length) {
					filter.config.Id.inactive = true;
				}

				/* Set dirty */
				filterSelection.dirty = isDirty(filterSelection.originalFilters, segment.filter);

				/* We dont want to splice any contacts from the 'fromSelection' list as we have not changed the selection */
				if (filterSelection.state.activeTab !== 'fromSelection') {
					/* Find contact and remove it from listed contacts */
					var existingIndex = _.findIndex(listState.data, { id: contactId });

					if (existingIndex !== -1) {
						listState.data.splice(existingIndex, 1);
						listState.total = listState.total - 1;
						listState.numberPages = Math.ceil(listState.total / listState.limit);
					}
				}

				/* Update store and rerender */
				dataStore.setStore(store);

				/* Recount stats */
				countAll({ fromIncludeExclude: true });
			}
		};

		var removeContactFromManuallyIncluded = removeContactFromManualList.bind(null, getManuallyIncludedFilter);
		var removeContactFromManuallyExcluded = removeContactFromManualList.bind(null, getManuallyExcludedFilter);

		var render = function (props, callback) {
			if (!rootNode || !props.activeTab) {
				return;
			}

			ReactDOM.render(
				React.createElement(
					ReactTemplates.segment.root,
					Object.assign({}, props, { selectData: selectData, renderCallback: callback || function () {} })
				),
				rootNode,
				function () {}
			);
		};

		$scope.$on('$destroy', function () {
			ReactDOM.unmountComponentAtNode(rootNode);
			rootNode = undefined;
		});

		var removeAttributeFromPath = function (flow, attribute) {
			// should remove id from all steps.
			var cleanAttribute = function (obj, attr) {
				delete obj[attr];

				if (obj.childYes) {
					obj.childYes = cleanAttribute(obj.childYes, attr);
				}

				if (obj.childNo) {
					obj.childNo = cleanAttribute(obj.childNo, attr);
				}

				return obj;
			};

			if (!flow.path) {
				return flow;
			}

			flow.path = cleanAttribute(flow.path, attribute);

			return flow;
		};

		const onSetFlowStatus = status => {
			var flowComponent = dataStore.get('flowComponent');
			flowComponent.flow.status = status;
			if (status === 'active') {
				flowComponent.activatingFlow = true;
				const segment = flowComponent.flow.segment;
				if (segment?.active === 0) {
					Segment.save({ id: segment.id, active: 1 });
				}
			}
			dataStore.setStore({ flowComponent: flowComponent });

			Flow.save(flowComponent.flow)
				.then(function () {
					globalTracker.track('Updated flow status to ' + status);
					if (status === 'active') {
						setTimeout(function () {
							var flowComponent = dataStore.get('flowComponent');
							// fake loading for at least 1 sec
							flowComponent.activatingFlow = false;
							flowComponent.flowActivatedInfo = true;
							dataStore.setStore({ flowComponent: flowComponent });
							setTimeout(function () {
								dataStore.set('flowComponent.flowActivatedInfo', false);
							}, 1800);
						}, 1800);
					}
				})
				.catch(err => logError(err, '[EditSegment] failed setting flow status'));
		};

		var actions = {
			saveSegment: function (resourceOptions, options) {
				var segment = dataStore.get('segment');

				segment.filter = window.BabelServices.MergeFilters(segment.filter, getConfig);

				return Segment.save(segment, resourceOptions || {}).then(function (res) {
					if (!_.get(options, 'onlySave')) {
						var filterSelection = dataStore.get('filterSelection');
						filterSelection.dirty = false;
						filterSelection.originalFilters = segment.filter;

						dataStore.set('filterSelection', filterSelection);

						if (segment.version === 2) {
							countAllClientsAndContacts()
								.then(({ clients, contacts }) => {
									dataStore.setStore({
										totalClients: clients,
										totalContacts: contacts
									});
								})
								.catch(err => logError(err, '[EditSegment] Failed counting contacts after save'));
						}
					}

					return res;
				});
			},
			removeFilter: function (filterToRemove) {
				var store = dataStore.getStore();
				var segment = store.segment;
				var pendingSaves = store.filterComponent.state.pendingSaves;

				segment.filter = _.filter(segment.filter, function (filter) {
					return filter.id !== filterToRemove.id;
				});

				store.hasIncludeFilter = getHasIncludeFilter();

				pendingSaves[filterToRemove.id] = true;

				dataStore.setStore(store);

				Segment.save(segment)
					.then(function (res) {
						if (!(res && res.data) || (res && res.error)) {
							return $q.reject('Erorr saving segment');
						}

						var pendingSaves = dataStore.get('filterComponent.state.pendingSaves');
						delete pendingSaves[filterToRemove.id];
						dataStore.set('filterComponent.state.pendingSaves', pendingSaves);

						if (filterToRemove.isExclude) {
							countExcludeFilters();
						} else {
							countIncludeFilters();
						}
					})
					.catch(function () {
						segment.filter.push(filterToRemove);
					});

				getContacts();
			},
			removeExcludedContacts: function () {
				var store = dataStore.getStore();
				var segment = store.segment;

				_.each(segment.filter, function (filter) {
					delete filter.config.ExcludeId;

					filter.body.q = _.filter(filter.body.q, function (o) {
						if (o.a !== 'id') {
							return o;
						} else {
							if (o.c !== 'ne') {
								return o;
							}
						}
					});
				});

				dataStore.setStore(store);

				Segment.save(segment)
					.then(function (res) {
						if (!(res && res.data) || (res && res.error)) {
							return $q.reject('Erorr saving segment');
						}

						var pendingSaves = dataStore.get('filterComponent.state.pendingSaves');
						// delete pendingSaves[filterToRemove.id];
						dataStore.set('filterComponent.state.pendingSaves', pendingSaves);

						countIncludeFilters();
					})
					.catch(function () {
						// segment.filter.push(filterToRemove);
					});

				getContacts();
			},
			setFlowStatus: function (status) {
				var prompt = $q.when();
				// Ask user with popup then do request
				if (status === 'active') {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						prompt = new Promise(resolve => {
							openModal('Alert', {
								title: 'flow.confirmActivate',
								body: 'flow.confirmActivateBody',
								headerIcon: 'play',
								alertType: 'info',
								confirmButtonText: 'flow.yesActivate',
								onClose: confirmed => {
									if (confirmed) {
										resolve();
									}
								}
							});
						});
					} else {
						prompt = $upModal.open('infoConfirm', {
							title: 'flow.confirmActivate',
							body: 'flow.confirmActivateBody',
							icon: 'fa-play',
							resolveTrue: 'flow.yesActivate',
							no: 'default.abort'
						});
					}
				}

				// eslint-disable-next-line promise/catch-or-return
				prompt.then(function () {
					onSetFlowStatus(status);
				});

				return prompt;
			},
			editSegment: function () {
				var store = dataStore.getStore();
				var segment = store.segment;
				var options = {
					segment: segment
				};
				openNewSegmentModal(options)
					.then(res => {
						const segment = dataStore.get('segment');
						// The filters may not be forrmated correctly from the api so we can only assign the properties that may have changed
						const updatedSegment = _.assign(segment, _.pick(res, ['name', 'description', 'active']));
						dataStore.setStore({ segment: updatedSegment });
					})
					.catch(() => {});
			},
			copySegment: async () => {
				// eslint-disable-next-line promise/catch-or-return
				await $upModal
					.open('infoPrompt', {
						title: 'segment.copy',
						body: 'default.name',
						ok: 'segment.copy',
						icon: 'fa-save'
					})
					.then(async name => {
						try {
							const { data: segment } = await Segment.save({
								name,
								active: 1
							});

							const filter = dataStore.getStore().segment.filter.map(f => ({
								...f,
								id: undefined,
								segmentId: segment.id,
								date: undefined
							}));

							const mergedFilters = window.BabelServices.MergeFilters(filter, getConfig);

							await Segment.save({ ...segment, filter: mergedFilters });

							Tools.$state.go('segment', { id: segment.id });
						} catch (e) {
							logError(e, 'Could not save segment');
						}
					});
			},
			copyFlow: function (flow) {
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('infoPrompt', {
						title: 'flow.copyFlow.title',
						body: 'default.name',
						ok: 'flow.copyFlow.title',
						icon: 'fa-save'
					})
					.then(function (name) {
						delete flow.id;
						delete flow.segmentId;
						flow.status = 'draft';
						flow.name = name;
						flow = removeAttributeFromPath(flow, 'id');
						flow = removeAttributeFromPath(flow, 'mailCampaignId');

						Flow.save(flow)
							.then(function (savedFlow) {
								Tools.$state.go('flow', { id: savedFlow.data.id });
							})
							.catch(err => logError(err, '[EditSegment] failed copying flow'));
					});
			},
			copyFlowPromise: function (flow) {
				return new Promise(function (resolve) {
					delete flow.id;
					delete flow.segmentId;
					flow.status = 'draft';
					flow.name = flow.name + ' (' + Tools.$translate('default.copied') + ')';
					flow = removeAttributeFromPath(flow, 'id');
					flow = removeAttributeFromPath(flow, 'mailCampaignId');

					return resolve(flow);
				});
			},
			editFlow: function (flow) {
				openModal('CreateFlow', {
					flow,
					onClose: savedFlow => (savedFlow ? Tools.$state.reload() : undefined)
				});
			},
			deleteFlow: function (flow) {
				var segment = flow.segment;
				if (!flow || !flow.id) {
					return;
				}

				var customerId = AppService.getCustomerId();
				var confirmButtonText = $translate.instant('admin.modal.deleteEntity', {
					entity: $translate.instant(entityName('flow', 1)).toLowerCase()
				});

				var alertConfirmOptions = {
					type: 'confirm',
					reactive: true,
					fullscreen: true,
					hideAbort: false,
					dialog: AlertConfirm,
					id: 'confirm-delete-fields',
					body: React.createElement(AlertBody, {
						customerId: customerId,
						numSelected: 1,
						entity: 'flow'
					}),
					confirmButtonText: confirmButtonText,
					confirmClass: 'btn-red',
					confirmationMode: 'text',
					confirmText: Tools.AppService.getSelf().email,
					confirmFn: function () {
						return Flow.delete(flow.id).then(function () {
							const store = dataStore.pluck('flowComponent', 'segment');
							dataStore.setStore({ flowComponent: { ...store.flowComponent, flow: null } });
							$state.go('segment', { customerId: customerId, id: segment.id });
						});
					}
				};

				if (FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					return openModal('Alert', {
						...alertConfirmOptions,
						title: getConfirmationTitle('flow', 1),
						onClose: confirmed => {
							if (confirmed) {
								alertConfirmOptions.confirmFn();
							}
						}
					});
				}

				return $upModal.open('alert', alertConfirmOptions);
			}
		};

		let modalIsOpen = false;
		var hasShownStateChangeWarning = false;
		$scope.$on('$stateChangeStart', function (e, toState, toStateParams, fromState, fromStateParams, options) {
			if (
				!hasShownStateChangeWarning &&
				toState.name.indexOf('segment.') === -1 &&
				dataStore.get('filterSelection.dirty') &&
				!modalIsOpen
			) {
				modalIsOpen = true;
				e.preventDefault();

				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('UnsavedChangesAlert', {
						onClose: async confirmed => {
							hasShownStateChangeWarning = true;
							modalIsOpen = false;
							if (confirmed === undefined) {
								return;
							}
							if (confirmed) {
								try {
									if (toState.name.startsWith('react-root-')) {
										options = {};
									}

									await actions.saveSegment({}, { onlySave: true });
									$state.go(toState.name, toStateParams, options);
								} catch (err) {
									logError(err, '[EditSegment] stateChangeStart failed to save segment');
								}
							} else {
								$state.go(toState.name, toStateParams);
							}
						}
					});
					return;
				}

				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('infoConfirm', {
						title: 'default.saveChanges',
						body: 'confirm.changesWillBeLost',
						icon: 'fa-exclamation-triangle',
						resolveFalse: 'default.save',
						resolveFalseBtnClass: 'btn-bright-blue',
						resolveTrue: 'default.discardChanges',
						resolveTrueBtnClass: 'btn-bright-blue btn-lined'
					})
					.then(function (skipSave) {
						hasShownStateChangeWarning = true;
						if (toState.name.startsWith('react-root-')) {
							options = {};
						}
						if (skipSave === false) {
							actions
								.saveSegment({}, { onlySave: true })
								.then(function () {
									$state.go(toState.name, toStateParams, options);
								})
								.catch(err => logError(err, '[EditSegment] stateChangeStart failed to save segment'));
						} else {
							$state.go(toState.name, toStateParams);
						}
					});
			}
		});

		var initialStore = {
			activeTab: null,
			listState: {
				loading: true,
				error: null,
				limit: 50,
				offset: 0,
				data: [],
				currentPage: 1,
				numberPages: 0,
				total: 0
			},
			hasIncludeFilter: false,
			totalContacts: 0,
			totalClients: 0,
			totalIncludeContacts: 0,
			totalExcludeContacts: 0,
			/* This is for the new selection UI */
			getExcludeFilter: getExcludeFilter,
			getIncludeFilter: getIncludeFilter,
			filterSelection: {
				LIST_AJAX_LIMIT: 50,
				dirty: false,
				state: {
					placeholderHidden: false,
					activeTab: 'total',
					expanded: true,
					autoFocus: false,
					stats: {
						total: 0,
						manuallyExcluded: 0,
						manuallyIncluded: 0,
						fromSelection: 0
					}
				},
				fakeConfigs: {},
				changeTab: function (tab) {
					var filterSelection = dataStore.get('filterSelection');
					filterSelection.state.activeTab = tab;
					dataStore.set('filterSelection', filterSelection);

					getContacts({ resetPage: true });
				},
				toggleExpand: function () {
					var filterState = dataStore.get('filterSelection.state');
					filterState.expanded = !filterState.expanded;
					filterState.placeholderHidden = true;
					dataStore.set('filterSelection.state', filterState);
				},
				toggleOrGroup: function (isExclude) {
					var data = dataStore.pluck('segment', 'filterSelection');
					var segment = data.segment;
					var filterSelection = data.filterSelection;
					var filters = isExclude ? getExcludeFilter(segment) : getIncludeFilter(segment);

					if (filters) {
						filters.orGroup = !filters.orGroup;

						var originalFilters = filterSelection.originalFilters;
						filterSelection.dirty = isDirty(originalFilters, segment.filter);

						dataStore.setStore(data);

						getContacts({ resetPage: true });
						countAll();
					}
				},
				addFilter: function (isExclude, configName) {
					var data = dataStore.pluck('segment', 'filterSelection');
					var segment = data.segment;
					var filterSelection = data.filterSelection;

					var filters = isExclude ? getExcludeFilter(segment) : getIncludeFilter(segment);

					/*
					Filters should never be undefined here as i fix this in init to,
					just added it here to for super extra saftey
				*/
					if (!filters) {
						filters = { isExclude: isExclude, config: {}, body: {} };
						segment.filter.push(filters);
					}

					var filter = FilterHelper.filter(configName, 'contact', { getConfig: getConfig });

					/*
					In advanced search visitorFilters - date defaulted to whenever due to
					performance reasons. Here we dont want that behavior.
				*/
					if (configName === 'VisitorFilters') {
						filter.value.Date.value.preset = 'whenever';
					}

					/*
					For radio elements we dont want the "all" option, if they want all they can remove the filter
				*/
					var config = getConfig(configName);
					if (
						config.type === 'radio' ||
						config.displayType === 'radio' ||
						config.displayType === 'listShortSingle'
					) {
						var firstInactiveValue = _.find(config.options, function (option) {
							return !option.inactive;
						});
						if (firstInactiveValue) {
							filter.value = firstInactiveValue.value;
							filter.comparisonType = firstInactiveValue.comparisonType;
							filter.inactive = false;
						}
					}

					/*
					We want to treat text-filters as lists
				*/
					if (configName.indexOf('Custom') > -1 && config.displayType === 'text') {
						filter.comparisonType = 'Search';
					}

					/*
					These filters "feels" like they are active when you choose them so i will set them to it
				*/

					if (
						[
							'OpportunityFilters',
							'ContactOpportunityFilters',
							'ClientOrderFilters',
							'ContactOrderFilters',
							'ClientActivityFilters',
							'ContactOnlyActivityFilters',
							'MailCampaignFilters',
							'VisitorFilters',
							'FormSubmitFilters',
							'ClientAgreementFilters',
							'ContactAgreementFilters',
							'Appointment',
							'ClientAppointment',
							'ContactToDo',
							'ContactPhoneCall',
							'ClientToDo',
							'ClientPhoneCall',
							'ClientActivity'
						].indexOf(configName) > -1
					) {
						filter.inactive = false;
					} else {
						filter.inactive = FilterHelper.isInactiveValue(filter, 'contact', config);
					}

					filters.config[filter.filterName] = filter;
					var build = FilterHelper.parseFilters(filters.config, 'contact', null, null, {
						getConfig: getConfig,
						useTags: true,
						groupAllFilters: true
					}).build();

					filters.body = { q: build.q || [] };

					if (build.customerId) {
						filters.body.customerId = build.customerId;
					}
					if (build.userId) {
						filters.body.userId = build.userId;
					}

					var newData = { segment: segment };

					if (!filter.inactive) {
						var originalFilters = filterSelection.originalFilters;
						filterSelection.dirty = isDirty(originalFilters, segment.filter);
						newData.filterSelection = filterSelection;
					}

					dataStore.setStore(newData);

					if (!filter.inactive) {
						getContacts({ resetPage: true });
						countAll();
					}
				},
				editFilter: function (isExclude, filterName, filter) {
					var data = dataStore.pluck('segment', 'filterSelection');
					var segment = data.segment;
					var filterSelection = data.filterSelection;

					var filters = isExclude ? getExcludeFilter(segment) : getIncludeFilter(segment);

					if (filters) {
						filters.config[filterName] = filter;
						var build = FilterHelper.parseFilters(filters.config, 'contact', null, null, {
							getConfig: getConfig,
							useTags: true,
							groupAllFilters: true
						}).build();
						filters.body = { q: build.q || [] };

						if (build.customerId) {
							filters.body.customerId = build.customerId;
						}
						if (build.userId) {
							filters.body.userId = build.userId;
						}

						var originalFilters = filterSelection.originalFilters;
						filterSelection.dirty = isDirty(originalFilters, segment.filter);

						dataStore.setStore(data);

						getContacts({ resetPage: true });
						countAll();
					}
				},
				removeFilter: function (isExclude, filterName) {
					var data = dataStore.pluck('segment', 'filterSelection');
					var segment = data.segment;
					var filterSelection = data.filterSelection;
					var filters = isExclude ? getExcludeFilter(segment) : getIncludeFilter(segment);

					if (filters) {
						delete filters.config[filterName];
						var build = FilterHelper.parseFilters(filters.config, 'contact', null, null, {
							getConfig: getConfig,
							useTags: true,
							groupAllFilters: true
						}).build();
						filters.body = { q: build.q || [] };

						if (build.customerId) {
							filters.body.customerId = build.customerId;
						}
						if (build.userId) {
							filters.body.userId = build.userId;
						}

						var originalFilters = filterSelection.originalFilters;
						filterSelection.dirty = isDirty(originalFilters, segment.filter);

						dataStore.setStore(data);

						getContacts({ resetPage: true });
						countAll();
					}
				}
			},
			filterComponent: {
				state: {
					expanded: false,
					pendingSaves: {}
				},
				toggleExpand: function () {
					var filterState = dataStore.get('filterComponent.state');
					dataStore.set('filterComponent.state.expanded', !filterState.expanded);
				},
				editFilter: function (filter) {
					var segment = dataStore.get('segment');
					if (filter.config && filter.config.ExcludeId) {
						filter.config.ExcludeId.inactive = true;
					}

					if (!filter.hasOwnProperty('manualExcluded')) {
						filter.manualExcluded = false;
					}

					var options = {
						q: FilterHelper.convertForURL(filter.config, 'contact', { getConfig: getConfig }),
						fromSegment: {
							segment: segment,
							filter: _.pick(filter, 'id', 'config', 'body', 'isExclude', 'manualExcluded')
						}
					};

					$state.go('contacts', options);
				},
				addFilter: function (isExclude) {
					var segment = dataStore.get('segment');
					var options = {
						q: FilterHelper.convertForURL(
							{
								Active: {
									value: true
								},
								MissingEmail: {
									value: false
								}
							},
							'contact',
							{ getConfig: getConfig }
						),
						fromSegment: {
							segment: segment,
							filter: {
								body: {},
								config: {},
								isExclude: isExclude !== undefined ? isExclude : false,
								manualExcluded: false
							}
						}
					};

					$state.go('contacts', options);
				}
			},
			mailCampaignComponent: {
				openMailCampaign: function (mailCampaign) {
					var mailCampaignCanBeSent = !mailCampaign.sendDate
						? true
						: moment(mailCampaign.sendDate).diff() < 12e4;

					if (mailCampaign.status === 'SCHEDULED' && mailCampaignCanBeSent) {
						$upModal.open('warningAlertlg', {
							title: $translate.instant('mail.mayAlreadyProcessed'),
							body: 'mailInfo.mayAlreadyProcessed',
							yes: 'default.iAgree',
							icon: 'fa-warning'
						});
					} else if (
						mailCampaign.status === 'SCHEDULED' ||
						mailCampaign.status === 'DRAFT' ||
						mailCampaign.status === 'NO_CONTACTS' ||
						mailCampaign.status === 'ERROR'
					) {
						Tools.routerHistory.push(`/group-mail-editor/${mailCampaign.id}`);
					} else {
						$state.go('mailCampaign.dashboard', { customerId: customerId, id: mailCampaign.id });
					}
				},
				newMailCampaign: function () {
					var segment = dataStore.get('segment');
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_CREATE_GROUP_MAIL')) {
						openModal('CreateGroupMail', {
							segment
						});
					} else {
						Tools.$upModal.open('createGroupMail', { segment });
					}
				},
				removeMailCampaign: function (mailCampaign) {
					return $upModal
						.open('warningConfirm', {
							title:
								$translate.instant('default.remove') +
								' ' +
								$translate.instant('default.mailinglist').toLowerCase(),
							body: 'confirm.removeMailinglist',
							resolveTrue: 'default.remove',
							icon: 'fa-warning'
						})
						.then(function () {
							return MailCampaign.customer(customerId)
								.delete(mailCampaign)
								.then(function () {
									var data = dataStore.get('listState.data');
									data = _.filter(data, function (_mailCampaign) {
										return _mailCampaign.id !== mailCampaign.id;
									});

									dataStore.set('listState.data', data);
								});
						});
				},
				archiveMailCampaign: function (mailCampaign) {
					MailCampaign.archive(mailCampaign.id, !mailCampaign.isArchived)
						.then(function () {
							mailCampaign.isArchived = !mailCampaign.isArchived;
						})
						.catch(err => logError(err, '[EditSegment] failed archiving mailcampaign'));
				}
			},
			contactComponent: {
				filterStr: '',
				currentSort: {
					attr: 'name',
					asc: true
				},
				setSort: function (key) {
					var currentSort = dataStore.get('contactComponent.currentSort');
					if (currentSort.attr === key) {
						currentSort.asc = !currentSort.asc;
					} else {
						currentSort.attr = key;
						currentSort.asc = true;
					}

					dataStore.set('contactComponent.currentSort', currentSort);

					getContacts();
				},
				isContactManuallyExcluded: function (contactId) {
					var segment = dataStore.get('segment');
					var filter = getManuallyExcludedFilter(segment);

					if (filter) {
						return filter.config.Id.value.indexOf(contactId) !== -1;
					}
					return false;
				},
				isContactManuallyIncluded: function (contactId) {
					var segment = dataStore.get('segment');
					var filter = getManuallyIncludedFilter(segment);

					if (filter) {
						return filter.config.Id.value.indexOf(contactId) !== -1;
					}
					return false;
				},
				editContact: function (contact) {
					var options = {
						customerId: customerId,
						id: contact.id
					};

					$upModal.open('editContact', options);
				},
				filterContacts: function (value) {
					dataStore.set('contactComponent.filterStr', value);
					getContacts();
				},
				removeContactFromManuallyIncluded: removeContactFromManuallyIncluded,
				removeContactFromManuallyExcluded: removeContactFromManuallyExcluded,
				excludeContactFromSegment: function (contact) {
					var store = dataStore.pluck('segment', 'filterSelection', 'listState');
					var segment = store.segment;
					var i = getManuallyExcludedFilter(segment, true);
					var excludeFilter;

					if (segment.version === 2) {
						var filterSelection = store.filterSelection;

						if (i !== -1) {
							var filter = segment.filter[i];

							if (filter.config.Id.value.indexOf(contact.id) === -1) {
								filter.config.Id.value.push(contact.id);
							}
							if (filter.body.q.length && filter.body.q[0].v.indexOf(contact.id) === -1) {
								filter.body.q[0].v.push(contact.id);
							}

							filter.config.Id.inactive = false;
						} else {
							excludeFilter = {
								isExclude: true,
								body: {
									q: [{ a: 'id', c: 'eq', v: [contact.id] }]
								},
								config: {
									Id: {
										value: [contact.id],
										comparisonType: 'Equals',
										filterName: 'Id',
										dataType: 'idList',
										inactive: false,
										orGroup: false
									}
								}
							};
							segment.filter.push(excludeFilter);
						}

						const includeFilter = getManuallyIncludedFilter(segment, false);
						if (includeFilter) {
							includeFilter.config.Id.value = includeFilter.config.Id.value.filter(
								contactId => contactId !== contact.id
							);
							includeFilter.body.q[0].v = includeFilter.body.q[0].v.filter(
								contactId => contactId !== contact.id
							);
							includeFilter.config.Id.inactive = includeFilter.config.Id.value.length === 0;
						}

						/* Set dirty state */
						filterSelection.dirty = isDirty(filterSelection.originalFilters, segment.filter);

						/* Get active filtet tab, if we are on total we want to remove the excluded contact */
						var activeFilterTab = filterSelection.state.activeTab;

						if (activeFilterTab === 'total') {
							/* Fetch liststate to get a clone of it */
							var listState = store.listState;

							/* Find contact and remove it from listed contacts */
							var existingIndex = _.findIndex(listState.data, { id: contact.id });

							if (existingIndex !== -1) {
								listState.data.splice(existingIndex, 1);
								listState.total = listState.total - 1;
								listState.numberPages = Math.ceil(listState.total / listState.limit);
							}
						}

						/*
						Setting liststate with the new object will trigger a rerender of the contact list as
						  ReactTemplates.segment.contacts shouldComponentUpdate will return true with newProps.listState !== this.props.listState
					*/
						dataStore.setStore(store);

						/* Recount stats */
						countAll({ fromIncludeExclude: true });
					} else {
						if (i !== -1) {
							segment.filter[i].config.IncludeId.value.push(contact.id);
							segment.filter[i].body.q[0].v.push(contact.id);
							dataStore.set('segment.filter', segment.filter);
						} else {
							excludeFilter = {
								isExclude: true,
								body: {
									q: [{ a: 'id', c: 'eq', v: [contact.id] }]
								},
								config: {
									IncludeId: {
										value: [contact.id],
										comparisonType: 'Equals',
										filterName: 'IncludeId',
										columnPath: 'id',
										dataType: 'idList',
										field: 'id',
										isExcludeFilter: true,
										manualExcluded: true
									}
								}
							};

							dataStore.push('segment.filter', excludeFilter);
						}

						actions
							.saveSegment({ skipNotification: true })
							.then(function (res) {
								if (res && res.data) {
									var existingIndex = _.findIndex(dataStore.get('listState.data'), {
										id: contact.id
									});

									if (existingIndex !== -1) {
										dataStore.splice('listState.data', existingIndex, 1);
									}

									countAll();
									NotificationService.addNotification({
										title: 'default.saved',
										body: 'segment.notification.excludedContact.desc',
										style: 'success',
										icon: 'check'
									});
								}
							})
							.catch(err => logError(err, '[EditSegment] failed excluding contact from segment'));
					}
				}
			},
			eventComponent: {
				chartDataLoading: false,
				eventsLoading: false,
				chartData: [],
				events: [],
				selectedFilter: 'all',
				setFilter: function (value) {
					if (value !== dataStore.get('eventComponent.selectedFilter')) {
						dataStore.set('eventComponent.selectedFilter', value);
						getEvents();
					}
				}
			},
			paginatorChange: function (page) {
				dataStore.set('listState.currentPage', page);

				switch (dataStore.get('activeTab')) {
					case 'contacts':
						return getContacts();
					case 'mailcampaign':
						return getMailCampaigns();
				}
			},
			tools: {
				$translate: $translate,
				$state: $state,
				formatNumber: $filter('numberFormat'),
				AppService: AppService,
				FilterHelper: FilterHelper,
				MailTemplate: MailTemplate,
				MailCampaign: MailCampaign,
				Form: Form,
				avatarService: avatarService,
				RequestBuilder: RequestBuilder,
				Contact: Contact
			},
			archiveSegment: function () {
				var segment = dataStore.get('segment');
				if (!segment || !segment.id) {
					return;
				}

				const flowComponent = dataStore.get('flowComponent');
				if (flowComponent?.status === 'active') {
					onSetFlowStatus('paused');
				}

				segment.active = 0;

				Segment.save(segment)
					.then(function (res) {
						segment.name = res.data.name;
						segment.description = res.data.description;

						dataStore.setStore({ segment: segment });
						$state.go('segment', { customerId: customerId, id: segment.id });
					})
					.catch(err => logError(err, '[EditSegment] failed archiving segment'));
			},
			activateSegment: function () {
				var segment = dataStore.get('segment');
				if (!segment || !segment.id) {
					return;
				}

				segment.active = 1;

				Segment.save(segment)
					.then(function (res) {
						segment.name = res.data.name;
						segment.description = res.data.description;

						dataStore.setStore({ segment: segment });
						$state.go('segment', { customerId: customerId, id: segment.id });
					})
					.catch(err => logError(err, '[EditSegment] failed activating segment'));
			},
			segment: {},
			hasActiveSelection: hasActiveSelection,
			export: function () {
				var actions = MultiRunnerActions.get(MultiRunnerActions.type.CONTACT);
				var store = dataStore.pluck('listState', 'segment', 'filterSelection', 'contactComponent');
				var segment = store.segment;

				var hasSelection, query, filters;

				filters = getFiltersWithAtleastOneActiveConfig(segment.filter);
				hasSelection = hasActiveSelection(filters);

				if (hasSelection) {
					query = FilterHelper.mergeSegmentFilters(filters);
					actions.export.run(
						{
							selectedView: {
								sorting: [
									{
										attribute: store.contactComponent.currentSort.attr,
										ascending: store.contactComponent.currentSort.asc ? 'A' : 'Z'
									}
								]
							}
						},
						query,
						null,
						['contact.name'],
						{},
						true
					);
				}
			},
			delete: function () {
				var segment = dataStore.get('segment');
				if (!segment || !segment.id) {
					return;
				}

				var hasFlow = dataStore.get('flowComponent');
				var entity = $translate.instant(entityName('segment', 1)).toLowerCase();
				if (hasFlow) {
					entity = $translate.instant('default.segmentAndFlow').toLowerCase();
				}

				var customerId = AppService.getCustomerId();
				var confirmButtonText = $translate.instant('admin.modal.deleteEntity', {
					entity: entity
				});

				var alertConfirmOptions = {
					type: 'confirm',
					reactive: true,
					fullscreen: true,
					hideAbort: false,
					dialog: AlertConfirm,
					id: 'confirm-delete-fields',
					body: React.createElement(AlertBody, {
						customerId: customerId,
						numSelected: 1,
						entity: hasFlow ? 'segmentAndFlow' : 'segment'
					}),
					confirmButtonText: confirmButtonText,
					confirmClass: 'btn-red',
					confirmationMode: 'text',
					confirmText: Tools.AppService.getSelf().email,
					confirmFn: function () {
						return Segment.delete(segment.id).then(function () {
							history.replace('/segments');
						});
					}
				};

				if (FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					return openModal('Alert', {
						...alertConfirmOptions,
						title: getConfirmationTitle(hasFlow ? 'segmentAndFlow' : 'segment', 1),
						onClose: confirmed => {
							if (confirmed) {
								alertConfirmOptions.confirmFn();
							}
						}
					});
				}

				return $upModal.open('alert', alertConfirmOptions);
			},
			editSettings: function (name, desc) {
				var segment = dataStore.get('segment');

				segment.name = name;
				segment.description = desc;

				dataStore.setStore({ segment: segment });
				actions.saveSegment();
			},
			getHref: function (stateName, stateParams) {
				stateParams = stateParams || {};
				stateParams.customerId = customerId;
				return $state.href(stateName, stateParams);
			},
			flowComponent: {
				showSelectionTooltip: !!$stateParams.showSelectionTooltip,
				activatingFlow: false,
				flowActivatedInfo: false,
				flow: null,
				loading: true,
				showTooltip: false,
				usePreset: function (presetId) {
					$state.go('editFlow', { segmentId: dataStore.get('segment.id'), preset: presetId });
				},
				createFlow: function () {
					$state.go('editFlow', { segmentId: dataStore.get('segment.id') });
				},
				editFlow: function () {
					var openPromise = $q.when();
					var flowComponent = dataStore.get('flowComponent');
					if (flowComponent.flow.status === 'active') {
						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
							openPromise = new Promise(resolve => {
								openModal('Alert', {
									title: 'flow.activeFlow',
									body: 'flow.activeFlowWarning',
									confirmButtonText: 'flow.pauseAndEdit',
									headerIcon: 'warning',
									onClose: async confirmed => {
										if (confirmed) {
											flowComponent.loading = true;
											flowComponent.flow.status = 'paused';
											dataStore.setStore({ flowComponent: flowComponent });

											try {
												await Flow.save(flowComponent.flow);
												resolve();
											} catch (err) {
												logError(err, '[EditSegment] failed saving flow');
											}
										}
									}
								});
							});
						} else {
							openPromise = $upModal
								.open('warningConfirm', {
									icon: 'fa-warning',
									title: 'flow.activeFlow',
									body: 'flow.activeFlowWarning',
									resolveTrue: 'flow.pauseAndEdit',
									no: 'default.abort'
								})
								.then(function () {
									flowComponent.loading = true;
									flowComponent.flow.status = 'paused';
									dataStore.setStore({ flowComponent: flowComponent });

									return Flow.save(flowComponent.flow);
								});
						}
					}

					// eslint-disable-next-line promise/catch-or-return
					openPromise.then(function () {
						$state.go('editFlow', { segmentId: dataStore.get('segment.id') });
					});
				},

				hideTooltip: function () {
					dataStore.set('flowComponent.showTooltip', false);
				},

				hideSelectionTooltip: function () {
					dataStore.set('flowComponent.showSelectionTooltip', false);
				}
			}
		};

		function getMailCampaigns() {
			var state = dataStore.get('listState');

			state.loading = true;
			state.error = null;
			dataStore.set('listState', state);

			var rb = new RequestBuilder();
			rb.addFilter(MailCampaign.attr.segment.attr.id, rb.comparisonTypes.Equals, dataStore.get('segment.id'));
			rb.addFilter(MailCampaign.attr.flowId, rb.comparisonTypes.Equals, null);
			rb.limit = state.limit;
			rb.offset = (state.currentPage - 1) * state.limit;
			rb.addSort(MailCampaign.attr.sendDate, false);

			return MailCampaign.find(rb.build())
				.then(function (res) {
					// Check if mailcampaignTab is stil active (since they share data array)
					if (dataStore.get('activeTab') !== 'mailcampaign') {
						return; // abort if we moved to another page
					}
					var state = dataStore.get('listState');
					state.loading = false;

					if (res && Array.isArray(res.data)) {
						state.data = res.data;
						state.total = res.metadata.total;
						state.numberPages = Math.ceil(state.total / state.limit);

						const newNumberOfSentMailCampaign = res.data.reduce(
							(total, mailCampaign) => (mailCampaign.status === 'SENT' ? total + 1 : total),
							0
						);
						const stats = dataStore.get('segment.stats');

						if (stats && newNumberOfSentMailCampaign > stats.mailCount) {
							dataStore.set('segment.stats.mailCount', newNumberOfSentMailCampaign);
						}
					} else {
						state.error = { code: 500 };
					}

					dataStore.set('listState', state);
				})
				.catch(function (res) {
					var listState = dataStore.get('listState');
					listState.loading = false;
					listState.data = [];
					listState.numberPages = 0;
					listState.total = 0;
					listState.error =
						res && res.data && res.data.error
							? res && res.data && res.data.error
							: { code: res.status, msg: res.statusText };

					dataStore.set('listState', listState);
				});
		}

		function getContacts(options) {
			var store = dataStore.pluck('listState', 'segment', 'filterSelection', 'contactComponent');
			var listState = store.listState;
			var segment = store.segment;
			var activeSelection = store.filterSelection.state.activeTab;
			var hasSelection, query, filters;

			if (_.get(options, 'resetPage')) {
				listState.currentPage = 1;
			}

			switch (activeSelection) {
				case 'total':
					filters = getFiltersWithAtleastOneActiveConfig(segment.filter);
					hasSelection = hasActiveSelection(filters);

					if (hasSelection) {
						query = FilterHelper.mergeSegmentFilters(filters);
					}

					break;
				case 'fromSelection':
					filters = getAllNoneManualFilters(segment);
					hasSelection = hasActiveSelection(filters);

					if (hasSelection) {
						query = FilterHelper.mergeSegmentFilters(filters);
					}

					break;
				case 'manuallyIncluded':
					filters = getManuallyIncludedFilter(segment);
					hasSelection =
						filters !== undefined && filters.config && filters.config.Id && !filters.config.Id.inactive;

					if (hasSelection) {
						query = _.cloneDeep(filters.body);
					}

					break;
				case 'manuallyExcluded':
					filters = getManuallyExcludedFilter(segment);
					hasSelection =
						filters !== undefined && filters.config && filters.config.Id && !filters.config.Id.inactive;

					if (hasSelection) {
						query = _.cloneDeep(filters.body);
					}

					break;
			}

			if (hasSelection) {
				listState.loading = true;
				listState.error = null;
				dataStore.set('listState', listState);

				query.f = ['name', 'client', 'email', 'segments', 'score', 'unsubscribed', 'optOut'];
				query.limit = listState.limit;
				query.offset = (listState.currentPage - 1) * listState.limit;
				var missingFirst = store.contactComponent.currentSort.attr === 'Segment_' + store.segment.id + '.date';
				query.sort = [
					{
						a: store.contactComponent.currentSort.attr,
						s: store.contactComponent.currentSort.asc ? 'A' : 'Z',
						mf: missingFirst
					}
				];
				query.segmentId = segment.id;

				if (store.contactComponent.filterStr && store.contactComponent.filterStr.length) {
					query.q.push({
						or: {
							q: [
								[{ a: 'name', c: 'src', v: store.contactComponent.filterStr }],
								[{ a: 'email', c: 'src', v: store.contactComponent.filterStr }],
								[{ a: 'client.name', c: 'src', v: store.contactComponent.filterStr }]
							]
						}
					});
				}

				return Contact.find(query)
					.then(function (res) {
						// Check if contactTab is stil active (since they share data array)
						if (dataStore.get('activeTab') !== 'contacts') {
							return; // abort if we moved to another page
						}
						// Doing a shallow clone, dont want to clone the data array
						var newListState = _.clone(listState);
						newListState.loading = false;

						if (res && Array.isArray(res.data)) {
							newListState.data = res.data;
							newListState.total = res.metadata.total;
							newListState.numberPages = Math.ceil(newListState.total / newListState.limit);
						} else {
							newListState.error = { code: 500 };
						}

						return ScoreSum.scoreSumRequest({
							contactIds: res.data.map(contact => contact.id),
							endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
							startDate: moment().subtract(90, 'day').format('YYYY-MM-DD')
						}).then(res => {
							newListState.data.forEach(contact => {
								const scoreObject = res.data.find(obj => obj.contactId === contact.id);
								if (scoreObject) {
									contact.marketScoreMonth = scoreObject.score;
								} else {
									contact.marketScoreMonth = 0;
								}
							});
							dataStore.set('listState', newListState);
						});
					})
					.catch(function (res) {
						var store = dataStore.pluck('listState', 'filterSelection');
						var listState = store.listState;
						var filterSelection = store.filterSelection;
						// This is just to hide the save button
						filterSelection.dirty = false;

						listState.loading = false;
						listState.data = [];
						listState.numberPages = 0;
						listState.total = 0;
						listState.error =
							res && res.data && res.data.error
								? res && res.data && res.data.error
								: { code: res.status, msg: res.statusText };

						dataStore.setStore({ listState: listState, filterSelection: filterSelection });
					});
			} else {
				listState.loading = false;
				listState.error = null;
				listState.data = [];
				listState.numberPages = 0;
				listState.total = 0;
				dataStore.set('listState', listState);
				return $q.when({ data: [] }); // so we can hook on this function as a promise
			}
		}

		var getFlow = function (skipStats = false) {
			var store = dataStore.pluck('flowComponent', 'segment');
			var filters = new RequestBuilder();
			filters.addFilter(Flow.attr.segment, filters.comparisonTypes.Equals, store.segment.id);
			Flow.find(filters.build(), { skipStats })
				.then(function (res) {
					if (res && res.data && res.data.length) {
						store.flowComponent.flow = res.data[0];
					}
					store.flowComponent.loading = false;
					dataStore.setStore({ flowComponent: store.flowComponent });
				})
				.catch(err => logError(err, '[EditSegment] failed loading flow'));
		};

		var getNewListState = function (options) {
			var defaultState = {
				loading: true,
				error: null,
				limit: 50,
				offset: 0,
				data: [],
				currentPage: 1,
				numberPages: 0,
				total: 0
			};

			return _.assign(defaultState, options);
		};

		function getEvents() {
			// keep it from blocking shit
			dataStore.set('eventComponent.eventsLoading', true, function () {
				dataStore.set('eventComponent.eventsLoading', false);
			});
		}

		function getCurrentTab(url) {
			const urlRegExp = /\/\d+\/segments\/\d+\/($|contacts\/?$|mailcampaign\/?$|flow\/?$|^(?![\s\S])$)/;
			const match = urlRegExp.exec(url);

			if (!match || !(typeof match[1] === 'string')) {
				return null;
			}

			switch (match[1].replace('/', '')) {
				case 'flow':
					return 'flow';
				case 'mailcampaign':
					return 'mailcampaign';
				default:
					return 'contacts';
			}
		}

		var unsubEvents;
		let contactFiltersInitialized = false;

		var urlChange = function (url) {
			const activeTab = getCurrentTab(url);
			const currentActiveTab = dataStore.get('activeTab');

			if (unsubEvents) {
				unsubEvents();
				unsubEvents = null;
			}

			if (activeTab && activeTab !== currentActiveTab) {
				switch (activeTab) {
					case 'contacts': {
						const { filterSelection, filterComponent, segment } = dataStore.pluck(
							'filterSelection',
							'filterComponent',
							'segment'
						);
						filterSelection.state.autoFocus = false;

						const newState = {
							activeTab: 'contacts',
							listState: getNewListState(),
							filterSelection,
							filterComponent,
							hasIncludeFilter: getHasIncludeFilter(segment.filter)
						};

						if (!contactFiltersInitialized) {
							const filterSelection = newState.filterSelection;
							filterSelection.fakeConfigs = window.Tools.getFakeFilterConfigs();
							filterSelection.originalFilters = _.cloneDeep(segment.filter);
							filterSelection.contactCustomFields = _.filter(
								AppService.getCustomFields('contact'),
								cf => cf.visible
							);
							filterSelection.clientCustomFields = _.filter(
								AppService.getCustomFields('account'),
								cf => cf.visible
							);
							filterSelection.orderCustomFields = _.filter(
								AppService.getCustomFields('order'),
								cf => cf.visible
							);
							initialStore.filterSelection.filterConfigs = filterSelection.filterConfigs =
								getFilterConfigs(segment.id);
							selectData = getSelectData(
								_.assign({}, filterSelection.filterConfigs, filterSelection.fakeConfigs)
							);
							if (!segment.filter || !segment.filter.length || $stateParams.expanded === 'expanded') {
								newState.filterComponent.state.expanded = true;
							}
							contactFiltersInitialized = true;
						}

						dataStore.setStore(newState, function () {
							setTimeout(function () {
								dataStore.set('filterSelection.state.autoFocus', true);
							}, 0);
						});

						getContacts();
						countAll();

						unsubEvents = $scope.$on('contact.updated', function (e, updated) {
							var store = dataStore.getStore();
							var i = _.findIndex(store.listState.data, { id: updated.id });
							var query = FilterHelper.mergeSegmentFilters(store.segment.filter);
							// I doubt that FilterHelper.match work
							if (i !== -1 && FilterHelper.match(query.q, updated, 'contact')) {
								// Still matches filters and exists in list, update
								_.each(Object.keys(store.listState.data[i]), function (key) {
									if (updated[key] !== undefined) {
										store.listState.data[i][key] = updated[key];
									}
								});
								dataStore.setStore(store);
							} else if (i !== -1) {
								// not matching filters anymore, remove contact
								dataStore.splice('listState.data', i, 1);
								countAll();
							}
						});

						break;
					}
					case 'mailcampaign': {
						dataStore.setStore({ activeTab: 'mailcampaign', listState: getNewListState() });
						getMailCampaigns();

						break;
					}
					case 'flow': {
						const flowComponent = dataStore.get('flowComponent');
						flowComponent.loading = true;

						if ($stateParams.showTooltip) {
							$state.go('.', { showTooltip: undefined }, { notify: false });
							flowComponent.showTooltip = true;
						}

						dataStore.setStore({ activeTab: 'flow', flowComponent, listState: getNewListState() });
						getFlow();

						break;
					}
				}
			}
		};

		var mergeFiltersPart = function (filterObjects, exclude) {
			var filters = _.filter(filterObjects, { isExclude: exclude });
			var filtersBody = _.pluck(filters, 'body');

			var query = _.reduce(
				filtersBody,
				function (res, filter) {
					if (filter.q) {
						res.or.q.push(filter.q);
					}
					return res;
				},
				{ or: { q: [] } }
			);

			var res = { q: [] };

			if (query.or.q.length) {
				res.q.push(query);
			}
			return res;
		};

		var countContacts = function (query) {
			var defer = $q.defer();
			query.limit = 0;

			if (query.q && query.q.length) {
				Contact.find(query)
					.then(function (res) {
						if (res && res.metadata) {
							defer.resolve(res.metadata.total);
						} else {
							defer.resolve(0);
						}
					})
					.catch(function () {
						defer.resolve(0);
					});
			} else {
				defer.resolve(0);
			}

			return defer.promise;
		};

		/* Version 1 aggregations */

		var countTotalContacts = function () {
			var store = dataStore.getStore();
			var segment = store.segment;
			var hasIncludeFilter = getHasIncludeFilter(segment.filter);

			if (hasIncludeFilter) {
				var query = FilterHelper.mergeSegmentFilters(segment.filter);

				return countContacts(query).then(function (count) {
					dataStore.set('totalContacts', count);
				});
			} else {
				dataStore.set('totalContacts', 0);
			}
		};

		var countTotalIncludeContacts = function () {
			var segment = dataStore.get('segment');
			var query = mergeFiltersPart(segment.filter, false);

			return countContacts(query).then(function (count) {
				dataStore.setStore({ totalIncludeContacts: count });
			});
		};

		var countTotalExcludeContacts = function () {
			var segment = dataStore.get('segment');
			var query = mergeFiltersPart(segment.filter, true);

			return countContacts(query).then(function (count) {
				dataStore.setStore({ totalExcludeContacts: count });
			});
		};

		var countContactsPerFilter = function () {
			var promises = _.map(dataStore.get('segment.filter'), function (filter) {
				return countContacts({ q: filter.body.q }).then(function (count) {
					filter.$$nrMatchingContacts = count;
					return filter;
				});
			});

			return $q.all(promises).then(function (mappedFilters) {
				dataStore.set('segment.filter', mappedFilters);
			});
		};

		function countExcludeFilters() {
			$q.all([countTotalContacts(), countTotalExcludeContacts()]);
		}

		function countIncludeFilters() {
			$q.all([countTotalContacts(), countTotalIncludeContacts()]);
		}

		/* Version 2 aggregations */

		function countAllClientsAndContacts() {
			var segment = dataStore.get('segment');
			var filters = getFiltersWithAtleastOneActiveConfig(segment.filter);
			var hasIncludeFilter = hasActiveSelection(filters);

			if (hasIncludeFilter) {
				return countParticipants(segment.filter);
			} else {
				return $q.when({ contacts: 0, clients: 0 });
			}
		}

		var countAllNoneManualFilters = function () {
			var segment = dataStore.get('segment');
			var filters = getAllNoneManualFilters(segment);

			if (filters.length) {
				var hasIncludeFilter = hasActiveSelection(filters);

				if (hasIncludeFilter) {
					var query = FilterHelper.mergeSegmentFilters(filters);
					return countContacts(query);
				}
			}
			return $q.when(0);
		};

		var countManuallyIncludedContacts = function () {
			var segment = dataStore.get('segment');
			var filter = getManuallyIncludedFilter(segment);

			if (filter && filter.config && filter.config.Id && !filter.config.Id.inactive) {
				return countContacts(filter.body);
			}
			return $q.when(0);
		};

		var countManuallyExcludedContacts = function () {
			var segment = dataStore.get('segment');
			var filter = getManuallyExcludedFilter(segment);

			if (filter && filter.config.Id && !filter.config.Id.inactive) {
				return countContacts(filter.body);
			}
			return $q.when(0);
		};

		function countAll(options) {
			if (dataStore.get('segment.version') === 2) {
				$q.all({
					total: countAllClientsAndContacts(),
					manuallyIncluded: countManuallyIncludedContacts(),
					manuallyExcluded: countManuallyExcludedContacts(),
					fromSelection: countAllNoneManualFilters()
				})
					.then(({ total: { contacts, clients }, manuallyIncluded, manuallyExcluded, fromSelection }) => {
						const filterSelection = dataStore.get('filterSelection');
						const newStats = {
							total: contacts,
							manuallyIncluded,
							manuallyExcluded,
							fromSelection
						};
						const oldStats = filterSelection.state.stats;
						filterSelection.state.stats = newStats;
						const newState = {
							filterSelection: filterSelection,
							totalClients: clients,
							totalContacts: contacts
						};

						dataStore.setStore(newState, function () {
							if (_.get(options, 'fromIncludeExclude')) {
								handleStatChangeOnIncludeExclude(oldStats, newStats);
							}
						});
					})
					.catch(function () {
						const filterSelection = dataStore.get('filterSelection');
						filterSelection.state.stats = {
							total: 0,
							manuallyIncluded: 0,
							manuallyExcluded: 0,
							fromSelection: 0
						};
						const newState = { filterSelection: filterSelection, totalContacts: 0, totalClients: 0 };
						dataStore.setStore(newState);
					});
			} else {
				$q.all([
					countTotalContacts(),
					countTotalIncludeContacts(),
					countTotalExcludeContacts(),
					countContactsPerFilter()
				]);
			}
		}

		function isDirty(odlFilters, newFilters) {
			if (odlFilters.length !== newFilters.length) {
				return true;
			}

			var itr = 0,
				length = newFilters.length,
				newPart,
				oldPart;
			for (; itr < length; ++itr) {
				newPart = FilterHelper.convertForURL(newFilters[itr].config, 'contact', {
					skipInactive: true,
					getConfig: getConfig
				});
				oldPart = FilterHelper.convertForURL(odlFilters[itr].config, 'contact', {
					skipInactive: true,
					getConfig: getConfig
				});

				if (newPart !== oldPart) {
					return true;
				}
			}

			var oldExcludeFilters = getExcludeFilter({ filter: odlFilters });
			var newExcludeFilters = getExcludeFilter({ filter: newFilters });
			var oldIncludeFilters = getIncludeFilter({ filter: odlFilters });
			var newIncludeFilters = getIncludeFilter({ filter: newFilters });

			if (
				_.get(oldExcludeFilters, 'orGroup') !== _.get(newExcludeFilters, 'orGroup') ||
				_.get(oldIncludeFilters, 'orGroup') !== _.get(newIncludeFilters, 'orGroup')
			) {
				return true;
			}

			return false;
		}

		function getSelectData(filterConfigs) {
			var listConfigs = _.pick(filterConfigs, function (config) {
				return (
					config.displayType === 'list' ||
					config.displayType === 'listLazy' ||
					(!config.hasOwnProperty('displayType') && config.type === 'list')
				);
			});

			return _.mapValues(listConfigs, function (config, filterName) {
				var deferred = $q.defer();

				/* I dont want all of these requests to block more important ones, hence the timeput */
				setTimeout(function () {
					var promise;

					if (config.dataPromise) {
						promise = Tools.$injector.invoke(
							config.dataPromise,
							{},
							{ customerId: customerId, filterConfig: config, filterName: filterName }
						);
					} else if (config.searchFn) {
						promise = Tools.$injector.invoke(config.searchFn, {})(
							'',
							config.includeFields,
							0,
							initialStore.filterSelection.LIST_AJAX_LIMIT
						);
					} else if (config.resource && typeof config.resource === 'function') {
						promise = Tools.$injector.invoke(config.resource, {})(customerId);
					} else if (config.resource) {
						var resource = Tools.$injector.get(config.resource);

						if (resource.customer) {
							resource = resource.customer(customerId);
						}

						if (config.resourceType) {
							resource = resource.setType(config.resourceType);
						}

						promise = resource.find({});
					} else {
						// Should never get here
						console.warn('List filter without any resource or dataPromise', filterName, config);
						promise = $q.when({ data: [], metadata: { total: Infinity } });
					}

					promise
						.then(function (res) {
							deferred.resolve(res);
						})
						.catch(err => {
							logError(err, `[EditSegment] failed loading selectData for ${filterName}`);
							deferred.reject(err);
						});
				}, 50);

				return deferred.promise;
			});
		}

		function getFilterConfigs(id) {
			return window.Tools.getCriteriaFilterConfigs(id);
		}

		function getConfig(filterName) {
			var config = initialStore.filterSelection.filterConfigs[filterName];

			if (!config) {
				var standardConfig = ListViewService.getStandardFilters('contact')[filterName];
				config = _.assign({}, standardConfig, { filterName: filterName, entity: 'contact' });
			}

			return config;
		}

		var init = function () {
			customerId = AppService.getCustomerId();
			var segment = meta.segment;

			if (segment.version === 2) {
				var excludeFilter = getExcludeFilter(segment);
				var includeFilter = getIncludeFilter(segment);

				if (!excludeFilter) {
					segment.filter.push({ orGroup: true, isExclude: true, config: {}, body: {} });
				}
				if (!includeFilter) {
					segment.filter.push({ orGroup: false, isExclude: false, config: {}, body: {} });
				}
			}

			initialStore.segment = meta.segment;
			initialStore.hasIncludeFilter = getHasIncludeFilter(meta.segment.filter);

			dataStore = new DataStore(render, actions, initialStore, function () {
				/*
				React.createElement is sync so if we defined the "callback function" here dataStore wont
				initialized when it is run, hence the timeout.
			*/
				setTimeout(function () {
					dataStore.set('filterSelection.state.autoFocus', true);
				}, 1000);
			});

			const activeTab = getCurrentTab($location.$$path);

			// If we are not on the contact tab we only need to fetch the total
			if (activeTab === 'contacts') {
				countAll({ updateTotal: true });
			} else {
				countAllClientsAndContacts()
					.then(({ clients, contacts }) => {
						dataStore.setStore({
							totalClients: clients,
							totalContacts: contacts
						});
					})
					.catch(err => logError(err, '[EditSegment] Failed counting contacts in init'));
			}
			// If we are on the flow tab urlChange will fetch the flow with the stats
			if (activeTab !== 'flow') {
				getFlow(true);
			}

			$scope.$watch(function () {
				return $location.$$path;
			}, urlChange);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);
