import React from 'react';
import PropTypes from 'prop-types';

import BemClass from '@upsales/components/Utils/bemClass';

import './CriteriaWrapper.scss';
import { Icon, Button, Tooltip, EllipsisTooltip } from '@upsales/components';
import t from 'Components/Helpers/translate';

const CriteriaWrapper = props => {
	const { className, onClick, onRemove, children, lang, locked, type, showEntity } = props;
	const classes = new BemClass('CriteriaWrapper', className);

	let lastCol;
	if (onRemove && !locked) {
		lastCol = (
			<td className={classes.elem('last-col').b()}>
				<Tooltip title={lang.remove} position="left">
					<Button
						type="link"
						color="grey"
						className={classes.elem('remove').b()}
						onClick={e => e.stopPropagation() || onRemove()}
					>
						<Icon name="times-circle" />
					</Button>
				</Tooltip>
			</td>
		);
	} else if (locked) {
		lastCol = (
			<td className={classes.elem('last-col').b()}>
				<Tooltip
					title={t(type === 'criteria' ? 'default.journeyCriteriaLocked' : 'default.journeyActionLocked')}
					position="left"
				>
					<Icon className={classes.elem('lock').b()} name="lock" />
				</Tooltip>
			</td>
		);
	} else {
		lastCol = <td />;
	}

	return (
		<tr
			className={classes.mod({ 'has-on-click': !locked && onClick, locked }).b()}
			onClick={locked ? null : onClick}
		>
			<td className={classes.elem('title-col').b()}>
				<div className={classes.elem('title-container')}>
					<div>
						<EllipsisTooltip title={lang.title} position="right">
							<div className={classes.elem('title', 'ellipsis').b()}>
								{props.icon ? <Icon name={props.icon} space="mrs" /> : null}
								{lang.title}
							</div>
						</EllipsisTooltip>
						{showEntity ? (
							<div className={classes.elem('entity', 'ellipsis').b()}>{lang.entity}</div>
						) : null}
					</div>
					{lang.tooltip ? (
						<div className={classes.elem('info-icon').b()}>
							<Tooltip title={lang.tooltip} position="right">
								<Icon name="question-circle"></Icon>
							</Tooltip>
						</div>
					) : null}
				</div>
			</td>
			<td className={classes.elem('table-col').b()}>{children}</td>
			{lastCol}
		</tr>
	);
};

CriteriaWrapper.propTypes = {
	className: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	lang: PropTypes.object.isRequired,
	onClick: PropTypes.func,
	onRemove: PropTypes.func,
	locked: PropTypes.bool,
	type: PropTypes.oneOf(['criteria', 'action']),
	showEntity: PropTypes.bool,
	icon: PropTypes.string
};

export default CriteriaWrapper;
