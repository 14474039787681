import { addNotification, STYLE as notificationStyle } from 'Store/reducers/SystemNotificationReducer';
import { ListViewDefaultColumn, RenderProvided } from 'App/components/ListView/ListViewRenderHelpers';
import { ListViewTableProvided } from 'App/components/ListView/ListViewTable/ListViewTable';
import { Link, TableColumn, TableRow, Text, Icon, Tooltip } from '@upsales/components';
import useComponentDidUnmount from 'App/components/hooks/useComponentDidUnmount';
import RequiredFields from 'Components/Modals/CreateNewAccount/RequiredFields';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { useCustomFields } from 'App/components/hooks/appHooks';
import DirectorAttributes from 'App/babel/attributes/Director';
import { useTranslation } from 'Components/Helpers/translate';
import { DefaultButton, ThirdButton } from '@upsales/components/Buttons';
import React, { useState, useRef, useEffect } from 'react';
import { type Director } from 'App/resources/Model/Client';
import { useSoftDeployAccess } from 'App/components/hooks';
import Prospecting from 'App/babel/resources/Prospecting';
import CustomField from 'App/resources/Model/CustomField';
import BemClass from '@upsales/components/Utils/bemClass';
import ComparisonTypes from 'Resources/ComparisonTypes';
import RequestBuilder from 'Resources/RequestBuilder';
import { useAppDispatch } from 'App/components/hooks';
import ContactResource from 'App/resources/Contact';
import ClientResource from 'App/resources/Client';
import Contact from 'App/resources/Model/Contact';
import ListView from 'App/components/ListView';
import logError from 'Helpers/logError';
import fetcher from './fetcher';
import { openDrawer } from 'Services/Drawer';
import T from 'Components/Helpers/translate';

import './BoardMembers.scss';

const NAME_MAXLENGTH = 50;
const TITLE_MAXLENGTH = 128;

export type DirectorWithMatch = Director & {
	matchInUpsales: { id: number; name: string };
	matchInUpsalesCompany: { id: number; name: string };
	clientProspectingId: string;
	clientName: string;
	orgNumber: string;
	roles: string;
};

const getContactHref = (id: number) =>
	Tools.$state.href('contact.dashboard', {
		customerId: Tools.AppService.getCustomerId(),
		id
	});

const getAccountHref = (id: number) =>
	Tools.$state.href(
		Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD') ? 'react-root-clientCard' : 'account.dashboard',
		{
			customerId: Tools.AppService.getCustomerId(),
			id,
			page: 'overview'
		}
	);

const mapCustomFields = (
	customFields: {
		name: string;
		value: string;
	}[]
) =>
	customFields.reduce<{ fieldId: number; value: string }[]>((res, { name, value }) => {
		const fieldId = parseInt(name.split('_')[1]);
		if (!isNaN(fieldId)) {
			res.push({ fieldId, value });
		}
		return res;
	}, []);

const AddButton = ({
	director,
	forceFetch,
	addClientId
}: {
	director: DirectorWithMatch;
	forceFetch: () => void;
	addClientId?: (id: number) => void;
}) => {
	const classes = new BemClass('BoardMembers');
	const [saving, setSaving] = useState(false);
	const hasNewFields = useSoftDeployAccess('NEW_FIELDS');
	const { t } = useTranslation();
	const contactCustomFields = useCustomFields('contact');
	const accountCustomFields = useCustomFields('account');
	const dispatch = useAppDispatch();

	const contactReq = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ContactResource.save>>>>(null);

	useComponentDidUnmount(() => contactReq.current?.cancel());

	const doAddContact = (properties: { name: string; value: string }[], clientId: number) => {
		setSaving(true);

		const contact: Partial<Contact> = { ssn: director.prospectingId };
		if (hasNewFields) {
			if (director.firstName && director.lastName) {
				contact.firstName = director.firstName?.substring(0, NAME_MAXLENGTH);
				contact.lastName = director.lastName?.substring(0, NAME_MAXLENGTH);
			} else {
				contact.firstName = director.name?.substring(0, NAME_MAXLENGTH);
			}
		} else {
			contact.name = director.name?.substring(0, NAME_MAXLENGTH);
		}

		contact.title = director.roles.substring(0, TITLE_MAXLENGTH);
		contact.client = { id: clientId } as any;

		contact.custom = mapCustomFields(properties);

		contactReq.current = makeCancelable(
			ContactResource.save(contact, { params: { usingFirstnameLastname: hasNewFields } })
		);

		return contactReq.current.promise
			.then(() => {
				dispatch(
					addNotification({
						style: notificationStyle.SUCCESS,
						icon: 'save',
						title: t('savedTitle.prospecting.contact')
					})
				);
			})
			.catch(err => logError(err, 'Failed to save director'))
			.finally(() => {
				forceFetch();
				setSaving(false);
			});
	};

	const addContact = (clientId: number) => {
		const requiredFields = contactCustomFields.filter(value => {
			return value.obligatoryField && value.$hasAccess && value.editable;
		});

		if (requiredFields.length) {
			Tools.$upModal.open('generic', {
				Component: (props: any) => (
					<div id="create-new-account-modal">
						<RequiredFields {...props} />
					</div>
				),
				requiredFields: requiredFields,
				entityType: 'Contact',
				fullscreen: true,
				actions: {
					addAccount: (addingAccount: any, properties: any, purchaseType: any, resolve: () => void) =>
						doAddContact(properties, clientId).then(resolve)
				}
			});
		} else {
			doAddContact([], clientId);
		}
	};

	const doAddAccount = (prospectingId: string, customValues: CustomField[] = []) =>
		Prospecting.save({ prospectingId, customValues }, { params: { skipAddCEO: true } }).then(({ data }) => {
			addContact(data.id);
			addClientId?.(data.id);
		});

	const addAccount = () => {
		const requiredFields = accountCustomFields.filter(value => {
			return value.obligatoryField && value.$hasAccess && value.editable && value.alias !== 'ORG_NO';
		});

		if (requiredFields.length) {
			Tools.$upModal.open('generic', {
				Component: (props: any) => (
					<div id="create-new-account-modal">
						<RequiredFields {...props} />
					</div>
				),
				requiredFields: requiredFields,
				fullscreen: true,
				actions: {
					addAccount: (addingAccount: any, properties: any, purchaseType: any, resolve: () => void) =>
						doAddAccount(director.clientProspectingId, properties).then(resolve)
				}
			});
		} else {
			doAddAccount(director.clientProspectingId);
		}
	};

	if (director.matchInUpsales) {
		return null;
	}

	return (
		<div className={classes.elem('addButtonColumn').b()}>
			<Tooltip
				title={
					director.matchInUpsalesCompany
						? t('companyGroup.boardMembers.addContact')
						: t('companyGroup.boardMembers.addCompanyAndContact')
				}
				position="bottom"
				distance={30}
			>
				<DefaultButton
					className={classes.elem('addButtonColumn').elem('addBoardBuyButton').b()}
					loading={saving}
					onClick={() => {
						if (director.matchInUpsalesCompany) {
							addContact(director.matchInUpsalesCompany.id);
						} else {
							addAccount();
						}
					}}
				>
					<span className={classes.elem('icon').b()}>
						<Icon name="plus" />
					</span>
					<span className={classes.elem('expandOnRowHover').b()}>{t('account.addAsNew')}</span>
				</DefaultButton>
			</Tooltip>
		</div>
	);
};

const BoardMembers = (props: {
	clientId?: number;
	hideHeader?: boolean;
	columns?: string[];
	orgNumbers: string[];
	prospectingId: string;
	addClientId?: (id: number) => void;
}) => {
	const {
		clientId,
		hideHeader,
		addClientId,
		prospectingId,
		columns = ['name', 'clientName', 'role', 'memberSince', 'additional', '']
	} = props;

	const [orgNumbers, setOrgNumbers] = useState<string[]>(props.orgNumbers);

	useEffect(() => {
		if (!clientId) {
			return;
		}

		const getAndUpdateOrgNumbers = async () => {
			const rb = new RequestBuilder();

			rb.addFilter({ field: 'operationalAccount.id' }, ComparisonTypes.Equals, clientId);

			const { data } = await ClientResource.find(rb.build());
			const orgNumbers = props.orgNumbers.concat(data.map(({ orgNo }) => orgNo)).filter(Boolean);

			setOrgNumbers(orgNumbers);
		};

		getAndUpdateOrgNumbers();
	}, [clientId]);

	const renderTableRow = (
		director: DirectorWithMatch,
		{
			columns,
			attributes,
			onFilterChange
		}: ListViewTableProvided<DirectorWithMatch, RenderProvided<DirectorWithMatch>>
	) => {
		const columnElements = columns.map(column => {
			let content = null;
			switch (column) {
				case 'name': {
					content = director.matchInUpsales ? (
						<Text size="sm">
							<Link onClick={e => e.stopPropagation()} href={getContactHref(director.matchInUpsales.id)}>
								{director.name}
							</Link>
						</Text>
					) : (
						<Text size="sm">{director.name}</Text>
					);
					break;
				}

				case 'clientName': {
					content = director.matchInUpsalesCompany ? (
						<Text size="sm">
							<Link
								onClick={e => e.stopPropagation()}
								href={getAccountHref(director.matchInUpsalesCompany.id)}
							>
								{director.clientName}
							</Link>
						</Text>
					) : (
						<Text size="sm">{director.clientName}</Text>
					);
					break;
				}

				case 'additional': {
					return (
						<TableColumn className="columnWidth" key={column + director.prospectingId + director.orgNumber}>
							<ThirdButton
								onClick={() =>
									openDrawer('AdditionalBoardMembers', {
										director,
										prospectingId
									})
								}
							>
								{T('account.showCompanies')}
							</ThirdButton>
						</TableColumn>
					);
				}

				case '': {
					return (
						<TableColumn className="columnWidth" key={column + director.prospectingId + director.orgNumber}>
							<AddButton
								director={director}
								forceFetch={() => onFilterChange({}, { silent: true })}
								addClientId={addClientId}
							/>
						</TableColumn>
					);
				}

				default: {
					return (
						<ListViewDefaultColumn<DirectorWithMatch>
							key={column}
							item={director}
							attributes={attributes}
							column={column}
						/>
					);
				}
			}
			return <TableColumn key={column + director.prospectingId + director.orgNumber}>{content}</TableColumn>;
		});

		return <TableRow key={director.prospectingId + director.orgNumber}>{columnElements}</TableRow>;
	};

	return (
		<ListView<DirectorWithMatch>
			hideFilters
			attributes={DirectorAttributes}
			broadcastType="contact"
			getData={rb => fetcher(rb, { orgNumbers, prospectingId })}
			columns={columns}
			skipSortById
			renderToolsColumn={false}
			canSortCustomFields={false}
			renderTableRow={renderTableRow}
			renderHeader={hideHeader ? () => null : undefined}
			initialSorting={[{ attribute: 'name', ascending: true }]}
		/>
	);
};

export default BoardMembers;
