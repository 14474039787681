import openModal from 'App/services/Modal';
import ValidationService from 'Services/ValidationService';

angular.module('domain.admin').controller('EditIntegration', [
	'AppService',
	'$scope',
	'$state',
	'$stateParams',
	'StandardIntegration',
	'$q',
	'CacheRefresher',
	'$safeApply',
	'NotificationService',
	function (
		AppService,
		$scope,
		$state,
		$stateParams,
		StandardIntegration,
		$q,
		CacheRefresher,
		$safeApply,
		NotificationService
	) {
		var AdminSubCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;
		var allowStateChange = false;
		var initialHash;
		var currentHash;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = 'editIntegration';
		AdminSubCtrl.rootData.pageLoading = true;

		var customerId;

		AdminSubCtrl.rootData.pageData = {
			integration: null,
			inits: [
				'job',
				'phone',
				'esign',
				'sms',
				'webinar',
				'dataSource',
				'fileStorage',
				'calendar',
				'legacyCalendar',
				'chatNotifications',
				'mail',
				'import',
				'after_form_submit',
				'after_client_update',
				'after_client_insert',
				'after_client_delete',
				'after_contact_update',
				'after_contact_insert',
				'after_contact_delete',
				'after_order_update',
				'after_order_edit',
				'after_order_insert',
				'after_order_delete',
				'after_activity_update',
				'after_activity_insert',
				'after_activity_delete',
				'after_phonecall_insert',
				'after_phonecall_update',
				'after_todo_insert',
				'after_todo_update',
				'after_appointment_update',
				'after_appointment_insert',
				'after_appointment_delete',
				'after_project_update',
				'after_project_insert',
				'after_project_delete',
				'after_clientrelation_update',
				'after_clientrelation_insert',
				'after_clientrelation_delete',
				'after_agreement_update',
				'after_agreement_insert',
				'after_agreement_edit',
				'after_agreement_delete',
				'after_lead_update',
				'after_lead_insert',
				'after_lead_delete',
				'after_userdefobj_update',
				'after_userdefobj_insert',
				'after_userdefobj_delete',
				'after_userdefobj2_update',
				'after_userdefobj2_insert',
				'after_userdefobj2_delete',
				'after_userdefobj3_update',
				'after_userdefobj3_insert',
				'after_userdefobj3_delete',
				'after_userdefobj4_update',
				'after_userdefobj4_insert',
				'after_userdefobj4_delete',
				'before_order_save',
				'before_agreement_save',
				'before_client_save',
				'show_field_sync',
				'app_status_order',
				'disable_products',
				'limit_pricelists'
			]
		};

		if (Tools.FeatureHelper.hasSoftDeployAccess('BEFORE_CLIENT_PROSPECTING_SAVE')) {
			AdminSubCtrl.rootData.pageData.inits.push('before_client_prospecting_save');
		}

		AdminSubCtrl.rootData.onSave = function () {
			AdminSubCtrl.rootData.saving = true;
			AdminSubCtrl.rootData.pageData.integration.standardIntegrationTag = [];
			AdminSubCtrl.rootData.pageData.integration.standardIntegrationInit = [];

			_.keys(AdminSubCtrl.rootData.pageData.integration.inits).forEach(function (key) {
				if (AdminSubCtrl.rootData.pageData.integration.inits[key]) {
					AdminSubCtrl.rootData.pageData.integration.standardIntegrationInit.push({
						type: key
					});
				}
			});

			// Parse json
			try {
				JSON.parse(AdminSubCtrl.rootData.pageData.integration.configJson);
			} catch (e) {
				NotificationService.addNotification({
					style: NotificationService.style.WARN,
					icon: 'warning',
					title: 'default.error',
					body: 'Your JSON is invalid'
				});
				AdminSubCtrl.rootData.saving = false;
				return;
			}

			var data = Object.assign({}, AdminSubCtrl.rootData.pageData.integration);
			if (data.inputDebounceInMs && data.inputDebounceInMs < 500) {
				data.inputDebounceInMs = 500;
			}
			if (data.inputDebounceInMs && data.inputDebounceInMs > 3000) {
				data.inputDebounceInMs = 3000;
			}
			if (!data.inputDebounceInMs || !data.inits.after_order_edit) {
				data.inputDebounceInMs = null;
			}
			data.category = data.category.join(',');
			data.file = data.$file;
			data.region = data.region.map(region => region.id).join(',');

			if (ValidationService.validEmail(data.supportEmail) && data.supportEmail) {
				StandardIntegration.save(data)
					.then(function () {
						AdminSubCtrl.rootData.saving = false;
						allowStateChange = true;
						$state.go('administration.integrations', { customerId: customerId });

						// Refresh meta
						CacheRefresher.refresh([CacheRefresher.types.METADATA]);
					})
					.catch(function (err) {
						var fieldName;
						if (err.data.error.msg && !err.data.error.translated) {
							let body;
							if (err.data.error.msg.field) {
								fieldName = err.data.error.msg.field.replace(/_/g, ' ');
								body =
									fieldName[0].toUpperCase() +
									fieldName.slice(1) +
									' field ' +
									err.data.error.msg.msg;
							} else {
								body = err.data.error.msg;
								body = body[0].toUpperCase() + body.slice(1);
							}
							NotificationService.addNotification({
								style: NotificationService.style.ERROR,
								icon: 'error',
								title: 'default.error',
								body
							});
						}
						AdminSubCtrl.rootData.saving = false;
						$safeApply($scope);
					});
			} else {
				AdminSubCtrl.rootData.saving = false;
				NotificationService.addNotification({
					style: NotificationService.style.ERROR,
					icon: 'error',
					title: 'default.error',
					body: 'Email is not valid'
				});
			}
		};

		AdminSubCtrl.rootData.onChange = function (integration) {
			AdminSubCtrl.rootData.pageData.integration = integration;
			AdminSubCtrl.rootData.saveVisible = true;
			currentHash = Tools.LZString.compressToBase64(JSON.stringify(AdminSubCtrl.rootData.pageData.integration));

			$safeApply($scope);
		};

		$scope.stopEdit = function () {
			$scope.editing = false;
		};

		var getResumeState = function (stateObj) {
			return function (mergeParams) {
				var stateParams = _.merge(stateObj.stateParams, mergeParams || {});

				$state.go(stateObj.state.name, stateParams, stateObj.options);
			};
		};

		var init = function () {
			var promise;

			if ($stateParams.id) {
				promise = StandardIntegration.get($stateParams.id);
			} else {
				promise = $q.when({ data: StandardIntegration.new() });
			}

			promise
				.then(function (res) {
					var integration = res.data;

					integration.inits = {};
					AdminSubCtrl.rootData.pageData.inits.forEach(function (init) {
						var found = _.find(integration.standardIntegrationInit, { type: init });
						if (found) {
							integration.inits[init] = true;
						} else {
							integration.inits[init] = false;
						}
					});

					integration.tags = '';

					integration.standardIntegrationTag.forEach(function (tag) {
						integration.tags += tag.tag + ', ';
					});

					integration.color = integration.color || '#ffffff';
					integration.terms =
						(integration.contract && integration.contract.body) ||
						'# Terms \n\nThis integration is developed, hosted and maintained by **' +
							Tools.AppService.getAccountSelf().client.name +
							'**. \n\nBy activating this integration ' +
							Tools.AppService.getAccountSelf().client.name +
							' will have access to your Upsales account. \n\nAny configuration the integration may have, including credentials such as API keys, usernames and passwords will also be available to ' +
							Tools.AppService.getAccountSelf().client.name +
							'. \n\nBy accepting these terms and conditions you confirm to have read, understood and accepted them.';

					if (!integration.category) {
						integration.category = [];
					} else {
						integration.category = Array.isArray(integration.category)
							? integration.category
							: integration.category.split(',');
					}

					if (!integration.region) {
						integration.region = [];
					} else {
						const CountryCodes = Tools.CountryCodes;
						const countries = CountryCodes.countries();
						integration.region = Array.isArray(integration.region)
							? integration.region
							: integration.region.split(',').reduce((memo, region) => {
									const country = countries.find(country => country.id === region);
									if (country) {
										memo.push({ ...country, translated: Tools.$translate(country.lang) });
									}

									return memo;
							  }, []);
					}

					AdminSubCtrl.rootData.pageData.integration = integration;
					AdminSubCtrl.rootData.pageLoading = false;
					initialHash = Tools.LZString.compressToBase64(
						JSON.stringify(AdminSubCtrl.rootData.pageData.integration)
					);
					currentHash = initialHash;
				})
				.catch(err => console.error('Error while retrieving standard integration:', err));

			$scope.$on(
				'$stateChangeStart',
				function (event, toState, toStateParams, fromState, fromStateParams, options) {
					if (!allowStateChange && initialHash !== currentHash) {
						event.preventDefault();

						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
							openModal('UnsavedChangesAlert', {
								onClose: async confirmed => {
									if (confirmed === undefined) {
										return;
									}
									if (confirmed) {
										await AdminSubCtrl.rootData.onSave();
									}

									var stateObj = { state: toState, stateParams: toStateParams, options: options };
									allowStateChange = true;
									getResumeState(stateObj)();
								}
							});
							return;
						}

						Tools.$upModal
							.open('warningConfirm', {
								title: 'default.saveChanges',
								body: 'confirm.changesWillBeLost',
								resolveTrue: 'default.dontSave',
								no: 'default.abort',
								icon: 'fa-exclamation-triangle'
							})
							.then(function () {
								var stateObj = { state: toState, stateParams: toStateParams, options: options };

								allowStateChange = true;

								getResumeState(stateObj)();
							})
							.catch(err => console.error('Error while opening modal:', err));
					}
				}
			);
		};

		AppService.loadedPromise.then(init).catch(err => console.error('Error while loading app service:', err));
	}
]);
