import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UpSelect from '../Inputs/UpSelect';

import {
	Card,
	CardHeader,
	CardContent,
	Column,
	Row,
	Label,
	Toggle,
	Tooltip,
	Title,
	Text,
	Icon
} from '@upsales/components';
import FloatingSaveButton from 'Components/FloatingSaveButton';
import AdminHeader from './AdminHeader';

import { setCardConfig, saveConfig, initCardEditor } from 'Store/reducers/SalesboardReducer';
import './SalesboardCard.scss';
import { reactRouteCompatibility } from 'App/helpers/angularRouteHelpers';

const mapStateToProps = state => ({
	config: state.Salesboard.cardConfig,
	saving: state.Salesboard.savingCardConfig,
	cardConfigChanged: state.Salesboard.cardConfigChanged
});

const mapDispatchToProps = {
	setCardConfig,
	saveConfig,
	init: initCardEditor
};

class SalesboardCard extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;
		this.lang = {
			editCardInfo: t('salesboardCard.editCardInfo'),
			cardLayout: t('salesboardCard.cardLayout'),
			showUser: t('admin.showUser'),
			salesboard: t('default.salesboard'),
			save: t('default.save'),
			selectField: t('salesboardCard.selectField'),
			linkTo: t('salesboardCard.linkTo'),
			tooltip: t('salesboardCard.tooltip'),
			selectZone: t('salesboardCard.selectZone'),
			or: t('default.or').toLowerCase(),
			showRecommendations: t('salesboardCard.showRecommendations'),
			showPlannedPhonecall: t('opportunity.salesBoard.showPlannedPhonecall'),
			showPlannedAppointment: t('opportunity.salesBoard.showPlannedAppointment'),
			showPlannedTodo: t('opportunity.salesBoard.showPlannedTodo'),
			showPlannedActivity: t('opportunity.salesBoard.showPlannedActivity')
		};

		this.state = {
			selectedZone: null
		};

		this.selectableFields = [
			{ title: t('default.description'), field: 'description' },
			{ title: t('default.accountName'), field: 'client.name' },
			{ title: t('field.order.value'), field: 'value', type: 'currency' },
			{ title: t('field.order.date'), field: 'date', type: 'date' },
			{ title: t('field.stage.name'), field: 'stage.name' },
			{ title: t('field.contact.name'), field: 'contact.name' },
			{ title: t('field.project.name'), field: 'project.name' }
		];

		this.selectableLinks = [
			{ title: t('salesboardCard.link.accountDash'), id: 'client.id', state: 'account.dashboard' },
			{ title: t('salesboardCard.link.accountOrder'), id: 'client.id', state: 'account.orders' },
			{ title: t('salesboardCard.link.contactDash'), id: 'contact.id', state: 'contact.dashboard' },
			{ title: t('salesboardCard.link.contactOrder'), id: 'contact.id', state: 'contact.order' },
			{ title: t('salesboardCard.link.campaignDash'), id: 'project.id', state: 'campaign.dashboard' }
		];

		this.selectableTooltips = [
			{ title: t('default.description'), field: 'description' },
			{ title: t('default.accountName'), field: 'client.name' },
			{ title: t('field.order.value'), field: 'value', type: 'currency' },
			{ title: t('field.order.date'), field: 'date', type: 'date' },
			{ title: t('field.stage.name'), field: 'stage.name' },
			{ title: t('field.contact.name'), field: 'contact.name' },
			{ title: t('field.project.name'), field: 'project.name' },
			{ title: t('field.notes'), field: 'notes' },
			{ title: t('field.id'), field: 'id' }
		];

		this.fakeOrder = {
			description: 'Banner',
			'client.name': 'Upsales',
			value: 'Upsales',
			date: moment().format('DD MMM YYYY'),
			'stage.name': 'Prospect 1',
			'contact.name': 'John Wilson',
			'project.name': 'Prospects ' + moment().format('YYYY'),
			notes: 'Notes',
			id: '1723'
		};

		this.hasTodos = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');

		this.self = Tools.AppService.getSelf();

		const metadata = Tools.AppService.getMetadata();

		// Default currency
		const curr = _.find(metadata.customerCurrencies, { masterCurrency: true });

		this.fakeOrder.value = '9 200 ' + (curr ? curr.iso : 'EUR');
		this.fakeOrder.contributionMargin = '800 ' + (curr ? curr.iso : 'EUR');

		props.init();

		this.fieldChanged = this.fieldChanged.bind(this);
		this.stateChanged = this.stateChanged.bind(this);
		this.tooltipChanged = this.tooltipChanged.bind(this);

		this.cbIsActive = Tools.AppService.getMetadata().params.UseContributionMargin;
		if (this.cbIsActive) {
			this.selectableFields.push({
				title: t('default.contributionMargin'),
				field: 'contributionMargin',
				type: 'currency'
			});
		}

		const customFields = Tools.AppService.getCustomFields('order');

		if (customFields.length) {
			const cfOptions = { title: t('default.otherOrderFields'), id: null, children: [] };

			for (const cf of customFields) {
				cfOptions.children.push({ title: cf.name, field: `${cf.id}`, type: 'customField' });
			}

			this.selectableFields.push(cfOptions);
		}
	}

	selectZone(selectedZone) {
		this.setState({ selectedZone });
	}

	opportunityAIZone() {
		const { todo, meeting, phonecall, activity } = this.props.config.field4;

		return (
			<div className="SalesboardCard__fake-card__recommendations">
				{meeting || meeting == null ? (
					<Icon name="calendar-check-o" color="bright-green" space="pts prm pbm plm" />
				) : null}
				{(activity || activity == null) && !this.hasTodos ? (
					<Icon name="activity" color="bright-green" space="pts prm pbm plm" />
				) : null}
				{(todo || todo == null) && this.hasTodos ? (
					<Icon name="activity" color="bright-green" space="pts prm pbm plm" />
				) : null}
				{(phonecall || phonecall == null) && this.hasTodos ? (
					<Icon name="phone" color="bright-green" space="pts prm pbm plm" />
				) : null}
				<Text size="sm" color="grey-11" bold>
					{'3/4'}
				</Text>
			</div>
		);
	}

	renderZonePreview(type, zone) {
		const config = this.props.config;
		var zoneConfig = config[zone];
		if (!zoneConfig) {
			zoneConfig = { field: '', type: null };
		}
		const fakeData = this.fakeOrder[zoneConfig.field] || Tools.$translate('default.value');
		let c = type + ' SalesboardCard__fake-card--editable-zone';
		let content;
		if (this.state.selectedZone === zone) {
			c += ' SalesboardCard__fake-card--selected-zone';
		}

		if (zone === 'field4' && config.showUser) {
			c += ' has-avatar';
		}

		if (zoneConfig && zoneConfig.related) {
			content = (
				<span className="related SalesboardCard__fake-card--zone-content">{this.opportunityAIZone()}</span>
			);
		} else {
			content = (
				<span className="SalesboardCard__fake-card--zone-content">
					{zoneConfig && zoneConfig.link ? (
						<a onClick={this.preventClick}>{fakeData}</a>
					) : (
						<span>{fakeData}</span>
					)}
					{!zoneConfig ? <span className="card-placeholder">{this.lang.noData}</span> : null}
				</span>
			);
		}

		return (
			<div key={'zone-' + zone} className={c} onClick={this.selectZone.bind(this, zone)}>
				{zoneConfig && zoneConfig.tooltip ? (
					<Tooltip title={this.fakeOrder[zoneConfig.tooltip]} position="top">
						{content}
					</Tooltip>
				) : (
					content
				)}
			</div>
		);
	}

	renderSelect(fieldName, title, disabled, data, valKey, onChange) {
		let value = null;

		if (
			this.props.config[this.state.selectedZone][fieldName] &&
			this.props.config[this.state.selectedZone][fieldName].length
		) {
			value = this.props.config[this.state.selectedZone][fieldName];
		}

		return (
			<div className="form-group">
				<Label>{title}</Label>
				<UpSelect
					className="form-control"
					onChange={e => {
						setImmediate(() => onChange(e.target.value));
					}}
					matcher={(term, undef, item) => (item.title || '').toLowerCase().indexOf(term.toLowerCase()) !== -1}
					disabled={disabled}
					data={data}
					formatSelection={(r, container, escape) => escape(r.title)}
					formatResult={(r, container, query, escape) => escape(r.title)}
					getId={r => r[valKey]}
					defaultValue={value}
					placeholder={title}
				/>
			</div>
		);
	}

	fieldChanged(val) {
		const newConfig = { ...this.props.config, [this.state.selectedZone]: { field: val } };
		let selectedField = _.find(this.selectableFields, { field: newConfig[this.state.selectedZone].field });

		if (!selectedField) {
			const customFieldsParent = _.find(this.selectableFields, { id: null });

			if (customFieldsParent) {
				selectedField = _.find(customFieldsParent.children, { field: val });
			}
		}

		if (selectedField && selectedField.type) {
			newConfig[this.state.selectedZone].type = selectedField.type;
		} else {
			newConfig[this.state.selectedZone].type = null;
		}
		this.props.setCardConfig(newConfig);
	}

	stateChanged(val) {
		const newConfig = {
			...this.props.config,
			[this.state.selectedZone]: {
				...this.props.config[this.state.selectedZone],
				state: val
			}
		};
		if (newConfig[this.state.selectedZone].state) {
			const selectedLink = _.find(this.selectableLinks, { state: newConfig[this.state.selectedZone].state });
			newConfig[this.state.selectedZone].link = true;
			newConfig[this.state.selectedZone].id = selectedLink.id;
		} else {
			newConfig[this.state.selectedZone].link = false;
			newConfig[this.state.selectedZone].id = null;
		}
		this.props.setCardConfig(newConfig);
	}

	tooltipChanged(val) {
		const newConfig = {
			...this.props.config,
			[this.state.selectedZone]: {
				...this.props.config[this.state.selectedZone],
				tooltip: val
			}
		};
		if (newConfig[this.state.selectedZone].tooltip) {
			const selectedTooltip = _.find(this.selectableTooltips, {
				field: newConfig[this.state.selectedZone].tooltip
			});
			newConfig[this.state.selectedZone].tooltipType = selectedTooltip.type;
		} else {
			newConfig[this.state.selectedZone].tooltipType = null;
		}
		this.props.setCardConfig(newConfig);
	}

	toggle(type) {
		const { config, setCardConfig } = this.props;
		const { selectedZone } = this.state;

		const activeOrDefualt = config[selectedZone][type] || config[selectedZone][type] == null;

		return (
			<Toggle
				color="medium-green"
				checked={activeOrDefualt}
				onChange={() =>
					setCardConfig({
						[selectedZone]: {
							...config[selectedZone],
							[type]: !activeOrDefualt
						}
					})
				}
			/>
		);
	}

	render() {
		const {
			salesboard,
			editCardInfo,
			cardLayout,
			showUser,
			save,
			selectZone,
			tooltip,
			linkTo,
			selectField,
			or,
			showRecommendations
		} = this.lang;
		const { config, saving, setCardConfig, saveConfig, cardConfigChanged } = this.props;
		const { selectedZone } = this.state;

		return (
			<div className="AdminSalesboardCard admin-root-wrapper">
				<AdminHeader
					title={salesboard}
					description={editCardInfo}
					image="salesboard-card.svg"
					articleId={468}
				/>

				<div className="SalesboardCard__content">
					<Card className="SalesboardCard__card">
						<CardHeader title={cardLayout} />
						<CardContent>
							<Row>
								<Column>
									<div className="SalesboardCard__zone-toggle-row">
										<Toggle
											size="lg"
											color="medium-green"
											checked={config.showUser}
											onChange={v => setCardConfig({ showUser: v })}
											disabled={saving}
										/>
										<Label>{showUser}</Label>
									</div>
									{this.state.selectedZone ? (
										<div className="SalesboardCard__zone-settings">
											{this.renderSelect(
												'field',
												selectField,
												saving || config[selectedZone].related,
												this.selectableFields,
												'field',
												this.fieldChanged
											)}
											{this.renderSelect(
												'state',
												linkTo,
												saving || config[selectedZone].related || !config[selectedZone].field,
												this.selectableLinks,
												'state',
												this.stateChanged
											)}
											{this.renderSelect(
												'tooltip',
												tooltip,
												saving || config[selectedZone].related || !config[selectedZone].field,
												this.selectableTooltips,
												'field',
												this.tooltipChanged
											)}

											<div className="SalesboardCard__separator">
												<Text color="grey-11">
													{'----'} {or} {'----'}
												</Text>
											</div>

											<div className="SalesboardCard__zone-toggle-row">
												<Toggle
													size="lg"
													color="medium-green"
													checked={config[selectedZone].related}
													onChange={v =>
														setCardConfig({
															[selectedZone]: {
																...config[selectZone],
																related: v,
																meeting: v,
																todo: v,
																phonecall: v
															}
														})
													}
													disabled={saving}
												/>
												<Label>{showRecommendations}</Label>
												<ReactTemplates.elevio articleId={711} sidebar>
													<Icon name="info-circle" />
												</ReactTemplates.elevio>

												{config[selectedZone].related ? (
													<div className="SalesboardCard__zone-toggle-row__opportunityAI">
														{this.toggle('meeting')}
														<Icon name="calendar" space="pts prm pbm plm" />
														<Text>{this.lang.showPlannedAppointment}</Text>
														<br />
														{this.hasTodos ? (
															<React.Fragment>
																{this.toggle('todo')}
																<Icon name="activity" space="pts prm pbm plm" />
																<Text>{this.lang.showPlannedTodo}</Text>
																<br />

																{this.toggle('phonecall')}
																<Icon name="phone" space="pts prm pbm plm" />
																<Text>{this.lang.showPlannedPhonecall}</Text>
															</React.Fragment>
														) : (
															<React.Fragment>
																{this.toggle('activity')}
																<Icon name="activity" space="pts prm pbm plm" />
																<Text>{this.lang.showPlannedActivity}</Text>
															</React.Fragment>
														)}
													</div>
												) : null}
											</div>
										</div>
									) : (
										<Title className="SalesboardCard__none-selected" color="grey">
											{selectZone}
										</Title>
									)}
								</Column>
								<Column>
									<div className="SalesboardCard__ghost-card" />

									<div className="SalesboardCard__fake-card deal-card">
										{this.renderZonePreview('deal-card-title', 'field1')}

										{this.renderZonePreview('deal-card-field2', 'field2')}

										{this.renderZonePreview('deal-card-field3', 'field3')}

										<br />
										<div className="clearfix" />

										{this.renderZonePreview('deal-card-field4', 'field4')}

										{config.showUser ? (
											<Tooltip position="left" title={this.self.name}>
												{React.createElement(ReactTemplates.TOOLS.avatar, {
													user: this.self,
													size: 20,
													className: 'avatar pull-right',
													avatarService: Tools.avatarService
												})}
											</Tooltip>
										) : null}
									</div>

									<div className="SalesboardCard__ghost-card" />
									<div className="SalesboardCard__ghost-card" />
								</Column>
							</Row>
						</CardContent>
					</Card>
				</div>
				<FloatingSaveButton
					visible={cardConfigChanged}
					saving={saving}
					onClick={() => saveConfig()}
					text={save}
				/>
			</div>
		);
	}
}

SalesboardCard.propTypes = {
	config: PropTypes.object.isRequired,
	saving: PropTypes.bool.isRequired,
	setCardConfig: PropTypes.func.isRequired,
	saveConfig: PropTypes.func.isRequired,
	init: PropTypes.func.isRequired,
	cardConfigChanged: PropTypes.bool.isRequired
};

export const detached = SalesboardCard;
const Component = connect(mapStateToProps, mapDispatchToProps)(SalesboardCard);

export const AdminSalesboardCard = reactRouteCompatibility(Component, {
	featureFlag: 'PORTED_ROUTE_SALESBOARDCARD',
	wrapInPage: false
});
export default Component;

window.AdminSalesboardCard = Component;
