'use strict';
import { checkPortedResolve } from 'App/helpers/angularRouteHelpers';
import history from 'App/pages/routes/history';

angular.module('domain.segment', ['ngRoute', 'upResources', 'security.authorization']).config([
	'$stateProvider',
	'$upModalProvider',
	function ($stateProvider, $upModalProvider) {
		$stateProvider
			.state('segment', {
				template: '<div id="edit-segment-root"></div>',
				url: '/:customerId/segments/:id/',
				controller: 'EditSegment',
				section: 'market',
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					meta: [
						'$stateParams',
						'EditSegmentMeta',
						'$state',
						function ($stateParams, EditSegmentMeta) {
							return new Promise(resolve => {
								// eslint-disable-next-line promise/catch-or-return
								Tools.AppService.loadedPromise.then(() => {
									EditSegmentMeta($stateParams)
										.then(resolve)
										.catch(function () {
											history.push('/segments');
										});
								});
							});
						}
					]
				},
				params: {
					filter: { value: null },
					segment: { value: null },
					expanded: 'notExpanded',
					showTooltip: { value: null },
					showSelectionTooltip: { value: null }
				}
			})
			.state('flow', {
				template: '<div id="edit-flow-root"></div>',
				url: '/:customerId/flows/:id/',
				controller: 'EditFlow',
				section: 'market',
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return checkPortedResolve('flow', () => {
								$routeGuard.requireUser();
							});
						}
					]
				},
				params: {
					preset: null
				}
			})
			.state('segment.events', {
				template: '',
				url: 'contacts/',
				controller: '',
				section: 'market'
			})
			.state('segment.contacts', {
				template: '',
				url: 'contacts/',
				controller: '',
				section: 'market'
			})
			.state('segment.mailCampaign', {
				template: '',
				url: 'mailcampaign/',
				controller: '',
				section: 'market'
			})
			.state('segment.flow', {
				template: '',
				url: 'flow/',
				controller: '',
				section: 'market'
			})
			.state('editFlow', {
				name: 'editFlow',
				template: '<div id="flow-editor-root"></div>',
				url: '/:customerId/flow/:segmentId?preset&showSelectionTooltip',
				controller: 'FlowEditor',
				section: 'market',
				params: {
					showSelectionTooltip: { value: null }
				}
			})
			.state('editFlowStandalone', {
				name: 'editFlowStandalone',
				template: '<div id="flow-editor-root"></div>',
				url: '/:customerId/flow/standalone/:id?preset&segmentId&showSelectionTooltip',
				controller: 'FlowEditor',
				section: 'market',
				params: {
					showSelectionTooltip: { value: null }
				}
			});

		$upModalProvider
			.modal('NewSegment', {
				template: '<div id="newSegment__root"></div>',
				style: 'v2',
				controller: 'newSegmentModal as newSegmentModal',
				initialHeight: 730
			})
			.modal('addToSegment', {
				template: '<div id="addToSegment__root"></div>',
				style: 'width550 info',
				controller: 'addToSegmentModal as addToSegmentModal'
			});
	}
]);
