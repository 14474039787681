import Prospecting from 'App/babel/resources/Prospecting';
import RequestBuilder, { comparisonTypes as ComparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';

const fetcher = async (
	rb: RequestBuilder,
	{ orgNumbers, prospectingId }: { orgNumbers: string[]; prospectingId?: string }
) => {
	const countryCode = (prospectingId ?? '').split('.')[0];
	if (!orgNumbers.length || !countryCode) {
		return Promise.resolve({ data: [], metadata: { total: 0 } });
	}
	rb.addFilter({ field: 'orgNumber' }, ComparisonTypes.Equals, orgNumbers);

	if (countryCode === 'SE') {
		const orBuilder = rb.orBuilder();
		orBuilder.next();
		orBuilder.addFilter({ field: 'toDate' }, ComparisonTypes.Equals, '0000-00-00');
		orBuilder.next();
		orBuilder.addFilter({ field: 'toDate' }, ComparisonTypes.Equals, null);
		orBuilder.done();
	}

	rb.extraParams.push({
		key: 'country',
		value: countryCode
	});

	const response = await Prospecting.findDirectors(rb.build());

	for (const director of response.data) {
		director.roles =
			director.roleCodes
				?.split(',')
				.map((roleCode: string) => T(`prospecting.roleCodes.${roleCode}`))
				.join(', ') ?? '';
	}

	return response;
};

export default fetcher;
