import { insertAfter } from 'App/babel/store/helpers/array';
import { generateUserFilter } from 'App/upsales/common/attributes/filter/userFilter';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import { renderContent as renderJourneyStepFilterRow } from 'Components/Filters/JourneyStepFilterRow';
import getCategoryConfigs from 'App/babel/filters/CategoryFilters';

angular.module('upAttributes').service('ActivityAttributes', [
	'FilterType',
	'DisplayType',
	'FeatureHelper',
	'AppService',
	'$q',
	function (FilterType, DisplayType, FeatureHelper, AppService, $q) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: false
				},
				description: {
					title: 'default.description',
					field: 'description',
					type: FilterType.String,
					displayType: DisplayType.String,
					inputType: 'text',
					groupable: false,
					sortable: 'description',
					selectableColumn: true
				},
				date: {
					title: 'default.date',
					field: 'date',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: [
						{ title: 'date.year', field: 'date', type: 'year' },
						{ title: 'date.month', field: 'date', type: 'month' }
					],
					sortable: 'date',
					selectableColumn: true
				},
				time: {
					field: 'time',
					selectableColumn: false
				},
				closeDate: {
					title: 'default.closeDate',
					field: 'closeDate',
					type: FilterType.Date,
					displayType: DisplayType.String,
					inputType: 'date',
					groupable: false,
					selectableColumn: false
				},
				accountManager: {
					title: 'default.accountManager',
					field: 'client.user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					inputType: 'select',
					groupable: false,
					selectableColumn: true
				},
				contactAndClient: {
					title: 'default.contactAndClient',
					field: 'contactAndClient',
					displayType: DisplayType.String,
					sortable: 'contact.name',
					groupable: false,
					selectableColumn: true
				},
				activity: {
					title: 'default.activity',
					field: 'activity',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true
				},
				endDate: {
					title: 'default.endDate',
					field: 'endDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					filterName: 'endDateActivity',
					inputType: 'date',
					groupable: [
						{ title: 'date.year', field: 'date', type: 'year' },
						{ title: 'date.month', field: 'date', type: 'month' }
					],
					sortable: true,
					selectableColumn: false,
					filterable: false
				},
				modDate: {
					title: 'default.modDate',
					field: 'modDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					inputType: 'date'
				},
				regDate: {
					title: 'default.regDate',
					field: 'regDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					inputType: 'date'
				},
				users: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					inputType: 'select',
					displayAttr: 'name',
					parent: 'users',
					sortable: 'user.name',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					},
					groupable: false,
					selectableColumn: true
				},
				contacts: {
					title: 'default.contact',
					field: 'contact.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					parent: 'contacts',
					sortable: 'contact.name',
					attr: {
						id: {
							field: 'contact.id',
							type: FilterType.Number
						},
						name: {
							field: 'contact.name',
							type: FilterType.String
						}
					}
				},
				client: {
					title: 'default.account',
					field: 'client.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					link: 'accounts',
					groupable: false,
					sortable: 'client.name',
					selectableColumn: true,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String
						},
						user: {
							field: 'client.user',
							type: FilterType.String
						},
						category: {
							field: 'client.category',
							type: FilterType.String
						}
					}
				},
				activityType: {
					title: 'default.activityType',
					field: 'activityType.id',
					displayAttr: 'name',
					displayType: DisplayType.String,
					inputType: 'select',
					groupable: false,
					sortable: 'activityType.name',
					selectableColumn: true,
					parent: 'activityType',
					attr: {
						id: {
							field: 'activityType.id',
							type: FilterType.Number
						},
						name: {
							field: 'activityType.name',
							type: FilterType.String
						},
						first: {
							field: 'activityType.first',
							type: FilterType.Boolean
						}
					}
				},
				notes: {
					field: 'notes',
					type: FilterType.String,
					title: 'default.notes',
					inputType: 'select'
				},
				isAppointment: {
					field: 'isAppointment',
					type: FilterType.Boolean
				},
				project: {
					title: 'default.project',
					field: 'project.id',
					displayAttr: 'name',
					displayType: DisplayType.String,
					inputType: 'select',
					groupable: true,
					selectableColumn: false,
					parent: 'project',
					sortable: 'project.name',
					attr: {
						id: {
							field: 'project.id',
							type: FilterType.Number
						},
						name: {
							field: 'project.name',
							type: FilterType.String
						}
					}
				},
				projectPlan: {
					title: 'default.projectPlan',
					field: 'projectPlan.id',
					selectableColumn: false,
					attr: {
						id: {
							field: 'projectPlan.id',
							type: FilterType.Number
						},
						name: {
							field: 'projectPlan.name',
							type: FilterType.String
						}
					}
				},
				callList: {
					title: 'default.callList',
					field: 'callList.id',
					selectableColumn: false,
					attr: {
						id: {
							field: 'callList.id',
							type: FilterType.Number
						},
						name: {
							field: 'callList.name',
							type: FilterType.String
						}
					}
				},
				opportunity: {
					title: 'default.opportunity',
					field: 'opportunity.id',
					displayAttr: 'name',
					displayType: DisplayType.String,
					inputType: 'select',
					selectableColumn: true,
					parent: 'opportunity',
					sortable: 'opportunity.description',
					attr: {
						id: {
							field: 'opportunity.id',
							type: FilterType.Number
						},
						name: {
							field: 'opportunity.description',
							type: FilterType.String
						}
					}
				},
				phone: {
					title: 'default.phone',
					field: 'phone',
					selectableColumn: true,
					type: FilterType.String
				},
				custom: {},
				journeyStep: {
					title: 'default.journeyStep',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				clientJourneyStep: {
					title: 'default.journeyStepClient',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true
				},
				contactJourneyStep: {
					title: 'default.journeyStepContact',
					field: 'contact.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true
				},
				outcome: {
					title: 'default.lastOutcome',
					field: 'lastOutcome',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					sortable: 'lastOutcome.date'
				}
			};

			// Alt.namespace
			attributes.account = angular.copy(attributes.client);
			attributes.account.selectableColumn = false;
			attributes.campaign = angular.copy(attributes.project);
			attributes.campaign.selectableColumn = true;

			if (AppService.loaded) {
				const metaData = AppService.getMetadata();
				const syncJourneyStatus = metaData.params ? metaData.params.SyncJourneyStatus : false;
				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE)) {
					attributes.opportunity.selectableColumn = false;
				}
				attributes.journeyStep.selectableColumn = syncJourneyStatus;
				attributes.clientJourneyStep.selectableColumn = !syncJourneyStatus;
				attributes.contactJourneyStep.selectableColumn = !syncJourneyStatus;
			}

			if (FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION')) {
				attributes.priority = {
					title: 'default.prio',
					field: 'priority',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'priority',
					selectableColumn: true
				};
			}

			var dateFilterPresets = [
				'whenever',
				'untilToday',
				'untilTomorrow',
				'untilTwoDays',
				'untilOneWeek',
				'untilTwoWeeks',
				'untilOneMonth',
				'untilOneQuarter',
				'untilSixMonths',
				'untilOneYear',
				'last7days',
				'last14days',
				'last30days',
				'custom'
			];

			var closeDateFilterPresets = [
				'whenever',
				'currentDay',
				'lastDay',
				'currentWeek',
				'lastWeek',
				'currentMonth',
				'lastMonth',
				'currentQuarter',
				'lastQuarter',
				'currentYear',
				'lastYear',
				'last7days',
				'last14days',
				'last30days',
				'custom'
			];

			const tmpAccountCategories = getCategoryConfigs(
				'account',
				'Account',
				{
					field: 'client.category.id',
					parent: 'default.account',
					entity: undefined
				},
				'default.accountCategories'
			);

			const accountCategories = {};
			for (const key in tmpAccountCategories) {
				// Filter2:getCompareFilters() will get wrong config otherwise because FilterHelper.isCategory
				const newKey = key.replace('_', '');

				accountCategories[newKey] = tmpAccountCategories[key];
			}

			var standardFilters = {
				Date: {
					field: 'date',
					title: 'default.date',
					displayType: 'dateRange',
					type: 'dateRange',
					presets: dateFilterPresets
				},
				RegDate: {
					field: 'regDate',
					title: 'default.regDate',
					displayType: 'dateRange',
					type: 'dateRange',
					presets: closeDateFilterPresets
				},
				CloseDate: {
					field: 'closeDate',
					title: 'default.closeDate',
					displayType: 'dateRange',
					type: 'dateRange',
					presets: closeDateFilterPresets
				},
				Priority: {
					field: 'priority',
					title: 'activity.priority',
					type: 'raw',
					displayType: 'radio',
					generate: function () {
						return {
							value: [ACTIVITY_PRIORITY.NONE]
						};
					},
					build: function (filter, rb, getField) {
						if (filter.value === ACTIVITY_PRIORITY.HIGH || filter.value[0] === ACTIVITY_PRIORITY.HIGH) {
							rb.addFilter(getField('priority'), rb.comparisonTypes.Equals, filter.value);
						}
					},
					options: [
						{ text: 'default.all', value: ACTIVITY_PRIORITY.NONE, inactive: true },
						{ text: 'default.important', value: ACTIVITY_PRIORITY.HIGH }
					]
				},
				ActivityType: {
					field: 'activityType.id',
					inputType: 'select',
					multiple: true,
					dataPromise: function (AppService, $q) {
						if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
							return $q.when({
								data: Object.values(AppService.getTodoTypes()).concat(
									AppService.getActivityTypes('activity', true)
								)
							});
						}
						return $q.when({ data: AppService.getActivityTypes('activity', true) });
					},
					type: 'list',
					searchField: 'name',
					displayText: 'name',
					multiComparitors: true,
					search: false,
					title: 'default.activityTypes',
					listTitle: 'default.activityType'
				},
				User: generateUserFilter(AppService.AccessType.ACTIVITY, {
					field: 'user.id',
					title: 'default.user'
				}),
				Campaign: {
					field: 'project.id',
					inputType: 'select',
					resource: 'Campaign',
					multiple: true,
					search: true,
					includeFields: ['name'],
					searchFn: function (Campaign, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Campaign.attr.name, true);

							if (term) {
								filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Search, term);
							}

							return Campaign.customer(cId).find(filter.build());
						};
					},
					searchField: 'name',
					displayText: _.property('name'),
					type: 'list',
					displayType: 'listLazy',
					multiComparitors: true,
					title: 'default.campaigns',
					columnPath: 'campaigns'
				},
				Status: {
					title: 'default.status',
					type: 'raw',
					displayType: 'radio',
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb, getField) {
						var val = filter.value;

						if (val === null) {
							return;
						} else if (val === 'open') {
							/*
							The "id" filter is not needed when querying against /activities, however when going against /clients or /contacts
							and using a activity.closeDate or client.activity.closeDate = null filter you will get all clients or contacts that either
							have open activities or no activities at all. The id filter makes it so that only the clients and contacts that have open activities
							matches. This should not affect the result when going against /activities as all activities have an id;
						*/
							rb.addFilter(getField('id'), rb.comparisonTypes.NotEquals, null);
							rb.addFilter(getField('closeDate'), rb.comparisonTypes.Equals, null);
						} else {
							rb.addFilter(getField('closeDate'), rb.comparisonTypes.NotEquals, null);
						}
					},
					options: [
						{ text: 'default.all', value: null, inactive: true },
						{ text: 'default.open', value: 'open' },
						{ text: 'default.closed', value: 'closed' }
					]
				},
				Id: {
					title: 'default.id',
					field: 'id',
					columnPath: 'id',
					inputType: 'text',
					type: 'text',
					hide: true
				},
				IdList: {
					title: 'default.id',
					field: 'id',
					columnPath: 'id',
					inputType: 'list',
					type: 'list',
					hide: true
				},
				CallList: {
					title: 'default.callList',
					field: 'callList.id',
					hide: true,
					type: 'raw',
					build: function (filter, rb, getField) {
						if (filter.value) {
							rb.addFilter(getField('callList.id'), rb.comparisonTypes.Equals, filter.value);
						}
					},
					generate: function () {
						return {
							value: undefined
						};
					}
				},
				Project: {
					field: 'project.id',
					inputType: 'select',
					resource: 'Campaign',
					multiple: true,
					search: true,
					searchFn: function (Campaign, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term) {
							var filter = new RequestBuilder();
							filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Search, term);
							return Campaign.customer(cId).find(filter.build());
						};
					},
					searchField: 'name',
					displayText: _.property('name'),
					type: 'list',
					multiComparitors: true,
					title: 'default.campaigns',
					columnPath: 'campaigns',
					hide: true
				},
				AdvancedDate: {
					title: 'default.date',
					type: 'raw',
					hide: true,
					fromUrl: function (f) {
						return {
							value: {
								field: f.v.field,
								start: f.v.start ? new Date(f.v.start) : null,
								end: f.v.end ? new Date(f.v.end) : null,
								preset: 'custom'
							}
						};
					},
					generate: function () {
						return {
							value: {
								field: 'closeDate',
								start: null,
								end: null,
								preset: 'custom'
							}
						};
					},
					build: function (filter, rb, getField) {
						var val = filter.value;
						var field = filter.value.field;

						if (val.start) {
							rb.addFilter(getField(field), rb.comparisonTypes.GreaterThanEquals, val.start);
						}
						if (val.end) {
							rb.addFilter(getField(field), rb.comparisonTypes.LowerThanEquals, val.end);
						}

						return;
					}
				},
				Description: {
					field: 'description',
					title: 'default.description',
					inputType: 'text',
					type: 'text'
				},
				IsAppointment: {
					field: 'isAppointment',
					title: 'default.isAppointment',
					inputType: 'boolean',
					type: 'boolean',
					hide: true
				},
				Contact: {
					field: 'contact.id',
					multiple: true,
					title: 'default.contacts',
					inputType: 'select',
					resource: 'Contact',
					type: 'list',
					displayType: 'listLazy',
					includeFields: ['name'],
					searchFn: function (Contact, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Contact.attr.name, true);

							if (term) {
								filter.addFilter(Contact.attr.name, filter.comparisonTypes.Search, term);
							}

							return Contact.customer(cId).find(filter.build());
						};
					},
					search: true,
					multiComparitors: true,
					searchField: 'name',
					displayText: 'name',
					hide: true
					// displayText: function(res) {
					// 	return res.name + (res.client ? ' <i class="gray">(' + res.client.name + ')</i>' : '');
					// }
				},
				Account: {
					field: 'client.id',
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					type: 'list',
					searchField: 'name',
					displayText: 'name',
					displayType: 'listLazy',
					search: true,
					includeFields: ['name'],
					searchFn: function (Account, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Account.attr.name, true);

							if (term) {
								filter.addFilter(Account.attr.name, filter.comparisonTypes.Search, term);
							}

							return Account.customer(cId).find(filter.build());
						};
					},
					title: 'default.account',
					multiComparitors: true,
					parent: 'default.account'
				},
				AccountManager: generateUserFilter(AppService.AccessType.ACCOUNT, {
					field: 'client.user.id',
					title: 'default.accountManagers'
				}),
				...accountCategories,
				Notes: {
					field: 'notes',
					multiple: false,
					resource: 'Activity',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb, getField) {
						if (filter.value) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter(
								getField('comment.description'),
								rb.comparisonTypes.Search,
								filter.value
							);

							orBuilder.next();
							orBuilder.addFilter(getField('notes'), rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					},
					searchField: 'notes',
					displayText: 'notes',
					search: true,
					title: 'default.notes',
					inputType: 'text',
					displayType: 'text'
				},
				HasOpportunity: {
					field: 'opportunity.id',
					listTitle: 'default.opportunity',
					title: 'default.opportunity',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.with', value: null, comparisonType: 'NotEquals' },
						{ text: 'default.without', value: null, comparisonType: 'Equals' }
					]
				},
				AddressFilters: {
					title: 'default.addresses',
					type: 'raw',
					inputType: 'address',
					displayType: 'address',
					view: 'upsales/common/components/listFilters/address.tpl.html',
					parent: 'default.account',
					availableAddresses: ['Visit', 'Mail', 'Delivery', 'Invoice', 'Other'],
					getText: function (val) {
						var num = 0;
						if (val.Zipcode.value.start || val.Zipcode.value.end) {
							num++;
						}
						if (val.Address.value) {
							num++;
						}
						if (val.City.value) {
							num++;
						}
						if (val.State.value) {
							num++;
						}
						if (val.Country.value) {
							num++;
						}
						return num;
					},
					generate: function () {
						return {
							value: {
								AddressType: { value: null },
								Zipcode: { value: { start: null, end: null } },
								Address: { value: '', comparisonType: 'Search' },
								City: { value: '', comparisonType: 'Search' },
								State: { value: '', comparisonType: 'Search' },
								Country: { value: '', comparisonType: 'Search' }
							}
						};
					},
					build: function (filter, rb) {
						var val = filter.value;
						var hasValue = false;

						// Set up group
						var groupBuilder = rb.groupBuilder();
						if (val.AddressType.value) {
							groupBuilder.addFilter(
								{ field: 'client.address.type' },
								rb.comparisonTypes.Equals,
								val.AddressType.value
							);
						}

						// Address
						if (val.Address.value.length) {
							groupBuilder.addFilter(
								{ field: 'client.address.address' },
								rb.comparisonTypes[val.Address.comparisonType],
								val.Address.value
							);
							hasValue = true;
						}

						// City
						if (val.City.value.length) {
							groupBuilder.addFilter(
								{ field: 'client.address.city' },
								rb.comparisonTypes[val.City.comparisonType],
								val.City.value
							);
							hasValue = true;
						}

						// State
						if (val.State.value.length) {
							groupBuilder.addFilter(
								{ field: 'client.address.state' },
								rb.comparisonTypes[val.State.comparisonType],
								val.State.value
							);
							hasValue = true;
						}

						// Country
						if (val.Country.value.length) {
							groupBuilder.addFilter(
								{ field: 'client.address.country' },
								rb.comparisonTypes[val.Country.comparisonType],
								val.Country.value
							);
							hasValue = true;
						}

						// Zipcode start
						if (val.Zipcode.value.start) {
							groupBuilder.addFilter(
								{ field: 'client.address.zipcode' },
								rb.comparisonTypes.GreaterThanEquals,
								val.Zipcode.value.start.toString()
							);
							hasValue = true;
						}

						// Zipcode end
						if (val.Zipcode.value.end) {
							groupBuilder.addFilter(
								{ field: 'client.address.zipcode' },
								rb.comparisonTypes.LowerThanEquals,
								val.Zipcode.value.end.toString()
							);
							hasValue = true;
						}

						if (hasValue) {
							groupBuilder.done();
						}
					}
				},
				ListSearch: {
					filterName: 'ListSearch',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v
						};
					},
					build: function (filter, rb) {
						if (filter.value && filter.value.length) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter({ field: 'contact.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter({ field: 'client.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter(
								{ field: 'activityType.name' },
								rb.comparisonTypes.Wildcard,
								filter.value
							);

							orBuilder.next();
							orBuilder.addFilter({ field: 'description' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					}
				},
				JourneyStep: {
					type: 'list',
					filterName: 'JourneyStep',
					parent: 'default.account',
					field: 'client.journeyStep',
					title: 'default.journeyStep',
					hide: true,
					dataPromise: function () {
						return $q.when({
							data: Tools.AppService.getJourneySteps().map(function (j) {
								j.id = j.value;
								return j;
							})
						});
					},
					displayComponent: function (item) {
						return renderJourneyStepFilterRow(item);
					}
				}
			};

			if (!FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION')) {
				delete standardFilters.Priority;
			}

			if (AppService.loaded) {
				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE)) {
					standardFilters.HasOpportunity.hide = true;
				}

				if (FeatureHelper.isAvailable(FeatureHelper.Feature.JOURNEY_STATUS)) {
					standardFilters.JourneyStep.hide = false;
				}

				if (
					AppService.getMetadata().params.brokenFiscalYearEnabled &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR)
				) {
					insertAfter(closeDateFilterPresets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(closeDateFilterPresets, 'lastQuarter', 'lastFiscalQuarter');
					insertAfter(closeDateFilterPresets, 'currentYear', 'currentFiscalYear');
					insertAfter(closeDateFilterPresets, 'lastYear', 'lastFiscalYear');
				}
			}

			var requiredFields = ['isAppointment', 'custom'];

			return {
				getAll: function () {
					return attributes;
				},
				standardFilters: standardFilters,
				attr: attributes,
				requiredFields: requiredFields,
				keys: {
					ID: 'id',
					DESCRIPTION: 'description',
					DATE: 'date',
					CLOSEDATE: 'closeDate',
					USERS: 'users',
					CONTACT: 'contact',
					ACCOUNT: 'account',
					ACTIVITYTYPE: 'activityType',
					NOTES: 'notes',
					ISAPPOINTMENT: 'isAppointment',
					PROJECT: 'project',
					CUSTOM: 'custom',
					CONTACTS: 'contacts'
				}
			};
		};
	}
]);
