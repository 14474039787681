import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import BemClass from '@upsales/components/Utils/bemClass';
import CriteriaInput from './CriteriaRow/CriteriaInput';
import CriteriaTableFooter from './CriteriaTableFooter';
import CriteriaPlaceholder from './CriteriaPlaceholder';
import ComparisonToggle from './ComparisonToggle';
import t from 'Components/Helpers/translate';
import './CriteriaTable.scss';

class CriteriaTable extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			include: t('segment.include'),
			exclude: t('segment.exclude'),
			whoMatch: props.entities.contact ? t('segment.contactsWhoMatch') : t('segment.companiesWhoMatch'),
			followingCriterias: t('segment.followingCriterias'),
			any: t('default.any').toLowerCase(),
			all: t('default.all').toLowerCase(),
			or: t('default.or').toLowerCase(),
			addCriteriasFor: t('segment.addCriteriasFor'),
			client: t('default.clients'),
			contact: t('default.contacts'),
			selectPlaceholder: t('segment.selectCriteriaPlaceholder'),
			otherInfo: t('default.otherInfo'),
			addCriteriaForCompaniesContactsHere: t('admin.addCriteriaHere')
		};

		const isExclude = props.filters.isExclude;
		const { toggleOrGroup, editFilter, removeFilter } = props;

		this.toggleOrGroup = toggleOrGroup.bind(null, isExclude);
		this.editFilter = editFilter.bind(null, isExclude);
		this.removeFilter = removeFilter.bind(null, isExclude);

		this.state = { drilldowns: {} };

		this.registerDrilldown = this.registerDrilldown.bind(this);
		this.toggleDrilldown = this.toggleDrilldown.bind(this);
		this.registeredDrilldowns = {};
	}

	toggleDrilldown(filterName) {
		const { drilldowns } = this.state;
		drilldowns[filterName] = !drilldowns[filterName];
		this.setState({ drilldowns });
	}

	registerDrilldown(filterName, fn) {
		this.registeredDrilldowns[filterName] = fn;
		this.setState({}); // Only adds one more render as all registers happen in the same cycle
	}

	onChange(newValue, newFilter, oldFilter) {
		if (!newFilter) {
			newFilter = _.cloneDeep(oldFilter);
		}
		newFilter.value = newValue;
		this.editFilter(newFilter.filterName, newFilter);
	}

	render() {
		const { filters, filterConfigs, fakeConfigs, addFilter, listStateError, type, entities } = this.props;
		const isExclude = filters.isExclude;
		const hasCpvFilter = Tools.FeatureHelper.hasSoftDeployAccess('PROSPECTING_CPV_FILTER');

		const classes = new BemClass('CriteriaTable');

		const filterRows = Object.keys(filters.config || {}).reduce((res, filterName) => {
			const filter = filters.config[filterName];
			const configName = Tools.FilterHelper.getConfigName(filterName);
			const config = filterConfigs[configName];
			
			if (filterName === 'IndustryCPV' && !hasCpvFilter) {
				return res;
			}

			if (config && config.showOnSegment) {
				const type = config.displayType ? config.displayType : config.type;
				const hasOpenDrilldown = this.state.drilldowns[filterName] || false;
				const RegisteredDrilldown = this.registeredDrilldowns[filterName];

				res.push(
					<CriteriaInput
						key={filterName}
						type={type}
						config={config}
						filter={filter}
						removeCriteria={this.removeFilter}
						onChange={(newValue, newFilter) => this.onChange(newValue, newFilter, filter)}
						toggleDrilldown={this.toggleDrilldown}
						registerDrilldown={this.registerDrilldown}
						hasOpenDrilldown={hasOpenDrilldown}
						selectData={this.props.selectData}
						LIST_AJAX_LIMIT={this.props.LIST_AJAX_LIMIT}
						fakeConfigs={fakeConfigs}
						autoFocus={this.props.autoFocus}
						listStateError={listStateError}
						entities={entities}
						filterConfigs={filterConfigs}
					/>
				);
				if (RegisteredDrilldown) {
					res.push(
						<RegisteredDrilldown
							open={hasOpenDrilldown}
							key={filterName + '-drilldown'}
							config={config}
							filter={filter}
							LIST_AJAX_LIMIT={this.props.LIST_AJAX_LIMIT}
							fakeConfigs={fakeConfigs}
							selectData={this.props.selectData}
							onChange={this.editFilter}
							filterConfigs={filterConfigs}
						/>
					);
				}
			}

			return res;
		}, []);

		return (
			<div className={classes.b()}>
				{!this.props.noHeader ? (
					<div className={classes.elem('header').b()}>
						<i className={isExclude ? 'fa fa-minus-circle' : 'fa fa-plus-circle'} />
						<span>{(isExclude ? this.lang.exclude : this.lang.include) + ' ' + this.lang.whoMatch}</span>
						<ComparisonToggle
							value={filters.orGroup}
							onChange={this.toggleOrGroup}
							yes={this.lang.any}
							no={this.lang.all}
						/>
						<span>{this.lang.followingCriterias + ':'}</span>
					</div>
				) : null}
				{filterRows.length ? (
					<table className="table">
						<tbody>{filterRows}</tbody>
					</table>
				) : (
					<CriteriaPlaceholder text={this.lang.addCriteriaForCompaniesContactsHere} />
				)}
				<CriteriaTableFooter
					type={type}
					filterConfigs={filterConfigs}
					filters={filters}
					addFilter={addFilter}
					entities={entities}
					anchor={this.props.criteriaSelectAnchor}
				/>
			</div>
		);
	}
}

CriteriaTable.propTypes = {
	filters: PropTypes.object.isRequired,
	filterConfigs: PropTypes.object.isRequired,
	fakeConfigs: PropTypes.object.isRequired,
	addFilter: PropTypes.func.isRequired,
	toggleOrGroup: PropTypes.func.isRequired,
	editFilter: PropTypes.func.isRequired,
	removeFilter: PropTypes.func.isRequired,
	selectData: PropTypes.object.isRequired,
	LIST_AJAX_LIMIT: PropTypes.number.isRequired,
	state: PropTypes.object.isRequired,
	listStateError: PropTypes.object,
	type: PropTypes.string.isRequired,
	entities: PropTypes.object,
	noHeader: PropTypes.bool,
	criteriaSelectAnchor: PropTypes.object,
	autoFocus: PropTypes.bool
};

CriteriaTable.defaultProps = {
	LIST_AJAX_LIMIT: 50,
	addFilter: () => {},
	removeFilter: () => {},
	editFilter: () => {},
	toggleOrGroup: () => {},
	fakeConfigs: {},
	selectData: {},
	state: {},
	type: 'criteria',
	autoFocus: false,
	entities: {
		contact: true,
		client: true
	}
};

export default CriteriaTable;
