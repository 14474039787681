'use strict';
import DecisionMakersSidebar from 'App/babel/components/DecisionMakersSidebar';

angular.module('upDirectives').directive('upDecisionMakerRoot', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		link: function ($scope, $element, $attr) {
			var root = $element[0];
			if (root) {
				window.SetupComponent(
					$scope,
					DecisionMakersSidebar,
					root,
					{
						salesCoachDecisionMaker: $scope.$eval($attr.salesCoachDecisionMaker),
						newTitleCategories: $scope.$eval($attr.newTitleCategories),
						clientId: $scope.$eval($attr.clientId),
						updateStakeholders: $scope.$eval($attr.updateStakeholders),
						updateTitleCategories: $scope.$eval($attr.updateTitleCategories),
						stakeholders: $scope.$eval($attr.stakeholders),
						titleCategories: $scope.$eval($attr.titleCategories),
						contacts: $scope.$eval($attr.contacts),
						disabled: $scope.$eval($attr.disabled)
					},
					{
						redux: true,
						watch: [
							{
								attr: $attr.salesCoachDecisionMaker,
								prop: 'salesCoachDecisionMaker'
							},
							{
								attr: $attr.newTitleCategories,
								prop: 'newTitleCategories'
							},
							{
								attr: $attr.contacts,
								prop: 'contacts'
							},
							{
								attr: $attr.stakeholders,
								prop: 'stakeholders'
							},
							{
								attr: $attr.titleCategories,
								prop: 'titleCategories'
							}
						]
					}
				);
			}
		}
	};
});
