import { useSelf } from 'App/components/hooks/appHooks';
import { useSoftDeployAccess } from 'App/components/hooks';
import { addNotification, STYLE as notificationStyle } from 'Store/reducers/SystemNotificationReducer';
import { useAppDispatch } from 'App/components/hooks';

export default function MailCampaignAllowed() {
	const isAdmin = useSelf()?.administrator;
	const isMailAdmin = useSelf()?.userParams.mailAdmin;
	const isAllowed = isAdmin || isMailAdmin;
	const hasFlag = useSoftDeployAccess('REACT_MAIL_CAMPAIGN');
	const dispatch = useAppDispatch();

	// Avoid duplicate notifications since angular will show as well
	if (!isAllowed && hasFlag) {
		dispatch(
			addNotification({
				title: 'default.error',
				body: 'errorNotFound.mailCampaign',
				style: notificationStyle.ERROR,
				icon: 'times'
			})
		);
	}

	return isAllowed || false;
}
